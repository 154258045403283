/**
 * This constant is what we are using for our application state at present. It
 * describes, by way of example, the schema of the data on which this system is
 * built. This state is analogous to what we would consider an end-user's
 * "document", and will ultimately be the data persisted by the back-end API.
 *
 * Currently, this is all stubbed out data that corresponds 1-to-1 with the
 * input values in a spreadsheet provided by The Lanis Group. The purpose of
 * this state is to capture all possible inputs from the user on this webapp.
 *
 * However, once the application inputs are fleshed out, the next steps are:
 *
 *  1. Create second fake state with sensible defaults that do not break
 *     everything
 *  2. Implement API for creating a new "document" on the back-end which
 *     would provide #1 to this application as state
 *  3. Implement API for fetching a "document" on the back-end which would
 *     provide what would look like this fake state, but with real values
 *  4. Implement API for saving this "document" to the back-end service
 *  5. Remove this fake state
 */
export const fake_state_todo_remove = {
  applicant: {
    first_contact_date: "",
    pay_off_debt_to_qualify: "1",
    all_loan_types_the_same: "",
    different_loan_options_for_paying_off_debt: "1",
    enough_reserves_to_purchase_home: "",
    borrowers: [],
    //LEPriceEngine
    closing_costs_expire: "",
    closing_costs_expire_time: "",
    closing_costs_expire_time_zone: "",
    estimated_close_of_escrow: "",
    first_payment_date: "",
    pricing_lock_period: "",
    loan_locked: "",
    loan_lock_date: "",
    loan_lock_time: "",
    loan_lock_time_zone: ""
  },
  accountSummaries: {},
  residences: {},
  property: {
    classification: "",
    expectancy: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    title_vesting: "",
    will_home_be_in_trust: "",
    purpose_of_purchase: "",
    purpose_of_refinance: "",
    impounds_for_loan: "",
    preferred_monthly_payment: "0",
    occupancy: "",
    property_type: "",
    hoa1: "0",
    hoa1_frequency: "",
    hoa2: "0",
    hoa2_frequency: "",
    mello_roos: "",
    mello_roos_amount: "0",
    mello_roos_frequency: "",
    property_tax_percent: "0",
    refinance_property_tax_amount: "0",
    refinance_property_tax_frequency: "",
    home_owners_insurance: "0",
    home_owners_insurance_frequency: "",
    listed_price: "0",
    target_ltv: "80",
    escrow_opened: "",
    different_escrow_title: "",
    close_of_escrow_date: "",
    time_sensitive: "",
    solar_system: "",
    solar_system_type: "",
    solar_system_amount: "0",
    loans_or_liens: "",
    hero_pace_loans_on_the_property: "",
    tax_liens_state_or_federal: "",
    reo_or_bank_owned: "",
    in_foreclosure: "",
    principal_balance: "0",
    first_mortgage: "0",
    second_mortgage: "0"
  },
  is_selling_property: "",
  property_to_be_sold: {
    property_type: "",
    first_current_monthly_payment_principal: "0",
    first_current_monthly_payment_interest: "0",
    second_current_monthly_payment: "0",
    miscellaneous_housing_payment: "0",
    existing_mortgage_insurance: "0",
    fha_loan: "",
    concurrent_close: "",
    lender_fees: "0",
    current_market_value: "0",
    first_loan_balance: "0",
    second_loan_balance: "0",
    third_loan_balance: "0",
    solar_system: "",
    solar_system_type: "",
    solar_system_amount: "0",
    loans_or_liens: "",
    hero_pace_loans_on_the_property: "",
    tax_liens_state_or_federal: "",
    reo_or_bank_owned: "",
    in_foreclosure: "",
    hoa_transfer_fees: "0",
    pay_off_back_taxes: "0",
    seller_concessions: "0",
    rebate_back_to_borrower_closing_costs: "0",
    percent_seller_closing_costs: "0",
    real_estate_commission: "0",
    original_purchase_money_loans: "",
    lender_payoff_first_loan_demand_recorded: "",
    lender_payoff_second_loan_demand_recorded: "",
    lender_payoff_third_loan_demand_recorded: "",
    first_loan_total_pay_off_amount: "0",
    second_loan_total_pay_off_amount: "0",
    third_loan_total_pay_off_amount: "0"
  },
  investment_properties: [],
  loans: [],
  summary: {
    selection_loan_number: "",
    selection_date: "",
    selection_time: "",
    loans: [
      {
        anti_steering_selection: ""
      },
      {
        anti_steering_selection: ""
      },
      {
        anti_steering_selection: ""
      },
      {
        anti_steering_selection: ""
      },
      {
        anti_steering_selection: ""
      }
    ],
    refinance_cash_flow_over_years: ["3", "5", "10", "15"]
  }
};
