import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  loan_origination_fee,
  mortgage_broker_fee,
  percent_of_loan_amount,
  total_origination_charges
} from "../../../calculations/loan";
import ORIGINATION_FEES from "../../../constants/origination_fees";
import PercentField from "../../fields/percent_field";
import LoanFees from "./loan_fees";
import { LENDER_PAID_FEE } from "../../../constants/origination_fee_paid_by";
import { useDocument } from "../loan_tool";
import { class_list } from "../../../constants/utils";
import TrainingTip from "../../ui/training_tip";

export default function OriginationCharges({
  loan,
  property,
  readOnly,
  removeOriginationCharges,
  updateOriginationCharges
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isLenderPaid = loan.origination_fee_paid_by === LENDER_PAID_FEE;
  const percentOfLoanAmount = percent_of_loan_amount(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  const loanOriginationFee = loan_origination_fee(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );

  return (
    <div className="origination-charges">
      <div className="closing-costs-row gray-bg">
        <div className="flex align-items-center">
          <div className="section-title">A. Origination Charges</div>
          <TrainingTip content="LANIS Training Tip: Tolerance Level 0%" />
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_origination_charges(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      {percentOfLoanAmount.gt(0) && (
        <div className="closing-costs-row">
          <div>
            <span>
              <PercentField
                className="loan-amount-points closing-costs-cell gray-bg"
                naked
                value={percentOfLoanAmount}
              />
            </span>
            <span> of Loan Amount (Points)</span>
          </div>
        </div>
      )}
      {loanOriginationFee.gt(0) && (
        <div className="closing-costs-row">
          <div>Loan Origination Fee</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={loanOriginationFee}
          />
        </div>
      )}
      <div className="closing-costs-row">
        <div>Mortgage Broker Fee</div>
        <div>{isLenderPaid && "Lender Pd."}</div>
        <div className="flex">
          <span
            className={class_list(
              "closing-costs-cell",
              "right-align",
              isLenderPaid && "gray-bg"
            )}
          >
            {isLenderPaid && (
              <CurrencyField
                naked
                fixedDigits={0}
                value={mortgage_broker_fee(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            )}
          </span>
          <span
            className={class_list(
              "closing-costs-cell",
              "right-align",
              !isLenderPaid && "gray-bg"
            )}
          >
            {!isLenderPaid && (
              <CurrencyField
                naked
                fixedDigits={0}
                value={mortgage_broker_fee(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            )}
          </span>
        </div>
      </div>
      <LoanFees
        loanFees={loan.origination_fees}
        allFees={ORIGINATION_FEES}
        removeFee={removeOriginationCharges}
        updateFees={updateOriginationCharges}
        readOnly={readOnly}
      />
    </div>
  );
}
