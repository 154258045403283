import React, { useRef, useEffect, useMemo } from "react";

import "./css/loan_tool_navigation.scss";
import { useDocument } from "../../components/loan_tools/loan_tool";
import SaveLoan from "../../components/loan_tools/save_loan";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import {
  APPLICANT_INFO,
  CLOSING_COSTS_EST,
  CURRENT_PROP_INFO,
  DEBT_INFO,
  DOWN_PAYMENT,
  INCOME,
  INVEST_REO,
  LE_PRICE_ENG,
  LE_PRICE_ENG_OPT,
  LOAN_CONFIGURATOR,
  LOAN_TYPE,
  NAVIGATION_NAMES,
  NEW_PROPERTY,
  PURCHASE_NAV,
  REFINANCE_NAV,
  RESERVES,
  SELLING_PROP,
  SUBJECT_PROPERTY
} from "../../constants/navigations";
import {
  class_list,
  get_bounding_offset_rect,
  is_nav_marked
} from "../../constants/utils";

function NavConnectorLine({ positionOne, positionTwo }) {
  if (!positionOne || !positionTwo) {
    return <></>;
  }

  const rightOriented = positionTwo.left < positionOne.left;
  const widthOffset = rightOriented
    ? (positionOne.width - positionTwo.width) / 2
    : (positionTwo.width - positionOne.width) / 2;

  const styles = {
    top: positionOne.top + positionOne.height,
    left: rightOriented
      ? positionTwo.left + positionTwo.width / 2
      : positionOne.left + positionOne.width / 2,
    height: positionTwo.top - (positionOne.top + positionOne.height),
    width: Math.abs(positionOne.left - positionTwo.left) + widthOffset
  };

  return (
    <div
      className={class_list(
        "nav-connector-line",
        rightOriented && "nav-connector-right-oriented"
      )}
      style={styles}
    >
      <div className="nav-connector-line-top" />
      <div className="nav-connector-line-bottom" />
    </div>
  );
}

export default function LoanToolNavigation({ active, isDisableNavSave }) {
  const [
    ,
    ,
    {
      documentId,
      documentType,
      navConnectorLinePositionOne,
      navConnectorLinePositionTwo
    },
    { setNavConnectorLinePositionOne, setNavConnectorLinePositionTwo }
  ] = useDocument();
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const saveAndContinuePath = useMemo(
    () =>
      (active === APPLICANT_INFO &&
        isPurchase &&
        `/loan-tool/${documentId}/new-property`) ||
      (active === APPLICANT_INFO &&
        isRefinance &&
        `/loan-tool/${documentId}/subject-property`) ||
      (active === NEW_PROPERTY &&
        `/loan-tool/${documentId}/selling-property`) ||
      (active === SUBJECT_PROPERTY &&
        `/loan-tool/${documentId}/current-prop-info`) ||
      `/loan-tool/${documentId}/invest-reo`,
    [active]
  );

  const applicantInfoRef = useRef();
  const activeToRef = {
    [APPLICANT_INFO]: useRef(),
    [NEW_PROPERTY]: useRef(),
    [SELLING_PROP]: useRef(),
    [DOWN_PAYMENT]: useRef(),
    [CLOSING_COSTS_EST]: useRef(),
    [SUBJECT_PROPERTY]: useRef(),
    [CURRENT_PROP_INFO]: useRef(),
    [DOWN_PAYMENT]: useRef(),
    [LOAN_CONFIGURATOR]: useRef(),
    [CLOSING_COSTS_EST]: useRef()
  };

  const navArray = isPurchase ? PURCHASE_NAV : REFINANCE_NAV;
  const isApplicantInfoActive = [
    APPLICANT_INFO,
    NEW_PROPERTY,
    SELLING_PROP,
    SUBJECT_PROPERTY,
    CURRENT_PROP_INFO
  ].includes(active);

  useEffect(() => {
    updateNavConnector();
    window.addEventListener("resize", updateNavConnector);

    return () => {
      window.removeEventListener("resize", updateNavConnector);
      setNavConnectorLinePositionOne(null);
      setNavConnectorLinePositionTwo(null);
    };
  }, [active]);

  function updateNavConnector() {
    if (isApplicantInfoActive) {
      setNavConnectorLinePositionOne(
        get_bounding_offset_rect(applicantInfoRef.current)
      );
      setNavConnectorLinePositionTwo(
        get_bounding_offset_rect(activeToRef[active].current)
      );
    }

    if ([DOWN_PAYMENT, LOAN_CONFIGURATOR, CLOSING_COSTS_EST].includes(active)) {
      setNavConnectorLinePositionOne(
        get_bounding_offset_rect(activeToRef[active].current)
      );
    }
  }

  function NavGap() {
    return (
      <>
        <div className="nav-arrow">
          <div className="arrow-body" />
          <div className="arrow-head" />
        </div>
        <div className="nav-connector" />
      </>
    );
  }

  return (
    <section className="loan-tool-navigation">
      <NavConnectorLine
        positionOne={navConnectorLinePositionOne}
        positionTwo={navConnectorLinePositionTwo}
      />
      <ul className="nav-bar">
        <li className="client-info">Client Info</li>
        <li
          className={class_list(
            "nav-button",
            isApplicantInfoActive && "active",
            !isApplicantInfoActive &&
              is_nav_marked(navArray, active, APPLICANT_INFO) &&
              "marked"
          )}
        >
          <SaveLoan
            ref={applicantInfoRef}
            useAnchor
            name={NAVIGATION_NAMES[APPLICANT_INFO]}
            path={`/loan-tool/${documentId}/applicant-info`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === INVEST_REO && "active",
            is_nav_marked(navArray, active, INVEST_REO) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[INVEST_REO]}
            path={`/loan-tool/${documentId}/invest-reo`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === DEBT_INFO && "active",
            is_nav_marked(navArray, active, DEBT_INFO) && "marked"
          )}
        >
          <div className="nav-arrow">
            <div className="arrow-body" />
            <div className="arrow-head" />
          </div>
          <div className="nav-connector" />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[DEBT_INFO]}
            path={`/loan-tool/${documentId}/debt-info`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === INCOME && "active",
            is_nav_marked(navArray, active, INCOME) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[INCOME]}
            path={`/loan-tool/${documentId}/income`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === RESERVES && "active",
            is_nav_marked(navArray, active, RESERVES) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[RESERVES]}
            path={`/loan-tool/${documentId}/reserves`}
          />
        </li>
        <li className="nav-button">
          <SaveLoan
            className="button"
            name="Save"
            disabled={isDisableNavSave}
          />
        </li>
      </ul>
      {isApplicantInfoActive && (
        <ul className="nav-bar sub-nav">
          <li
            className={class_list(
              "nav-button",
              active === APPLICANT_INFO && "active",
              is_nav_marked(navArray, active, APPLICANT_INFO) && "marked"
            )}
          >
            <SaveLoan
              ref={activeToRef[APPLICANT_INFO]}
              useAnchor
              name={NAVIGATION_NAMES[APPLICANT_INFO]}
              path={`/loan-tool/${documentId}/applicant-info`}
            />
          </li>
          {isPurchase && (
            <>
              <li
                className={class_list(
                  "nav-button",
                  active === NEW_PROPERTY && "active",
                  is_nav_marked(navArray, active, NEW_PROPERTY) && "marked"
                )}
              >
                <NavGap />
                <SaveLoan
                  ref={activeToRef[NEW_PROPERTY]}
                  useAnchor
                  name={NAVIGATION_NAMES[NEW_PROPERTY]}
                  path={`/loan-tool/${documentId}/new-property`}
                />
              </li>
              <li
                className={class_list(
                  "nav-button",
                  active === SELLING_PROP && "active",
                  is_nav_marked(navArray, active, SELLING_PROP) && "marked"
                )}
              >
                <NavGap />
                <SaveLoan
                  ref={activeToRef[SELLING_PROP]}
                  useAnchor
                  name={NAVIGATION_NAMES[SELLING_PROP]}
                  path={`/loan-tool/${documentId}/selling-property`}
                />
              </li>
            </>
          )}
          {isRefinance && (
            <>
              <li
                className={class_list(
                  "nav-button",
                  active === SUBJECT_PROPERTY && "active",
                  is_nav_marked(navArray, active, SUBJECT_PROPERTY) && "marked"
                )}
              >
                <NavGap />
                <SaveLoan
                  ref={activeToRef[SUBJECT_PROPERTY]}
                  useAnchor
                  name={NAVIGATION_NAMES[SUBJECT_PROPERTY]}
                  path={`/loan-tool/${documentId}/subject-property`}
                />
              </li>
              <li
                className={class_list(
                  "nav-button",
                  active === CURRENT_PROP_INFO && "active",
                  is_nav_marked(navArray, active, CURRENT_PROP_INFO) && "marked"
                )}
              >
                <NavGap />
                <SaveLoan
                  ref={activeToRef[CURRENT_PROP_INFO]}
                  useAnchor
                  name={NAVIGATION_NAMES[CURRENT_PROP_INFO]}
                  path={`/loan-tool/${documentId}/current-prop-info`}
                />
              </li>
            </>
          )}
          <li className="nav-button">
            <SaveLoan
              className="button"
              name="Save and Continue"
              path={saveAndContinuePath}
              disabled={isDisableNavSave}
            />
          </li>
        </ul>
      )}
      <ul className="nav-bar">
        <li className="loan-details">Loan Details</li>
        <li
          className={class_list(
            "nav-button",
            active === LOAN_TYPE && "active",
            is_nav_marked(navArray, active, LOAN_TYPE) && "marked"
          )}
        >
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[LOAN_TYPE]}
            path={`/loan-tool/${documentId}/loan-type`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === LE_PRICE_ENG && "active",
            is_nav_marked(navArray, active, LE_PRICE_ENG) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[LE_PRICE_ENG]}
            path={`/loan-tool/${documentId}/loan-estimate-price-engine`}
          />
        </li>
        <li
          className={class_list(
            "nav-button",
            active === LE_PRICE_ENG_OPT && "active",
            is_nav_marked(navArray, active, LE_PRICE_ENG_OPT) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            useAnchor
            name={NAVIGATION_NAMES[LE_PRICE_ENG_OPT]}
            path={`/loan-tool/${documentId}/loan-estimate-price-engine-options`}
          />
        </li>
        {isPurchase && (
          <li
            className={class_list(
              "nav-button",
              active === DOWN_PAYMENT && "active",
              is_nav_marked(navArray, active, DOWN_PAYMENT) && "marked"
            )}
          >
            <NavGap />
            <SaveLoan
              ref={activeToRef[DOWN_PAYMENT]}
              useAnchor
              name={NAVIGATION_NAMES[DOWN_PAYMENT]}
              path={`/loan-tool/${documentId}/down-payment-calculator`}
            />
          </li>
        )}
        {isRefinance && (
          <li
            className={class_list(
              "nav-button",
              active === LOAN_CONFIGURATOR && "active",
              is_nav_marked(navArray, active, LOAN_CONFIGURATOR) && "marked"
            )}
          >
            <NavGap />
            <SaveLoan
              ref={activeToRef[LOAN_CONFIGURATOR]}
              useAnchor
              name={NAVIGATION_NAMES[LOAN_CONFIGURATOR]}
              path={`/loan-tool/${documentId}/loan-configurator`}
            />
          </li>
        )}
        <li
          className={class_list(
            "nav-button",
            active === CLOSING_COSTS_EST && "active",
            is_nav_marked(navArray, active, CLOSING_COSTS_EST) && "marked"
          )}
        >
          <NavGap />
          <SaveLoan
            ref={activeToRef[CLOSING_COSTS_EST]}
            useAnchor
            name={NAVIGATION_NAMES[CLOSING_COSTS_EST]}
            path={`/loan-tool/${documentId}/closing-costs`}
          />
        </li>
      </ul>
    </section>
  );
}
