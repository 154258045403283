import React from "react";
import CurrencyField from "../../fields/currency_field";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import {
  actual_cash_down_payment,
  adjustments_and_other_credits,
  emd,
  estimated_cash_to_close,
  estimated_closing_costs_financed,
  first_mortgage_payment_p_and_i,
  liquid_reserves_after_close_of_escrow,
  loan_amount_second_mortgage,
  seller_credits,
  total_closing_costs,
  total_cost_to_purchase,
  total_down_payment_cash_to_lender
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import {
  calculate_reserve_total,
  total_of_liquid_and_retirement_accounts,
  total_reserves_available
} from "../../../calculations/reserve";
import { TYPE_RETIREMENT } from "../../../constants/reserves_account_types";
import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { is_any_non_zero } from "../../../calculations/utils";

import { useDocument } from "../../loan_tools/loan_tool";
import TrainingTip from "../../ui/training_tip";
import {
  ActualPitiHoaWithoutShock,
  ActualPrincipalAndInterestMonthlyPayment,
  AmortizedCombinedPaymentIOLoan,
  AmountOffDebtToPayOffToQualify,
  ArmTerms,
  BottomEndDebtToIncomeRatio,
  BottomEndHomeExpenseRatioIOLoan,
  DtiBottomEndExpenseRatio,
  DtiTopFrontExpenseRatio,
  FhaAdditionalMonthlyPayForMipInsurance,
  FmFmacMonthlyPaymentForInsurance,
  InterestOnlyPayment,
  InterestRateOnNewSecondTd,
  MonthlyPaymentAmountNewSecondTd,
  MonthlyPaymentSavings,
  PaymentShockOnNewSecondLoanQualifying,
  PayOffDebtToQualifyBottomEndExpRatio,
  QualifyingArmPaymentPitiHoaMiIncluded,
  QualifyingPrincipalInterestTaxesInsuranceHoaAndMi,
  ReservesPositiveOrShortage,
  TopFrontEndHomeExpenseRatioIOLoan,
  TopFrontHomeExpenseRatio,
  TotalOfAllNewLoans,
  TotalReserveRequirement
} from "./calculating_cash_to_close_line_items";

export default function CalculatingCashToClose() {
  const [
    {
      loans,
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];
  const hasSecondLoanType = loans.some(loan =>
    SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)
  );
  const hasInterestOnly = loans.some(
    loan => loan.payment_type === INTEREST_ONLY
  );
  const hasArm = loans.some(loan => ARM_TERMS.includes(loan.term));

  return (
    <>
      <tbody className="loan-summaries">
        <tr>
          <td className="summary-header" colSpan="3">
            Calculating Cash to Close
          </td>
          <td className="left-align" colSpan="5">
            (Page 2 of Loan Estimates)
          </td>
        </tr>
        <tr>
          <td colSpan="3">Total Closing Costs (J)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_closing_costs(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Closing Costs Financed (Included in Loan Amount)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">
            <div className="indent">Down Payment/Funds from Borrower</div>
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={actual_cash_down_payment(documentType, loan, property)}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="bottom-border" colSpan="3">
            <div className="indent">Amount of 2nd Loan</div>
          </td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan_amount_second_mortgage(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            Total Down Pymt Cash To Lender
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_down_payment_cash_to_lender(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Deposit Made (Typical 3%)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={emd(documentType, loan, property).neg()}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Funds for Borrower</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan.funds_for_borrower}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Seller Credits (Reduces Closing Costs)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={seller_credits(documentType, loan, property)}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="bottom-border" colSpan="3">
            Adjustments and Other Credits
          </td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={adjustments_and_other_credits(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="bottom-border bold" colSpan="3">
            Estimated Cash to Close*
          </td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_cash_to_close(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property,
                    accountSummaries,
                    residences
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="8">
            (*After all Adjustments and Credits)
          </td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <TotalCostToPurchaseHome fiveLoans={fiveLoans} />
        <TotalOfLiquidReserves fiveLoans={fiveLoans} />
        <LiquidReservesAfterCloseOfEscrow fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <RetirementReserves fiveLoans={fiveLoans} />
        <TotalOfLiquidAndRetirementAccounts fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <TotalReserveRequirement fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <ReservesPositiveOrShortage fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td className="bold" colSpan="8">
            Rate and Payment Analysis:
          </td>
        </tr>
        <tr>
          <td colSpan="3">Interest Rate on NEW 1st TD</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField naked decimalPlaces={3} value={loan.rate} />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Monthly Payment Amount New 1st TD</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={first_mortgage_payment_p_and_i(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <InterestRateOnNewSecondTd fiveLoans={fiveLoans} />
        <MonthlyPaymentAmountNewSecondTd
          documentType={documentType}
          fiveLoans={fiveLoans}
          property={property}
        />
        <PaymentShockOnNewSecondLoanQualifying
          documentType={documentType}
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <FhaAdditionalMonthlyPayForMipInsurance
          fiveLoans={fiveLoans}
          property={property}
        />
        <FmFmacMonthlyPaymentForInsurance
          fiveLoans={fiveLoans}
          property={property}
        />
        <TotalOfAllNewLoans fiveLoans={fiveLoans} property={property} />
        <ActualPrincipalAndInterestMonthlyPayment
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <tr>
          <td colSpan="8" />
        </tr>
        <QualifyingPrincipalInterestTaxesInsuranceHoaAndMi
          fiveLoans={fiveLoans}
          property={property}
        />
        <ActualPitiHoaWithoutShock
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <TopFrontHomeExpenseRatio fiveLoans={fiveLoans} />
        <BottomEndDebtToIncomeRatio fiveLoans={fiveLoans} />
        <MonthlyPaymentSavings fiveLoans={fiveLoans} />
        <PayOffDebtToQualifyBottomEndExpRatio fiveLoans={fiveLoans} />
        <AmountOffDebtToPayOffToQualify fiveLoans={fiveLoans} />
        <InterestOnlyPayment
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <AmortizedCombinedPaymentIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <TopFrontEndHomeExpenseRatioIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <BottomEndHomeExpenseRatioIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <ArmTerms fiveLoans={fiveLoans} hasArm={hasArm} />
        <QualifyingArmPaymentPitiHoaMiIncluded
          fiveLoans={fiveLoans}
          hasArm={hasArm}
        />
        <DtiTopFrontExpenseRatio fiveLoans={fiveLoans} hasArm={hasArm} />
        <DtiBottomEndExpenseRatio fiveLoans={fiveLoans} hasArm={hasArm} />
        <tr>
          <td colSpan="8" />
        </tr>
      </tbody>
    </>
  );
}

// Total Cost To Purchase Home
function TotalCostToPurchaseHome({ fiveLoans }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_cost_to_purchase(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <>
        <tr>
          <td className="bold" colSpan="3">
            Total Cost To Purchase Property &gt; &gt; &gt;
          </td>
          {values.map((value, index) => (
            <td key={index}>
              {value && <CurrencyField naked fixedDigits={0} value={value} />}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="8">
            (Before any Adjustments or Credits){" "}
            <TrainingTip content="LANIS Training note: This is a very important number to understand because it may affect the Reserve requirement from the Lenders. If you are short of Reserves, you will need to advise the Buyer’s Agent or Borrower(s) that a seller credit will be needed or the Borrower(s) will need to have additional assets to qualify for the loan. A gift from a relative or converting retirement plans would require proof that funds are available for withdrawal. The paper trail will need to be detailed." />
          </td>
        </tr>
      </>
    );
  }
  return <></>;
}

// Total of Liquid Reserves
function TotalOfLiquidReserves({ fiveLoans }) {
  const [
    {
      applicant,
      accountSummaries,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_reserves_available(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="bold" colSpan="3">
          Total of Liquid Reserves &gt; &gt;
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Liquid Reserves After Close of Escrow
function LiquidReservesAfterCloseOfEscrow({ fiveLoans }) {
  const [
    {
      applicant,
      accountSummaries,
      residences,
      property_to_be_sold,
      property,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      liquid_reserves_after_close_of_escrow(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        property,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="bold" colSpan="3">
          Liquid Reserves After Close of Escrow &gt; &gt;{" "}
          <TrainingTip content="LANIS Training Note: If the loan is Borrower(s) paid and this is a Negative number, you have too much credit and the balance will typically be applied to a Principal reduction. Check with your Lender for the current Reduction Policies." />
        </td>
        {values.map((value, index) => (
          <td className="double-top-border" key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Retirement Reserves
function RetirementReserves({ fiveLoans }) {
  const [
    {
      applicant,
      accountSummaries,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      calculate_reserve_total(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        is_selling_property,
        TYPE_RETIREMENT
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Retirement Reserves</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Total of Liquid and Retirement Accounts
function TotalOfLiquidAndRetirementAccounts({ fiveLoans }) {
  const [
    {
      applicant,
      accountSummaries,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_of_liquid_and_retirement_accounts(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="bold" colSpan="3">
          Total of Liquid and Retirement Accounts
        </td>
        {values.map((value, index) => (
          <td className="top-border" key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}
