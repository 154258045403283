export const APPLICANT_INFO = "1";
export const NEW_PROPERTY = "2";
export const SELLING_PROP = "3";
export const INVEST_REO = "4";
export const DEBT_INFO = "5";
export const INCOME = "6";
export const RESERVES = "7";
export const LOAN_TYPE = "8";
export const LE_PRICE_ENG = "9";
export const LE_PRICE_ENG_OPT = "10";
export const DOWN_PAYMENT = "11";
export const CLOSING_COSTS_EST = "12";

// Refinance
export const SUBJECT_PROPERTY = "13";
export const CURRENT_PROP_INFO = "14";
export const LOAN_CONFIGURATOR = "15";

export const NAVIGATION_NAMES = Object.freeze({
  [APPLICANT_INFO]: "Applicant Info",
  [NEW_PROPERTY]: "New Property",
  [SELLING_PROP]: "Selling Prop",
  [INVEST_REO]: "Invest. REO",
  [DEBT_INFO]: "Debt Info",
  [INCOME]: "Income",
  [RESERVES]: "Reserves",
  [LOAN_TYPE]: "Loan Type",
  [LE_PRICE_ENG]: "LE Price Eng",
  [LE_PRICE_ENG_OPT]: "LE Price Eng Opt",
  [DOWN_PAYMENT]: "Down Payment",
  [CLOSING_COSTS_EST]: "Closing Costs Est",
  [SUBJECT_PROPERTY]: "Subject Property",
  [CURRENT_PROP_INFO]: "Current Prop Info",
  [LOAN_CONFIGURATOR]: "Loan Configurator"
});

export const PURCHASE_NAV = [
  APPLICANT_INFO,
  NEW_PROPERTY,
  SELLING_PROP,
  INVEST_REO,
  DEBT_INFO,
  INCOME,
  RESERVES,
  LOAN_TYPE,
  LE_PRICE_ENG,
  LE_PRICE_ENG_OPT,
  DOWN_PAYMENT,
  CLOSING_COSTS_EST
];

export const REFINANCE_NAV = [
  APPLICANT_INFO,
  SUBJECT_PROPERTY,
  CURRENT_PROP_INFO,
  INVEST_REO,
  DEBT_INFO,
  INCOME,
  RESERVES,
  LOAN_TYPE,
  LE_PRICE_ENG,
  LE_PRICE_ENG_OPT,
  LOAN_CONFIGURATOR,
  CLOSING_COSTS_EST
];
