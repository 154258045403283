export const INVESTMENT_PROPERTY = "0";
export const PRIMARY_RESIDENCE = "1";
export const SECOND_HOME = "2";
export const VACATION_HOME = "3";

export default Object.freeze({
  [INVESTMENT_PROPERTY]: "Investment property",
  [PRIMARY_RESIDENCE]: "Primary Residence",
  [SECOND_HOME]: "Second Home",
  [VACATION_HOME]: "Vacation Home"
});
