import React from "react";
import { total_loan_costs } from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../loan_tool";

export default function TotalLoanCosts({ loan, property }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  return (
    <div className="total-loan-costs">
      <div className="closing-costs-row gray-bg top-border">
        <div className="section-title">D. Total loan costs (A + B + C)</div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_loan_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
    </div>
  );
}
