import React, { useState } from "react";
import Decimal from "decimal.js";

import { YES } from "../../../constants/yes_no";
import LOAN_TYPES, {
  REFINANCE_LOAN_TYPES
} from "../../../constants/loan_types";
import PROPERTY_TYPES from "../../../constants/property_type";

import { lender_credit_cost } from "../../../calculations/loan";

import NumberField from "../../fields/number_field";
import DateField from "../../fields/date_field";
import TextField from "../../fields/text_field";
import PercentField from "../../fields/percent_field";

import LoanProcessorDataTable from "./loan_processor_data_table";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import NoPricingLockPeriod from "../../../pages/loan_tool/no_pricing_lock_period";
import { REFINANCE } from "../../../constants/document_types";

export default function LoanEstimatePriceEngineOptionsMainPage({
  showEditLoanOptions
}) {
  const [
    { applicant, loans, property, is_selling_property, property_to_be_sold },
    ,
    { documentType }
  ] = useDocument();
  const [showOriginationFeesCredits, setShowOriginationFeesCredits] = useState(
    false
  );

  const isRefinance = documentType === REFINANCE;

  function toggleOriginationFeesCredits() {
    setShowOriginationFeesCredits(!showOriginationFeesCredits);
  }

  function edit(e) {
    const index = new Decimal(e.target.dataset.index).toNumber();
    showEditLoanOptions(index);
  }

  return (
    <div className="le-price-engine-options-main">
      {loans.length > 0 && (
        <>
          <p>
            For Loans that are all the Same Type, Fill in the Interest Rate, and
            Base Loan Fee or Lender Credit in the Fields Below.
            <br />
            Use the Pricing tool below for Lender LLPA&#39;s and additional add
            on fees depending on your loan scenario.
          </p>
          <p>
            Note: A different Pricing Tool will appear for each Lender selected
            or if the structure of the loan is different; Cash Out vs. Rate and
            Term for example.
          </p>
          <p>
            {applicant.all_loan_types_the_same === YES
              ? "All Loan Types are the same"
              : "Loan Types are Different, check Lender specific Items and Fees."}
          </p>
          <NoPricingLockPeriod />
          <div className="le-price-engine-options-top">
            {applicant.pricing_lock_period && (
              <NumberField
                readOnly
                name="Pricing / Lock Period"
                value={applicant.pricing_lock_period}
              />
            )}
            {applicant.loan_locked === YES && (
              <DateField name="Lock Date" value={applicant.loan_lock_date} />
            )}
          </div>

          <Section className="le-price-engine-options-loans" name="Loans">
            <table>
              <thead>
                <tr>
                  <th>Loan #</th>
                  <th>Loan Type</th>
                  <th>Rate</th>
                  <th>Lender</th>
                  <th>Property Type</th>
                  <th>Broker/ Banker Fee</th>
                  <th>Lender Credit / Cost</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {loans.map((loan, index) => (
                  <tr key={index}>
                    <td>Loan {index + 1}</td>
                    <td>
                      <TextField
                        readOnly
                        value={
                          isRefinance
                            ? REFINANCE_LOAN_TYPES[loan.type]
                            : LOAN_TYPES[loan.type]
                        }
                      />
                    </td>
                    <td>
                      <PercentField
                        readOnly
                        decimalPlaces={3}
                        value={loan.rate}
                      />
                    </td>
                    <td>
                      <TextField readOnly value={loan.lender} />
                    </td>
                    <td>
                      <TextField
                        readOnly
                        value={PROPERTY_TYPES[loan.property_type]}
                      />
                    </td>
                    <td>
                      <PercentField
                        readOnly
                        value={loan.loan_options.broker_banker_fee}
                      />
                    </td>
                    <td>
                      <PercentField
                        readOnly
                        value={lender_credit_cost(
                          documentType,
                          loan,
                          property,
                          property_to_be_sold,
                          is_selling_property,
                          true
                        )}
                      />
                    </td>
                    <td>
                      <button
                        className="button"
                        data-index={index}
                        onClick={edit}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <button className="button" onClick={toggleOriginationFeesCredits}>
              Review All Origination Fees And Credits
            </button>
            {showOriginationFeesCredits && (
              <LoanProcessorDataTable
                loans={loans}
                property={property}
                is_selling_property={is_selling_property}
                property_to_be_sold={property_to_be_sold}
              />
            )}
          </Section>
        </>
      )}
    </div>
  );
}
