import React, { useState } from "react";
import { parseFullName } from "parse-full-name";
import TextField from "../fields/text_field";
import PhoneField from "../fields/phone_field";
import StripeCardCheckout from "../stripe/stripe_card_checkout";
import { is_valid_email } from "../../constants/utils";
import ErrorMessages from "../ui/error_messages";

export default function BasicSignupInformation({
  profile,
  onProfileChange,
  companyProfile,
  onCompanyProfileChange,
  password,
  onPasswordChange,
  onBeforeCreatePaymentMethod,
  paymentName,
  setPaymentName,
  promoCode,
  setPromoCode,
  onSubmit,
  isPaymentSkipped,
  paymentErrors,
  setPaymentErrors
}) {
  const [fullName, setFullName] = useState(() => {
    return [profile.first_name, profile.middle_name, profile.last_name].join(" ").trim()
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});

  function validateEmail() {
    const messages = { ...errorMessages };
    delete messages.email;
    delete messages.confirmEmail;
    if (!is_valid_email(profile.email)) {
      messages.email = "Invalid Email format.";
    }
    setErrorMessages(messages);
  }

  function onFullNameChange(fullName) {
    setFullName(fullName);

    const profileUpdates = {
      full_name: fullName
    };

    // If there is just 1 word with no spaces, assume it is
    // just the first name. Otherwise, the parseFullName
    // library assumes it to be the last name.
    if (fullName.indexOf(" ") === -1) {
      profileUpdates.first_name = fullName;
    } else {
      const nameParts = parseFullName(fullName);
      if (nameParts.first) {
        profileUpdates.first_name = nameParts.first;
      }
      if (nameParts.middle) {
        profileUpdates.middle_name = nameParts.middle;
      }
      if (nameParts.last) {
        profileUpdates.last_name = nameParts.last;
      }
    }

    onProfileChange(profileUpdates);
  }

  function validatePassword() {
    const messages = { ...errorMessages };
    delete messages.confirmPassword;
    if (password !== confirmPassword) {
      messages.confirmPassword = "Password does not match Confirm Password.";
    }
    setErrorMessages(messages);
  }

  function isValid() {
    return (
      profile.email &&
      profile.cell_phone &&
      password &&
      Object.keys(errorMessages).length === 0
    );
  }

  return (
    <div className="signup-basic-info">
      <div className="error-messages">
        {Object.entries(errorMessages).map(([key, value]) => (
          <div key={key}>{value}</div>
        ))}
      </div>
      <div className="signup-basic-info-group">
        <h4>Step #1 Contact Details</h4>
        <div>
          <TextField
            fieldName="full_name"
            placeholder="Full Name"
            value={fullName}
            onChange={value => onFullNameChange(value.full_name)}
          />
          <TextField
            fieldName="email"
            typeOverride="email"
            placeholder="Email"
            value={profile.email}
            onChange={onProfileChange}
            onBlur={validateEmail}
          />
          <PhoneField
            fieldName="cell_phone"
            placeholder="Phone"
            value={profile.cell_phone}
            onChange={onProfileChange}
          />
        </div>
      </div>
      <div className="signup-basic-info-group">
        <h4>Step #2 Choose a Password</h4>
        <div>
          <TextField
            fieldName="password"
            typeOverride="password"
            placeholder="Password"
            value={password}
            onChange={onPasswordChange}
            onBlur={validatePassword}
          />
          <TextField
            typeOverride="password"
            fieldName="confirm_password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={value => setConfirmPassword(value.confirm_password)}
            onBlur={validatePassword}
          />
        </div>
      </div>
      <div className="signup-basic-info-group">
        <h4>Step #3 LO Office / Billing Address</h4>
        <div>
          <TextField
            fieldName="business_address"
            placeholder="Street Address"
            value={companyProfile.business_address}
            onChange={onCompanyProfileChange}
          />
          <TextField
            fieldName="city"
            placeholder="City"
            value={companyProfile.city}
            onChange={onCompanyProfileChange}
          />
          <div className="billing-address-bottom-row">
            <TextField
              fieldName="state"
              placeholder="State"
              value={companyProfile.state}
              onChange={onCompanyProfileChange}
            />
            <TextField
              fieldName="zip"
              placeholder="Zip Code"
              value={companyProfile.zip}
              onChange={onCompanyProfileChange}
            />
          </div>
        </div>
      </div>
      <div className="signup-basic-info-group">
        <h4>Step #4 Credit Card Information</h4>
        <div>
          <ErrorMessages messages={paymentErrors}/>
          <StripeCardCheckout
            onBeforeCreatePaymentMethod={onBeforeCreatePaymentMethod}
            paymentName={paymentName}
            setPaymentName={setPaymentName}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            onSubmit={onSubmit}
            isPaymentSkipped={isPaymentSkipped}
            onError={error => setPaymentErrors([error.message])}
            profile={profile}
            disabled={!isValid()}
          />
        </div>
      </div>
    </div>
  );
}
