import React, { useState } from "react";
import "./css/income.scss";

import BorrowerIncomeTable from "../../components/income/borrower_income_table";
import EditBorrowerIncome from "../../components/income/edit_borrower_income";
import EditAdditionalIncome from "../../components/income/edit_additional_income";
import EditEmploymentInformation from "../../components/income/edit_employment_information";

import StandardTemplate from "../../templates/standard_template/standard_template";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { INCOME } from "../../constants/navigations";
import NoBorrowers from "./no_borrowers";

export default function IncomePage() {
  const [document, setDocument] = useDocument();
  const [activeView, setActiveView] = useState("BORROWER_TABLE");
  const [additionalIncomeIndex, setAdditionalIncomeIndex] = useState();
  const [incomeSourceIndex, setIncomeSourceIndex] = useState();
  const [borrowerIndex, setBorrowerIndex] = useState(0);

  function showEditIncomeSource(borrowerIndex, incomeSourceIndex) {
    setActiveView("EDIT_INCOME_SOURCE");
    setBorrowerIndex(borrowerIndex);
    setIncomeSourceIndex(incomeSourceIndex);
  }

  function showBorrowerTable() {
    setActiveView("BORROWER_TABLE");
    setBorrowerIndex(null);
    setAdditionalIncomeIndex(null);
    setIncomeSourceIndex(null);
  }

  function showEditAdditionalIncome(borrowerIndex, additionalIncomeIndex) {
    setActiveView("EDIT_ADDITIONAL_INCOME");
    setBorrowerIndex(borrowerIndex);
    setAdditionalIncomeIndex(additionalIncomeIndex);
  }

  function showEmploymentInformation() {
    setActiveView("EMPLOYMENT_INFORMATION");
  }

  function onChange(updatedIncomeState, borrowerIndexOverride) {
    const bIndex = Number.isInteger(borrowerIndexOverride)
      ? borrowerIndexOverride
      : borrowerIndex;
    const borrowers = [...document.applicant.borrowers];
    const updatedBorrower = { ...borrowers[bIndex] };
    updatedBorrower.income = {
      ...updatedBorrower.income,
      ...updatedIncomeState
    };
    borrowers[bIndex] = updatedBorrower;
    const applicant = { ...document.applicant, borrowers };
    setDocument({ ...document, applicant });
  }

  const { borrowers } = document.applicant;
  const borrower = borrowers[borrowerIndex];
  return (
    <StandardTemplate
      active={INCOME}
      isDisableNavSave={activeView !== "BORROWER_TABLE"}
    >
      <NoBorrowers />

      <div className="income">
        {activeView === "BORROWER_TABLE" && (
          <BorrowerIncomeTable
            showEditIncomeSource={showEditIncomeSource}
            showEditAdditionalIncome={showEditAdditionalIncome}
            onChangeBorrowerIncome={onChange}
          />
        )}

        {activeView === "EDIT_INCOME_SOURCE" && (
          <EditBorrowerIncome
            borrowerIndex={borrowerIndex}
            borrower={borrower}
            incomeSource={borrower.income.sources[incomeSourceIndex]}
            incomeSourceIndex={incomeSourceIndex}
            showBorrowerTable={showBorrowerTable}
            showEmploymentInformation={showEmploymentInformation}
            onChangeBorrowerIncome={onChange}
            backToTable={showBorrowerTable}
          />
        )}

        {activeView === "EMPLOYMENT_INFORMATION" && (
          <EditEmploymentInformation
            borrower={borrowers[borrowerIndex]}
            employmentInformation={
              borrower.income.sources[incomeSourceIndex].employment_information
            }
            incomeSourceIndex={incomeSourceIndex}
            onChangeBorrowerIncome={onChange}
            backToTable={showBorrowerTable}
          />
        )}

        {activeView === "EDIT_ADDITIONAL_INCOME" && (
          <EditAdditionalIncome
            borrowerIndex={borrowerIndex}
            borrower={borrower}
            editedEntryIndex={additionalIncomeIndex}
            incomeSource={borrower.income.other_income[additionalIncomeIndex]}
            onChangeBorrowerIncome={onChange}
            backToTable={showBorrowerTable}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
