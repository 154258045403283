import React from "react";

import TextField from "../fields/text_field";
import PhoneField from "../fields/phone_field";

export default function UserProfile({
  profile,
  onProfileChange,
  emailReadOnly,
  password,
  onPasswordChange,
  hidePassword,
  isNewUser
}) {
  return (
    <>
      <TextField
        typeOverride="email"
        readOnly={emailReadOnly}
        fieldName="email"
        name="Email"
        value={profile.email}
        onChange={onProfileChange}
      />
      {!hidePassword && (
        <TextField
          typeOverride="password"
          fieldName="password"
          name="Password"
          value={password}
          placeholder={!isNewUser && "Keep Current Password"}
          onChange={value => onPasswordChange(value.password)}
        />
      )}
      <TextField
        fieldName="first_name"
        name="First Name"
        value={profile.first_name}
        placeholder="First Name"
        onChange={onProfileChange}
      />
      <TextField
        fieldName="middle_name"
        name="Middle Name"
        value={profile.middle_name}
        placeholder="Middle Name"
        onChange={onProfileChange}
      />
      <TextField
        fieldName="last_name"
        name="Last Name"
        value={profile.last_name}
        placeholder="Last Name"
        onChange={onProfileChange}
      />
      <TextField
        fieldName="nmls_number"
        name="NMLS #"
        value={profile.nmls_number}
        onChange={onProfileChange}
      />
      <TextField
        fieldName="state_real_estate_license"
        name="State Real Estate or State License"
        value={profile.state_real_estate_license}
        onChange={onProfileChange}
      />
      <PhoneField
        fieldName="cell_phone"
        name="Cell Phone"
        value={profile.cell_phone}
        onChange={onProfileChange}
      />
      <PhoneField
        fieldName="direct_contact_phone"
        name="Direct Contact Phone"
        value={profile.direct_contact_phone}
        onChange={onProfileChange}
      />
      <PhoneField
        fieldName="office_phone"
        name="Office Phone"
        value={profile.office_phone}
        onChange={onProfileChange}
      />
      <TextField
        fieldName="loan_origination_software_used"
        name="Loan Origination Software (LOS) used"
        value={profile.loan_origination_software_used}
        onChange={onProfileChange}
      />
    </>
  );
}
