import config from "../config/config.js";
import {
  getAccessToken,
  getHeaders,
  handleResponse,
  removeAccessToken,
  setAccessToken
} from "./utils";

const UserService = {
  /**
   * @returns {boolean} Whether the user is logged in
   */
  isLoggedIn() {
    return !!getAccessToken();
  },

  /**
   * Attempts to log in a user.
   * @param   {string} username The username
   * @param   {string} password The password
   * @returns {Promise<boolean>} A promise that is fulfilled with the value of whether
   *          the user was successfully logged in.
   */
  async login(username, password) {
    const data = new FormData();
    data.append("lhuser", username);
    data.append("lhpass", password);
    const response = await fetch(config.serviceHost + "login", {
      method: "POST",
      headers: new Headers({
        Accept: "application/json"
      }),
      body: data
    });
    const json = await response.json();

    if (json["access_token"]) {
      setAccessToken(json["access_token"]);
      return true;
    }

    return false;
  },

  /**
   * Attempts to log the currently logged-in user out.
   * Note: In reality, simply removing the 'access_token' key from localstorage
   * will successfully log out a user, but there is bookkeeping on the server
   * that is helped (though, not strictly necessary) by this call.
   * @returns {Promise<object>} A promise that is fulfilled with the response
   *          from whether the user was successfully logged out.
   */
  async logout() {
    const headers = getHeaders();
    await fetch(config.serviceHost + "logout", {
      method: "POST",
      headers
    });

    removeAccessToken();
  },

  /**
   * Gets the currently logged in user's details.
   * @returns {Promise<Object>} A promise that is fulfilled with the details of
   *          the currently logged in user.
   */
  async getUser() {
    let resp = await fetch(config.serviceHost + "user", {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + getAccessToken()
      })
    });

    return await resp.json();
  },

  /**
   * Attempts to update the password of the currently logged in user.
   * @param   {string} currentPassword The current password for this user
   * @param   {string} newPassword The proposed password
   * @param   {string} repeatPassword The new password repeated
   * @returns {Promise<object>} A promise that is fulfilled with the response
   *          from the password update attempt
   */
  async updatePassword(currentPassword, newPassword, repeatPassword) {
    let data = new URLSearchParams();
    data.set("currentPassword", currentPassword);
    data.set("newPassword", newPassword);
    data.set("repeatPassword", repeatPassword);

    let resp = await fetch(config.serviceHost + "user/updatePassword", {
      method: "PUT",
      headers: new Headers({
        Accept: "application/json",
        Authorization: "Bearer " + getAccessToken()
      }),
      body: data
    });

    return await resp.json();
  },

  async postPasswordResetRequest(username) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("un", username);
    const response = await fetch(config.serviceHost + "password-reset", {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async postPasswordReset(username, ticket, newPassword, confirmPassword) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("un", username);
    data.append("vt", ticket);
    data.append("newpw", newPassword);
    data.append("confirmpw", confirmPassword);
    const response = await fetch(config.serviceHost + "password-reset/auth", {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async getProfile() {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + "user/profile", {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async postUserProfile(userProfile) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("firstName", userProfile.first_name);
    data.append("lastName", userProfile.last_name);
    data.append("content", JSON.stringify(userProfile));
    const response = await fetch(config.serviceHost + "user/user-profile", {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async postCompanyProfile(companyProfile, companyLogo) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("content", JSON.stringify(companyProfile));
    if (companyLogo) {
      data.append("companyLogo", companyLogo);
    }
    const response = await fetch(
      config.serviceHost + "company-admin/company-profile",
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  },

  async postRegistration(
    profile,
    password,
    companyProfile,
    product,
    paymentName,
    paymentEmail,
    paymentPhone,
    promoCode,
    paymentMethod
  ) {
    const data = new FormData();
    data.append("email", profile.email);
    data.append("password", password);
    data.append("firstName", profile.first_name);
    data.append("lastName", profile.last_name);
    data.append("profile", JSON.stringify(profile));
    data.append("companyProfile", JSON.stringify(companyProfile));
    data.append("product", product);
    data.append("subscriptionName", paymentName);
    data.append("subscriptionEmail", paymentEmail);
    data.append("subscriptionPhone", paymentPhone);
    data.append("promoCode", promoCode);
    data.append("paymentMethod", paymentMethod);
    const response = await fetch(config.serviceHost + "registration/register", {
      method: "POST",
      headers: new Headers({
        Accept: "application/json"
      }),
      body: data
    });
    return handleResponse(response);
  },

  async isPaymentSkipped(
    promoCode
  ) {
    const headers = getHeaders();
    const params = new URLSearchParams();
    params.append("promoCode", promoCode);
    const url = new URL(config.serviceHost + "registration/payment-skipped");
    url.search =  params.toString();
    const response = await fetch(url, {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async getRegistrationReceipt() {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + "registration/receipt", {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async getCompanyUsers() {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + "company-admin/users", {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async getCompanyUser(userId) {
    const headers = getHeaders();
    const response = await fetch(
      config.serviceHost + `company-admin/user/${userId}`,
      {
        method: "GET",
        headers
      }
    );
    return handleResponse(response);
  },

  async postCompanyUser(userId, email, password, profile) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    data.append("firstName", profile.first_name);
    data.append("lastName", profile.last_name);
    data.append("profile", JSON.stringify(profile));
    const response = await fetch(
      config.serviceHost + `company-admin/user/${userId}`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  },

  async deleteCompanyUser(userId) {
    const headers = getHeaders();
    const response = await fetch(
      config.serviceHost + `company-admin/user/${userId}`,
      {
        method: "DELETE",
        headers
      }
    );
    return handleResponse(response);
  },

  async postCompanyNewUser(
    role,
    mainUserId,
    email,
    password,
    firstName,
    lastName,
    profile
  ) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("role", role);
    data.append("mainUserId", mainUserId);
    data.append("email", email);
    data.append("password", password);
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("profile", JSON.stringify(profile));
    const response = await fetch(
      config.serviceHost + `company-admin/new-user`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  },

  async postUnsubscribe() {
    const headers = getHeaders();
    const response = await fetch(
      config.serviceHost + `company-admin/unsubscribe`,
      {
        method: "POST",
        headers
      }
    );
    return handleResponse(response);
  },

  async postSubscribe(
    product,
    paymentName,
    paymentEmail,
    paymentPhone,
    paymentMethod,
    promoCode
  ) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("product", product);
    data.append("subscriptionName", paymentName);
    data.append("subscriptionEmail", paymentEmail);
    data.append("subscriptionPhone", paymentPhone);
    data.append("paymentMethod", paymentMethod);
    data.append("promoCode", promoCode);
    const response = await fetch(
      config.serviceHost + `company-admin/subscribe`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  }
};

export default UserService;
