export const TWO_FOUR_PLEX = "1";
export const COM_NR = "2";
export const COM_R = "3";
export const CONDO = "4";
export const COOP = "5";
export const FARM = "6";
export const LAND = "7";
export const MIXED = "8";
export const MOBIL = "9";
export const MULTI = "10";
export const SFR = "11";
export const TOWN = "12";

export default Object.freeze({
  [TWO_FOUR_PLEX]: "2-4PLEX",
  [COM_NR]: "COM-NR",
  [COM_R]: "COM-R",
  [CONDO]: "CONDO",
  [COOP]: "COOP",
  [FARM]: "FARM",
  [LAND]: "LAND",
  [MIXED]: "MIXED",
  [MOBIL]: "MOBIL",
  [MULTI]: "MULTI",
  [SFR]: "SFR",
  [TOWN]: "TOWN"
});
