import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { format_currency } from "../constants/format";
import { Link } from "@reach/router";
import UserService from "../services/user_service";
import StandardTemplate from "../templates/standard_template/standard_template";
import Spinner from "../components/ui/spinner";
import ErrorMessages from "../components/ui/error_messages";
import { handleErrorMessages } from "../services/utils";
import "./css/sign_up.scss";

function formatCurrency(cents) {
  return format_currency((cents / 100).toString(), {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

function formatDate(epochSeconds, format = "LLL") {
  return moment(new Date(epochSeconds * 1000)).format(format);
}

function formatCardBrand(brand, last4) {
  // If the brand doesn't contain any uppercase, assume it's a lower case string,
  // and we need to capitalize each word.
  if (!brand.match(/[A-Z]/)) {
    brand = brand.split(/[\s-_]+/)
      .map(str => str.charAt(0).toUpperCase() + str.slice(1))
      .join(" ");
  }

  return `${brand} - ${last4}`;
}

function SummaryTable({
  subscription,
  upcomingInvoice,
  paymentMethod
}) {
  // Use this latest invoice, unless he user is now in a trial. If they're in a trial,
  // the latest invoice shows a $0 charge for the trial period, but we want to show
  // actual payment info, so use the upcoming invoice if that is the case.
  const invoice = subscription.status === "trialing" ? upcomingInvoice : subscription.latest_invoice;

  let discountAmountFormatted = 0;
  if (invoice.discount) {
    if (invoice.total_discount_amounts) {
      for (const discountInfo of invoice.total_discount_amounts) {
        if (discountInfo.discount === invoice.discount.id) {
          discountAmountFormatted = "-" + formatCurrency(discountInfo.amount);
          break;
        }
      }
    }

    if (discountAmountFormatted === 0 && invoice.discount.coupon) {
      discountAmountFormatted = "-" + invoice.discount.coupon.percent_off + "%";
    }
  }

  const lineItem = invoice.lines.data[0];

  return (
    <table className="signup-receipt-payment-table">
      <thead>
        <tr>
          <th>AMOUNT PAID</th>
          <th>DATE PAID</th>
          <th>PAYMENT METHOD</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {formatCurrency(subscription.latest_invoice.amount_paid)}
          </td>
          <td>
            {formatDate(subscription.latest_invoice.created)}
          </td>
          <td>
            {paymentMethod ?
              formatCardBrand(paymentMethod.card.brand, paymentMethod.card.last4)
              : "Unknown"}
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th colSpan="3">SUMMARY</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan="2">
            {lineItem.description}
          </td>
          <td>
            {formatCurrency(lineItem.amount)}
          </td>
        </tr>
        {subscription.status === "trialing" && (
          <tr>
            <td colSpan="3">
              Free trial:{" "}
              {formatDate(subscription.trial_start, "LL")}
              {" - "}
              {formatDate(subscription.trial_end, "LL")}
            </td>
          </tr>
        )}
        <tr>
          <td colSpan="3">
            Payment period:{" "}
            {formatDate(lineItem.period.start, "LL")}
            {" - "}
            {formatDate(lineItem.period.end, "LL")}
          </td>
        </tr>
        <tr>
          <td colSpan="2">
            Subtotal
          </td>
          <td>
            {formatCurrency(invoice.subtotal)}
          </td>
        </tr>
        {invoice.discount && (
          <tr>
            <td colSpan="2">
              PROMO - {invoice.discount.coupon.name}
            </td>
            <td>
              {discountAmountFormatted}
            </td>
          </tr>
        )}
        <tr>
          <th colSpan="2">
            Total
          </th>
          <td>
            {formatCurrency(invoice.total)}
          </td>
        </tr>
        {invoice.next_payment_attempt && (
          <tr>
            <td colSpan="3">
              To be billied on <strong>{formatDate(invoice.next_payment_attempt)}</strong>.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default function SignUpReceipt() {
  const [showSpinner, setShowSpinner] = useState(true);
  const [errorMessages, setErrorMessages] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [upcomingInvoice, setUpcomingInvoice] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    UserService.getRegistrationReceipt().then(response => {
      setSubscription(response.subscription);
      setUpcomingInvoice(response.upcomingInvoice);
      setPaymentMethod(response.paymentMethod);
      setShowSpinner(false);
    }).catch(error => {
      handleErrorMessages(error, setErrorMessages);
      setShowSpinner(false);
    });
  }, []);

  return (
    <StandardTemplate basic={true} className="sign-up">
      <div className="main-title">Thank You for your Purchase!</div>
      <ErrorMessages messages={errorMessages} />
      {showSpinner && <Spinner/>}
      {(subscription && upcomingInvoice && paymentMethod) && (
        <div className="sign-up-receipt">
          <h4>Receipt from The LANIS Group, LLC</h4>
          <p>
            Invoice # {subscription.latest_invoice.number}
          </p>
          <p>
            <a
              href={subscription.latest_invoice.hosted_invoice_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              View invoice on Stripe
            </a>
          </p>
          <SummaryTable
            subscription={subscription}
            upcomingInvoice={upcomingInvoice}
            paymentMethod={paymentMethod}
          />
          <p>
            If you have any questions, contact us at <a href="mailto:sales@golanis.com">sales@golanis.com</a> or
            call at <a href="tel:1-805-994-0744">+1 805-994-0744</a> extension 205.
          </p>
          <p>
            Click the Button Below to finish setting up your account and start using LANIS TIER|ONE
          </p>
          <p>
            <Link to="/sign-up/profile" className="button">New Member Profile</Link>
          </p>
        </div>
      )}
    </StandardTemplate>
  );
}
