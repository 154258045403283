import * as RESERVES_ACCOUNT_TYPES from "../constants/reserves_account_types";
import * as MARITAL_STATUS from "../constants/marital_status";
import * as ADDITIONAL_INCOME_TYPES from "../constants/additional_income_types";
import * as RENTING_OR_OWNING from "../constants/renting_or_owning";
import * as LIABILITY_TYPE from "../constants/liability_types";
import * as LOAN_TYPES from "../constants/loan_types";
import * as LOAN_TERMS from "../constants/loan_terms";
import * as DOCUMENT_TYPES from "../constants/document_types";
import * as PROPERTY_CLASSIFICATION from "../constants/property_classification";
import * as PURPOSE_OF_REFINANCE from "../constants/purpose_of_refinance";
import * as PROPERTY_TYPE from "../constants/property_type";
import * as REO_PROPERTY_TYPE from "../constants/reo_property_type";
import * as REFINANCE_LOAN_PURPOSES from "../constants/loan_purpose";

export const FNM = {
  ASSET_TYPE: {
    CHECKING_ACCOUNT: "03",
    CASH_DEPOSIT_ON_SALES_CONTRACT: "F1",
    SAVINGS_ACCOUNT: "SG",
    GIFT: "F2",
    CERTIFICATE_OF_DEPOSIT: "01",
    MONEY_MARKET_FUND: "F3",
    MUTUAL_FUNDS: "F4",
    STOCK: "05",
    BOND: "06",
    SECURED_BORROWED_FUNDS: "F5",
    BRIDGE_LOAN: "F7",
    RETIREMENT_FUNDS: "08",
    NET_WORTH_OF_BUSINESS_OWNED: "F8",
    TRUST_FUNDS: "11",
    OTHER_NON_LIQUID_ASSET: "M1",
    OTHER_LIQUID_ASSET: "OL",
    NET_EQUITY: "NE",
    CASH_ON_HAND: "COH",
    GIFT_OF_EQUITY: "GE",
  },
  MARITAL_STATUS: {
    MARRIED: "M",
    SEPARATED: "S",
    UNMARRIED: "U",
  },
  INCOME_CODE: {
    MILITARY_BASE_PAY: "F1",
    MILITARY_RATIONS_ALLOWANCE: "07",
    MILITARY_FLIGHT_PAY: "F2",
    MILITARY_HAZARD_PAY: "F3",
    MILITARY_CLOTHES_ALLOWANCE: "02",
    MILITARY_QUARTERS_ALLOWANCE: "04",
    MILITARY_PROP_PAY: "03",
    MILITARY_OVERSEAS_PAY: "F4",
    MILITARY_COMBAT_PAY: "F5",
    MILITARY_VARIABLE_HOUSING_ALLOWANCE: "F6",
    ALIMONY_CHILD_SUPPORT_INCOME: "F7",
    NOTES_RECEIVABLE_INSTALLMENT: "F8",
    PENSION_RETIREMENT_INCOME: "41",
    SOCIAL_SECURITY_DISABILITY_INCOME: "42",
    REAL_ESTATE_MORTGAGE_DIFFERENTIAL: "30",
    TRUST_INCOME: "F9",
    ACCESSORY_UNIT_INCOME: "AU",
    UNEMPLOYMENT_PUBLIC_ASSISTANCE: "M1",
    AUTOMOBILE_EXPENSE_ACCOUNT_INCOME: "M2",
    FOSTER_CARE: "M3",
    VA_BENEFITS_NON_EDUCATION: "M4",
    NON_BORROWER_HOUSEHOLD_INCOME: "NB",
    OTHER_INCOME: "45",
    BASE_EMPLOYMENT_INCOME: "20",
    OVERTIME: "09",
    BONUSES: "08",
    COMMISSIONS: "10",
    DIVIDENDS_INTEREST: "17",
    SUBJECT_PROPERTY_NET_CASH_FLOW: "SI",
    HOUSING_CHOICE_VOUCHER: "S8",
    NET_RENTAL_INCOME: "33",
    BOARDER_INCOME: "BI",
    MORTGAGE_CREDIT_CERTIFICATE: "MC",
    CAPITAL_GAINS: "CG",
    EMPLOYMENT_RELATED_ASSETS: "EA",
    FOREIGN_INCOME: "FI",
    ROYALTY_PAYMENT: "RP",
    SEASONAL_INCOME: "SE",
    TEMPORARY_LEAVE: "TL",
    TIP_INCOME: "TI",
  },
  PRESENT_FORMER_CODE: {
    FORMER_RESIDENCE: "F4",
    PRESENT_ADDRESS: "ZG",
    MAILING_ADDRESS: "BH"
  },
  OWN_RENT_LIVING_RENT_FREE: {
    LIVING_RENT_FREE: "X",
    RENT: "R",
    OWN: "O",
  },
  LIABILITY_TYPE: {
    INSTALLMENT_LOAN: "I",
    THIRTY_DAY_CHARGE_ACCOUNT_OPEN: "O",
    REVOLVING_CHARGE_ACCOUNT: "R",
    HELOC: "C",
    MORTGAGE: "M",
    LEASE_PAYMENTS: "F",
    LIENS: "N",
    TAXES: "A",
    OTHER_LIABILITY: "Z",
  },
  EXPENSE_TYPE: {
    ALIMONY: "DR",
    CHILD_SUPPORT: "DT",
    SEPARATE_MAINTENANCE_PAYMENT: "DV",
    JOB_RELATED_EXPENSE: "DZ",
    OTHER_EXPENSE_TYPE: "EE",
  },
  OTHER_CREDIT_TYPE: {
    CASH_DEPOSIT_ON_SALES_CONTRACT: "01",
    SELLER_CREDIT: "02",
    LENDER_CREDIT: "03",
    RELOCATION_FUNDS: "04",
    EMPLOYER_ASSISTED_HOUSING: "05",
    LEASE_PURCHASE_FUND: "06",
    OTHER: "07",
    BORROWER_PAID_FEES: "08",
    SWEAT_EQUITY: "09",
  },
  LOAN_TYPES: {
    CONVENTIONAL: "01",
    VA: "02",
    FHA: "03",
    USDA_OR_RURAL_HOUSING_SERVICE: "04",
    OTHER: "07"
  },
  AMORTIZATION_TYPES: {
    ADJUSTABLE_RATE: "01",
    GEM: "04",
    FIXED_RATE: "05",
    GPM: "06",
    OTHER: "13",
  },
  PURPOSE_OF_LOAN: {
    CONSTRUCTION: "04",
    REFINANCE: "05",
    CONSTRUCTION_PERM: "13",
    OTHER: "15",
    PURCHASE: "16",
  },
  TYPE_OF_RESIDENCE: {
    PRIMARY_RESIDENCE: "1",
    SECONDARY_RESIDENCE: "2",
    INVESTMENT_PROPERTY: "D",
  },
  PURPOSE_OF_REFINANCE: {
    NO_CASH_OUT: "F1",
    CASH_OUT_OTHER: "01",
    CASH_OUT_OR_HOME_IMPROVEMENT: "04",
    CASH_OUT_OR_DEBT_CONSOLIDATION: "11",
    LIMITED_CASH_OUT: "13",
  },
  PRESENT_PROPOSED_INDICATOR: {
    PRESENT_HOUSING_EXPENSE: "1",
    PROPOSED_HOUSING_EXPENSE: "2"
  },
  HOUSING_EXPENSE_TYPE: {
    RENT: "25",
    FIRST_MORTGAGE_P_AND_I: "26",
    OTHER_FINANCING_P_AND_I: "22",
    HAZARD_INSURANCE: "01",
    REAL_ESTATE_TAXES: "14",
    MORTGAGE_INSURANCE: "02",
    HOMEOWNER_ASSOCIATION_DUES: "06",
    OTHER: "23"
  },
  TYPE_OF_PROPERTY: {
    SINGLE_FAMILY: "14",
    CONDOMINIUM: "04",
    TOWNHOUSE: "16",
    CO_OPERATIVE: "13",
    TWO_TO_FOUR_UNIT_PROPERTY: "15",
    MULTIFAMILY: "18",
    MANUFACTURED_OR_MOBILE_HOME: "08",
    COMMERCIAL_NON_RESIDENTIAL: "02",
    MIXED_USE_RESIDENTIAL: "F1",
    FARM: "05",
    HOME_AND_BUSINESS_COMBINED: "03",
    LAND: "07",
  },
  SUBJECT_PROPERTY_TYPE_CODE: {
    DETACHED: "01",
    ATTACHED: "02",
    CONDOMINIUM: "03",
    PLANNED_UNIT_DEVELOPMENT_PUD: "04",
    CO_OPERATIVE_CO_OP: "05",
    HIGH_RISE_CONDO: "07",
    MANUFACTURED_HOME: "08",
    DETACHED_CONDO: "09",
    MANUFACTURED_HOME_CONDO_OR_PUD_OR_CO_OP: "10",
    MANUFACTURED_HOME_MH_ADVANTAGE: "11",
  },
  PROPERTY_DISPOSITION: {
    SOLD: "S",
    RETAINED: "H",
    PENDING_SALE: "P",
    RENTAL: "R",
  },
  LEGAL_DESCRIPTION_OF_SUBJECT_PROPERTY: {
    OTHER: "F1",
    METES_AND_BOUNDS: "02",
  },
  ESTATE_WILL_BE_HELD_IN: {
    FEE_SIMPLE: "1",
    LEASEHOLD: "2",
  },
  OWNER_OF_EXISTING_MORTGAGE: {
    FANNIE_MAE: "01",
    FREDDIE_MAC: "02",
    SELLER_OTHER: "03",
    UNKNOWN: "F1",
  },
  ACTUAL_VS_ESTIMATED_APPRAISED_INDICATOR: {
    ACTUAL: "01",
    ESTIMATED: "02",
  },
  LIEN_TYPE_CODE: {
    FIRST_MORTGAGE: "1",
    SECOND_MORTGAGE: "2",
    OTHER_MORTGAGE: "F",
  },
  APPLICANT_CO_APPLICANT_INDICATOR: {
    APPLICANT: "BW",
    CO_APPLICANT: "QZ",
  },
  APPLICATION_TAKEN_BY: {
    FACE_TO_FACE: "F",
    MAIL_OR_FAX: "M",
    TELEPHONE: "T",
    INTERNET_OR_EMAIL: "I",
  }
};

const FNM_ASSET_TYPE_TO_LANIS_ACCOUNT_SUB_TYPE = {
  [FNM.ASSET_TYPE.CHECKING_ACCOUNT]: RESERVES_ACCOUNT_TYPES.CHECKING_SAVINGS,
  [FNM.ASSET_TYPE.CASH_DEPOSIT_ON_SALES_CONTRACT]: RESERVES_ACCOUNT_TYPES.DEPOSIT_ON_SALES_CONTRACT,
  [FNM.ASSET_TYPE.SAVINGS_ACCOUNT]: RESERVES_ACCOUNT_TYPES.CHECKING_SAVINGS,
  [FNM.ASSET_TYPE.GIFT]: RESERVES_ACCOUNT_TYPES.GIFT_SOURCE_N_A,
  [FNM.ASSET_TYPE.CERTIFICATE_OF_DEPOSIT]: RESERVES_ACCOUNT_TYPES.CHECKING_SAVINGS,
  [FNM.ASSET_TYPE.MONEY_MARKET_FUND]: RESERVES_ACCOUNT_TYPES.STOCKS_BONDS,
  [FNM.ASSET_TYPE.MUTUAL_FUNDS]: RESERVES_ACCOUNT_TYPES.STOCKS_BONDS,
  [FNM.ASSET_TYPE.STOCK]: RESERVES_ACCOUNT_TYPES.STOCKS_BONDS,
  [FNM.ASSET_TYPE.BOND]: RESERVES_ACCOUNT_TYPES.STOCKS_BONDS,
  [FNM.ASSET_TYPE.SECURED_BORROWED_FUNDS]: RESERVES_ACCOUNT_TYPES.SECURED_BORROWED_FUNDS,
  [FNM.ASSET_TYPE.BRIDGE_LOAN]: RESERVES_ACCOUNT_TYPES.BRIDGE_LOAN,
  [FNM.ASSET_TYPE.RETIREMENT_FUNDS]: RESERVES_ACCOUNT_TYPES.RETIREMENT_FUNDS,
  [FNM.ASSET_TYPE.NET_WORTH_OF_BUSINESS_OWNED]: RESERVES_ACCOUNT_TYPES.TRADE_EQUITY,
  [FNM.ASSET_TYPE.TRUST_FUNDS]: RESERVES_ACCOUNT_TYPES.TRUST_FUNDS,
  [FNM.ASSET_TYPE.OTHER_NON_LIQUID_ASSET]: RESERVES_ACCOUNT_TYPES.OTHER_TYPE_OF_DOWN_PAYMENT,
  [FNM.ASSET_TYPE.OTHER_LIQUID_ASSET]: RESERVES_ACCOUNT_TYPES.OTHER_TYPE_OF_DOWN_PAYMENT,
  [FNM.ASSET_TYPE.NET_EQUITY]: RESERVES_ACCOUNT_TYPES.TRADE_EQUITY,
  [FNM.ASSET_TYPE.CASH_ON_HAND]: RESERVES_ACCOUNT_TYPES.CASH_ON_HAND,
  [FNM.ASSET_TYPE.GIFT_OF_EQUITY]: RESERVES_ACCOUNT_TYPES.GIFT_RELATIVE,
};

const LANIS_ACCOUNT_SUB_TYPE_TO_FNM_ASSET_TYPE = {
  [RESERVES_ACCOUNT_TYPES.CHECKING_SAVINGS]: FNM.ASSET_TYPE.CHECKING_ACCOUNT,
  [RESERVES_ACCOUNT_TYPES.DEPOSIT_ON_SALES_CONTRACT]: FNM.ASSET_TYPE.CASH_DEPOSIT_ON_SALES_CONTRACT,
  [RESERVES_ACCOUNT_TYPES.GIFT_SOURCE_N_A]: FNM.ASSET_TYPE.GIFT,
  [RESERVES_ACCOUNT_TYPES.STOCKS_BONDS]: FNM.ASSET_TYPE.STOCK,
  [RESERVES_ACCOUNT_TYPES.SECURED_BORROWED_FUNDS]: FNM.ASSET_TYPE.SECURED_BORROWED_FUNDS,
  [RESERVES_ACCOUNT_TYPES.BRIDGE_LOAN]: FNM.ASSET_TYPE.BRIDGE_LOAN,
  [RESERVES_ACCOUNT_TYPES.RETIREMENT_FUNDS]: FNM.ASSET_TYPE.RETIREMENT_FUNDS,
  [RESERVES_ACCOUNT_TYPES.LOT_EQUITY]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.TRUST_FUNDS]: FNM.ASSET_TYPE.TRUST_FUNDS,
  [RESERVES_ACCOUNT_TYPES.OTHER_TYPE_OF_DOWN_PAYMENT]: FNM.ASSET_TYPE.OTHER_NON_LIQUID_ASSET,
  [RESERVES_ACCOUNT_TYPES.CASH_ON_HAND]: FNM.ASSET_TYPE.CASH_ON_HAND,
  [RESERVES_ACCOUNT_TYPES.GIFT_RELATIVE]: FNM.ASSET_TYPE.GIFT_OF_EQUITY,
  [RESERVES_ACCOUNT_TYPES.EQUITY_FROM_SUBJECT_PROPERTY]: FNM.ASSET_TYPE.OTHER_NON_LIQUID_ASSET,
  [RESERVES_ACCOUNT_TYPES.EQUITY_ON_SOLD_PROPERTY]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.GIFT_EMPLOYER]: FNM.ASSET_TYPE.GIFT,
  [RESERVES_ACCOUNT_TYPES.GIFT_GOVERNMENT_ASSISTANCE]: FNM.ASSET_TYPE.GIFT,
  [RESERVES_ACCOUNT_TYPES.GIFT_NON_SELLER_FUNDED_NON_PROFIT]: FNM.ASSET_TYPE.GIFT,
  [RESERVES_ACCOUNT_TYPES.GIFT_SELLER_FUNDED_NON_PROFIT]: FNM.ASSET_TYPE.GIFT,
  [RESERVES_ACCOUNT_TYPES.RENT_WITH_OPTION_TO_PURCHASE]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.SALE_OF_CHATTEL]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.SWEAT_EQUITY]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.TRADE_EQUITY]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.UNSECURED_BORROWED_FUNDS]: FNM.ASSET_TYPE.NET_EQUITY,
  [RESERVES_ACCOUNT_TYPES.EQUITY_FROM_PENDING_SALE]: FNM.ASSET_TYPE.NET_EQUITY,
};

export const LANIS_ACCOUNT_SUB_TYPE = {
  import: FNM_ASSET_TYPE_TO_LANIS_ACCOUNT_SUB_TYPE,
  export: LANIS_ACCOUNT_SUB_TYPE_TO_FNM_ASSET_TYPE
};

const FNM_MARITAL_STATUS_TO_LANIS_MARITAL_STATUS = {
  [FNM.MARITAL_STATUS.MARRIED]: MARITAL_STATUS.MARRIED,
  [FNM.MARITAL_STATUS.SEPARATED]: MARITAL_STATUS.SEPARATED,
  [FNM.MARITAL_STATUS.UNMARRIED]: MARITAL_STATUS.UNMARRIED,
};

const LANIS_MARITAL_STATUS_TO_FNM_MARITAL_STATUS = {
  [MARITAL_STATUS.MARRIED]: FNM.MARITAL_STATUS.MARRIED,
  [MARITAL_STATUS.SEPARATED]: FNM.MARITAL_STATUS.SEPARATED,
  [MARITAL_STATUS.UNMARRIED]: FNM.MARITAL_STATUS.UNMARRIED,
};

export const LANIS_MARITAL_STATUS = {
  import: FNM_MARITAL_STATUS_TO_LANIS_MARITAL_STATUS,
  export: LANIS_MARITAL_STATUS_TO_FNM_MARITAL_STATUS
};

const FNM_INCOME_CODE_TO_LANIS_ADDITIONAL_INCOME_TYPES = {
  [FNM.INCOME_CODE.MILITARY_BASE_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_BASE_PAY,
  [FNM.INCOME_CODE.MILITARY_RATIONS_ALLOWANCE]: ADDITIONAL_INCOME_TYPES.MILITARY_RATIONS_ALLOWANCE,
  [FNM.INCOME_CODE.MILITARY_FLIGHT_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_FLIGHT_PAY,
  [FNM.INCOME_CODE.MILITARY_HAZARD_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_HAZARD_PAY,
  [FNM.INCOME_CODE.MILITARY_CLOTHES_ALLOWANCE]: ADDITIONAL_INCOME_TYPES.MILITARY_CLOTHES_ALLOWANCE,
  [FNM.INCOME_CODE.MILITARY_QUARTERS_ALLOWANCE]: ADDITIONAL_INCOME_TYPES.MILITARY_QUARTERS_ALLOWANCE,
  [FNM.INCOME_CODE.MILITARY_PROP_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_PROP_PAY,
  [FNM.INCOME_CODE.MILITARY_OVERSEAS_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_OVERSEAS_PAY,
  [FNM.INCOME_CODE.MILITARY_COMBAT_PAY]: ADDITIONAL_INCOME_TYPES.MILITARY_COMBAT_PAY,
  [FNM.INCOME_CODE.MILITARY_VARIABLE_HOUSING_ALLOWANCE]: ADDITIONAL_INCOME_TYPES.MILITARY_VARIABLE_HOUSING_ALLOWANCE,
  [FNM.INCOME_CODE.ALIMONY_CHILD_SUPPORT_INCOME]: ADDITIONAL_INCOME_TYPES.ALIMONY,
  [FNM.INCOME_CODE.NOTES_RECEIVABLE_INSTALLMENT]: ADDITIONAL_INCOME_TYPES.NOTES_RECEIVABLE,
  [FNM.INCOME_CODE.PENSION_RETIREMENT_INCOME]: ADDITIONAL_INCOME_TYPES.RETIREMENT,
  [FNM.INCOME_CODE.SOCIAL_SECURITY_DISABILITY_INCOME]: ADDITIONAL_INCOME_TYPES.SOCIAL_SECURITY,
  [FNM.INCOME_CODE.REAL_ESTATE_MORTGAGE_DIFFERENTIAL]: ADDITIONAL_INCOME_TYPES.REAL_ESTATE_MORTGAGE_DIFFERENTIAL_INCOME,
  [FNM.INCOME_CODE.TRUST_INCOME]: ADDITIONAL_INCOME_TYPES.TRUST_INCOME,
  [FNM.INCOME_CODE.ACCESSORY_UNIT_INCOME]: ADDITIONAL_INCOME_TYPES.HOUSING_OR_PARSONAGE,
  [FNM.INCOME_CODE.UNEMPLOYMENT_PUBLIC_ASSISTANCE]: ADDITIONAL_INCOME_TYPES.UNEMPLOYMENT,
  [FNM.INCOME_CODE.AUTOMOBILE_EXPENSE_ACCOUNT_INCOME]: ADDITIONAL_INCOME_TYPES.AUTOMOBILE_ALLOWANCE,
  [FNM.INCOME_CODE.FOSTER_CARE]: ADDITIONAL_INCOME_TYPES.FOSTER_CARE,
  [FNM.INCOME_CODE.VA_BENEFITS_NON_EDUCATION]: ADDITIONAL_INCOME_TYPES.VA_BENEFITS,
  [FNM.INCOME_CODE.NON_BORROWER_HOUSEHOLD_INCOME]: ADDITIONAL_INCOME_TYPES.OTHER_INCOME,
  [FNM.INCOME_CODE.OTHER_INCOME]: ADDITIONAL_INCOME_TYPES.OTHER_INCOME,
  [FNM.INCOME_CODE.DIVIDENDS_INTEREST]: ADDITIONAL_INCOME_TYPES.INTEREST_AND_DIVIDENDS,
  [FNM.INCOME_CODE.SUBJECT_PROPERTY_NET_CASH_FLOW]: ADDITIONAL_INCOME_TYPES.SUBJECT_PROPERTY_NET_CASH_FLOW,
  [FNM.INCOME_CODE.HOUSING_CHOICE_VOUCHER]: ADDITIONAL_INCOME_TYPES.UNEMPLOYMENT,
  [FNM.INCOME_CODE.BOARDER_INCOME]: ADDITIONAL_INCOME_TYPES.BOARDER_INCOME,
  [FNM.INCOME_CODE.MORTGAGE_CREDIT_CERTIFICATE]: ADDITIONAL_INCOME_TYPES.MORTGAGE_CREDIT_CERTIFICATE,
  [FNM.INCOME_CODE.CAPITAL_GAINS]: ADDITIONAL_INCOME_TYPES.CAPITAL_GAINS,
  [FNM.INCOME_CODE.EMPLOYMENT_RELATED_ASSETS]: ADDITIONAL_INCOME_TYPES.EMPLOYMENT_RELATED_ASSETS,
  [FNM.INCOME_CODE.FOREIGN_INCOME]: ADDITIONAL_INCOME_TYPES.FOREIGN_INCOME,
  [FNM.INCOME_CODE.ROYALTY_PAYMENT]: ADDITIONAL_INCOME_TYPES.ROYALTY_PAYMENTS,
  [FNM.INCOME_CODE.SEASONAL_INCOME]: ADDITIONAL_INCOME_TYPES.SEASONAL_INCOME,
  [FNM.INCOME_CODE.TEMPORARY_LEAVE]: ADDITIONAL_INCOME_TYPES.TEMPORARY_LEAVE,
  [FNM.INCOME_CODE.TIP_INCOME]: ADDITIONAL_INCOME_TYPES.TIP_INCOME,
};

const LANIS_ADDITIONAL_INCOME_TYPES_TO_FNM_INCOME_CODE = {
  [ADDITIONAL_INCOME_TYPES.MILITARY_BASE_PAY]: FNM.INCOME_CODE.MILITARY_BASE_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_RATIONS_ALLOWANCE]: FNM.INCOME_CODE.MILITARY_RATIONS_ALLOWANCE,
  [ADDITIONAL_INCOME_TYPES.MILITARY_FLIGHT_PAY]: FNM.INCOME_CODE.MILITARY_FLIGHT_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_HAZARD_PAY]: FNM.INCOME_CODE.MILITARY_HAZARD_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_CLOTHES_ALLOWANCE]: FNM.INCOME_CODE.MILITARY_CLOTHES_ALLOWANCE,
  [ADDITIONAL_INCOME_TYPES.MILITARY_QUARTERS_ALLOWANCE]: FNM.INCOME_CODE.MILITARY_QUARTERS_ALLOWANCE,
  [ADDITIONAL_INCOME_TYPES.MILITARY_PROP_PAY]: FNM.INCOME_CODE.MILITARY_PROP_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_OVERSEAS_PAY]: FNM.INCOME_CODE.MILITARY_OVERSEAS_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_COMBAT_PAY]: FNM.INCOME_CODE.MILITARY_COMBAT_PAY,
  [ADDITIONAL_INCOME_TYPES.MILITARY_VARIABLE_HOUSING_ALLOWANCE]: FNM.INCOME_CODE.MILITARY_VARIABLE_HOUSING_ALLOWANCE,
  [ADDITIONAL_INCOME_TYPES.ALIMONY]: FNM.INCOME_CODE.ALIMONY_CHILD_SUPPORT_INCOME,
  [ADDITIONAL_INCOME_TYPES.NOTES_RECEIVABLE]: FNM.INCOME_CODE.NOTES_RECEIVABLE_INSTALLMENT,
  [ADDITIONAL_INCOME_TYPES.RETIREMENT]: FNM.INCOME_CODE.PENSION_RETIREMENT_INCOME,
  [ADDITIONAL_INCOME_TYPES.SOCIAL_SECURITY]: FNM.INCOME_CODE.SOCIAL_SECURITY_DISABILITY_INCOME,
  [ADDITIONAL_INCOME_TYPES.REAL_ESTATE_MORTGAGE_DIFFERENTIAL_INCOME]: FNM.INCOME_CODE.REAL_ESTATE_MORTGAGE_DIFFERENTIAL,
  [ADDITIONAL_INCOME_TYPES.TRUST_INCOME]: FNM.INCOME_CODE.TRUST_INCOME,
  [ADDITIONAL_INCOME_TYPES.HOUSING_OR_PARSONAGE]: FNM.INCOME_CODE.ACCESSORY_UNIT_INCOME,
  [ADDITIONAL_INCOME_TYPES.UNEMPLOYMENT]: FNM.INCOME_CODE.UNEMPLOYMENT_PUBLIC_ASSISTANCE,
  [ADDITIONAL_INCOME_TYPES.AUTOMOBILE_ALLOWANCE]: FNM.INCOME_CODE.AUTOMOBILE_EXPENSE_ACCOUNT_INCOME,
  [ADDITIONAL_INCOME_TYPES.FOSTER_CARE]: FNM.INCOME_CODE.FOSTER_CARE,
  [ADDITIONAL_INCOME_TYPES.VA_BENEFITS]: FNM.INCOME_CODE.VA_BENEFITS_NON_EDUCATION,
  [ADDITIONAL_INCOME_TYPES.OTHER_INCOME]: FNM.INCOME_CODE.OTHER_INCOME,
  [ADDITIONAL_INCOME_TYPES.INTEREST_AND_DIVIDENDS]: FNM.INCOME_CODE.DIVIDENDS_INTEREST,
  [ADDITIONAL_INCOME_TYPES.SUBJECT_PROPERTY_NET_CASH_FLOW]: FNM.INCOME_CODE.SUBJECT_PROPERTY_NET_CASH_FLOW,
  [ADDITIONAL_INCOME_TYPES.BOARDER_INCOME]: FNM.INCOME_CODE.BOARDER_INCOME,
  [ADDITIONAL_INCOME_TYPES.MORTGAGE_CREDIT_CERTIFICATE]: FNM.INCOME_CODE.MORTGAGE_CREDIT_CERTIFICATE,
  [ADDITIONAL_INCOME_TYPES.CAPITAL_GAINS]: FNM.INCOME_CODE.CAPITAL_GAINS,
  [ADDITIONAL_INCOME_TYPES.EMPLOYMENT_RELATED_ASSETS]: FNM.INCOME_CODE.EMPLOYMENT_RELATED_ASSETS,
  [ADDITIONAL_INCOME_TYPES.FOREIGN_INCOME]: FNM.INCOME_CODE.FOREIGN_INCOME,
  [ADDITIONAL_INCOME_TYPES.ROYALTY_PAYMENTS]: FNM.INCOME_CODE.ROYALTY_PAYMENT,
  [ADDITIONAL_INCOME_TYPES.SEASONAL_INCOME]: FNM.INCOME_CODE.SEASONAL_INCOME,
  [ADDITIONAL_INCOME_TYPES.TEMPORARY_LEAVE]: FNM.INCOME_CODE.TEMPORARY_LEAVE,
  [ADDITIONAL_INCOME_TYPES.TIP_INCOME]: FNM.INCOME_CODE.TIP_INCOME,
  [ADDITIONAL_INCOME_TYPES.CHILD_SUPPORT]: FNM.INCOME_CODE.ALIMONY_CHILD_SUPPORT_INCOME,
  [ADDITIONAL_INCOME_TYPES.DISABILITY]: FNM.INCOME_CODE.SOCIAL_SECURITY_DISABILITY_INCOME,
  [ADDITIONAL_INCOME_TYPES.MORTGAGE_DIFFERENTIAL_PAYMENTS]: FNM.INCOME_CODE.MORTGAGE_CREDIT_CERTIFICATE,
  [ADDITIONAL_INCOME_TYPES.PUBLIC_ASSISTANCE]: FNM.INCOME_CODE.UNEMPLOYMENT_PUBLIC_ASSISTANCE,
  [ADDITIONAL_INCOME_TYPES.SEPARATE_MAINTENANCE]: FNM.INCOME_CODE.OTHER_INCOME,
  [ADDITIONAL_INCOME_TYPES.TRAILING_CO_BORROWER_INCOME]: FNM.INCOME_CODE.OTHER_INCOME,
};

export const LANIS_ADDITIONAL_INCOME_TYPES = {
  import: FNM_INCOME_CODE_TO_LANIS_ADDITIONAL_INCOME_TYPES,
  export: LANIS_ADDITIONAL_INCOME_TYPES_TO_FNM_INCOME_CODE
};

const FNM_OWN_RENT_LIVING_RENT_FREE_TO_LANIS_CURRENTLY_RENTING_OR_OWNING = {
  [FNM.OWN_RENT_LIVING_RENT_FREE.LIVING_RENT_FREE]: RENTING_OR_OWNING.OWN_IN_TRUST,
  [FNM.OWN_RENT_LIVING_RENT_FREE.RENT]: RENTING_OR_OWNING.RENT,
  [FNM.OWN_RENT_LIVING_RENT_FREE.OWN]: RENTING_OR_OWNING.OWN,
};

const LANIS_CURRENTLY_RENTING_OR_OWNING_TO_FNM_OWN_RENT_LIVING_RENT_FREE = {
  [RENTING_OR_OWNING.OWN_IN_TRUST]: FNM.OWN_RENT_LIVING_RENT_FREE.LIVING_RENT_FREE,
  [RENTING_OR_OWNING.RENT]: FNM.OWN_RENT_LIVING_RENT_FREE.RENT,
  [RENTING_OR_OWNING.OWN]: FNM.OWN_RENT_LIVING_RENT_FREE.OWN,
};

export const LANIS_CURRENTLY_RENTING_OR_OWNING = {
  import: FNM_OWN_RENT_LIVING_RENT_FREE_TO_LANIS_CURRENTLY_RENTING_OR_OWNING,
  export: LANIS_CURRENTLY_RENTING_OR_OWNING_TO_FNM_OWN_RENT_LIVING_RENT_FREE
};

const FNM_LIABILITY_TYPE_TO_LANIS_LIABILITY_TYPE = {
  [FNM.LIABILITY_TYPE.INSTALLMENT_LOAN]: LIABILITY_TYPE.INSTALLMENT,
  [FNM.LIABILITY_TYPE.THIRTY_DAY_CHARGE_ACCOUNT_OPEN]: LIABILITY_TYPE.THIRTY_DAY_CHARGE_ACCT,
  [FNM.LIABILITY_TYPE.REVOLVING_CHARGE_ACCOUNT]: LIABILITY_TYPE.REVOLVING_CHARGE,
  [FNM.LIABILITY_TYPE.HELOC]: LIABILITY_TYPE.HOME_EQUITY,
  [FNM.LIABILITY_TYPE.MORTGAGE]: LIABILITY_TYPE.MORTGAGE,
  [FNM.LIABILITY_TYPE.LEASE_PAYMENTS]: LIABILITY_TYPE.LEASE,
  [FNM.LIABILITY_TYPE.LIENS]: LIABILITY_TYPE.LIENS,
  [FNM.LIABILITY_TYPE.TAXES]: LIABILITY_TYPE.TAXES,
  [FNM.LIABILITY_TYPE.OTHER_LIABILITY]: LIABILITY_TYPE.OTHER_LIABILITIES,
};

const LANIS_LIABILITY_TYPE_TO_FNM_LIABILITY_TYPE = {
  [LIABILITY_TYPE.INSTALLMENT]: FNM.LIABILITY_TYPE.INSTALLMENT_LOAN,
  [LIABILITY_TYPE.THIRTY_DAY_CHARGE_ACCT]: FNM.LIABILITY_TYPE.THIRTY_DAY_CHARGE_ACCOUNT_OPEN,
  [LIABILITY_TYPE.REVOLVING_CHARGE]: FNM.LIABILITY_TYPE.REVOLVING_CHARGE_ACCOUNT,
  [LIABILITY_TYPE.HOME_EQUITY]: FNM.LIABILITY_TYPE.HELOC,
  [LIABILITY_TYPE.MORTGAGE]: FNM.LIABILITY_TYPE.MORTGAGE,
  [LIABILITY_TYPE.LEASE]: FNM.LIABILITY_TYPE.LEASE_PAYMENTS,
  [LIABILITY_TYPE.LIENS]: FNM.LIABILITY_TYPE.LIENS,
  [LIABILITY_TYPE.TAXES]: FNM.LIABILITY_TYPE.TAXES,
  [LIABILITY_TYPE.OTHER_LIABILITIES]: FNM.LIABILITY_TYPE.OTHER_LIABILITY,
};

const FNM_EXPENSE_TYPE_TO_LANIS_LIABILITY_TYPE = {
  [FNM.EXPENSE_TYPE.ALIMONY]: LIABILITY_TYPE.ALIMONY,
  [FNM.EXPENSE_TYPE.CHILD_SUPPORT]: LIABILITY_TYPE.CHILDCARE,
  [FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT]: LIABILITY_TYPE.SEPARATE_MAINTENANCE,
  [FNM.EXPENSE_TYPE.JOB_RELATED_EXPENSE]: LIABILITY_TYPE.OTHER_EXPENSES,
  [FNM.EXPENSE_TYPE.OTHER_EXPENSE_TYPE]: LIABILITY_TYPE.OTHER_EXPENSES,
};

const LANIS_LIABILITY_TYPE_TO_FNM_EXPENSE_TYPE = {
  [LIABILITY_TYPE.CHILDCARE]: FNM.EXPENSE_TYPE.CHILD_SUPPORT,
  [LIABILITY_TYPE.ALIMONY]: FNM.EXPENSE_TYPE.ALIMONY,
  [LIABILITY_TYPE.SEPARATE_MAINTENANCE]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.OTHER_EXPENSES]: FNM.EXPENSE_TYPE.OTHER_EXPENSE_TYPE,
  [LIABILITY_TYPE.PRIMARY_RESIDENCE]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.SECOND_HOME]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.SECOND_LOAN_SUB]: FNM.EXPENSE_TYPE.OTHER_EXPENSE_TYPE,
  [LIABILITY_TYPE.AUTO]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.BOAT]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.MOTORCYCLE]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.RV]: FNM.EXPENSE_TYPE.SEPARATE_MAINTENANCE_PAYMENT,
  [LIABILITY_TYPE.STUDENT_LOAN]: FNM.EXPENSE_TYPE.OTHER_EXPENSE_TYPE,
};

export const LANIS_LIABILITY_TYPE = {
  TO_LIABILITY_TYPE: {
    import: FNM_LIABILITY_TYPE_TO_LANIS_LIABILITY_TYPE,
    export: LANIS_LIABILITY_TYPE_TO_FNM_LIABILITY_TYPE
  },
  TO_EXPENSE_TYPE: {
    import: FNM_EXPENSE_TYPE_TO_LANIS_LIABILITY_TYPE,
    export: LANIS_LIABILITY_TYPE_TO_FNM_EXPENSE_TYPE
  }
};

const FNM_LOAN_TYPES_TO_LANIS_LOAN_TYPES = {
  [FNM.LOAN_TYPES.CONVENTIONAL]: LOAN_TYPES.FANNIEMAE,
  [FNM.LOAN_TYPES.VA]: LOAN_TYPES.VA_PURCHASE,
  [FNM.LOAN_TYPES.FHA]: LOAN_TYPES.FHA_PURCHASE,
  [FNM.LOAN_TYPES.USDA_OR_RURAL_HOUSING_SERVICE]: LOAN_TYPES.USDA_PURCHASE,
  [FNM.LOAN_TYPES.OTHER]: LOAN_TYPES.OTHER, // Note: This is a soft match.  "07" (Other) is
                                            // allowed to be any of the remaining loan types.
};

const LANIS_LOAN_TYPES_TO_FNM_LOAN_TYPES = {
  [LOAN_TYPES.FANNIEMAE]: FNM.LOAN_TYPES.CONVENTIONAL,
  [LOAN_TYPES.VA_PURCHASE]: FNM.LOAN_TYPES.VA,
  [LOAN_TYPES.FHA_PURCHASE]: FNM.LOAN_TYPES.FHA,
  [LOAN_TYPES.USDA_PURCHASE]: FNM.LOAN_TYPES.USDA_OR_RURAL_HOUSING_SERVICE,
  [LOAN_TYPES.OTHER]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.FREDDIEMAC]: FNM.LOAN_TYPES.CONVENTIONAL,
  [LOAN_TYPES.JUMBO]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.PORTFOLIO]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.HARD_MONEY]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.CONSTRUCTION]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.NON_QM]: FNM.LOAN_TYPES.OTHER,
  [LOAN_TYPES.FIX_AND_FLIP]: FNM.LOAN_TYPES.OTHER,
};

export const LANIS_LOAN_TYPES = {
  import: FNM_LOAN_TYPES_TO_LANIS_LOAN_TYPES,
  export: LANIS_LOAN_TYPES_TO_FNM_LOAN_TYPES
};

// Null fields are ones defined by FNM but fully unsupported in Lanis
const FNM_AMORTIZATION_TYPES_TO_LANIS_ARM_FIX = {
  [FNM.AMORTIZATION_TYPES.ADJUSTABLE_RATE]: LOAN_TERMS.ARM,
  [FNM.AMORTIZATION_TYPES.GEM]: null,
  [FNM.AMORTIZATION_TYPES.FIXED_RATE]: LOAN_TERMS.FIXED,
  [FNM.AMORTIZATION_TYPES.GPM]: null,
  [FNM.AMORTIZATION_TYPES.OTHER]: null,
};

const LANIS_ARM_FIX_TO_FNM_AMORTIZATION_TYPES = {
  [LOAN_TERMS.ARM]: FNM.AMORTIZATION_TYPES.ADJUSTABLE_RATE,
  [LOAN_TERMS.FIXED]: FNM.AMORTIZATION_TYPES.FIXED_RATE,
};

export const LANIS_ARM_FIX = {
  import: FNM_AMORTIZATION_TYPES_TO_LANIS_ARM_FIX,
  export: LANIS_ARM_FIX_TO_FNM_AMORTIZATION_TYPES
};

// Null fields are ones defined by FNM but fully unsupported in Lanis
const FNM_PURPOSE_OF_LOAN_TO_LANIS_DOCUMENT_TYPES = {
  [FNM.PURPOSE_OF_LOAN.CONSTRUCTION]: null,
  [FNM.PURPOSE_OF_LOAN.REFINANCE]: DOCUMENT_TYPES.REFINANCE,
  [FNM.PURPOSE_OF_LOAN.CONSTRUCTION_PERM]: null,
  [FNM.PURPOSE_OF_LOAN.OTHER]: null,
  [FNM.PURPOSE_OF_LOAN.PURCHASE]: DOCUMENT_TYPES.PURCHASE,
};

const LANIS_DOCUMENT_TYPES_TO_FNM_PURPOSE_OF_LOAN = {
  [DOCUMENT_TYPES.REFINANCE]: FNM.PURPOSE_OF_LOAN.REFINANCE,
  [DOCUMENT_TYPES.PURCHASE]: FNM.PURPOSE_OF_LOAN.PURCHASE,
};

export const LANIS_DOCUMENT_TYPES = {
  import: FNM_PURPOSE_OF_LOAN_TO_LANIS_DOCUMENT_TYPES,
  export: LANIS_DOCUMENT_TYPES_TO_FNM_PURPOSE_OF_LOAN
};

const FNM_TYPE_OF_RESIDENCE_TO_LANIS_PROPERTY_CLASSIFICATION = {
  [FNM.TYPE_OF_RESIDENCE.PRIMARY_RESIDENCE]: PROPERTY_CLASSIFICATION.PRIMARY_RESIDENCE,
  [FNM.TYPE_OF_RESIDENCE.SECONDARY_RESIDENCE]: PROPERTY_CLASSIFICATION.SECOND_HOME,
  [FNM.TYPE_OF_RESIDENCE.INVESTMENT_PROPERTY]: PROPERTY_CLASSIFICATION.INVESTMENT_PROPERTY,
};

const LANIS_PROPERTY_CLASSIFICATION_TO_FNM_TYPE_OF_RESIDENCE = {
  [PROPERTY_CLASSIFICATION.PRIMARY_RESIDENCE]: FNM.TYPE_OF_RESIDENCE.PRIMARY_RESIDENCE,
  [PROPERTY_CLASSIFICATION.SECOND_HOME]: FNM.TYPE_OF_RESIDENCE.SECONDARY_RESIDENCE,
  [PROPERTY_CLASSIFICATION.INVESTMENT_PROPERTY]: FNM.TYPE_OF_RESIDENCE.INVESTMENT_PROPERTY,
  [PROPERTY_CLASSIFICATION.VACATION_HOME]: FNM.TYPE_OF_RESIDENCE.SECONDARY_RESIDENCE,
};

export const LANIS_PROPERTY_CLASSIFICATION = {
  import: FNM_TYPE_OF_RESIDENCE_TO_LANIS_PROPERTY_CLASSIFICATION,
  export: LANIS_PROPERTY_CLASSIFICATION_TO_FNM_TYPE_OF_RESIDENCE
};

const FNM_PURPOSE_OF_REFINANCE_TO_LANIS_PURPOSE_OF_REFINANCE = {
  [FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT]: PURPOSE_OF_REFINANCE.NO_CASH_OUT_OTHER,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OTHER]: PURPOSE_OF_REFINANCE.CASH_OUT_OTHER,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT]: PURPOSE_OF_REFINANCE.CASH_OUT_HOME_IMPROVEMENT,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_DEBT_CONSOLIDATION]: PURPOSE_OF_REFINANCE.CASH_OUT_DEBT_CONSOLIDATION,
  [FNM.PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT]: PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT_RATE_TERM,
};

const LANIS_PURPOSE_OF_REFINANCE_TO_FNM_PURPOSE_OF_REFINANCE = {
  [PURPOSE_OF_REFINANCE.NO_CASH_OUT_OTHER]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
  [PURPOSE_OF_REFINANCE.CASH_OUT_OTHER]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OTHER,
  [PURPOSE_OF_REFINANCE.CASH_OUT_HOME_IMPROVEMENT]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT,
  [PURPOSE_OF_REFINANCE.CASH_OUT_DEBT_CONSOLIDATION]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_DEBT_CONSOLIDATION,
  [PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT_RATE_TERM]: FNM.PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT,
  [PURPOSE_OF_REFINANCE.CHANGE_IN_RATE_TERM]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
  [PURPOSE_OF_REFINANCE.NO_CASH_OUT_FHA_STREAMLINED_REFINANCE]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
};

export const LANIS_PURPOSE_OF_REFINANCE = {
  import: FNM_PURPOSE_OF_REFINANCE_TO_LANIS_PURPOSE_OF_REFINANCE,
  export: LANIS_PURPOSE_OF_REFINANCE_TO_FNM_PURPOSE_OF_REFINANCE
};

const FNM_TYPE_OF_PROPERTY_TO_LANIS_PROPERTY_TYPE = {
  [FNM.TYPE_OF_PROPERTY.SINGLE_FAMILY]: PROPERTY_TYPE.DETACHED,
  [FNM.TYPE_OF_PROPERTY.CONDOMINIUM]: PROPERTY_TYPE.CONDOMINIUM,
  [FNM.TYPE_OF_PROPERTY.TOWNHOUSE]: PROPERTY_TYPE.MULTI_UNIT_3_4,
  [FNM.TYPE_OF_PROPERTY.CO_OPERATIVE]: PROPERTY_TYPE.COOPERATIVE,
  [FNM.TYPE_OF_PROPERTY.TWO_TO_FOUR_UNIT_PROPERTY]: PROPERTY_TYPE.MULTI_UNIT_3_4,
  [FNM.TYPE_OF_PROPERTY.MULTIFAMILY]: PROPERTY_TYPE.PUD,
  [FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME]: PROPERTY_TYPE.MANUFACTURED_SINGLE_WIDE,
  [FNM.TYPE_OF_PROPERTY.COMMERCIAL_NON_RESIDENTIAL]: PROPERTY_TYPE.CONDOMINIUM,
  [FNM.TYPE_OF_PROPERTY.MIXED_USE_RESIDENTIAL]: PROPERTY_TYPE.DETACHED,
  [FNM.TYPE_OF_PROPERTY.FARM]: PROPERTY_TYPE.DETACHED,
  [FNM.TYPE_OF_PROPERTY.HOME_AND_BUSINESS_COMBINED]: PROPERTY_TYPE.DETACHED,
  [FNM.TYPE_OF_PROPERTY.LAND]: PROPERTY_TYPE.DETACHED,
};

const LANIS_PROPERTY_TYPE_TO_FNM_TYPE_OF_PROPERTY = {
  [PROPERTY_TYPE.ATTACHED]: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
  [PROPERTY_TYPE.DETACHED_CONDOMINIUM]: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
  [PROPERTY_TYPE.DUPLEX_1_2]: FNM.TYPE_OF_PROPERTY.TWO_TO_FOUR_UNIT_PROPERTY,
  [PROPERTY_TYPE.HIGH_RISE_CONDOMINIUM]: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
  [PROPERTY_TYPE.DETACHED]: FNM.TYPE_OF_PROPERTY.SINGLE_FAMILY,
  [PROPERTY_TYPE.CONDOMINIUM]: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
  [PROPERTY_TYPE.COOPERATIVE]: FNM.TYPE_OF_PROPERTY.CO_OPERATIVE,
  [PROPERTY_TYPE.MULTI_UNIT_3_4]: FNM.TYPE_OF_PROPERTY.TWO_TO_FOUR_UNIT_PROPERTY,
  [PROPERTY_TYPE.PUD]: FNM.TYPE_OF_PROPERTY.MULTIFAMILY,
  [PROPERTY_TYPE.MANUFACTURED_SINGLE_WIDE]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
  [PROPERTY_TYPE.MANUFACTURED_DOUBLE_WIDE]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
  [PROPERTY_TYPE.MANUFACTURED_MULTI_WIDE]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
  [PROPERTY_TYPE.MANUFACTURED_CONDO_PUD_COOP]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
  [PROPERTY_TYPE.MODULAR_PRE_FABRICATED_HOME]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
};

const FNM_SUBJECT_PROPERTY_TYPE_CODE_TO_LANIS_PROPERTY_TYPE = {
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.DETACHED]: PROPERTY_TYPE.DETACHED,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.ATTACHED]: PROPERTY_TYPE.ATTACHED,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.CONDOMINIUM]: PROPERTY_TYPE.CONDOMINIUM,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.PLANNED_UNIT_DEVELOPMENT_PUD]: PROPERTY_TYPE.PUD,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.CO_OPERATIVE_CO_OP]: PROPERTY_TYPE.COOPERATIVE,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.HIGH_RISE_CONDO]: PROPERTY_TYPE.HIGH_RISE_CONDOMINIUM,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME]: PROPERTY_TYPE.MANUFACTURED_SINGLE_WIDE,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.DETACHED_CONDO]: PROPERTY_TYPE.DETACHED_CONDOMINIUM,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME_CONDO_OR_PUD_OR_CO_OP]: PROPERTY_TYPE.MANUFACTURED_CONDO_PUD_COOP,
  [FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME_MH_ADVANTAGE]: PROPERTY_TYPE.MANUFACTURED_MULTI_WIDE,
};

const LANIS_PROPERTY_TYPE_TO_FNM_SUBJECT_PROPERTY_TYPE_CODE = {
  [PROPERTY_TYPE.DETACHED]: FNM.SUBJECT_PROPERTY_TYPE_CODE.DETACHED,
  [PROPERTY_TYPE.ATTACHED]: FNM.SUBJECT_PROPERTY_TYPE_CODE.ATTACHED,
  [PROPERTY_TYPE.MULTI_UNIT_3_4]: FNM.SUBJECT_PROPERTY_TYPE_CODE.CONDOMINIUM,
  [PROPERTY_TYPE.CONDOMINIUM]: FNM.SUBJECT_PROPERTY_TYPE_CODE.CONDOMINIUM,
  [PROPERTY_TYPE.PUD]: FNM.SUBJECT_PROPERTY_TYPE_CODE.PLANNED_UNIT_DEVELOPMENT_PUD,
  [PROPERTY_TYPE.DUPLEX_1_2]: FNM.SUBJECT_PROPERTY_TYPE_CODE.CO_OPERATIVE_CO_OP,
  [PROPERTY_TYPE.COOPERATIVE]: FNM.SUBJECT_PROPERTY_TYPE_CODE.CO_OPERATIVE_CO_OP,
  [PROPERTY_TYPE.HIGH_RISE_CONDOMINIUM]: FNM.SUBJECT_PROPERTY_TYPE_CODE.HIGH_RISE_CONDO,
  [PROPERTY_TYPE.MANUFACTURED_SINGLE_WIDE]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME,
  [PROPERTY_TYPE.MANUFACTURED_DOUBLE_WIDE]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME,
  [PROPERTY_TYPE.MANUFACTURED_MULTI_WIDE]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME,
  [PROPERTY_TYPE.MODULAR_PRE_FABRICATED_HOME]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME,
  [PROPERTY_TYPE.DETACHED_CONDOMINIUM]: FNM.SUBJECT_PROPERTY_TYPE_CODE.DETACHED_CONDO,
  [PROPERTY_TYPE.MANUFACTURED_CONDO_PUD_COOP]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME_CONDO_OR_PUD_OR_CO_OP,
  [PROPERTY_TYPE.MANUFACTURED_MULTI_WIDE]: FNM.SUBJECT_PROPERTY_TYPE_CODE.MANUFACTURED_HOME_MH_ADVANTAGE,
};

export const LANIS_PROPERTY_TYPE = {
  TO_TYPE_OF_PROPERTY: {
    import: FNM_TYPE_OF_PROPERTY_TO_LANIS_PROPERTY_TYPE,
    export: LANIS_PROPERTY_TYPE_TO_FNM_TYPE_OF_PROPERTY
  },
  TO_SUBJECT_PROPERTY_TYPE_CODE: {
    import: FNM_SUBJECT_PROPERTY_TYPE_CODE_TO_LANIS_PROPERTY_TYPE,
    export: LANIS_PROPERTY_TYPE_TO_FNM_SUBJECT_PROPERTY_TYPE_CODE
  }
};

const FNM_TYPE_OF_PROPERTY_TO_LANIS_REO_PROPERTY_TYPE = {
  [FNM.TYPE_OF_PROPERTY.SINGLE_FAMILY]: REO_PROPERTY_TYPE.SFR,
  [FNM.TYPE_OF_PROPERTY.CONDOMINIUM]: REO_PROPERTY_TYPE.CONDO,
  [FNM.TYPE_OF_PROPERTY.TOWNHOUSE]: REO_PROPERTY_TYPE.TOWN,
  [FNM.TYPE_OF_PROPERTY.CO_OPERATIVE]: REO_PROPERTY_TYPE.COOP,
  [FNM.TYPE_OF_PROPERTY.TWO_TO_FOUR_UNIT_PROPERTY]: REO_PROPERTY_TYPE.TWO_FOUR_PLEX,
  [FNM.TYPE_OF_PROPERTY.MULTIFAMILY]: REO_PROPERTY_TYPE.MULTI,
  [FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME]: REO_PROPERTY_TYPE.MOBIL,
  [FNM.TYPE_OF_PROPERTY.COMMERCIAL_NON_RESIDENTIAL]: REO_PROPERTY_TYPE.COM_NR,
  [FNM.TYPE_OF_PROPERTY.MIXED_USE_RESIDENTIAL]: REO_PROPERTY_TYPE.MIXED,
  [FNM.TYPE_OF_PROPERTY.FARM]: REO_PROPERTY_TYPE.FARM,
  [FNM.TYPE_OF_PROPERTY.HOME_AND_BUSINESS_COMBINED]: REO_PROPERTY_TYPE.COM_R,
  [FNM.TYPE_OF_PROPERTY.LAND]: REO_PROPERTY_TYPE.LAND,
};

const LANIS_REO_PROPERTY_TYPE_TO_FNM_TYPE_OF_PROPERTY = {
  [REO_PROPERTY_TYPE.SFR]: FNM.TYPE_OF_PROPERTY.SINGLE_FAMILY,
  [REO_PROPERTY_TYPE.CONDO]: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
  [REO_PROPERTY_TYPE.TOWN]: FNM.TYPE_OF_PROPERTY.TOWNHOUSE,
  [REO_PROPERTY_TYPE.COOP]: FNM.TYPE_OF_PROPERTY.CO_OPERATIVE,
  [REO_PROPERTY_TYPE.TWO_FOUR_PLEX]: FNM.TYPE_OF_PROPERTY.TWO_TO_FOUR_UNIT_PROPERTY,
  [REO_PROPERTY_TYPE.MULTI]: FNM.TYPE_OF_PROPERTY.MULTIFAMILY,
  [REO_PROPERTY_TYPE.MOBIL]: FNM.TYPE_OF_PROPERTY.MANUFACTURED_OR_MOBILE_HOME,
  [REO_PROPERTY_TYPE.COM_NR]: FNM.TYPE_OF_PROPERTY.COMMERCIAL_NON_RESIDENTIAL,
  [REO_PROPERTY_TYPE.MIXED]: FNM.TYPE_OF_PROPERTY.MIXED_USE_RESIDENTIAL,
  [REO_PROPERTY_TYPE.FARM]: FNM.TYPE_OF_PROPERTY.FARM,
  [REO_PROPERTY_TYPE.COM_R]: FNM.TYPE_OF_PROPERTY.HOME_AND_BUSINESS_COMBINED,
  [REO_PROPERTY_TYPE.LAND]: FNM.TYPE_OF_PROPERTY.LAND,
};

export const LANIS_REO_PROPERTY_TYPE = {
  import: FNM_TYPE_OF_PROPERTY_TO_LANIS_REO_PROPERTY_TYPE,
  export: LANIS_REO_PROPERTY_TYPE_TO_FNM_TYPE_OF_PROPERTY
};

const FNM_PURPOSE_OF_REFINANCE_TO_LOAN_PURPOSE = {
  [FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT]: REFINANCE_LOAN_PURPOSES.NO_CASH_OUT_OTHER,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OTHER]: REFINANCE_LOAN_PURPOSES.CASH_OUT_OTHER,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT]: REFINANCE_LOAN_PURPOSES.CASH_OUT_HOME_IMPROVEMENT,
  [FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_DEBT_CONSOLIDATION]: REFINANCE_LOAN_PURPOSES.CASH_OUT_DEBT_CONSOLIDATION,
  [FNM.PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT]: REFINANCE_LOAN_PURPOSES.LIMITED_CASH_OUT_RATE_TERM,
};

const LANIS_LOAN_PURPOSE_TO_FNM_PURPOSE_OF_REFINANCE = {
  [REFINANCE_LOAN_PURPOSES.NO_CASH_OUT_OTHER]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
  [REFINANCE_LOAN_PURPOSES.CASH_OUT_OTHER]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OTHER,
  [REFINANCE_LOAN_PURPOSES.CASH_OUT_HOME_IMPROVEMENT]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT,
  [REFINANCE_LOAN_PURPOSES.CONSTRUCTION]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT,
  [REFINANCE_LOAN_PURPOSES.HOME_EQUITY]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_HOME_IMPROVEMENT,
  [REFINANCE_LOAN_PURPOSES.CHANGE_IN_RATE_TERM]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
  [REFINANCE_LOAN_PURPOSES.NO_CASH_OUT_FHA_STREAMLINED_REFINANCE]: FNM.PURPOSE_OF_REFINANCE.NO_CASH_OUT,
  [REFINANCE_LOAN_PURPOSES.CASH_OUT_DEBT_CONSOLIDATION]: FNM.PURPOSE_OF_REFINANCE.CASH_OUT_OR_DEBT_CONSOLIDATION,
  [REFINANCE_LOAN_PURPOSES.LIMITED_CASH_OUT_RATE_TERM]: FNM.PURPOSE_OF_REFINANCE.LIMITED_CASH_OUT,
};

export const LANIS_LOAN_PURPOSE = {
  import: FNM_PURPOSE_OF_REFINANCE_TO_LOAN_PURPOSE,
  export: LANIS_LOAN_PURPOSE_TO_FNM_PURPOSE_OF_REFINANCE
};
