export const PERIOD_1_3 = "1";
export const PERIOD_4_5 = "2";
export const PERIOD_6_7 = "3";
export const PERIOD_8_10 = "4";
export const PERIOD_11_15 = "5";
export const PERIOD_16_20 = "6";
export const PERIOD_21 = "7";
export const PERIOD_UNKNOWN = "8";

export default Object.freeze({
  [PERIOD_1_3]: "1-3 Years",
  [PERIOD_4_5]: "4-5 Years",
  [PERIOD_6_7]: "6-7 Years",
  [PERIOD_8_10]: "8-10 Years",
  [PERIOD_11_15]: "11-15 Years",
  [PERIOD_16_20]: "16-20 Years",
  [PERIOD_21]: "21 Years+",
  [PERIOD_UNKNOWN]: "Unknown"
});
