import Decimal from "decimal.js";
import * as investment_reo_calculator from "./investment_reo";

export function year_to_month_income(amount) {
  return new Decimal(amount).dividedBy(12);
}

export function month_to_year_income(amount) {
  return new Decimal(amount).times(12);
}

export function payment_to_year(amount, payments) {
  return new Decimal(amount).times(payments);
}

export function year_to_payment(amount, payments) {
  return new Decimal(amount).dividedBy(payments);
}

export function two_year_average(amount1, amount2) {
  return new Decimal(amount1).plus(new Decimal(amount2)).dividedBy(2);
}

//TODO make this more generic so we don't have to always include all of the fields.
export function yearly_income_total(base, overtime, bonuses, commission) {
  return new Decimal(base)
    .plus(overtime)
    .plus(bonuses)
    .plus(commission);
}

export function percent_increase_income(monthly_income, increase) {
  return new Decimal(monthly_income)
    .times(new Decimal(increase))
    .dividedBy(100)
    .plus(new Decimal(monthly_income));
}

export function sum_total_other_income(other_income) {
  return other_income.reduce((accum, entry) => {
    return accum.plus(new Decimal(entry.yearly_amount));
  }, new Decimal(0));
}

export function sum_total_income_sources(income_sources) {
  if (income_sources === null || income_sources === undefined) {
    return new Decimal(0);
  }
  return income_sources.reduce(
    (accum, source) => accum.add(income_source_yearly_amount(source)),
    new Decimal(0)
  );
}

export function compute_total_income(borrowers, investment_properties) {
  const totalPositiveInvestREOIncome = investment_properties_yearly_amount(
    investment_properties
  );
  const totalIncome = borrowers.reduce((accum, borrower) => {
    if (!borrower.income) {
      return accum;
    }
    return accum
      .plus(sum_total_income_sources(borrower.income.sources))
      .plus(sum_total_other_income(borrower.income.other_income));
  }, new Decimal(0));

  return totalIncome.add(
    totalPositiveInvestREOIncome.gt(0) ? totalPositiveInvestREOIncome : 0
  );
}

export function investment_properties_monthly_amount(investment_properties) {
  const amount = investment_reo_calculator.net_income_or_loss_total(
    investment_properties
  );
  return amount.gt(0) ? amount : new Decimal(0);
}

export function investment_properties_yearly_amount(investment_properties) {
  return investment_properties_monthly_amount(investment_properties).mul(12);
}

/**
 * Income - Yearly Income
 */
export function income_source_yearly_amount(source) {
  return new Decimal(source.base_income_0)
    .add(new Decimal(source.overtime_0).add(source.overtime_1).div(2))
    .add(new Decimal(source.bonuses_0).add(source.bonuses_1).div(2))
    .add(new Decimal(source.commission_0).add(source.commission_1).div(2));
}
