import React, { useState } from "react";
import { Link } from "@reach/router";

import DOCUMENT_TYPES from "../../constants/document_types";

import "./css/loan_tool_controls.scss";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { borrower_names } from "../../constants/utils";
import SaveLoan from "../../components/loan_tools/save_loan";
import TextField from "../../components/fields/text_field";
import FnmExport from "../../components/loan_tools/fnm_export";
import { FnmHelpLink } from "../../components/ui/fnm_help";

// const LOCAL_STORAGE_KEY = "LANIS_DOCUMENT_STATE";

export default function LoanToolControls() {
  const [
    { applicant },
    ,
    { documentId, documentType, name },
    { saveDocument, setDocumentName }
  ] = useDocument();
  const [newName, setNewName] = useState(name);
  const [isEditName, setEditName] = useState(false);

  const borrowerNames = borrower_names(applicant.borrowers);

  // function logState() {
  //   // eslint-disable-next-line no-console
  //   console.log(document);
  // }
  //
  // function downloadState() {
  //   download("state.json", JSON.stringify(document, null, 2));
  // }
  //
  // function save() {
  //   let documentState;
  //   try {
  //     documentState = JSON.stringify(document);
  //     window.localStorage.setItem(LOCAL_STORAGE_KEY, documentState);
  //     window.alert("Document has been successfully saved.");
  //   } catch (e) {
  //     window.alert("Sorry! unable to save the document in its current state.");
  //     return;
  //   }
  // }
  //
  // function load(state) {
  //   const documentString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  //   if (!documentString && !state) {
  //     window.alert("Unable to load document.");
  //     return;
  //   }
  //   try {
  //     const documentState = state ? state : JSON.parse(documentString);
  //     // Take care defaults.
  //     Object.entries(fake_state_todo_remove).forEach(([key, value]) => {
  //       if (value && typeof value === "object" && !Array.isArray(value)) {
  //         documentState[key] = {
  //           ...value,
  //           ...documentState[key]
  //         };
  //       }
  //     });
  //     documentState.loans.forEach((loan, index) => {
  //       documentState.loans[index] = {
  //         ...default_loan,
  //         ...documentState.loans[index]
  //       };
  //       Object.entries(default_loan).forEach(([key, value]) => {
  //         if (value && typeof value === "object" && !Array.isArray(value)) {
  //           documentState.loans[index][key] = {
  //             ...value,
  //             ...documentState.loans[index][key]
  //           };
  //         }
  //       });
  //     });
  //
  //     setDocument(documentState);
  //   } catch (e) {
  //     window.alert("Unable to parse document.");
  //   }
  // }
  //
  // function reset() {
  //   setDocument(fake_state_todo_remove);
  // }

  function toggleEditName() {
    setNewName(name);
    setEditName(!isEditName);
  }

  function saveNewDocumentName() {
    setDocumentName(newName);
    setEditName(!isEditName);
    saveDocument();
  }

  return (
    <section className="loan-tool-controls">
      <div className="loan-tool-controls-title">
        {!isEditName && (
          <div className="document-name">
            {name}
            <button
              className="button edit-document-name-button"
              onClick={toggleEditName}
            >
              &#x270e;
            </button>
          </div>
        )}
        {isEditName && (
          <div className="edit-document-name">
            <TextField
              className="edit-document-name-text-field"
              fieldName="new-document-name"
              value={newName}
              onChange={newValue => setNewName(newValue["new-document-name"])}
            />
            <button
              className="button edit-document-name-button"
              onClick={toggleEditName}
            >
              Cancel
            </button>
            <button
              className="button edit-document-name-button"
              onClick={saveNewDocumentName}
            >
              Save
            </button>
          </div>
        )}
      </div>
      <div className="loan-tool-controls-bar">
        <div className="loan-tool-left">
          <div className="loan-tool-type">{DOCUMENT_TYPES[documentType]}</div>
          <div className="borrower-names">
            <div className="flex-direction-column">
              <div>{borrowerNames[0]}</div>
              <div>{borrowerNames[1]}</div>
            </div>
            <div className="flex-direction-column">
              <div>{borrowerNames[2]}</div>
              <div>{borrowerNames[3]}</div>
            </div>
          </div>
        </div>
        {/*<ul>*/}
        {/*<li>*/}
        {/*<button onClick={downloadState}>Download State</button>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<button onClick={logState}>Log State</button>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<button onClick={save}>Save form</button>*/}
        {/*</li>*/}
        {/*<li>*/}
        {/*<button onClick={() => load()}>Load form</button>*/}
        {/*</li>*/}
        {/*</ul>*/}
        {/*<button className="button" onClick={() => load(RANDY_2019_03_21)}>*/}
        {/*Load Randy&apos;s*/}
        {/*</button>*/}
        {/*<button className="button" onClick={reset}>*/}
        {/*Reset form*/}
        {/*</button>*/}
        <FnmHelpLink />
        <FnmExport />
        <SaveLoan
          useAnchor
          className="button"
          name="Summary Sheet"
          path={`/loan-tool/${documentId}/summary-sheet`}
        />
        <Link className="my-loans" to="/">
          My Loans
        </Link>
      </div>
    </section>
  );
}
