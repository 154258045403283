import React, { useState } from "react";
import "./css/subscribe.scss";

import StandardTemplate from "../templates/standard_template/standard_template";
import Checkout from "./checkout";
import UserService from "../services/user_service";
import { basic_monthly } from "../constants/subscription_products";
import { Link, navigate } from "@reach/router";
import { processCompanyProfile, useUser } from "../hooks/user";
import Spinner from "../components/ui/spinner";

export default function Subscribe() {
  const [{ accountProfile, companyProfile }, { setCompanyProfile }] = useUser();
  const [selectedProduct, setSelectedProduct] = useState(basic_monthly);
  const [paymentErrors, setPaymentErrors] = useState([]);
  const [paymentName, setPaymentName] = useState("");
  const [paymentEmail, setPaymentEmail] = useState("");
  const [paymentPhone, setPaymentPhone] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  async function onPaymentSubmit(paymentMethod) {
    setShowSpinner(true);
    setPaymentErrors([]);
    await UserService.postSubscribe(
      selectedProduct,
      paymentName,
      paymentEmail,
      paymentPhone,
      paymentMethod,
      promoCode
    )
      .then(companyProfile => {
        setCompanyProfile(processCompanyProfile(companyProfile));
        setSuccess(true);
      })
      .catch(error => {
        error.json().then(messages => {
          setPaymentErrors(messages || ["There was an error."]);
        });
      });
    setShowSpinner(false);
  }

  if (!isSuccess && companyProfile.subscription_active) {
    navigate("/admin-panel");
    return <></>;
  }

  return (
    <StandardTemplate basic showMoreLinks className="subscribe-page">
      {showSpinner && <Spinner />}
      {isSuccess && (
        <>
          <div className="main-title">Thank you!</div>
          <div className="content center-align">
            Thank you for the subscription. Please click{" "}
            <Link to="/admin-panel" className="button admin-button">
              Admin Panel
            </Link>{" "}
            to go back to the admin panel.
          </div>
        </>
      )}
      {!isSuccess && (
        <>
          <div className="main-title">Subscribe</div>
          <Checkout
            onBeforeCreatePaymentMethod={() => setShowSpinner(true)}
            onSubmit={onPaymentSubmit}
            product={selectedProduct}
            setProduct={setSelectedProduct}
            paymentErrors={paymentErrors}
            setPaymentErrors={setPaymentErrors}
            paymentName={paymentName}
            setPaymentName={setPaymentName}
            paymentEmail={paymentEmail}
            setPaymentEmail={setPaymentEmail}
            paymentPhone={paymentPhone}
            setPaymentPhone={setPaymentPhone}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            profile={accountProfile}
          />
        </>
      )}
    </StandardTemplate>
  );
}
