export const FHA_SECONDARY_RESIDENCE = "0";
export const INVESTMENT_PROPERTY = "1";
export const OWNER = "2";
export const SECOND_HOME = "3";
export const NON_OWNER = "4";

export default Object.freeze({
  [FHA_SECONDARY_RESIDENCE]: "FHA Secondary Residence",
  [INVESTMENT_PROPERTY]: "Investment Property",
  [OWNER]: "Owner",
  [SECOND_HOME]: "Second Home",
  [NON_OWNER]: "Non-Owner"
});
