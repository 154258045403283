import React, { useEffect, useState, useRef } from "react";
import { liquid_funds } from "../../../calculations/reserve";

import { default_reserve } from "../../../schema/reserve";
import {
  TYPE_ASSET,
  TYPE_GIFT,
  TYPE_RETIREMENT,
  RESERVE_TYPES,
  ASSET_SUBTYPES,
  GIFT_SUBTYPES,
  RETIREMENT_SUBTYPES
} from "../../../constants/reserves_account_types";
import {
  PERCENT_CREDITED_FHA,
  PERCENT_CREDITED_AGENCIES
} from "../../../constants/reserves_percentage_credited";

import PercentField from "../../fields/percent_field";
import TextField from "../../fields/text_field";
import CurrencyField from "../../fields/currency_field";
import SelectField from "../../fields/select_field";
import Section from "../../ui/section";
import { useDocument } from "../loan_tool";

export default function EditReserve({ reserve, onChange, showReservesTable }) {
  const [,,,{ saveDocument }] = useDocument();
  const [reserveEdit, setReserveEdit] = useState({
    ...default_reserve,
    ...reserve
  });
  const callback = useRef();
  useEffect(() => {
    callback.current = () => {
      if (reserveEdit.is_modified) {
        if (
          reserveEdit.type !== "" &&
          reserveEdit.sub_type !== "" &&
          reserveEdit.funds !== "0"
        ) {
          if (
            window.confirm(
              "You have unsaved data in reserve form. Would you like to save it?"
            )
          ) {
            save();
          } else {
            // do nothing
          }
        } else {
          alert(
            "Not all necessary fields were entered. Therefore changes would be dismissed."
          );
        }
      }
    };
  }, [reserveEdit]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function save() {
    delete reserveEdit.is_modified;
    onChange(reserveEdit);
    saveDocument();
    showReservesTable();
  }

  function cancel() {
    showReservesTable();
  }

  function onChangeType({ type }) {
    setReserveEdit({
      ...reserveEdit,
      type,
      sub_type: "",
      is_modified: true
    });
  }

  function change(updatedState) {
    setReserveEdit({ ...reserveEdit, ...updatedState, is_modified: true });
  }

  const { type, sub_type, bank_name, account_number, funds } = reserveEdit;
  const percentLoans = PERCENT_CREDITED_AGENCIES[sub_type] || "0";
  const percentFHA = PERCENT_CREDITED_FHA[sub_type] || "0";
  let subTypeOptions;
  if (type === TYPE_ASSET) {
    subTypeOptions = ASSET_SUBTYPES;
  } else if (type === TYPE_GIFT) {
    subTypeOptions = GIFT_SUBTYPES;
  } else if (type === TYPE_RETIREMENT) {
    subTypeOptions = RETIREMENT_SUBTYPES;
  }

  const saveDisabled = type === "" || sub_type === "" || funds === "0";

  return (
    <Section className="reserves-edit-reserve" name="Edit Reserve">
      <div className="flex">
        <div className="third-width">
          <SelectField
            fieldName="type"
            name="Account Type"
            value={type}
            onChange={onChangeType}
            selectOptions={RESERVE_TYPES}
          />

          <SelectField
            fieldName="sub_type"
            name="Account Sub Type"
            value={sub_type}
            onChange={change}
            selectOptions={subTypeOptions}
            sortLabel
            title="LANIS Training Tip: If you list funds available, you must select the appropriate account type in the field to indicate where the funds are coming from."
          />

          <TextField
            fieldName="bank_name"
            name="Bank Name"
            value={bank_name}
            onChange={change}
          />

          <TextField
            fieldName="account_number"
            name="Account Number"
            value={account_number}
            onChange={change}
          />

          <CurrencyField
            fieldName="funds"
            name="Total Funds"
            value={funds}
            onChange={change}
          />
        </div>

        <div className="third-width">
          <div className="reserves-edit-reserve-title">
            FannieMae / FreddieMac / Jumbo Loans
          </div>
          <PercentField
            name="Percentage Credited"
            title="LANIS Training tip: Stocks and Bonds and certain investment accounts are calculated at 70% for agency type of loans. FHA may allow 100% of these funds. For regular retirement accounts, 60% is the calculation used for Reserves."
            readOnly
            value={percentLoans}
          />
          <CurrencyField
            name="Total Funds"
            readOnly
            value={liquid_funds(funds, percentLoans)}
          />
        </div>

        <div className="third-width">
          <div className="reserves-edit-reserve-title">FHA Loans</div>
          <PercentField
            name="Percentage Credited"
            title="LANIS Training tip: Stocks and Bonds and certain investment accounts are calculated at 70% for agency type of loans. FHA may allow 100% of these funds. For regular retirement accounts, 60% is the calculation used for Reserves."
            readOnly
            value={percentFHA}
          />
          <CurrencyField
            name="Total Funds"
            readOnly
            value={liquid_funds(funds, percentFHA)}
          />
        </div>
      </div>
      <div>
        <button className="button" onClick={cancel}>
          Cancel
        </button>
        <button className="button" disabled={saveDisabled} onClick={save}>
          Save
        </button>
      </div>
    </Section>
  );
}
