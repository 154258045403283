import React from "react";

import { loan_term } from "../../../calculations/loan";

import { ARM_TERMS } from "../../../constants/loan_terms";
import ORIGINATION_FEE_PAID_BY from "../../../constants/origination_fee_paid_by";
import LOAN_PAYMENT_TYPES, {
  INTEREST_ONLY,
  PAYMENT_PERIODS
} from "../../../constants/loan_payment_type";
import LOAN_TYPES, {
  REFINANCE_LOAN_TYPES
} from "../../../constants/loan_types";
import PROPERTY_TYPES, {
  HOA_PROPERTY_TYPES
} from "../../../constants/property_type";
import FREQUENCIES from "../../../constants/frequencies";
import { YES, YES_NO } from "../../../constants/yes_no";

import CurrencyField from "../../fields/currency_field";
import TextField from "../../fields/text_field";
import NumberField from "../../fields/number_field";
import PercentField from "../../fields/percent_field";

import ArmRateAdjustmentsTable from "./arm_rate_adjustments";
import PaymentAdjustmentTable from "./payment_adjustment_table";
import { useDocument } from "../loan_tool";
import { REFINANCE } from "../../../constants/document_types";

export default function LoanEstimateEntry({ loan, loanIndex }) {
  const [, , { documentType }] = useDocument();
  const {
    balloon_payment,
    balloon_payment_period,
    base_fee,
    brokered_loan,
    display_fee,
    interest_only_period,
    lender,
    lender_program,
    origination_fee_paid_by,
    payment_type,
    prepayment_penalty,
    prepayment_terms,
    rate,
    type
  } = loan;

  const isRefinance = documentType === REFINANCE;

  return (
    <div>
      <div className="le-price-engine-title">Loan {loanIndex + 1}</div>

      <TextField
        name="Origination Fee Paid By"
        readOnly
        value={ORIGINATION_FEE_PAID_BY[origination_fee_paid_by]}
      />

      <TextField name="Display Fee" readOnly value={YES_NO[display_fee]} />

      <TextField name="Brokered Loan" readOnly value={YES_NO[brokered_loan]} />

      <TextField name="Lender" readOnly value={lender} />

      <TextField name="Lender Program" readOnly value={lender_program} />

      <TextField
        readOnly
        name="Loan Type"
        value={isRefinance ? REFINANCE_LOAN_TYPES[type] : LOAN_TYPES[type]}
      />

      <PercentField
        readOnly
        name="Rate"
        decimalPlaces={3}
        value={rate}
        title="LANIS Training Tip: Loan 1 should always be the Lowest Rate Possible and it is used in the Anti Stearing Disclosure. For other Loan Options, increase the Interest Rate for the appropriate Loan Type and the closing costs desired."
      />

      <PercentField readOnly name="Base Fee" value={base_fee} />

      <NumberField readOnly name="Amortization" value={loan_term(loan)} />

      <TextField
        readOnly
        name="Property Type"
        value={PROPERTY_TYPES[loan.property_type]}
      />

      {HOA_PROPERTY_TYPES.includes(loan.property_type) && (
        <>
          <CurrencyField readOnly name="HOA 1" value={loan.hoa1} />
          <TextField
            readOnly
            name="HOA 1 Frequency"
            value={FREQUENCIES[loan.hoa1_frequency]}
          />
          <CurrencyField readOnly name="HOA 2" value={loan.hoa2} />
          <TextField
            readOnly
            name="HOA 2 Frequency"
            value={FREQUENCIES[loan.hoa2_frequency]}
          />
          <CurrencyField
            readOnly
            name="Mello Roos"
            value={loan.mello_roos_amount}
          />
          <TextField
            readOnly
            name="Mello Roos Frequency"
            value={FREQUENCIES[loan.mello_roos_frequency]}
          />
        </>
      )}

      <TextField
        readOnly
        name="Payment Type"
        value={LOAN_PAYMENT_TYPES[payment_type]}
      />

      {payment_type === INTEREST_ONLY && (
        <TextField
          name="Interest only period"
          readOnly
          value={PAYMENT_PERIODS[interest_only_period]}
        />
      )}

      <TextField
        readOnly
        name="Balloon Payment"
        value={YES_NO[balloon_payment]}
      />

      {balloon_payment === YES && (
        <TextField
          name="Balloon Payment Period"
          readOnly
          value={PAYMENT_PERIODS[balloon_payment_period]}
        />
      )}

      <TextField
        readOnly
        name="Prepayment Penalty"
        value={YES_NO[prepayment_penalty]}
      />

      {prepayment_penalty === YES && (
        <TextField readOnly name="Prepayment Terms" value={prepayment_terms} />
      )}

      {ARM_TERMS.includes(loan.term) && (
        <>
          <ArmRateAdjustmentsTable readOnly loan={loan} />
          <PaymentAdjustmentTable loan={loan} />
        </>
      )}
    </div>
  );
}
