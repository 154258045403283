import React from "react";
import { YES_NO_TBD } from "../../../constants/yes_no";
import CREDIT_SCORE from "../../../constants/credit_score";
import OCCUPANCY from "../../../constants/occupancy";
import DateField from "../../fields/date_field";
import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";
import { estimated_loan_amount } from "../../../calculations/investment_reo";
import IMPOUNDS_FOR_LOAN from "../../../constants/impounds_for_loan";

import { useDocument } from "../../loan_tools/loan_tool";
import TrainingTip from "../../ui/training_tip";

export default function GeneralInfo() {
  const [{ applicant, property, property_to_be_sold }] = useDocument();
  const firstBorrower = applicant.borrowers[0] || {};

  return (
    <>
      <tbody className="with-border with-td-border">
        <tr>
          <td className="center-align thick-top-border no-right-border">
            Loan Locked
          </td>
          <td className="center-align thick-top-border no-left-border">Date</td>
          <td className="thick-top-border no-border" />
          <td className="center-align thick-top-border no-border bold">
            Sales Price
          </td>
          <td className="center-align thick-top-border no-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={property.listed_price}
            />
          </td>
          <td className="thick-top-border no-right-border no-bottom-border bold">
            Credit Score
          </td>
          <td className="thick-top-border no-border">
            {CREDIT_SCORE[firstBorrower.lowest_credit_mid_score]}{" "}
            <TrainingTip content="LANIS Training Tip: The Score displayed is the Lowest Middle Credit Score from All Borrowers and comes from the Applicant Page.  The Add On Fees based on this score and the LLPA Table determines the pricing on the loan.   With many Lenders, if you are over 740 or 760, there is no additional range as you have the best pricing available." />
          </td>
          <td className="thick-top-border no-border" />
        </tr>
        <tr>
          <td className="center-align">{YES_NO_TBD[applicant.loan_locked]}</td>
          <td className="center-align">
            <DateField naked value={applicant.loan_lock_date} />
          </td>
          <td className="right-align no-border">Target LTV</td>
          <td className="center-align no-border">
            <PercentField naked fixedDigits={0} value={property.target_ltv} />
          </td>
          <td className="center-align no-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={estimated_loan_amount(property)}
            />
          </td>
          <td className="no-top-border italic" colSpan="3">
            (Score or range for Pricing of LLPA&apos;s)
          </td>
        </tr>
        <tr>
          <td colSpan="2">Escrow Opened</td>
          <td>{YES_NO_TBD[property.escrow_opened]}</td>
          <td colSpan="2" rowSpan="2">
            <div>Occupancy Type</div>
            <div>{OCCUPANCY[property.occupancy]}</div>
          </td>
          <td colSpan="3" rowSpan="2">
            <div className="bold italic">Impounds For This Loan?</div>
            <div>{IMPOUNDS_FOR_LOAN[property.impounds_for_loan]}</div>
          </td>
        </tr>
        <tr>
          <td colSpan="2">Different Title / Escrow Company</td>
          <td>{YES_NO_TBD[property.different_escrow_title]}</td>
        </tr>
        <tr>
          <td colSpan="2">Close OF Escrow Date</td>
          <td>
            <DateField naked value={property.close_of_escrow_date} />
          </td>
          <td colSpan="5" rowSpan="2" />
        </tr>
        <tr>
          <td colSpan="2">Concurrent COE</td>
          <td>{YES_NO_TBD[property_to_be_sold.concurrent_close]}</td>
        </tr>
      </tbody>
    </>
  );
}
