import React from "react";
import { MONTHLY } from "../../constants/income_calculator_types";
import {
  yearly_income_total,
  year_to_month_income,
  month_to_year_income,
  two_year_average
} from "../../calculations/income";

import CurrencyField from "../fields/currency_field";
import NumberField from "../fields/number_field";

export default function AnnualIncomeCalculator({
  incomeSource,
  onChangeIncome
}) {
  const {
    year_0,
    year_1,
    base_income_0,
    bonuses_0,
    commission_0,
    overtime_0,
    overtime_1,
    base_income_1,
    bonuses_1,
    commission_1,
    calculator
  } = incomeSource;
  const monthlyMode = calculator === MONTHLY;

  const year0Total = yearly_income_total(
    base_income_0,
    overtime_0,
    bonuses_0,
    commission_0
  );

  const year1Total = yearly_income_total(
    base_income_1,
    overtime_1,
    bonuses_1,
    commission_1
  );

  const twoYearAverageTotal = two_year_average(overtime_0, overtime_1)
    .add(two_year_average(bonuses_0, bonuses_1))
    .add(two_year_average(commission_0, commission_1));

  function onChange(updatedState) {
    onChangeIncome(updatedState);
  }

  function onChangeMonthlyValue(updatedState) {
    Object.keys(updatedState).forEach(fieldKey => {
      updatedState[fieldKey] = month_to_year_income(
        updatedState[fieldKey]
      ).toString();
    });
    onChangeIncome(updatedState);
  }

  return (
    <div className="edit-borrower-income-calculator">
      <table>
        <thead>
          <tr>
            <th>
              <NumberField
                fieldName="year_0"
                onChange={onChange}
                value={year_0}
              />
            </th>
            <th>Per Year</th>
            <th>Per Month</th>

            <th>
              <NumberField
                fieldName="year_1"
                onChange={onChange}
                value={year_1}
              />
            </th>
            <th>Per Month</th>
            <th>2 Yr Avg.</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Base</td>
            <td>
              <CurrencyField
                fieldName="base_income_0"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "1"}
                onChange={onChange}
                value={base_income_0}
              />
            </td>
            <td>
              <CurrencyField
                fieldName="base_income_0"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "1"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(base_income_0)}
              />
            </td>

            <td>
              <CurrencyField
                fieldName="base_income_1"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "4"}
                onChange={onChange}
                value={base_income_1}
              />
            </td>
            <td>
              <CurrencyField
                fieldName="base_income_1"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "4"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(base_income_1)}
              />
            </td>
            <td />
          </tr>

          <tr>
            <td>Bonuses</td>
            <td>
              <CurrencyField
                fieldName="bonuses_0"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "2"}
                onChange={onChange}
                value={bonuses_0}
              />
            </td>

            <td>
              <CurrencyField
                fieldName="bonuses_0"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "2"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(bonuses_0)}
              />
            </td>

            <td>
              <CurrencyField
                fieldName="bonuses_1"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "5"}
                onChange={onChange}
                value={bonuses_1}
              />
            </td>
            <td>
              <CurrencyField
                fieldName="bonuses_1"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "5"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(bonuses_1)}
              />
            </td>

            <td>
              <CurrencyField
                tabIndex="-1"
                readOnly
                value={two_year_average(bonuses_0, bonuses_1)}
              />
            </td>
          </tr>

          <tr>
            <td>Commission</td>
            <td>
              <CurrencyField
                fieldName="commission_0"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "3"}
                onChange={onChange}
                value={commission_0}
              />
            </td>
            <td>
              <CurrencyField
                fieldName="commission_0"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "3"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(commission_0)}
              />
            </td>

            <td>
              <CurrencyField
                fieldName="commission_1"
                readOnly={monthlyMode}
                tabIndex={monthlyMode ? "-1" : "6"}
                onChange={onChange}
                value={commission_1}
              />
            </td>
            <td>
              <CurrencyField
                fieldName="commission_1"
                readOnly={!monthlyMode}
                tabIndex={!monthlyMode ? "-1" : "6"}
                onChange={onChangeMonthlyValue}
                value={year_to_month_income(commission_1)}
              />
            </td>

            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={two_year_average(commission_0, commission_1)}
              />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>Total</td>
            <td>
              <CurrencyField readOnly tabIndex="-1" value={year0Total} />
            </td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={year_to_month_income(year0Total)}
              />
            </td>
            <td>
              <CurrencyField readOnly tabIndex="-1" value={year1Total} />
            </td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={year_to_month_income(year1Total)}
              />
            </td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={twoYearAverageTotal}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
