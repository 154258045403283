import React from "react";
import "./css/checkbox_field.scss";

import TrainingTip from "../ui/training_tip";

export default function CheckboxField({
  className,
  onChange,
  fieldName,
  id,
  name,
  title,
  readOnly,
  checked,
  showRequired
}) {
  function class_name() {
    let classname = "input-field checkbox-field";
    if (className) {
      classname += ` ${className}`;
    }
    return classname;
  }

  function change(event) {
    if (onChange && fieldName) {
      onChange({
        [fieldName]: event.target.checked
      });
    }
  }

  return (
    <div className="input-field-container checkbox-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>
      <div className={class_name()}>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={change}
          disabled={readOnly}
        />
      </div>
    </div>
  );
}
