export const PRIMARY_RESIDENCE = "1";
export const SECOND_HOME = "2";
export const SECOND_LOAN_SUB = "3";
export const AUTO = "4";
export const BOAT = "5";
export const CHILDCARE = "6";
export const HOME_EQUITY = "7";
export const INSTALLMENT = "8";
export const LEASE = "9";
export const LIENS = "10";
export const MORTGAGE = "11";
export const MOTORCYCLE = "12";
export const THIRTY_DAY_CHARGE_ACCT = "13";
export const OTHER_EXPENSES = "14";
export const OTHER_LIABILITIES = "15";
export const REVOLVING_CHARGE = "16";
export const RV = "17";
export const SEPARATE_MAINTENANCE = "18";
export const STUDENT_LOAN = "19";
export const TAXES = "20";
export const ALIMONY = "21";

export const LIABILITY_TYPES_FOR_PAYMENTS_LEFT = [AUTO, BOAT, MOTORCYCLE, RV];

export default Object.freeze({
  [PRIMARY_RESIDENCE]: "Primary Residence",
  [SECOND_HOME]: "2nd Home",
  [SECOND_LOAN_SUB]: "2nd Loan Sub",
  [AUTO]: "Auto Loan (s)",
  [BOAT]: "Boat",
  [CHILDCARE]: "Child Care",
  [ALIMONY]: "Alimony",
  [HOME_EQUITY]: "Home Equity Line Of Credit",
  [INSTALLMENT]: "Installment Loan",
  [LEASE]: "Lease Payments",
  [LIENS]: "Liens",
  [MORTGAGE]: "Mortgage",
  [MOTORCYCLE]: "Motorcycle",
  [THIRTY_DAY_CHARGE_ACCT]: "Open 30 day charge acc",
  [OTHER_EXPENSES]: "Other Expenses",
  [OTHER_LIABILITIES]: "Other Liabilities",
  [REVOLVING_CHARGE]: "Revolving Charge",
  [RV]: "RV",
  [SEPARATE_MAINTENANCE]: "Separate Maintenance",
  [STUDENT_LOAN]: "Student Loan",
  [TAXES]: "Taxes"
});
