import React, { useEffect, useState, useRef } from "react";

import {
  equity_from_pending_sale,
  estimate_real_estate_fees,
  estimate_seller_closing_costs,
  lender_fees_estimate,
  net_from_sale
} from "../../../calculations/reserve";

import { balance_pay_offs_total } from "../../../calculations/debt_info";

import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";

import { useDocument } from "../../loan_tools/loan_tool";
import { full_month_of_interest_for_fha_loan } from "../../../calculations/loan";
import { first_current_monthly_payment } from "../../../calculations/property";
import Section from "../../ui/section";

export default function EquityFromSaleCalculator({
  onChange,
  showReservesTable
}) {
  const [document, , { documentType }, { saveDocument }] = useDocument();
  const [propertyToBeSold, setPropertyToBeSold] = useState({
    ...document.property_to_be_sold
  });
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (propertyToBeSold.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Equity From Sale Calculator. Would you like to save it before you leave?"
          )
        ) {
          save();
        }
      }
    };
  }, [propertyToBeSold]);

  useEffect(() => {
    return () => {
      callback.current();
    };
  }, []);

  function change(updatedState) {
    setPropertyToBeSold({
      ...propertyToBeSold,
      ...updatedState,
      is_modified: true
    });
  }

  function save() {
    delete propertyToBeSold.is_modified;
    onChange({ property_to_be_sold: propertyToBeSold });
    saveDocument();
    showReservesTable();
  }

  function cancel() {
    showReservesTable();
  }

  const { accountSummaries, residences, loans, is_selling_property } = document;

  const {
    current_market_value,
    first_loan_balance,
    second_loan_balance,
    second_current_monthly_payment,
    lender_fees,
    hoa_transfer_fees,
    pay_off_back_taxes,
    seller_concessions,
    rebate_back_to_borrower_closing_costs,
    percent_seller_closing_costs,
    real_estate_commission
  } = propertyToBeSold;

  const lenderFeesEstimate = lender_fees_estimate(propertyToBeSold);

  return (
    <Section
      className="reserves-equity-from-sale-calculator"
      name="Equity From Sale Calculator"
    >
      <table>
        <thead>
          <tr>
            <td>
              <h4>Current home net cash out estimate</h4>
            </td>
            <td />
            <td className="center-align">Sale of Existing Home </td>
            <td className="center-align">Monthly P&amp;I Payment </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>Current Home Sales Price</td>
            <td />
            <td>
              <CurrencyField readOnly value={current_market_value} />
            </td>
          </tr>

          <tr>
            <td>Present Loan Balance 1st TD</td>
            <td />
            <td>
              <CurrencyField readOnly value={first_loan_balance} />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={first_current_monthly_payment(propertyToBeSold)}
              />
            </td>
          </tr>

          <tr>
            <td>Present Loan Balances 2nd TD</td>
            <td />
            <td>
              <CurrencyField readOnly value={second_loan_balance} />
            </td>
            <td>
              <CurrencyField readOnly value={second_current_monthly_payment} />
            </td>
          </tr>

          <tr>
            <td>Pay Off Demands - Lender Estimate (Payment + $100.00)</td>
            <td />
            <td>
              <CurrencyField readOnly value={lenderFeesEstimate} />
            </td>
          </tr>

          <tr>
            <td>HOA Transfer fees </td>
            <td />
            <td>
              <CurrencyField
                fieldName="hoa_transfer_fees"
                value={hoa_transfer_fees}
                onChange={change}
              />
            </td>
          </tr>

          <tr>
            <td>Seller Concessions</td>
            <td />
            <td>
              <CurrencyField
                fieldName="seller_concessions"
                value={seller_concessions}
                onChange={change}
              />
            </td>
          </tr>

          <tr>
            <td>Pay Off Back Taxes</td>
            <td />
            <td>
              <CurrencyField
                fieldName="pay_off_back_taxes"
                value={pay_off_back_taxes}
                onChange={change}
              />
            </td>
          </tr>

          <tr>
            <td>Full Month Mortgage Insurance For FHA Loan</td>
            <td />
            <td>
              <CurrencyField
                readOnly
                value={full_month_of_interest_for_fha_loan(
                  is_selling_property,
                  propertyToBeSold
                )}
              />
            </td>
          </tr>

          <tr>
            <td>Rebate back to borrower closing costs</td>
            <td />
            <td>
              <CurrencyField
                fieldName="rebate_back_to_borrower_closing_costs"
                value={rebate_back_to_borrower_closing_costs}
                onChange={change}
              />
            </td>
          </tr>

          <tr>
            <td>Lender Fees</td>
            <td />
            <td>
              <CurrencyField
                fieldName="lender_fees"
                value={lender_fees}
                onChange={change}
              />
            </td>
          </tr>

          <tr>
            <td>Estimate of Seller Closing Costs (Based on Sale Price)</td>
            <td>
              <PercentField
                fieldName="percent_seller_closing_costs"
                value={percent_seller_closing_costs}
                onChange={change}
              />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={estimate_seller_closing_costs(propertyToBeSold)}
              />
            </td>
          </tr>
          <tr>
            <td>Estimate of Real Estate Fees (Per Listing Agreement)</td>
            <td>
              <PercentField
                fieldName="real_estate_commission"
                value={real_estate_commission}
                onChange={change}
              />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={estimate_real_estate_fees(propertyToBeSold)}
              />
            </td>
          </tr>
          <tr>
            <td>Net From Sale</td>
            <td />
            <td>
              <CurrencyField
                readOnly
                value={net_from_sale(
                  propertyToBeSold,
                  residences,
                  is_selling_property
                )}
              />
            </td>
          </tr>

          {loans.length && (
            <tr>
              <td colSpan="4">
                <table>
                  <thead>
                    <tr>
                      <th />
                      {loans.map((loan, index) => (
                        <th key={index}>Loan {index + 1}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Pay Off Debt</td>
                      {loans.map((loan, index) => (
                        <td key={index}>
                          <CurrencyField
                            readOnly
                            value={balance_pay_offs_total(
                              Object.values(accountSummaries),
                              residences,
                              loan
                            )}
                          />
                        </td>
                      ))}
                    </tr>
                    {/*<tr>*/}
                    {/*<td>Total of all Loans, Fees &amp; Pay Offs</td>*/}
                    {/*{loans.map((loan, index) => (*/}
                    {/*<td key={index}>*/}
                    {/*<CurrencyField*/}
                    {/*readOnly*/}
                    {/*value={sum_total_loans_fees_payoff(*/}
                    {/*propertyToBeSold,*/}
                    {/*accountSummaries,*/}
                    {/*residences,*/}
                    {/*loan,*/}
                    {/*is_selling_property*/}
                    {/*)}*/}
                    {/*/>*/}
                    {/*</td>*/}
                    {/*))}*/}
                    {/*</tr>*/}
                    <tr>
                      <td>Approximate Funds to seller</td>
                      {loans.map((loan, index) => (
                        <td key={index}>
                          <CurrencyField
                            readOnly
                            value={equity_from_pending_sale(
                              documentType,
                              propertyToBeSold,
                              accountSummaries,
                              residences,
                              loan,
                              is_selling_property
                            )}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <button className="button" onClick={cancel}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
