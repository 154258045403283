import React, { useState } from "react";

import Section from "../ui/section";
import TextField from "../fields/text_field";
import {
  default_account_profile,
  default_company_profile
} from "../../schema/profile";
import SystemAdminService from "../../services/system_admin_service";
import { handleErrorMessages } from "../../services/utils";
import ErrorMessages from "../ui/error_messages";
import CheckboxField from "../fields/checkbox_field";

export default function AddCompany({ onExit }) {
  const [companyProfile, setCompanyProfile] = useState({
    ...default_company_profile
  });
  const [accountProfile, setAccountProfile] = useState({
    ...default_account_profile
  });
  const [password, setPassword] = useState("");
  const [loanOfficerRole, setLoanOfficerRole] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  function onCompanyProfileChange(updates) {
    setCompanyProfile({
      ...companyProfile,
      ...updates
    });
  }

  function onAccountProfileChange(updates) {
    setAccountProfile({
      ...accountProfile,
      ...updates
    });
  }

  async function onSave() {
    await SystemAdminService.postNewCompany(
      companyProfile,
      accountProfile.email,
      password,
      accountProfile.first_name,
      accountProfile.last_name,
      accountProfile,
      loanOfficerRole
    )
      .then(() => onExit())
      .catch(error => handleErrorMessages(error, setErrorMessages));
  }

  return (
    <Section name="Add Company" className="add-company">
      <ErrorMessages messages={errorMessages} />
      <div className="half-width">
        <p className="bold">Company Information</p>
        <TextField
          fieldName="company_name"
          name="Company Name"
          value={companyProfile.company_name}
          onChange={onCompanyProfileChange}
        />
        <TextField
          typeOverride="email"
          fieldName="email"
          name="Company Email"
          value={companyProfile.email}
          onChange={onCompanyProfileChange}
        />
        <p>
          <span className="bold">Admin Information</span> (This will create a
          new user account for the company admin)
        </p>
        <TextField
          typeOverride="email"
          fieldName="email"
          name="Admin Email"
          value={accountProfile.email}
          onChange={onAccountProfileChange}
        />
        <TextField
          typeOverride="password"
          fieldName="password"
          name="Password"
          value={password}
          onChange={value => setPassword(value.password)}
        />
        <TextField
          fieldName="first_name"
          name="Admin First Name"
          value={accountProfile.first_name}
          onChange={onAccountProfileChange}
        />
        <TextField
          fieldName="last_name"
          name="Admin Last Name"
          value={accountProfile.last_name}
          onChange={onAccountProfileChange}
        />
        <CheckboxField
          name="Make Company Admin as Loan Officer"
          fieldName="loan_officer_role"
          checked={loanOfficerRole}
          onChange={value => setLoanOfficerRole(value.loan_officer_role)}
        />
      </div>
      <div>
        <button className="button" onClick={onExit}>
          Cancel
        </button>
        <button className="button" onClick={onSave}>
          Save
        </button>
      </div>
    </Section>
  );
}
