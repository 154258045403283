import React from "react";

import {
  ltv_first_td,
  emd,
  heloc_rate,
  total_adjustments_other_credits,
  total_lpmi_adjustment_fees,
  seller_concessions,
  cash_out_add_to_loan_amount,
  current_second_loan_amount,
  current_third_loan_amount
} from "../../../calculations/loan";

import MORTGAGE_PAYMENT_PERIODS from "../../../constants/mortgage_payment_periods";
import {
  LTV_MORTGAGE_INSURANCE_PERCENT_MINIMUM,
  USDA_MONTHLY_MORTGAGE_INSURANCE_RATE
} from "../../../constants/fixed_values";
import SECOND_LOAN_OPTIONS, {
  SECOND_LOAN_PERCENT,
  SECOND_LOAN_AMOUNT
} from "../../../constants/second_loan_options";
import {
  CONVENTIONAL_LOANS,
  FANNIEMAE,
  FHA_STREAMLINE,
  FREDDIEMAC,
  LOAN_TYPE_CATEGORY_NAMES,
  VA_IRRRL
} from "../../../constants/loan_types";
import {
  CASH_DOWN_PAYMENT_TYPES,
  FIXED_LOAN_AMOUNT_PAYMENT_TYPES,
  PERCENT_PAYMENT_TYPES,
  SECOND_LOAN_PAYMENT_TYPES
} from "../../../constants/payment_type";
import LOAN_RATES, {
  FIXED_RATE,
  VARIABLE_RATE
} from "../../../constants/loan_rates";
import FHA_MORTGAGE_INSURANCE_RATES from "../../../constants/fha_mortgage_insurance_rates";
import { NO, YES, YES_NO } from "../../../constants/yes_no";

import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";
import SelectField from "../../fields/select_field";
import { useDocument } from "../loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import {
  is_fha,
  is_show_lpmi_adjustment_fees,
  is_usda,
  is_va,
  will_second_subordinate
} from "../../../constants/utils";
import Section from "../../ui/section";
import VAPricingTool from "./va_pricing_tool";
import TextField from "../../fields/text_field";
import Decimal from "decimal.js";

const SORTED_LOAN_RATES = Object.entries(LOAN_RATES)
    .sort(([a], [b]) => {
      return new Decimal(a).greaterThan(b) ? 1 : -1;
    })
    .map(([, value]) => value);

export default function LoanInformation({
  showOtherAdjustmentsPage,
  showLPMIAdjustmentFeesPage,
  loan,
  updateLoan,
  areAllLoansSameEmdAndSellerConcessions,
  makeAllLoansSameEmdAndSellerConcessions
}) {
  const [
    { property_to_be_sold, property, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isPercentPaymentType = PERCENT_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );
  const isSecondLoanPaymentType = SECOND_LOAN_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );
  const isCashDownPaymentType = CASH_DOWN_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );
  const isFixedLoanAmountPaymentType = FIXED_LOAN_AMOUNT_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );

  const ltvFirstTd = ltv_first_td(documentType, loan, property);
  const showLPMIAdjustmentFees = is_show_lpmi_adjustment_fees(
    documentType,
    loan,
    property
  );

  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;

  function refinance_loan_type_training_tip() {
    if (loan.type === FANNIEMAE || loan.type === FREDDIEMAC) {
      return (
        <>
          <div>LANIS Training Tip: Conventional Cash Out</div>
          <div>
            Cash back to the borrower may be disbursed up to the greater of 1%
            of the new refinance mortgage or $2,000. In the event of excess cash
            to the borrower, the lender may apply a principal curtailment to
            bring the loan into compliance with the maximum cash back
            requirement. The maximum amount of curtailment permitted is the
            greater of 1% of the new refinance mortgage or $2,000.
          </div>
        </>
      );
    }
    if (is_fha(loan.type)) {
      return (
        <>
          <div>
            LANIS Training Tip: FHA Standard Refi and FHA Streamline Cash Out
          </div>
          <div>
            A Standard FHA Refi you can typically have Cash Out up to 85% LTV.
            Check with your Lender to see if there are any Credit Overlays for
            Cash Out. For a Streamline Refi, Incidental cash back to the
            borrower not to exceed $500.00 for a FHA Streamline. In the event
            that it is discovered prior to closing that an incorrect loan amount
            calculation will result in cash back to the borrower in excess of
            $500, the loan amount must be corrected. Principal reductions are
            permitted only for unforeseen circumstances at closing, and are
            limited to the lesser of $2,500 or 2% of the original loan amount
          </div>
        </>
      );
    }
    if (is_va(loan.type)) {
      return (
        <>
          <div>
            LANIS Training Tip: Standard VA Cash Out and VA IRRRL Streamline
          </div>
          <div>
            All VA Refinance Loans are considered Cash Out per the Guidelines.
            Check with your Lender on a Standard Cash Out Refinance if there are
            any Credit Overlays or limits on the Amount of Cash at the COE. For
            VA IRRRL, VA does not set a “ceiling” or a specific dollar
            limitation on cash refunds resulting from adjustments at closing.
            However, if a situation involves a borrower receiving more than
            $500, consult VA as to its acceptability. Lenders and VA personnel
            should exercise common sense when assessing such situations and draw
            from basic program information to know the difference between an
            equity withdrawal and cash from unforeseen circumstances. In the
            event that it is discovered prior to closing that an incorrect loan
            amount calculation will result in cash back to the borrower in
            excess of $500, the loan amount must be corrected. Principal
            reductions are permitted only for unforeseen circumstances at
            closing, and are limited to the lesser of $2,500 or 2% of the
            original loan amount.
          </div>
        </>
      );
    }
    if (is_usda(loan.type)) {
      return (
        <>
          <div>LANIS Training Tip: USDA Cash Out</div>
          <div>
            The borrowers may not receive any cash back at closing, other than
            the documented amount for costs paid in advance by the borrower from
            their personal funds (appraisal, credit report). Loan fees paid by
            the borrower(s) with credit cards or other short terms loans may not
            be reimbursed at closing.
          </div>
        </>
      );
    }
    return "";
  }

  return (
    <Section className="down-payment-loan-information" name="Loan Information">
      <div className="flex">
        <div className="third-width">
          {isPercentPaymentType && (
            <PercentField
              fieldName="percent_down"
              name={
                isPurchase ? "1st Mortgage Percent Down" : "Equity Remaining"
              }
              fixedDigits={2}
              value={loan.percent_down}
              onChange={updateLoan}
            />
          )}

          {isCashDownPaymentType && (
            <CurrencyField
              fieldName="cash_down"
              name="1st Mortgage Cash Down"
              value={loan.cash_down}
              onChange={updateLoan}
            />
          )}

          {isFixedLoanAmountPaymentType && (
            <CurrencyField
              fieldName="fixed_loan_amount"
              name={
                isRefinance
                  ? "1st Fixed Loan Amount"
                  : "1st Mortgage Cash Down"
              }
              value={loan.fixed_loan_amount}
              onChange={updateLoan}
            />
          )}

          {isRefinance && (
            <>
              {(current_second_loan_amount(
                documentType,
                loan,
                property_to_be_sold,
                is_selling_property
              ).gt(0) ||
                new Decimal(
                  property_to_be_sold.second_current_monthly_payment
                ).gt(0)) && (
                <>
                  <SelectField
                    name="Will 2nd Subordinate"
                    fieldName="will_second_subordinate"
                    value={loan.will_second_subordinate}
                    selectOptions={YES_NO}
                    showDefaultOption
                    onChange={updateLoan}
                  />
                  <CurrencyField
                    name="Current 2nd Loan Amount"
                    readOnly
                    value={current_second_loan_amount(
                      documentType,
                      loan,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                  <CurrencyField
                    name="Current 2nd Payment"
                    readOnly
                    value={property_to_be_sold.second_current_monthly_payment}
                  />
                </>
              )}

              {(current_third_loan_amount(
                loan,
                property_to_be_sold,
                is_selling_property
              ).gt(0) ||
                new Decimal(
                  property_to_be_sold.miscellaneous_housing_payment
                ).gt(0)) && (
                <>
                  <SelectField
                    name="Will 3rd Subordinate"
                    fieldName="will_third_subordinate"
                    value={loan.will_third_subordinate}
                    selectOptions={YES_NO}
                    showDefaultOption
                    onChange={updateLoan}
                  />
                  <CurrencyField
                    name="Current 3rd Loan Amount"
                    readOnly
                    value={current_third_loan_amount(
                      loan,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                  <CurrencyField
                    name="Current 3rd Payment"
                    readOnly
                    value={property_to_be_sold.miscellaneous_housing_payment}
                  />
                </>
              )}
            </>
          )}

          {isSecondLoanPaymentType &&
            !will_second_subordinate(
              documentType,
              loan,
              property_to_be_sold,
              is_selling_property
            ) && (
              <>
                <div className="down-payment-section-title">
                  Second Mortgage
                </div>

                <SelectField
                  fieldName="second_mortgage_loan_term_monthly"
                  name="Second Mortgage loan term"
                  value={loan.second_mortgage_loan_term_monthly}
                  onChange={updateLoan}
                  selectOptions={MORTGAGE_PAYMENT_PERIODS}
                />

                <SelectField
                  name="Percent or amount?"
                  fieldName="second_mortgage_loan_percent_or_amount"
                  value={loan.second_mortgage_loan_percent_or_amount}
                  onChange={updateLoan}
                  selectOptions={SECOND_LOAN_OPTIONS}
                />
                {SECOND_LOAN_PERCENT ===
                  loan.second_mortgage_loan_percent_or_amount && (
                  <PercentField
                    fieldName="second_mortgage_loan_percent"
                    name="Second Mortgage loan %"
                    value={loan.second_mortgage_loan_percent}
                    onChange={updateLoan}
                  />
                )}
                {SECOND_LOAN_AMOUNT ===
                  loan.second_mortgage_loan_percent_or_amount && (
                  <CurrencyField
                    fieldName="second_mortgage_loan_amount"
                    name="Second Mortgage loan amount"
                    value={loan.second_mortgage_loan_amount}
                    onChange={updateLoan}
                  />
                )}

                {loan.second_mortgage_loan_rate_type === FIXED_RATE && (
                  <PercentField
                    id="second_mortgage_fixed_rate"
                    fieldName="second_mortgage_fixed_rate"
                    onChange={updateLoan}
                    name="Interest rate fixed rate"
                    value={loan.second_mortgage_fixed_rate}
                    roundTo={3}
                    decimalPlaces={3}
                    datalist={SORTED_LOAN_RATES}
                  />
                )}
                {loan.second_mortgage_loan_rate_type === VARIABLE_RATE && (
                  <>
                    <PercentField
                      name="Index"
                      fieldName="second_mortgage_loan_index"
                      value={loan.second_mortgage_loan_index}
                      onChange={updateLoan}
                    />
                    <PercentField
                      name="Margin"
                      fieldName="second_mortgage_loan_margin"
                      value={loan.second_mortgage_loan_margin}
                      onChange={updateLoan}
                    />
                    <PercentField
                      readOnly
                      name="HELOC Rate"
                      value={heloc_rate(loan)}
                    />
                  </>
                )}
              </>
          )}

          {(is_fha(loan.type) || is_usda(loan.type)) && (
            <>
              <SelectField
                name="Is UFMIP Financed?"
                fieldName="is_ufmip_financed"
                value={loan.is_ufmip_financed}
                onChange={updateLoan}
                selectOptions={YES_NO}
              />
            </>
          )}

          {is_va(loan.type) && (
            <VAPricingTool loan={loan} updateLoan={updateLoan} />
          )}
        </div>

        <div className="third-width">
          <PercentField
            readOnly
            fieldName="ltv_first_td"
            name="LTV 1st TD"
            fixedDigits={2}
            value={ltvFirstTd}
          />
          {CONVENTIONAL_LOANS.includes(loan.type) &&
            ltv_first_td(documentType, loan, property).gt(
              LTV_MORTGAGE_INSURANCE_PERCENT_MINIMUM
            ) && (
              <>
                <div className="down-payment-section-title">
                  Mortgage Insurance
                </div>
                <SelectField
                  name="Is Mortgage Insurance Payment Monthly?"
                  fieldName="mortgage_insurance_is_monthly"
                  value={loan.mortgage_insurance_is_monthly}
                  onChange={updateLoan}
                  selectOptions={YES_NO}
                />
                <SelectField
                  name="Is Mortgage Insurance Lender Paid? (In closing Costs)"
                  fieldName="mortgage_insurance_is_lender_paid"
                  value={loan.mortgage_insurance_is_lender_paid}
                  onChange={updateLoan}
                  selectOptions={YES_NO}
                />
                {loan.mortgage_insurance_is_monthly === YES && (
                  <PercentField
                    name="Monthly Rate Factor PMI"
                    fieldName="mortgage_insurance_usda_rate_factor_pmi"
                    value={loan.mortgage_insurance_usda_rate_factor_pmi}
                    onChange={updateLoan}
                    fixedDigits={3}
                  />
                )}
              </>
          )}
          {is_fha(loan.type) && (
            <>
              <div className="down-payment-section-title">
                Government Mortgage Insurance
              </div>
              <SelectField
                name="Monthly Mortgage Insurance Rate"
                fieldName="mortgage_insurance_fha_rate"
                value={loan.mortgage_insurance_fha_rate}
                onChange={updateLoan}
                selectOptions={FHA_MORTGAGE_INSURANCE_RATES}
                showDefaultOption
                defaultOptionValue="0"
              />
              <a
                className="button inverse-button"
                href="https://www.mgic.com/rates/ratefinder"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rate Finder
              </a>
            </>
          )}
          {is_usda(loan.type) && (
            <>
              <div className="down-payment-section-title">
                Government Mortgage Insurance
              </div>
              <PercentField
                readOnly
                name="Monthly Mortgage Insurance Rate"
                value={USDA_MONTHLY_MORTGAGE_INSURANCE_RATE}
              />
            </>
          )}
        </div>

        <div className="third-width">
          {isPurchase && (
            <>
              <div className="down-payment-all-loans-same-emd-seller-concessions">
                <div>
                  ALL Loans have same EMD &amp; Seller Concessions?{" "}
                  <span className="bold">
                    {areAllLoansSameEmdAndSellerConcessions ? "Yes" : "No"}
                  </span>
                </div>
                <button
                  className="button"
                  onClick={makeAllLoansSameEmdAndSellerConcessions}
                >
                  Make all loans same EMD &amp; Seller Concessions
                </button>
              </div>
              <PercentField
                fieldName="emd"
                name="EMD (Earnest Money Deposit)"
                value={loan.emd}
                onChange={updateLoan}
              />
              <CurrencyField
                fieldName="emd_amount"
                name="EMD (Earnest Money Deposit)"
                value={loan.emd_amount}
                onChange={updateLoan}
              />
              <CurrencyField
                readOnly
                fieldName="earnest_money_deposit"
                name="EMD (Earnest Money Deposit)"
                value={emd(documentType, loan, property)}
              />
              <PercentField
                fieldName="seller_concessions"
                name="Seller Concessions (Optional)"
                title="LANIS Training Tip: Seller concessions are tied to Guidelines and are dependent on the LTV in most cases. Check your individual Guidelines for current Percentage caps on Seller Concessions."
                value={loan.seller_concessions}
                onChange={updateLoan}
              />
              <CurrencyField
                fieldName="seller_concessions_amount"
                name="Seller Concessions (Optional)"
                value={loan.seller_concessions_amount}
                onChange={updateLoan}
              />
              <CurrencyField
                readOnly
                fieldName="seller_concessions_value"
                name="Seller Concessions"
                value={seller_concessions(documentType, loan, property)}
              />
            </>
          )}

          {isRefinance && (
            <>
              <TextField
                className="right-align"
                name="Loan Type"
                readOnly
                value={LOAN_TYPE_CATEGORY_NAMES[loan.type]}
                title={refinance_loan_type_training_tip()}
              />
              {loan.rate_and_term_refi === NO && (
                <>
                  <PercentField
                    fieldName="refinance_cash_out_add_to_loan_amount_percent"
                    name="Cash Out (Add to Loan) %"
                    value={loan.refinance_cash_out_add_to_loan_amount_percent}
                    onChange={updateLoan}
                  />
                  <CurrencyField
                    fieldName="refinance_cash_out_add_to_loan_amount"
                    name="Cash Out (Add to Loan) $"
                    value={loan.refinance_cash_out_add_to_loan_amount}
                    onChange={updateLoan}
                  />
                  <CurrencyField
                    readOnly
                    name="Cash Out (Add to Loan Amount)"
                    value={cash_out_add_to_loan_amount(
                      documentType,
                      loan,
                      property
                    )}
                  />
                </>
              )}
              {(loan.type === FHA_STREAMLINE || loan.type === VA_IRRRL) && (
                <CurrencyField
                  fieldName="max_cash_back_rate_term_refi_va_fha"
                  name="Max. Cash Back, Rate & Term Refi VA/FHA"
                  value={loan.max_cash_back_rate_term_refi_va_fha}
                  onChange={updateLoan}
                />
              )}
              {loan.rate_and_term_refi === YES && (
                <CurrencyField
                  fieldName="max_cash_out_at_coe"
                  name="Maximum Cash Out at COE (Depends on Program Guidelines)"
                  value={loan.max_cash_out_at_coe}
                  onChange={updateLoan}
                />
              )}
            </>
          )}

          <CurrencyField
            readOnly
            name="Total Adjustments and Other Credits"
            value={total_adjustments_other_credits(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
          <button className="button" onClick={showOtherAdjustmentsPage}>
            Edit Adjustments
          </button>

          {showLPMIAdjustmentFees && (
            <>
              <PercentField
                readOnly
                name="LPMI Adjustment Fees"
                value={total_lpmi_adjustment_fees(loan)}
              />
              <button className="button" onClick={showLPMIAdjustmentFeesPage}>
                Edit LPMI Adjustment Fees
              </button>
            </>
          )}
        </div>
      </div>
    </Section>
  );
}
