import React from "react";

import {
  ADJUSTMENT_CAP_PERCENTS,
  ADJUSTMENT_LIFE_CAP,
  ARM_INDEX_TYPES,
  FIRST_ADJUSTMENT_CAP_PERCENTS
} from "../../../constants/loan_arm_properties";
import PercentField from "../../fields/percent_field";
import {
  arm_beginning_month,
  arm_max_interest_rate
} from "../../../calculations/loan";
import NumberField from "../../fields/number_field";
import Section from "../../ui/section";

export default function AdjustableInterestRateTable({ loan }) {
  return (
    <Section className="arm-table" name="Adjustable Interest Rate">
      <div className="arm-table-left">
        <div className="arm-note-title">NOTE:</div>
        <div className="italic">
          If you are applying for an Adjustable Rate Mortgage (ARM)
          <br />
          please take special note of the Limits on Interest Rates.
          <br />
          These Changes potentially will have a significant impact
          <br />
          on your Monthly home payment.
          <br />
          <br />
          Please discuss these changes with your Loan Officer in
          <br />
          detail before your select this as an option for your mortgage.
        </div>
      </div>
      <div className="arm-table-right">
        <div className="bold">Adjustable Interest Rate (AIR) Table</div>
        <div className="arm-table-row">
          <div>Index + Margin</div>
          <div className="flex-space-between">
            <div>Index Type:</div>
            <div>{ARM_INDEX_TYPES[loan.arm_index_type]}</div>
            <PercentField naked value={loan.arm_margin} />
          </div>
        </div>
        <div className="arm-table-row">
          <div>Initial Interest Rate</div>
          <div className="right-align">
            <PercentField naked decimalPlaces={3} value={loan.rate} />
          </div>
        </div>
        <div className="arm-table-row">
          <div>Minimum/Maximum Interest Rate</div>
          <div className="flex-start-with-front-spacing">
            <PercentField naked decimalPlaces={3} value={loan.rate} />
            <PercentField naked value={arm_max_interest_rate(loan)} />
          </div>
        </div>
        <div className="arm-table-row bold">
          <div>Change Frequency</div>
        </div>
        <div className="arm-table-row">
          <div>First Change</div>
          <div className="flex-start-with-front-spacing">
            <div>Beginning of month</div>
            <NumberField naked value={arm_beginning_month(loan)} />
          </div>
        </div>
        <div className="arm-table-row">
          <div>Subsequent Changes</div>
          <div className="right-align">Every 12th month after first change</div>
        </div>
        <div className="arm-table-row bold">
          <div>Limits on Interest Rate Changes</div>
        </div>
        <div className="arm-table-row">
          <div>First Change</div>
          <div className="right-align">
            <PercentField
              naked
              value={FIRST_ADJUSTMENT_CAP_PERCENTS[loan.arm_caps]}
            />
          </div>
        </div>
        <div className="arm-table-row">
          <div>Subsequent Changes</div>
          <div className="right-align">
            <PercentField
              naked
              value={ADJUSTMENT_CAP_PERCENTS[loan.arm_caps]}
            />
          </div>
        </div>
        <div className="arm-table-row">
          <div>Life Maximum Interest Rate Cap</div>
          <div className="right-align">
            <PercentField naked value={ADJUSTMENT_LIFE_CAP[loan.arm_caps]} />
          </div>
        </div>
      </div>
    </Section>
  );
}
