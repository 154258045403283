import { ARM_TERMS } from "../constants/loan_terms";
import { month_to_year } from "./utils";
import {
  ADJUSTMENT_LIFE_CAP,
  ARM_FIRST_CHANGE_MONTHS
} from "../constants/loan_arm_properties";
import Decimal from "decimal.js";
import {
  arm_first_payment_amount,
  arm_max_payment_amount,
  arm_second_payment_amount,
  first_mortgage_payment_interest_only,
  first_mortgage_payment_p_and_i,
  initial_escrow_payment_at_closing_homeowners_insurance,
  initial_escrow_payment_at_closing_property_taxes,
  mortgage_insurance_premium_monthly,
  property_tax
} from "./loan";
import {
  hoa_dues_1,
  hoa_dues_2,
  home_owners_insurance_monthly,
  mello_roos
} from "./property";
import { payment_for_DTI_calc_total } from "./debt_info";
import { compute_total_income } from "./income";
import { INTEREST_ONLY } from "../constants/loan_payment_type";

/**
 * TRID - Interest Rate - starting in year
 */
export function arm_adjust_starting_in_year(loan) {
  if (ARM_TERMS.includes(loan.term)) {
    return month_to_year(ARM_FIRST_CHANGE_MONTHS[loan.term]).add(1);
  }
  return new Decimal(0);
}

/**
 * TRID - Interest Rate - As High as
 */
export function arm_adjust_as_high_as_rate(loan) {
  if (ARM_TERMS.includes(loan.term)) {
    return new Decimal(ADJUSTMENT_LIFE_CAP[loan.arm_caps]).add(loan.rate);
  }
  return new Decimal(0);
}

/**
 * TRID - Estimated Taxes, Insurance & Assessments - other a month
 */
export function estimated_taxes_insurance_assessments_other_monthly(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return property_tax(documentType, loan, property)
    .add(home_owners_insurance_monthly(documentType, property))
    .add(
      mortgage_insurance_premium_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(hoa_dues_1(property, loan))
    .add(hoa_dues_2(property, loan))
    .add(mello_roos(property, loan));
}

/**
 * TRID - Estimated Escrow
 */
export function estimated_escrow(documentType, loan, property) {
  return new Decimal(0)
    .add(
      initial_escrow_payment_at_closing_property_taxes(
        documentType,
        loan,
        property
      ).gt(0)
        ? property_tax(documentType, loan, property)
        : 0
    )
    .add(
      initial_escrow_payment_at_closing_homeowners_insurance(
        documentType,
        loan,
        property
      ).gt(0)
        ? home_owners_insurance_monthly(documentType, property)
        : 0
    );
}

/**
 * TRID - Estimated Total Monthly Payment
 */
export function estimated_total_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return first_mortgage_payment_p_and_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      mortgage_insurance_premium_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(estimated_escrow(documentType, loan, property));
}

/**
 * TRID - Estimated Total Monthly Payment first x-th year
 */
export function arm_first_estimated_total_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_first_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(estimated_escrow(documentType, loan, property));
  }
  return new Decimal(0);
}

/**
 * TRID - Estimated Total Monthly Payment second x-th year
 */
export function arm_second_estimated_total_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_second_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(estimated_escrow(documentType, loan, property));
  }
  return new Decimal(0);
}

/**
 * TRID - Estimated Total Monthly Payment remaining year
 */
export function arm_max_estimated_total_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_max_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(estimated_escrow(documentType, loan, property));
  }
  return new Decimal(0);
}

/**
 * TRID - Est. I/O Monthly Payment
 */
export function estimated_io_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return first_mortgage_payment_interest_only(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(estimated_escrow(documentType, loan, property));
  }
  return new Decimal(0);
}

/**
 * TRID - Total I/O Monthly Payment
 */
export function total_io_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return first_mortgage_payment_interest_only(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).add(
      estimated_taxes_insurance_assessments_other_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return new Decimal(0);
}

/**
 * TRID - Total Monthly Payment Includes ALL items below
 */
export function total_monthly_payment_includes_all(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return first_mortgage_payment_p_and_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    estimated_taxes_insurance_assessments_other_monthly(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * TRID - Total Monthly Payment Includes ALL items below - first x-th year
 */
export function arm_first_total_monthly_payment_includes_all(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_first_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).add(
      estimated_taxes_insurance_assessments_other_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return new Decimal(0);
}

/**
 * TRID - Total Monthly Payment Includes ALL items below - second x-th year
 */
export function arm_second_total_monthly_payment_includes_all(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_second_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).add(
      estimated_taxes_insurance_assessments_other_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return new Decimal(0);
}

/**
 * TRID - Total Monthly Payment Includes ALL items below - remaining year
 */
export function arm_max_total_monthly_payment_includes_all(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_max_payment_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).add(
      estimated_taxes_insurance_assessments_other_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return new Decimal(0);
}

/**
 * TRID - DTI Per Loan Adjustment (PITI) - first x-th year
 */
export function arm_first_dti_per_loan_adjustment_piti(
  documentType,
  loan,
  property,
  accountSummaries,
  residences,
  investment_properties,
  borrowers,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_first_total_monthly_payment_includes_all(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        payment_for_DTI_calc_total(
          Object.values(accountSummaries),
          residences,
          investment_properties,
          loan
        )
      )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

/**
 * TRID - DTI Per Loan Adjustment (PITI) - second x-th year
 */
export function arm_second_dti_per_loan_adjustment_piti(
  documentType,
  loan,
  property,
  accountSummaries,
  residences,
  investment_properties,
  borrowers,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_second_total_monthly_payment_includes_all(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        payment_for_DTI_calc_total(
          Object.values(accountSummaries),
          residences,
          investment_properties,
          loan
        )
      )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

/**
 * TRID - DTI Per Loan Adjustment (PITI) - first x-th year
 */
export function arm_max_dti_per_loan_adjustment_piti(
  documentType,
  loan,
  property,
  accountSummaries,
  residences,
  investment_properties,
  borrowers,
  property_to_be_sold,
  is_selling_property
) {
  if (ARM_TERMS.includes(loan.term)) {
    return arm_max_total_monthly_payment_includes_all(
      documentType,
      loan,
      property
    )
      .add(
        payment_for_DTI_calc_total(
          Object.values(accountSummaries),
          residences,
          investment_properties,
          loan,
          property_to_be_sold,
          is_selling_property
        )
      )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}
