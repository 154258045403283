import React from "react";
import Decimal from "decimal.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

import "./css/time_field.scss";
import TrainingTip from "../ui/training_tip";

export default function TimeField({
  value,
  onChange,
  fieldName,
  className,
  naked,
  id,
  title,
  tabIndex,
  readOnly,
  name,
  showRequired
}) {
  /**
   * Update the state of this text field.
   */
  function change(newDate) {
    const newValue = newDate ? moment(newDate).format("HH:mm") : "";
    if (newValue !== value) {
      if (onChange && fieldName) {
        onChange({
          [fieldName]: newValue
        });
      }
    }
  }

  function class_name() {
    let classname = "input-field time-field";

    if (className) {
      classname += ` ${className}`;
    }

    return classname;
  }

  if (naked) {
    return <span className={className}>{formatTwelveHour(value)}</span>;
  }

  return (
    <div className="input-field-container time-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>
      <div className={class_name()}>
        <DatePicker
          id={id}
          className="time-field-input"
          selected={value ? moment(value, "HH:mm").toDate() : ""}
          showTimeSelect
          showTimeSelectOnly
          dateFormat="hh:mm aa"
          onChange={change}
          tabIndex={tabIndex}
          readOnly={readOnly}
          popperPlacement="bottom-start"
          popperModifiers={{
            preventOverflow: {
              enabled: true,
              escapeWithReference: false,
              boundariesElement: "viewport"
            }
          }}
        />
      </div>
    </div>
  );
}

function formatTwelveHour(value) {
  if (!value) {
    return "";
  }
  const times = value.split(":");
  const militaryHours = new Decimal(times[0]);
  const hours = militaryHours.gt(12)
    ? militaryHours.sub(12)
    : militaryHours.eq(0)
      ? new Decimal(12)
      : militaryHours;
  const meridiem = militaryHours.gt(12) ? "PM" : "AM";
  return hours + ":" + times[1] + " " + meridiem;
}
