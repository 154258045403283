import React from "react";
import "./css/other_considerations.css";
import {
  ASSUMPTION_ALLOW,
  ASSUMPTION_NOT_ALLOW,
  SERVICING_SERVICE,
  SERVICING_TRANSFER
} from "../../../constants/trid";
import SelectField from "../../fields/select_field";

export default function OtherConsiderations({ updateTrid, trid }) {
  const assumptionAllowOptions = { [ASSUMPTION_ALLOW]: "X" };
  const assumptionNotAllowOptions = { [ASSUMPTION_NOT_ALLOW]: "X" };
  const servicingServiceOptions = { [SERVICING_SERVICE]: "X" };
  const servicingTransferOptions = { [SERVICING_TRANSFER]: "X" };

  return (
    <div className="other-considerations">
      <div className="content-section">
        <div className="summary-header content-title">Other Considerations</div>
      </div>
      <div className="content-section">
        <div className="content-title">Appraisal</div>
        <div className="content-description">
          We may order an appraisal to determine the property’s value and charge
          you for this
          <br />
          appraisal. We will promptly give you a copy of any appraisal, even if
          your loan does not close.
          <br />
          You can pay for an additional appraisal for your own use at your own
          cost.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Assumption</div>
        <div className="content-description">
          <div>If you sell or transfer this property to another person, we</div>
          <div className="flex-start">
            <div className="selections no-print">
              <div>
                <SelectField
                  emptyDefault
                  showDefaultOption
                  fieldName="assumption"
                  value={trid.assumption}
                  selectOptions={assumptionAllowOptions}
                  onChange={updateTrid}
                />
              </div>
              <div>
                <SelectField
                  emptyDefault
                  showDefaultOption
                  fieldName="assumption"
                  value={trid.assumption}
                  selectOptions={assumptionNotAllowOptions}
                  onChange={updateTrid}
                />
              </div>
            </div>
            <div className="selections print-only">
              <div>{trid.assumption === ASSUMPTION_ALLOW && "X"}</div>
              <div>{trid.assumption === ASSUMPTION_NOT_ALLOW && "X"}</div>
            </div>
            <div>
              <div>
                will allow, under certain conditions, this person to assume this
                loan on the original terms.
              </div>
              <div>
                will not allow this person to assume this loan on the original
                terms.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">
          Homeowner&apos;s
          <br />
          Insurance
        </div>
        <div className="content-description">
          This loan requires homeowner’s insurance on the property, which you
          may obtain from a<br />
          company of your choice that we find acceptable.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Late Payment</div>
        <div className="content-description">
          If your payment is more than 15 days late, we will charge a late fee
          of 5% of the monthly
          <br />
          principal and interest payment.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">
          Liability After
          <br />
          Foreclosure
        </div>
        <div className="content-description">
          Taking this loan could end any state law protection you may currently
          have against Liability for
          <br />
          unpaid debt if your lender forecloses on your home. If you lose this
          protection, you may have to pay
          <br />
          any debt remaining even after foreclosure. You may want to consult a
          lawyer for more information.
          <br />
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Loan Acceptance</div>
        <div className="content-description">
          You do not have to accept this loan because you have received this
          form or signed a loan application.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Refinancing</div>
        <div className="content-description">
          Refinancing this loan will depend on your future financial situation,
          the property value, and
          <br />
          market conditions. You may not be able to refinance this loan.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Servicing</div>
        <div className="content-description">
          <div>We intend</div>
          <div className="flex-start">
            <div className="selections no-print">
              <div>
                <SelectField
                  emptyDefault
                  showDefaultOption
                  fieldName="servicing"
                  value={trid.servicing}
                  selectOptions={servicingServiceOptions}
                  onChange={updateTrid}
                />
              </div>
              <div>
                <SelectField
                  emptyDefault
                  showDefaultOption
                  fieldName="servicing"
                  value={trid.servicing}
                  selectOptions={servicingTransferOptions}
                  onChange={updateTrid}
                />
              </div>
            </div>
            <div className="selections print-only">
              <div>{trid.servicing === SERVICING_SERVICE && "X"}</div>
              <div>{trid.servicing === SERVICING_TRANSFER && "X"}</div>
            </div>
            <div>
              <div>
                to service your loan. If so, you will make your payments to us.
              </div>
              <div>to transfer servicing of your loan.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
