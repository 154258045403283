import Decimal from "decimal.js";
import { NO, YES } from "../constants/yes_no";
import { MONTHLY, ANNUALLY, QUARTERLY } from "../constants/frequencies";
import { REFINANCE } from "../constants/document_types";
import { deep_clone_simple, show_hoas } from "../constants/utils";
import { toMonthly } from "./utils";

/**
 * Wraps the given investment REO together with the subject property to form a single
 * REO with any subject property-derived fields set from the subject property.
 */
export function wrap_subject_property(subject_property, investment_property) {
 const wrapped = deep_clone_simple(investment_property);
  const isSubjectPropertyHoa = show_hoas(subject_property);
  const subjectPropertyHoa1 = isSubjectPropertyHoa ? subject_property.hoa1 : "0";
  const subjectPropertyHoa2 = isSubjectPropertyHoa ? subject_property.hoa2 : "0";
 Object.assign(wrapped, {
   market_value: subject_property.listed_price,
   principal_balance: subject_property.principal_balance,
   property_address: subject_property.address,
   property_address_2: subject_property.address2,
   property_city: subject_property.city,
   property_state: subject_property.state,
   property_zip: subject_property.zip,
   first_mortgage: subject_property.first_mortgage,
   second_mortgage: subject_property.second_mortgage,
   taxes: toMonthly(subject_property.refinance_property_tax_amount,
       subject_property.refinance_property_tax_frequency),
   insurance: toMonthly(subject_property.home_owners_insurance,
       subject_property.home_owners_insurance_frequency),
   hoa1: subjectPropertyHoa1,
   hoa2: subjectPropertyHoa2,
   hoa1_frequency: subject_property.hoa1_frequency,
   hoa2_frequency: subject_property.hoa2_frequency,
   mello_roos: subject_property.mello_roos,
   mello_roos_amount: subject_property.mello_roos_amount,
   mello_roos_frequency: subject_property.mello_roos_frequency
 });
 return wrapped;
}

/**
 * Wraps the given REO with the subject property if it is linked to the subject property,
 * otherwise returns the REO as-is.
 */
export function wrap_subject_property_if_needed(subject_property, investment_property) {
  if (investment_property.is_subject_property === YES) {
    return wrap_subject_property(subject_property, investment_property);
  }
  return investment_property;
}

/**
 * Wraps any subject property-linked REO with the subject property,
 * and leaves all other properties as-is. If `includeSubjectProperty`
 * is false, the subject property will be omitted from the results.
 */
export function wrap_investment_properties(subject_property, investment_properties,
                                           includeSubjectProperty = true) {
  if (!includeSubjectProperty) {
    investment_properties = investment_properties.filter(property => property.is_subject_property !== YES);
  }
  return investment_properties.map(property => {
    if (property.is_subject_property === YES) {
      return wrap_subject_property(subject_property, property);
    }
    return property;
  })
}

/**
 * Calculation for additional cash flow per year
 */
export function additional_cash_flow_per_year(property) {
  return new Decimal(property.gross_rent_payment).mul("12");
}

/**
 * Calculation for net 75
 */
export function net_75(property) {
  return new Decimal(property.gross_rent_payment).mul("0.75");
}

/**
 * Calculation of net income or loss
 */
export function net_income_or_loss(property) {
  if (property.reported_on_1040 === NO) {
    return net_75(property).sub(piti_hoa_monthly_payment(property));
  } else {
    return total_net_monthly_rental_income_or_loss(
      total_gross_rental_income_from_tax_returns(
        property.tax_returns,
        property.months_included_in_income
      ),
      total_payment(property)
    );
  }
}

export function hoa_monthly_payment(amount, frequency) {
  if (frequency === ANNUALLY) {
    return new Decimal(amount).dividedBy(12);
  } else if (frequency === QUARTERLY) {
    return new Decimal(amount).dividedBy(3);
  } else if (frequency === MONTHLY) {
    return new Decimal(amount);
  } else {
    return new Decimal(0);
  }
}

/**
 * Calculation for piti and hoa monthly payment
 */
export function piti_hoa_monthly_payment(property) {
  //TODO is this correct?
  return new Decimal(property.first_mortgage)
    .plus(property.second_mortgage)
    .plus(property.taxes)
    .plus(property.insurance)
    .plus(hoa_monthly_payment(property.hoa1, property.hoa1_frequency))
    .plus(hoa_monthly_payment(property.hoa2, property.hoa2_frequency));
}

/**
 * Calculation for additional cash flow per year total
 */
export function additional_cash_flow_per_year_total(investment_properties) {
  return investment_properties.reduce(
    (accumulator, property) =>
      property.reported_on_1040 === NO
        ? accumulator.add(additional_cash_flow_per_year(property))
        : accumulator,
    new Decimal("0")
  );
}

/**
 * Calculation for gross rent payment total
 */
export function gross_rent_payment_total(investment_properties) {
  return investment_properties.reduce(
    (accumulator, property) =>
      property.reported_on_1040 === NO
        ? accumulator.add(property.gross_rent_payment)
        : accumulator,
    new Decimal("0")
  );
}

/**
 * Calculation for the net 75 percent total
 */
export function net_75_total(investment_properties) {
  return investment_properties.reduce(
    (accumulator, property) =>
      property.reported_on_1040 === NO
        ? accumulator.add(net_75(property))
        : accumulator,
    new Decimal("0")
  );
}

/**
 * Calculation for the net income or loss total
 */
export function net_income_or_loss_total(investment_properties) {
  return investment_properties.reduce((accumulator, property) => {
    return accumulator.add(net_income_or_loss(property));
  }, new Decimal("0"));
}

/**
 * Calculation for the piti hoa monthly payment total
 */
export function piti_hoa_monthly_payment_total(investment_properties) {
  return investment_properties.reduce(
    (accumulator, property) =>
      property.reported_on_1040 === NO
        ? accumulator.add(piti_hoa_monthly_payment(property))
        : accumulator,
    new Decimal("0")
  );
}

/**
 * Calculation for total payment
 */
export function total_payment(property) {
  //TODO: Is this correct? do we just add all 3 HOA fields?
  return new Decimal(property.first_mortgage)
    .plus(property.second_mortgage)
    .plus(property.taxes)
    .plus(property.insurance)
    .plus(hoa_monthly_payment(property.hoa1, property.hoa1_frequency))
    .plus(hoa_monthly_payment(property.hoa2, property.hoa2_frequency))
    .plus(
      hoa_monthly_payment(
        property.mello_roos_amount,
        property.mello_roos_frequency
      )
    );
}

/**
 * Calculation for total misc monthly fees
 */
export function total_misc_monthly_fees(property) {
  return new Decimal(property.taxes)
    .plus(property.insurance)
    .plus(hoa_monthly_payment(property.hoa1, property.hoa1_frequency))
    .plus(hoa_monthly_payment(property.hoa2, property.hoa2_frequency))
    .plus(property.mello_roos === YES
      ? hoa_monthly_payment(
        property.mello_roos_amount,
        property.mello_roos_frequency
      ) : new Decimal("0")
    );
}

/**
 * Calculation for the estimated loan amount
 */
export function estimated_loan_amount(property) {
  return new Decimal(property.listed_price).mul(
    new Decimal(property.target_ltv).div(100)
  );
}

/**
 * New Property - Down Payment
 */
export function down_payment(property) {
  return new Decimal(property.listed_price).sub(
    estimated_loan_amount(property)
  );
}

/**
 * Calculation for the property tax amount
 */
export function property_tax_amount(documentType, property) {
  if (documentType === REFINANCE) {
    return new Decimal(property.refinance_property_tax_amount);
  }
  return new Decimal(property.property_tax_percent)
    .div(100)
    .mul(property.listed_price);
}

/**
 * Calculation for the total gross rental income from each tax return on the
 * schedule E calculator.
 */
export function total_gross_rental_income_from_individual_tax_return(
  tax_return
) {
  return new Decimal(tax_return.income)
    .plus(new Decimal(tax_return.depreciation))
    .plus(new Decimal(tax_return.insurance))
    .plus(new Decimal(tax_return.mortgage_interest))
    .plus(new Decimal(tax_return.taxes))
    .plus(new Decimal(tax_return.hoa))
    .plus(new Decimal(tax_return.repairs))
    .plus(new Decimal(tax_return.other));
}

/***
 * Calculation for the total gross rental income for all tax returns on the schedule
 * E calculator.
 */
export function total_gross_rental_income_from_tax_returns(
  tax_returns,
  months_included_in_income
) {
  let total = new Decimal(0);
  for (let tax_return of tax_returns) {
    total = total.plus(
      total_gross_rental_income_from_individual_tax_return(tax_return)
    );
  }
  return total.dividedBy(new Decimal(months_included_in_income));
}

/**
 * Calculation for the total Gross Rental income on the rental lease section of the
 * schedule E calculator.
 */
export function total_gross_rental_income_from_rental_lease(
  investment_property
) {
  return new Decimal(investment_property.gross_rent_payment)
    .times(
      new Decimal(investment_property.gross_rent_percent_used_in_calculations)
    )
    .dividedBy(100);
}

/**
 * Calculation for the net monthly rental income or loss on the rental lease section of the
 * schedule E calculator.
 */
export function total_net_monthly_rental_income_or_loss(
  gross_rental_income_from_rental_lease,
  total_payments_interest
) {
  return gross_rental_income_from_rental_lease.minus(
    new Decimal(total_payments_interest)
  );
}

/**
 * Net yearly income or loss.
 */
export function total_net_yearly_income_loss_rental(investment_properties) {
  return net_income_or_loss_total(investment_properties);
}
