import React from "react";
import "./css/total_cost_analysis.scss";
import CurrencyField from "../../fields/currency_field";
import {
  loan_amount_first_mortgage,
  loan_term_in_years,
  total_closing_costs
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import {
  cost_over_time,
  total_cost_of_purchasing_over_time,
  years_fully_amortized
} from "../../../calculations/total_cost_analysis";
import { useDocument } from "../../loan_tools/loan_tool";
import Decimal from "decimal.js";

export default function TotalCostAnalysis() {
  const [
    { loans, property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const headerYears = [10, 15, 20, 25, 30];

  function isShowEntry(loanYears, years) {
    return (
      loanYears.gt(years) ||
      loanYears
        .sub(years)
        .abs()
        .lt(5)
    );
  }

  function renderYearHeader(loanYears) {
    return headerYears.map(years => (
      <td className="center-align" key={years}>
        {isShowEntry(loanYears, years) &&
          Decimal.min(loanYears, years).toString() + " Year"}
      </td>
    ));
  }

  /**
   * Builds a 2D array for the closing costs of each loan + "header year"
   * combination, including both the amount and relative ratios.
   *
   * e.g.
   * Array
   * (
   *   [10] => Array ( [0] => { amount, ratio }, [1] => { amount, ratio }, ... )
   *   [15] => Array ( [0] => { amount, ratio }, [1] => { amount, ratio }, ... )
   *   [20] => Array ( [0] => { amount, ratio }, [1] => { amount, ratio }, ... )
   *   [25] => Array ( [0] => { amount, ratio }, [1] => { amount, ratio }, ... )
   *   [30] => Array ( [0] => { amount, ratio }, [1] => { amount, ratio }, ... )
   * )
   */
  function initializeTotalCosts() {
    if (!loans.length) {
      return [];
    }

    const totals = [];
    headerYears.forEach(years => {
      totals[years] = [];

      // Populate amounts.
      loans.forEach((loan, loanIndex) => {
        const loanYears = loan_term_in_years(loan);
        totals[years][loanIndex] = isShowEntry(loanYears, years)
          ? {
            amount: total_cost_of_purchasing_over_time(
              documentType,
              loan,
              years,
              property,
              property_to_be_sold,
              is_selling_property
            )
          }
          : {
            amount: null
          };
      });

      // Calculate min and max per header year block.
      const amounts = totals[years].reduce((amounts, total) => {
        if (total.amount) {
          amounts.push(total.amount);
        }
        return amounts;
      }, []);
      const minAmount = amounts.length
        ? Decimal.min(...amounts)
        : new Decimal(0);
      const maxAmount = amounts.length
        ? Decimal.max(...amounts)
        : new Decimal(0);
      const minMaxDiff = maxAmount.sub(minAmount);

      // Populate ratios.
      totals[years] = totals[years].map(total => {
        total.ratio = total.amount
          ? total.amount.sub(minAmount).div(minMaxDiff)
          : null;
        return total;
      });
    });
    return totals;
  }

  function renderTotalCost(loanYears, years, loanIndex) {
    const totalCost = totalCosts[years][loanIndex];
    if (!totalCost.amount) {
      return;
    }

    const styles = { width: totalCost.ratio.mul(100).toString() + "%" };
    return (
      <div className="bar-container">
        <div className={totalCost.ratio.eq(0) ? "" : "bar"} style={styles} />
        <div className="bar-content">
          <CurrencyField naked fixedDigits={0} value={totalCost.amount} />
        </div>
      </div>
    );
  }

  const totalCosts = initializeTotalCosts();

  return (
    <div className="total-cost-analysis">
      <div className="chart-container">
        <div className="title big-font bold">
          Total Cost Analysis to Purchase Home - Includes Amortized Payments and
          Closing Costs
        </div>
        <table className="analysis-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          {loans.map((loan, index) => {
            const loanNumber = index + 1;
            const loanYears = loan_term_in_years(loan);
            return (
              <tbody key={index}>
                {index === 0 && (
                  <>
                    <tr>
                      <td rowSpan="2" />
                      <td className="center-align" rowSpan="2">
                        Loan
                        <br />
                        Amount
                      </td>
                      <td className="center-align" rowSpan="2">
                        Interest
                        <br />
                        Rate
                      </td>
                      <td className="center-align" colSpan="5">
                        Years fully Amortized to Pay Off the Loan Balance
                      </td>
                    </tr>
                    <tr>{renderYearHeader(loanYears)}</tr>
                  </>
                )}
                {index !== 0 && (
                  <>
                    <tr>
                      <td colSpan="3" />
                      {renderYearHeader(loanYears)}
                    </tr>
                  </>
                )}
                <tr>
                  <td className="center-align top-border bottom-border">
                    Loan {loanNumber}
                  </td>
                  <td className="center-align top-border bottom-border">
                    <CurrencyField
                      naked
                      fixedDigits={0}
                      value={loan_amount_first_mortgage(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </td>
                  <td className="center-align top-border bottom-border">
                    <PercentField naked decimalPlaces={3} value={loan.rate} />
                  </td>
                  {headerYears.map(years => (
                    <td
                      className="right-align top-border bottom-border"
                      key={years}
                    >
                      {isShowEntry(loanYears, years) && (
                        <CurrencyField
                          naked
                          fixedDigits={0}
                          value={years_fully_amortized(
                            documentType,
                            loan,
                            years,
                            property,
                            property_to_be_sold,
                            is_selling_property
                          )}
                        />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan="3">Cost of Loan Over Time</td>
                  {headerYears.map(years => (
                    <td className="right-align" key={years}>
                      {isShowEntry(loanYears, years) && (
                        <CurrencyField
                          naked
                          fixedDigits={0}
                          value={cost_over_time(
                            documentType,
                            loan,
                            years,
                            property,
                            property_to_be_sold,
                            is_selling_property
                          )}
                        />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan="3">
                    Section J Closing Costs from the Loan Estimate
                  </td>
                  {headerYears.map(years => (
                    <td className="right-align" key={years}>
                      {isShowEntry(loanYears, years) && (
                        <CurrencyField
                          naked
                          fixedDigits={0}
                          value={total_closing_costs(
                            documentType,
                            loan,
                            property,
                            property_to_be_sold,
                            is_selling_property
                          )}
                        />
                      )}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan="3">
                    Total Cost of Purchasing the home over time
                  </td>
                  {headerYears.map(years => (
                    <td className="right-align top-border" key={years}>
                      {renderTotalCost(loanYears, years, index)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td colSpan="8" />
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </div>
  );
}
