export const ADT = "ADT";
export const AST = "AST";
export const AKDT = "AKDT";
export const AKST = "AKST";
export const CDT = "CDT";
export const CST = "CST";
export const EDT = "EDT";
export const EST = "EST";
export const HADT = "HADT";
export const HAST = "HAST";
export const MDT = "MDT";
export const MST = "MST";
export const PDT = "PDT";
export const PST = "PST";

export default Object.freeze({
  [ADT]: ADT,
  [AST]: AST,
  [AKDT]: AKDT,
  [AKST]: AKST,
  [CDT]: CDT,
  [CST]: CST,
  [EDT]: EDT,
  [EST]: EST,
  [HADT]: HADT,
  [HAST]: HAST,
  [MDT]: MDT,
  [MST]: MST,
  [PDT]: PDT,
  [PST]: PST
});
