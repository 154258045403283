import React, { useState, useMemo } from "react";
import "./css/down_payment_calculator.scss";

import InformationRecap from "../../components/loan_tools/down_payment_calculator/information_recap";
import OtherCreditsTable from "../../components/loan_tools/down_payment_calculator/other_credits_table";
import LPMIAdjustmentFees from "../../components/loan_tools/down_payment_calculator/lpmi_adjustment_fees";
import LoanInformation from "../../components/loan_tools/down_payment_calculator/loan_information";
import CalculationResults from "../../components/loan_tools/down_payment_calculator/calculation_results";
import StandardTemplate from "../../templates/standard_template/standard_template";

import PAYMENT_TYPES, {
  REFINANCE_PAYMENT_TYPES,
  SECOND_LOAN_PAYMENT_TYPES
} from "../../constants/payment_type";
import { FIXED_RATE, VARIABLE_RATE } from "../../constants/loan_rates";

import SelectField from "../../components/fields/select_field";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { ltv_first_td } from "../../calculations/loan";
import RefinanceLoanCalculator from "../../components/loan_tools/down_payment_calculator/refinance_loan_calculator";
import { DOWN_PAYMENT, LOAN_CONFIGURATOR } from "../../constants/navigations";
import LoanPickerNavigation from "../../components/loan_tools/loan_picker_navigation";
import NoLoans from "./no_loans";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import { YES, YES_NO } from "../../constants/yes_no";
import TextField from "../../components/fields/text_field";
import Decimal from "decimal.js";
import SaveLoan from "../../components/loan_tools/save_loan";

const allLoansSameDownPaymentFields = [
  "down_payment_type",
  "offer_price",
  "percent_down",
  "cash_down",
  "fixed_loan_amount"
];
const allLoansSameEmdAndSellerConcessionsFields = [
  "emd",
  "emd_amount",
  "seller_concessions",
  "seller_concessions_amount"
];

/**
 * DownPaymentCalculatorPage renders all the loans, one per tab controlled via
 * state, known to the system. A specific calculator is rendered given the
 * state of this component (loan_index).
 */
export default function DownPaymentCalculatorPage() {
  const [document, setDocument, { documentType }] = useDocument();
  const [loanIndex, setLoanIndex] = useState(0);
  const [activeView, setActiveView] = useState("DOWN_PAYMENT_CALCULATOR");
  const loan = document.loans[loanIndex];
  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;

  const areAllLoansSameDownPayment = useMemo(() => {
    const loanOne = document.loans[0];
    return !loanOne
      ? true
      : !document.loans.some(
        loan =>
          loan.down_payment_type !== loanOne.down_payment_type ||
            !ltv_first_td(documentType, loan, document.property).eq(
              ltv_first_td(documentType, loanOne, document.property)
            )
      );
  }, [document.loans]);
  const areAllLoansSameEmdAndSellerConcessions = useMemo(() => {
    const loanOne = document.loans[0];
    return !loanOne
      ? true
      : !document.loans.some(
        loan =>
          !new Decimal(loan.emd).eq(loanOne.emd) ||
            !new Decimal(loan.emd_amount).eq(loanOne.emd_amount) ||
            !new Decimal(loan.seller_concessions).eq(
              loanOne.seller_concessions
            ) ||
            !new Decimal(loan.seller_concessions_amount).eq(
              loanOne.seller_concessions_amount
            )
      );
  }, [document.loans]);

  function makeAllLoansSameDownPayment() {
    if (
      window.confirm(
        "Are you sure you want to make all loans having the same down payment as Loan 1?"
      )
    ) {
      const loans = [...document.loans];
      loans.forEach(loan =>
        allLoansSameDownPaymentFields.forEach(
          field => (loan[field] = loans[0][field])
        )
      );
      setDocument({ ...document, loans });
    }
  }
  function makeAllLoansSameEmdAndSellerConcessions() {
    if (
      window.confirm(
        "Are you sure you want to make all loans having the same EMD and Seller Concessions as Loan 1?"
      )
    ) {
      const loans = [...document.loans];
      loans.forEach(loan =>
        allLoansSameEmdAndSellerConcessionsFields.forEach(
          field => (loan[field] = loans[0][field])
        )
      );
      setDocument({ ...document, loans });
    }
  }

  function updateLoan(updatedLoan) {
    const loans = [...document.loans];
    loans[loanIndex] = {
      ...loans[loanIndex],
      ...updatedLoan
    };
    setDocument({ ...document, loans });
  }

  function showLPMIAdjustmentFeesPage() {
    setActiveView("EDIT_LPMI_ADJUSTMENTS");
  }

  function showOtherAdjustmentsPage() {
    setActiveView("EDIT_OTHER_CREDITS");
  }

  function showCalculator() {
    setActiveView("DOWN_PAYMENT_CALCULATOR");
  }

  function showRefinanceLoanCalculator() {
    setActiveView("REFINANCE_LOAN_CALCULATOR");
  }

  return (
    <StandardTemplate
      active={isRefinance ? LOAN_CONFIGURATOR : DOWN_PAYMENT}
      isDisableNavSave={activeView !== "DOWN_PAYMENT_CALCULATOR"}
    >
      <NoLoans />

      {document.loans.length > 0 && (
        <LoanPickerNavigation
          currentLoanIndex={loanIndex}
          setLoanIndex={setLoanIndex}
          onLoanChange={showCalculator}
        />
      )}

      <div className="down-payment">
        {activeView === "DOWN_PAYMENT_CALCULATOR" && (
          <div>
            {isPurchase && document.loans.length > 0 && (
              <div className="down-payment-all-loans-the-same">
                <div>
                  Do ALL Loans have the Same Down Payment?{" "}
                  <span className="bold">
                    {areAllLoansSameDownPayment ? "Yes" : "No"}
                  </span>
                </div>
                <button
                  className="button"
                  onClick={makeAllLoansSameDownPayment}
                >
                  Make all loans have the same down payment
                </button>
              </div>
            )}
            {loan && (
              <>
                <div className="down-payment-top flex">
                  <div className="half-width">
                    {isRefinance && (
                      <TextField
                        readOnly
                        name="All Loans Are Same Loan Type"
                        value={
                          document.applicant.all_loan_types_the_same === YES
                            ? "Yes"
                            : "No"
                        }
                      />
                    )}
                    <SelectField
                      fieldName="down_payment_type"
                      name={
                        isRefinance
                          ? "Refinance Options"
                          : "Down Payment Options"
                      }
                      value={loan.down_payment_type}
                      onChange={updateLoan}
                      selectOptions={
                        isRefinance ? REFINANCE_PAYMENT_TYPES : PAYMENT_TYPES
                      }
                    />

                    {SECOND_LOAN_PAYMENT_TYPES.includes(
                      loan.down_payment_type
                    ) && (
                      <SelectField
                        fieldName="second_mortgage_loan_rate_type"
                        name="Fixed or Variable Rate 2nd"
                        value={loan.second_mortgage_loan_rate_type}
                        onChange={updateLoan}
                        selectOptions={{
                          [FIXED_RATE]: "Fixed Rate",
                          [VARIABLE_RATE]: "Variable Rate"
                        }}
                      />
                    )}

                    {isRefinance && (
                      <SelectField
                        fieldName="rate_and_term_refi"
                        name="Rate and Term Refi"
                        value={loan.rate_and_term_refi}
                        onChange={updateLoan}
                        selectOptions={YES_NO}
                      />
                    )}
                  </div>
                  <div className="configure-dti">
                    <div>Configure DTI</div>
                    <SaveLoan
                      name="Debt Info"
                      path="debt-info"
                      className="button"
                    />
                  </div>
                </div>

                <InformationRecap loan={loan} updateLoan={updateLoan} />

                <LoanInformation
                  showOtherAdjustmentsPage={showOtherAdjustmentsPage}
                  showLPMIAdjustmentFeesPage={showLPMIAdjustmentFeesPage}
                  loan={loan}
                  updateLoan={updateLoan}
                  areAllLoansSameEmdAndSellerConcessions={
                    areAllLoansSameEmdAndSellerConcessions
                  }
                  makeAllLoansSameEmdAndSellerConcessions={
                    makeAllLoansSameEmdAndSellerConcessions
                  }
                />

                {isRefinance && (
                  <button
                    className="button loan-calculator-button"
                    onClick={showRefinanceLoanCalculator}
                  >
                    Loan Calculator
                  </button>
                )}

                <CalculationResults loan={loan} updateLoan={updateLoan} />
              </>
            )}
          </div>
        )}
        {loan && activeView === "EDIT_OTHER_CREDITS" && (
          <OtherCreditsTable
            loan={loan}
            goBack={showCalculator}
            updateLoan={updateLoan}
          />
        )}
        {loan && activeView === "EDIT_LPMI_ADJUSTMENTS" && (
          <LPMIAdjustmentFees
            loan={loan}
            goBack={showCalculator}
            updateLoan={updateLoan}
          />
        )}
        {loan && activeView === "REFINANCE_LOAN_CALCULATOR" && (
          <RefinanceLoanCalculator
            loan={loan}
            goBack={showCalculator}
            updateLoan={updateLoan}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
