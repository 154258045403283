import React from "react";
import "./css/closing_cost_details.scss";
import LoanCosts from "./loan_costs";
import OtherCosts from "./other_costs";
import CalculatingCashToClose from "./calculating_cash_to_close";
import AdjustableInterestRateTable from "./adjustable_interest_rate_table";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import AdjustablePaymentTable from "./adjustable_payment_table";
import { useDocument } from "../loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import RefinanceCalculatingCashToClose from "./refinance_calculating_cash_to_close";

export default function ClosingCostDetails({ loan }) {
  const [, , { documentType }] = useDocument();
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const isArm = ARM_TERMS.includes(loan.term);
  const isInterestOnly = loan.payment_type === INTEREST_ONLY;

  return (
    <div className="closing-cost-details">
      <div className="closing-cost-details-title thick-bottom-border big-font bold">
        Closing Cost Details
      </div>
      <div className="left-right-container">
        <div className="left">
          <LoanCosts loan={loan} />
        </div>
        <div className="right">
          <OtherCosts loan={loan} />
          {isPurchase && <CalculatingCashToClose loan={loan} />}
          {isRefinance && <RefinanceCalculatingCashToClose loan={loan} />}
        </div>
      </div>
      <div className="services-by">
        <div className="flex-space-between">
          <div>Escrow Services By:</div>
          <div>{loan.escrow_services_by}</div>
        </div>
        <div className="flex-space-between">
          <div>Title Services By:</div>
          <div>{loan.title_services_by}</div>
        </div>
      </div>
      {isArm && (
        <div className="arm-specifics left-right-container">
          <div className="left">
            <div className="arm-note flex-space-between">
              <div className="big-font bold">NOTE:</div>
              <div className="italic">
                If you are applying for an Adjustable Rate Mortgage (ARM) please
                take special note of the Limits on Interest Rates. These Changes
                potentially will have a significant impact on your Monthly home
                payment.
                <br />
                <br />
                Please discuss these changes with your Loan Officer in detail
                before your select this as an option for your mortgage.
              </div>
            </div>
          </div>
          <div className="right">
            <AdjustableInterestRateTable loan={loan} />
          </div>
        </div>
      )}
      {isInterestOnly && <AdjustablePaymentTable loan={loan} />}
    </div>
  );
}
