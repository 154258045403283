import Decimal from "decimal.js";
import * as FREQUENCY from "../constants/frequencies";

/**
 * Excel PMT function/formula.
 *
 * https://stackoverflow.com/a/22385930
 * with our version following the same logic using Decimal
 **/
// function PMT(ir, np, pv, fv, type) {
//   /*
//    * ir   - interest rate per month
//    * np   - number of periods (months)
//    * pv   - present value
//    * fv   - future value
//    * type - when the payments are due:
//    *        0: end of the period, e.g. end of month (default)
//    *        1: beginning of period
//    */
//   var pmt, pvif;
//
//   fv || (fv = 0);
//   type || (type = 0);
//
//   if (ir === 0)
//     return -(pv + fv)/np;
//
//   pvif = Math.pow(1 + ir, np);
//   pmt = - ir * pv * (pvif + fv) / (pvif - 1);
//
//   if (type === 1)
//     pmt /= (1 + ir);
//
//   return pmt;
// }
export function pmt(ir, np, pv, fv, type) {
  let pmt, pvif;

  fv || (fv = new Decimal(0));
  type || (type = new Decimal(0));

  if (ir.eq(0)) {
    return pv
      .add(fv)
      .div(np)
      .neg();
  }

  pvif = new Decimal(1).add(ir).pow(np);
  pmt = ir
    .mul(pv)
    .mul(pvif.add(fv))
    .div(pvif.sub(1))
    .neg();

  if (type.eq(1)) {
    return pmt.div(ir.add(1));
  }

  return pmt;
}

/**
 * Excel RATE function/formula.
 *
 * https://stackoverflow.com/a/39028970
 * with our version using Decimal
 */
// var rate = function(nper, pmt, pv, fv, type, guess) {
//   // Sets default values for missing parameters
//   fv = typeof fv !== "undefined" ? fv : 0;
//   type = typeof type !== "undefined" ? type : 0;
//   guess = typeof guess !== "undefined" ? guess : 0.1;
//
//   // Sets the limits for possible guesses to any
//   // number between 0% and 100%
//   var lowLimit = 0;
//   var highLimit = 1;
//
//   // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
//   // the solution as valid.
//   var tolerance = Math.abs(0.00000005 * pmt);
//
//   // Tries at most 40 times to find a solution within the tolerance.
//   for (var i = 0; i < 40; i++) {
//     // Resets the balance to the original pv.
//     var balance = pv;
//
//     // Calculates the balance at the end of the loan, based
//     // on loan conditions.
//     for (var j = 0; j < nper; j++) {
//       if (type == 0) {
//         // Interests applied before payment
//         balance = balance * (1 + guess) + pmt;
//       } else {
//         // Payments applied before insterests
//         balance = (balance + pmt) * (1 + guess);
//       }
//     }
//
//     // Returns the guess if balance is within tolerance.  If not, adjusts
//     // the limits and starts with a new guess.
//     if (Math.abs(balance + fv) < tolerance) {
//       return guess;
//     } else if (balance + fv > 0) {
//       // Sets a new highLimit knowing that
//       // the current guess was too big.
//       highLimit = guess;
//     } else {
//       // Sets a new lowLimit knowing that
//       // the current guess was too small.
//       lowLimit = guess;
//     }
//
//     // Calculates the new guess.
//     guess = (highLimit + lowLimit) / 2;
//   }
//
//   // Returns null if no acceptable result was found after 40 tries.
//   return null;
// };
export function rate(nper, pmt, pv, fv, t, g) {
  // Sets default values for missing parameters
  const futureValue = fv || new Decimal(0);
  const type = t || new Decimal(0);
  let guess = g || new Decimal(0.1);

  // Sets the limits for possible guesses to any
  // number between 0% and 100%
  let lowLimit = new Decimal(0);
  let highLimit = new Decimal(1);

  // Defines a tolerance of up to +/- 0.00005% of pmt, to accept
  // the solution as valid.
  const tolerance = pmt.mul(0.00000005).abs();

  // Tries at most 40 times to find a solution within the tolerance.
  for (let i = 0; i < 40; i++) {
    // Resets the balance to the original pv.
    let balance = pv;

    // Calculates the balance at the end of the loan, based
    // on loan conditions.
    for (let j = 0; nper.gt(j); j++) {
      if (type.eq(0)) {
        // Interests applied before payment
        balance = balance.mul(guess.add(1)).add(pmt);
      } else {
        // Payments applied before interests
        balance = balance.add(pmt).mul(guess.add(1));
      }
    }

    // Returns the guess if balance is within tolerance.  If not, adjusts
    // the limits and starts with a new guess.
    if (
      balance
        .add(futureValue)
        .abs()
        .lt(tolerance)
    ) {
      return guess;
    } else if (balance.add(futureValue).gt(0)) {
      // Sets a new highLimit knowing that
      // the current guess was too big.
      highLimit = guess;
    } else {
      // Sets a new lowLimit knowing that
      // the current guess was too small.
      lowLimit = guess;
    }

    // Calculates the new guess.
    guess = highLimit.add(lowLimit).div(2);
  }

  // Returns 0 if no acceptable result was found after 40 tries.
  return new Decimal(0);
}

export function value_of_principal_as_of_term(
  loan_amount,
  rate,
  number_of_periods
) {
  if (!rate) {
    return new Decimal(0);
  }
  const rate_per_period = new Decimal(rate).div(100).div(12);
  return rate_per_period
    .add(1)
    .pow(number_of_periods)
    .mul(loan_amount);
}

export function future_value_of_annuity(
  monthly_payment,
  rate,
  number_of_periods
) {
  if (!rate) {
    return new Decimal(0);
  }
  const rate_per_period = new Decimal(rate).div(100).div(12);
  return rate_per_period
    .add(1)
    .pow(number_of_periods)
    .sub(1)
    .div(rate_per_period)
    .mul(monthly_payment);
}

/**
 * https://money.stackexchange.com/a/61819
 */
export function principal_balance_as_of_term(
  loan_amount,
  monthly_payment,
  rate,
  number_of_periods
) {
  return value_of_principal_as_of_term(
    loan_amount,
    rate,
    number_of_periods
  ).sub(future_value_of_annuity(monthly_payment, rate, number_of_periods));
}

export function is_any_non_zero(arrayOfValues) {
  if (arrayOfValues && arrayOfValues.length) {
    return arrayOfValues.some(value => value && !value.eq(0));
  }
  return false;
}

/**
 * Convert month to year.
 */
export function month_to_year(months) {
  return months ? new Decimal(months).div(12) : new Decimal(0);
}

/**
 * Convert year to month.
 */
export function year_to_month(years) {
  return years ? new Decimal(years).mul(12) : new Decimal(0);
}

/**
 * Convert the given amount defined in the given frequency to monthly.
 */
export function toMonthly(decimal, frequency) {
  switch (frequency) {
    case FREQUENCY.ANNUALLY:
      return new Decimal(decimal).div(12);
    case FREQUENCY.QUARTERLY:
      return new Decimal(decimal).div(3);
    default:
      return new Decimal(decimal);
  }
}
