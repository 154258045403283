// For credit scores, the highest possible credit is considered the lowest number.
// If these are updated then the lowest_credit_score function in "../calculations/applicant" will need to be updated.
export const RANGE_800 = "1";
export const RANGE_760 = "2";
export const RANGE_740 = "3";
export const RANGE_720 = "4";
export const RANGE_700 = "5";
export const RANGE_680 = "6";
export const RANGE_660 = "7";
export const RANGE_640 = "8";
export const RANGE_620 = "9";
export const RANGE_600 = "10";
export const RANGE_580 = "11";
export const RANGE_560 = "12";

export default Object.freeze({
  [RANGE_800]: "800+",
  [RANGE_760]: "760-799",
  [RANGE_740]: "740-759",
  [RANGE_720]: "720-739",
  [RANGE_700]: "700-719",
  [RANGE_680]: "680-699",
  [RANGE_660]: "660-679",
  [RANGE_640]: "640-659",
  [RANGE_620]: "620-639",
  [RANGE_600]: "600-619",
  [RANGE_580]: "580-599",
  [RANGE_560]: "560-579"
});
