import React from "react";

import {
  balance_pay_off,
  balance_to_be_paid_down,
  current_balance,
  lower_balance_payment_left,
  monthly_payment,
  payment_for_DTI_calc,
  payment_savings,
  payments_left,
  percent_of_credit_used
} from "../../../calculations/debt_info";

import LiabilityTypes, {
  LIABILITY_TYPES_FOR_PAYMENTS_LEFT
} from "../../../constants/liability_types";
import ACCOUNT_DISPOSITIONS from "../../../constants/account_dispositions";

import SelectField from "../../../components/fields/select_field";
import NumberField from "../../../components/fields/number_field";
import CurrencyField from "../../../components/fields/currency_field";
import PercentField from "../../../components/fields/percent_field";
import { class_list } from "../../../constants/utils";

export default function AccountSummaryRow({
  accountSummary,
  residence,
  borrower,
  disposition,
  updateDisposition,
  showEditAccount,
  deleteAccountSummary,
  hasLoans
}) {
  function selectDisposition(state) {
    updateDisposition(accountSummary, state.disposition);
  }

  function editAccount() {
    showEditAccount(accountSummary.id);
  }

  const percentOfCreditUsed = percent_of_credit_used(accountSummary, residence);
  const lowerBalancePaymentLeft = lower_balance_payment_left(
    accountSummary,
    residence
  );

  const paymentsLeft = LIABILITY_TYPES_FOR_PAYMENTS_LEFT.includes(
    accountSummary.liability_type
  )
    ? payments_left(accountSummary, residence)
    : "";

  const monthlyPayment = monthly_payment(accountSummary, residence);
  const currentBalance = current_balance(accountSummary, residence);
  const paymentSavings = payment_savings(
    accountSummary,
    residence,
    disposition
  );
  const paymentForDTICalc = payment_for_DTI_calc(
    accountSummary,
    residence,
    disposition
  );
  const balancePayoff = balance_pay_off(currentBalance, disposition);
  const balanceToBePaidDown = balance_to_be_paid_down(
    accountSummary,
    residence,
    disposition
  );

  return (
    <tr className="account-row">
      <td>
        {borrower ? `${borrower.first_name} ${borrower.last_name}` : 'N/A'}
      </td>
      <td>{LiabilityTypes[accountSummary.liability_type]}</td>
      <td>{accountSummary.description}</td>
      <td>{accountSummary.account_number}</td>

      <td>
        <CurrencyField readOnly fixedDigits={0} value={monthlyPayment} />
      </td>
      <td>
        <CurrencyField readOnly fixedDigits={0} value={paymentSavings} />
      </td>
      <td>
        <CurrencyField readOnly fixedDigits={0} value={currentBalance} />
      </td>
      <td>
        <CurrencyField
          readOnly
          fixedDigits={0}
          value={accountSummary.high_credit_limit}
        />
      </td>
      <td>
        <CurrencyField readOnly fixedDigits={0} value={paymentForDTICalc} />
      </td>
      <td>
        <CurrencyField readOnly fixedDigits={0} value={balancePayoff} />
      </td>

      <td>
        <PercentField
          className={class_list(
            percentOfCreditUsed.gt(50) && "red-text",
            percentOfCreditUsed.lte(50) && "green-text"
          )}
          readOnly
          fixedDigits={0}
          allowEmpty={true}
          naked={!percentOfCreditUsed.isFinite()}
          value={percentOfCreditUsed.isFinite() ? percentOfCreditUsed : ""}
        />
      </td>
      <td>
        {paymentsLeft && (
          <NumberField readOnly decimalPlaces={1} value={paymentsLeft} />
        )}
      </td>
      <td>
        <CurrencyField
          readOnly
          fixedDigits={0}
          value={lowerBalancePaymentLeft}
        />
      </td>

      <td className="disposition-field">
        <SelectField
          value={disposition}
          fieldName="disposition"
          onChange={selectDisposition}
          selectOptions={ACCOUNT_DISPOSITIONS}
          readOnly={!hasLoans}
        />
      </td>

      <td>
        <CurrencyField readOnly fixedDigits={0} value={balanceToBePaidDown} />
      </td>
      <td>
        <div className="flex-space-between">
          <button className="button" onClick={editAccount}>
            Edit
          </button>
          <button
            className="button"
            onClick={() => deleteAccountSummary(accountSummary.id)}
          >
            Delete
          </button>
        </div>
      </td>
    </tr>
  );
}
