import React, { useEffect, useState, useRef } from "react";

import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import {
  total_adjustments_other_credits,
  loan_amount_second_mortgage
} from "../../../calculations/loan";

import CurrencyField from "../../fields/currency_field";
import Section from "../../ui/section";
import { useDocument } from "../loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";

export default function OtherCreditsTable({ loan, goBack, updateLoan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType },
    { saveDocument }
  ] = useDocument();
  const [loanEdit, setLoanEdit] = useState({ ...loan });
  const { other_adjustments } = loanEdit;

  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loanEdit.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Adjustments & Credits Form. Would you like to save before you leave?"
          )
        ) {
          save();
        } else {
          // do nothing
        }
      }
    };
  }, [loanEdit]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function save() {
    delete loanEdit.is_modified;
    updateLoan(loanEdit);
    saveDocument();
    goBack();
  }

  function cancel() {
    goBack();
  }

  function updateAdjustment(updatedState) {
    setLoanEdit({
      ...loanEdit,
      other_adjustments: {
        ...loanEdit.other_adjustments,
        ...updatedState
      },
      is_modified: true
    });
  }

  return (
    <Section
      className="down-payment-edit-modal"
      name="Adjustments and Other Credits"
    >
      {SECOND_LOAN_PAYMENT_TYPES.includes(loanEdit.down_payment_type) && (
        <CurrencyField
          readOnly
          name="2nd Loan Amount"
          value={loan_amount_second_mortgage(
            documentType,
            loanEdit,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      )}

      <CurrencyField
        name="Borrower Paid Fees"
        fieldName="borrower_paid_fees"
        value={other_adjustments.borrower_paid_fees}
        onChange={updateAdjustment}
      />

      {isPurchase && (
        <>
          <CurrencyField
            name="Cash Deposit on Sales Contract"
            fieldName="cash_deposit_on_sales_contract"
            value={other_adjustments.cash_deposit_on_sales_contract}
            onChange={updateAdjustment}
          />

          <CurrencyField
            name="Employer Assisted Housing"
            fieldName="employer_assisted_housing"
            value={other_adjustments.employer_assisted_housing}
            onChange={updateAdjustment}
          />

          <CurrencyField
            name="Lease Purchase Fund"
            fieldName="lease_purchase_fund"
            value={other_adjustments.lease_purchase_fund}
            onChange={updateAdjustment}
          />
        </>
      )}

      <CurrencyField
        name="Lender Credit"
        fieldName="lender_credit"
        value={other_adjustments.lender_credit}
        onChange={updateAdjustment}
      />

      {isPurchase && (
        <>
          <CurrencyField
            name="Relocation Funds"
            fieldName="relocation_funds"
            value={other_adjustments.relocation_funds}
            onChange={updateAdjustment}
          />

          <CurrencyField
            name="Seller Credit"
            fieldName="seller_credit"
            value={other_adjustments.seller_credit}
            onChange={updateAdjustment}
          />
        </>
      )}

      {isRefinance && (
        <>
          <CurrencyField
            name="Miscellaneous Credit"
            fieldName="miscellaneous_credit_one"
            value={other_adjustments.miscellaneous_credit_one}
            onChange={updateAdjustment}
          />

          <CurrencyField
            name="Miscellaneous Credit"
            fieldName="miscellaneous_credit_two"
            value={other_adjustments.miscellaneous_credit_two}
            onChange={updateAdjustment}
          />

          <CurrencyField
            name="Miscellaneous Credit"
            fieldName="miscellaneous_credit_three"
            value={other_adjustments.miscellaneous_credit_three}
            onChange={updateAdjustment}
          />
        </>
      )}

      <CurrencyField
        readOnly
        name="Total Adjustments and Other Credits"
        value={total_adjustments_other_credits(
          documentType,
          loanEdit,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <button className="button" onClick={cancel}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
