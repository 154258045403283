import React from "react";
import {
  actual_piti_hoa_without_shock,
  actual_principal_and_interest_monthly_payment,
  bottom_end_home_expense_ratio_io_loan,
  dti_bottom_end_expense_ratio,
  dti_top_front_expense_ratio,
  first_mortgage_payment_interest_only,
  interest_rate_on_new_second_loan,
  monthly_payment_amount_new_second_td,
  mortgage_insurance_government_fee,
  mortgage_insurance_pmi_monthly_fee,
  payment_shock_rate,
  qualifying_arm_first_mortgage_piti_hoa_mi_included,
  qualifying_arm_interest_rate,
  qualifying_principal_interest_taxes_insurance_hoa_and_mi,
  top_front_end_home_expense_ratio_io_loan,
  top_front_home_expense_ratio,
  total_of_all_new_loans,
  total_qualifying_amortizing_payment_p_and_i_payment
} from "../../../calculations/loan";
import { is_any_non_zero } from "../../../calculations/utils";
import PercentField from "../../fields/percent_field";
import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../loan_tool";
import {
  reserves_positive_or_shortage,
  total_reserve_requirement_for_loan_type
} from "../../../calculations/reserve";
import TrainingTip from "../../ui/training_tip";
import {
  balance_pay_offs_total,
  debt_to_income_ratio,
  pay_off_debt_to_qualify_minus_debt_to_income,
  payment_savings_total
} from "../../../calculations/debt_info";
import { REFINANCE } from "../../../constants/document_types";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { class_list, loan_term_name } from "../../../constants/utils";
import { YES } from "../../../constants/yes_no";
import { wrap_investment_properties } from "../../../calculations/investment_reo";

// Interest Rate on NEW Second Loan
export function InterestRateOnNewSecondTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      interest_rate_on_new_second_loan(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic underline" colSpan="3">
          Interest Rate on NEW Second Loan
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={3} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Monthly Payment Amount New Second Loan
export function MonthlyPaymentAmountNewSecondTd({
  documentType,
  fiveLoans,
  property
}) {
  const [{ property_to_be_sold, is_selling_property }] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      monthly_payment_amount_new_second_td(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic underline" colSpan="3">
          Monthly Payment Amount New Second Loan
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Payment Shock on New 2nd Loan (Qualifying)
export function PaymentShockOnNewSecondLoanQualifying({
  documentType,
  fiveLoans,
  hasSecondLoanType,
  property
}) {
  const [{ property_to_be_sold, is_selling_property }] = useDocument();
  if (!hasSecondLoanType) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      payment_shock_rate(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic underline" colSpan="3">
          Payment Shock on New Second Loan (Qualifying)
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// FHA/USDA Add Monthly Pay for MIP Insurance
export function FhaAdditionalMonthlyPayForMipInsurance({
  fiveLoans,
  property
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      mortgage_insurance_government_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic" colSpan="3">
          FHA/USDA Add Monthly Pay for MIP Insurance
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// FM / FMAC Monthly Payment for Insurance
export function FmFmacMonthlyPaymentForInsurance({ fiveLoans, property }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      mortgage_insurance_pmi_monthly_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic" colSpan="3">
          FM / FMac Monthly Payment for Insurance
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Total of All New Loan(s) (Qualifying Payment)
export function TotalOfAllNewLoans({ fiveLoans, property }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_of_all_new_loans(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="top-border" colSpan="3">
          Total of All New Loan(s) (Qualifying Payment)
        </td>
        {values.map((value, index) => (
          <td className="top-border" key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Actual Principal and Interest - Monthly Payment
export function ActualPrincipalAndInterestMonthlyPayment({
  fiveLoans,
  hasSecondLoanType,
  property
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  if (!hasSecondLoanType) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      actual_principal_and_interest_monthly_payment(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );

  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic underline green-text" colSpan="3">
          {hasSecondLoanType &&
            "Actual Principal and Interest - Monthly Payment"}
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Qualifying Principal Interest Taxes Insurance (HOA and MI)
export function QualifyingPrincipalInterestTaxesInsuranceHoaAndMi({
  fiveLoans,
  property
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      qualifying_principal_interest_taxes_insurance_hoa_and_mi(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          Qualifying Principal Interest Taxes Insurance (HOA and MI)
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Actual PITI HOA w/Out Shock - Monthly Payment
export function ActualPitiHoaWithoutShock({
  fiveLoans,
  hasSecondLoanType,
  property
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  if (!hasSecondLoanType) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      actual_piti_hoa_without_shock(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td className="italic underline green-text" colSpan="3">
          Actual PITI HOA w/Out Shock - Monthly Payment
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Total Reserve Requirement
export function TotalReserveRequirement({ fiveLoans }) {
  const [
    {
      loans,
      property,
      investment_properties,
      residences,
      accountSummaries,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_reserve_requirement_for_loan_type(
        documentType,
        loans,
        loan,
        property,
        investment_properties,
        residences,
        accountSummaries,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          Total Reserve Requirement:{" "}
          <TrainingTip content="LANIS Training Note: The Reserves required are based on the loan program selected. For agency programs, 2 months is typical, for most FHA programs, none are required with the exception of Low Score Programs where 3 months are required plus residual income. For Jumbo Loans, check your guidelines. It can range from 6 to 48 months depending on the Borrower(s) situation." />
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Reserves (Positive or Shortage)
export function ReservesPositiveOrShortage({ fiveLoans }) {
  const [
    {
      loans,
      applicant,
      accountSummaries,
      residences,
      property_to_be_sold,
      property,
      is_selling_property,
      investment_properties
    },
    ,
    { documentType }
  ] = useDocument();
  const isRefinance = documentType === REFINANCE;
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      reserves_positive_or_shortage(
        documentType,
        loans,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        property,
        is_selling_property,
        investment_properties
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td
          className={class_list("bold", "italic", isRefinance && "top-border")}
          colSpan="3"
        >
          Reserves (Positive or <span className="red-text">Shortage</span>) &gt;
          &gt;{" "}
          <TrainingTip content="LANIS Training Tip: If you have a shortage of Liquid Funds you will need to review possible other funds available i.e. Retirement plans, gifts from family members or converting other assets. (Paper Trail will be very important). This can be a major issue and cause your Borrower(s) to not qualify for the loan." />
        </td>
        {values.map((value, index) => (
          <td
            className={class_list(
              value && value.lt(0) && "red-text",
              isRefinance && "top-border"
            )}
            key={index}
          >
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Top / Front Home Expense Qualifying Ratio
export function TopFrontHomeExpenseRatio({ fiveLoans }) {
  const [
    {
      applicant,
      property,
      investment_properties,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const values = fiveLoans.map(
    loan =>
      loan &&
      top_front_home_expense_ratio(
        documentType,
        loan,
        property,
        borrowers,
        investment_properties,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          Top / Front Home Expense Qualifying Ratio{" "}
          <TrainingTip content="LANIS Training Tip: If the DTI for a conforming loan is over 45%, DTI Maximum 50%, your Loan may not be approved for either or a Conforming or High Balance Loan. The only way to know for sure is to run AUS. Jumbo 41% or 43% and depends on the Lender. FHA 50% to 55% and depends on the Lender." />
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Bottom End Debt To Income Qualifying Ratio
// Debt To Income Ratio (Key Factor in D/U)
export function BottomEndDebtToIncomeRatio({ fiveLoans }) {
  const [
    {
      applicant,
      investment_properties,
      accountSummaries,
      property,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  const isRefinance = documentType === REFINANCE;
  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties, false);
  const values = fiveLoans.map(
    loan =>
      loan &&
      debt_to_income_ratio(
        documentType,
        borrowers,
        wrapped_investment_properties,
        accountSummaries,
        property,
        residences,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          {isRefinance
            ? "Debt To Income Ratio (Key Factor in D/U)"
            : "Bottom End Debt To Income Qualifying Ratio"}{" "}
          <TrainingTip content="LANIS Training Tip: If the DTI for a conforming loan is over 45%, DTI Maximum 50%, your Loan may not be approved for either or a Conforming or High Balance Loan. The only way to know for sure is to run AUS. Jumbo 41% or 43% and depends on the Lender. FHA 50% to 55% and depends on the Lender." />
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Interest Only Payment
export function InterestOnlyPayment({ fiveLoans, hasInterestOnly }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  if (!hasInterestOnly) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      loan.payment_type === INTEREST_ONLY &&
      first_mortgage_payment_interest_only(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td className="right-align" colSpan="3">
            Interest Only Payment
          </td>
          {values.map((value, index) => (
            <td key={index}>
              {value && <CurrencyField naked fixedDigits={0} value={value} />}
            </td>
          ))}
        </tr>
      </>
    );
  }
}

// Amortized Combined Payment I/O Loan
export function AmortizedCombinedPaymentIOLoan({ fiveLoans, hasInterestOnly }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  if (!hasInterestOnly) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      loan.payment_type === INTEREST_ONLY &&
      total_qualifying_amortizing_payment_p_and_i_payment(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Amortized Combined Payment I/O Loan</td>
        {values.map((value, index) => (
          <td key={index}>
            {value ? (
              <CurrencyField naked fixedDigits={0} value={value} />
            ) : (
              "N/A"
            )}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Top / Front End Home Expense Ratio I/O Loan
export function TopFrontEndHomeExpenseRatioIOLoan({
  fiveLoans,
  hasInterestOnly
}) {
  const [
    {
      applicant,
      investment_properties,
      property,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  if (!hasInterestOnly) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      loan.payment_type === INTEREST_ONLY &&
      top_front_end_home_expense_ratio_io_loan(
        documentType,
        loan,
        property,
        borrowers,
        investment_properties,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Top / Front End Home Expense Ratio I/O Loan</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Bottom End Home Expense Ratio I/O Loan
export function BottomEndHomeExpenseRatioIOLoan({
  fiveLoans,
  hasInterestOnly
}) {
  const [
    {
      property,
      applicant,
      investment_properties,
      accountSummaries,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  if (!hasInterestOnly) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      loan.payment_type === INTEREST_ONLY &&
      bottom_end_home_expense_ratio_io_loan(
        documentType,
        loan,
        property,
        borrowers,
        investment_properties,
        accountSummaries,
        residences,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          {hasInterestOnly && "Bottom End Home Expense Ratio I/O Loan"}
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// ARM terms
export function ArmTerms({ fiveLoans, hasArm }) {
  if (!hasArm) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan => loan && ARM_TERMS.includes(loan.term) && loan_term_name(loan.term)
  );
  return (
    <>
      <tr>
        <td colSpan="8" />
      </tr>
      <tr>
        <td colSpan="3" />
        {values.map((value, index) => (
          <td key={index}>{value}</td>
        ))}
      </tr>
    </>
  );
}

// Qualifying ARM Payment (PITI) HOA & MI incl.
export function QualifyingArmPaymentPitiHoaMiIncluded({ fiveLoans, hasArm }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  if (!hasArm) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      qualifying_arm_interest_rate(loan).gt(0) &&
      qualifying_arm_first_mortgage_piti_hoa_mi_included(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Qualifying ARM Payment (PITI) HOA & MI incl.</td>
        {values.map((value, index) => (
          <td key={index}>
            {value ? (
              <CurrencyField naked fixedDigits={0} value={value} />
            ) : (
              "N/A"
            )}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// DTI Top / Front Expense Ratio
export function DtiTopFrontExpenseRatio({ fiveLoans, hasArm }) {
  const [
    {
      property,
      applicant,
      investment_properties,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  if (!hasArm) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      qualifying_arm_interest_rate(loan).gt(0) &&
      dti_top_front_expense_ratio(
        documentType,
        loan,
        property,
        borrowers,
        investment_properties,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">DTI Top / Front Expense Ratio</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// DTI Bottom End Expense Ratio
export function DtiBottomEndExpenseRatio({ fiveLoans, hasArm }) {
  const [
    {
      property,
      applicant,
      investment_properties,
      accountSummaries,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const { borrowers } = applicant;
  if (!hasArm) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      qualifying_arm_interest_rate(loan).gt(0) &&
      dti_bottom_end_expense_ratio(
        documentType,
        loan,
        property,
        borrowers,
        investment_properties,
        accountSummaries,
        residences,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">DTI Bottom End Expense Ratio</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Monthly Payment Savings
export function MonthlyPaymentSavings({ fiveLoans }) {
  const [{ applicant, accountSummaries, residences }] = useDocument();
  if (applicant.pay_off_debt_to_qualify !== YES) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      payment_savings_total(Object.values(accountSummaries), residences, loan)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Monthly Payment Savings</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Pay off Debt to Qualify - Bottom End Exp. Ratio
export function PayOffDebtToQualifyBottomEndExpRatio({ fiveLoans }) {
  const [
    {
      applicant,
      investment_properties,
      accountSummaries,
      property,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties, false);
  const { borrowers } = applicant;
  if (applicant.pay_off_debt_to_qualify !== YES) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      pay_off_debt_to_qualify_minus_debt_to_income(
        documentType,
        borrowers,
        wrapped_investment_properties,
        accountSummaries,
        property,
        residences,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">
          Pay off Debt to Qualify - Bottom End Exp. Ratio{" "}
          <TrainingTip content="LANIS Training Tip: If the DTI for a conforming loan is over 45%, DTI Maximum 50%, your Loan may not be approved for either or a Conforming or High Balance Loan. The only way to know for sure is to run AUS. Jumbo 41% or 43% and depends on the Lender. FHA 50% to 55% and depends on the Lender." />
        </td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <PercentField naked fixedDigits={2} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Amount off Debt to Pay Off to Qualify
export function AmountOffDebtToPayOffToQualify({ fiveLoans }) {
  const [{ applicant, accountSummaries, residences }] = useDocument();
  if (applicant.pay_off_debt_to_qualify !== YES) {
    return <></>;
  }
  const values = fiveLoans.map(
    loan =>
      loan &&
      balance_pay_offs_total(Object.values(accountSummaries), residences, loan)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Amount off Debt to Pay Off to Qualify</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}
