import React from "react";
import "./css/file_field.scss";

export default function FileField({
  id,
  name,
  className,
  onChange,
  fieldName,
  readOnly,
  inputKey,
  showRequired
}) {
  function class_name() {
    let classname = "input-field file-field";

    if (className) {
      classname += ` ${className}`;
    }

    return classname;
  }

  function change(event) {
    let newValue;
    newValue = event.target.files[0];
    if (onChange && fieldName) {
      onChange({
        [fieldName]: newValue
      });
    }
  }

  return (
    <div className="input-field-container file-field-container">
      <label htmlFor={id}>{name}{showRequired && <span className="required-asterisk">*</span>}</label>
      {!readOnly && (
        <input
          key={inputKey}
          id={id}
          type="file"
          className={class_name()}
          onChange={change}
        />
      )}
    </div>
  );
}
