import {
  IMPORT_CONFIG_FIELDS,
  EXPORT_CONFIG_FIELDS
} from "./fnm_config";
import { deep_clone_simple } from "../constants/utils";
import { fake_state_todo_remove as default_document } from "../constants/fake_state_todo_remove";

/**
 * @type {Object<String, ImportConfigField>}
 */
const IMPORT_CONFIG = Object.assign(...IMPORT_CONFIG_FIELDS
    .map(field => ({ [field.recordId]: field })));

export function fromFnmText(fnmString, options) {
  //console.log(fnmString);

  const document = deep_clone_simple(default_document);
  const documentInfo = {
    documentType: null,
    reoAssetData: {}
  };
  const baseContext = { document, documentInfo, options };

  const lines = fnmString.split(/\r?\n/g);
  const occurrences = {};
  const occurrenceLinesSoFar = {};
  const linesToParse = lines
      .filter(line => line.length)
      .map(line => {
        const recordId = line.substring(0, 3);
        const field = IMPORT_CONFIG[recordId];
        if (!field) {
          console.warn(`Field not found for record id: "${recordId}"`);
        }
        return { field, line };
      })
      .filter(({ field }) => field)
      .sort(({ field: fieldA }, { field: fieldB }) =>
          IMPORT_CONFIG_FIELDS.indexOf(fieldA) - IMPORT_CONFIG_FIELDS.indexOf(fieldB));
  const allOccurrenceLines = {};
  linesToParse.forEach(({ field, line}) => {
    const { recordId } = field;
    allOccurrenceLines[recordId] = occurrenceLinesSoFar[recordId] || [];
    allOccurrenceLines[recordId].push(line);
  });
  linesToParse.forEach(function ({ field, line }) {
        const { recordId } = field;
        occurrenceLinesSoFar[recordId] = occurrenceLinesSoFar[recordId] || [];
        occurrenceLinesSoFar[recordId].push(line);
        // occurrences are 0 indexed, because they're to be used as indexes.
        occurrences[recordId] = (occurrences[recordId] !== undefined ? occurrences[recordId] : -1) + 1;
        const occurrence = occurrences[recordId];
        const inContext = {
          ...baseContext,
          occurrence,
          occurrenceLinesSoFar: occurrenceLinesSoFar[recordId],
          allOccurrenceLines: allOccurrenceLines[recordId]
        }
        inContext.field = field;
        if (field.format) {
          field.parse = function (line) {
            const data = {};
            Object.entries(field.format)
                .forEach(([property, definition]) => {
                  const {
                    position,
                    length
                  } = definition;
                  data[property] = line.substr(position - 1, length).trim()
                });
            return data;
          };
          inContext.data = field.parse(line);
        }
        const functionSkip = typeof field.skip === 'function' && field.skip(inContext);
        const booleanSkip = typeof field.skip === 'boolean' && field.skip;
        if (functionSkip || booleanSkip) {
          // Logging disabled here to keep it a bit less noisy during testing
          //console.debug(`Field intentionally skipped for record id: \"${recordId}\"`)
          return
        }
        if (field.item) {
          inContext.item = field.item(inContext);
        }
        if (field.populate) {
          field.populate(inContext);
        }
      });

  // There's not really a better place for this.
  function formatAddress(borrower) {
    return [
      borrower.current_address,
      borrower.city,
      borrower.state,
      borrower.zip_code,
    ].filter(str => !!str).join(", ");
  }
  document.applicant.borrowers.forEach((borrower, thisIndex) => {
    const thisAddress = formatAddress(borrower);
    if (thisAddress) {
      const sameAddressAsIndex = document.applicant.borrowers.findIndex((other, otherIndex) => {
        if (otherIndex > thisIndex) {
          return false;
        }
        if (other === borrower) {
          return false;
        }
        const otherAddress = formatAddress(other);
        return otherAddress && thisAddress === otherAddress;
      });
      if (sameAddressAsIndex >= 0) {
        borrower.same_address_as = sameAddressAsIndex + "";
      }
    }
  });

  return {
    document,
    documentType: documentInfo.documentType
  };
}

export function toFnmText(document, documentType, accountProfile, companyProfile) {
  // TODO: Add verification function/console.warns for export fields
  //  where the properties don't form a sequential list of blocks
  //  starting from position 4. Then use that in the tests as well as
  //  a sanity check.
  return EXPORT_CONFIG_FIELDS
      .map(field => {
        const processedFormat = processExportFormat(field.recordId, field.format);
        if (processedFormat.error) {
          // Continue with the export for the sake of convenience during dev,
          // but this shouldn't ever happen for users as it's covered in the
          // tests for all fields.
          console.warn(processedFormat.error);
        }
        const baseContext = { document, documentType, accountProfile, companyProfile };
        let dataValues;
        if (field.each) {
          const items = array(field.each(baseContext));
          dataValues = [];
          items.forEach(item => {
            const context = {
              ...baseContext,
              item
            };
            dataValues = dataValues.concat(array(field.data(context)));
          });
        } else {
          dataValues = array(field.data(baseContext));
        }
        return dataValues.map(data => {
          data = data || {};
          return field.recordId + processedFormat.properties
              .map(({ property, definition }) => {
                const {
                  length
                } = definition;
                const value = data[property];
                const valueStr = "" + (value === null || value === undefined ? "" : value);
                return valueStr.padStart(length).substring(0, length);
              })
              .join("");
        });
      })
      .reduce((arr1, arr2) => [].concat(arr1, arr2), [])
      // Per the spec, EOL should include carriage returns.
      .join("\r\n");
}

// The following function is only exported for the sake of testing.
// Probably not best practice, but it's simple. Only intended for
// internal use in fnm.js.
export function processExportFormat(recordId, format) {
  let currentIndex = 4;
  let error = null;
  const properties = Object.entries(format)
      .sort(([, a], [, b]) => a.position - b.position)
      .map(([property, definition], index) => {
        const {
          position,
          length
        } = definition;
        if (index === 0 && position !== 4) {
          error = `Field ${recordId} does not start from position 4`;
        }
        if (!error && currentIndex !== position) {
          error = `Field ${recordId} does not form a continuous line, starting on property ${property}`;
        }
        if (!error && length <= 0) {
          error = `Field ${recordId} has a non-positive length in property ${property}`;
        }
        currentIndex = currentIndex + length;
        return {
          property,
          definition
        }
      });
  return {
    properties,
    error
  }
}

function array(objOrArray) {
  if (objOrArray === null || objOrArray === undefined) {
    return [];
  }
  return objOrArray instanceof Array ? objOrArray : [objOrArray]
}
