import React, { useEffect, useState } from "react";

import Section from "../ui/section";
import CompanyProfile from "../profile/company_profile";
import SystemAdminService from "../../services/system_admin_service";
import {
  default_company_details,
  SUBSCRIPTION_ALWAYS_ACTIVE,
  SUBSCRIPTION_ALWAYS_UNSUBSCRIBED,
  SUBSCRIPTION_NORMAL,
  SUBSCRIPTION_STATUSES
} from "../../schema/system_admin/company_details";
import { default_account_profile } from "../../schema/profile";
import DateField from "../fields/date_field";
import NumberField from "../fields/number_field";
import SelectField from "../fields/select_field";
import TextField from "../fields/text_field";
import { handleErrorMessages } from "../../services/utils";
import ErrorMessages from "../ui/error_messages";
import FileField from "../fields/file_field";
import Spinner from "../ui/spinner";
import { useUser } from "../../hooks/user";

export default function EditCompany({ companyId, onExit }) {
  const [{ companyProfile }, { getProfile }] = useUser();
  const [companyDetails, setCompanyDetails] = useState({
    ...default_company_details
  });
  const [errorMessages, setErrorMessages] = useState([]);
  const [csvFile, setCsvFile] = useState("");
  const [addMultipleUsersErrors, setAddMultipleUsersErrors] = useState([]);
  // Changing key to force re-render to clear the input value.
  const [csvFileInputKey, setCsvFileInputKey] = useState(Date.now());
  const [showSpinner, setShowSpinner] = useState(false);

  function processCompanyDetails(data) {
    const details = { ...data };
    const content = details.company_profile.content;
    delete details.company_profile.content;

    return {
      ...details,
      company_profile: {
        ...default_account_profile,
        ...(content ? JSON.parse(content) : {}),
        ...details.company_profile
      }
    };
  }

  function loadCompany() {
    SystemAdminService.getCompany(companyId)
      .then(data => {
        setCompanyDetails(processCompanyDetails(data));
      })
      .catch(error => handleErrorMessages(error, setErrorMessages));
  }

  function onProfileChange(updates) {
    const updatedProfile = { ...companyDetails.company_profile, ...updates };
    setCompanyDetails({
      ...companyDetails,
      company_profile: updatedProfile
    });
  }

  function onDetailsChange(updates) {
    setCompanyDetails({
      ...companyDetails,
      ...updates
    });
  }

  async function onSave() {
    setShowSpinner(true);
    await SystemAdminService.postCompany(
      companyId,
      companyDetails.company_profile,
      companyDetails.company_profile.seat_limit,
      companyDetails.subscription_status
    )
      .then(() => {
        if (companyId === companyProfile.id) {
          return getProfile();
        }
      })
      .then(() => onExit())
      .catch(error => {
        setShowSpinner(false);
        handleErrorMessages(error, setErrorMessages);
      });
  }

  async function onAddMultipleUsersSubmit() {
    if (!csvFile) {
      window.alert("Please select a CSV file first.");
      return;
    }
    setShowSpinner(true);
    await SystemAdminService.postAddMultipleUsers(companyId, csvFile)
      .then(() => {
        setShowSpinner(false);
        window.alert("Adding users succeeded!");
        onExit();
      })
      .catch(error => {
        setCsvFile("");
        setCsvFileInputKey(Date.now());
        setShowSpinner(false);
        handleErrorMessages(error, setAddMultipleUsersErrors);
      });
  }

  useEffect(() => {
    loadCompany();
  }, [companyId]);

  return (
    <>
      {showSpinner && <Spinner />}
      <button className="button back-button" onClick={onExit}>
        Back
      </button>
      <Section name="Edit Company" className="edit-company">
        <ErrorMessages messages={errorMessages} />
        <div className="flex">
          <div className="half-width">
            <CompanyProfile
              companyProfile={companyDetails.company_profile}
              onCompanyProfileChange={onProfileChange}
            />
          </div>
          <div className="half-width">
            <NumberField
              name="Current Seat Amount"
              value={companyDetails.current_seat_amount}
              readOnly
            />
            <NumberField
              name="Seat Limit"
              fieldName="seat_limit"
              value={companyDetails.company_profile.seat_limit}
              onChange={onProfileChange}
            />
            <SelectField
              name="Subscription Status"
              fieldName="subscription_status"
              value={companyDetails.subscription_status.toString()}
              onChange={onDetailsChange}
              selectOptions={SUBSCRIPTION_STATUSES}
            />
            <ul>
              <li>
                {SUBSCRIPTION_STATUSES[SUBSCRIPTION_NORMAL]} - Subscription
                payment is going through Stripe, and in most cases it should not
                be changed.
              </li>
              <li>
                {SUBSCRIPTION_STATUSES[SUBSCRIPTION_ALWAYS_ACTIVE]} - The
                company is always considered subscribed.
              </li>
              <li>
                {SUBSCRIPTION_STATUSES[SUBSCRIPTION_ALWAYS_UNSUBSCRIBED]} - The
                company is always considered unsubscribed.
              </li>
            </ul>
            <DateField
              name="Subscription End Date"
              value={companyDetails.company_profile.subscription_end_date}
              readOnly
            />
            <p className="bold">Stripe Billing Related Information</p>
            <TextField
              name="Stripe Customer ID"
              value={companyDetails.stripe_customer_id}
              readOnly
            />
            <TextField
              name="Stripe Subscription ID"
              value={companyDetails.stripe_subscription_id}
              readOnly
            />
            <TextField
              name="Stripe Customer Email"
              value={companyDetails.subscription_email}
              readOnly
            />
            <TextField
              name="Stripe Customer Name"
              value={companyDetails.subscription_name}
              readOnly
            />
            <TextField
              name="Stripe Customer Phone"
              value={companyDetails.subscription_phone}
              readOnly
            />
          </div>
        </div>
        <div>
          <button className="button" onClick={onSave}>
            Save
          </button>
        </div>
      </Section>
      <Section name="Add Multiple Users" className="add-multiple-users">
        <ErrorMessages messages={addMultipleUsersErrors} />
        <p>Add multiple users using a CSV file.</p>
        <p className="bold italic">
          A password reset email will be sent to each user added.
        </p>
        <p>
          These exact headers must be included as the first line in the CSV
          file.
          <span className="code-block">
            email,firstName,lastName,role,loanOfficerEmail
          </span>
        </p>
        <ul>
          <li>{`email - User's email as well as the login.`}</li>
          <li>{`firstName - User's first name.`}</li>
          <li>{`lastName - User's last name.`}</li>
          <li>{`role - Currently only supports "Loan Officer" and "Loan Processor".`}</li>
          <li>{`loanOfficerEmail - The Loan Officer email for the Loan Processor. Only applicable to Loan Processors.`}</li>
        </ul>
        <div className="half-width">
          <FileField
            inputKey={csvFileInputKey}
            fieldName="csv_file"
            name="CSV File"
            onChange={value => setCsvFile(value.csv_file)}
          />
          <button className="button" onClick={onAddMultipleUsersSubmit}>
            Submit
          </button>
        </div>
      </Section>
    </>
  );
}
