import React from "react";

import {
  PRIMARY_RESIDENCE,
  SECOND_HOME
} from "../../../constants/liability_types";
import { JUMBO } from "../../../constants/loan_types";
import OCCUPANCY, { OWNER } from "../../../constants/occupancy";

import PercentField from "../../fields/percent_field";
import TextField from "../../fields/text_field";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";

import {
  total_payment,
  wrap_investment_properties,
  wrap_subject_property_if_needed
} from "../../../calculations/investment_reo";
import {
  jumbo_reserves_required_total,
  total_cost_to_purchase
} from "../../../calculations/loan";
import {
  monthly_pitia_total,
  reserve_total_payment,
  residence_total_payment
} from "../../../calculations/debt_info";
import {
  calculations_percentage,
  monthly_percent_reserves,
  reserves_positive_or_shortage,
  subject_property_monthly_percent_reserves,
  subject_property_monthly_pitia,
  subject_property_monthly_reserves,
  subject_property_outstanding_upb,
  subject_property_reserve_months,
  subject_property_total_reserves,
  total_reserve_requirement_for_loan_type,
  total_reserves_available
} from "../../../calculations/reserve";
import { summary_selected_loan } from "../../../constants/utils";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import { PURCHASE } from "../../../constants/document_types";

export default function TotalReserveCalculator() {
  const [document, , { documentType }] = useDocument();
  const {
    applicant,
    accountSummaries,
    loans,
    property,
    investment_properties,
    residences,
    property_to_be_sold,
    is_selling_property
  } = document;
  const borrowers = applicant.borrowers;
  const residenceAccounts = Object.values(accountSummaries).filter(
    filterResidenceAccountSummaries
  );
  const isPurchase = documentType === PURCHASE;

  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties, false);
  const subjectPropertyIsReo = investment_properties
      .some(property => property.is_subject_property);

  function renderNewPropertyRow() {
    const {
      loans,
      summary,
      property,
      investment_properties,
      residences
    } = document;
    const loan = summary_selected_loan(loans, summary);
    const wrapped_investment_properties = wrap_investment_properties(
        property, investment_properties, false);
    return (
      <tr>
        <td>
          <TextField readOnly value="Subject Property" />
        </td>
        <td>
          <TextField readOnly value={OCCUPANCY[property.occupancy]} />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={subject_property_outstanding_upb(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={subject_property_monthly_pitia(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td>
          <NumberField
            readOnly
            value={subject_property_reserve_months(property)}
          />
        </td>
        <td>
          <PercentField
            readOnly
            value={calculations_percentage(wrapped_investment_properties, residences)}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={subject_property_monthly_reserves(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={subject_property_monthly_percent_reserves(
              documentType,
              loan,
              property,
              wrapped_investment_properties,
              residences,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={subject_property_total_reserves(
              documentType,
              loan,
              property,
              wrapped_investment_properties,
              residences,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
      </tr>
    );
  }

  function renderPrimaryResidenceRow() {
    return (
      <tr>
        <td>
          <TextField readOnly value="Primary Residence" />
        </td>
        <td>
          <TextField readOnly value={OCCUPANCY[OWNER]} />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={0}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={0}
          />
        </td>
        <td>
          <NumberField
            readOnly
            value={0}
          />
        </td>
        <td>
          <PercentField
            readOnly
            value={0}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={0}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={0}
          />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={0}
          />
        </td>
      </tr>
    );
  }

  function renderInvesmentREORow(investmentREO, index) {
    const { investment_properties, residences, property } = document;
    const wrapped_investment_properties = wrap_investment_properties(
        property, investment_properties);
    const wrappedREO = wrap_subject_property_if_needed(property, investmentREO);
    const monthlyPITIA = total_payment(wrappedREO);
    const reserveMonths = 6;
    const reservePercentage = calculations_percentage(
      wrapped_investment_properties,
      residences
    );
    const monthlyReserveAmount = reserve_total_payment(
      reserveMonths,
      monthlyPITIA
    );
    const monthlyPercentReserves = monthly_percent_reserves(
      wrappedREO,
      wrapped_investment_properties,
      residences
    );
    const totalReserves = monthlyPercentReserves.plus(monthlyReserveAmount);

    return (
      <tr key={index}>
        <td>
          <TextField readOnly value={wrappedREO.property_address} />
        </td>
        <td>
          <TextField readOnly value="Non-Owner" />
        </td>
        <td>
          <CurrencyField readOnly value={wrappedREO.principal_balance} />
        </td>
        <td>
          <CurrencyField readOnly value={monthlyPITIA} />
        </td>
        <td>
          <NumberField readOnly value={reserveMonths} />
        </td>
        <td>
          <PercentField readOnly value={reservePercentage} />
        </td>
        <td>
          <CurrencyField readOnly value={monthlyReserveAmount} />
        </td>
        <td>
          <CurrencyField readOnly value={monthlyPercentReserves} />
        </td>
        <td>
          <CurrencyField readOnly value={totalReserves} />
        </td>
      </tr>
    );
  }

  function renderResidenceRow(accountSummary, index) {
    const residence = document.residences[accountSummary.residence];
    let propType,
      address,
      reserveMonths,
      reservePercentage = calculations_percentage(
        wrapped_investment_properties,
        residences
      ),
      monthlyPercentReserves,
      monthlyReserveAmount,
      totalReserves;

    const residenceTotalPayment = residence_total_payment(
      residence.first_mortgage,
      residence.second_mortgage,
      residence.taxes,
      residence.insurance,
      residence.hoa
    );

    if (accountSummary.liability_type === PRIMARY_RESIDENCE) {
      address = `Primary Residence`;
      propType = "Owner";
      reserveMonths = "0";
      reservePercentage = "0";
      monthlyReserveAmount = "0";
      monthlyPercentReserves = "0";
      totalReserves = "0";
    } else {
      address = residence.address;
      propType = "2nd Home";
      reserveMonths = "2";
      monthlyReserveAmount = reserve_total_payment(
        reserveMonths,
        residenceTotalPayment
      );
      monthlyPercentReserves = monthly_percent_reserves(
        residence,
        wrapped_investment_properties,
        residences
      );
      totalReserves = monthlyPercentReserves.plus(monthlyReserveAmount);
    }

    return (
      <tr key={index}>
        <td>
          <TextField readOnly value={address} />
        </td>
        <td>
          <TextField readOnly value={propType} />
        </td>
        <td>
          <CurrencyField readOnly value={residence.principal_balance} />
        </td>
        <td>
          <CurrencyField readOnly value={residenceTotalPayment} />
        </td>
        <td>
          <NumberField readOnly value={reserveMonths} />
        </td>
        <td>
          {reservePercentage === "0" ? (
            <TextField readOnly value="N/A" />
          ) : (
            <PercentField readOnly value={reservePercentage} />
          )}
        </td>
        <td>
          <CurrencyField readOnly value={monthlyReserveAmount} />
        </td>
        <td>
          <CurrencyField readOnly value={monthlyPercentReserves} />
        </td>
        <td>
          <CurrencyField readOnly value={totalReserves} />
        </td>
      </tr>
    );
  }

  function filterResidenceAccountSummaries(accountSummary) {
    const residenceLiabilityTypes = [PRIMARY_RESIDENCE, SECOND_HOME];
    return residenceLiabilityTypes.includes(accountSummary.liability_type);
  }

  return (
    <>
      <Section
        className="total-reserve-calculator"
        name="Total Reserve Calculator"
      >
        <table className="reserve-property-table">
          <thead>
            <tr>
              <th>Occupancy</th>
              <th>Prop Type</th>
              <th>Outstanding UPB</th>
              <th>Monthly PITIA</th>
              <th>Reserve Months</th>
              <th>Calculations Percentage</th>
              <th>PITI Reserves</th>
              <th>UPB % Reserves</th>
              <th>Total Reserves</th>
            </tr>
          </thead>
          <tbody>
            {renderNewPropertyRow()}
            {subjectPropertyIsReo && renderPrimaryResidenceRow()}
            {residenceAccounts.map(renderResidenceRow)}
            {wrapped_investment_properties.map(renderInvesmentREORow)}
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td />
              <td />
              <td>
                <CurrencyField
                  readOnly
                  value={monthly_pitia_total(loans, wrapped_investment_properties)}
                />
              </td>
            </tr>
          </tfoot>
        </table>
        <div />

        {loans.some(loan => loan.type === JUMBO) && (
          <table className="total-reserve-calculator-jumbo-reserves">
            <tbody>
              <tr>
                <th/>
                {loans.map((loan, index) => (
                    <td key={index} className="bold center-align">
                      Loan {index + 1}
                    </td>
                ))}
              </tr>
              <tr>
                <th>Jumbo Reserves Required</th>
                {loans.map((loan, index) => (
                    loan.type === JUMBO ? (
                        <td key={index}>
                          <NumberField
                              readOnly
                              value={jumbo_reserves_required_total(loan)}
                          />
                        </td>
                    ) : (
                        <td key={index} className="center-align">
                          N/A
                        </td>
                    )
                ))}
              </tr>
            </tbody>
          </table>
        )}

        <table className="total-reserve-calculator-bottom-table">
          <tbody>
            <tr>
              <th scope="col">Reserve Summary For Loans</th>
              {loans.map((loan, index) => (
                <td key={index} className="bold center-align">
                  Loan {index + 1}
                </td>
              ))}
            </tr>
            {isPurchase && (
              <tr>
                <th scope="col">Total Cost To Purchase Home</th>
                {loans.map((loan, index) => (
                  <td key={index}>
                    <CurrencyField
                      readOnly
                      value={total_cost_to_purchase(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </td>
                ))}
              </tr>
            )}
            <tr>
              <th scope="col">Total Reserves Available</th>
              {loans.map((loan, index) => (
                <td key={index}>
                  <CurrencyField
                    readOnly
                    value={total_reserves_available(
                      documentType,
                      borrowers,
                      accountSummaries,
                      residences,
                      property_to_be_sold,
                      loan,
                      is_selling_property
                    )}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="col">Total Reserve Requirement</th>
              {loans.map((loan, index) => (
                <td key={index}>
                  <CurrencyField
                    readOnly
                    value={total_reserve_requirement_for_loan_type(
                      documentType,
                      loans,
                      loan,
                      property,
                      wrapped_investment_properties,
                      residences,
                      accountSummaries,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </td>
              ))}
            </tr>
            <tr>
              <th scope="col">Reserves (Positive or Shortage)</th>
              {loans.map((loan, index) => (
                <td key={index}>
                  <CurrencyField
                    readOnly
                    value={reserves_positive_or_shortage(
                      documentType,
                      loans,
                      borrowers,
                      accountSummaries,
                      residences,
                      property_to_be_sold,
                      loan,
                      property,
                      is_selling_property,
                      wrapped_investment_properties
                    )}
                  />
                </td>
              ))}
            </tr>
            {isPurchase && (
              <tr>
                <th scope="col">(After Purchase)</th>
              </tr>
            )}
          </tbody>
        </table>
      </Section>

      <Section
        className="fnma-reserves-calculation"
        name="FNMA Reserves Calculation"
      >
        <table>
          <tbody>
            <tr>
              <td>FNMA Reserves Calculation</td>
              <td />
            </tr>
            <tr>
              <td>If Subject Property is:</td>
              <td>Then Reserves required for subject transaction is</td>
            </tr>
            <tr>
              <td>Primary Residence</td>
              <td>None</td>
            </tr>
            <tr>
              <td>2nd Home</td>
              <td>2 months PITIA</td>
            </tr>
            <tr>
              <td>Non-Owner Rental</td>
              <td>6 months PITIA</td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td colSpan="2">
                Plus additional reserves for other properties owned based upon
                their respected Unpaid Principal Balance (UPB)**
              </td>
            </tr>
            <tr>
              <td>If 1-4 Financed Properties</td>
              <td>2% of the unpaid principal balance</td>
            </tr>
            <tr>
              <td>If 5-6 Financed Properties</td>
              <td>4% of the unpaid principal balance</td>
            </tr>
            <tr>
              <td>If 7-10 Financed Properties</td>
              <td>6% of the unpaid principal balance</td>
            </tr>
          </tbody>
        </table>
      </Section>
    </>
  );
}
