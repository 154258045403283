export const APPROVED_DU = "1";
export const APPROVED_UW = "2";
export const CLOSED = "3";
export const CD_DISCLOSED = "4";
export const CLEARED_TO_CLOSE = "5";
export const DENIED = "6";
export const DENIED_CREDIT = "7";
export const DENIED_COMPLIANCE = "8";
export const DENIED_COST_TO_CURE = "9";
export const DENIED_QUAL_RATE = "10";
export const DENIED_RESERVES = "11";
export const DISCLOSE_LE = "12";
export const DISCLOSED_LE_NOT_SUBMITTED = "13";
export const DOCS_OUT = "14";
export const DPA_CLEARED = "15";
export const DPA_SUBMITTED = "16";
export const FUNDED = "17";
export const LOST_NOT_PROCEEDING = "18";
export const LOST_TO_COMPETION = "19";
export const NEW = "20";
export const NOTES = "21";
export const NOT_COMPLIANT = "22";
export const ON_HOLD = "23";
export const PTD_COND = "24";
export const PTF_COND = "25";
export const SUBMITTED_TO_PROCESSOR = "26";
export const TBD_REPORT = "27";
export const WORKING_NOT_SUBMITTED = "28";

export const LOAN_STATUS_NAMES = Object.freeze({
  [APPROVED_DU]: "Approved-DU",
  [APPROVED_UW]: "Approved-UW",
  [CLOSED]: "Closed",
  [CD_DISCLOSED]: "CD Disclosed",
  [CLEARED_TO_CLOSE]: "Cleared to Close",
  [DENIED]: "Denied",
  [DENIED_CREDIT]: "Denied Credit",
  [DENIED_COMPLIANCE]: "Denied Compliance",
  [DENIED_COST_TO_CURE]: "Denied Cost to Cure",
  [DENIED_QUAL_RATE]: "Denied Qual Rate",
  [DENIED_RESERVES]: "Denied Reserves",
  [DISCLOSE_LE]: "Disclose LE",
  [DISCLOSED_LE_NOT_SUBMITTED]: "Disclosed LE Not Submitted",
  [DOCS_OUT]: "Docs Out",
  [DPA_CLEARED]: "DPA Cleared",
  [DPA_SUBMITTED]: "DPA Submitted",
  [FUNDED]: "Funded",
  [LOST_NOT_PROCEEDING]: "Lost Not Proceeding",
  [LOST_TO_COMPETION]: "Lost to Competion",
  [NEW]: "New",
  [NOTES]: "NOTES",
  [NOT_COMPLIANT]: "Not Compliant",
  [ON_HOLD]: "On Hold",
  [PTD_COND]: "PTD Cond",
  [PTF_COND]: "PTF Cond",
  [SUBMITTED_TO_PROCESSOR]: "Submitted to Processor",
  [TBD_REPORT]: "TBD Report",
  [WORKING_NOT_SUBMITTED]: "Working Not Submitted"
});

export const LOAN_STATUS_PERCENTS = Object.freeze({
  [APPROVED_DU]: "40",
  [APPROVED_UW]: "50",
  [CLOSED]: "100",
  [CD_DISCLOSED]: "85",
  [CLEARED_TO_CLOSE]: "70",
  [DENIED]: "0",
  [DENIED_CREDIT]: "0",
  [DENIED_COMPLIANCE]: "0",
  [DENIED_COST_TO_CURE]: "0",
  [DENIED_QUAL_RATE]: "0",
  [DENIED_RESERVES]: "0",
  [DISCLOSE_LE]: "40",
  [DISCLOSED_LE_NOT_SUBMITTED]: "40",
  [DOCS_OUT]: "75",
  [DPA_CLEARED]: "75",
  [DPA_SUBMITTED]: "70",
  [FUNDED]: "95",
  [LOST_NOT_PROCEEDING]: "0",
  [LOST_TO_COMPETION]: "0",
  [NEW]: "10",
  [NOTES]: "",
  [NOT_COMPLIANT]: "0",
  [ON_HOLD]: "30",
  [PTD_COND]: "60",
  [PTF_COND]: "80",
  [SUBMITTED_TO_PROCESSOR]: "20",
  [TBD_REPORT]: "10",
  [WORKING_NOT_SUBMITTED]: "0"
});
