import React from "react";
import {
  total_services_you_cannot_shop_for,
  upfront_funding_fee,
  upfront_mortgage_insurance_fee_fha,
  upfront_mortgage_insurance_fee_usda
} from "../../../calculations/loan";
import SERVICES_YOU_CANNOT_SHOP_FOR from "../../../constants/services_you_cannot_shop_for";
import CurrencyField from "../../fields/currency_field";
import LoanFees from "./loan_fees";
import {
  is_fha,
  is_government_fee_financed_into_loan,
  is_usda,
  is_va
} from "../../../constants/utils";
import TrainingTip from "../../ui/training_tip";
import { useDocument } from "../loan_tool";

export default function ServicesYouCannotShopFor({
  loan,
  readOnly,
  updateLoan,
  removeServicesYouCannotShopFor,
  updateServicesYouCannotShopFor
}) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isFHA = is_fha(loan.type);
  const isUSDA = is_usda(loan.type);
  const isVA = is_va(loan.type);

  return (
    <div className="services-you-cannot-shop-for">
      <div className="closing-costs-row gray-bg top-border">
        <div className="flex align-items-center">
          <div className="section-title">B. Services You Cannot Shop For</div>
          <TrainingTip content="LANIS Training Tip: Tolerance Level 0%" />
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_services_you_cannot_shop_for(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      {isFHA && (
        <div className="closing-costs-row">
          <div>Upfront Mortgage Insurance Fee / FHA</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={upfront_mortgage_insurance_fee_fha(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
      )}
      {isUSDA && (
        <div className="closing-costs-row">
          <div>Upfront Mortgage Insurance Fee / USDA</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={upfront_mortgage_insurance_fee_usda(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
      )}
      {isVA && (
        <div className="closing-costs-row">
          <div>Upfront Funding Fee / VA</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={upfront_funding_fee(documentType, loan, property)}
          />
        </div>
      )}
      {is_government_fee_financed_into_loan(loan) && (
        <div className="closing-costs-row">
          <div>(Government Fee is Financed into the loan)</div>
        </div>
      )}
      <LoanFees
        loanFees={loan.services_you_cannot_shop_for}
        allFees={SERVICES_YOU_CANNOT_SHOP_FOR}
        removeFee={removeServicesYouCannotShopFor}
        updateFees={updateServicesYouCannotShopFor}
        readOnly={readOnly}
      />
    </div>
  );
}
