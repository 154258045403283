import React, { useState, useMemo, useRef, useEffect } from "react";
import Decimal from "decimal.js";

import "./css/percent_field.scss";
import TrainingTip from "../ui/training_tip";

/**
 * PercentField is suitable for rendering a percent-input component.
 * In practice, this means storing, as a string, the digits, sign, and decimal
 * characters.
 */
export default function PercentField({
  readOnly,
  naked,
  allowEmpty,
  value,
  onChange,
  fieldName,
  className,
  id,
  name,
  title,
  tabIndex,
  placeholder,
  fixedDigits,
  decimalPlaces,
  roundTo,
  datalist,
  showRequired
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const displayValue = useMemo(() => format(value), [value]);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.select();
    }
  }, [isFocused]);

  /**
   * Update the state of this percent field.
   * @param {*} event
   */
  function change(event) {
    let newValue;
    let targetValue;
    if (event.target.value === "-") {
      newValue = "-0";
      targetValue = "-";
    } else {
      targetValue = event.target.value.replace(/[^\d.-]/g, "");
      newValue = targetValue === "" ? "0" : targetValue;
    }

    try {
      if (Number.isInteger(roundTo)) {
        newValue = new Decimal(newValue).toDP(roundTo).toString();
      } else {
        // Just test for validity.
        new Decimal(newValue);
      }
    } catch (e) {
      return;
    }

    setInnerValue(targetValue);

    if (onChange && fieldName) {
      onChange({
        [fieldName]: newValue
      });
    }
  }

  /**
   * Event fired when this percent field is focused.
   */
  function onFocus() {
    if (!readOnly) {
      setIsFocused(true);
      setInnerValue(value ? value.replace(/[^\d.-]/g, "") : 0);
    }
  }

  /**
   * Event fired when this percent field is blurred.
   */
  function onBlur() {
    if (!readOnly) {
      setIsFocused(false);
      setInnerValue(format(value));
    }
  }

  /**
   * Formats the given value into a percentage string.
   * @param {*} value
   */
  function format(value) {
    if (allowEmpty && typeof value !== "number" && !value) {
      return "";
    }
    let decimalValue;
    try {
      decimalValue = new Decimal(value);
    } catch (e) {
      decimalValue = new Decimal(0);
    }
    const isDecimalPlaces = Number.isInteger(decimalPlaces);
    const digits = Number.isInteger(fixedDigits)
      ? fixedDigits
      : isDecimalPlaces
        ? decimalPlaces
        : 3;
    return (
      (isDecimalPlaces
        ? decimalValue.toDP(digits)
        : decimalValue.toFixed(digits)
      ).toString() + "%"
    );
  }

  /**
   * Helper function to get the current classname.
   */
  function class_name() {
    let classname = "input-field percent-field";

    if (className) {
      classname += ` ${className}`;
    }

    return classname;
  }

  if (naked) {
    return <span className={`mono-font ${className}`}>{displayValue}</span>;
  }

  return (
    <div className="input-field-container percent-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>
      <input
        ref={inputRef}
        id={id}
        type="text"
        value={isFocused ? innerValue : displayValue}
        onChange={change}
        onFocus={onFocus}
        onBlur={onBlur}
        step="any"
        tabIndex={tabIndex}
        className={class_name()}
        readOnly={readOnly}
        placeholder={placeholder}
        list={id && id + "-datalist"}
      />
      {datalist && (
        <datalist id={id + "-datalist"}>
          {datalist.map(value => (
            <option key={value} value={value} />
          ))}
        </datalist>
      )}
    </div>
  );
}
