import React from "react";
import Decimal from "decimal.js";

import LOAN_PRICING_PERIODS from "../../../constants/loan_pricing_period";
import { YES, YES_NO_TBD } from "../../../constants/yes_no";

import SelectField from "../../fields/select_field";
import DateField from "../../fields/date_field";

import LoanEstimateEntry from "./loan_estimate_entry";
import TIME_ZONES from "../../../constants/time_zones";
import TimeField from "../../fields/time_field";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import { add_days } from "../../../constants/utils";

export default function LoanEstimatePriceEngineMainPage({
  onChangeApplicant,
  showEditLoan
}) {
  const [document] = useDocument();
  const { applicant, loans } = document;
  const {
    closing_costs_expire,
    closing_costs_expire_time,
    closing_costs_expire_time_zone,
    estimated_close_of_escrow,
    first_payment_date,
    pricing_lock_period,
    loan_locked,
    loan_lock_date,
    loan_lock_time,
    loan_lock_time_zone
  } = applicant;

  const estimatedCloseOfEscrow = new Date(estimated_close_of_escrow);
  const firstPaymentDate = new Date(first_payment_date);
  const showDateWarning =
    !isNaN(estimatedCloseOfEscrow) &&
    !isNaN(firstPaymentDate) &&
    firstPaymentDate < estimatedCloseOfEscrow.getTime();

  function edit(e) {
    const index = new Decimal(e.target.dataset.index).toNumber();
    showEditLoan(index);
  }

  return (
    <div className="le-price-engine-main">
      {loans.length > 0 && (
        <>
          <div className="le-price-engine-main-top">
            {showDateWarning && (
              <div className="le-price-engine-main-warning">
                Warning: The first payment date has been set before your
                estimated close of escrow; this is incorrect.
              </div>
            )}
            <div className="flex">
              <div className="half-width">
                <DateField
                  fieldName="closing_costs_expire"
                  name="Closing costs expire"
                  value={closing_costs_expire}
                  onChange={onChangeApplicant}
                />
                <TimeField
                  fieldName="closing_costs_expire_time"
                  name="Closing costs expire time"
                  value={closing_costs_expire_time}
                  onChange={onChangeApplicant}
                />
                <SelectField
                  fieldName="closing_costs_expire_time_zone"
                  name="Closing costs expire time zone"
                  value={closing_costs_expire_time_zone}
                  onChange={onChangeApplicant}
                  selectOptions={TIME_ZONES}
                />
                <DateField
                  fieldName="estimated_close_of_escrow"
                  name="Estimated close of escrow"
                  value={estimated_close_of_escrow}
                  onChange={onChangeApplicant}
                />
                <DateField
                  name="First payment date"
                  fieldName="first_payment_date"
                  value={first_payment_date}
                  onChange={onChangeApplicant}
                />
              </div>
              <div className="half-width">
                <SelectField
                  name="Pricing / Lock Period"
                  fieldName="pricing_lock_period"
                  value={pricing_lock_period}
                  onChange={onChangeApplicant}
                  selectOptions={LOAN_PRICING_PERIODS}
                />
                <SelectField
                  name="Loan Locked"
                  fieldName="loan_locked"
                  value={loan_locked}
                  onChange={onChangeApplicant}
                  selectOptions={YES_NO_TBD}
                />
                {loan_locked === YES && (
                  <DateField
                    name="Lock Date"
                    fieldName="loan_lock_date"
                    value={loan_lock_date}
                    onChange={onChangeApplicant}
                  />
                )}
                {loan_locked === YES && (
                  <TimeField
                    fieldName="loan_lock_time"
                    name="Lock Time"
                    value={loan_lock_time}
                    onChange={onChangeApplicant}
                  />
                )}
                {loan_locked === YES && (
                  <SelectField
                    fieldName="loan_lock_time_zone"
                    name="Lock Time Zone"
                    value={loan_lock_time_zone}
                    onChange={onChangeApplicant}
                    selectOptions={TIME_ZONES}
                  />
                )}
                {loan_locked === YES &&
                  loan_lock_date &&
                  pricing_lock_period && (
                  <DateField
                    name="Lock Exp Date"
                    readOnly
                    value={add_days(loan_lock_date, pricing_lock_period)}
                  />
                )}
              </div>
            </div>
          </div>

          <Section className="le-price-engine-main-loans" name="Loans">
            <div className="flex">
              {loans.map((loan, index) => (
                <div key={index} className="fifth-width">
                  <LoanEstimateEntry
                    key={index}
                    loan={loan}
                    loanIndex={index}
                    readOnly
                  />
                  <button className="button" data-index={index} onClick={edit}>
                    Edit
                  </button>
                </div>
              ))}
            </div>
          </Section>
        </>
      )}
    </div>
  );
}
