import {
  INVESTMENT_PROPERTY as INVESTMENT_PROPERTY_CLASSIFICATION,
  PRIMARY_RESIDENCE,
  SECOND_HOME as SECOND_HOME_CLASSIFICATION,
  VACATION_HOME
} from "../../constants/property_classification";
import {
  INVESTMENT_PROPERTY as INVESTMENT_PROPERTY_OCCUPANCY,
  SECOND_HOME as SECOND_HOME_OCCUPANCY
} from "../../constants/occupancy";
import {
  createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
  createBorrowerIfNotExist
} from "./borrower";
import {
  HOA_PROPERTY_TYPES
} from "../../constants/property_type";
import TITLE_VESTING from "../../constants/title_vesting";
import { deep_clone_simple } from "../../constants/utils";
import { default_property } from "../../schema/investment_property";
import { OWNER } from "../../constants/occupancy";
import { MONTHLY } from "../../constants/frequencies";
import { NO, YES } from "../../constants/yes_no";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import { createSelectedLoanIfNotExist } from "./loan";
import {
  FNM,
  LANIS_LOAN_PURPOSE,
  LANIS_PROPERTY_CLASSIFICATION,
  LANIS_PROPERTY_TYPE,
  LANIS_PURPOSE_OF_REFINANCE, LANIS_REO_PROPERTY_TYPE
} from "../fnm_constants";
import { default_reserve } from "../../schema/reserve";
import { TYPE_ASSET } from "../../constants/reserves_account_types";
import * as RESERVES_ACCOUNT_TYPES from "../../constants/reserves_account_types";
import { filterParseFnmDecimal, parseFnmDecimal } from "../util";

export function importPropertyInformation({ data, document }) {
  const { property } = document;
  // Note: This includes both the first and second lines of the address, without newlines.
  property.address = data.propertyStreetAddress;
  property.city = data.propertyCity;
  property.state = data.propertyState;
  property.zip = data.propertyZipCode;
}

export function importPropertyClassification({ data, document }) {
  const { propertyWillBe } = data;
  const { property } = document;
  if (propertyWillBe) {
    const classification = LANIS_PROPERTY_CLASSIFICATION.import[propertyWillBe];
    if (classification) {
      property.classification = classification;
      if (property.classification === PRIMARY_RESIDENCE) {
        property.occupancy = OWNER;
      } else if (property.classification === INVESTMENT_PROPERTY_CLASSIFICATION) {
        property.occupancy = INVESTMENT_PROPERTY_OCCUPANCY;
      } else if (property.classification === SECOND_HOME_CLASSIFICATION
          || property.classification === VACATION_HOME) {
        property.occupancy = SECOND_HOME_OCCUPANCY;
      }
    } else {
      console.warn("Type of residence did not map to a supported property classification", propertyWillBe);
    }
  }
}

export function importPropertyTitleVesting({ data, document }) {
  const { mannerInWhichTitleWillBeHeld } = data;
  const { property } = document;
  if (mannerInWhichTitleWillBeHeld) {
    const titleVestingType = (Object.entries(TITLE_VESTING)
            .find(([, name]) => name.toLocaleLowerCase() === mannerInWhichTitleWillBeHeld.toLocaleLowerCase())
        || [])[0];
    if (titleVestingType) {
      property.title_vesting = titleVestingType;
    } else {
      console.warn("Manner in which title will be held did not map to a supported title vesting",
          mannerInWhichTitleWillBeHeld);
    }
  }
}

export function importPurposeOfRefinance({ data: { purposeOfRefinance }, document, documentInfo: { documentType } }) {
  const {
    property
  } = document;
  if (purposeOfRefinance) {
    const lanisPurposeOfRefinance = LANIS_PURPOSE_OF_REFINANCE.import[purposeOfRefinance];
    if (lanisPurposeOfRefinance) {
      property.purpose_of_refinance = lanisPurposeOfRefinance;
      if (documentType !== PURCHASE) {
        const loan = createSelectedLoanIfNotExist({ document });
        const loanPurpose = LANIS_LOAN_PURPOSE.import[purposeOfRefinance];
        if (loanPurpose) {
          loan.purpose = loanPurpose;
        } else {
          console.warn("Purpose of refinance did not map to a supported loan purpose", purposeOfRefinance);
        }
      } else {
        console.debug("skipping loan purpose for purchase");
      }
    } else {
      console.warn("Purpose of Refinance did not map to a supported purpose of refinance");
    }
  }
}

export function importHousingExpenses({ data, document, documentInfo, item: borrower }) {
  const {
    presentProposedIndicator,
    housingPaymentTypeCode,
    housingPaymentAmountMonthlyHousingExp
  } = data;
  const {
    property_to_be_sold,
    property
  } = document;
  const { documentType } = documentInfo;
  const isPresent = presentProposedIndicator === FNM.PRESENT_PROPOSED_INDICATOR.PRESENT_HOUSING_EXPENSE;
  const monthlyPaymentAmount = parseFnmDecimal(housingPaymentAmountMonthlyHousingExp);
  if (isPresent) {
    document.is_selling_property = YES;
    switch (housingPaymentTypeCode) {
      case FNM.HOUSING_EXPENSE_TYPE.REAL_ESTATE_TAXES:
        if (documentType === REFINANCE) {
          const existingPropertyTaxAmount = filterParseFnmDecimal(property.refinance_property_tax_amount);
          if (monthlyPaymentAmount && !existingPropertyTaxAmount) {
            property.refinance_property_tax_amount = monthlyPaymentAmount.toString();
          } else {
            console.warn("Skipping refinance property tax amount, already assigned");
          }
          property.refinance_property_tax_frequency = MONTHLY;
        } else {
          const listedPrice = parseFnmDecimal(property.listed_price);
          const existingPropertyTaxPercent = filterParseFnmDecimal(property.property_tax_percent);
          if (!existingPropertyTaxPercent) {
            if (monthlyPaymentAmount && listedPrice && !listedPrice.isZero()) {
              property.property_tax_percent = monthlyPaymentAmount
                  .times(100)
                  .div(property.listed_price)
                  .toDP(2)
                  .toString();
            } else {
              console.warn("Cannot assign property tax percent without listed price");
            }
          } else {
            console.warn("Skipping property tax percent, already assigned");
          }
        }
        break;
      case FNM.HOUSING_EXPENSE_TYPE.FIRST_MORTGAGE_P_AND_I:
        if (monthlyPaymentAmount) {
          const existingPaymentPrincipal = filterParseFnmDecimal(
              property_to_be_sold.first_current_monthly_payment_principal);
          if (!existingPaymentPrincipal) {
            property_to_be_sold.first_current_monthly_payment_principal = monthlyPaymentAmount.toString();
          } else {
            console.warn("Skipping first current monthly payment principal, already assigned");
          }
        }
        break;
      case FNM.HOUSING_EXPENSE_TYPE.OTHER_FINANCING_P_AND_I:
        if (monthlyPaymentAmount) {
          const existingMonthlyPayment = filterParseFnmDecimal(property_to_be_sold.second_current_monthly_payment);
          if (!existingMonthlyPayment) {
            property_to_be_sold.second_current_monthly_payment = monthlyPaymentAmount.toString();
          } else {
            console.warn("Skipping second current monthly payment, already assigned");
          }
        }
        break;
      case FNM.HOUSING_EXPENSE_TYPE.HAZARD_INSURANCE:
        if (monthlyPaymentAmount) {
          const existingHomeOwnersInsurance = filterParseFnmDecimal(property.home_owners_insurance);
          if (!existingHomeOwnersInsurance) {
            property.home_owners_insurance = monthlyPaymentAmount.toString();
          } else {
            console.warn("Skipping home owners insurance, already assigned");
          }
        }
        property.home_owners_insurance_frequency = MONTHLY;
        break;
      case FNM.HOUSING_EXPENSE_TYPE.HOMEOWNER_ASSOCIATION_DUES: {
        if (!HOA_PROPERTY_TYPES.includes(property.property_type)) {
          const investmentProperty = document.investment_properties[0];
          if (investmentProperty) {
            if (monthlyPaymentAmount) {
              const existingHoa1 = filterParseFnmDecimal(investmentProperty.hoa1);
              if (!existingHoa1) {
                investmentProperty.hoa1 = monthlyPaymentAmount.toString();
              } else {
                console.warn("Skipping REO hoa1, already assigned");
              }
            }
            investmentProperty.hoa1_frequency = MONTHLY;
          } else {
            console.warn("Cannot import HOA, no valid properties to import into.")
          }
        } else {
          const existingHoa1 = filterParseFnmDecimal(property.hoa1);
          if (!existingHoa1) {
            property.hoa1 = monthlyPaymentAmount.toString();
            property.hoa1_frequency = MONTHLY;
          } else {
            console.warn("Skipping hoa1, already assigned");
          }
        }
        break;
      }
      case FNM.HOUSING_EXPENSE_TYPE.MORTGAGE_INSURANCE: {
        const existingMortgageInsurance = filterParseFnmDecimal(property_to_be_sold.existing_mortgage_insurance);
        if (!existingMortgageInsurance) {
          property_to_be_sold.existing_mortgage_insurance = monthlyPaymentAmount.toString();
        } else {
          console.warn("Skipping mortgage insurance, already assigned");
        }
        break;
      }
      case FNM.HOUSING_EXPENSE_TYPE.RENT: {
        borrower.rental_amount = monthlyPaymentAmount.toString();
        break;
      }
      default:
        console.warn("Unsupported housing expense type detected: ", housingPaymentTypeCode);
    }
  }
}

export function importRealEstateOwned({ data, document, documentInfo, allOccurrenceLines, field }) {
  const {
    currentResidenceIndicator,
    subjectPropertyIndicator,
    propertyStreetAddress,
    propertyCity,
    propertyState,
    propertyZipCode,
    propertyDisposition,
    typeOfProperty,
    presentMarketValue,
    amountOfMortgagesLiens,
    grossRentalIncome,
    mortgagePayments,
    insuranceMaintenanceTaxesMisc,
    reoAssetId
  } = data;
  const { documentType } = documentInfo;
  const isCurrentResidence = currentResidenceIndicator === 'Y';
  const parsedMarketValue = parseFnmDecimal(presentMarketValue);
  const parsedAmountOfMortgagesLiens = parseFnmDecimal(amountOfMortgagesLiens);
  const isActualSubjectProperty = subjectPropertyIndicator === 'Y';
  // If there is no subject property anywhere in the FNM file, then just
  // consider the first REO to be the subject property.
  const noSubjectPropertyPresent = allOccurrenceLines
      .map(line => field.parse(line))
      .every(({ subjectPropertyIndicator }) => subjectPropertyIndicator !== 'Y');
  const isEffectiveSubjectProperty = documentType === REFINANCE
      && !documentInfo.subjectPropertySet
      && noSubjectPropertyPresent;
  if (isCurrentResidence) {
    const borrower = createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower({ data, document });
    if (propertyStreetAddress) {
      borrower.current_address = propertyStreetAddress;
    }
    if (propertyCity) {
      borrower.city = propertyCity;
    }
    if (propertyState) {
      borrower.state = propertyState;
    }
    if (propertyZipCode) {
      borrower.zip_code = propertyZipCode;
    }
    document.applicant.borrowers.forEach(borrower => {
      // Try to apply the market value to the other borrowers
      if (borrower.current_address === propertyStreetAddress) {
        if (parsedMarketValue) {
          borrower.market_value = parsedMarketValue.toString();
        }
      }
    })
  }
  const isSubjectProperty = isActualSubjectProperty || isEffectiveSubjectProperty;
  const isRental = propertyDisposition === FNM.PROPERTY_DISPOSITION.RENTAL;
  const isSubjectPropertyRental = documentType === REFINANCE
      && isSubjectProperty
      && isRental;
  if (documentType !== REFINANCE
      || (!isSubjectProperty && isRental)
      || isSubjectPropertyRental) {
    if (isSubjectPropertyRental) {
      document.property.classification = INVESTMENT_PROPERTY_CLASSIFICATION;
    }
    const property = deep_clone_simple(default_property);
    property.is_subject_property = isSubjectProperty ? YES : NO;
    property.property_address = propertyStreetAddress;
    property.property_city = propertyCity;
    property.property_state = propertyState;
    property.property_zip = propertyZipCode;
    if (parsedMarketValue) {
      property.market_value = parsedMarketValue.toString();
    }
    if (parsedAmountOfMortgagesLiens) {
      if (isSubjectPropertyRental) {
        document.property.principal_balance = parsedAmountOfMortgagesLiens.toString();
      } else {
        property.principal_balance = parsedAmountOfMortgagesLiens.toString();
      }
    }
    const parsedGrossRentalIncome = parseFnmDecimal(grossRentalIncome);
    if (parsedGrossRentalIncome) {
      property.gross_rent_payment = parsedGrossRentalIncome.toString();
    }
    const parsedMortgagePayments = parseFnmDecimal(mortgagePayments);
    if (parsedMortgagePayments) {
      if (isSubjectPropertyRental) {
        document.property.first_mortgage = parsedMortgagePayments.toString();
      } else {
        property.first_mortgage = parsedMortgagePayments.toString();
      }
    }
    const parsedInsuranceMaintenanceTaxesMisc = parseFnmDecimal(insuranceMaintenanceTaxesMisc);
    if (parsedInsuranceMaintenanceTaxesMisc) {
      property.taxes = parsedInsuranceMaintenanceTaxesMisc.toString();
    }
    const reoPropertyType = LANIS_REO_PROPERTY_TYPE.import[typeOfProperty];
    if (reoPropertyType) {
      property.reo_property_type = reoPropertyType;
    }
    property.reported_on_1040 = NO;
    const sellingProperty = propertyDisposition === FNM.PROPERTY_DISPOSITION.PENDING_SALE
        || propertyDisposition === FNM.PROPERTY_DISPOSITION.SOLD;
    if (sellingProperty) {
      if (documentType === PURCHASE && document.is_selling_property !== YES) {
        document.is_selling_property = YES;
        document.property_to_be_sold.property_type = INVESTMENT_PROPERTY_CLASSIFICATION;
        const marketValue = parseFnmDecimal(property.market_value);
        if (marketValue) {
          document.property_to_be_sold.current_market_value = marketValue.toString();
        }
      } else {
        const reserve = deep_clone_simple(default_reserve);
        reserve.type = TYPE_ASSET;
        reserve.sub_type = RESERVES_ACCOUNT_TYPES.TRADE_EQUITY;
        const marketValue = parseFnmDecimal(property.market_value);
        if (marketValue) {
          reserve.funds = marketValue.toString();
        }
        const borrower = createBorrowerIfNotExist({ occurrence: 0, document });
        borrower.reserves.push(reserve);
      }
    } else {
      if (reoAssetId) {
        documentInfo.reoAssetData[reoAssetId] = {
          property
        }
      }
      document.investment_properties.push(property);
    }
  }
  if (isSubjectProperty) {
    if (reoAssetId) {
      documentInfo.reoAssetData[reoAssetId] = {
        isSubjectProperty: true
      }
    }
    documentInfo.subjectPropertySet = true;
    const propertyType = LANIS_PROPERTY_TYPE.TO_TYPE_OF_PROPERTY.import[typeOfProperty];
    if (parsedMarketValue) {
      document.property.listed_price = parsedMarketValue.toString();
    }
    if (parsedAmountOfMortgagesLiens) {
      document.property_to_be_sold.first_loan_balance = parsedAmountOfMortgagesLiens.toString();
      document.is_selling_property = YES;
    }
    if (propertyType) {
      if (document.property.classification === PRIMARY_RESIDENCE) {
        document.property.occupancy = OWNER;
      }
    } else {
      console.warn("Unsupported type of property detected: ", typeOfProperty);
    }
  }
}

export function importLoanCharacteristics({ data, document }) {
  const { subjectPropertyTypeCode } = data;
  const { property } = document;
  const subjectPropertyType = LANIS_PROPERTY_TYPE.TO_SUBJECT_PROPERTY_TYPE_CODE.import[subjectPropertyTypeCode];
  if (subjectPropertyType) {
    property.property_type = subjectPropertyType;
  } else {
    console.warn("Unsupported type of subject property detected: ", subjectPropertyTypeCode);
  }
}

export function importDetailsOfTransaction({ data, document, documentInfo }) {
  const { documentType } = documentInfo;
  const { purchasePrice } = data;
  const parsedPurchasePrice = parseFnmDecimal(purchasePrice);
  if (parsedPurchasePrice && documentType === PURCHASE) {
    document.property.listed_price = parsedPurchasePrice.toString();
  }
}
