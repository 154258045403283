export const ARM_10_1 = "1";
export const ARM_7_1 = "2";
export const ARM_5_1 = "3";
export const ARM_3_1 = "4";
export const YEARS_5 = "5";
export const YEARS_6 = "6";
export const YEARS_7 = "7";
export const YEARS_8 = "8";
export const YEARS_9 = "9";
export const YEARS_10 = "10";
export const YEARS_11 = "11";
export const YEARS_12 = "12";
export const YEARS_13 = "13";
export const YEARS_14 = "14";
export const YEARS_15 = "15";
export const YEARS_16 = "16";
export const YEARS_17 = "17";
export const YEARS_18 = "18";
export const YEARS_19 = "19";
export const YEARS_20 = "20";
export const YEARS_21 = "21";
export const YEARS_22 = "22";
export const YEARS_23 = "23";
export const YEARS_24 = "24";
export const YEARS_25 = "25";
export const YEARS_26 = "26";
export const YEARS_27 = "27";
export const YEARS_28 = "28";
export const YEARS_29 = "29";
export const YEARS_30 = "30";
export const YEARS_40 = "40";

export const ARM = "ARM";
export const FIXED = "Fixed";

export const ARM_TERMS = [ARM_10_1, ARM_3_1, ARM_5_1, ARM_7_1];

export const LOAN_TERMS = Object.freeze({
  [ARM_10_1]: "10/1 ARM",
  [ARM_3_1]: "3/1 ARM",
  [ARM_5_1]: "5/1 ARM",
  [ARM_7_1]: "7/1 ARM",
  [YEARS_5]: "5",
  [YEARS_6]: "6",
  [YEARS_7]: "7",
  [YEARS_8]: "8",
  [YEARS_9]: "9",
  [YEARS_10]: "10",
  [YEARS_11]: "11",
  [YEARS_12]: "12",
  [YEARS_13]: "13",
  [YEARS_14]: "14",
  [YEARS_15]: "15",
  [YEARS_16]: "16",
  [YEARS_17]: "17",
  [YEARS_18]: "18",
  [YEARS_19]: "19",
  [YEARS_20]: "20",
  [YEARS_21]: "21",
  [YEARS_22]: "22",
  [YEARS_23]: "23",
  [YEARS_24]: "24",
  [YEARS_25]: "25",
  [YEARS_26]: "26",
  [YEARS_27]: "27",
  [YEARS_28]: "28",
  [YEARS_29]: "29",
  [YEARS_30]: "30",
  [YEARS_40]: "40"
});
