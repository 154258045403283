import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  apr,
  in_five_years_principal_paid_off,
  in_five_years_total_paid,
  total_interest_percentage
} from "../../../calculations/loan";
import DateField from "../../fields/date_field";
import PercentField from "../../fields/percent_field";
import { useDocument } from "../../loan_tools/loan_tool";

export default function Comparisons({ loan }) {
  const [
    { applicant, property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  return (
    <div>
      <div className="content-section">
        <div className="summary-header content-title">Comparisons</div>
        <div className="content-description">
          Use these measures to compare this loan with other loans.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">In 5 Years</div>
        <div className="content-value">
          <div>
            <CurrencyField
              naked
              fixedDigits={0}
              value={in_five_years_total_paid(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </div>
          <div>
            <CurrencyField
              naked
              fixedDigits={0}
              value={in_five_years_principal_paid_off(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </div>
        </div>
        <div className="content-description">
          <div>
            Total you will have paid in principal, interest, mortgage insurance,
            and loan costs.
          </div>
          <div className="flex-start-with-spacing">
            <div>Principal you will have paid off.</div>
            <div className="bold">First Payment Date</div>
            <div className="bold">
              <DateField naked value={applicant.first_payment_date} />
            </div>
          </div>
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Annual Percentage Rate (APR)</div>
        <div className="content-value">
          <PercentField
            naked
            fixedDigits={3}
            value={apr(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
        <div className="content-description">
          Your costs over the loan term expressed as a rate. This is not your
          interest rate.
        </div>
      </div>
      <div className="content-section">
        <div className="content-title">Total Interest Percentage (TIP)</div>
        <div className="content-value">
          <PercentField
            naked
            fixedDigits={3}
            value={total_interest_percentage(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
        <div className="content-description">
          <div>
            The total amount of interest that you will pay over the loan term as
            a<br />
            percentage of your loan amount.
          </div>
        </div>
      </div>
    </div>
  );
}
