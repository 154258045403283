import React from "react";
import "./css/misc.scss";
import { useDocument } from "../../components/loan_tools/loan_tool";
import { Link } from "@reach/router";

export default function NoPricingLockPeriod() {
  const [document, , { documentId }] = useDocument();

  const isShow =
    !document ||
    !document.applicant ||
    document.applicant.pricing_lock_period === "";

  return (
    <>
      {isShow && (
        <div className="no-pricing-lock-period">
          Pricing / Lock Period has not been set yet. Please go to the{" "}
          <Link
            className="button"
            to={`/loan-tool/${documentId}/loan-estimate-price-engine`}
          >
            LE Price Eng
          </Link>{" "}
          page to set it up.
        </div>
      )}
    </>
  );
}
