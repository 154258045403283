export const default_pipeline_data = {
  coe: "",
  loan_registered_submitted: "",
  approval_date: "",
  clear_to_close: "",
  docs_out: "",
  funded_disposition: "",
  additional_details: "",
  status: ""
};
