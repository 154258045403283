import React from "react";

import CurrencyField from "../../fields/currency_field";
import { VIEWS } from "../../../constants/investment_reo_views";
import { YES, NO } from "../../../constants/yes_no";

import {
  additional_cash_flow_per_year,
  net_75,
  piti_hoa_monthly_payment,
  net_income_or_loss, wrap_subject_property_if_needed
} from "../../../calculations/investment_reo";
import NumberField from "../../fields/number_field";
import TextField from "../../fields/text_field";

/**
 * InvestPropertyTable displays all the investment properties which belong to the
 * client.
 */
export default function InvestPropertyTable({
  property_index,
  property,
  subject_property,
  openView
}) {
  function edit() {
    openView(VIEWS.INVESTMENT_CALCULATOR, property_index);
  }

  function reported_on_1040() {
    switch (property.reported_on_1040) {
      case YES:
        return "Yes Reported";
      case NO:
        return "Not Reported";
      default:
        return "";
    }
  }

  const read_property = wrap_subject_property_if_needed(subject_property, property);

  return (
    <tr>
      <td>
        <NumberField readOnly value={read_property.year_of_purchase} />
      </td>
      <td className="center-align">{reported_on_1040()}</td>
      <td>
        <TextField readOnly value={read_property.property_address} />
      </td>
      <td>
        {read_property.reported_on_1040 === NO && (
          <CurrencyField readOnly value={read_property.gross_rent_payment} />
        )}
      </td>
      <td>
        {read_property.reported_on_1040 === NO && (
          <CurrencyField
            readOnly
            value={additional_cash_flow_per_year(read_property).toString()}
          />
        )}
      </td>
      <td>
        {read_property.reported_on_1040 === NO && (
          <CurrencyField readOnly value={net_75(read_property).toString()} />
        )}
      </td>
      <td>
        {read_property.reported_on_1040 === NO && (
          <CurrencyField
            readOnly
            value={piti_hoa_monthly_payment(read_property).toString()}
          />
        )}
      </td>
      <td>
        <CurrencyField
          readOnly
          value={net_income_or_loss(read_property).toString()}
        />
      </td>
      <td>
        <button className="button" onClick={edit}>
          Edit
        </button>
      </td>
    </tr>
  );
}
