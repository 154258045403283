import React, { useState } from "react";
import UserService from "../../services/user_service";
import moment from "moment-timezone";
import { processCompanyProfile, useUser } from "../../hooks/user";
import ErrorMessages from "../ui/error_messages";
import Spinner from "../ui/spinner";

export default function Unsubscribe({ onExit }) {
  const [, { setCompanyProfile }] = useUser();
  const [errorMessages, setErrorMessages] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  async function onUnsubscribe() {
    setShowSpinner(true);
    setErrorMessages([]);
    await UserService.postUnsubscribe()
      .then(companyProfile => {
        const endDate = moment(companyProfile.subscription_end_date)
          .tz(moment.tz.guess())
          .format("MM/DD/YYYY");
        setCompanyProfile(processCompanyProfile(companyProfile));
        setSuccessMessage(
          `You have unsubscribed and your subscription will be terminated on ${endDate}.`
        );
      })
      .catch(error => {
        error.json().then(messages => {
          setErrorMessages(messages || ["There was an error."]);
        });
      });
    setShowSpinner(false);
  }

  return (
    <div className="unsubscribe">
      {showSpinner && <Spinner />}
      {successMessage && (
        <p>
          {successMessage}{" "}
          <button className="button" onClick={onExit}>
            Back
          </button>
        </p>
      )}
      {!successMessage && (
        <>
          <ErrorMessages messages={errorMessages} />
          <p>
            Are you sure you want to unsubscribe?{" "}
            <button className="button" onClick={onExit}>
              Cancel
            </button>
            <button className="button" onClick={onUnsubscribe}>
              Unsubscribe
            </button>
          </p>
        </>
      )}
    </div>
  );
}
