import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import "./css/home.scss";

import DocumentService from "../services/document_service";
import TextField from "../components/fields/text_field";
import { fake_state_todo_remove } from "../constants/fake_state_todo_remove";
import CurrencyField from "../components/fields/currency_field";
import { LOAN_TYPE_CATEGORY_NAMES } from "../constants/loan_types";
import DateField from "../components/fields/date_field";
import SelectField from "../components/fields/select_field";
import {
  APPROVED_DU,
  APPROVED_UW,
  CD_DISCLOSED,
  CLEARED_TO_CLOSE,
  CLOSED,
  DENIED,
  DENIED_COMPLIANCE,
  DENIED_COST_TO_CURE,
  DENIED_CREDIT,
  DENIED_QUAL_RATE,
  DENIED_RESERVES,
  DISCLOSE_LE,
  DISCLOSED_LE_NOT_SUBMITTED,
  DOCS_OUT,
  DPA_CLEARED,
  DPA_SUBMITTED,
  FUNDED,
  LOAN_STATUS_NAMES,
  LOAN_STATUS_PERCENTS,
  LOST_NOT_PROCEEDING,
  LOST_TO_COMPETION,
  NEW,
  NOT_COMPLIANT,
  NOTES,
  ON_HOLD,
  PTD_COND,
  PTF_COND,
  SUBMITTED_TO_PROCESSOR,
  TBD_REPORT,
  WORKING_NOT_SUBMITTED
} from "../constants/loan_status";
import PercentField from "../components/fields/percent_field";
import { default_metadata } from "../schema/metadata";
import { default_pipeline_data } from "../schema/pipeline_data";
import DOCUMENT_TYPES, {
  PURCHASE,
  REFINANCE
} from "../constants/document_types";
import { class_list } from "../constants/utils";
import moment from "moment-timezone";
import StandardTemplate from "../templates/standard_template/standard_template";
import { NO, YES, YES_NO } from "../constants/yes_no";
import { useUser } from "../hooks/user";
import { Link } from "@reach/router";
import TrainingTip from "../components/ui/training_tip";
import { fromFnmText } from "../fnm/fnm";
import ErrorMessages from "../components/ui/error_messages";
import { FnmHelpLink } from "../components/ui/fnm_help";

function DateFieldMask({
  children,
  fieldName,
  value,
  onChange,
  onBlurChange,
  readOnly
}) {
  const [showMask, setShowMask] = useState(true);
  const dateRef = useRef();

  useLayoutEffect(() => {
    if (!showMask) {
      dateRef.current.setFocus();
    }
  }, [showMask]);

  function onMaskClick() {
    if (readOnly) {
      return;
    }
    setShowMask(false);
  }

  function onDateFieldBlur() {
    setShowMask(true);
  }

  return (
    <div>
      {showMask && (
        <div className="pointer" onClick={onMaskClick}>
          {children}
        </div>
      )}
      {!showMask && (
        <DateField
          ref={dateRef}
          onBlur={onDateFieldBlur}
          fieldName={fieldName}
          value={value}
          onChange={onChange}
          onBlurChange={onBlurChange}
        />
      )}
    </div>
  );
}

export default function HomePage() {
  const [{ accountProfile, companyProfile }, { logout }] = useUser();
  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [documentType, setDocumentType] = useState(PURCHASE);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [fnmImporting, setFnmImporting] = useState(false);
  const [importDocument, setImportDocument] = useState(null);
  const [importFile, setImportFile] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);
  const [currentSection, setCurrentSection] = useState("TIME_SENSITIVE");
  const [showClosed, setShowClosed] = useState(NO);
  const fileInputEl = useRef();
  const filteredDocuments = useMemo(
    () =>
      documents.filter(
        doc =>
          !doc.pipelineData ||
          doc.pipelineData.status !== CLOSED ||
          showClosed === YES
      ),
    [documents, showClosed]
  );
  const timeSensitivePipeline = useMemo(
    () =>
      filteredDocuments.filter(
        doc => doc.metadata && doc.metadata.time_sensitive === YES
      ),
    [filteredDocuments]
  );
  const purchasePipeline = useMemo(
    () =>
      filteredDocuments.filter(
        doc =>
          doc.type === PURCHASE &&
          (!doc.metadata || doc.metadata.time_sensitive !== YES)
      ),
    [filteredDocuments]
  );
  const refinancePipeline = useMemo(
    () =>
      filteredDocuments.filter(
        doc =>
          doc.type === REFINANCE &&
          (!doc.metadata || doc.metadata.time_sensitive !== YES)
      ),
    [filteredDocuments]
  );

  useEffect(() => {
    if (!importFile) {
      return;
    }

    let ignore = false;

    const reader = new FileReader();
    reader.onload = () => {
      if (!ignore) {
        try {
          // The code gen sometimes compiles this poorly, seemingly.
          // Picking non-conflicting names should prevent the issue.
          const {
            document: _document,
            documentType: _documentType
          } = fromFnmText(reader.result, {});
          if (!_documentType) {
            setErrorMessages([`Error reading FNM: Unsupported loan type`]);
          } else {
            console.log(`Ready to import document of type ${DOCUMENT_TYPES[_documentType]}:`, _document);
            setImportDocument(_document);
            setDocumentType(_documentType);
            setErrorMessages([]);
          }
        } catch (e) {
          console.error("Error converting FNM to object:", e);
          setErrorMessages([`Error reading FNM: ${e.message || "Unknown"}`]);
        }
      }
    };
    reader.readAsText(importFile);

    return () => {
      ignore = true;
    };
  }, [importFile]);

  function onImportFileChange(e) {
    const file = e.target.files[0];

    if (!file) {
      setImportFile(null);
      setErrorMessages(["No file selected."]);
      return;
    }
    setImportFile(file);
    setErrorMessages([]);
  }

  const greenStatuses = [
    APPROVED_DU,
    APPROVED_UW,
    CLEARED_TO_CLOSE,
    DPA_CLEARED,
    DPA_SUBMITTED,
    NEW,
    PTD_COND,
    PTF_COND,
    SUBMITTED_TO_PROCESSOR,
    WORKING_NOT_SUBMITTED
  ];
  const inverseGreenStatuses = [CLOSED, CD_DISCLOSED, DOCS_OUT, FUNDED];
  const redStatuses = [
    DENIED,
    DENIED_CREDIT,
    DENIED_COMPLIANCE,
    DENIED_COST_TO_CURE,
    DENIED_QUAL_RATE,
    DENIED_RESERVES,
    LOST_NOT_PROCEEDING,
    LOST_TO_COMPETION
  ];
  const redOnWhiteStatuses = [
    DISCLOSE_LE,
    DISCLOSED_LE_NOT_SUBMITTED,
    NOT_COMPLIANT
  ];
  const yellowStatuses = [NOTES, ON_HOLD, TBD_REPORT];

  const getDocuments = async () => {
    try {
      const loanDocuments = await DocumentService.getPipelineData();
      loanDocuments
        .sort((docA, docB) => docB.modified - docA.modified)
        .forEach(loanDocument => {
          loanDocument.metadata =
            loanDocument.metadata && JSON.parse(loanDocument.metadata);
          loanDocument.pipelineData =
            loanDocument.pipelineData && JSON.parse(loanDocument.pipelineData);
        });
      return loanDocuments;
    } catch (e) {
      if (e.statusText === "Unauthorized") {
        logout();
      }
    }
  };

  const showCreateNameModal = type => {
    setDocumentType(type);
    setShowCreateModal(true);
  };

  const showCreateNameModalForImport = () => {
    setFnmImporting(true);
    setShowCreateModal(true);
  };

  const hideCreateNameModal = () => {
    setDocumentName("");
    setFnmImporting(false);
    setErrorMessages([]);
    setImportFile(null);
    setImportDocument(null);
    setShowCreateModal(false);
  };

  const createDocument = async (documentToCreate = fake_state_todo_remove) => {
    try {
      const response = await DocumentService.createDocument(
        documentType,
        documentToCreate,
        default_metadata,
        documentName
      );
      hideCreateNameModal();
      window.open(`/loan-tool/${response.id}`, "_blank");
    } catch (e) {
      // TODO: do something.
    }
  };

  const saveStatus = async (status, id) => {
    try {
      return await DocumentService.savePipelineData(status, id);
    } catch (e) {
      // TODO: do something.
    }
  };

  const onPipelineDataChange = async (updatedPipelineData, id, isSave) => {
    const updateDocuments = newPipelineData => {
      const updatedDocuments = documents.reduce((newDocuments, document) => {
        const newDocument = { ...document };
        if (newDocument.id === id) {
          newDocument.pipelineData = newPipelineData;
        }
        newDocuments.push(newDocument);
        return newDocuments;
      }, []);
      setDocuments(updatedDocuments);
    };

    const document = documents.find(document => document.id === id);
    if (document) {
      const newPipelineData = {
        ...(document.pipelineData || default_pipeline_data),
        ...updatedPipelineData
      };
      if (isSave) {
        await saveStatus(newPipelineData, id).then(() => {
          updateDocuments(newPipelineData);
        });
      } else {
        updateDocuments(newPipelineData);
      }
    }
  };

  useEffect(() => {
    getDocuments().then(newDocuments => {
      if (newDocuments) {
        setDocuments(newDocuments);
      }
    });
  }, []);

  function renderFilter() {
    return (
      <div className="pipeline-filters">
        <SelectField
          fieldName="value"
          name="Show Closed Documents"
          value={showClosed}
          onChange={updates => setShowClosed(updates.value)}
          selectOptions={YES_NO}
        />
      </div>
    );
  }

  function renderList(docs) {
    return docs.map(document => {
      const metadata = document.metadata || {};
      const pipelineData = document.pipelineData || {};
      return (
        <div key={document.id} className="pipeline-entry-container">
          <div className="pipeline-entry-title">
            {companyProfile.subscription_active ? (
              <a
                className="button"
                href={`/loan-tool/${document.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {document.name}
              </a>
            ) : (
              <button className="button" disabled={true}>
                {document.name}
              </button>
            )}
            {metadata.time_sensitive === YES && (
              <span className="time-sensitive-badge">Time Sensitive</span>
            )}
          </div>
          <div className="pipeline-entry">
            <div className="pipeline-entry-row">
              <div className="pipeline-entry-cell">
                <div className="entry-title">Last Modified</div>
                <div className="bold">
                  {moment(document.modified)
                    .tz(moment.tz.guess())
                    .format("MM/DD/YYYY hh:mm A")}
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div>
                  {metadata.borrower_names && metadata.borrower_names[0]}
                </div>
                <div>
                  {metadata.borrower_names && metadata.borrower_names[2]}
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div>
                  {metadata.borrower_names && metadata.borrower_names[1]}
                </div>
                <div>
                  {metadata.borrower_names && metadata.borrower_names[3]}
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">1st Loan Amount</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={metadata.first_loan_amount}
                  />
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">2nd Loan Amount</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={metadata.second_loan_amount}
                  />
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Lender</div>
                <div>{metadata.lender}</div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Loan Type</div>
                <div>{LOAN_TYPE_CATEGORY_NAMES[metadata.loan_type]}</div>
              </div>
            </div>
            <div className="pipeline-entry-row">
              <div className="pipeline-entry-cell">
                <div className="entry-title">Registered Submitted</div>
                <div>
                  <DateFieldMask
                    fieldName="loan_registered_submitted"
                    value={pipelineData.loan_registered_submitted}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        !pipelineData.loan_registered_submitted &&
                          "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField
                          naked
                          value={pipelineData.loan_registered_submitted}
                        />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Locked Exp Date</div>
                <div
                  className={class_list(
                    "right-angle-box",
                    "inverse-right-angle-box",
                    !metadata.locked_exp_date && "unfilled-right-angle-box"
                  )}
                >
                  <div className="box-content">
                    <DateField naked value={metadata.locked_exp_date} />
                  </div>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Approval Date</div>
                <div>
                  <DateFieldMask
                    fieldName="approval_date"
                    value={pipelineData.approval_date}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        !pipelineData.approval_date &&
                          "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField naked value={pipelineData.approval_date} />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Clear to Close (CTC)</div>
                <div>
                  <DateFieldMask
                    fieldName="clear_to_close"
                    value={pipelineData.clear_to_close}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        !pipelineData.clear_to_close &&
                          "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField naked value={pipelineData.clear_to_close} />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Docs Out</div>
                <div>
                  <DateFieldMask
                    fieldName="docs_out"
                    value={pipelineData.docs_out}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        !pipelineData.docs_out && "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField naked value={pipelineData.docs_out} />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Funded Disposition</div>
                <div>
                  <DateFieldMask
                    fieldName="funded_disposition"
                    value={pipelineData.funded_disposition}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        !pipelineData.funded_disposition &&
                          "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField
                          naked
                          value={pipelineData.funded_disposition}
                        />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
            </div>
            <div className="pipeline-entry-row">
              <div className="pipeline-entry-cell">
                <div className="entry-title">Loan Number</div>
                <div>{metadata.loan_number}</div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">COE</div>
                <div>
                  <DateFieldMask
                    fieldName="coe"
                    value={pipelineData.coe}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  >
                    <div
                      className={class_list(
                        "right-angle-box",
                        "inverse-right-angle-box",
                        !pipelineData.coe && "unfilled-right-angle-box"
                      )}
                    >
                      <div className="box-content">
                        <DateField naked value={pipelineData.coe} />
                      </div>
                    </div>
                  </DateFieldMask>
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Refinance Purchase</div>
                <div>{DOCUMENT_TYPES[document.type]}</div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Additional Details</div>
                <div>
                  <TextField
                    fieldName="additional_details"
                    value={pipelineData.additional_details}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, false)
                    }
                    onBlurChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    readOnly={!companyProfile.subscription_active}
                  />
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Status</div>
                <div className="loan-status-container">
                  <SelectField
                    className={class_list(
                      greenStatuses.includes(pipelineData.status) &&
                        "green-status",
                      inverseGreenStatuses.includes(pipelineData.status) &&
                        "inverse-green-status",
                      redStatuses.includes(pipelineData.status) && "red-status",
                      redOnWhiteStatuses.includes(pipelineData.status) &&
                        "red-on-white-status",
                      yellowStatuses.includes(pipelineData.status) &&
                        "yellow-status"
                    )}
                    optionClassNameFunction={value => {
                      if (greenStatuses.includes(value)) {
                        return "green-status";
                      }
                      if (inverseGreenStatuses.includes(value)) {
                        return "inverse-green-status";
                      }
                      if (redStatuses.includes(value)) {
                        return "red-status";
                      }
                      if (redOnWhiteStatuses.includes(value)) {
                        return "red-on-white-status";
                      }
                      if (yellowStatuses.includes(value)) {
                        return "yellow-status";
                      }
                    }}
                    onChange={updates =>
                      onPipelineDataChange(updates, document.id, true)
                    }
                    fieldName="status"
                    value={pipelineData.status || ""}
                    selectOptions={LOAN_STATUS_NAMES}
                    showDefaultOption
                    readOnly={!companyProfile.subscription_active}
                  />
                </div>
              </div>
              <div className="pipeline-entry-cell">
                <div className="entry-title">Percent of Process to Close</div>
                <div>
                  {LOAN_STATUS_PERCENTS[pipelineData.status] && (
                    <PercentField
                      naked
                      fixedDigits={0}
                      value={LOAN_STATUS_PERCENTS[pipelineData.status]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <StandardTemplate basic showMoreLinks>
      <div className="pipeline-page">
        <div className="top-header-section">
          <div className="top-header-section-title">PIPELINE</div>
          {accountProfile.loan_officer_role && (
            <>
              <button
                className="button"
                onClick={() => showCreateNameModal(PURCHASE)}
                disabled={!companyProfile.subscription_active}
              >
                New Purchase
              </button>
              <button
                className="button"
                onClick={() => showCreateNameModal(REFINANCE)}
                disabled={!companyProfile.subscription_active}
              >
                New Refinance
              </button>
              <button
                className="button"
                onClick={() => showCreateNameModalForImport()}
                disabled={!companyProfile.subscription_active}
              >
                FNM Import
              </button>
              <TrainingTip content="LANIS Training Tip: Please turn off Pop up Blockers to see your new File. Also, Before Importing a file into LANIS TIER|ONE the Loan
Officer or Processor must have the Social Security Field filled in even though the Borrower may not want you to pull credit. You can use a
temporary SS# i.e., 111-11-1111 as an example. A different Social Security number would need to be entered for All Borrowers to comply
with cross referencing in the Fannie Mae formatting protocols and Importing correctly in to the LANIS App." />
              <FnmHelpLink />
            </>
          )}
        </div>
        {!companyProfile.subscription_active && (
          <div className="error-messages">
            <p>
              Your subscription has expired.{" "}
              {companyProfile.normal_subscription &&
                (accountProfile.company_admin_role ? (
                  <>
                    Please go to{" "}
                    <Link to="/subscribe" className="button">
                      Subscribe
                    </Link>{" "}
                    to renew your subscription.
                  </>
                ) : (
                  "Please contact your administrator."
                ))}
              {!companyProfile.normal_subscription &&
                "Please contact customer support."}
            </p>
          </div>
        )}
        <div className="pipeline-section-nav">
          <div
            className={class_list(
              "pipeline-section-picker",
              "time-sensitive",
              currentSection === "TIME_SENSITIVE" && "active"
            )}
            onClick={() => setCurrentSection("TIME_SENSITIVE")}
          >
            Time Sensitive Pipeline
          </div>
          <div
            className={class_list(
              "pipeline-section-picker",
              currentSection === PURCHASE && "active"
            )}
            onClick={() => setCurrentSection(PURCHASE)}
          >
            Purchase Pipeline
          </div>
          <div
            className={class_list(
              "pipeline-section-picker",
              currentSection === REFINANCE && "active"
            )}
            onClick={() => setCurrentSection(REFINANCE)}
          >
            Refinance Pipeline
          </div>
        </div>
        <div>{renderFilter()}</div>
        <div className="pipeline-list">
          {currentSection === "TIME_SENSITIVE" &&
            renderList(timeSensitivePipeline)}
          {currentSection === PURCHASE && renderList(purchasePipeline)}
          {currentSection === REFINANCE && renderList(refinancePipeline)}
        </div>
        {showCreateModal && (
          <div className="create-name-modal-bg" onClick={hideCreateNameModal}>
            <div
              className="create-name-modal"
              onClick={e => e.stopPropagation()}
            >
              <TextField
                name={`${!fnmImporting ? DOCUMENT_TYPES[documentType] + " " : ""}Document Name`}
                fieldName="documentName"
                value={documentName}
                onChange={value => setDocumentName(value.documentName)}
              />
              { fnmImporting && (
                  <>
                    <div className="input-field-container import-file-field">
                      <label htmlFor="file">File</label>
                      <input
                          id="file"
                          className="input-field"
                          type="file"
                          ref={fileInputEl}
                          accept=".fnm"
                          onChange={onImportFileChange}
                      />
                    </div>
                    <ErrorMessages messages={errorMessages} />
                    <button
                        className="button"
                        disabled={!documentName || !importDocument}
                        onClick={() => createDocument(importDocument)}
                    >
                      Create
                    </button>
                  </>
              ) }
              { !fnmImporting && (
                  <button
                      className="button"
                      disabled={!documentName}
                      onClick={() => createDocument()}
                  >
                    Create
                  </button>
              ) }
              <button className="button" onClick={hideCreateNameModal}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </StandardTemplate>
  );
}
