import React from "react";
import "./css/checkout.scss";

import {
  basic_monthly,
  basic_monthly_2,
  basic_yearly
} from "../constants/subscription_products";
import StripeCardCheckout from "../components/stripe/stripe_card_checkout";
import ErrorMessages from "../components/ui/error_messages";

// Checkout page that is meant to be a full page content in other pages.
export default function Checkout({
  onBeforeCreatePaymentMethod,
  onSubmit,
  product,
  setProduct,
  paymentErrors,
  setPaymentErrors,
  paymentName,
  setPaymentName,
  paymentEmail,
  setPaymentEmail,
  paymentPhone,
  setPaymentPhone,
  promoCode,
  setPromoCode,
  profile,
  newRegistration
}) {
  return (
    <div className="checkout">
      <ErrorMessages messages={paymentErrors} />
      <div className="flex">
        <div className="half-width">
          <div className="title">Payment Plans</div>
          <div className="plan-title">
            Basic Professional Platform
            {newRegistration && " – Include a 14 day free trial"}
          </div>
          <div className="plan-price">
            <input
              type="radio"
              name="products"
              id="basic-monthly"
              checked={product === basic_monthly}
              value={basic_monthly}
              onChange={e => setProduct(e.target.value)}
            />
            <label htmlFor="basic-monthly">Monthly $85.00</label>
          </div>
          <div className="plan-price">
            <input
              type="radio"
              name="products"
              id="basic-monthly-2"
              checked={product === basic_monthly_2}
              value={basic_monthly_2}
              onChange={e => setProduct(e.target.value)}
            />
            <label htmlFor="basic-monthly">
              Monthly $95.00 (Add 1 Dedicated Loan Processor Sub-Account)
            </label>
          </div>
          <div className="plan-price">
            <input
              type="radio"
              name="products"
              id="basic-yearly"
              checked={product === basic_yearly}
              value={basic_yearly}
              onChange={e => setProduct(e.target.value)}
            />
            <label htmlFor="basic-yearly">
              Annual $1,020.00 (Includes 1 Dedicated Loan Processor Sub-Account
              FREE for the first year, $120.00
            </label>
          </div>
        </div>
        <div className="half-width">
          <div className="title">Payment</div>
          <StripeCardCheckout
            onBeforeCreatePaymentMethod={onBeforeCreatePaymentMethod}
            paymentName={paymentName}
            setPaymentName={setPaymentName}
            paymentEmail={paymentEmail}
            setPaymentEmail={setPaymentEmail}
            paymentPhone={paymentPhone}
            setPaymentPhone={setPaymentPhone}
            onSubmit={onSubmit}
            onError={error => setPaymentErrors([error.message])}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
            profile={profile}
          />
        </div>
      </div>
    </div>
  );
}
