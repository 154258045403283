import React from "react";

import CurrencyField from "../../fields/currency_field";

import InvestmentProperty from "./investment_property";

import {
  gross_rent_payment_total,
  additional_cash_flow_per_year_total,
  net_75_total,
  piti_hoa_monthly_payment_total,
  net_income_or_loss_total, wrap_investment_properties
} from "../../../calculations/investment_reo";
import { VIEWS } from "../../../constants/investment_reo_views";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import TrainingTip from "../../ui/training_tip";

/**
 * InvestPropertyTable displays all the investment properties which belong to the
 * client.
 */
export default function InvestPropertyTable({ openView }) {
  const [{ applicant, investment_properties, property: subject_property }] = useDocument();

  /**
   * Event fired when the add property button is pressed
   */
  function addProperty() {
    openView(VIEWS.INVESTMENT_CALCULATOR, investment_properties.length);
  }

  const { borrowers } = applicant;

  const wrapped_investment_properties = wrap_investment_properties(subject_property, investment_properties);

  return (
    <>
      {borrowers.length > 0 && (
        <Section name="Investment Properties">
          <table className="invest-reo-table">
            <thead>
              <tr>
                <th>Year of Purchase</th>
                <th>Reported on 1040 Return</th>
                <th>Property Address</th>
                <th>Gross Rent Payment</th>
                <th>Add. Cash Flow / Year</th>
                <th>NET 75%</th>
                <th>PITI &amp; HOA Mo. Payment</th>
                <th>
                  Net Income or Loss{" "}
                  <TrainingTip content="LANIS Training Tip: This will depend on if the property was listed on the prior year’s 1040 Schedule E tax return. If not the 75% rule applies for Income Calculations." />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {wrapped_investment_properties.map((property, property_index) => {
                return (
                  <InvestmentProperty
                    key={property_index}
                    property_index={property_index}
                    property={property}
                    subject_property={subject_property}
                    openView={openView}
                  />
                );
              })}
            </tbody>
            <tbody>
              <tr>
                <td colSpan="9">
                  <div className="divider" />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td className="right-align">
                  <strong>Totals</strong>
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={gross_rent_payment_total(
                        wrapped_investment_properties
                    ).toString()}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={additional_cash_flow_per_year_total(
                        wrapped_investment_properties
                    ).toString()}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={net_75_total(wrapped_investment_properties).toString()}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={piti_hoa_monthly_payment_total(
                        wrapped_investment_properties
                    ).toString()}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={net_income_or_loss_total(
                        wrapped_investment_properties
                    ).toString()}
                  />
                </td>
                <td />
              </tr>
            </tfoot>
          </table>
          <div>
            <button className="button" onClick={addProperty}>
              Add Investment Property
            </button>
          </div>
        </Section>
      )}
    </>
  );
}
