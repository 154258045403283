import React, { useEffect, useState, useRef } from "react";

import PercentField from "../fields/percent_field";
import NumberField from "../fields/number_field";
import SelectField from "../fields/select_field";

import { default_loan } from "../../schema/loan";

import LOAN_TYPES, {
  REFINANCE_LOAN_TYPES,
  JUMBO
} from "../../constants/loan_types";
import { LOAN_TERMS, ARM_TERMS } from "../../constants/loan_terms";
import LOAN_PURPOSE, {
  REFINANCE_LOAN_PURPOSES
} from "../../constants/loan_purpose";

import {
  ARM_CAPS,
  ARM_MARGINS,
  ARM_INDEX_TYPES
} from "../../constants/loan_arm_properties";
import Section from "../ui/section";
import { useDocument } from "../loan_tools/loan_tool";
import { REFINANCE } from "../../constants/document_types";
import { NO } from "../../constants/yes_no";
import { INCLUDE_IN_DTI } from "../../constants/account_dispositions";

export default function EditLoanPage({ loan, showMainPage, onChangeLoan }) {
  const [
    { applicant, loans, accountSummaries },
    ,
    { documentType },
    { saveDocument }
  ] = useDocument();
  const [loanEdit, setLoanEdit] = useState({ ...default_loan, ...loan });
  const {
    type,
    term,
    arm_caps,
    arm_margin,
    arm_index_type,
    arm_index_percent,
    jumbo_months_of_reserves,
    jumbo_reserves_for_other_properties,
    jumbo_arm_months_of_reserves,
    purpose
  } = loanEdit;

  const isRefinance = documentType === REFINANCE;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loanEdit.is_modified) {
        if (validLoan()) {
          if (
            window.confirm(
              "You have unsaved data in loan form. Would you like to save it?"
            )
          ) {
            save();
          } else {
            // do nothing
          }
        } else {
          alert(
            "Not all necessary fields were entered. Therefore changes would be dismissed."
          );
        }
      }
    };
  }, [loanEdit]);

  useEffect(() => {
    //do nothing

    return () => {
      callback.current();
    };
  }, []);

  function onChange(updatedState) {
    setLoanEdit({ ...loanEdit, ...updatedState, is_modified: true });
  }

  function save() {
    delete loanEdit.is_modified;
    if (!loan && applicant.different_loan_options_for_paying_off_debt === NO) {
      // Dispositions need to be set
      const firstLoan = loans[0];
      if (firstLoan) {
        loanEdit.account_dispositions = { ...firstLoan.account_dispositions };
      } else {
        Object.keys(accountSummaries).forEach(accountSummary => {
          loanEdit.account_dispositions[accountSummary] = INCLUDE_IN_DTI;
        });
      }
    }
    onChangeLoan(loanEdit);
    saveDocument();
    showMainPage();
  }

  function cancel() {
    showMainPage();
  }

  function validateNonEmptyValue(value) {
    return value !== "";
  }

  function validLoan() {
    const validationFields = [loanEdit.type, loanEdit.term, loanEdit.purpose];

    const isARMTerm = ARM_TERMS.includes(loanEdit.term);

    if (isARMTerm) {
      validationFields.push(
        loanEdit.arm_caps,
        loanEdit.arm_margin,
        loanEdit.arm_years,
        loanEdit.arm_index_type,
        loanEdit.arm_index_percent
      );
    }

    if (loanEdit.type === JUMBO) {
      validationFields.push(
        loanEdit.jumbo_months_of_reserves,
        loanEdit.jumbo_reserves_for_other_properties,
        loanEdit.jumbo_arm_months_of_reserves
      );
      if (isARMTerm) {
        validationFields.push(loanEdit.jumbo_arm_months_of_reserves);
      }
    }

    return validationFields.every(validateNonEmptyValue);
  }

  //We want these to show up in descending order.
  const termOptions = [];
  for (let key of Object.keys(LOAN_TERMS)) {
    termOptions.push(
      <option key={key} value={key}>
        {LOAN_TERMS[key]}
      </option>
    );
  }
  termOptions.reverse();

  return (
    <Section className="edit-loan-type" name="Edit Loan Type">
      <div className="half-width">
        <SelectField
          name="Loan Type"
          fieldName="type"
          value={type}
          onChange={onChange}
          selectOptions={isRefinance ? REFINANCE_LOAN_TYPES : LOAN_TYPES}
          sortLabel
        />

        <SelectField
          name="Loan Term"
          fieldName="term"
          value={term}
          onChange={onChange}
        >
          {termOptions}
        </SelectField>

        {ARM_TERMS.includes(term) && (
          <div>
            <SelectField
              name="ARM Caps"
              fieldName="arm_caps"
              value={arm_caps}
              onChange={onChange}
              selectOptions={ARM_CAPS}
            />
            <SelectField
              name="ARM Margin"
              fieldName="arm_margin"
              value={arm_margin}
              onChange={onChange}
              selectOptions={ARM_MARGINS}
            />

            <SelectField
              name="ARM Index Type"
              fieldName="arm_index_type"
              value={arm_index_type}
              onChange={onChange}
              selectOptions={ARM_INDEX_TYPES}
              sortLabel
            />

            <PercentField
              name="ARM Index"
              fieldName="arm_index_percent"
              fixedDigits={5}
              value={arm_index_percent}
              onChange={onChange}
            />
          </div>
        )}

        <SelectField
          name="Purpose"
          fieldName="purpose"
          value={purpose}
          onChange={onChange}
          selectOptions={isRefinance ? REFINANCE_LOAN_PURPOSES : LOAN_PURPOSE}
          showDefaultOption
        />

        {type === JUMBO && (
          <div>
            <NumberField
              name="Mo. Of Reserves"
              fieldName="jumbo_months_of_reserves"
              value={jumbo_months_of_reserves}
              onChange={onChange}
            />
            <NumberField
              name="Reserves for other properties"
              fieldName="jumbo_reserves_for_other_properties"
              value={jumbo_reserves_for_other_properties}
              onChange={onChange}
            />
            {ARM_TERMS.includes(term) && (
              <NumberField
                name="Mo. of Reserves ARM Loans"
                fieldName="jumbo_arm_months_of_reserves"
                value={jumbo_arm_months_of_reserves}
                onChange={onChange}
              />
            )}
          </div>
        )}
      </div>

      <button className="button" onClick={cancel}>
        Cancel
      </button>
      <button className="button" disabled={!validLoan()} onClick={save}>
        Save
      </button>
    </Section>
  );
}
