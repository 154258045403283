import React from "react";

import CurrencyField from "../../fields/currency_field";
import EditFeeList from "./edit_fee_list";

export default function LoanFees({
  loanFees,
  allFees,
  removeFee,
  updateFees,
  readOnly
}) {
  function onFeeChange(fee) {
    const updatedLoanFees = { ...loanFees, ...fee };
    updateFees(updatedLoanFees);
  }

  const fees = Object.entries(loanFees)
    .map(([key, value]) => ({
      type: key,
      amount: value,
      name: allFees[key]
    }))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className="loan-fees">
      {fees.map(fee => (
        <div key={fee.type} className="closing-costs-row">
          <div>
            {!readOnly && (
              <button className="button" onClick={() => removeFee(fee.type)}>
                X
              </button>
            )}
            {fee.name}
          </div>
          <CurrencyField
            displayFixedDigits={0}
            fieldName={fee.type}
            readOnly={readOnly}
            onChange={onFeeChange}
            value={fee.amount}
          />
        </div>
      ))}
      <EditFeeList loanFees={loanFees} allFees={allFees} save={updateFees} />
    </div>
  );
}
