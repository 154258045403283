import React, { useState } from "react";

import InvestmentCalculator from "../../components/loan_tools/investment_reo/investment_calculator";
import InvestmentPropertyTable from "../../components/loan_tools/investment_reo/investment_property_table";
import ScheduleECalculator from "../../components/loan_tools/investment_reo/schedule_e_calculator";
import { VIEWS } from "../../constants/investment_reo_views";

import StandardTemplate from "../../templates/standard_template/standard_template";

import "./css/invest_reo.scss";
import { INVEST_REO } from "../../constants/navigations";
import NoBorrowers from "./no_borrowers";

/**
 * InvestReoPage displays all the investment properties which belong to the
 * client.
 */
export default function InvestReoPage() {
  const [view, setView] = useState(VIEWS.PROPERTY_TABLE);
  const [editPropertyIndex, setEditPropertyIndex] = useState(0);

  /**
   * Callback to be executed when the user wants to open the editor.
   */
  function openView(view, property_index) {
    if (Number.isInteger(property_index)) {
      setEditPropertyIndex(property_index);
    }

    setView(view);
  }

  return (
    <StandardTemplate
      active={INVEST_REO}
      isDisableNavSave={view !== VIEWS.PROPERTY_TABLE}
    >
      <NoBorrowers />

      <div className="invest-reo">
        {view === VIEWS.PROPERTY_TABLE && (
          <InvestmentPropertyTable openView={openView} />
        )}
        {view === VIEWS.INVESTMENT_CALCULATOR && (
          <InvestmentCalculator
            openView={openView}
            property_index={editPropertyIndex}
          />
        )}
        {view === VIEWS.SCHEDULE_E_CALCULATOR && (
          <ScheduleECalculator
            openView={openView}
            property_index={editPropertyIndex}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
