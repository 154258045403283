import React, { useEffect, useState, useRef } from "react";
import Decimal from "decimal.js";

import CurrencyField from "../../fields/currency_field";
import SelectField from "../../fields/select_field";
import TextField from "../../fields/text_field";

import { VIEWS } from "../../../constants/investment_reo_views";
import FREQUENCY from "../../../constants/frequencies";
import REO_PROPERTY_TYPE from "../../../constants/reo_property_type";
import { YES_NO_TBD, YES_NO, YES, NO } from "../../../constants/yes_no";

import {
  total_misc_monthly_fees,
  total_payment,
  wrap_subject_property_if_needed
} from "../../../calculations/investment_reo";
import { default_property } from "../../../schema/investment_property";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import { fake_state_todo_remove } from "../../../constants/fake_state_todo_remove";

/**
 * InvestmentCalculator provides inputs for all the values of an investment
 * property.
 */
export default function InvestmentCalculator({ property_index, openView }) {
  const [document, setDocument, , { saveDocument }] = useDocument();
  const [property, setProperty] = useState({
    ...default_property,
    ...document.investment_properties[property_index]
  });
  const [subject_property] = useState({
    ...fake_state_todo_remove.property,
    ...document.property
  });
  const isSubjectProperty = property.is_subject_property === YES;
  const wrapped_property = wrap_subject_property_if_needed(subject_property, property);

  // const investmentRef = useRef({ is_modified: 0 });
  const callback = useRef();
  /*
    This hook updates callback with current state values
  */
  useEffect(() => {
    callback.current = () => {
      if (property.is_modified) {
        // todo: replace with modal
        if (property.reported_on_1040 !== "") {
          if (
            window.confirm(
              "You have unsaved changes in the Investment Property form. Would you like to save it before you leave?"
            )
          ) {
            save();
          } else {
            //do nothing
          }
        } else {
          alert(
            "Not all necessary fields were entered. Therefore changes would be dismissed."
          );
        }
      }
    };
  }, [property]);

  /*
  This hook catches unsaved changes in the form and prompts a save dialog
 */
  useEffect(() => {
    // do nothing

    // cleanup
    return () => {
      callback.current();
    };
  }, []);

  /**
   * Event fired when the cancel button is clicked
   */
  function close() {
    openView(VIEWS.PROPERTY_TABLE);
  }

  /**
   * Event fired when the next button is clicked
   */
  function saveAndNext() {
    delete property.is_modified;
    document.investment_properties[property_index] = property;

    setDocument(document);

    openView(VIEWS.SCHEDULE_E_CALCULATOR);
  }

  async function onChange(editedProperty) {
    setProperty({
      ...property,
      ...editedProperty,
      is_modified: true
    });
  }

  /**
   * Event fired when save button is clicked
   */
  function save() {
    delete property.is_modified;
    const investment_properties = [...document.investment_properties];
    investment_properties[property_index] = property;
    setDocument({ ...document, investment_properties });
    saveDocument();
    openView(VIEWS.PROPERTY_TABLE);
  }

  const showSaveButton =
    property.reported_on_1040 === NO || property.reported_on_1040 === "";

  return (
    <div className="investment-calculator">
      <Section name={`Rental Property #${property_index + 1}`}>
        <div className="flex">
          <div className="half-width">
            <SelectField
              fieldName="reported_on_1040"
              name="Reported on 1040 Schedule E Return"
              value={property.reported_on_1040}
              onChange={onChange}
              selectOptions={YES_NO}
            />
            <TextField
              fieldName="year_of_purchase"
              name="Year of Purchase"
              value={property.year_of_purchase}
              onChange={onChange}
            />
            <CurrencyField
              fieldName="market_value"
              name="Market Value"
              value={wrapped_property.market_value}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              fieldName="principal_balance"
              name="Principal Balance"
              value={wrapped_property.principal_balance}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <TextField
              fieldName="property_address"
              name="Address"
              value={wrapped_property.property_address}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <TextField
              fieldName="property_address_2"
              name="Address 2"
              value={wrapped_property.property_address_2}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <TextField
              fieldName="property_city"
              name="City"
              value={wrapped_property.property_city}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <TextField
              fieldName="property_state"
              name="State"
              value={wrapped_property.property_state}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <TextField
              fieldName="property_zip"
              name="Zip"
              value={wrapped_property.property_zip}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              fieldName="first_mortgage"
              name="1st Mortgage"
              value={wrapped_property.first_mortgage}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              fieldName="second_mortgage"
              name="2nd Mortgage"
              value={wrapped_property.second_mortgage}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              fieldName="taxes"
              name="Taxes Included"
              value={wrapped_property.taxes}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              fieldName="insurance"
              name="Insurance (HO6 if Condominium)"
              value={wrapped_property.insurance}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            <CurrencyField
              readOnly
              fieldName="total_payment"
              name="Total of PITI, (HOA - MI)"
              value={total_payment(wrapped_property).toString()}
            />
            <CurrencyField
              fieldName="gross_rent_payment"
              name="Rent Received"
              value={property.gross_rent_payment}
              onChange={onChange}
            />
            <SelectField
                fieldName="reo_property_type"
                name="Property Type"
                value={wrapped_property.reo_property_type}
                onChange={onChange}
                selectOptions={REO_PROPERTY_TYPE}
            />
            <CurrencyField
              fieldName="hoa1"
              name="HOA 1"
              value={wrapped_property.hoa1}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            {new Decimal(wrapped_property.hoa1).greaterThan(0) && (
              <SelectField
                fieldName="hoa1_frequency"
                name="HOA 1 Frequency"
                value={wrapped_property.hoa1_frequency}
                onChange={onChange}
                selectOptions={FREQUENCY}
                readOnly={isSubjectProperty}
              />
            )}
            <CurrencyField
              fieldName="hoa2"
              name="HOA 2"
              value={wrapped_property.hoa2}
              onChange={onChange}
              readOnly={isSubjectProperty}
            />
            {new Decimal(wrapped_property.hoa2).greaterThan(0) && (
              <SelectField
                fieldName="hoa2_frequency"
                name="HOA 2 Frequency"
                value={wrapped_property.hoa2_frequency}
                onChange={onChange}
                selectOptions={FREQUENCY}
                readOnly={isSubjectProperty}
              />
            )}
            <SelectField
              fieldName="mello_roos"
              name="Mello Roos"
              value={wrapped_property.mello_roos}
              onChange={onChange}
              selectOptions={YES_NO_TBD}
              readOnly={isSubjectProperty}
            />
            {wrapped_property.mello_roos === YES && (
              <CurrencyField
                fieldName="mello_roos_amount"
                name="Amount"
                value={wrapped_property.mello_roos_amount}
                onChange={onChange}
                readOnly={isSubjectProperty}
              />
            )}
            {wrapped_property.mello_roos === YES && (
              <SelectField
                fieldName="mello_roos_frequency"
                name="Frequency"
                value={wrapped_property.mello_roos_frequency}
                onChange={onChange}
                selectOptions={FREQUENCY}
                readOnly={isSubjectProperty}
              />
            )}
            <CurrencyField
                readOnly
                fieldName="total_misc_monthly_fees"
                name="Total of Misc Monthly Fees"
                value={total_misc_monthly_fees(wrapped_property).toString()}
            />
          </div>
        </div>
        <div>
          <button className="button" onClick={close}>
            Cancel
          </button>
          {property.reported_on_1040 === YES && (
            <button className="button" onClick={saveAndNext}>
              Next: Schedule E Calculator
            </button>
          )}
          {showSaveButton && (
            <button
              className="button"
              disabled={property.reported_on_1040 === ""}
              onClick={save}
            >
              Save
            </button>
          )}
        </div>
      </Section>
    </div>
  );
}
