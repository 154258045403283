import React from "react";

import {
  arm_first_payment_adjust,
  arm_first_payment_amount,
  arm_first_payment_rate,
  arm_max_payment_amount,
  arm_max_payment_rate,
  arm_second_payment_adjust,
  arm_second_payment_amount,
  arm_second_payment_rate,
  arm_third_payment_adjust
} from "../../../calculations/loan";

import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import PercentField from "../../fields/percent_field";
import { useDocument } from "../loan_tool";

export default function PaymentAdjustmentTable({ loan, isEditLoan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const { arm_caps } = loan;
  const rate = loan.rate === "" ? "0" : loan.rate;

  return (
    <div className={isEditLoan && "third-width"}>
      <div className="le-price-engine-title">Payment Adjustment Table</div>
      <NumberField
        name="1st Payment Adjust"
        readOnly
        value={arm_first_payment_adjust(loan)}
      />
      <PercentField
        name="1st Payment Rate"
        readOnly
        value={arm_first_payment_rate(rate, arm_caps)}
      />
      <CurrencyField
        name="1st Payment amount"
        readOnly
        value={arm_first_payment_amount(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <NumberField
        name="2nd Payment Adjust"
        readOnly
        value={arm_second_payment_adjust(loan)}
      />
      <PercentField
        name="2nd Payment Rate"
        readOnly
        value={arm_second_payment_rate(rate, arm_caps)}
      />
      <CurrencyField
        name="2nd Payment amount"
        readOnly
        value={arm_second_payment_amount(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <NumberField
        name="3rd Payment Adjust"
        readOnly
        value={arm_third_payment_adjust(loan)}
      />
      <PercentField
        name="Max ARM Interest rate"
        readOnly
        value={arm_max_payment_rate(rate, arm_caps)}
      />
      <CurrencyField
        name="Max ARM Payment"
        readOnly
        value={arm_max_payment_amount(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
    </div>
  );
}
