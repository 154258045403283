import React, { useState, useEffect } from "react";
import "./css/account_profile.scss";

import StandardTemplate from "../templates/standard_template/standard_template";
import Section from "../components/ui/section";
import TextField from "../components/fields/text_field";
import { useUser } from "../hooks/user";
import { default_account_profile } from "../schema/profile";
import CompanyProfile from "../components/profile/company_profile";
import Image from "../components/ui/image";
import { get_role_names } from "../constants/utils";
import UserProfile from "../components/profile/user_profile";

export default function AccountProfile() {
  const [
    { accountProfile, companyProfile },
    { saveAccountProfile, getProfile }
  ] = useUser();
  const [profile, setProfile] = useState({ ...default_account_profile });

  async function onSaveProfile() {
    saveAccountProfile({ ...profile });
  }

  function onProfileChange(updatedProfile) {
    setProfile({
      ...profile,
      ...updatedProfile
    });
  }

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setProfile(accountProfile);
  }, [accountProfile]);

  return (
    <StandardTemplate basic showMoreLinks className="account-profile">
      <div className="top-header-section">
        <div className="top-header-section-title">MY ACCOUNT</div>
      </div>
      <Section name="Loan Officer Information" className="loan-officer-info">
        <div className="flex">
          <div className="half-width">
            <UserProfile
              profile={profile}
              onProfileChange={onProfileChange}
              hidePassword={true}
              emailReadOnly={true}
            />
            <div className="flex-space-between">
              <div>User Role</div>
              <div className="user-role">
                {get_role_names(accountProfile).map((roleName, index) => (
                  <div key={index}>
                    <TextField readOnly value={roleName} />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="half-width">
            <CompanyProfile companyProfile={companyProfile} readOnly />
            <div className="flex-space-between">
              <div>Company Logo</div>
              <div className="company-logo">
                <Image url="company/company-logo" />
              </div>
            </div>
          </div>
        </div>
        <button className="button" onClick={onSaveProfile}>
          Save
        </button>
      </Section>
    </StandardTemplate>
  );
}
