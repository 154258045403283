import { default_company_profile } from "../profile";

export const SUBSCRIPTION_NORMAL = 0;
export const SUBSCRIPTION_ALWAYS_ACTIVE = 1;
export const SUBSCRIPTION_ALWAYS_UNSUBSCRIBED = 2;

export const SUBSCRIPTION_STATUSES = Object.freeze({
  [SUBSCRIPTION_NORMAL]: "Normal",
  [SUBSCRIPTION_ALWAYS_ACTIVE]: "Always Active",
  [SUBSCRIPTION_ALWAYS_UNSUBSCRIBED]: "Always Unsubscribed"
});

export const default_company_details = {
  company_profile: { ...default_company_profile },
  subscription_status: "",
  stripe_customer_id: "",
  stripe_subscription_id: "",
  subscription_email: "",
  subscription_name: "",
  subscription_phone: "",
  current_seat_amount: 0
};
