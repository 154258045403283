import React from "react";
import Decimal from "decimal.js";

import {
  PERCENT_CREDITED_FHA,
  PERCENT_CREDITED_AGENCIES
} from "../../../constants/reserves_percentage_credited";
import {
  RESERVE_TYPES,
  RESERVE_SUBTYPES
} from "../../../constants/reserves_account_types";
import { liquid_funds } from "../../../calculations/reserve";

import TextField from "../../fields/text_field";
import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";
import Section from "../../ui/section";

export default function BorrowerReserveEntry({
  borrowerIndex,
  borrower,
  showEditReserve,
  deleteReserve
}) {
  const { reserves } = borrower;
  const borrowerName = `${borrower.first_name} ${borrower.middle_name} ${
    borrower.last_name
  }`;

  function addReserve() {
    showEditReserve(borrowerIndex, borrower.reserves.length);
  }

  function editReserve(e) {
    const index = new Decimal(e.target.dataset.index).toNumber();
    showEditReserve(borrowerIndex, index);
  }

  function _deleteReserve(e) {
    const index = new Decimal(e.target.dataset.index).toNumber();
    deleteReserve(borrowerIndex, index);
  }

  return (
    <Section
      className="reserves-borrower-entry"
      name={`Reserves for Borrower ${borrowerIndex + 1} (${borrowerName})`}
    >
      {reserves.length > 0 && (
        <table>
          <thead>
            <tr>
              <th />
              <th />
              <th />
              <th />
              <th>FannieMae / FreddieMac / Jumbo Loans</th>
              <th />
              <th>FHA Loans</th>
              <th />
              <th />
              <th />
            </tr>
            <tr>
              <th>Account Type</th>
              <th>Account Subtype</th>
              <th>Account Number</th>
              <th>Total Funds</th>
              <th>Percent credited</th>
              <th>Credited funds</th>
              <th>Percent credited</th>
              <th>Credited funds</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {reserves.map((reserve, index) => (
              <tr key={index}>
                <td>
                  <TextField readOnly value={RESERVE_TYPES[reserve.type]} />
                </td>
                <td>
                  <TextField
                    readOnly
                    value={RESERVE_SUBTYPES[reserve.sub_type]}
                  />
                </td>
                <td>
                  <TextField readOnly value={reserve.account_number} />
                </td>
                <td>
                  <CurrencyField readOnly value={reserve.funds} />
                </td>
                <td>
                  <PercentField
                    readOnly
                    decimalPlaces={0}
                    value={PERCENT_CREDITED_AGENCIES[reserve.sub_type]}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={liquid_funds(
                      reserve.funds,
                      PERCENT_CREDITED_AGENCIES[reserve.sub_type]
                    )}
                  />
                </td>
                <td>
                  <PercentField
                    readOnly
                    decimalPlaces={0}
                    value={PERCENT_CREDITED_FHA[reserve.sub_type]}
                  />
                </td>
                <td>
                  <CurrencyField
                    readOnly
                    value={liquid_funds(
                      reserve.funds,
                      PERCENT_CREDITED_FHA[reserve.sub_type]
                    )}
                  />
                </td>
                <td>
                  <button
                    className="button"
                    data-index={index}
                    onClick={editReserve}
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    className="button"
                    data-index={index}
                    onClick={_deleteReserve}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <button className="button" onClick={addReserve}>
        Add Reserve
      </button>
    </Section>
  );
}
