export const PERIOD_7 = "7";
export const PERIOD_14 = "4";
export const PERIOD_15 = "15";
export const PERIOD_21 = "21";
export const PERIOD_30 = "30";
export const PERIOD_45 = "45";
export const PERIOD_60 = "60";
export const PERIOD_90 = "90";

export default Object.freeze({
  [PERIOD_7]: "7",
  [PERIOD_14]: "14",
  [PERIOD_15]: "15",
  [PERIOD_21]: "21",
  [PERIOD_30]: "30",
  [PERIOD_45]: "45",
  [PERIOD_60]: "60",
  [PERIOD_90]: "90"
});
