export function format_currency(value, customConfig) {
  return parseFloat((value || "0").replace(/[^\d.-]/g, "")).toLocaleString(
    "en-US",
    Object.assign(
      {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
        minimumFractionDigits: 2
      },
      customConfig || {}
    )
  );
}
