let currentId = 0;

/**
 * Id generator used when inserting new items to state, in order to ensure
 * uniqueness and make it easier to keep track of relationships between
 * objects.
 */
export default function generate_id(prefix = "id", skipDate = false, id = currentId++) {
  if (skipDate) {
    return `${prefix}-${id}`;
  }
  return `${prefix}-${id}-${Date.now()}`;
}
