import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  actual_cash_down_payment,
  adjustments_and_other_credits,
  deposit,
  estimated_cash_to_close,
  estimated_closing_costs_financed,
  loan_credits,
  seller_concessions,
  seller_credits,
  total_closing_costs,
  total_cost_to_purchase
} from "../../../calculations/loan";
import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import { YES } from "../../../constants/yes_no";
import { useDocument } from "../loan_tool";
import TrainingTip from "../../ui/training_tip";
import { is_fha, is_usda, is_va } from "../../../constants/utils";

export default function CalculatingCashToClose({ loan, updateLoan }) {
  const [
    {
      property_to_be_sold,
      is_selling_property,
      property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();
  const hasSecondLoan = SECOND_LOAN_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );

  return (
    <div className="calculating-cash-to-close">
      <div className="closing-costs-row bottom-border">
        <div className="main-title">Calculating Cash to Close</div>
      </div>
      <div className="closing-costs-row">
        <div>Total Closing Costs (J)</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={total_closing_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div>Closing Costs Financed (Included in Loan Amount)</div>
        <div>
          <CurrencyField
            className="closing-costs-cell right-align"
            naked
            fixedDigits={0}
            value={estimated_closing_costs_financed(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
          <div className="closing-costs-cell" />
        </div>
      </div>
      <div className="closing-costs-row">
        <div>Down Payment/Funds from Borrower</div>
        {hasSecondLoan && <div>(Includes 2nd Loan Amt.)</div>}
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={actual_cash_down_payment(documentType, loan, property)}
        />
      </div>
      <div className="closing-costs-row">
        <div>Deposit</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={deposit(documentType, loan, property)}
        />
      </div>
      <div className="closing-costs-row">
        <div>Funds for Borrower</div>
        <CurrencyField
          className="closing-costs-cell"
          displayFixedDigits={0}
          value={loan.funds_for_borrower}
          fieldName="funds_for_borrower"
          onChange={updateLoan}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell double-width">
          Seller Credits
        </div>
        <div className="closing-costs-cell center-align">RE Credits:</div>
        <CurrencyField
          className="closing-costs-cell center-align gray-bg"
          naked
          fixedDigits={0}
          value={seller_concessions(documentType, loan, property)}
        />
        <div className="closing-costs-cell center-align">Loan Credits:</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={loan_credits(documentType, loan, property)}
        />
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={seller_credits(documentType, loan, property)}
        />
      </div>
      <div className="closing-costs-row">
        <div>
          Adjustment and Other Credits{" "}
          <TrainingTip content="LANIS Training Tip:  In this section you will find credits and the most confusing can be if there is a Second Mortgage included in the Transaction.  This amount is deducted from the Estimated Cash to Close." />
        </div>
        {hasSecondLoan && <div>(Credit for 2nd Loan Amt.)</div>}
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={adjustments_and_other_credits(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row top-border bottom-border overflow-hidden">
        <div className="closing-costs-cell-shell double-width bold">
          Total Cost to Purchase
        </div>
        <CurrencyField
          className="closing-costs-cell right-border right-align gray-bg"
          naked
          fixedDigits={0}
          value={total_cost_to_purchase(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
        <div className="closing-costs-cell" />
        <div className="closing-costs-cell-shell double-width bold">
          Estimated Cash to Close
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={estimated_cash_to_close(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property,
            accountSummaries,
            residences
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Estimated Closing Costs Financed{" "}
          <TrainingTip content="LANIS Training Tip: It's important to disclose to the Borrower(s) when the UPMIP or Funding Fee has been added to the Loan Amount. Borrower(s) will need to understand their Loan Amount has been increased." />
        </div>
        <div className="closing-costs-cell center-align green-text">
          {is_va(loan.type) &&
            estimated_closing_costs_financed(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell center-align green-text">
          {loan.is_ufmip_financed === YES &&
            is_fha(loan.type) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell center-align green-text">
          {loan.is_ufmip_financed === YES &&
            is_usda(loan.type) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell" />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          (Paid from your Loan Amount)
        </div>
        <div className="closing-costs-cell center-align">
          {is_va(loan.type) &&
            estimated_closing_costs_financed(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        <div className="closing-costs-cell center-align">
          {loan.is_ufmip_financed === YES && is_fha(loan.type) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        <div className="closing-costs-cell center-align">
          {loan.is_ufmip_financed === YES && is_usda(loan.type) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        <div className="closing-costs-cell" />
      </div>
    </div>
  );
}
