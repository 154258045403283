import React from "react";
import "./css/loan_estimate.css";

import LOAN_TYPES, {
  LOAN_TYPE_CATEGORY_NAMES,
  REFINANCE_LOAN_TYPES
} from "../../../constants/loan_types";
import OCCUPANCY from "../../../constants/occupancy";
import LOAN_PURPOSE from "../../../constants/loan_purpose";
import DateField, { today } from "../../fields/date_field";
import {
  borrower_names,
  loan_arm_fix,
  loan_term_name
} from "../../../constants/utils";
import CurrencyField from "../../fields/currency_field";
import {
  loan_term_in_years,
  offer_price_or_market_value
} from "../../../calculations/loan";
import NumberField from "../../fields/number_field";
import { YES, YES_NO_TBD } from "../../../constants/yes_no";
import TimeField from "../../fields/time_field";
import TIME_ZONES from "../../../constants/time_zones";
import { useDocument } from "../../loan_tools/loan_tool";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import PROPERTY_CLASSIFICATION from "../../../constants/property_classification";

export default function LoanEstimate({ loan, loanNumber }) {
  const [{ applicant, property }, , { documentType }] = useDocument();

  const isRefinance = documentType === REFINANCE;
  const firstBorrower = applicant.borrowers[0] || {};
  const borrowerNames = borrower_names(applicant.borrowers);
  const isInterestOnly = loan.payment_type === INTEREST_ONLY;
  const isPurchase = documentType === PURCHASE;

  return (
    <div className="loan-estimate no-bottom-border with-border">
      <div className="left-right-container">
        <div className="left"> </div>
        <div className="right">
          <div className="loan-label bigger-font bold">Loan {loanNumber}</div>
          This is Not a Formal Disclosure as required by the CFPB. One will be
          issued based on your choice of the loan amount on the Summary Sheet.
          Save this Financial Analysis to better understand your options.{" "}
          <span className="italic">(Loan Options 1 - 5 are your Choice).</span>
        </div>
      </div>
      <div className="left-right-container top-border">
        <div className="left">
          <div className="flex-space-between">
            <div className="biggest-font">Loan Estimate</div>
            <div>
              {isRefinance
                ? REFINANCE_LOAN_TYPES[loan.type]
                : LOAN_TYPES[loan.type]}
            </div>
            <div>
              {isRefinance
                ? PROPERTY_CLASSIFICATION[property.classification]
                : OCCUPANCY[property.occupancy]}
            </div>
          </div>
          <table className="general-info">
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="bold">FIRST ISSUE</td>
                <td>
                  <DateField naked value={applicant.first_contact_date} />
                </td>
                <td />
              </tr>
              <tr>
                <td className="bold">LAST DATE ISSUED</td>
                <td>
                  <DateField naked value={today()} />
                </td>
                <td />
              </tr>
              <tr>
                <td className="bold">APPLICANTS</td>
                <td>{borrowerNames[0]}</td>
                <td>{borrowerNames[2]}</td>
              </tr>
              <tr>
                <td />
                <td>{borrowerNames[1]}</td>
                <td>{borrowerNames[3]}</td>
              </tr>
              <tr>
                <td />
                <td colSpan="2">{firstBorrower.current_address}</td>
              </tr>
              <tr>
                <td />
                <td>{firstBorrower.city}</td>
                <td>
                  <div className="flex-start-with-front-spacing">
                    <div>{firstBorrower.state}</div>
                    <div>{firstBorrower.zip_code}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="bold">SUBJECT PROPERTY</td>
                <td colSpan="2">{property.address}</td>
              </tr>
              <tr>
                <td />
                <td>{property.city}</td>
                <td>
                  <div className="flex-start-with-front-spacing">
                    <div>{property.state}</div>
                    <div>{property.zip}</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  className="center-align middle-align normal-font bold"
                  rowSpan="2"
                >
                  {isPurchase ? (
                    "SALE PRICE"
                  ) : (
                    <>
                      <div>REFINANCE</div>
                      <div>(Market Value)</div>
                    </>
                  )}
                </td>
                <td
                  className="center-align middle-align normal-font"
                  rowSpan="2"
                >
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={offer_price_or_market_value(
                      documentType,
                      loan,
                      property
                    )}
                  />
                </td>
                <td />
              </tr>
              <tr>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="right">
          <table className="loan-info">
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <tbody>
              <tr>
                <td className="bold" colSpan="2">
                  LOAN TERM
                </td>
                <td className="center-align">
                  <NumberField naked value={loan_term_in_years(loan)} />
                </td>
                <td className="center-align">Years</td>
                <td colSpan="3" />
              </tr>
              <tr>
                <td className="bold" colSpan="2">
                  PURPOSE
                </td>
                <td className="center-align">{LOAN_PURPOSE[loan.purpose]}</td>
                <td colSpan="4" />
              </tr>
              <tr>
                <td className="bold" colSpan="2">
                  PRODUCT
                </td>
                <td className="center-align">{loan_term_name(loan.term)}</td>
                <td className="center-align">{loan_arm_fix(loan.term)}</td>
                <td colSpan="3">
                  {isInterestOnly && "Interest Only Period Applies"}
                </td>
              </tr>
              <tr>
                <td className="bold" colSpan="2">
                  LOAN TYPE
                </td>
                <td className="center-align">
                  {LOAN_TYPE_CATEGORY_NAMES[loan.type]}
                </td>
                <td colSpan="4" />
              </tr>
              <tr>
                <td className="bold" colSpan="2">
                  LOAN ID #
                </td>
                <td colSpan="5" />
              </tr>
              <tr>
                <td className="bold">RATE LOCK</td>
                <td className="center-align">
                  {YES_NO_TBD[applicant.loan_locked]}
                </td>
                <td className="center-align">
                  {applicant.loan_locked === YES && (
                    <DateField naked value={applicant.loan_lock_date} />
                  )}
                </td>
                <td className="center-align">
                  {applicant.loan_locked === YES && "Until"}
                </td>
                <td className="center-align">
                  {applicant.loan_locked === YES && (
                    <TimeField naked value={applicant.loan_lock_time} />
                  )}
                </td>
                <td className="center-align">
                  {applicant.loan_locked === YES &&
                    TIME_ZONES[applicant.loan_lock_time_zone]}
                </td>
                <td />
              </tr>
              <tr>
                <td className="italic" colSpan="7">
                  Before closing, your interest rate, points, and lender credits
                  can
                </td>
              </tr>
              <tr>
                <td className="italic" colSpan="7">
                  change unless you lock the interest rate. All other estimated
                </td>
              </tr>
              <tr>
                <td className="italic" colSpan="2">
                  closing costs expire on
                </td>
                <td className="center-align">
                  <DateField naked value={applicant.closing_costs_expire} />
                </td>
                <td className="center-align">at</td>
                <td className="center-align">
                  <TimeField
                    naked
                    value={applicant.closing_costs_expire_time}
                  />
                </td>
                <td className="center-align">
                  {TIME_ZONES[applicant.closing_costs_expire_time_zone]}
                </td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
