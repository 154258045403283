import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  loan_origination_fee,
  mortgage_broker_fee,
  percent_of_loan_amount,
  total_loan_costs,
  total_origination_charges,
  total_services_you_can_shop_for,
  total_services_you_cannot_shop_for,
  upfront_funding_fee,
  upfront_mortgage_insurance_fee_fha,
  upfront_mortgage_insurance_fee_usda
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import { LENDER_PAID_FEE } from "../../../constants/origination_fee_paid_by";
import ORIGINATION_FEES from "../../../constants/origination_fees";
import SERVICES_YOU_CANNOT_SHOP_FOR from "../../../constants/services_you_cannot_shop_for";
import SERVICES_YOU_CAN_SHOP_FOR from "../../../constants/services_you_can_shop_for";
import {
  is_fha,
  is_government_fee_financed_into_loan,
  is_usda,
  is_va
} from "../../../constants/utils";
import { useDocument } from "../../loan_tools/loan_tool";
import { YES } from "../../../constants/yes_no";
import TrainingTip from "../../ui/training_tip";

export default function LoanCosts({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isLenderPaid = loan.origination_fee_paid_by === LENDER_PAID_FEE;

  return (
    <>
      <table className="left-table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td className="summary-header">Loan Costs</td>
            <td colSpan="3" />
          </tr>
        </tbody>
      </table>
      <div className="loan-costs-content flex-column-space-between">
        <table className="left-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="gray-bg bold" colSpan="2">
                A. Origination Charges{" "}
                <TrainingTip content="LANIS Training Note: Tolerance Level 0%" />
              </td>
              <td className="right-align gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_origination_charges(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td />
            </tr>
            {percent_of_loan_amount(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) && (
              <tr>
                <td colSpan="2">
                  <div className="indent">
                    <PercentField
                      naked
                      fixedDigits={3}
                      value={percent_of_loan_amount(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />{" "}
                    of Loan Amount (Points)
                  </div>
                </td>
                <td colSpan="2" />
              </tr>
            )}
            {loan_origination_fee(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) && (
              <tr>
                <td colSpan="2">Loan Origination Fee</td>
                <td className="right-align">
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={loan_origination_fee(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </td>
                <td />
              </tr>
            )}
            <tr>
              {isLenderPaid && loan.display_fee === YES && (
                <>
                  <td>
                    <div className="flex-space-between">
                      <div>Mortgage Broker Fee</div>
                      <div>Lender Pd.</div>
                    </div>
                  </td>
                  <td className="right-align">
                    <CurrencyField
                      naked
                      fixedDigits={0}
                      value={mortgage_broker_fee(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </td>
                  <td />
                </>
              )}
              {!isLenderPaid && (
                <>
                  <td>Mortgage Broker Fee</td>
                  <td />
                  <td className="right-align">
                    <CurrencyField
                      naked
                      fixedDigits={0}
                      value={mortgage_broker_fee(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </td>
                </>
              )}
              <td />
            </tr>
            {Object.entries(loan.origination_fees)
              .sort((a, b) =>
                ORIGINATION_FEES[a[0]].localeCompare(ORIGINATION_FEES[b[0]])
              )
              .map(([name, value]) => (
                <tr key={name}>
                  <td colSpan="2">{ORIGINATION_FEES[name]}</td>
                  <td className="right-align">
                    <CurrencyField naked fixedDigits={0} value={value} />
                  </td>
                  <td />
                </tr>
              ))}
            <tr>
              <td colSpan="4" />
            </tr>
          </tbody>
        </table>
        <table className="left-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="2">
                B. Services You Cannot Shop For{" "}
                <TrainingTip content="LANIS Training Note: Tolerance 0%" />
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_services_you_cannot_shop_for(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td />
            </tr>
            <UpfrontFee
              loan={loan}
              documentType={documentType}
              property={property}
              property_to_be_sold={property_to_be_sold}
              is_selling_property={is_selling_property}
            />
            {is_government_fee_financed_into_loan(loan) && (
              <tr>
                <td colSpan="2">(Government Fee is Financed into the loan)</td>
                <td colSpan="2" />
              </tr>
            )}
            {Object.entries(loan.services_you_cannot_shop_for)
              .sort((a, b) =>
                SERVICES_YOU_CANNOT_SHOP_FOR[a[0]].localeCompare(
                  SERVICES_YOU_CANNOT_SHOP_FOR[b[0]]
                )
              )
              .map(([name, value]) => (
                <tr key={name}>
                  <td colSpan="2">{SERVICES_YOU_CANNOT_SHOP_FOR[name]}</td>
                  <td className="right-align">
                    <CurrencyField naked fixedDigits={0} value={value} />
                  </td>
                  <td />
                </tr>
              ))}
            <tr>
              <td colSpan="4" />
            </tr>
          </tbody>
        </table>
        <table className="left-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="2">
                C. Services You Can Shop For{" "}
                <TrainingTip content="LANIS Training Note:  10% if from SSPL N/A if Borrower selected." />
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_services_you_can_shop_for(loan)}
                />
              </td>
              <td />
            </tr>
            {Object.entries(loan.services_you_can_shop_for)
              .sort((a, b) =>
                SERVICES_YOU_CAN_SHOP_FOR[a[0]].localeCompare(
                  SERVICES_YOU_CAN_SHOP_FOR[b[0]]
                )
              )
              .map(([name, value]) => (
                <tr key={name}>
                  <td colSpan="2">{SERVICES_YOU_CAN_SHOP_FOR[name]}</td>
                  <td className="right-align">
                    <CurrencyField naked fixedDigits={0} value={value} />
                  </td>
                  <td />
                </tr>
              ))}
            <tr>
              <td colSpan="4" />
            </tr>
          </tbody>
        </table>
        <table className="left-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="2">
                D. Total loan costs (A + B + C)
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_loan_costs(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

function UpfrontFee({
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
}) {
  if (is_fha(loan.type)) {
    return (
      <tr>
        <td colSpan="2">Upfront Mortgage Insurance Fee / FHA</td>
        <td className="right-align">
          <CurrencyField
            naked
            fixedDigits={0}
            value={upfront_mortgage_insurance_fee_fha(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td />
      </tr>
    );
  }
  if (is_usda(loan.type)) {
    return (
      <tr>
        <td colSpan="2">Upfront Mortgage Insurance Fee / USDA</td>
        <td className="right-align">
          <CurrencyField
            naked
            fixedDigits={0}
            value={upfront_mortgage_insurance_fee_usda(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </td>
        <td />
      </tr>
    );
  }
  if (is_va(loan.type)) {
    return (
      <tr>
        <td colSpan="2">Upfront Funding Fee / VA</td>
        <td className="right-align">
          <CurrencyField
            naked
            fixedDigits={0}
            value={upfront_funding_fee(documentType, loan, property)}
          />
        </td>
        <td />
      </tr>
    );
  }
  return <></>;
}
