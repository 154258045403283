import config from "../config/config.js";
import { getHeaders, handleResponse } from "./utils";

const DocumentService = {
  async getDocument(id) {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + "document/" + id, {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async saveDocument(document, metadata, id, name) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("name", name);
    data.append("document", JSON.stringify(document));
    data.append("metadata", JSON.stringify(metadata));
    const response = await fetch(config.serviceHost + `document/${id}`, {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async getPipelineData() {
    const headers = getHeaders();
    const response = await fetch(
      config.serviceHost + "document/pipeline-data",
      {
        method: "GET",
        headers
      }
    );
    return handleResponse(response);
  },

  async createDocument(type, document, metadata, name) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("type", type);
    data.append("name", name);
    data.append("document", JSON.stringify(document));
    data.append("metadata", JSON.stringify(metadata));
    const response = await fetch(config.serviceHost + `document/new`, {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async savePipelineData(pipelineData, id) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("pipeline-data", JSON.stringify(pipelineData));
    const response = await fetch(
      config.serviceHost + `document/${id}/pipeline-data`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  }
};

export default DocumentService;
