import React from "react";
import "./css/projected_payments.css";

import { ARM_TERMS } from "../../../constants/loan_terms";
import PercentField from "../../fields/percent_field";
import {
  arm_first_payment_amount,
  arm_first_payment_rate,
  arm_max_payment_amount,
  arm_max_payment_rate,
  arm_second_payment_adjust,
  arm_second_payment_amount,
  arm_second_payment_rate,
  arm_third_payment_adjust,
  estimated_cash_to_close,
  first_mortgage_payment_interest_only,
  first_mortgage_payment_p_and_i,
  initial_escrow_payment_at_closing_homeowners_insurance,
  initial_escrow_payment_at_closing_mortgage_insurance,
  initial_escrow_payment_at_closing_property_taxes,
  initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly,
  loan_term_in_years,
  mortgage_insurance_premium_monthly,
  total_closing_costs,
  total_loan_costs,
  total_other_costs
} from "../../../calculations/loan";
import NumberField from "../../fields/number_field";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import CurrencyField from "../../fields/currency_field";
import { revised_dti } from "../../../calculations/debt_info";
import {
  home_owners_insurance_monthly,
  mello_roos,
  total_hoas_monthly
} from "../../../calculations/property";
import {
  arm_first_dti_per_loan_adjustment_piti,
  arm_first_estimated_total_monthly_payment,
  arm_first_total_monthly_payment_includes_all,
  arm_max_dti_per_loan_adjustment_piti,
  arm_max_estimated_total_monthly_payment,
  arm_max_total_monthly_payment_includes_all,
  arm_second_dti_per_loan_adjustment_piti,
  arm_second_estimated_total_monthly_payment,
  arm_second_total_monthly_payment_includes_all,
  estimated_escrow,
  estimated_io_monthly_payment,
  estimated_taxes_insurance_assessments_other_monthly,
  estimated_total_monthly_payment,
  total_io_monthly_payment,
  total_monthly_payment_includes_all
} from "../../../calculations/trid";
import { useDocument } from "../../loan_tools/loan_tool";
import { month_to_year } from "../../../calculations/utils";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import { wrap_investment_properties } from "../../../calculations/investment_reo";

export default function ProjectedPayment({ loan }) {
  const [
    {
      applicant,
      investment_properties,
      accountSummaries,
      property,
      residences,
      property_to_be_sold,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const borrowers = applicant.borrowers;
  const isArm = ARM_TERMS.includes(loan.term);
  const isInterestOnly = loan.payment_type === INTEREST_ONLY;
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties, false);

  return (
    <div className="projected-payments">
      <table className="info-table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody className="no-top-border with-border with-td-border">
          <tr>
            <td className="no-border summary-header">Projected Payments</td>
            <td className="no-border" />
            <td className="center-align no-border">
              {isInterestOnly && "Years I/O"}
            </td>
            <td className="no-border" colSpan="2">
              {isArm && (
                <div className="flex-start-with-spacing">
                  <div>Rate Maximum</div>
                  <div>
                    <PercentField
                      naked
                      fixedDigits={3}
                      value={arm_first_payment_rate(loan.rate, loan.arm_caps)}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="no-border" colSpan="2">
              {isArm && (
                <div className="flex-start-with-spacing">
                  <div>Rate Maximum</div>
                  <div>
                    <PercentField
                      naked
                      fixedDigits={3}
                      value={arm_second_payment_rate(loan.rate, loan.arm_caps)}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="no-border" colSpan="2">
              {isArm && (
                <div className="flex-start-with-spacing">
                  <div>Rate Maximum</div>
                  <div>
                    <PercentField
                      naked
                      fixedDigits={3}
                      value={arm_max_payment_rate(loan.rate, loan.arm_caps)}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="bold">Payment Calculation</td>
            <td className="no-right-border">
              1 - <NumberField naked value={loan_term_in_years(loan)} /> Years
            </td>
            <td className="center-align no-left-border">
              {isInterestOnly && (
                <NumberField
                  naked
                  value={month_to_year(loan.interest_only_period)}
                />
              )}
            </td>
            <td colSpan="2">
              {isArm && (
                <>
                  <NumberField
                    naked
                    value={arm_second_payment_adjust(loan).div(12)}
                  />
                  <span>th Year</span>
                </>
              )}
            </td>
            <td colSpan="2">
              {isArm && (
                <>
                  <NumberField
                    naked
                    value={arm_third_payment_adjust(loan).div(12)}
                  />
                  <span>th Year</span>
                </>
              )}
            </td>
            <td colSpan="2">
              {isArm && (
                <>
                  <NumberField
                    naked
                    value={arm_third_payment_adjust(loan)
                      .div(12)
                      .add(1)}
                  />
                  <span> - 30 Years</span>
                </>
              )}
            </td>
          </tr>
          <tr>
            <td className="no-bottom-border bold" rowSpan="2">
              Principal &amp; Interest
            </td>
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={2}
                value={first_mortgage_payment_p_and_i(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align right-border no-border">
              {isInterestOnly &&
                first_mortgage_payment_interest_only(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                ).gt(0) && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={first_mortgage_payment_interest_only(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={first_mortgage_payment_p_and_i(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_first_payment_amount(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={first_mortgage_payment_p_and_i(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_second_payment_amount(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={first_mortgage_payment_p_and_i(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_max_payment_amount(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="center-align no-border">
              {first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              ).gt(0) && "Prin. & Int."}
            </td>
            <td className="center-align right-border no-border">
              {isInterestOnly && "Only Interest"}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>Minimum</div>
                  <div>Maximum</div>
                </div>
              )}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>Minimum</div>
                  <div>Maximum</div>
                </div>
              )}
            </td>
            <td className="right-border no-border" colSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>Minimum</div>
                  <div>Maximum</div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="right-border no-border bold">Mortgage Insurance</td>
            <td className="center-align no-border" rowSpan="2">
              <CurrencyField
                naked
                fixedDigits={2}
                value={mortgage_insurance_premium_monthly(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align right-border no-border" rowSpan="2">
              {isInterestOnly && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="2"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="2"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="2"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="right-border no-border" />
          </tr>
          <tr>
            <td className="right-border no-border bold">Estimated Escrow</td>
            <td className="center-align no-border" rowSpan="2">
              <CurrencyField
                naked
                fixedDigits={2}
                value={estimated_escrow(documentType, loan, property)}
              />
            </td>
            <td className="center-align right-border no-border" rowSpan="3">
              {isInterestOnly && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={estimated_escrow(documentType, loan, property)}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="3"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={estimated_escrow(documentType, loan, property)}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="3"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={estimated_escrow(documentType, loan, property)}
                />
              )}
            </td>
            <td
              className="center-align right-border no-border"
              colSpan="2"
              rowSpan="3"
            >
              {isArm && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={estimated_escrow(documentType, loan, property)}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="right-border no-border">
              (If Impounded - See Below)
            </td>
          </tr>
          <tr>
            <td className="right-border no-border smaller-font italic">
              <div className="indent">Amount Can Increase Over Time</div>
            </td>
          </tr>
          <tr>
            <td className="no-bottom-border">Estimated Total</td>
            <td
              className="center-align middle-align no-left-border"
              colSpan="2"
              rowSpan="2"
            >
              <CurrencyField
                naked
                fixedDigits={2}
                value={estimated_total_monthly_payment(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_first_estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_second_estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_max_estimated_total_monthly_payment(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="no-top-border">Monthly Payment</td>
          </tr>
          <tr>
            <td>
              <div className="indent">
                {isInterestOnly && "Est. I/O Monthly Payment >"}
              </div>
            </td>
            <td className="center-align" colSpan="2">
              {isInterestOnly && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={estimated_io_monthly_payment(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td colSpan="6" />
          </tr>
          <tr>
            <td className="no-bottom-border bold italic">
              <div className="indent">Total Monthly Payment</div>
            </td>
            <td
              className="center-align middle-align no-left-border"
              colSpan="2"
              rowSpan="2"
            >
              <CurrencyField
                naked
                fixedDigits={2}
                value={total_monthly_payment_includes_all(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_first_total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_second_total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
            <td className="center-align middle-align" colSpan="2" rowSpan="2">
              {isArm && (
                <div className="flex-space-between">
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={arm_max_total_monthly_payment_includes_all(
                        documentType,
                        loan,
                        property,
                        property_to_be_sold,
                        is_selling_property
                      )}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
          <tr>
            <td className="no-top-border">
              <div className="indent">Includes ALL items below</div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="indent">
                {isInterestOnly && "Total I/O Monthly Payment >"}
              </div>
            </td>
            <td className="center-align" colSpan="2">
              {isInterestOnly && (
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={total_io_monthly_payment(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td colSpan="6" />
          </tr>
          <tr>
            <td className="bold">DTI Per Loan Adjustment (PITI)</td>
            <td className="center-align" colSpan="2">
              <PercentField
                naked
                fixedDigits={2}
                value={revised_dti(
                  documentType,
                  applicant.pay_off_debt_to_qualify,
                  borrowers,
                  wrapped_investment_properties,
                  accountSummaries,
                  property,
                  residences,
                  loan,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="right-align" colSpan="2">
              {isArm && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={arm_first_dti_per_loan_adjustment_piti(
                    documentType,
                    loan,
                    property,
                    accountSummaries,
                    residences,
                    wrapped_investment_properties,
                    borrowers,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="right-align" colSpan="2">
              {isArm && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={arm_second_dti_per_loan_adjustment_piti(
                    documentType,
                    loan,
                    property,
                    accountSummaries,
                    residences,
                    wrapped_investment_properties,
                    borrowers,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="right-align" colSpan="2">
              {isArm && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={arm_max_dti_per_loan_adjustment_piti(
                    documentType,
                    loan,
                    property,
                    accountSummaries,
                    residences,
                    wrapped_investment_properties,
                    borrowers,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          </tr>
          <tr>
            <td className="thick-top-border no-bottom-border bold">
              Estimated Taxes, Insurance
            </td>
            <td className="thick-top-border no-border" colSpan="2" />
            <td
              className="center-align thick-top-border no-border bold"
              colSpan="4"
            >
              This estimate includes
            </td>
            <td
              className="thick-top-border right-border no-border bold"
              colSpan="2"
            >
              In escrow?
            </td>
          </tr>
          <tr>
            <td className="right-border no-border bold">&amp; Assessments</td>
            <td className="no-border" colSpan="2" />
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
                  documentType,
                  loan,
                  property
                )}
              />
            </td>
            <td className="center-align no-border bold">
              {initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
                documentType,
                loan,
                property
              ).gt(0) && "X"}
            </td>
            <td className="no-border" colSpan="2">
              Property Taxes
              {mello_roos(property, loan).gt(0) && " + Mello Roos"}
            </td>
            <td className="center-align no-border bold">
              {initial_escrow_payment_at_closing_property_taxes(
                documentType,
                loan,
                property
              ).gt(0)
                ? "YES"
                : "NO"}
            </td>
            <td className="no-border" />
          </tr>
          <tr>
            <td className="right-border no-border smaller-font italic">
              <div className="indent">Amount Can Increase Over Time</div>
            </td>
            <td className="no-border" colSpan="2" />
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={home_owners_insurance_monthly(documentType, property)}
              />
            </td>
            <td className="center-align no-border bold">
              {home_owners_insurance_monthly(documentType, property).gt(0) &&
                "X"}
            </td>
            <td className="no-border" colSpan="2">
              Homeowner&apos;s Insurance
            </td>
            <td className="center-align no-border bold">
              {initial_escrow_payment_at_closing_homeowners_insurance(
                documentType,
                loan,
                property
              ).gt(0)
                ? "YES"
                : "NO"}
            </td>
            <td className="no-border" />
          </tr>
          <tr>
            <td className="right-border no-border" rowSpan="4" />
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_taxes_insurance_assessments_other_monthly(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="no-border" />
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={mortgage_insurance_premium_monthly(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align no-border bold">
              {mortgage_insurance_premium_monthly(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              ).gt(0) && "X"}
            </td>
            <td className="no-border" colSpan="2">
              <div className="flex-space-between">
                <div>Other:</div>
                <div>
                  {initial_escrow_payment_at_closing_mortgage_insurance(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  ).gt(0) && "Mortgage Insurance"}
                </div>
              </div>
            </td>
            <td className="center-align no-border bold">
              {initial_escrow_payment_at_closing_mortgage_insurance(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              ).gt(0) && "YES"}
            </td>
            <td className="no-border" />
          </tr>
          <tr>
            <td className="center-align no-border">a month</td>
            <td className="no-border" />
            <td className="center-align no-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={total_hoas_monthly(property, loan)}
              />
            </td>
            <td className="center-align no-border bold">
              {total_hoas_monthly(property, loan).gt(0) && "X"}
            </td>
            <td className="no-border" colSpan="2">
              <div className="flex-start-with-spacing">
                <div>Other:</div>
                <div>{total_hoas_monthly(property, loan).gt(0) && "HOA"}</div>
              </div>
            </td>
            <td className="no-border" colSpan="2" />
          </tr>
          <tr>
            <td className="no-border" rowSpan="2" colSpan="4" />
            <td className="no-border italic" colSpan="4">
              See Section G on page 2 for escrowed property costs. You must
            </td>
          </tr>
          <tr>
            <td className="no-border italic" colSpan="4">
              pay for other property costs separately.
            </td>
          </tr>
          <tr>
            <td className="big-font bold" colSpan="9">
              Costs at Closing
            </td>
          </tr>
          <tr>
            <td className="middle-align no-bottom-border bold" rowSpan="2">
              Estimated Closing Costs
            </td>
            <td
              className="middle-align center-align no-border left-border"
              rowSpan="2"
            >
              <CurrencyField
                naked
                fixedDigits={0}
                value={total_closing_costs(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="no-border" colSpan="6">
              <div className="flex-start-with-spacing">
                <div>Includes</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={total_loan_costs(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </div>
                <div>in Loan Costs +</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={total_other_costs(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </div>
                <div>Other Costs</div>
              </div>
            </td>
            <td className="no-border right-border" />
          </tr>
          <tr>
            <td className="no-border" />
            <td className="no-border right-border" colSpan="6">
              (in Lender Credits). See details on page 2.
            </td>
          </tr>
          <tr>
            <td className="middle-align bold" rowSpan="2">
              Estimated Cash to Close
            </td>
            <td
              className="middle-align center-align no-right-border"
              rowSpan="2"
            >
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                )}
              />
            </td>
            <td className="no-left-border no-bottom-border" colSpan="7">
              Includes Closing Costs.
              <span className="italic">
                {" "}
                See Calculating Cash to Close on page 2 for details.
              </span>
            </td>
          </tr>
          <tr>
            <td className="center-align no-border bold">
              {isPurchase &&
                estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                ).gt(0) &&
                "X"}
              {isRefinance &&
                estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                ).lt(0) &&
                "X"}
            </td>
            <td className="no-border">From</td>
            <td className="center-align no-border bold">
              {isPurchase &&
                estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                ).lt(0) &&
                "X"}
              {isRefinance &&
                estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                ).gt(0) &&
                "X"}
            </td>
            <td className="no-border">To Borrower</td>
            <td className="no-border" colSpan="3" />
          </tr>
        </tbody>
      </table>
      <div className="consumer-finance-info smaller-font">
        Visit www.consumerfinance.gov/learnmore for general information and
        tools.
      </div>
    </div>
  );
}
