import React from "react";
import {
  estimated_cash_to_close,
  estimated_closing_costs_financed,
  estimated_total_payoffs_and_payments,
  estimated_total_payoffs_and_payments_debt,
  estimated_total_payoffs_and_payments_mortgage,
  loan_amount_first_mortgage,
  total_closing_costs
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import { YES } from "../../../constants/yes_no";
import { useDocument } from "../../loan_tools/loan_tool";
import { is_fha, is_usda, is_va } from "../../../constants/utils";

export default function RefinanceCalculatingCashToClose({ loan }) {
  const [
    {
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  const isFhaUfmipFinanced =
    is_fha(loan.type) && loan.is_ufmip_financed === YES;
  const isUsdaUfmipFinanced =
    is_usda(loan.type) && loan.is_ufmip_financed === YES;
  const isVaAddToLoan =
    is_va(loan.type) &&
    estimated_closing_costs_financed(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    );

  return (
    <div className="calculating-cash-to-close">
      <table className="right-table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td className="summary-header bottom-border" colSpan="3">
              Calculating Cash to Close
            </td>
            <td className="bottom-border" colSpan="4" />
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan="6">Loan Amount</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={loan_amount_first_mortgage(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="6">Total Closing Costs (J)</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={total_closing_costs(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="4">Estimated Total Payoffs and Payments</td>
            <td className="center-align">Mortgage</td>
            <td className="center-align">Debt</td>
            <td />
          </tr>
          <tr>
            <td colSpan="4" />
            <td className="center-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_total_payoffs_and_payments_mortgage(
                  documentType,
                  loan,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td className="center-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_total_payoffs_and_payments_debt(
                  accountSummaries,
                  residences,
                  loan
                )}
              />
            </td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_total_payoffs_and_payments(
                  documentType,
                  loan,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                )}
              />
            </td>
          </tr>
          <tr>
            <td className="top-border bottom-border bold" colSpan="2">
              Estimated Cash to Close
            </td>
            <td className="top-border bottom-border bold">
              {estimated_cash_to_close(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              ).lt(0) && "X"}
            </td>
            <td className="top-border bottom-border">From</td>
            <td className="top-border bottom-border bold">
              {estimated_cash_to_close(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              ).gt(0) && "X"}
            </td>
            <td className="top-border bottom-border">To Borrower</td>
            <td className="right-align top-border bottom-border">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_cash_to_close(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                )}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="3">Estimated Closing Costs Financed</td>
            <td className="center-align">
              {isVaAddToLoan && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td className="center-align">
              {isFhaUfmipFinanced && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td className="center-align">
              {isUsdaUfmipFinanced && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td />
          </tr>
          <tr>
            <td colSpan="3">(Paid from your Loan Amount)</td>
            <td className="center-align">
              {isVaAddToLoan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="center-align">
              {isFhaUfmipFinanced && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="center-align">
              {isUsdaUfmipFinanced && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="right-align">
              {estimated_cash_to_close(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              ).gt(0) && (
                <CurrencyField
                  className="closing-costs-cell right-align"
                  naked
                  fixedDigits={0}
                  value={total_closing_costs(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
