import React, { useEffect, useState, useRef } from "react";

import WAGE_FORMS from "../../constants/wage_forms";
import INCOME_CALCULATOR_TYPES, {
  ANNUAL,
  MONTHLY,
  WEEKLY,
  MONTHLY_24,
  MONTHLY_26,
  PROFESSOR_TEACHER,
  HOURLY
} from "../../constants/income_calculator_types";
import {
  default_income_source,
  default_annual_source,
  default_monthly_source,
  default_weekly_source,
  default_monthly_24_source,
  default_monthly_26_source,
  default_teacher_source,
  default_hourly_source,
  default_employment_information
} from "../../schema/income_source";

import AnnualIncomeCalculator from "./annual_income_calculator";
import PaymentsPerYearIncomeCalculator from "./payments_per_year_income_calculator";
import TeacherIncomeCalculator from "./teacher_income_calculator";
import HourlyIncomeCalculator from "./hourly_income_calculator";

import SelectField from "../fields/select_field";
import NumberField from "../fields/number_field";
import TextField from "../fields/text_field";
import Section from "../ui/section";
import { useDocument } from "../loan_tools/loan_tool";

export default function EditBorrowerIncome({
  borrowerIndex,
  borrower,
  incomeSource,
  incomeSourceIndex,
  showBorrowerTable,
  showEmploymentInformation,
  onChangeBorrowerIncome,
  backToTable
}) {
  const [,,,{ saveDocument }] = useDocument();
  const [incomeSourceEdit, setIncomeSourceEdit] = useState({
    ...default_income_source,
    ...incomeSource
  });
  const [employmentInformationEdit, setEmploymentInformationEdit] = useState({
    ...default_employment_information,
    ...incomeSourceEdit.employment_information
  });
  const {
    calculator,
    description,
    year_0,
    w2_or_self_employed,
    years_on_the_current_job,
    months_on_the_current_job
  } = incomeSourceEdit;
  const {
    line_of_work_years,
  } = employmentInformationEdit;
  const { first_name, middle_name, last_name } = borrower;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (incomeSourceEdit.is_modified || employmentInformationEdit.is_modified) {
        if (
          window.confirm(
            "You have unsaved salary data. Would you like to save it before you leave?"
          )
        ) {
          onSave();
        } else {
          // do nothing
        }
      }
    };
  }, [incomeSourceEdit]);

  useEffect(() => {
    // do nothing

    // cleanup
    return () => {
      callback.current();
    };
  }, []);

  function onSelectCalculator({ calculator }) {
    let schema = default_income_source;
    switch (calculator) {
      case ANNUAL:
        schema = default_annual_source;
        break;
      case MONTHLY:
        schema = default_monthly_source;
        break;
      case WEEKLY:
        schema = default_weekly_source;
        break;
      case MONTHLY_24:
        schema = default_monthly_24_source;
        break;
      case MONTHLY_26:
        schema = default_monthly_26_source;
        break;
      case PROFESSOR_TEACHER:
        schema = default_teacher_source;
        break;
      case HOURLY:
        schema = default_hourly_source;
        break;
      default:
        return;
    }
    setIncomeSourceEdit(schema);
  }

  function onChange(updatedState) {
    setIncomeSourceEdit({
      ...incomeSourceEdit,
      ...updatedState,
      is_modified: true
    });
  }

  function onChangeEmploymentInformation(updatedState) {
    setEmploymentInformationEdit({
      ...employmentInformationEdit,
      ...updatedState,
      is_modified: true
    });
  }

  function onSave(isNextStep) {
    delete incomeSourceEdit.is_modified;
    delete employmentInformationEdit.is_modified;
    incomeSourceEdit.employment_information = employmentInformationEdit;
    const sources = [...borrower.income.sources];
    sources[incomeSourceIndex] = incomeSourceEdit;
    onChangeBorrowerIncome({ sources });
    saveDocument();
    isNextStep ? showEmploymentInformation() : backToTable();
  }

  function onCancel() {
    showBorrowerTable();
  }

  function renderCalculator() {
    switch (incomeSourceEdit.calculator) {
      case ANNUAL:
      case MONTHLY:
        return (
          <AnnualIncomeCalculator
            incomeSource={incomeSourceEdit}
            onChangeIncome={onChange}
          />
        );

      case MONTHLY_24:
      case MONTHLY_26:
      case WEEKLY:
        return (
          <PaymentsPerYearIncomeCalculator
            incomeSource={incomeSourceEdit}
            onChangeIncome={onChange}
          />
        );

      case PROFESSOR_TEACHER:
        return (
          <TeacherIncomeCalculator
            incomeSource={incomeSourceEdit}
            onChangeIncome={onChange}
          />
        );

      case HOURLY:
        return (
          <HourlyIncomeCalculator
            incomeSource={incomeSourceEdit}
            onChangeIncome={onChange}
          />
        );

      default:
        return <div />;
    }
  }

  return (
    <div className="edit-borrower-income">
      <Section
        name={`Editing Income Source For ${first_name} ${middle_name} ${last_name} (Borrower ${borrowerIndex +
          1})`}
      >
        <div className="edit-borrower-income-top">
          <SelectField
            fieldName="calculator"
            name="Select an Income Calculator"
            value={calculator}
            selectOptions={INCOME_CALCULATOR_TYPES}
            onChange={onSelectCalculator}
            sortLabel
            title="LANIS Training Tip:  It is always important to double check the income based on the Ability To Repay regulations. Use the calculators below to determine the exact income of the Borrowers.  To figure out the Self-Employed or Investment income on Schedule E of the Tax Returns, use the Sch. C / Sch. E Button and include it in your submission file."
          />

          <NumberField
            fieldName="year_0"
            name="Year"
            value={year_0}
            onChange={onChange}
          />

          <TextField
            fieldName="description"
            name="Description"
            value={description}
            onChange={onChange}
          />

          <SelectField
            fieldName="w2_or_self_employed"
            name="W2 or Self Employed"
            value={w2_or_self_employed}
            selectOptions={WAGE_FORMS}
            onChange={onChange}
            title="LANIS Training Tip:  It's important to know if your Borrower(s) is W2'd, Self-Employed or Self Employed with a 1099.  If they are W2'd or 1099'd, include these documents with the Tax Returns when you submit to the Lender.  If they are receiving a K1 and qwn more the 25% of the company, Corporate Tax Returns would be needed as well.  Make sure you ask for all the Income documentation upfront to be compliant with the Ability to Repay law."
          />

          <NumberField
            fieldName="years_on_the_current_job"
            name="Years on the current job"
            value={years_on_the_current_job}
            onChange={onChange}
            title="LANIS Training Tip:  In most cases, you will need a 2 Year History of employment.  If the Borrower(s) is counting Commission, Bonus or Overtime income, you must have a 2 year consistent history in most cases.  If the Borrower is moving from 1099 to W2 income, check the Lenders Guidelines to see what will be acceptable for underwriting.  It is always Best Practice Not to count Overtime income into the DTI figures as Overtime income can go away due to job situations.  Use the Base Income to be safe."
          />

          <NumberField
            fieldName="months_on_the_current_job"
            name="Months on the current job"
            value={months_on_the_current_job}
            onChange={onChange}
          />

          <NumberField
              fieldName="line_of_work_years"
              value={line_of_work_years}
              name="Years in line of work"
              onChange={onChangeEmploymentInformation}
          />
        </div>

        {renderCalculator()}

        <button className="button" onClick={onCancel}>
          Cancel
        </button>
        <button className="button" onClick={() => onSave(true)}>
          Next: Employment Information
        </button>
        <button className="button" onClick={() => onSave(false)}>
          Save
        </button>
      </Section>
    </div>
  );
}
