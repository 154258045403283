import React from "react";
import {
  actual_cash_down_payment,
  adjustments_and_other_credits,
  deposit,
  estimated_cash_to_close,
  estimated_closing_costs_financed,
  loan_credits,
  seller_concessions,
  seller_credits,
  total_closing_costs,
  total_cost_to_purchase
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import { YES } from "../../../constants/yes_no";
import { useDocument } from "../../loan_tools/loan_tool";
import { is_fha, is_usda, is_va } from "../../../constants/utils";

export default function CalculatingCashToClose({ loan }) {
  const [
    {
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  const hasSecondLoan = SECOND_LOAN_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );
  const isFhaUfmipFinanced =
    is_fha(loan.type) && loan.is_ufmip_financed === YES;
  const isUsdaUfmipFinanced =
    is_usda(loan.type) && loan.is_ufmip_financed === YES;
  const isVaAddToLoan =
    is_va(loan.type) &&
    estimated_closing_costs_financed(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    );

  return (
    <div className="calculating-cash-to-close">
      <table className="right-table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td className="summary-header bottom-border" colSpan="3">
              Calculating Cash to Close
            </td>
            <td className="bottom-border" colSpan="4" />
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td colSpan="6">Total Closing Costs (J)</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={total_closing_costs(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="5">
              Closing Costs Financed (Included in Loan Amount)
            </td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={estimated_closing_costs_financed(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
            <td />
          </tr>
          <tr>
            <td colSpan="4">Down Payment/Funds from Borrower</td>
            <td colSpan="2">{hasSecondLoan && "(Includes 2nd Loan Amt.)"}</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={actual_cash_down_payment(documentType, loan, property)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="6">Deposit</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={deposit(documentType, loan, property)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="6">Funds for Borrower</td>
            <td className="right-align red-text">
              <CurrencyField
                naked
                fixedDigits={0}
                value={loan.funds_for_borrower}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="2">Seller Credits</td>
            <td colSpan="4">
              <div className="flex-space-between">
                <div>RE Credits:</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={seller_concessions(documentType, loan, property)}
                  />
                </div>
                <div>Loan Credits:</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={loan_credits(documentType, loan, property)}
                  />
                </div>
              </div>
            </td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={seller_credits(documentType, loan, property)}
              />
            </td>
          </tr>
          <tr>
            <td colSpan="4">Adjustments and Other Credits</td>
            <td colSpan="2">{hasSecondLoan && "(Credit for 2nd Loan Amt.)"}</td>
            <td className="right-align">
              <CurrencyField
                naked
                fixedDigits={0}
                value={adjustments_and_other_credits(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
            </td>
          </tr>
          <tr>
            <td className="top-border right-border bottom-border" colSpan="3">
              <div className="flex-space-between">
                <div className="bold">Total Cost To Purchase</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={total_cost_to_purchase(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </div>
              </div>
            </td>
            <td
              className="center-align top-border right-border bottom-border"
              colSpan="4"
            >
              <div className="flex-start-with-front-spacing">
                <div className="bold">Estimated Cash to Close</div>
                <div>
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={estimated_cash_to_close(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property,
                      accountSummaries,
                      residences
                    )}
                  />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="3">Estimated Closing Costs Financed</td>
            <td className="center-align">
              {isVaAddToLoan && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td className="center-align">
              {isFhaUfmipFinanced && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td className="center-align">
              {isUsdaUfmipFinanced && LOAN_TYPE_CATEGORY_NAMES[loan.type]}
            </td>
            <td />
          </tr>
          <tr>
            <td colSpan="3">(Paid from your Loan Amount)</td>
            <td className="center-align">
              {isVaAddToLoan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="center-align">
              {isFhaUfmipFinanced && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td className="center-align">
              {isUsdaUfmipFinanced && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={estimated_closing_costs_financed(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
}
