import React from "react";
import Decimal from "decimal.js";

import "./css/number_field.scss";
import TrainingTip from "../ui/training_tip";

/**
 * NumberField is suitable for rendering a number-input component.
 */
export default function NumberField({
  className,
  onChange,
  fieldName,
  naked,
  value,
  readOnly,
  id,
  name,
  title,
  tabIndex,
  placeholder,
  decimalPlaces,
  showRequired
}) {
  function class_name() {
    let classname = "input-field number-field";

    if (className) {
      classname += ` ${className}`;
    }

    return classname;
  }

  function onClick(event) {
    if (!readOnly) {
      event.target.select();
    }
  }

  /**
   * Update the state of this currency field.
   * @param {*} event
   */
  function change(event) {
    let targetValue;
    let newValue;
    if (event.target.value === "-") {
      newValue = "-0";
      targetValue = "-";
    } else {
      targetValue = event.target.value.replace(/[^\d.-]/g, "");
      newValue = targetValue === "" ? "0" : targetValue;
    }
    try {
      newValue = format(newValue);
    } catch (e) {
      return;
    }

    if (onChange && fieldName) {
      onChange({
        [fieldName]: newValue
      });
    }
  }

  /**
   * Formats the given value into a currency string.
   * @param {*} value
   */
  function format(value) {
    let decimalValue;
    try {
      decimalValue = new Decimal(value);
    } catch (e) {
      decimalValue = new Decimal(0);
    }
    if (Number.isInteger(decimalPlaces)) {
      decimalValue = decimalValue.toDP(decimalPlaces);
    }
    return decimalValue.toString();
  }

  if (naked) {
    return <span className={className}>{format(value)}</span>;
  }

  // Hide the arrows when a number is readOnly.
  const type = readOnly ? "text" : "number";
  return (
    <div className="input-field-container number-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>

      <input
        id={id}
        type={type}
        value={format(value)}
        onChange={change}
        step="any"
        tabIndex={tabIndex}
        className={class_name()}
        readOnly={readOnly}
        onClick={onClick}
        placeholder={placeholder}
      />
    </div>
  );
}
