import React, { useState, useEffect } from "react";
import { navigate } from "@reach/router";
import StandardTemplate from "../templates/standard_template/standard_template";
import UserProfile from "../components/profile/user_profile";
import { default_account_profile, default_company_profile } from "../schema/profile";
import { useUser } from "../hooks/user";
import CompanyProfile from "../components/profile/company_profile";
import "./css/sign_up.scss";
import { handleErrorMessages } from "../services/utils";

export default function SignUpProfile() {
  const [errorMessages, setErrorMessages] = useState({});
  const [
    { accountProfile, companyProfile: accountCompanyProfile },
    { saveAccountProfile, saveCompanyProfile, getProfile }
  ] = useUser();
  const [profile, setProfile] = useState({ ...default_account_profile });
  const [companyProfile, setCompanyProfile] = useState({ ...default_company_profile });

  async function onSaveProfile() {
    Promise.all([
      saveAccountProfile({ ...profile }),
      saveCompanyProfile({ ...companyProfile })
    ]).then(() => {
      navigate("/");
    }).catch(e => handleErrorMessages(e, setErrorMessages));
  }

  function onProfileChange(updatedProfile) {
    setProfile({
      ...profile,
      ...updatedProfile
    });
  }

  function onCompanyProfileChange(updatedCompanyProfile) {
    setCompanyProfile({
      ...companyProfile,
      ...updatedCompanyProfile
    })
  }

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    setProfile(accountProfile);
  }, [accountProfile]);

  useEffect(() => {
    setCompanyProfile(accountCompanyProfile);
  }, [accountCompanyProfile]);

  return (
    <StandardTemplate basic={true}>
      <div className="sign-up">
        <div className="main-title">New Member Account Profile</div>
        <div className="sign-up-profile">
          <div className="error-messages">
            {Object.entries(errorMessages).map(([key, value]) => (
              <div key={key}>{value}</div>
            ))}
          </div>
          <div className="flex">
            <div className="half-width">
              <div className="title">Loan Officer / Subscriber Information</div>
              <UserProfile
                profile={profile}
                onProfileChange={onProfileChange}
                hidePassword={true}
                emailReadOnly={true}
              />
            </div>
            <div className="half-width">
              <div className="title">Company Information</div>
              <CompanyProfile
                companyProfile={companyProfile}
                onCompanyProfileChange={onCompanyProfileChange}
                readOnly={false}
              />
            </div>
          </div>
          <button className="button" onClick={onSaveProfile}>
            Save
          </button>
        </div>
      </div>
    </StandardTemplate>
  );
}
