import React, { useEffect, useState, useRef } from "react";
import {
  add_funds_to_from_borrower,
  additional_cash_out_needed,
  current_loan_balance_first_td,
  current_loan_balance_second_td,
  loan_amount_first_mortgage_base,
  loan_amount_second_mortgage,
  third_loan_balance,
  total_closing_costs,
  va_add_to_loan
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";
import { PERCENT_PAYMENT_TYPES } from "../../../constants/payment_type";
import { useDocument } from "../loan_tool";
import { balance_pay_offs_total } from "../../../calculations/debt_info";
import { default_loan } from "../../../schema/loan";
import Section from "../../ui/section";
import { is_va } from "../../../constants/utils";

export default function RefinanceLoanCalculator({ loan, goBack, updateLoan }) {
  const [
    {
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType },
    { saveDocument }
  ] = useDocument();
  const [loanCalculator, setLoanCalculator] = useState({
    ...default_loan.refinance_loan_calculator,
    ...loan.refinance_loan_calculator
  });
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loanCalculator.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Refinance Calculator. Would you like to save it before you leave?"
          )
        ) {
          save();
        }
      }
    };
  }, [loanCalculator]);

  useEffect(() => {
    return () => {
      callback.current();
    };
  }, []);

  function save() {
    delete loanCalculator.is_modified;
    updateLoan({ refinance_loan_calculator: loanCalculator });
    saveDocument();
    goBack();
  }

  function cancel() {
    goBack();
  }

  function updateLoanCalculator(updatedState) {
    setLoanCalculator({
      ...loanCalculator,
      ...updatedState,
      is_modified: true
    });
  }

  return (
    <Section className="refinance-loan-calculator" name="Loan Calculator">
      <CurrencyField
        readOnly
        name="Max Loan Amount"
        value={loan_amount_first_mortgage_base(documentType, loan, property)}
      />

      {PERCENT_PAYMENT_TYPES.includes(loan.down_payment_type) && (
        <PercentField readOnly value={loan.percent_down} />
      )}

      <p className="bold">
        *May include FHA - VA - USDA Up front Fee if Financed
      </p>

      <CurrencyField
        name="Total of New 1st Loan"
        fieldName="total_of_new_first_loan"
        value={loanCalculator.total_of_new_first_loan}
        onChange={updateLoanCalculator}
      />
      <CurrencyField
        readOnly
        name="Total of New 2nd Loan"
        value={loan_amount_second_mortgage(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <CurrencyField
        readOnly
        name="Current Loan Balance 1st TD"
        value={current_loan_balance_first_td(
          property_to_be_sold,
          is_selling_property
        )}
      />
      <CurrencyField
        readOnly
        name="Current Loan Balance 2nd TD"
        value={current_loan_balance_second_td(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <CurrencyField
        readOnly
        name="Current Loan Balance 3rd TD"
        value={third_loan_balance(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <CurrencyField
        readOnly
        name="Pay Off Debt"
        value={balance_pay_offs_total(
          Object.values(accountSummaries),
          residences,
          loan
        )}
      />
      <CurrencyField
        name="Prepay Penalty"
        fieldName="prepay_penalty"
        value={loanCalculator.prepay_penalty}
        onChange={updateLoanCalculator}
      />
      <CurrencyField
        readOnly
        name="Additional Cash Out Needed"
        value={additional_cash_out_needed(documentType, loan, property)}
      />
      {is_va(loan.type) && (
        <CurrencyField
          readOnly
          name="VA Add UFF To Loan"
          value={va_add_to_loan(loan)}
        />
      )}
      <CurrencyField
        readOnly
        name="Total Closing Costs"
        value={total_closing_costs(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <CurrencyField
        name="Closing Cost Pad"
        fieldName="closing_cost_pad"
        value={loanCalculator.closing_cost_pad}
        onChange={updateLoanCalculator}
      />
      <CurrencyField
        readOnly
        name="Add Funds To / From Borrower"
        value={add_funds_to_from_borrower(
          documentType,
          loanCalculator,
          loan,
          accountSummaries,
          residences,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />

      <button className="button" onClick={cancel}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
