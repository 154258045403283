import React, { useState } from "react";
import "./css/le_price_engine_options.scss";

import StandardTemplate from "../../templates/standard_template/standard_template";
import LoanEstimatePriceEngineOptionsMainPage from "../../components/loan_tools/loan_estimate_price_engine_options/loan_estimate_options_main_page";
import EditLoanOptions from "../../components/loan_tools/loan_estimate_price_engine_options/edit_loan_options";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { LE_PRICE_ENG_OPT } from "../../constants/navigations";
import NoLoans from "./no_loans";

export default function LePriceEngineOptionsPage() {
  const [document, setDocument] = useDocument();
  const [activeView, setActiveView] = useState("MAIN_PAGE");
  const [editLoanOptionsIndex, setEditLoanOptionsIndex] = useState();

  function showMainPage() {
    setActiveView("MAIN_PAGE");
  }

  function showEditLoanOptions(index) {
    setActiveView("EDIT_LOAN_OPTIONS");
    setEditLoanOptionsIndex(index);
  }

  function updateLoan(loan) {
    const loans = [...document.loans];
    loans[editLoanOptionsIndex] = loan;
    setDocument({
      ...document,
      loans
    });
  }

  return (
    <StandardTemplate
      active={LE_PRICE_ENG_OPT}
      isDisableNavSave={activeView !== "MAIN_PAGE"}
    >
      <NoLoans />

      <div className="le-price-engine-options">
        {activeView === "MAIN_PAGE" && (
          <LoanEstimatePriceEngineOptionsMainPage
            showEditLoanOptions={showEditLoanOptions}
          />
        )}
        {activeView === "EDIT_LOAN_OPTIONS" && (
          <EditLoanOptions
            loanIndex={editLoanOptionsIndex}
            back={showMainPage}
            onSave={updateLoan}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
