import React, { useEffect, useState, useRef } from "react";

import {
  lender_credit_cost,
  loan_amount_first_mortgage
} from "../../../calculations/loan";
import { lowest_credit_score } from "../../../calculations/applicant";

import {
  INVESTMENT_PROPERTY_ADD_ON_FEE_TYPES,
  AGENCY_PLUS_SUPER_CONFORMING_TYPES,
  SUBORDANITE_ADD_ON_FEE_TYPES,
  FREDDIEMAC_ADD_ON_FEE_TYPES,
  CONDOMINIUM_ADD_ON_FEE_TYPES,
  MULTI_UNIT_ADD_ON_FEE_TYPES,
  DU_LP_REFI_PLUS_ADD_ON_FEE_TYPES
} from "../../../constants/loan_options";
import CREDIT_SCORES from "../../../constants/credit_score";
import {
  CONDOMINIUM_TYPES,
  MANUFACTURED_PROPERTY_TYPES,
  MULTI_UNIT_TYPES
} from "../../../constants/property_type";
import { ARM_INDEX_TYPES } from "../../../constants/loan_arm_properties";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { INVESTMENT_PROPERTY } from "../../../constants/property_classification";
import { FREDDIEMAC } from "../../../constants/loan_types";

import SelectField from "../../fields/select_field";
import PercentField from "../../fields/percent_field";
import TextField from "../../fields/text_field";

import { useDocument } from "../../loan_tools/loan_tool";
import PROPERTY_TYPES from "../../../constants/property_type";
import { is_show_lpmi_adjustment_fees } from "../../../constants/utils";
import { AGENCY_PLUS_SUPER_CONFORMING_MINIMUM } from "../../../constants/fixed_values";
import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import { REFINANCE } from "../../../constants/document_types";
import Section from "../../ui/section";

const allInputFields = [
  "fico_ltv_adjust",
  "broker_special",
  "refinance_cash_out",
  "refinance_cash_out_high_balance_loans",
  "high_balance_loan_amount",
  "interest_only",
  "loan_amount",
  "lock_period_adjust",
  "miscellaneous_fee",
  "no_impounds",
  "purchase_special",
  "state_of_zone_add",
  "manufactured_modular_add_on_fee",
  "private_mortgage_insurance",
  "arm_add_on_fee",
  "investment_property_add_on_fee",
  "investment_property_add_on_fee_type",
  "agency_plus_super_conforming",
  "subordanite_add_on_fee",
  "subordanite_add_on_fee_type",
  "freddiemac_add_on_fee",
  "condominium_add_on_fee",
  "condominium_add_on_fee_type",
  "multi_unit_add_on_fee",
  "multi_unit_add_on_fee_type",
  "du_lp_refi_plus_add_on_fee",
  "du_lp_refi_plus_add_on_fee_type",
  "broker_banker_fee"
];

export default function EditLoanOptions({ loanIndex, back, onSave }) {
  const [document, , { documentType }, { saveDocument }] = useDocument();
  const {
    property,
    applicant,
    property_to_be_sold,
    is_selling_property
  } = document;
  const loan = document.loans[loanIndex];
  const [loanOptions, setLoanOptions] = useState(loan.loan_options);
  const {
    agency_plus_super_conforming,
    agency_plus_super_conforming_type,
    arm_add_on_fee,
    broker_banker_fee,
    broker_special,
    cash_out,
    refinance_cash_out,
    refinance_cash_out_high_balance_loans,
    condominium_add_on_fee,
    condominium_add_on_fee_type,
    du_lp_refi_plus_add_on_fee,
    du_lp_refi_plus_add_on_fee_type,
    fico_ltv_adjust,
    freddiemac_add_on_fee,
    freddiemac_add_on_fee_type,
    high_balance_loan_amount,
    interest_only,
    investment_property_add_on_fee,
    investment_property_add_on_fee_type,
    loan_amount,
    loan_amount_greater_than_424100,
    lock_period_adjust,
    lpmi_adjustment_base_loan,
    manufactured_modular_add_on_fee,
    miscellaneous_fee,
    multi_unit_add_on_fee,
    multi_unit_add_on_fee_type,
    no_impounds,
    private_mortgage_insurance,
    purchase_special,
    second_home,
    subordanite_add_on_fee,
    subordanite_add_on_fee_type,
    state_of_zone_add,
    term_25_years_and_less
  } = loanOptions;

  const isRefinance = documentType === REFINANCE;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loanOptions.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Loan Options form. Would you like to save it before you leave?"
          )
        ) {
          save();
        } else {
          // do nothing
        }
      }
    };
  }, [loanOptions]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function onChange(updatedState) {
    setLoanOptions({ ...loanOptions, ...updatedState, is_modified: true });
  }

  function save() {
    delete loanOptions.is_modified;
    loan.loan_options = loanOptions;
    onSave(loan);
    saveDocument();
    back();
  }

  function onCancel() {
    back();
  }

  function showManufacturedAddOnFee() {
    return MANUFACTURED_PROPERTY_TYPES.includes(loan.property_type);
  }

  function showPrivateMortgageInsurance() {
    return is_show_lpmi_adjustment_fees(documentType, loan, property);
  }

  function showTerm25YearsAndLess() {
    return is_show_lpmi_adjustment_fees(documentType, loan, property);
  }

  function showSecondHome() {
    return is_show_lpmi_adjustment_fees(documentType, loan, property);
  }

  function showLoanAmountGreaterThan424100() {
    return is_show_lpmi_adjustment_fees(documentType, loan, property);
  }

  function showArmAddOnFee() {
    return ARM_TERMS.includes(loan.term);
  }

  function showInvestmentPropertyAddOnFee() {
    return document.property.classification === INVESTMENT_PROPERTY;
  }

  function showAgencyPlusSuperConforming() {
    return loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).gte(AGENCY_PLUS_SUPER_CONFORMING_MINIMUM);
  }

  function showFreddieMacAddOnFee() {
    return loan.type === FREDDIEMAC;
  }

  function showCondominiumAddOnFee() {
    return CONDOMINIUM_TYPES.includes(loan.property_type);
  }

  function showMultiUnitAddOnFee() {
    return MULTI_UNIT_TYPES.includes(loan.property_type);
  }

  function showSubordinateAddOnFee() {
    return SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type);
  }

  const loanState = {
    ...loan,
    loan_options: loanOptions
  };
  const includeBankerFee = true;
  const lenderCreditCost = lender_credit_cost(
    documentType,
    loanState,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBankerFee
  );

  function populateFromLoanOne() {
    const { loans } = document;
    if (
        window.confirm(
            "This will wipe existing data on this page and Replace it with Data from Loan 1, continue?"
        )
    ) {
      const tempLoan = allInputFields.reduce((loan, field) => {
        loan.loan_options[field] = loans[0].loan_options[field];
        return loan;
      }, { loan_options: { ...loan.loan_options } });
      tempLoan.loan_options.is_modified = true;
      setLoanOptions(tempLoan.loan_options);
    }
  }

  const lowestCreditScore = lowest_credit_score(applicant.borrowers).toString();

  return (
    <Section
      className="le-price-engine-options-edit-loan"
      name={`Edit Loan ${loanIndex + 1}`}
    >
      {loanIndex > 0 && (
          <div className="flex-end align-items-center bottom-border medium-border">
            <button
                className="button"
                onClick={() => populateFromLoanOne(loan)}
            >
              Populate Data From Loan 1
            </button>
          </div>
      )}
      <PercentField name="Rate" readOnly decimalPlaces={3} value={loan.rate} />
      <TextField name="Lender" readOnly value={loan.lender} />
      <TextField
        name="Property Type"
        readOnly
        value={PROPERTY_TYPES[loan.property_type]}
      />

      <TextField
        readOnly
        name="Score Range"
        value={CREDIT_SCORES[lowestCreditScore]}
      />

      <PercentField
        name="1st Original Base Price Cost"
        readOnly
        value={loan.base_fee}
      />
      <PercentField
        name="FICO / LTV Adjust"
        fieldName="fico_ltv_adjust"
        value={fico_ltv_adjust}
        onChange={onChange}
      />
      <PercentField
        name="Broker Special (TIER | ONE)"
        fieldName="broker_special"
        value={broker_special}
        onChange={onChange}
      />
      <PercentField
        name="Cash Out"
        fieldName="refinance_cash_out"
        value={refinance_cash_out}
        onChange={onChange}
      />
      <PercentField
        name="Cash Out High Balance Loans"
        fieldName="refinance_cash_out_high_balance_loans"
        value={refinance_cash_out_high_balance_loans}
        onChange={onChange}
      />
      <PercentField
        name="High Balance Loan Amount"
        fieldName="high_balance_loan_amount"
        value={high_balance_loan_amount}
        onChange={onChange}
      />
      <PercentField
        name="Interest Only"
        fieldName="interest_only"
        value={interest_only}
        onChange={onChange}
      />
      <PercentField
        name="Loan Amount"
        fieldName="loan_amount"
        value={loan_amount}
        onChange={onChange}
      />
      <PercentField
        name="Lock Period Adjust"
        fieldName="lock_period_adjust"
        value={lock_period_adjust}
        onChange={onChange}
      />
      <PercentField
        name="Miscellaneous Fee"
        fieldName="miscellaneous_fee"
        value={miscellaneous_fee}
        onChange={onChange}
      />
      <PercentField
        name="No Impounds"
        fieldName="no_impounds"
        value={no_impounds}
        onChange={onChange}
      />
      <PercentField
        name="Purchase Special"
        fieldName="purchase_special"
        value={purchase_special}
        onChange={onChange}
      />
      <PercentField
        name="State of Zone Add"
        fieldName="state_of_zone_add"
        value={state_of_zone_add}
        onChange={onChange}
      />
      {showManufacturedAddOnFee() && (
        <PercentField
          name="Manufactured / Modular Homes add on Fee"
          fieldName="manufactured_modular_add_on_fee"
          value={manufactured_modular_add_on_fee}
          onChange={onChange}
        />
      )}
      {showPrivateMortgageInsurance() && (
        <PercentField
          name="Private Mortgage Insurance"
          fieldName="private_mortgage_insurance"
          value={private_mortgage_insurance}
          onChange={onChange}
        />
      )}
      {showPrivateMortgageInsurance() && (
        <PercentField
          name="LPMI Adjustment Base Loan"
          readOnly
          fieldName="lpmi_adjustment_base_loan"
          value={lpmi_adjustment_base_loan}
          onChange={onChange}
        />
      )}
      {showTerm25YearsAndLess() && (
        <PercentField
          name="Term 25 years and Less"
          readOnly
          fieldName="term_25_years_and_less"
          value={term_25_years_and_less}
          onChange={onChange}
        />
      )}
      {is_show_lpmi_adjustment_fees(documentType, loan, property) && (
        <PercentField
          name="Cash Out"
          readOnly
          fieldName="cash_out"
          value={cash_out}
          onChange={onChange}
        />
      )}
      {showSecondHome() && (
        <PercentField
          name="Second Home"
          readOnly
          fieldName="second_home"
          value={second_home}
          onChange={onChange}
        />
      )}
      {showLoanAmountGreaterThan424100() && (
        <PercentField
          name="Loan Amount Greater than $424,100"
          fieldName="loan_amount_greater_than_424100"
          readOnly
          value={loan_amount_greater_than_424100}
          onChange={onChange}
        />
      )}
      {showArmAddOnFee() && (
        <PercentField
          name={`ARM Add on Fee (${ARM_INDEX_TYPES[loan.arm_index_type]})`}
          fieldName="arm_add_on_fee"
          value={arm_add_on_fee}
          onChange={onChange}
        />
      )}
      {showInvestmentPropertyAddOnFee() && (
        <>
          <PercentField
            name="Investment Property Add On Fee"
            fieldName="investment_property_add_on_fee"
            value={investment_property_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="Investment Property Add on Fee Type"
            fieldName="investment_property_add_on_fee_type"
            value={investment_property_add_on_fee_type}
            onChange={onChange}
            selectOptions={INVESTMENT_PROPERTY_ADD_ON_FEE_TYPES}
          />
        </>
      )}
      {showAgencyPlusSuperConforming() && (
        <>
          <PercentField
            name="Agency Plus / Super Conforming"
            fieldName="agency_plus_super_conforming"
            value={agency_plus_super_conforming}
            onChange={onChange}
          />
          <SelectField
            name="Agency Plus / Super Conforming"
            fieldName="agency_plus_super_conforming_type"
            value={agency_plus_super_conforming_type}
            onChange={onChange}
            selectOptions={AGENCY_PLUS_SUPER_CONFORMING_TYPES}
          />
        </>
      )}
      {showSubordinateAddOnFee() && (
        <>
          <PercentField
            name="Subordinate Add On Fee"
            fieldName="subordanite_add_on_fee"
            value={subordanite_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="Subordinate Add On Fee Type"
            fieldName="subordanite_add_on_fee_type"
            value={subordanite_add_on_fee_type}
            onChange={onChange}
            selectOptions={SUBORDANITE_ADD_ON_FEE_TYPES}
          />
        </>
      )}
      {showFreddieMacAddOnFee() && (
        <>
          <PercentField
            name="FreddieMac Add On Fee"
            fieldName="freddiemac_add_on_fee"
            value={freddiemac_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="FreddieMac Add On Fee Type"
            fieldName="freddiemac_add_on_fee_type"
            value={freddiemac_add_on_fee_type}
            onChange={onChange}
            selectOptions={FREDDIEMAC_ADD_ON_FEE_TYPES}
          />
        </>
      )}

      {showCondominiumAddOnFee() && (
        <>
          <PercentField
            name="Condominium Add On Fee"
            fieldName="condominium_add_on_fee"
            value={condominium_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="Condominium Add On Fee Type"
            fieldName="condominium_add_on_fee_type"
            value={condominium_add_on_fee_type}
            onChange={onChange}
            selectOptions={CONDOMINIUM_ADD_ON_FEE_TYPES}
          />
        </>
      )}

      {showMultiUnitAddOnFee() && (
        <>
          <PercentField
            name="Multi Unit Add On Fee"
            fieldName="multi_unit_add_on_fee"
            value={multi_unit_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="Multi Unit Add On Fee Type"
            fieldName="multi_unit_add_on_fee_type"
            value={multi_unit_add_on_fee_type}
            onChange={onChange}
            selectOptions={MULTI_UNIT_ADD_ON_FEE_TYPES}
          />
        </>
      )}

      {isRefinance && (
        <>
          <PercentField
            name="DU or LP REFI Plus Add On Fee"
            fieldName="du_lp_refi_plus_add_on_fee"
            value={du_lp_refi_plus_add_on_fee}
            onChange={onChange}
          />
          <SelectField
            name="DU or LP Refi Plus Add On Fee Type"
            fieldName="du_lp_refi_plus_add_on_fee_type"
            value={du_lp_refi_plus_add_on_fee_type}
            onChange={onChange}
            selectOptions={DU_LP_REFI_PLUS_ADD_ON_FEE_TYPES}
          />
        </>
      )}
      <PercentField
        name="Broker / Banker Fee"
        fieldName="broker_banker_fee"
        value={broker_banker_fee}
        onChange={onChange}
      />

      <PercentField
        readOnly
        name="Lender Credit / Cost"
        value={lenderCreditCost}
      />

      <button className="button" onClick={onCancel}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
