import React, { useEffect, useState, useRef } from "react";
import Decimal from "decimal.js";

import { default_borrower } from "../../../schema/borrower";
import CREDIT_SCORE_RANGES from "../../../constants/credit_score";
import RENTING_OR_OWNING, { OWN, RENT } from "../../../constants/renting_or_owning";
import MARITAL_STATUS from "../../../constants/marital_status";
import {
  YES,
  YES_NO_TBD,
  YES_NO_TBD_CREDIT_DEBT_OTHER
} from "../../../constants/yes_no";

import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import SelectField from "../../fields/select_field";
import TextField from "../../fields/text_field";

import { useDocument } from "../../loan_tools/loan_tool";
import PhoneField from "../../fields/phone_field";
import Section from "../../ui/section";
import { borrower_name } from "../../../constants/utils";
import TrainingTip from "../../ui/training_tip";
import SsnField from "../../fields/ssn_field";

export default function EditBorrower({
  borrower = default_borrower,
  borrowerIndex,
  subjectPropertyIsReo,
  onChangeBorrowers,
  showApplicantTable
}) {
  const [,,,{ saveDocument }] = useDocument();
  const [borrowerEdit, setBorrowerEdit] = useState({
    ...default_borrower,
    ...borrower
  });
  const [document] = useDocument();
  const { borrowers } = document.applicant;
  const callback = useRef();

  // This function updates callback with current state of the subform
  useEffect(() => {
    callback.current = () => {
      if (borrowerEdit.is_modified) {
        if (validBorrower()) {
          if (
            window.confirm(
              "You have unsaved data in Borrower Form. Would you like to save it before you leave?"
            )
          ) {
            save();
          }
        } else {
          // did not pass validation
          alert(
            "Not all necessary fields were entered. Therefore changes would be dismissed."
          );
        }
      }
    };
  }, [borrowerEdit]);

  useEffect(() => {
    // do nothing

    // cleanup
    return () => {
      callback.current();
    };
  }, []);

  function validBorrower() {
    const validationFields = [borrowerEdit.lowest_credit_mid_score, borrowerEdit.ssn];
    return validationFields.every(value => value !== "");
  }

  function save() {
    delete borrowerEdit.is_modified;
    borrowers[borrowerIndex] = borrowerEdit;

    for (let index in borrowers) {
      const borrower = borrowers[index];
      const isNotThisBorrower = borrowerIndex !== index;
      const isSameAddressAsThisBorrower = borrower.same_address_as === borrowerIndex.toString();
      const isCoBorrower = index.toString() === borrowerEdit.co_borrower;
      const isTargetingThisBorrowerAsCoBorrower = borrowerIndex.toString() === borrower.co_borrower;

      if (isNotThisBorrower && isSameAddressAsThisBorrower) {
        borrowers[index] = {
          ...borrower,
          current_address: borrowerEdit.current_address,
          current_address_2: borrowerEdit.current_address_2,
          city: borrowerEdit.city,
          state: borrowerEdit.state,
          zip_code: borrowerEdit.zip_code,
          currently_renting_or_owning: borrowerEdit.currently_renting_or_owning,
          rental_amount: borrowerEdit.rental_amount
        };
      }
      if (isCoBorrower) {
        borrower.co_borrower = borrowerIndex.toString();
      } else if (isTargetingThisBorrowerAsCoBorrower) {
        borrower.co_borrower = "";
      }
    }
    onChangeBorrowers(borrowers);
    saveDocument();
    showApplicantTable();
  }

  function cancel() {
    showApplicantTable();
  }

  function onChangeBorrower(updatedState) {
    setBorrowerEdit({
      ...borrowerEdit,
      ...updatedState,
      is_modified: true
    });
  }

  function onChangeSameAddressAs(updatedState) {
    if (updatedState.same_address_as === "none") {
      setBorrowerEdit({
        ...borrowerEdit,
        ...updatedState,
        is_modified: true
      });
      return;
    }
    const borrowerIndex = new Decimal(updatedState.same_address_as).toNumber();
    const otherBorrower = borrowers[borrowerIndex];
    if (!otherBorrower) {
      return; // This should never happen.
    }

    const borrower = {
      ...borrowerEdit,
      ...updatedState,
      current_address: otherBorrower.current_address,
      current_address_2: otherBorrower.current_address_2,
      city: otherBorrower.city,
      state: otherBorrower.state,
      zip_code: otherBorrower.zip_code,
      currently_renting_or_owning: otherBorrower.currently_renting_or_owning,
      market_value: otherBorrower.market_value,
      rental_amount: otherBorrower.rental_amount,
      is_modified: true
    };
    setBorrowerEdit(borrower);
  }

  function onChangeCoBorrower(updatedState) {
    if (updatedState.co_borrower === "") {
      setBorrowerEdit({
        ...borrowerEdit,
        ...updatedState,
        is_modified: true
      });
      return;
    }
    const borrowerIndex = new Decimal(updatedState.co_borrower).toNumber();
    const otherBorrower = borrowers[borrowerIndex];
    if (!otherBorrower) {
      return; // This should never happen.
    }

    const borrower = {
      ...borrowerEdit,
      ...updatedState,
      is_modified: true
    };
    setBorrowerEdit(borrower);
  }

  function renderBorrowerOption(borrower, index) {
    return (
      <option key={index} value={index}>
        {borrower_name(borrower)}
      </option>
    );
  }

  const currentlyRenting = borrowerEdit.currently_renting_or_owning === RENT;
  const currentlyOwning = borrowerEdit.currently_renting_or_owning === OWN;
  const marriedToOptions = [];
  const borrowerOptions = { none: "None" };
  const coBorrowerOptions = { "": "None" };

  for (let i = 0; i < borrowers.length; i++) {
    if (i !== borrowerIndex) {
      marriedToOptions.push(renderBorrowerOption(borrowers[i], i));
      const label = borrowers[i]
          ? borrower_name(borrowers[i])
          : `Borrower ${i + 1}`;
      if (borrowers[i].same_address_as !== borrowerIndex.toString()
          || (borrowerEdit.same_address_as !== "none"
              && borrowerEdit.same_address_as !== "")) {
        borrowerOptions[i.toString()] = label;
      }
      const alreadyHasCoBorrower = borrowers[i].co_borrower;
      const coBorrowerIsNotThis = borrowers[i].co_borrower !== borrowerIndex.toString();
      if (!alreadyHasCoBorrower || !coBorrowerIsNotThis) {
        coBorrowerOptions[i.toString()] = label;
      }
    }
  }

  function validateSameAsAddress() {
    const sameAddressAsSet = borrowerEdit.same_address_as !== "none" &&
        borrowerEdit.same_address_as !== "";
    if (!sameAddressAsSet) {
      return
    }
    const targetBorrowerIndex = new Decimal(borrowerEdit.same_address_as);
    const targetBorrower = borrowers[targetBorrowerIndex];
    const thisBorrowerIsBeforeTarget = borrowerIndex < targetBorrowerIndex;
    if (!targetBorrower || !thisBorrowerIsBeforeTarget) {
      return;
    }
    const otherBorrowerSameAddressAsSet = targetBorrower.same_address_as !== "none" &&
        targetBorrower.same_address_as !== "";
    if (!otherBorrowerSameAddressAsSet) {
      return;
    }
    const otherBorrowerTargetsThisBorrower = new Decimal(targetBorrower.same_address_as)
        .eq(borrowerIndex);
    if (otherBorrowerTargetsThisBorrower) {
      borrowerEdit.same_address_as = "none";
    }
  }

  validateSameAsAddress();

  const readOnlyAddress =
    borrowerEdit.same_address_as !== "none" &&
    borrowerEdit.same_address_as !== "";

  return (
    <div className="edit-borrower">
      <Section name={`Borrower #${borrowerIndex + 1}`}>
        <div className="half-width">
          <TextField
            fieldName="first_name"
            name="Borrower"
            value={borrowerEdit.first_name}
            placeholder="First"
            onChange={onChangeBorrower}
            title="LANIS Training Tip: Use full Legal Name so you are consistent throughout the process. Use Middle initials where needed. Remember to double check the Tax Returns, W2's and 1099's for consistency. It's always a great idea to pull a Property Profile to see how they hold Title. Consistency in the names will also let you know what name to use when pulling a Credit Report. This can eliminate Change of Circumstances towards the end of the transaction when time may be critical."
          />
          <TextField
            fieldName="middle_name"
            value={borrowerEdit.middle_name}
            placeholder="Middle"
            onChange={onChangeBorrower}
          />
          <TextField
            fieldName="last_name"
            value={borrowerEdit.last_name}
            placeholder="Last"
            onChange={onChangeBorrower}
          />
          <TextField
            fieldName="suffix"
            value={borrowerEdit.suffix}
            placeholder="Suffix"
            onChange={onChangeBorrower}
          />
          <TextField
            fieldName="current_address"
            name="Current Address"
            value={borrowerEdit.current_address}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
          />
          <TextField
            fieldName="current_address_2"
            name="Current Address 2"
            value={borrowerEdit.current_address_2}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
          />
          <TextField
            fieldName="city"
            name="City"
            value={borrowerEdit.city}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
          />
          <TextField
            fieldName="state"
            name="State"
            value={borrowerEdit.state}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
          />
          <TextField
            fieldName="zip_code"
            name="Zip Code"
            value={borrowerEdit.zip_code}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
          />
          <SelectField
            fieldName="currently_renting_or_owning"
            name="Currently Renting or Own Home"
            value={borrowerEdit.currently_renting_or_owning}
            onChange={onChangeBorrower}
            readOnly={readOnlyAddress}
            selectOptions={RENTING_OR_OWNING}
          />

          {currentlyOwning && subjectPropertyIsReo && (
            <CurrencyField
              fieldName="market_value"
              name="Market Value of Primary Residence"
              value={borrowerEdit.market_value}
              onChange={onChangeBorrower}
              readOnly={readOnlyAddress}
            />
          )}

          {currentlyRenting && (
            <CurrencyField
              fieldName="rental_amount"
              name="Rental Amount/Month"
              value={borrowerEdit.rental_amount}
              onChange={onChangeBorrower}
              readOnly={readOnlyAddress}
            />
          )}
          {currentlyRenting && (
            // TODO: Is this in months?
            <NumberField
              fieldName="how_long_in_current_residence"
              name="How Long in Current Residence"
              value={borrowerEdit.how_long_in_current_residence}
              onChange={onChangeBorrower}
            />
          )}

          {Object.keys(borrowerOptions).length > 1 && (
            <SelectField
              fieldName="same_address_as"
              name="Same address as..."
              selectOptions={borrowerOptions}
              value={borrowerEdit.same_address_as}
              onChange={onChangeSameAddressAs}
            />
          )}

          <SelectField
            fieldName="marital_status"
            name={`Marital Status Borrower ${borrowerIndex + 1}`}
            value={borrowerEdit.marital_status}
            onChange={onChangeBorrower}
            selectOptions={MARITAL_STATUS}
          />

          <SelectField
            fieldName="married_to"
            name="Married to"
            value={borrowerEdit.married_to}
            onChange={onChangeBorrower}
            showDefaultOption
          >
            <option value="Other">Other</option>
            {marriedToOptions}
          </SelectField>

          {borrowers.length > 1 && (
              <SelectField
                  fieldName="co_borrower"
                  name="Co-Borrower"
                  selectOptions={coBorrowerOptions}
                  value={borrowerEdit.co_borrower}
                  onChange={onChangeCoBorrower}
              />
          )}

          <TextField
            typeOverride="email"
            fieldName="email"
            name="Email"
            value={borrowerEdit.email}
            onChange={onChangeBorrower}
          />
          <PhoneField
            fieldName="cell_phone"
            name="Cell Phone"
            value={borrowerEdit.cell_phone}
            onChange={onChangeBorrower}
          />
          <PhoneField
            fieldName="home_phone"
            name="Home Phone"
            value={borrowerEdit.home_phone}
            onChange={onChangeBorrower}
          />
          <PhoneField
            fieldName="work_phone"
            name="Work Phone"
            value={borrowerEdit.work_phone}
            onChange={onChangeBorrower}
          />
          <SsnField
              fieldName="ssn"
              name="Social Security Number"
              value={borrowerEdit.ssn}
              onChange={onChangeBorrower}
              showRequired
              title="LANIS Training Tip: The Social Security Field must be filled in even though the Borrower may not want you to pull credit.  You can use a temporary SS# i.e., 111-11-1111 and fill in the other required fields then click on the Save Button below when it turns Blue."
          />
          <SelectField
            fieldName="is_veteran"
            name="Is the Borrower a Veteran"
            value={borrowerEdit.is_veteran}
            onChange={onChangeBorrower}
            selectOptions={YES_NO_TBD}
          />

          {borrowerEdit.is_veteran === YES && (
            <a
              className="button inverse-button"
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.benefits.va.gov/homeloans/purchaseco_eligibility.asp"
            >
              Check Eligibility
            </a>
          )}
          <SelectField
            fieldName="on_title"
            name="Will the Borrower be on Title"
            value={borrowerEdit.on_title}
            onChange={onChangeBorrower}
            selectOptions={YES_NO_TBD_CREDIT_DEBT_OTHER}
          />

          <SelectField
            fieldName="on_loan"
            name="Will the Borrower be on the Loan"
            value={borrowerEdit.on_loan}
            onChange={onChangeBorrower}
            selectOptions={YES_NO_TBD_CREDIT_DEBT_OTHER}
          />
          <SelectField
            fieldName="lowest_credit_mid_score"
            name="Lowest Credit Mid-Score"
            value={borrowerEdit.lowest_credit_mid_score}
            onChange={onChangeBorrower}
            selectOptions={CREDIT_SCORE_RANGES}
            showDefaultOption
            showRequired
          />
          <div>
            <a
              className="button inverse-button"
              href="https://www.titleprofile.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Look up Current Property Profile
            </a>{" "}
            <TrainingTip content="LANIS Training Tip: You should look up the Property Profile of the property they are living in to see the History and if the Mortgages or Liens on the property would make it a short sale (if they are purchasing the property they are currently living in). Also, if there is anything recorded on the property such as a lien that could take time to resolve and be removed. Double check the City, and how Title was held by the Sellers vs. the Contract names. Get a Prelim Title Report as soon as possible or call your Title Company for a deep search of the property. If they currently own the property, it's still important to find out the details, i.e. if a NOD was ever filed on the property, because if they are buying another property and it's a Jumbo loan, they may not qualify. It is a good idea to have a complete understanding of the Borrower and the property they have and what they will be purchasing." />
          </div>
          <div>
            <a
              className="button inverse-button"
              href="http://www.eppraisal.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Look up Current Market Value
            </a>{" "}
            <TrainingTip content="LANIS Training Tip: You should look up the Market Value of the Subject Property to see if the Sales price matched the Comps for the home. If not, you may have an issue with the LTV/CLTV which will affect the Pricing of your loan. Also, if the Sales price of the home is Over Priced the Appraisal could come in low and there may not be a cure so your Loan will not go through. Talk with the Buyer’s Agent to see how to resolve it. Check the Borrowers’ reserves to see if they could come in with more funds to close." />
          </div>
        </div>
        <button className="button" onClick={cancel}>
          Cancel
        </button>
        <button className="button" disabled={!validBorrower()} onClick={save}>
          Save
        </button>
      </Section>
    </div>
  );
}
