// loan.va_upfront_funding_fee
export const UPFRONT_FUND_FEE = Object.freeze({
  "0.500": "0.500%",
  "1.000": "1.000%",
  "1.250": "1.250%",
  "1.400": "1.400%",
  "1.500": "1.500%",
  "1.625": "1.625%",
  "1.750": "1.750%",
  "2.150": "2.150%",
  "2.250": "2.250%",
  "2.300": "2.300%",
  "2.400": "2.400%",
  "3.300": "3.300%",
  "3.600": "3.600%"
});
