import React from "react";

import { YES, NO } from "../../../constants/yes_no";
import {
  calculate_reserve_total,
  equity_from_pending_sale
} from "../../../calculations/reserve";

import CurrencyField from "../../fields/currency_field";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import { PURCHASE } from "../../../constants/document_types";

export default function ReservesTotalFooter() {
  const [document, , { documentType }] = useDocument();

  const {
    accountSummaries,
    residences,
    is_selling_property,
    property_to_be_sold,
    loans
  } = document;
  const isPurchase = documentType === PURCHASE;
  const { enough_reserves_to_purchase_home, borrowers } = document.applicant;
  const showEquityFromPendingSale =
    isPurchase &&
    (is_selling_property === YES || enough_reserves_to_purchase_home === NO);

  return (
    loans.length > 0 && (
      <Section name="Summary" className="reserves-summary">
        <table>
          <thead>
            <tr>
              <th />
              {loans.map((loan, index) => (
                <th key={index}>Loan {index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {showEquityFromPendingSale && (
              <tr>
                <td>Equity from Pending Sale</td>
                {loans.map((loan, index) => (
                  <td key={index}>
                    <CurrencyField
                      readOnly
                      value={equity_from_pending_sale(
                        documentType,
                        property_to_be_sold,
                        accountSummaries,
                        residences,
                        loan,
                        is_selling_property
                      )}
                    />
                  </td>
                ))}
              </tr>
            )}
            <tr>
              <td>Total Reserves</td>
              {loans.map((loan, index) => (
                <td key={index}>
                  <CurrencyField
                    readOnly
                    value={calculate_reserve_total(
                      documentType,
                      borrowers,
                      accountSummaries,
                      residences,
                      property_to_be_sold,
                      loan,
                      is_selling_property
                    )}
                  />
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Section>
    )
  );
}
