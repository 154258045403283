import React from "react";
import Decimal from "decimal.js";

import { jumbo_reserves_required_total } from "../../calculations/loan";

import { YES_NO, YES } from "../../constants/yes_no";
import LOAN_TYPES, {
  REFINANCE_LOAN_TYPES,
  JUMBO,
  LOAN_TYPE_CATEGORY_NAMES
} from "../../constants/loan_types";
import { ARM_TERMS } from "../../constants/loan_terms";
import LOAN_PURPOSE, {
  REFINANCE_LOAN_PURPOSES
} from "../../constants/loan_purpose";

import {
  ARM_CAPS,
  ARM_MARGINS,
  ARM_INDEX_TYPES
} from "../../constants/loan_arm_properties";

import TextField from "../fields/text_field";
import SelectField from "../fields/select_field";
import PercentField from "../fields/percent_field";
import NumberField from "../fields/number_field";
import { loan_arm_fix, loan_product } from "../../constants/utils";

import { useDocument } from "../loan_tools/loan_tool";
import Section from "../ui/section";
import { REFINANCE } from "../../constants/document_types";

export default function LoanTypeMainPage({
  showEditLoan,
  onChangeAllLoanTypesTheSame,
  onDeleteLoan
}) {
  const [document, , { documentType }] = useDocument();

  const { loans, applicant } = document;
  const isRefinance = documentType === REFINANCE;
  const loanColumns = [];
  const maxLoanCount = applicant.all_loan_types_the_same === YES ? 1 : 5;

  for (let index = 0; index < maxLoanCount; index++) {
    if (loans[index] && loans[index].type) {
      loanColumns.push(renderLoan(loans[index], index));
    } else {
      loanColumns.push(renderNoLoan(index));
    }
  }

  function edit(e) {
    const index = new Decimal(e.target.dataset.index);
    showEditLoan(Math.min(loans.length, index.toNumber()));
  }

  function deleteLoan(e) {
    const index = new Decimal(e.target.dataset.index);
    onDeleteLoan(index.toNumber());
  }

  function renderLoan(loan, index) {
    const {
      arm_caps,
      arm_index_percent,
      arm_index_type,
      arm_margin,
      arm_years,
      jumbo_arm_months_of_reserves,
      jumbo_months_of_reserves,
      jumbo_reserves_for_other_properties,
      purpose,
      term,
      type
    } = loan;
    const isARMLoan = ARM_TERMS.includes(loan.term);
    const product = loan_product(loan.term);
    const armFix = loan_arm_fix(loan.term);
    const jumboLoanReserves =
      type === JUMBO ? jumbo_reserves_required_total(loan) : "0";
    const allLoansTheSame = document.applicant.all_loan_types_the_same === YES;

    let title = `Loan ${index + 1}`;

    if (allLoansTheSame && index === 0) {
      title = "Loans 1-5";
    }

    return (
      <div key={index} className="fifth-width">
        <div className="loan-type-header">{title}</div>

        <TextField
          name="Loan Type"
          readOnly
          value={isRefinance ? REFINANCE_LOAN_TYPES[type] : LOAN_TYPES[type]}
        />

        <TextField readOnly name="Product" value={product} />
        <TextField readOnly name="ARM/Fix" value={armFix} />
        <TextField
          name="Loan Type"
          readOnly
          value={LOAN_TYPE_CATEGORY_NAMES[type]}
        />

        {isARMLoan && (
          <div>
            <TextField name="ARM Caps" readOnly value={ARM_CAPS[arm_caps]} />

            <TextField
              name="ARM Margin"
              readOnly
              value={ARM_MARGINS[arm_margin]}
            />

            <NumberField name="Years" readOnly value={arm_years} />

            <TextField
              name="ARM Index Type"
              readOnly
              value={ARM_INDEX_TYPES[arm_index_type]}
            />

            <PercentField
              name="ARM Index"
              fixedDigits={3}
              readOnly
              value={arm_index_percent}
            />
          </div>
        )}

        <TextField
          name="Purpose"
          readOnly
          value={
            isRefinance
              ? REFINANCE_LOAN_PURPOSES[purpose]
              : LOAN_PURPOSE[purpose]
          }
        />

        {type === JUMBO && (
          <div>
            <NumberField
              name="Mo. Of Reserves"
              readOnly
              value={jumbo_months_of_reserves}
            />
            <NumberField
              name="Reserves for other properties"
              readOnly
              value={jumbo_reserves_for_other_properties}
            />
            {ARM_TERMS.includes(term) && (
              <NumberField
                name="Mo. of Reserves ARM Loans"
                readOnly
                value={jumbo_arm_months_of_reserves}
              />
            )}
            <NumberField
              name="Total of reserves required"
              readOnly
              value={jumboLoanReserves}
            />
          </div>
        )}
        <button className="button" data-index={index} onClick={edit}>
          Edit
        </button>
        <button className="button" data-index={index} onClick={deleteLoan}>
          Delete
        </button>
      </div>
    );
  }

  function renderNoLoan(key) {
    const allLoansTheSame = document.applicant.all_loan_types_the_same === YES;
    let title = `Loan ${key + 1}`;
    if (allLoansTheSame && key === 0) {
      title = "Loans 1-5";
    }

    return (
      <div key={key} className="fifth-width">
        <div className="loan-type-header">{title}</div>
        <div>Loan Not Set</div>
        <button className="button" data-index={key} onClick={edit}>
          Add loan
        </button>
      </div>
    );
  }

  return (
    <div className="loan-type-main-page">
      <div className="half-width">
        <SelectField
          name="Are all loans the same type?"
          fieldName="all_loan_types_the_same"
          selectOptions={YES_NO}
          value={applicant.all_loan_types_the_same}
          onChange={onChangeAllLoanTypesTheSame}
        />
      </div>
      <Section className="loan-type-section" name="Loan Type">
        {loanColumns}
      </Section>
    </div>
  );
}
