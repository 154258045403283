export const default_loan = {
  account_dispositions: {},
  additional_monthly_fees: "0",
  arm_alt_index_type: "",
  arm_caps: "",
  arm_index_percent: "0",
  arm_index_type: "",
  arm_margin: "",
  arm_rate_floor: "0",
  // ARM loans are always 30 years.
  arm_years: "30",
  balloon_payment: "",
  balloon_payment_period: "",
  base_fee: "0",
  brokered_loan: "",
  // TODO ADD cash down input
  cash_down: "0",
  display_fee: "",
  down_payment_type: "",
  emd: "0",
  emd_amount: "0",
  escrow_flat_fee: "0",
  escrow_loan_amount_fee: "0",
  escrow_services_by: "",
  fixed_loan_amount: "0",
  grand_deed: "0",
  hoa1: "0",
  hoa1_frequency: "",
  hoa2: "0",
  hoa2_frequency: "",
  mello_roos_amount: "0",
  mello_roos_frequency: "",
  in_out_trust: "0",
  interest_only_period: "",
  is_ufmip_financed: "",
  jumbo_arm_months_of_reserves: "0",
  jumbo_months_of_reserves: "0",
  second_mortgage_loan_percent_or_amount: "",
  second_mortgage_loan_term_monthly: "",
  second_mortgage_loan_amount: "0",
  second_mortgage_loan_percent: "0",
  second_mortgage_loan_rate_type: "",
  second_mortgage_loan_index: "0",
  second_mortgage_loan_margin: "0",
  second_mortgage_fixed_rate: "0",
  jumbo_reserves_for_other_properties: "0",
  lender: "",
  lender_program: "",
  loan_type: "",
  mortgage_insurance_is_monthly: "",
  mortgage_insurance_fha_rate: "0",
  mortgage_insurance_usda_rate_factor_pmi: "0",
  mortgage_insurance_is_lender_paid: "",
  offer_price: "0",
  origination_fee_paid_by: "",
  payment_type: "",
  percent_down: "0",
  prepayment_penalty: "",
  prepayment_terms: "",
  product: "",
  property_type: "",
  purpose: "",
  qualifying_arm_interest_rate: "0",
  rate: "0",
  rate_calculator: {
    base_rate: "0",
    bank_statements: "0",
    term_add_on: "0",
    subordinate_second: "0",
    full_doc: "0",
    dti_add: "0",
    asset_depletion: "0",
    cash_out: "0",
    loan_amount: "0",
    other: "0",
    other_two: "0"
  },
  seller_concessions: "0",
  seller_concessions_amount: "0",
  term: "",
  title_services_by: "",
  trust_deed: "0",
  type: "",
  type_name: "",
  va_add_to_loan: "0",
  va_upfront_funding_fee: "",
  loan_options: {
    agency_plus_super_conforming: "0",
    agency_plus_super_conforming_type: "",
    arm_add_on_fee: "0",
    broker_banker_fee: "0",
    broker_special: "0",
    cash_out: "0",
    refinance_cash_out: "0",
    refinance_cash_out_high_balance_loans: "0",
    condominium_add_on_fee: "0",
    condominium_add_on_fee_type: "",
    du_lp_refi_plus_add_on_fee: "0",
    du_lp_refi_plus_add_on_fee_type: "",
    fico_ltv_adjust: "0",
    freddiemac_add_on_fee: "0",
    freddiemac_add_on_fee_type: "0",
    high_balance_loan_amount: "0",
    interest_only: "0",
    investment_property_add_on_fee: "0",
    investment_property_add_on_fee_type: "",
    loan_amount: "0",
    lock_period_adjust: "0",
    lpmi_adjustment_base_loan: "0",
    manufactured_modular_add_on_fee: "0",
    miscellaneous_fee: "0",
    multi_unit_add_on_fee: "0",
    multi_unit_add_on_fee_type: "",
    no_impounds: "0",
    non_owner: "0",
    over_conforming_limit: "0",
    purchase_special: "0",
    r_t_refinance: "0",
    second_home: "0",
    subordanite_add_on_fee: "0",
    subordanite_add_on_fee_type: "",
    state_of_zone_add: "0",
    term_25_years_and_less: "0",
    loan_amount_greater_than_424100: "0"
  },
  other_adjustments: {
    borrower_paid_fees: "0",
    cash_deposit_on_sales_contract: "0",
    employer_assisted_housing: "0",
    lease_purchase_fund: "0",
    lender_credit: "0",
    relocation_funds: "0",
    seller_credit: "0",
    miscellaneous_credit_one: "0",
    miscellaneous_credit_two: "0",
    miscellaneous_credit_three: "0"
  },
  origination_fees: {},
  services_you_cannot_shop_for: {},
  services_you_can_shop_for: {},
  transfer_taxes: {
    percent_split: "0",
    base_value_k: "0",
    county_base_tax_k: "0",
    city_base_tax_k: "0"
  },
  prepaids_homeowners_insurance_premium_months: "0",
  prepaids_mortgage_insurance_premium_months: "0",
  prepaids_property_taxes_months: "0",
  prepaids_real_estate_taxes_due: "0",
  prepaids: {
    days_of_interest: "360",
    per_days: "15"
  },
  initial_escrow_payment_at_closing_homeowners_insurance_months: "0",
  initial_escrow_payment_at_closing_mortgage_insurance_months: "0",
  initial_escrow_payment_at_closing_property_taxes_months: "0",
  other_services: {},
  funds_for_borrower: "0",
  trid: {
    assumption: "",
    servicing: ""
  },
  will_second_subordinate: "",
  will_third_subordinate: "",
  refinance_loan_calculator: {
    total_of_new_first_loan: "0",
    prepay_penalty: "0",
    closing_cost_pad: "0"
  },
  rate_and_term_refi: "",
  refinance_cash_out_add_to_loan_amount_percent: "0",
  refinance_cash_out_add_to_loan_amount: "0",
  max_cash_back_rate_term_refi_va_fha: "0",
  max_cash_out_at_coe: "0",
  ca_affordable_housing_recording_fee_co_sur_charge: "0"
};
