import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import "./css/loan_picker_navigation.scss";
import { class_list, get_bounding_offset_rect } from "../../constants/utils";
import SaveLoan from "../../components/loan_tools/save_loan";
import { useDocument } from "./loan_tool";
import Decimal from "decimal.js";

export default function LoanPickerNavigation({
  currentLoanIndex,
  setLoanIndex,
  onLoanChange
}) {
  const [
    document,
    ,
    { documentId },
    { saveDocument, setNavConnectorLinePositionTwo }
  ] = useDocument();
  const loanRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const [bodyHeight, setBodyHeight] = useState(0);

  useLayoutEffect(() => {
    updateNavLoan();
  }, [currentLoanIndex]);

  useLayoutEffect(() => {
    const height = window.document.body.offsetHeight;
    if (bodyHeight !== height) {
      setBodyHeight(height);
      updateNavLoan();
    }
  });

  useEffect(() => {
    window.addEventListener("resize", updateNavLoan);

    return () => {
      window.removeEventListener("resize", updateNavLoan);
    };
  }, [currentLoanIndex]);

  function updateNavLoan() {
    setNavConnectorLinePositionTwo(
      get_bounding_offset_rect(loanRefs[currentLoanIndex].current)
    );
  }

  function switchLoanTab(e) {
    const loanIndex = new Decimal(e.target.dataset.index).toNumber();
    if (loanIndex === currentLoanIndex) {
      return;
    }
    setLoanIndex(loanIndex);
    if (onLoanChange) {
      onLoanChange();
    }
    saveDocument();
  }

  return (
    <div className="loan-picker-navigation">
      {document.loans.map((loan, index) => (
        <div
          ref={loanRefs[index]}
          key={index}
          data-index={index}
          className={class_list(
            "loan-tab",
            index === currentLoanIndex && "active",
            index < currentLoanIndex && "marked"
          )}
          onClick={switchLoanTab}
        >
          Loan {index + 1}
          <div className="nav-connector" />
          <div className="nav-arrow">
            <div className="arrow-body" />
            <div className="arrow-head" />
          </div>
        </div>
      ))}
      <div className="loan-tab-shell">
        <SaveLoan
          className="button"
          name="Summary Sheet"
          path={`/loan-tool/${documentId}/summary-sheet`}
        />
        <div className="nav-connector" />
      </div>
    </div>
  );
}
