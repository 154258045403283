import React from "react";
import Decimal from "decimal.js";

import PROPERTY_CLASSIFICATION, {
  INVESTMENT_PROPERTY as INVESTMENT_PROPERTY_CLASSIFICATION,
  PRIMARY_RESIDENCE
} from "../../constants/property_classification";
import TITLE_VESTING from "../../constants/title_vesting";
import IMPOUNDS_FOR_LOAN from "../../constants/impounds_for_loan";
import OCCUPANCY from "../../constants/occupancy";
import FREQUENCY from "../../constants/frequencies";
import PROPERTY_TYPES from "../../constants/property_type";
import EXPECTANCY from "../../constants/expectancy";
import PURPOSE_OF_PURCHASE from "../../constants/purpose_of_purchase";
import PURPOSE_OF_REFINANCE from "../../constants/purpose_of_refinance";
import SOLAR_SYSTEM_TYPES from "../../constants/solar_system_type";
import { YES_NO_TBD, YES } from "../../constants/yes_no";

import CurrencyField from "../../components/fields/currency_field";
import DateField from "../../components/fields/date_field";
import PercentField from "../../components/fields/percent_field";
import SelectField from "../../components/fields/select_field";
import TextField from "../../components/fields/text_field";

import StandardTemplate from "../../templates/standard_template/standard_template";

import "./css/new_property.scss";
import {
  property_tax_amount,
  estimated_loan_amount,
  down_payment
} from "../../calculations/investment_reo";
import { deep_clone_simple, show_hoas } from "../../constants/utils";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { NEW_PROPERTY, SUBJECT_PROPERTY } from "../../constants/navigations";
import Section from "../../components/ui/section";
import TrainingTip from "../../components/ui/training_tip";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import SaveLoan from "../../components/loan_tools/save_loan";
import { default_property } from "../../schema/investment_property";

export default function NewPropertyPage() {
  const [document, setDocument, { documentType }] = useDocument();

  const { property } = document;
  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;
  const isPrimaryResidence = property.classification === PRIMARY_RESIDENCE;

  function onChangeProperty(newState) {
    const property = {
      ...document.property,
      ...newState
    };
    setDocument({ ...document, property });
  }

  function onChangePropertyAndInvestmentProperties(newState, investment_properties) {
    const property = {
      ...document.property,
      ...newState
    };
    setDocument({ ...document, property, investment_properties });
  }

  function onChangePropertyClassification(newClassification) {
    const newState = { ...newClassification };
    let updatedProperties;
    if (isRefinance) {
      const wasInvestmentProperty = document.property.classification === INVESTMENT_PROPERTY_CLASSIFICATION;
      const willBeInvestmentProperty = newClassification.classification === INVESTMENT_PROPERTY_CLASSIFICATION;
      if (wasInvestmentProperty && !willBeInvestmentProperty) {
        const investmentPropertyDisplayName = PROPERTY_CLASSIFICATION[INVESTMENT_PROPERTY_CLASSIFICATION];
        // The following uses `confirm`, which is a restricted global. For the time being we're going to use it,
        // which ESLint doesn't like. The line below tells ESLint to ignore the rule.
        // eslint-disable-next-line no-restricted-globals
        const areYouSure = confirm(`If the subject property type changes away from ${investmentPropertyDisplayName},` +
            " the investment REO representing this property will be deleted. Are you sure?");
        if (!areYouSure) {
          return;
        } else {
          updatedProperties = document.investment_properties.filter(property => {
            return property.is_subject_property !== YES;
          });
        }
      }
      if (!wasInvestmentProperty && willBeInvestmentProperty) {
        const alreadyHasSubjectPropertyREO = document.investment_properties.some(property => {
          return property.is_subject_property === YES;
        });
        if (!alreadyHasSubjectPropertyREO) {
          const investmentReo = deep_clone_simple(default_property);
          investmentReo.is_subject_property = YES;
          const investment_properties = document.investment_properties.slice(0);
          investment_properties.unshift(investmentReo);
          updatedProperties = investment_properties;
        }
      }
      const borrower = document.applicant.borrowers[0];
      if (newState.classification === PRIMARY_RESIDENCE && borrower) {
        newState.address = borrower.current_address;
        newState.address2 = borrower.current_address_2;
        newState.city = borrower.city;
        newState.state = borrower.state;
        newState.zip = borrower.zip_code;
      }
    }
    if (!updatedProperties) {
      onChangeProperty(newState);
    } else {
      onChangePropertyAndInvestmentProperties(newState, updatedProperties);
    }
  }

  return (
    <StandardTemplate active={isRefinance ? SUBJECT_PROPERTY : NEW_PROPERTY}>
      <div className="new-property">
        <Section
          name={
            isRefinance
              ? "Subject Property Information"
              : "New Property Information"
          }
        >
          <div className="flex new-property-top">
            <SelectField
              containerClassname="half-width"
              fieldName="classification"
              name={
                isRefinance
                  ? "What Property are you Refinancing"
                  : "What Property are you Purchasing"
              }
              value={property.classification}
              selectOptions={PROPERTY_CLASSIFICATION}
              onChange={onChangePropertyClassification}
              showDefaultOption
            />
            <SelectField
              containerClassname="half-width"
              fieldName="expectancy"
              name="How many years do you plan to keep this home"
              value={property.expectancy}
              onChange={onChangeProperty}
              selectOptions={EXPECTANCY}
            />
          </div>
          <div className="flex">
            <div className="third-width">
              <TextField
                fieldName="address"
                name="Address"
                value={property.address}
                onChange={onChangeProperty}
                readOnly={isRefinance && isPrimaryResidence}
              />
              <TextField
                fieldName="address2"
                name="Address 2"
                value={property.address2}
                onChange={onChangeProperty}
                readOnly={isRefinance && isPrimaryResidence}
              />
              <TextField
                fieldName="city"
                name="City"
                value={property.city}
                onChange={onChangeProperty}
                readOnly={isRefinance && isPrimaryResidence}
              />
              <TextField
                fieldName="state"
                name="State"
                value={property.state}
                onChange={onChangeProperty}
                readOnly={isRefinance && isPrimaryResidence}
              />
              <TextField
                fieldName="zip"
                name="Zip"
                value={property.zip}
                onChange={onChangeProperty}
                readOnly={isRefinance && isPrimaryResidence}
              />
              <SelectField
                fieldName="title_vesting"
                name="Title Vesting"
                value={property.title_vesting}
                onChange={onChangeProperty}
                selectOptions={TITLE_VESTING}
                sortLabel
              />

              <SelectField
                fieldName="will_home_be_in_trust"
                name="Will Property be in a Trust"
                value={property.will_home_be_in_trust}
                onChange={onChangeProperty}
                title="LANIS Training Tip: If the home is in a Trust, you will need to see how it is currently recorded on the property. Pull a Property Profile so you have the full Trust name and who signed the Grant Deed. Send the Grant Deed to escrow when you set up the file. Remember to add additional filing fees in Section E of the Loan Estimate. Remember to add the fee to put the home back into the Trust at the COE."
                selectOptions={YES_NO_TBD}
              />

              {isPurchase && (
                <SelectField
                  fieldName="purpose_of_purchase"
                  name="Purpose of Purchase"
                  value={property.purpose_of_purchase}
                  onChange={onChangeProperty}
                  title="LANIS Training Tip: This is Very Important: If you have a Buyer(s) where the Parents are buying for special needs Children or Children are buying for a Parent(s), there may not be any add on fees because it is NOT considered an Investment Property. Check with your Lender for details."
                  selectOptions={PURPOSE_OF_PURCHASE}
                />
              )}
              {isRefinance && (
                <SelectField
                  fieldName="purpose_of_refinance"
                  name="Purpose of Refinance"
                  value={property.purpose_of_refinance}
                  onChange={onChangeProperty}
                  selectOptions={PURPOSE_OF_REFINANCE}
                />
              )}

              <SelectField
                fieldName="impounds_for_loan"
                name="Impounds for Loan"
                value={property.impounds_for_loan}
                onChange={onChangeProperty}
                selectOptions={IMPOUNDS_FOR_LOAN}
              />
              {isPurchase && (
                <CurrencyField
                  fieldName="preferred_monthly_payment"
                  name="Preferred Mo. Payment (PITI)"
                  value={property.preferred_monthly_payment}
                  onChange={onChangeProperty}
                />
              )}
              <div className="flex-space-between align-items-center">
                <div>
                  Look up {isPurchase ? "New" : ""} Property Profile{" "}
                  <TrainingTip content="LANIS Training Tip: You should look up the Property Profile of the Subject Property to see the History of the property and if the Mortgages or Liens on the property would make it a short sale. If there is anything recorded on the property such as a lien, that could take time to resolve and be removed. Also double-check the City, and how Title is held by the Sellers vs. the Contract names. Get a Prelim Title Report as soon as possible or call your Title Company for a deep search of the property." />
                </div>
                <a
                  className="button inverse-button"
                  href="https://www.titleprofile.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Title Profile
                </a>
              </div>
              <div className="flex-space-between align-items-center">
                <div>
                  Look up {isPurchase ? "New" : ""} Market Value{" "}
                  <TrainingTip content="LANIS Training Tip: You should look up the Market Value of the Subject Property to see if the Sales price matched the Comps for the home. If not, you may have an issue with the LTV/CLTV which will affect the Pricing of your loan. Also, if the Sales price of the home is Over Priced the Appraisal could come in low and there may not be a cure so your Loan will not go through. Talk with the Buyer’s Agent to see how to resolve it. Check the Borrower(s)’ reserves to see if they could come in with more funds to close." />
                </div>
                <a
                  className="button inverse-button"
                  href="https://www.eppraisal.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AVM
                </a>
              </div>
            </div>

            <div className="third-width">
              <SelectField
                fieldName="occupancy"
                name="Occupancy"
                value={property.occupancy}
                onChange={onChangeProperty}
                title="LANIS Training Tip: If this is a Non-Owner property and the LTV is over 75.01%, the add-on fee could be much higher (>1.0%) and needs to be disclosed. Check the Lenders pricing engine or the rate sheet to make sure you have accurate pricing."
                selectOptions={OCCUPANCY}
              />
              <SelectField
                fieldName="property_type"
                name="Property Type"
                value={property.property_type}
                onChange={onChangeProperty}
                selectOptions={PROPERTY_TYPES}
              />
              {show_hoas(property) && (
                <CurrencyField
                  fieldName="hoa1"
                  name="HOA 1"
                  value={property.hoa1}
                  onChange={onChangeProperty}
                  title="LANIS Training Tip: (Note: There can be a 2nd HOA or Mello Roos fees as well).  If the Subject Property is a home in a Mobil Home Park, the HOA fees may be Space Rental fees. Also, there may be multiple park fees which must be included in the DTI Calculations."
                />
              )}
              {show_hoas(property) && (
                <SelectField
                  fieldName="hoa1_frequency"
                  name="HOA 1 Frequency"
                  value={property.hoa1_frequency}
                  onChange={onChangeProperty}
                  selectOptions={FREQUENCY}
                />
              )}
              {show_hoas(property) && (
                <CurrencyField
                  fieldName="hoa2"
                  name="HOA 2"
                  value={property.hoa2}
                  onChange={onChangeProperty}
                />
              )}
              {show_hoas(property) && (
                <SelectField
                  fieldName="hoa2_frequency"
                  name="HOA 2 Frequency"
                  value={property.hoa2_frequency}
                  onChange={onChangeProperty}
                  selectOptions={FREQUENCY}
                />
              )}
              {show_hoas(property) && (
                <SelectField
                  fieldName="mello_roos"
                  name="Mello Roos"
                  value={property.mello_roos}
                  onChange={onChangeProperty}
                  selectOptions={YES_NO_TBD}
                />
              )}
              {show_hoas(property) && property.mello_roos === "2" && (
                <CurrencyField
                  fieldName="mello_roos_amount"
                  name="Amount"
                  value={property.mello_roos_amount}
                  onChange={onChangeProperty}
                />
              )}
              {show_hoas(property) && property.mello_roos === "2" && (
                <SelectField
                  fieldName="mello_roos_frequency"
                  name="Frequency"
                  value={property.mello_roos_frequency}
                  onChange={onChangeProperty}
                  selectOptions={FREQUENCY}
                />
              )}
              {isRefinance && (
                <CurrencyField
                  fieldName="principal_balance"
                  name="Principal Balance"
                  value={!isPrimaryResidence
                      ? property.principal_balance
                      : new Decimal(document.property_to_be_sold.first_loan_balance)
                          .plus(document.property_to_be_sold.second_loan_balance)}
                  onChange={onChangeProperty}
                  readOnly={isPrimaryResidence}
                />
              )}
              {isRefinance && (
                <CurrencyField
                  fieldName="first_mortgage"
                  name="1st Mortgage"
                  value={!isPrimaryResidence
                      ? property.first_mortgage
                      : document.property_to_be_sold.first_current_monthly_payment_principal}
                  onChange={onChangeProperty}
                  readOnly={isPrimaryResidence}
                />
              )}
              {isRefinance && (
                <CurrencyField
                  fieldName="second_mortgage"
                  name="2nd Mortgage"
                  value={!isPrimaryResidence
                      ? property.second_mortgage
                      : document.property_to_be_sold.second_current_monthly_payment}
                  onChange={onChangeProperty}
                  readOnly={isPrimaryResidence}
                />
              )}
              {isPurchase && (
                <div className="flex-space-between align-items-center">
                  <div>Who is the Buyer&apos;s Agent</div>
                  <SaveLoan
                    className="button"
                    name="Buyer's RE Agent Info"
                    disabled
                  />
                </div>
              )}
              {/*<div className="flex-space-between align-items-center">*/}
              {/*<div>Who is the Escrow Officer</div>*/}
              {/*<SaveLoan*/}
              {/*className="button"*/}
              {/*name="Escrow Officer Info"*/}
              {/*disabled*/}
              {/*/>*/}
              {/*</div>*/}
            </div>

            <div className="third-width">
              <CurrencyField
                fieldName="listed_price"
                name={isPurchase ? "Listed / Sales Price" : "Market Value"}
                title="LANIS Training Tip: If you need to look up the market Value using an AVM,Please click on the AVM button above or click on the Loan Officer Tools button and pick your favorite AVM company Company. If it's a difficult property, you may need to pull MLS Comps and your favorite Realtor can help. If you belong to the MLS, pull the comps to be sure of the value."
                value={property.listed_price}
                onChange={onChangeProperty}
              />
              <PercentField
                fieldName="target_ltv"
                name="Target LTV"
                value={property.target_ltv}
                onChange={onChangeProperty}
              />
              <CurrencyField
                readOnly
                fieldName="estimated_loan_amount"
                name="Estimated Loan Amount"
                title="LANIS Training Tip: Freddie Mac Home Possible is capped at 453,100, Fannie Mae HOME READY on the other hand does have High balance available to county limit."
                value={estimated_loan_amount(property)}
              />
              {isPurchase && (
                <CurrencyField
                  readOnly
                  name="Down Payment"
                  value={down_payment(property)}
                />
              )}
              <div>
                <a
                  className="button inverse-button"
                  href="https://www.fhfa.gov/DataTools/Downloads/Documents/Conforming-Loan-Limits/FullCountyLoanLimitList2021_HERA-BASED_FINAL_FLAT.PDF"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Loan Limits Per County
                </a>{" "}
                <TrainingTip content="The FHFA's loan limits define the maximum amount that Fannie and Freddie can finance for a one-unit single-family home.  In most of the country, the 2021 maximum conforming loan limit for single-family properties will be $548,250. For High Balance Loan Limits, click on the link and see what is available in your County / State." />
              </div>
              {new Decimal(property.target_ltv).gte(95) && (
                <div>
                  <a
                    className="button inverse-button"
                    href="http://www.freddiemac.com/homepossible/eligibility.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home Possible Advisor
                  </a>
                </div>
              )}
              {isPurchase && (
                <>
                  <PercentField
                    fieldName="property_tax_percent"
                    name="Property Tax %"
                    value={property.property_tax_percent}
                    onChange={onChangeProperty}
                  />
                  <CurrencyField
                    readOnly
                    fieldName="property_tax_amount"
                    name="Property Tax Amount"
                    value={property_tax_amount(documentType, property)}
                  />
                </>
              )}
              {isRefinance && (
                <>
                  <CurrencyField
                    fieldName="refinance_property_tax_amount"
                    name="Property Tax Amount"
                    value={property.refinance_property_tax_amount}
                    onChange={onChangeProperty}
                  />
                  <SelectField
                    fieldName="refinance_property_tax_frequency"
                    name="Frequency"
                    value={property.refinance_property_tax_frequency}
                    onChange={onChangeProperty}
                    selectOptions={FREQUENCY}
                  />
                </>
              )}
              <CurrencyField
                fieldName="home_owners_insurance"
                name="Home Owners Insurance"
                value={property.home_owners_insurance}
                onChange={onChangeProperty}
              />
              {isRefinance && (
                <SelectField
                  fieldName="home_owners_insurance_frequency"
                  name="Frequency"
                  value={property.home_owners_insurance_frequency}
                  onChange={onChangeProperty}
                  selectOptions={FREQUENCY}
                />
              )}
              <SelectField
                fieldName="escrow_opened"
                name="Escrow Opened"
                value={property.escrow_opened}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />
              <SelectField
                fieldName="different_escrow_title"
                name="Different Escrow/Title"
                title="LANIS Training Tip: If you have a different Escrow, Closing Agent or Title Company, there will be additional Fees in Section C, Page 2 of the LE fees that will apply. You will need to disclose these fees to the Borrower(s) to be accurate. Tolerance Level in Section C is 10%."
                value={property.different_escrow_title}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />

              <DateField
                fieldName="close_of_escrow_date"
                name="Close of Escrow Date"
                value={property.close_of_escrow_date}
                onChange={onChangeProperty}
              />
              <SelectField
                fieldName="time_sensitive"
                name="Time Sensitive"
                title='LANIS Training Tip: If this is a Time-Sensitive Purchase, select "Yes" and it will be added to the Top of your Pipeline Report.'
                value={property.time_sensitive}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />
            </div>
          </div>
        </Section>
        <Section
          className="important-questions-to-ask"
          name={
            "Important Questions to Ask for On-Time Close" +
            (isPurchase ? " For the New Home" : "")
          }
        >
          <div className="flex">
            <div className="third-width">
              <SelectField
                fieldName="solar_system"
                name="Is there a Solar System"
                title="LANIS Training Tip: If there is a Solar System on the Subject Property and it's Leased or an Installment sale, removing the UCC or Subordinating the Lien may take as much as 2 - 3 weeks to resolve this issue. Do Not wait, check it out upfront with the Buyer’s Agent. If you don't it could delay the close of your loan because Your Lender does not want to be in 2nd Position. They will not Fund the loan until they are assured they will be in 1st position."
                value={property.solar_system}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />
              {property.solar_system === YES && (
                <SelectField
                  fieldName="solar_system_type"
                  name="Purchased or Leased"
                  value={property.solar_system_type}
                  onChange={onChangeProperty}
                  selectOptions={SOLAR_SYSTEM_TYPES}
                />
              )}
              {property.solar_system === YES && (
                <CurrencyField
                  fieldName="solar_system_amount"
                  name="Amount per Month"
                  value={property.solar_system_amount}
                  onChange={onChangeProperty}
                />
              )}
            </div>
            <div className="third-width">
              <SelectField
                fieldName="loans_or_liens"
                name={
                  "Loans or Liens on " + (isPurchase ? "New " : "") + "Home"
                }
                title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                value={property.loans_or_liens}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />
              {property.loans_or_liens === YES && (
                <SelectField
                  fieldName="hero_pace_loans_on_the_property"
                  name="HERO/Pace Loans on Home"
                  title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                  value={property.hero_pace_loans_on_the_property}
                  onChange={onChangeProperty}
                  selectOptions={YES_NO_TBD}
                />
              )}
              {property.loans_or_liens === YES && (
                <SelectField
                  fieldName="tax_liens_state_or_federal"
                  name="Tax Liens, State or Federal"
                  title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                  value={property.tax_liens_state_or_federal}
                  onChange={onChangeProperty}
                  selectOptions={YES_NO_TBD}
                />
              )}
            </div>
            <div className="third-width">
              {isPurchase && (
                <SelectField
                  fieldName="reo_or_bank_owned"
                  name="REO or Bank Owned"
                  title="LANIS Training Tip: REO properties can take longer to close than a normal transaction. Ask the Listing Agent what the typical process flow is for the specific Lender. Some Lenders can close in 4 weeks and others as long as 3 months. This will affect your lock pricing in the LE Price Engine. Discuss the pricing options with the Borrower(s)."
                  value={property.reo_or_bank_owned}
                  onChange={onChangeProperty}
                  selectOptions={YES_NO_TBD}
                />
              )}
              <SelectField
                fieldName="in_foreclosure"
                name="Is the Home in Foreclosure"
                title="LANIS Training Tip: REO properties can take longer to close than a normal transaction. Ask the Listing Agent what the typical process flow is for the specific Lender. Some Lenders can close in 4 weeks and others as long as 3 months. This will affect your lock pricing in the LE Price Engine. Discuss with the Borrower(s) the pricing options."
                value={property.in_foreclosure}
                onChange={onChangeProperty}
                selectOptions={YES_NO_TBD}
              />
            </div>
          </div>
        </Section>
      </div>
    </StandardTemplate>
  );
}
