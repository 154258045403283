import React from "react";
import CurrencyField from "../../fields/currency_field";
import OTHER_SERVICES from "../../../constants/other_services";
import LoanFees from "./loan_fees";
import { payoff_request_fee, total_other } from "../../../calculations/loan";
import { useDocument } from "../loan_tool";
import { REFINANCE } from "../../../constants/document_types";

export default function Other({
  loan,
  removeOtherServices,
  updateOtherServices,
  readOnly
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isRefinance = documentType === REFINANCE;

  return (
    <div className="other">
      <div className="closing-costs-row top-border gray-bg">
        <div className="section-title">H. Other</div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_other(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      {isRefinance && (
        <div className="closing-costs-row">
          <div>Payoff Request Fee</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={payoff_request_fee(property_to_be_sold, is_selling_property)}
          />
        </div>
      )}
      <LoanFees
        loanFees={loan.other_services}
        allFees={OTHER_SERVICES}
        removeFee={removeOtherServices}
        updateFees={updateOtherServices}
        readOnly={readOnly}
      />
    </div>
  );
}
