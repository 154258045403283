import React, { useState, useEffect } from "react";
import "./css/stripe_card_checkout.scss";

import { CardElement, Elements, injectStripe } from "react-stripe-elements";
import TextField from "../fields/text_field";
import PhoneField from "../fields/phone_field";

function CheckoutElement({
  stripe,
  elements,
  onBeforeCreatePaymentMethod,
  onSubmit,
  onError,
  isPaymentSkipped,
  disabled,
  paymentName,
  setPaymentName,
  paymentEmail,
  setPaymentEmail,
  paymentPhone,
  setPaymentPhone,
  promoCode,
  setPromoCode,
  profile
}) {
  const [paymentNameTouched, setPaymentNameTouched] = useState(false);
  const [paymentEmailTouched, setPaymentEmailTouched] = useState(false);
  const [paymentPhoneTouched, setPaymentPhoneTouched] = useState(false);

  // Until the user modifies the "Name on card" field, make it mirror the
  // "full name" field from the profile. Same for email and phone.
  useEffect(() => {
    if (!paymentNameTouched && profile.full_name) {
      setPaymentName(profile.full_name);
    }
  }, [profile, paymentNameTouched]);

  useEffect(() => {
    if (paymentEmail !== undefined && !paymentEmailTouched && profile.email) {
      setPaymentEmail(profile.email);
    }
  }, [profile, paymentEmail, paymentEmailTouched]);

  useEffect(() => {
    if (paymentPhone !== undefined && !paymentPhoneTouched && profile.cell_phone) {
      setPaymentPhone(profile.cell_phone);
    }
  }, [profile, paymentPhone, paymentPhoneTouched]);

  function handleNameChange(value) {
    setPaymentNameTouched(true);
    setPaymentName(value.name);
  }

  function handleEmailChange(value) {
    setPaymentEmailTouched(true);
    setPaymentEmail(value.email);
  }

  function handlePhoneChange(value) {
    setPaymentPhoneTouched(true);
    setPaymentPhone(value.phone);
  }

  async function handleSubmit(ev) {
    // We don't want to let default form submission happen here, which
    // would refresh the page.
    ev.preventDefault();

    if (onBeforeCreatePaymentMethod) {
      onBeforeCreatePaymentMethod();
    }

    // These aren't really a secret, so
    if (typeof isPaymentSkipped === "function" && await isPaymentSkipped(promoCode)) {
      await onSubmit();
    } else {
      await stripe
          .createPaymentMethod({
            type: "card",
            card: elements.getElement("card"),
            billing_details: {
              name: paymentName,
              email: paymentEmail || profile.email,
              phone: paymentPhone || profile.cell_phone
            }
          })
          .then(result => {
            if (result.error && onError) {
              return onError(result.error);
            } else if (onSubmit) {
              return onSubmit(result.paymentMethod.id);
            }
          });
    }
  }

  function submitDisabled() {
    return (
      disabled ||
      !paymentName ||
      (paymentEmail !== undefined && !paymentEmail) ||
      (paymentPhone !== undefined && !paymentPhone)
    );
  }

  const cardStyle = {
    base: {
      lineHeight: "20px"
    }
  };

  return (
    <>
      <div className="stripe-checkout">
        <TextField
          placeholder="Name on card"
          fieldName="name"
          value={paymentName}
          onChange={handleNameChange}
        />
        {paymentEmail !== undefined && (
          <TextField
            typeOverride="email"
            placeholder="Email"
            fieldName="email"
            value={paymentEmail}
            onChange={handleEmailChange}
          />
        )}
        {paymentPhone !== undefined && (
          <PhoneField
            placeholder="Phone"
            fieldName="phone"
            value={paymentPhone}
            onChange={handlePhoneChange}
          />
        )}
        <CardElement className="card-element" style={cardStyle} />
        <TextField
          placeholder="Promo Code"
          fieldName="promo_code"
          className="promo-code"
          value={promoCode}
          onChange={value => setPromoCode(value.promo_code)}
        />
        <button
          className="button"
          onClick={handleSubmit}
          disabled={submitDisabled()}
        >
          Submit
        </button>
      </div>
    </>
  );
}

const InjectedCheckout = injectStripe(CheckoutElement);

export default function StripeCheckout({
  onBeforeCreatePaymentMethod,
  onSubmit,
  onError,
  isPaymentSkipped,
  disabled,
  paymentName,
  setPaymentName,
  paymentEmail,
  setPaymentEmail,
  paymentPhone,
  setPaymentPhone,
  promoCode,
  setPromoCode,
  profile
}) {
  return (
    <div>
      <Elements>
        <InjectedCheckout
          onBeforeCreatePaymentMethod={onBeforeCreatePaymentMethod}
          onSubmit={onSubmit}
          onError={onError}
          isPaymentSkipped={isPaymentSkipped}
          disabled={disabled}
          paymentName={paymentName}
          setPaymentName={setPaymentName}
          paymentEmail={paymentEmail}
          setPaymentEmail={setPaymentEmail}
          paymentPhone={paymentPhone}
          setPaymentPhone={setPaymentPhone}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          profile={profile}
        />
      </Elements>
    </div>
  );
}
