import React, { useEffect, useState, useRef } from "react";
import Decimal from "decimal.js";

import { loan_term, total_rate_calculator } from "../../../calculations/loan";

import { LOANS_WITH_RATE_CALCULATOR } from "../../../constants/loan_types";
import { ARM_TERMS } from "../../../constants/loan_terms";
import ORIGINATION_FEE_PAID_BY from "../../../constants/origination_fee_paid_by";
import LOAN_PAYMENT_TYPES, {
  INTEREST_ONLY,
  PAYMENT_PERIODS
} from "../../../constants/loan_payment_type";
import LENDERS from "../../../constants/lenders";
import LOAN_TYPES, {
  REFINANCE_LOAN_TYPES
} from "../../../constants/loan_types";
import PROPERTY_TYPES, {
  HOA_PROPERTY_TYPES
} from "../../../constants/property_type";
import FREQUENCIES from "../../../constants/frequencies";
import { YES, YES_NO } from "../../../constants/yes_no";

import LOAN_RATES from "../../../constants/loan_rates";
import NumberField from "../../fields/number_field";
import CurrencyField from "../../fields/currency_field";
import SelectField from "../../fields/select_field";
import TextField from "../../fields/text_field";
import PercentField from "../../fields/percent_field";

import ArmRateAdjustmentsTable from "./arm_rate_adjustments";
import PaymentAdjustmentTable from "./payment_adjustment_table";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import SaveLoan from "../save_loan";
import { REFINANCE } from "../../../constants/document_types";

const SORTED_LOAN_RATES = Object.entries(LOAN_RATES)
  .sort(([a], [b]) => {
    return new Decimal(a).greaterThan(b) ? 1 : -1;
  })
  .map(([, value]) => value);

const allInputFields = [
  "origination_fee_paid_by",
  "display_fee",
  "brokered_loan",
  "lender",
  "lender_program",
  "property_type",
  "hoa1",
  "hoa1_frequency",
  "hoa2",
  "hoa2_frequency",
  "mello_roos_amount",
  "mello_roos_frequency",
  "payment_type",
  "interest_only_period",
  "balloon_payment",
  "balloon_payment_period",
  "prepayment_penalty",
  "prepayment_terms"
];

const allRateCalculatorInputFields = [
  "base_rate",
  "bank_statements",
  "term_add_on",
  "subordinate_second",
  "full_doc",
  "dti_add",
  "asset_depletion",
  "cash_out",
  "loan_amount",
  "other",
  "other_two"
];

export default function EditLoanEstimate({
  onChangeLoan,
  showMainPage,
  loanIndex
}) {
  const [document, , { documentId, documentType }, { saveDocument }] = useDocument();
  const [loan, setLoan] = useState({ ...document.loans[loanIndex] });
  const {
    balloon_payment,
    balloon_payment_period,
    base_fee,
    brokered_loan,
    display_fee,
    interest_only_period,
    lender,
    lender_program,
    origination_fee_paid_by,
    payment_type,
    prepayment_penalty,
    prepayment_terms,
    rate,
    type
  } = loan;
  const rate_calculator = { ...loan.rate_calculator };
  const isArm = ARM_TERMS.includes(loan.term);
  const isRefinance = documentType === REFINANCE;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loan.is_modified) {
        if (isValidLoan()) {
          if (
            window.confirm(
              "You have unsaved data in Loan Form. Would you like to save it before you leave?"
            )
          ) {
            save();
          } else {
            //do nothing
          }
        } else {
          alert(
            "Not all necessary fields were entered. Therefore changes would be dismissed."
          );
        }
      }
    };
  }, [loan]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function onChange(updatedState) {
    setLoan({ ...loan, ...updatedState, is_modified: true });
  }

  function onChangeRateCalculator(updatedState) {
    const rateCalculator = {
      ...rate_calculator,
      ...updatedState
    };
    const updatedRate = total_rate_calculator(rateCalculator);
    setLoan({
      ...loan,
      rate_calculator: rateCalculator,
      rate: updatedRate,
      is_modified: true
    });
  }

  function save() {
    delete loan.is_modified;
    onChangeLoan(loan);
    saveDocument();
    showMainPage();
  }

  function validateNonEmptyString(value) {
    return value !== null && value !== undefined && value !== "";
  }

  function isValidLoan() {
    const validatedFields = [
      loan.origination_fee_paid_by,
      loan.display_fee,
      loan.brokered_loan,
      loan.lender,
      loan.rate,
      loan.base_fee,
      loan.property_type,
      loan.payment_type,
      loan.balloon_payment,
      loan.prepayment_penalty
    ];

    if (HOA_PROPERTY_TYPES.includes(loan.property_type)) {
      validatedFields.push(loan.hoa1, loan.hoa2, loan.mello_roos_amount);
    }
    if (loan.payment_type === INTEREST_ONLY) {
      validatedFields.push(loan.interest_only_period);
    }
    if (loan.balloon_payment === YES) {
      validatedFields.push(loan.balloon_payment_period);
    }
    if (ARM_TERMS.includes(loan.term)) {
      validatedFields.push(
        loan.arm_index_percent,
        loan.arm_margin,
        loan.arm_rate_floor
      );
    }

    return validatedFields.every(validateNonEmptyString);
  }

  function populateFromLoanOne() {
    const { loans } = document;
    if (
        window.confirm(
            "This will wipe existing data on this page and Replace it with Data from Loan 1, continue?"
        )
    ) {
      const tempLoan = allInputFields.reduce((loan, field) => {
        loan[field] = loans[0][field];
        return loan;
      }, { ...loan });
      if (LOANS_WITH_RATE_CALCULATOR.includes(loan.type)) {
        const tempRateCalculator = allRateCalculatorInputFields.reduce((rateCalculator, field) => {
          rateCalculator[field] = loans[0].rate_calculator[field];
          return rateCalculator;
        }, { ...loan.rate_calculator });
        const updatedRate = total_rate_calculator(tempRateCalculator);
        Object.assign(tempLoan, {
          rate_calculator: tempRateCalculator,
          rate: updatedRate
        });
      }
      tempLoan.is_modified = true;
      setLoan(tempLoan);
    }
  }

  return (
    <Section
      className="le-price-engine-edit-loan"
      name={`Edit Loan ${loanIndex + 1}`}
    >
      <div className="flex">
        <div className="third-width">
          {loanIndex > 0 && (
              <div className="flex-end align-items-center bottom-border medium-border">
                <button
                    className="button"
                    onClick={() => populateFromLoanOne(loan)}
                >
                  Populate Data From Loan 1
                </button>
              </div>
          )}
          <SelectField
            name="Origination Fee Paid By"
            fieldName="origination_fee_paid_by"
            onChange={onChange}
            value={origination_fee_paid_by}
            selectOptions={ORIGINATION_FEE_PAID_BY}
          />
          <SelectField
            name="Display Fee"
            fieldName="display_fee"
            onChange={onChange}
            value={display_fee}
            selectOptions={YES_NO}
          />
          <SelectField
            name="Brokered Loan"
            fieldName="brokered_loan"
            onChange={onChange}
            value={brokered_loan}
            selectOptions={YES_NO}
          />
          <TextField
            id="lender"
            name="Lender"
            fieldName="lender"
            onChange={onChange}
            value={lender}
            datalist={LENDERS}
          />
          <TextField
            name="Lender Program"
            fieldName="lender_program"
            onChange={onChange}
            value={lender_program}
          />

          <TextField
            readOnly
            name="Loan Type"
            value={isRefinance ? REFINANCE_LOAN_TYPES[type] : LOAN_TYPES[type]}
          />

          {LOANS_WITH_RATE_CALCULATOR.includes(loan.type) && (
            <div className="le-price-engine-rate-calculator">
              <div className="le-price-engine-title">Rate Calculator</div>
              <PercentField
                fieldName="base_rate"
                onChange={onChangeRateCalculator}
                name="Base Rate"
                value={rate_calculator.base_rate}
              />
              <PercentField
                fieldName="bank_statements"
                onChange={onChangeRateCalculator}
                name="Bank Statements"
                value={rate_calculator.bank_statements}
              />
              <PercentField
                fieldName="term_add_on"
                onChange={onChangeRateCalculator}
                name="Term add on"
                value={rate_calculator.term_add_on}
              />
              <PercentField
                fieldName="subordinate_second"
                onChange={onChangeRateCalculator}
                name="Subordinate 2nd"
                value={rate_calculator.subordinate_second}
              />
              <PercentField
                fieldName="full_doc"
                onChange={onChangeRateCalculator}
                name="Full Doc"
                value={rate_calculator.full_doc}
              />
              <PercentField
                fieldName="dti_add"
                onChange={onChangeRateCalculator}
                name="DTI Add"
                value={rate_calculator.dti_add}
              />
              <PercentField
                fieldName="asset_depletion"
                onChange={onChangeRateCalculator}
                name="Asset Depletion"
                value={rate_calculator.asset_depletion}
              />
              <PercentField
                fieldName="cash_out"
                onChange={onChangeRateCalculator}
                name="Cash Out"
                value={rate_calculator.cash_out}
              />
              <PercentField
                fieldName="loan_amount"
                onChange={onChangeRateCalculator}
                name="Loan Amount"
                value={rate_calculator.loan_amount}
              />
              <PercentField
                fieldName="other"
                onChange={onChangeRateCalculator}
                name="Other"
                value={rate_calculator.other}
              />
              <PercentField
                fieldName="other_two"
                onChange={onChangeRateCalculator}
                name="Other"
                value={rate_calculator.other_two}
              />
              <PercentField
                readOnly
                name="Rate"
                decimalPlaces={3}
                value={rate}
                title="LANIS Training Tip: Loan 1 should always be the Lowest Rate Possible and it is used in the Anti Stearing Disclosure. For other Loan Options, increase the Interest Rate for the appropriate Loan Type and the closing costs desired."
              />
            </div>
          )}

          {!LOANS_WITH_RATE_CALCULATOR.includes(loan.type) && (
            <PercentField
              id="rate"
              fieldName="rate"
              onChange={onChange}
              name="Rate"
              value={rate}
              roundTo={3}
              decimalPlaces={3}
              datalist={SORTED_LOAN_RATES}
              title="LANIS Training Tip: Loan 1 should always be the Lowest Rate Possible and it is used in the Anti Stearing Disclosure. For other Loan Options, increase the Interest Rate for the appropriate Loan Type and the closing costs desired."
            />
          )}

          <PercentField
            fieldName="base_fee"
            onChange={onChange}
            name="Base Fee"
            value={base_fee}
          />

          <NumberField readOnly name="Amortization" value={loan_term(loan)} />

          <SelectField
            onChange={onChange}
            fieldName="property_type"
            name="Property Type"
            value={loan.property_type}
            selectOptions={PROPERTY_TYPES}
          />

          {HOA_PROPERTY_TYPES.includes(loan.property_type) && (
            <>
              <CurrencyField
                onChange={onChange}
                fieldName="hoa1"
                name="HOA 1"
                value={loan.hoa1}
              />
              <SelectField
                onChange={onChange}
                fieldName="hoa1_frequency"
                name="HOA 1 Frequency"
                value={loan.hoa1_frequency}
                selectOptions={FREQUENCIES}
              />
              <CurrencyField
                onChange={onChange}
                fieldName="hoa2"
                name="HOA 2"
                value={loan.hoa2}
              />
              <SelectField
                onChange={onChange}
                fieldName="hoa2_frequency"
                name="HOA 2 Frequency"
                value={loan.hoa2_frequency}
                selectOptions={FREQUENCIES}
              />
              <CurrencyField
                onChange={onChange}
                fieldName="mello_roos_amount"
                name="Mello Roos"
                value={loan.mello_roos_amount}
              />
              <SelectField
                onChange={onChange}
                fieldName="mello_roos_frequency"
                name="Mello Roos Frequency"
                value={loan.mello_roos_frequency}
                selectOptions={FREQUENCIES}
              />
            </>
          )}

          <SelectField
            onChange={onChange}
            name="Payment Type"
            fieldName="payment_type"
            value={payment_type}
            selectOptions={LOAN_PAYMENT_TYPES}
          />

          {payment_type === INTEREST_ONLY && (
            <SelectField
              name="Interest only period"
              onChange={onChange}
              fieldName="interest_only_period"
              value={interest_only_period}
              selectOptions={PAYMENT_PERIODS}
            />
          )}

          <SelectField
            onChange={onChange}
            fieldName="balloon_payment"
            name="Balloon Payment"
            value={balloon_payment}
            selectOptions={YES_NO}
          />

          {balloon_payment === YES && (
            <SelectField
              name="Balloon Payment Period"
              onChange={onChange}
              fieldName="balloon_payment_period"
              value={balloon_payment_period}
              selectOptions={PAYMENT_PERIODS}
            />
          )}

          <SelectField
            onChange={onChange}
            name="Prepayment Penalty"
            fieldName="prepayment_penalty"
            value={prepayment_penalty}
            selectOptions={YES_NO}
          />

          {prepayment_penalty === YES && (
            <TextField
              name="Prepayment Terms"
              onChange={onChange}
              fieldName="prepayment_terms"
              value={prepayment_terms}
            />
          )}
        </div>

        {isArm && (
          <>
            <ArmRateAdjustmentsTable
              loan={loan}
              isEditLoan={true}
              onChange={onChange}
            />
            <PaymentAdjustmentTable loan={loan} isEditLoan={true} />
          </>
        )}
      </div>

      <button className="button" onClick={showMainPage}>
        Cancel
      </button>
      <button className="button" disabled={!isValidLoan()} onClick={save}>
        Save
      </button>
      {loanIndex + 1 === document.loans.length && (
        <SaveLoan
          className="button"
          name="Save and Continue"
          path={`/loan-tool/${documentId}/loan-estimate-price-engine-options`}
          onSave={() => {
            delete loan.is_modified;
            onChangeLoan(loan);
          }}
        />
      )}
    </Section>
  );
}
