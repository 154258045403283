import Decimal from "decimal.js";
import {
  WILL_BE_PAID_OFF,
  RESUBORDINATED,
  INCLUDE_IN_DTI
} from "../constants/account_dispositions";

import {
  total_net_yearly_income_loss_rental,
  total_payment
} from "./investment_reo";
import { total_monthly_payment } from "./loan";
import { year_to_payment, compute_total_income } from "./income";
import { JUMBO } from "../constants/loan_types";
import { summary_selected_loan } from "../constants/utils";
import { NO } from "../constants/yes_no";

export function compute_total(quantities) {
  return quantities.reduce((accumulator, balance) => {
    return accumulator.plus(new Decimal(balance));
  }, new Decimal(0));
}

export function percent_of_credit_used(accountSummary, residence) {
  const currentBalance = current_balance(accountSummary, residence);
  const highCreditLimit = new Decimal(accountSummary.high_credit_limit);
  if (currentBalance.greaterThan(0)) {
    return currentBalance.times(100).dividedToIntegerBy(highCreditLimit);
  } else {
    return new Decimal(0);
  }
}

export function lower_balance_payment_left(accountSummary, residence) {
  const currentBalance = current_balance(accountSummary, residence);
  const highCreditLimit = new Decimal(accountSummary.high_credit_limit);
  return highCreditLimit.dividedBy(2).minus(currentBalance);
}

export function lower_balance_payment_left_total(accountSummaries, residences) {
  const lowerBalancePaymentsLeft = accountSummaries.map(accountSummary => {
    return lower_balance_payment_left(
      accountSummary,
      residences[accountSummary.residence]
    );
  });
  return compute_total(lowerBalancePaymentsLeft);
}

export function monthly_payment(accountSummary, residence) {
  return residence
    ? residence_total_payment(
      residence.first_mortgage,
      residence.second_mortgage,
      residence.taxes,
      residence.insurance,
      residence.hoa
    )
    : new Decimal(accountSummary.monthly_payment);
}

export function monthly_payments_total(
  accountSummaries,
  residences,
  investmentProperties
) {
  const monthlyPayments = [];
  const netIncomeLoss = total_net_yearly_income_loss_rental(
    investmentProperties
  ).neg();
  if (netIncomeLoss.greaterThan(0)) {
    monthlyPayments.push(netIncomeLoss);
  }
  accountSummaries.forEach(accountSummary => {
    const residence = residences[accountSummary.residence];
    monthlyPayments.push(monthly_payment(accountSummary, residence));
  });
  return compute_total(monthlyPayments);
}

export function current_balance(accountSummary, residence) {
  return residence
    ? new Decimal(residence.principal_balance)
    : new Decimal(accountSummary.current_balance);
}

export function current_balance_total(accountSummaries, residences) {
  const currentBalances = accountSummaries.map(accountSummary => {
    return current_balance(
      accountSummary,
      residences[accountSummary.residence]
    );
  });
  return compute_total(currentBalances);
}

export function high_credit_limit_total(accountSummaries) {
  return compute_total(
    accountSummaries.map(accountSummary => accountSummary.high_credit_limit)
  );
}

export function payment_savings(accountSummary, residence, accountDisposition) {
  return accountDisposition === WILL_BE_PAID_OFF
    ? monthly_payment(accountSummary, residence)
    : new Decimal(0);
}

export function payment_savings_total(accountSummaries, residences, loan) {
  const paymentSavings = accountSummaries.map(accountSummary => {
    return payment_savings(
      accountSummary,
      residences[accountSummary.residence],
      (loan && loan.account_dispositions[accountSummary.id]) || INCLUDE_IN_DTI
    );
  });
  return compute_total(paymentSavings);
}

export function payment_for_DTI_calc(
  accountSummary,
  residence,
  accountDisposition
) {
  if (
    accountDisposition === RESUBORDINATED ||
    accountDisposition === INCLUDE_IN_DTI
  ) {
    return monthly_payment(accountSummary, residence);
  } else {
    return new Decimal(0);
  }
}

export function payment_for_DTI_calc_total(
  accountSummaries,
  residences,
  investmentProperties,
  loan
) {
  const paymentsForDTICalc = accountSummaries.map(accountSummary => {
    return payment_for_DTI_calc(
      accountSummary,
      residences[accountSummary.residence],
      (loan && loan.account_dispositions[accountSummary.id]) || INCLUDE_IN_DTI
    );
  });
  const netIncomeLoss = total_net_yearly_income_loss_rental(
    investmentProperties
  ).neg();
  if (netIncomeLoss.greaterThan(0)) {
    paymentsForDTICalc.push(netIncomeLoss);
  }
  return compute_total(paymentsForDTICalc);
}

// TODO: why is accountDisposition here and unused?
export function balance_to_be_paid_down(
  accountSummary,
  residence //,
  // accountDisposition
) {
  const percentThreshold = 50;
  const percentOfCreditUsed = percent_of_credit_used(accountSummary, residence);
  if (percentOfCreditUsed.greaterThan(percentThreshold)) {
    return lower_balance_payment_left(accountSummary, residence);
  } else {
    return new Decimal(0);
  }
}

export function balance_to_be_paid_down_total(
  accountSummaries,
  residences,
  loan
) {
  const balancesToBePaidDown = accountSummaries.map(accountSummary => {
    return balance_to_be_paid_down(
      accountSummary,
      residences[accountSummary.residence],
      (loan && loan.account_dispositions[accountSummary.id]) || INCLUDE_IN_DTI
    );
  });
  return compute_total(balancesToBePaidDown);
}

export function balance_pay_off(current_balance, accountDisposition) {
  if (accountDisposition === WILL_BE_PAID_OFF) {
    return new Decimal(current_balance);
  } else {
    return new Decimal(0);
  }
}

export function balance_pay_offs_total(accountSummaries, residences, loan) {
  const balancePayOffs = accountSummaries.map(accountSummary => {
    const currentBalance = current_balance(
      accountSummary,
      residences[accountSummary.residence]
    );
    const accountDisposition =
      (loan && loan.account_dispositions[accountSummary.id]) || INCLUDE_IN_DTI;
    return balance_pay_off(currentBalance, accountDisposition);
  });
  return compute_total(balancePayOffs);
}

export function selected_balance_pay_offs_total(
  loans,
  summary,
  accountSummaries,
  residences
) {
  const loan = summary_selected_loan(loans, summary);
  if (!loan) {
    return new Decimal(0);
  }
  return balance_pay_offs_total(
    Object.values(accountSummaries),
    residences,
    loan
  );
}

export function payments_left(accountSummary, residence) {
  return current_balance(accountSummary, residence).div(
    monthly_payment(accountSummary, residence)
  );
}

// Calculations for the Reserve Calculator.
export function residence_total_payment(
  firstMortgage,
  secondMortgage,
  taxes,
  insurance,
  hoa
) {
  return [firstMortgage, secondMortgage, taxes, insurance, hoa]
    .map(value => {
      return new Decimal(value);
    })
    .reduce((total, value) => {
      return total.plus(value);
    }, new Decimal(0));
}

export function reserve_total_payment(reserves, residenceTotalPayment) {
  return new Decimal(reserves).times(new Decimal(residenceTotalPayment));
}

export function monthly_pitia_total(loans, investment_properties) {
  const isJumbo = loans.some(loan => loan.type === JUMBO);
  if (!isJumbo) {
    return new Decimal(0);
  }
  return investment_properties.reduce((accum, property) => {
    return accum.plus(total_payment(property));
  }, new Decimal(0));
}

export function pay_off_debt_to_qualify_minus_debt_to_income(
  documentType,
  borrowers,
  investmentProperties,
  accountSummaries,
  property,
  residences,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  const totalMonthlyPayment = total_monthly_payment(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  const totalPaymentForDTICalc = payment_for_DTI_calc_total(
    Object.values(accountSummaries),
    residences,
    investmentProperties,
    loan
  );
  const totalMonthlyIncome = year_to_payment(
    compute_total_income(borrowers, investmentProperties),
    12
  );

  return totalMonthlyIncome.eq(0)
    ? new Decimal(0)
    : totalMonthlyPayment
      .plus(totalPaymentForDTICalc)
      .dividedBy(totalMonthlyIncome)
      .mul(100);
}

export function debt_to_income_ratio(
  documentType,
  borrowers,
  investmentProperties,
  accountSummaries,
  property,
  residences,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  const totalMonthlyPayment = total_monthly_payment(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  const totalPaymentForDTICalc = payment_for_DTI_calc_total(
    Object.values(accountSummaries),
    residences,
    investmentProperties,
    loan
  );
  const paymentSavingsTotal = payment_savings_total(
    Object.values(accountSummaries),
    residences,
    loan
  );
  const totalMonthlyIncome = year_to_payment(
    compute_total_income(borrowers, investmentProperties),
    12
  );
  return totalMonthlyIncome.eq(0)
    ? new Decimal(0)
    : totalMonthlyPayment
      .plus(totalPaymentForDTICalc)
      .plus(paymentSavingsTotal)
      .dividedBy(totalMonthlyIncome)
      .mul(100);
}

export function revised_dti(
  documentType,
  pay_off_debt_to_qualify,
  borrowers,
  investmentProperties,
  accountSummaries,
  property,
  residences,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return pay_off_debt_to_qualify === NO
    ? debt_to_income_ratio(
      documentType,
      borrowers,
      investmentProperties,
      accountSummaries,
      property,
      residences,
      loan,
      property_to_be_sold,
      is_selling_property
    )
    : pay_off_debt_to_qualify_minus_debt_to_income(
      documentType,
      borrowers,
      investmentProperties,
      accountSummaries,
      property,
      residences,
      loan,
      property_to_be_sold,
      is_selling_property
    );
}
