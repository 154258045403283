import React from "react";

import CurrencyField from "../../components/fields/currency_field";
import SelectField from "../../components/fields/select_field";

import { YES_NO_TBD, YES } from "../../constants/yes_no";
import PROPERTY_CLASSIFICATION from "../../constants/property_classification";
import SOLAR_SYSTEM_TYPES from "../../constants/solar_system_type";

import StandardTemplate from "../../templates/standard_template/standard_template";

import "./css/selling_property.scss";
import {
  total_of_payments,
  total_of_loans,
  first_current_monthly_payment
} from "../../calculations/property";
import Decimal from "decimal.js";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import { selected_balance_pay_offs_total } from "../../calculations/debt_info";
import DateField from "../../components/fields/date_field";
import { CURRENT_PROP_INFO, SELLING_PROP } from "../../constants/navigations";
import Section from "../../components/ui/section";

export default function SellingPropertyPage() {
  const [document, setDocument, { documentType }] = useDocument();

  function onChange(updatedState) {
    setDocument({ ...document, ...updatedState });
  }

  function onChangeSellingProperty(updatedState) {
    const property_to_be_sold = {
      ...document.property_to_be_sold,
      ...updatedState
    };
    setDocument({ ...document, property_to_be_sold });
  }

  const {
    property_to_be_sold,
    is_selling_property,
    accountSummaries,
    residences,
    loans,
    summary
  } = document;
  const show_existing_mortgage_insurance = new Decimal(
    property_to_be_sold.existing_mortgage_insurance
  ).gt("0");
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;

  return (
    <StandardTemplate active={isRefinance ? CURRENT_PROP_INFO : SELLING_PROP}>
      <div className="selling-property">
        <div className="half-width">
          <SelectField
            fieldName="is_selling_property"
            name={
              isPurchase
                ? "Will a Home be sold for Down Payment?"
                : "Current Loan Information Monthly Payment? Pay Calc"
            }
            value={is_selling_property}
            onChange={onChange}
            selectOptions={YES_NO_TBD}
          />
        </div>

        {is_selling_property === YES && (
          <>
            <Section
              name={isRefinance ? "Current Property Info" : "Selling Property"}
              className="selling-property-section"
            >
              <div className="flex">
                <div className="half-width">
                  {isPurchase && (
                    <SelectField
                      fieldName="property_type"
                      name="Which Home will be sold"
                      value={property_to_be_sold.property_type}
                      onChange={onChangeSellingProperty}
                      selectOptions={PROPERTY_CLASSIFICATION}
                    />
                  )}
                  <CurrencyField
                    fieldName="first_current_monthly_payment_principal"
                    name="1st Current Monthly Payment Principal"
                    value={
                      property_to_be_sold.first_current_monthly_payment_principal
                    }
                    onChange={onChangeSellingProperty}
                  />
                  <CurrencyField
                    fieldName="first_current_monthly_payment_interest"
                    name="1st Current Monthly Payment Interest"
                    value={
                      property_to_be_sold.first_current_monthly_payment_interest
                    }
                    onChange={onChangeSellingProperty}
                  />
                  <CurrencyField
                    readOnly
                    name="1st Current Monthly Payment (Principal &amp; Interest)"
                    value={first_current_monthly_payment(property_to_be_sold)}
                  />
                  <CurrencyField
                    fieldName="second_current_monthly_payment"
                    name="2nd Current Monthly Payment (Principal &amp; Interest)"
                    value={property_to_be_sold.second_current_monthly_payment}
                    onChange={onChangeSellingProperty}
                  />
                  <CurrencyField
                    fieldName="miscellaneous_housing_payment"
                    name="Miscellaneous Housing Payment (3rd TD?)"
                    value={property_to_be_sold.miscellaneous_housing_payment}
                    onChange={onChangeSellingProperty}
                  />
                  <CurrencyField
                    fieldName="existing_mortgage_insurance"
                    name="Existing Mortgage Insurance"
                    value={property_to_be_sold.existing_mortgage_insurance}
                    onChange={onChangeSellingProperty}
                  />
                  {show_existing_mortgage_insurance && (
                    <SelectField
                      fieldName="fha_loan"
                      name="FHA Loan"
                      value={property_to_be_sold.fha_loan}
                      onChange={onChangeSellingProperty}
                      selectOptions={YES_NO_TBD}
                      title="LANIS Training Tip: If the MI is for an FHA Loan, you may need to configure one full month of interest into the Pay-off Demand from the current Lender. Check the Date of Origination and current FHA guidelines for pay off amounts."
                    />
                  )}
                  <CurrencyField
                    readOnly
                    name="Total of Payments"
                    value={total_of_payments(property_to_be_sold).toString()}
                  />
                </div>

                <div className="half-width">
                  {isPurchase && (
                    <>
                      <SelectField
                        fieldName="concurrent_close"
                        name="Concurrent Close"
                        value={property_to_be_sold.concurrent_close}
                        onChange={onChangeSellingProperty}
                        selectOptions={YES_NO_TBD}
                      />
                      <CurrencyField
                        fieldName="current_market_value"
                        name="What is the Current Market Value"
                        title="LANIS Training Tip: Check with the Listing Agent to see what the activity on the listed property has been and if the comparables justify the Sales Price. Use the AVM Look Up Market Value button to the right or go to the Resources Tab, Loan Officers Tools, AVM to run multiple AVM's."
                        value={property_to_be_sold.current_market_value}
                        onChange={onChangeSellingProperty}
                      />
                    </>
                  )}
                  <CurrencyField
                    fieldName="first_loan_balance"
                    name={
                      isPurchase
                        ? "1st Loan Balance"
                        : "Current Loan Balance 1st TD*"
                    }
                    value={property_to_be_sold.first_loan_balance}
                    onChange={onChangeSellingProperty}
                  />
                  <CurrencyField
                    fieldName="second_loan_balance"
                    name={
                      isPurchase
                        ? "2nd Loan Balance"
                        : "Current Loan Balance 2nd TD*"
                    }
                    value={property_to_be_sold.second_loan_balance}
                    onChange={onChangeSellingProperty}
                  />
                  {new Decimal(
                    property_to_be_sold.miscellaneous_housing_payment
                  ).gt(0) && (
                    <CurrencyField
                      fieldName="third_loan_balance"
                      name={
                        isPurchase
                          ? "3rd Loan Balance"
                          : "Current Loan Balance 3rd TD"
                      }
                      value={property_to_be_sold.third_loan_balance}
                      onChange={onChangeSellingProperty}
                    />
                  )}
                  {isRefinance && (
                    <>
                      <CurrencyField
                        readOnly
                        name="Pay Off Debt (Comes from Debt Tab)"
                        value={selected_balance_pay_offs_total(
                          loans,
                          summary,
                          accountSummaries,
                          residences
                        )}
                      />
                    </>
                  )}
                  <CurrencyField
                    readOnly
                    name="Total of Loans"
                    value={total_of_loans(
                      documentType,
                      property_to_be_sold,
                      accountSummaries,
                      residences,
                      loans,
                      summary
                    )}
                  />
                </div>
              </div>
            </Section>

            {isRefinance && (
              <Section name="Pay Offs">
                <div className="flex">
                  <div className="half-width">
                    <SelectField
                      fieldName="original_purchase_money_loans"
                      name="Original Purchase Money Loans?"
                      value={property_to_be_sold.original_purchase_money_loans}
                      selectOptions={YES_NO_TBD}
                      onChange={onChangeSellingProperty}
                    />
                  </div>
                  <div className="half-width">
                    <DateField
                      fieldName="lender_payoff_first_loan_demand_recorded"
                      name="Lender Payoff 1st Loan Demand Rec'ed."
                      value={
                        property_to_be_sold.lender_payoff_first_loan_demand_recorded
                      }
                      onChange={onChangeSellingProperty}
                    />
                    <CurrencyField
                      fieldName="first_loan_total_pay_off_amount"
                      name="Total Pay Off Amount"
                      value={
                        property_to_be_sold.first_loan_total_pay_off_amount
                      }
                      onChange={onChangeSellingProperty}
                    />
                    {new Decimal(
                      property_to_be_sold.second_current_monthly_payment
                    ).gt(0) && (
                      <>
                        <DateField
                          fieldName="lender_payoff_second_loan_demand_recorded"
                          name="Lender Payoff 2nd Loan Demand Rec'ed."
                          value={
                            property_to_be_sold.lender_payoff_second_loan_demand_recorded
                          }
                          onChange={onChangeSellingProperty}
                        />
                        <CurrencyField
                          fieldName="second_loan_total_pay_off_amount"
                          name="Total Pay Off Amount"
                          value={
                            property_to_be_sold.second_loan_total_pay_off_amount
                          }
                          onChange={onChangeSellingProperty}
                        />
                      </>
                    )}
                    {new Decimal(
                      property_to_be_sold.miscellaneous_housing_payment
                    ).gt(0) && (
                      <>
                        <DateField
                          fieldName="lender_payoff_third_loan_demand_recorded"
                          name="Lender Payoff 3rd Loan Demand Rec'ed."
                          value={
                            property_to_be_sold.lender_payoff_third_loan_demand_recorded
                          }
                          onChange={onChangeSellingProperty}
                        />
                        <CurrencyField
                          fieldName="third_loan_total_pay_off_amount"
                          name="Total Pay Off Amount"
                          value={
                            property_to_be_sold.third_loan_total_pay_off_amount
                          }
                          onChange={onChangeSellingProperty}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Section>
            )}

            <Section
              className="important-questions-to-ask"
              name={
                "Important Questions to Ask for On-Time Close" +
                (isPurchase ? " For the Property Being Sold" : "")
              }
            >
              <div className="flex">
                <div className="third-width">
                  <SelectField
                    fieldName="solar_system"
                    name="Is there a Solar System"
                    value={property_to_be_sold.solar_system}
                    onChange={onChangeSellingProperty}
                    selectOptions={YES_NO_TBD}
                    title="LANIS Training Tip: If there is a Solar System on the Subject Property and it's Leased or an Installment sale, removing the UCC or Subordinating the Lien may take as long as 2 - 3 weeks to resolve this issue. Do Not wait, check it out upfront with the Buyer’s Agent. If you don't it could delay the close of your loan because Your Lender does not want to be in 2nd Position. They will not Fund the loan until they are assured they will be in 1st position."
                  />
                  {property_to_be_sold.solar_system === YES && (
                    <SelectField
                      fieldName="solar_system_type"
                      name="Purchased or Leased"
                      value={property_to_be_sold.solar_system_type}
                      onChange={onChangeSellingProperty}
                      selectOptions={SOLAR_SYSTEM_TYPES}
                    />
                  )}
                  {property_to_be_sold.solar_system === YES && (
                    <CurrencyField
                      fieldName="solar_system_amount"
                      name="Amount per Month"
                      value={property_to_be_sold.solar_system_amount}
                      onChange={onChangeSellingProperty}
                    />
                  )}
                </div>
                <div className="third-width">
                  <SelectField
                    fieldName="loans_or_liens"
                    name={
                      "Loans or Liens on " + (isPurchase ? "New " : "") + "Home"
                    }
                    value={property_to_be_sold.loans_or_liens}
                    onChange={onChangeSellingProperty}
                    selectOptions={YES_NO_TBD}
                    title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                  />
                  {property_to_be_sold.loans_or_liens === YES && (
                    <SelectField
                      fieldName="hero_pace_loans_on_the_property"
                      name="HERO/Pace Loans on Home"
                      value={
                        property_to_be_sold.hero_pace_loans_on_the_property
                      }
                      onChange={onChangeSellingProperty}
                      selectOptions={YES_NO_TBD}
                      title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                    />
                  )}
                  {property_to_be_sold.loans_or_liens === YES && (
                    <SelectField
                      fieldName="tax_liens_state_or_federal"
                      name="Tax Liens, State or Federal"
                      value={property_to_be_sold.tax_liens_state_or_federal}
                      onChange={onChangeSellingProperty}
                      selectOptions={YES_NO_TBD}
                      title="LANIS Training Tip: Get a Prelim Title Report as soon as possible and make sure the property is Clear. These items can take time to clear and delay the closing of your loan."
                    />
                  )}
                </div>
                <div className="third-width">
                  {isPurchase && (
                    <SelectField
                      fieldName="reo_or_bank_owned"
                      name="REO or Bank Owned"
                      value={property_to_be_sold.reo_or_bank_owned}
                      onChange={onChangeSellingProperty}
                      selectOptions={YES_NO_TBD}
                      title="LANIS Training Tip: REO properties can take longer to close than a normal transaction. Ask the Listing Agent what the typical process flow is for the specific Lender. Some Lenders can close in 4 weeks and others as long as 3 months. This will affect your lock pricing. Discuss the pricing options with the Borrower(s)."
                    />
                  )}
                  <SelectField
                    fieldName="in_foreclosure"
                    name="Is Property in Foreclosure"
                    value={property_to_be_sold.in_foreclosure}
                    onChange={onChangeSellingProperty}
                    selectOptions={YES_NO_TBD}
                    title="LANIS Training Tip: REO properties can take longer to close than a normal transaction. Ask the Listing Agent what the typical process flow is for the specific Lender. Some Lenders can close in 4 weeks and others as long as 3 months. This will affect your lock pricing. Discuss the pricing options with the Borrowers."
                  />
                </div>
              </div>
            </Section>
          </>
        )}
      </div>
    </StandardTemplate>
  );
}
