export const BOND_REVIEW_FEE = "1";
export const CERTIFICATION_FEE = "2";
export const CLOSING_PROTECTION_LETTER_FEE = "3";
export const COUNTY_PROPERTY_TAXES_FEE = "4";
export const DEBT_CANCELATION_FEE = "5";
export const ESCROW_HOLDBACK_ADMINISTRATION_FEE = "6";
export const GENERAL_COUNCIL_FEE = "7";
export const PAYOFF_REQUEST_FEE = "8";
export const REAL_ESTATE_COMMISSION = "9";
export const TITLE_OWNERS_TITLE_INSURANCE_OPTIONAL = "10";

export default Object.freeze({
  [BOND_REVIEW_FEE]: "Bond Review Fee",
  [CERTIFICATION_FEE]: "Certification Fee",
  [CLOSING_PROTECTION_LETTER_FEE]: "Closing Protection Letter Fee",
  [COUNTY_PROPERTY_TAXES_FEE]: "County Property Taxes Fee",
  [DEBT_CANCELATION_FEE]: "Debt Cancelation Fee",
  [ESCROW_HOLDBACK_ADMINISTRATION_FEE]: "Escrow Holdback Administration Fee",
  [GENERAL_COUNCIL_FEE]: "General Council Fee",
  [PAYOFF_REQUEST_FEE]: "Payoff Request Fee",
  [REAL_ESTATE_COMMISSION]: "Real Estate Commission",
  [TITLE_OWNERS_TITLE_INSURANCE_OPTIONAL]:
    "Title - Owners Title Insurance (Optional)"
});
