import React from "react";
import { home_owners_insurance_monthly } from "../../../calculations/property";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import {
  mortgage_insurance_premium_monthly,
  prepaids_calculator,
  prepaids_homeowners_insurance_premium,
  prepaids_mortgage_insurance_premium,
  prepaids_property_taxes,
  property_tax,
  total_prepaids
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import { useDocument } from "../loan_tool";
import TrainingTip from "../../ui/training_tip";
import { is_fha, is_usda } from "../../../constants/utils";

export default function Prepaids({
  loan,
  property,
  readOnly,
  updateLoan,
  showPrepaidsCalculator
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  return (
    <div className="prepaids">
      <div className="closing-costs-row top-border gray-bg">
        <div className="flex-space-between flex-grow align-items-center">
          <div className="section-title">F. Prepaids</div>
          <button className="button" onClick={showPrepaidsCalculator}>
            Prepaids Calculator
          </button>
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_prepaids(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Homeowner&apos;s Insurance Premium
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={home_owners_insurance_monthly(documentType, property)}
        />
        <NumberField
          className="closing-costs-cell center-align"
          fieldName="prepaids_homeowners_insurance_premium_months"
          onChange={updateLoan}
          value={loan.prepaids_homeowners_insurance_premium_months}
          readOnly={readOnly}
        />
        <div className="closing-costs-cell">Months</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={prepaids_homeowners_insurance_premium(
            documentType,
            loan,
            property
          )}
        />
      </div>
      <div className="closing-costs-row flex-start">
        <div className="closing-costs-cell-shell triple-width">
          (HOI Per Year)
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={home_owners_insurance_monthly(documentType, property).mul(12)}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Mortgage Insurance Premium
        </div>
        {is_fha(loan.type) || is_usda(loan.type) ? (
          <>
            <div className="closing-costs-cell gray-bg" />
            <div className="closing-costs-cell gray-bg" />
            <div className="closing-costs-cell">Months</div>
            <div className="closing-costs-cell gray-bg" />
          </>
        ) : (
          <>
            <CurrencyField
              className="closing-costs-cell right-align gray-bg"
              naked
              fixedDigits={0}
              value={mortgage_insurance_premium_monthly(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
            <NumberField
              className="closing-costs-cell center-align"
              fieldName="prepaids_mortgage_insurance_premium_months"
              onChange={updateLoan}
              value={loan.prepaids_mortgage_insurance_premium_months}
              readOnly={readOnly}
            />
            <div className="closing-costs-cell">Months</div>
            <CurrencyField
              className="closing-costs-cell right-align gray-bg"
              naked
              fixedDigits={0}
              value={prepaids_mortgage_insurance_premium(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </>
        )}
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell">Prepaid Int</div>
        <NumberField
          className="closing-costs-cell center-align gray-bg"
          naked
          value={loan.prepaids.days_of_interest}
        />
        <CurrencyField
          className="closing-costs-cell center-align gray-bg"
          naked
          fixedDigits={4}
          value={prepaids_calculator.prepaid_interest(
            documentType,
            loan,
            loan.prepaids,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
        <div className="closing-costs-cell-shell center-align">
          Per Day X{" "}
          <TrainingTip content="LANIS Training Tip: Federal Law states you must have a minimum of 15 days on the LE." />
        </div>
        <NumberField
          className="closing-costs-cell center-align gray-bg"
          naked
          value={loan.prepaids.per_days}
        />
        <PercentField
          className="closing-costs-cell center-align gray-bg"
          naked
          decimalPlaces={3}
          value={loan.rate}
        />
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={prepaids_calculator.total_interest(
            documentType,
            loan,
            loan.prepaids,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Property Taxes
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={property_tax(documentType, loan, property)}
        />
        <NumberField
          className="closing-costs-cell center-align"
          fieldName="prepaids_property_taxes_months"
          onChange={updateLoan}
          value={loan.prepaids_property_taxes_months}
          readOnly={readOnly}
        />
        <div className="closing-costs-cell">Months</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={prepaids_property_taxes(documentType, loan, property)}
        />
      </div>
      <div className="closing-costs-row">
        <div>REAL ESTATE TAXES DUE (Depends on your states due dates)</div>
        <CurrencyField
          displayFixedDigits={0}
          fieldName="prepaids_real_estate_taxes_due"
          onChange={updateLoan}
          value={loan.prepaids_real_estate_taxes_due}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
}
