import React from "react";
import "./css/lender_broker_information.css";
import { useUser } from "../../../hooks/user";
import { useDocument } from "../loan_tool";
import { truthy_join } from "../../../constants/utils";
import PhoneField from "../../fields/phone_field";

export default function LenderBrokerInformation() {
  const [{ companyProfile }] = useUser();
  const [, , { documentAccountProfile }] = useDocument();

  return (
    <div className="lender-broker-information">
      <div className="lender-information">
        <div className="content-section">
          <div className="content-title">LENDER</div>
          <div className="content-description" />
        </div>
        <div className="content-section">
          <div className="content-title">NMLS LICENSE ID</div>
          <div className="content-description" />
        </div>
        <div className="content-section">
          <div className="content-title">LOAN OFFICER</div>
          <div className="content-description" />
        </div>
        <div className="content-section">
          <div className="content-title">NMLS ID</div>
          <div className="content-description" />
        </div>
        <div className="content-section">
          <div className="content-title">EMAIL</div>
          <div className="content-description" />
        </div>
        <div className="content-section">
          <div className="content-title">PHONE</div>
          <div className="content-description" />
        </div>
      </div>
      <div className="broker-information">
        <div className="content-section">
          <div className="content-title">COMPANY NAME</div>
          <div className="content-description">
            {companyProfile.company_name}
          </div>
        </div>
        <div className="content-section">
          <div className="content-title">NMLS/LICENSE ID</div>
          <div className="content-description flex-start-with-spacing">
            <div>{companyProfile.company_nmls_number}</div>
            <div>
              {
                `CA BRE: ${
                  documentAccountProfile.state_real_estate_license
                    ?
                    documentAccountProfile.state_real_estate_license
                    :
                    companyProfile.company_state_license
                }`
              }
            </div>
          </div>
        </div>
        <div className="content-section">
          <div className="content-title">LOAN OFFICER</div>
          <div className="content-description">
            {truthy_join(
              [
                documentAccountProfile.first_name,
                documentAccountProfile.middle_name,
                documentAccountProfile.last_name
              ],
              " "
            )}
          </div>
        </div>
        <div className="content-section">
          <div className="content-title">NMLS ID</div>
          <div className="content-description">
            {documentAccountProfile.nmls_number}
          </div>
        </div>
        <div className="content-section">
          <div className="content-title">EMAIL</div>
          <div className="content-description">{companyProfile.email}</div>
        </div>
        <div className="content-section">
          <div className="content-title">PHONE</div>
          <div className="content-description">
            <PhoneField naked value={companyProfile.contact_phone} />{" "}
            {companyProfile.extension && `ext ${companyProfile.extension}`}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
