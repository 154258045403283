export const ARCHITECTURAL_FEE = "1";
export const BUILDING_PERMIT = "2";
export const HOME_INSPECTION = "3";
export const SURVEY_FEE = "4";
export const TAX_MONITORING_FEE = "5";
export const TAX_SERVICE_FEE_NEW_1ST_TD = "6";
export const TAX_SERVICE_FEE_NEW_2ND_TD = "7";
export const TAX_STATUS_RESEARCH_FEE = "8";
export const TITLE_ABSTRACT_TITLE_SEARCH_FEE = "9";
export const TITLE_CLOSING_FEE_ESCROW_FEE_NEW_1ST_TD = "10";
export const TITLE_CLOSING_FEE_ESCROW_FEE_NEW_2ND_TD = "11";
export const TITLE_COPIER_OF_FAX_FEE = "12";
export const TITLE_COURIER_FEE_NEW_1ST_TD = "13";
export const TITLE_COURIER_FEE_NEW_2ND_TD = "14";
export const TITLE_DOC_PREP_FEE_NEW_1ST_TD = "15";
export const TITLE_DOC_PREP_FEE_NEW_2ND_TD = "16";
export const TITLE_DOCUMENT_SIGNING_FEE_NEW_1ST_TD = "17";
export const TITLE_DOCUMENT_SIGNING_FEE_NEW_2ND_TD = "18";
export const TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_1ST_TD = "19";
export const TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_2ND_TD = "20";
export const TITLE_EXAMINATION_FEE = "21";
export const TITLE_INSURANCE_BINDING_FEE_NEW_1ST_TD = "22";
export const TITLE_INSURANCE_BINDING_FEE_NEW_2ND_TD = "23";
export const TITLE_LENDER_ENDORSEMENTS_NEW_1ST_TD = "24";
export const TITLE_LENDER_ENDORSEMENTS_NEW_2ND_TD = "25";
export const TITLE_LENDERS_TITLE_POLICY = "26";
export const TITLE_LOAN_TIE_IN_FEE_NEW_1ST_TD = "27";
export const TITLE_LOAN_TIE_IN_FEE_NEW_2ND_TD = "28";
export const TITLE_NOTARY_FEE_NEW_1ST_TD = "29";
export const TITLE_NOTARY_FEE_NEW_2ND_TD = "30";
export const TITLE_OWNERS_TITLE_INSURANCE_PAID_BY_SELLER = "31";
export const TITLE_RECORDING_FEE_NEW_1ST_TD = "32";
export const TITLE_RECORDING_FEE_NEW_2ND_TD = "33";
export const TITLE_RECORDING_SERVICE_FEE_NEW_1ST_TD = "34";
export const TITLE_RECORDING_SERVICE_FEE_NEW_2ND_TD = "35";
export const TITLE_SUB_ESCROW_FEE_NEW_1ST_TD = "36";
export const TITLE_SUB_ESCROW_FEE_NEW_2ND_TD = "37";
export const TITLE_TITLE_SEARCH = "38";
export const TITLE_WIRE_DISBURSEMENT_FEE_NEW_1ST_TD = "39";
export const TITLE_WIRE_DISBURSEMENT_FEE_NEW_2ND_TD = "40";
export const VA_FUNDING_FEE = "41";
export const ATTORNEY_FEE = "42";
export const MOBILE_NOTARY_FEE = "43";
export const NOTARY_FEE = "44";

export const SERVICES_YOU_CAN_SHOP_FOR_APR = [
  ATTORNEY_FEE,
  MOBILE_NOTARY_FEE,
  NOTARY_FEE,
  TAX_MONITORING_FEE,
  TAX_SERVICE_FEE_NEW_1ST_TD,
  TAX_SERVICE_FEE_NEW_2ND_TD,
  TAX_STATUS_RESEARCH_FEE,
  TITLE_CLOSING_FEE_ESCROW_FEE_NEW_1ST_TD,
  TITLE_CLOSING_FEE_ESCROW_FEE_NEW_2ND_TD,
  TITLE_COPIER_OF_FAX_FEE,
  TITLE_COURIER_FEE_NEW_1ST_TD,
  TITLE_COURIER_FEE_NEW_2ND_TD,
  TITLE_DOC_PREP_FEE_NEW_1ST_TD,
  TITLE_DOC_PREP_FEE_NEW_2ND_TD,
  TITLE_DOCUMENT_SIGNING_FEE_NEW_1ST_TD,
  TITLE_DOCUMENT_SIGNING_FEE_NEW_2ND_TD,
  TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_1ST_TD,
  TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_2ND_TD,
  TITLE_LOAN_TIE_IN_FEE_NEW_1ST_TD,
  TITLE_LOAN_TIE_IN_FEE_NEW_2ND_TD,
  TITLE_NOTARY_FEE_NEW_1ST_TD,
  TITLE_NOTARY_FEE_NEW_2ND_TD,
  TITLE_SUB_ESCROW_FEE_NEW_1ST_TD,
  TITLE_SUB_ESCROW_FEE_NEW_2ND_TD,
  TITLE_WIRE_DISBURSEMENT_FEE_NEW_1ST_TD,
  TITLE_WIRE_DISBURSEMENT_FEE_NEW_2ND_TD,
  VA_FUNDING_FEE
];

export default Object.freeze({
  [ARCHITECTURAL_FEE]: "Architectural Fee",
  [BUILDING_PERMIT]: "Building Permit",
  [HOME_INSPECTION]: "Home inspection",
  [SURVEY_FEE]: "Survey Fee",
  [TAX_MONITORING_FEE]: "Tax Monitoring Fee",
  [TAX_SERVICE_FEE_NEW_1ST_TD]: "Tax Service Fee New 1st TD",
  [TAX_SERVICE_FEE_NEW_2ND_TD]: "Tax Service Fee New 2nd TD",
  [TAX_STATUS_RESEARCH_FEE]: "Tax Status Research Fee",
  [TITLE_ABSTRACT_TITLE_SEARCH_FEE]: "Title - Abstract / Title Search Fee",
  [TITLE_CLOSING_FEE_ESCROW_FEE_NEW_1ST_TD]:
    "Title - Closing Fee/Escrow Fee New 1st TD",
  [TITLE_CLOSING_FEE_ESCROW_FEE_NEW_2ND_TD]:
    "Title - Closing Fee/Escrow Fee New 2nd TD",
  [TITLE_COPIER_OF_FAX_FEE]: "Title - Copier of Fax Fee",
  [TITLE_COURIER_FEE_NEW_1ST_TD]: "Title - Courier Fee New 1st TD",
  [TITLE_COURIER_FEE_NEW_2ND_TD]: "Title - Courier Fee New 2nd TD",
  [TITLE_DOC_PREP_FEE_NEW_1ST_TD]: "Title - Doc Prep Fee New 1st TD",
  [TITLE_DOC_PREP_FEE_NEW_2ND_TD]: "Title - Doc Prep Fee New 2nd TD",
  [TITLE_DOCUMENT_SIGNING_FEE_NEW_1ST_TD]:
    "Title - Document Signing Fee New 1st TD",
  [TITLE_DOCUMENT_SIGNING_FEE_NEW_2ND_TD]:
    "Title - Document Signing Fee New 2nd TD",
  [TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_1ST_TD]:
    "Title - Electronic Document Fee New 1st TD",
  [TITLE_ELECTRONIC_DOCUMENT_FEE_NEW_2ND_TD]:
    "Title - Electronic Document Fee New 2nd TD",
  [TITLE_EXAMINATION_FEE]: "Title - Examination Fee",
  [TITLE_INSURANCE_BINDING_FEE_NEW_1ST_TD]:
    "Title - Insurance Binding Fee New 1st TD",
  [TITLE_INSURANCE_BINDING_FEE_NEW_2ND_TD]:
    "Title - Insurance Binding Fee New 2nd TD",
  [TITLE_LENDER_ENDORSEMENTS_NEW_1ST_TD]:
    "Title - Lender Endorsements New 1st TD",
  [TITLE_LENDER_ENDORSEMENTS_NEW_2ND_TD]:
    "Title - Lender Endorsements New 2nd TD",
  [TITLE_LENDERS_TITLE_POLICY]: "Title - Lender's Title Policy",
  [TITLE_LOAN_TIE_IN_FEE_NEW_1ST_TD]: "Title - Loan Tie In Fee New 1st TD",
  [TITLE_LOAN_TIE_IN_FEE_NEW_2ND_TD]: "Title - Loan Tie In Fee New 2nd TD",
  [TITLE_NOTARY_FEE_NEW_1ST_TD]: "Title - Notary Fee New 1st TD",
  [TITLE_NOTARY_FEE_NEW_2ND_TD]: "Title - Notary Fee New 2nd TD",
  [TITLE_OWNERS_TITLE_INSURANCE_PAID_BY_SELLER]:
    "Title - Owners Title Insurance Paid by Seller",
  [TITLE_RECORDING_FEE_NEW_1ST_TD]: "Title - Recording Fee New 1st TD",
  [TITLE_RECORDING_FEE_NEW_2ND_TD]: "Title - Recording Fee New 2nd TD",
  [TITLE_RECORDING_SERVICE_FEE_NEW_1ST_TD]:
    "Title - Recording Service Fee New 1st TD",
  [TITLE_RECORDING_SERVICE_FEE_NEW_2ND_TD]:
    "Title - Recording Service Fee New 2nd TD",
  [TITLE_SUB_ESCROW_FEE_NEW_1ST_TD]: "Title - Sub Escrow Fee New 1st TD",
  [TITLE_SUB_ESCROW_FEE_NEW_2ND_TD]: "Title - Sub Escrow Fee New 2nd TD",
  [TITLE_TITLE_SEARCH]: "Title - Title Search",
  [TITLE_WIRE_DISBURSEMENT_FEE_NEW_1ST_TD]:
    "Title - Wire / Disbursement fee New 1st TD",
  [TITLE_WIRE_DISBURSEMENT_FEE_NEW_2ND_TD]:
    "Title - Wire / Disbursement fee New 2nd TD",
  [VA_FUNDING_FEE]: "VA Funding Fee",
  [ATTORNEY_FEE]: "Attorney Fee",
  [MOBILE_NOTARY_FEE]: "Mobile Notary Fee",
  [NOTARY_FEE]: "Notary Fee"
});
