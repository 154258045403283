import React from "react";
import PercentField from "../../fields/percent_field";
import CurrencyField from "../../fields/currency_field";
import {
  additional_funds_to_from_borrower,
  apr,
  estimated_cash_to_close,
  in_five_years_principal_paid_off,
  in_five_years_total_paid,
  total_interest_percentage
} from "../../../calculations/loan";
import { useDocument } from "../../loan_tools/loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";

export default function Comparisons() {
  const [
    {
      loans,
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];

  return (
    <>
      <tbody className="loan-summaries">
        <tr>
          <td className="summary-header" colSpan="3">
            Comparisons
          </td>
          <td className="left-align" colSpan="5">
            (Page 3 of Loan Estimates)
          </td>
        </tr>
        {isPurchase && (
          <tr>
            <td className="right-align bold" colSpan="3">
              Rate:
            </td>
            {fiveLoans.map((loan, index) => (
              <td key={index}>
                {loan && (
                  <PercentField naked decimalPlaces={3} value={loan.rate} />
                )}
              </td>
            ))}
          </tr>
        )}
      </tbody>
      <tbody className="loan-summaries with-border">
        <tr>
          <td className="bold">In 5 Years</td>
          <td className="small-font" colSpan="2">
            Total Paid (PITI, MI and Closing Fee&apos;s)
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={in_five_years_total_paid(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td />
          <td colSpan="2">Principal you will have Paid Off</td>
          {fiveLoans.map((loan, index) => {
            let principalPaidOff;
            if (loan) {
             principalPaidOff = in_five_years_principal_paid_off(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
              );
            }
            return (
                <td key={index}>
                  {loan && (
                    <CurrencyField
                      naked
                      allowEmpty={true}
                      fixedDigits={0}
                      value={!principalPaidOff.isNaN() ? principalPaidOff : ""}
                    />
                  )}
                </td>
            );
          })}
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">Annual Percentage Rate (APR)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={3}
                  value={apr(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">Total Interest Percentage (TIP)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={3}
                  value={total_interest_percentage(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        {isPurchase && (
          <tr>
            <td colSpan="3">Estimated Cash to Close</td>
            {fiveLoans.map((loan, index) => (
              <td className="bottom-border" key={index}>
                {loan && (
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={estimated_cash_to_close(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property,
                      accountSummaries,
                      residences
                    )}
                  />
                )}
              </td>
            ))}
          </tr>
        )}
        {isRefinance && (
          <>
            <tr>
              <td className="bottom-border" colSpan="3">
                Estimated Cash From Borrower to Close
              </td>
              {fiveLoans.map((loan, index) => {
                if (!loan) {
                  return <td className="bottom-border" />;
                }
                const additionalFundsToFromBorrower = additional_funds_to_from_borrower(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                );
                return (
                  <td className="bottom-border bold" key={index}>
                    {loan && additionalFundsToFromBorrower.lte(0) && (
                      <CurrencyField
                        naked
                        fixedDigits={0}
                        value={additionalFundsToFromBorrower}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
            <tr>
              <td colSpan="3">
                Estimated Cash <span className="bold italic">TO</span> Borrower
              </td>
              {fiveLoans.map((loan, index) => {
                if (!loan) {
                  return <td />;
                }
                const additionalFundsToFromBorrower = additional_funds_to_from_borrower(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property,
                  accountSummaries,
                  residences
                );
                return (
                  <td className="bold" key={index}>
                    {loan && additionalFundsToFromBorrower.gt(0) && (
                      <CurrencyField
                        naked
                        fixedDigits={0}
                        value={additionalFundsToFromBorrower}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          </>
        )}
      </tbody>
    </>
  );
}
