export function getHeaders() {
  return new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.access_token}`
  });
}

export function handleResponse(response) {
  if (!response.ok) {
    throw response;
  }

  return response.json();
}

export function handleErrorMessages(error, setErrorMessages) {
  try {
    error.json().then(messages => {
      setErrorMessages(messages || ["There was an error."]);
    });
  } catch (e) {
    setErrorMessages(["There was an error."]);
  }
}

export function removeAccessToken() {
  if (localStorage.access_token) {
    localStorage.removeItem("access_token");
  }
}

export function setAccessToken(token) {
  if (token) {
    localStorage.setItem("access_token", token);
  }
}

export function getAccessToken() {
  return localStorage.access_token;
}
