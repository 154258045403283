import React from "react";
import "./css/page_footer.css";

export default function PageFooter({ currentPage, totalPage, loanId }) {
  return (
    <div className="page-footer">
      <div>LOAN ESTIMATE</div>
      <div className="footer-info">
        <span>
          Page {currentPage} of {totalPage}
        </span>
        <span>LOAN ID #</span>
        <span>{loanId}</span>
      </div>
    </div>
  );
}
