import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  first_mortgage_payment_p_and_i,
  loan_amount_first_mortgage
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import { ARM_TERMS } from "../../../constants/loan_terms";
import NumberField from "../../fields/number_field";
import { YES, YES_NO_TBD } from "../../../constants/yes_no";
import {
  arm_adjust_as_high_as_rate,
  arm_adjust_starting_in_year
} from "../../../calculations/trid";
import { useDocument } from "../loan_tool";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import { month_to_year } from "../../../calculations/utils";

export default function LoanTerms({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const isArm = ARM_TERMS.includes(loan.term);
  const isInterestOnly = loan.payment_type === INTEREST_ONLY;

  return (
    <table className="info-table">
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
        <col />
      </colgroup>
      <tbody className="no-top-border no-bottom-border with-border with-td-border">
        <tr>
          <td className="no-border summary-header">Loan Terms</td>
          <td className="no-border" colSpan="2" />
          <td className="no-border normal-font gray-bg" colSpan="3">
            Can this amount increase after closing?
          </td>
          <td className="no-border" colSpan="3" />
        </tr>
        <tr>
          <td className="bold">Loan Amount</td>
          <td className="center-align no-right-border" colSpan="2">
            <CurrencyField
              naked
              fixedDigits={0}
              value={loan_amount_first_mortgage(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </td>
          <td className="center-align no-right-border no-left-border">No</td>
          <td className="no-right-border no-left-border" colSpan="5" />
        </tr>
        <tr>
          <td className="bold">Interest Rate</td>
          <td
            className="center-align no-left-border no-right-border"
            colSpan="2"
            rowSpan="3"
          >
            <PercentField naked decimalPlaces={3} value={loan.rate} />
          </td>
          <td className="center-align no-left-border" rowSpan="3">
            {isArm ? "Yes" : "No"}
          </td>
          <td className="no-border" colSpan="3">
            {isArm && "Adjust Every Year starting in year"}
          </td>
          <td className="center-align no-border">
            {isArm && (
              <NumberField naked value={arm_adjust_starting_in_year(loan)} />
            )}
          </td>
          <td className="no-border" />
        </tr>
        <tr>
          <td rowSpan="2" />
          <td className="no-border">{isArm && "As High as"}</td>
          <td className="center-align no-border">
            {isArm && (
              <PercentField
                naked
                fixedDigits={3}
                value={arm_adjust_as_high_as_rate(loan)}
              />
            )}
          </td>
          <td className="center-align no-border">{isArm && "in year"}</td>
          <td className="center-align no-border">
            {isArm && (
              <NumberField naked value={arm_adjust_starting_in_year(loan)} />
            )}
          </td>
          <td className="no-border" />
        </tr>
        <tr>
          <td className="bottom-border no-border" colSpan="5">
            {isArm && "See AIR table on page 2 for details"}
          </td>
        </tr>
        <tr>
          <td className="no-bottom-border bold">
            Monthly Principal & Interest
          </td>
          <td
            className="center-align no-left-border no-right-border no-bottom-border"
            colSpan="2"
            rowSpan="3"
          >
            <CurrencyField
              naked
              fixedDigits={2}
              value={first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </td>
          <td
            className="center-align no-left-border no-bottom-border"
            rowSpan="3"
          >
            {isArm || isInterestOnly ? "Yes" : "No"}
          </td>
          <td className="no-border" colSpan="3">
            {isInterestOnly && <div>* Adjusts once in Year</div>}
            {isArm && <div>Adjust Every Year starting in year</div>}
          </td>
          <td className="no-border center-align">
            {isInterestOnly && (
              <div>
                <NumberField
                  naked
                  value={month_to_year(loan.interest_only_period).add(1)}
                />
              </div>
            )}
            {isArm && (
              <div>
                <NumberField naked value={arm_adjust_starting_in_year(loan)} />
              </div>
            )}
          </td>
          <td className="no-border" />
        </tr>
        <tr>
          <td className="no-top-border no-bottom-border smaller-font italic">
            See Projected Payments Below
          </td>
          <td className="no-border" colSpan="2">
            {isInterestOnly && <div>* Goes as high as</div>}
            {isArm && <div>Can go as High as</div>}
          </td>
          <td className="center-align no-border">
            {isInterestOnly && (
              <div>
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={first_mortgage_payment_p_and_i(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </div>
            )}
            {isArm && (
              <div>
                <PercentField
                  naked
                  fixedDigits={3}
                  value={arm_adjust_as_high_as_rate(loan)}
                />
              </div>
            )}
          </td>
          <td className="center-align no-border">
            {isInterestOnly && <div>in year</div>}
            {isArm && <div>in year</div>}
          </td>
          <td className="center-align no-border">
            {isInterestOnly && (
              <div>
                <NumberField
                  naked
                  value={month_to_year(loan.interest_only_period).add(1)}
                />
              </div>
            )}
            {isArm && (
              <div>
                <NumberField naked value={arm_adjust_starting_in_year(loan)} />
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td className="no-top-border no-bottom-border smaller-font italic">
            for Your Total Monthly Payment
          </td>
          <td className="no-border" colSpan="3">
            {isInterestOnly && (
              <div>* Includes only interest and no principal until year</div>
            )}
            {isArm && <div>See AIR table on page 2 for details</div>}
          </td>
          <td className="center-align no-border">
            {isInterestOnly && (
              <NumberField
                naked
                value={month_to_year(loan.interest_only_period).add(1)}
              />
            )}
          </td>
          <td className="no-border" />
        </tr>
        {isInterestOnly && (
          <tr>
            <td className="no-top-border no-bottom-border" />
            <td
              className="no-left-border no-top-border no-bottom-border"
              colSpan="3"
            />
            <td className="no-border" colSpan="5">
              * See AP Table on page 2 for details
            </td>
          </tr>
        )}
        <tr>
          <td className="no-bottom-border" />
          <td className="top-border no-border" colSpan="2" />
          <td className="top-border no-border normal-font gray-bg" colSpan="3">
            Does the loan have these features?
          </td>
          <td className="top-border no-border" colSpan="3" />
        </tr>
        <tr>
          <td className="no-border right-border bold">Prepayment Penalty</td>
          <td className="no-border" colSpan="2" />
          <td className="center-align no-border">
            {YES_NO_TBD[loan.prepayment_penalty]}
          </td>
          <td className="no-border" colSpan="4">
            {loan.prepayment_penalty === YES &&
              "Prepayment Penalty Amount will vary depending on Terms:"}
          </td>
          <td className="no-border">
            {loan.prepayment_penalty === YES && loan.prepayment_terms}
          </td>
        </tr>
        <tr>
          <td className="bold">Balloon Payment</td>
          <td className="no-right-border" colSpan="2" />
          <td className="no-left-border no-right-border center-align">
            {YES_NO_TBD[loan.balloon_payment]}
          </td>
          <td className="no-left-border no-right-border" colSpan="4">
            {loan.balloon_payment === YES &&
              "The Balloon Payment Amount will be due month"}
          </td>
          <td className="no-left-border">
            {loan.balloon_payment === YES && loan.balloon_payment_period}
          </td>
        </tr>
        <tr>
          <td className="no-border" colSpan="9" />
        </tr>
      </tbody>
    </table>
  );
}
