import React, { useEffect, useRef, useState } from "react";

import { total_lpmi_adjustment_fees } from "../../../calculations/loan";

import PercentField from "../../fields/percent_field";
import Section from "../../ui/section";
import { useDocument } from "../loan_tool";

export default function LPMIAdjustmentFees({ loan, goBack, updateLoan }) {
  const [,,,{ saveDocument }] = useDocument();
  const [loanEdit, setLoanEdit] = useState({ ...loan });
  const { loan_options } = loanEdit;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (loanEdit.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in LPMI Adjustments Form. Would you like to save it before you leave?"
          )
        ) {
          save();
        } else {
          // do nothing
        }
      }
    };
  }, [loanEdit]);

  useEffect(() => {
    return () => {
      callback.current();
    };
  }, []);

  function save() {
    delete loanEdit.is_modified;
    updateLoan(loanEdit);
    saveDocument();
    goBack();
  }

  function cancel() {
    goBack();
  }

  function updateLoanOption(updatedState) {
    setLoanEdit({
      ...loanEdit,
      loan_options: {
        ...loanEdit.loan_options,
        ...updatedState
      },
      is_modified: true
    });
  }

  return (
    <Section className="down-payment-edit-modal" name="LPMI Adjustment Fees">
      <PercentField
        name="LPMI Adjustment Base Loan"
        fieldName="lpmi_adjustment_base_loan"
        value={loan_options.lpmi_adjustment_base_loan}
        onChange={updateLoanOption}
      />

      <PercentField
        name="Term 25 years and less"
        fieldName="term_25_years_and_less"
        value={loan_options.term_25_years_and_less}
        onChange={updateLoanOption}
      />

      <PercentField
        name="Cash out"
        fieldName="cash_out"
        value={loan_options.cash_out}
        onChange={updateLoanOption}
      />

      <PercentField
        name="R&amp;T Refinance"
        fieldName="r_t_refinance"
        value={loan_options.r_t_refinance}
        onChange={updateLoanOption}
      />

      <PercentField
        name="Second Home"
        fieldName="second_home"
        value={loan_options.second_home}
        onChange={updateLoanOption}
      />

      <PercentField
        name="Non-Owner"
        fieldName="non_owner"
        value={loan_options.non_owner}
        onChange={updateLoanOption}
      />

      <PercentField
        name="Loan Amount > $424,100"
        fieldName="loan_amount_greater_than_424100"
        value={loan_options.loan_amount_greater_than_424100}
        onChange={updateLoanOption}
      />

      <PercentField
        readOnly
        name="Total LPMI Adjustment Fees"
        value={total_lpmi_adjustment_fees(loanEdit)}
      />
      <button className="button" onClick={cancel}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
