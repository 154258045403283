import React from "react";

import CurrencyField from "../../fields/currency_field";
import {
  rcd_fees,
  recording_fees_and_other_taxes,
  total_taxes_and_other_government_fees,
  transfer_tax_purchase,
  transfer_taxes_calculator
} from "../../../calculations/loan";
import NumberField from "../../fields/number_field";
import { NON_OWNER } from "../../../constants/occupancy";
import Decimal from "decimal.js";
import {
  RCD_FEE_GD,
  RCD_FEE_GD_TR,
  RCD_FEE_TD
} from "../../../constants/loan_fees";
import PercentField from "../../fields/percent_field";
import TrainingTip from "../../ui/training_tip";
import { useDocument } from "../loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";

export default function TaxesAndOtherGovernmentFees({
  loan,
  property,
  readOnly,
  updateLoan,
  showTransferTaxesCalculator
}) {
  const [, , { documentType }] = useDocument();

  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;

  function isNonOwner() {
    return property.occupancy === NON_OWNER;
  }

  function isInOutTrust() {
    return new Decimal(loan.in_out_trust).gt(0);
  }

  function isRcdFees() {
    return isNonOwner() || isInOutTrust();
  }

  return (
    <div className="taxes-and-other-government-fees">
      <div className="closing-costs-row gray-bg">
        <div className="flex-space-between flex-grow align-items-center">
          <div className="flex align-items-center">
            <div className="section-title">
              E. Taxes and Other Government Fees
            </div>
            <TrainingTip content="LANIS Training Tip:  Recording Fee and other taxes 10%.  Transfer Taxes 0%." />
          </div>
          {isPurchase && (
            <button className="button" onClick={showTransferTaxesCalculator}>
              Transfer Taxes Calculator
            </button>
          )}
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_taxes_and_other_government_fees(
            documentType,
            loan,
            property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div>Recording Fees and Other Taxes</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={recording_fees_and_other_taxes(loan)}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell">Trust Deed</div>
        <CurrencyField
          className="center-align"
          displayFixedDigits={0}
          fieldName="trust_deed"
          onChange={updateLoan}
          value={loan.trust_deed}
          readOnly={readOnly}
        />
        <div className="closing-costs-cell-shell center-align">Grand Deed</div>
        <CurrencyField
          className="center-align"
          displayFixedDigits={0}
          fieldName="grand_deed"
          onChange={updateLoan}
          value={loan.grand_deed}
          readOnly={readOnly}
        />
        <div className="closing-costs-cell-shell center-align">
          In/Out Trust
        </div>
        <div className="flex">
          <CurrencyField
            className="center-align"
            displayFixedDigits={0}
            fieldName="in_out_trust"
            onChange={updateLoan}
            value={loan.in_out_trust}
            readOnly={readOnly}
          />
          <div className="closing-costs-cell" />
        </div>
      </div>
      {isRefinance && (
        <div className="closing-costs-row">
          <div>CA Affordable Housing Recording Fee Co. Sur Charge</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            displayFixedDigits={0}
            fieldName="ca_affordable_housing_recording_fee_co_sur_charge"
            onChange={updateLoan}
            value={loan.ca_affordable_housing_recording_fee_co_sur_charge}
            readOnly={readOnly}
          />
        </div>
      )}
      {isPurchase && (
        <>
          <div className="closing-costs-row">
            <div className="closing-costs-cell-shell double-width">
              Transfer Tax - Purchase
            </div>
            <CurrencyField
              className="closing-costs-cell center-align gray-bg"
              naked
              fixedDigits={0}
              value={loan.transfer_taxes.base_value_k}
            />
            <div className="closing-costs-cell-shell center-align">
              Base Value
            </div>
            <NumberField
              className="closing-costs-cell center-align gray-bg"
              naked
              value={transfer_taxes_calculator.transfer_total(
                documentType,
                loan,
                loan.transfer_taxes,
                property
              )}
            />
            <div className="closing-costs-cell-shell center-align">
              Per Thousand
            </div>
            <CurrencyField
              className="closing-costs-cell right-align gray-bg"
              naked
              fixedDigits={0}
              value={transfer_tax_purchase(documentType, loan, property)}
            />
          </div>
          <div className="closing-costs-row flex-start flex-reverse-direction">
            <div className="closing-costs-cell" />
            <div className="closing-costs-cell center-align">Base Value</div>
            <div className="closing-costs-cell center-align">Buyer/Seller</div>
          </div>
          <div className="closing-costs-row">
            <div className="closing-costs-cell-shell">Country:</div>
            <CurrencyField
              className="closing-costs-cell center-align gray-bg"
              naked
              fixedDigits={0}
              value={transfer_taxes_calculator.county_transfer_amount(
                documentType,
                loan,
                loan.transfer_taxes,
                property
              )}
            />
            <div className="closing-costs-cell-shell center-align">City:</div>
            <CurrencyField
              className="closing-costs-cell center-align gray-bg"
              naked
              fixedDigits={0}
              value={transfer_taxes_calculator.city_transfer_amount(
                documentType,
                loan,
                loan.transfer_taxes,
                property
              )}
            />
            <CurrencyField
              className="closing-costs-cell center-align gray-bg"
              naked
              fixedDigits={0}
              value={transfer_taxes_calculator.transfer_total(
                documentType,
                loan,
                loan.transfer_taxes,
                property
              )}
            />
            <PercentField
              className="closing-costs-cell center-align gray-bg"
              naked
              fixedDigits={2}
              value={loan.transfer_taxes.percent_split}
            />
            <div className="closing-costs-cell" />
          </div>
          {isRcdFees() && (
            <div className="closing-costs-row">
              <div className="closing-costs-cell-shell">
                {isNonOwner() && "Rcd Fee TD"}
              </div>
              <CurrencyField
                className="closing-costs-cell center-align gray-bg"
                naked
                fixedDigits={0}
                value={isNonOwner() ? RCD_FEE_TD : 0}
              />
              <div className="closing-costs-cell-shell center-align">
                {isNonOwner() && "Rcd Fee GD"}
              </div>
              <CurrencyField
                className="closing-costs-cell center-align gray-bg"
                naked
                fixedDigits={0}
                value={isNonOwner() ? RCD_FEE_GD : 0}
              />
              <div className="closing-costs-cell-shell center-align">
                {isInOutTrust() && "Rcd Fee GD Tr"}
              </div>
              <CurrencyField
                className="closing-costs-cell center-align gray-bg"
                naked
                fixedDigits={0}
                value={isInOutTrust() ? RCD_FEE_GD_TR : 0}
              />
              <CurrencyField
                className="closing-costs-cell right-align gray-bg"
                naked
                fixedDigits={0}
                value={rcd_fees(loan, property)}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
