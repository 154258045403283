import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  estimated_cash_to_close,
  estimated_closing_costs_financed,
  estimated_total_payoffs_and_payments,
  estimated_total_payoffs_and_payments_debt,
  estimated_total_payoffs_and_payments_mortgage,
  loan_amount_first_mortgage,
  total_closing_costs
} from "../../../calculations/loan";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import { YES } from "../../../constants/yes_no";
import { useDocument } from "../loan_tool";
import TrainingTip from "../../ui/training_tip";
import { is_fha, is_usda, is_va } from "../../../constants/utils";

export default function RefinanceCalculatingCashToClose({ loan }) {
  const [
    {
      property_to_be_sold,
      is_selling_property,
      property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  return (
    <div className="calculating-cash-to-close">
      <div className="closing-costs-row bottom-border">
        <div className="main-title">Calculating Cash to Close</div>
      </div>
      <div className="closing-costs-row">
        <div>Loan Amount</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={loan_amount_first_mortgage(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div>Total Closing Costs (J)</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={total_closing_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          ).neg()}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell quad-width">
          Estimated Total Payoffs and Payments
        </div>
        <div className="closing-costs-cell center-align gray-dashed-border">
          Mortgage
        </div>
        <div className="closing-costs-cell center-align gray-dashed-border">
          Debt
        </div>
        <div className="closing-costs-cell" />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell quad-width" />
        {estimated_total_payoffs_and_payments_mortgage(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        ).eq(0) ? (
            <div className="closing-costs-cell no-top gray-bg" />
          ) : (
            <CurrencyField
              className="closing-costs-cell center-align no-top gray-bg"
              naked
              fixedDigits={0}
              value={estimated_total_payoffs_and_payments_mortgage(
                documentType,
                loan,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        {estimated_total_payoffs_and_payments_debt(
          accountSummaries,
          residences,
          loan
        ).eq(0) ? (
            <div className="closing-costs-cell no-top gray-bg" />
          ) : (
            <CurrencyField
              className="closing-costs-cell center-align no-top gray-bg"
              naked
              fixedDigits={0}
              value={estimated_total_payoffs_and_payments_debt(
                accountSummaries,
                residences,
                loan
              )}
            />
          )}
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={estimated_total_payoffs_and_payments(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property,
            accountSummaries,
            residences
          )}
        />
      </div>
      <div className="closing-costs-row top-border bottom-border overflow-hidden">
        <div className="closing-costs-cell-shell double-width bold">
          Estimated Cash to Close
        </div>
        <div className="closing-costs-cell center-align bold">
          {estimated_cash_to_close(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property,
            accountSummaries,
            residences
          ).lt(0) && "X"}
        </div>
        <div className="closing-costs-cell">From</div>
        <div className="closing-costs-cell center-align bold">
          {estimated_cash_to_close(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property,
            accountSummaries,
            residences
          ).gt(0) && "X"}
        </div>
        <div className="closing-costs-cell">To Borrower</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={estimated_cash_to_close(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property,
            accountSummaries,
            residences
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Estimated Closing Costs Financed{" "}
          <TrainingTip content="LANIS Training Tip: It's important to disclose to the Borrower(s) when the UPMIP or Funding Fee has been added to the Loan Amount. Borrower(s) will need to understand their Loan Amount has been increased." />
        </div>
        <div className="closing-costs-cell center-align green-text">
          {is_va(loan.type) &&
            estimated_closing_costs_financed(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell center-align green-text">
          {loan.is_ufmip_financed === YES &&
            is_fha(loan.type) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell center-align green-text">
          {loan.is_ufmip_financed === YES &&
            is_usda(loan.type) &&
            LOAN_TYPE_CATEGORY_NAMES[loan.type]}
        </div>
        <div className="closing-costs-cell" />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          (Paid from your Loan Amount)
        </div>
        <div className="closing-costs-cell center-align">
          {is_va(loan.type) &&
            estimated_closing_costs_financed(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).gt(0) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        <div className="closing-costs-cell center-align">
          {loan.is_ufmip_financed === YES && is_fha(loan.type) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        <div className="closing-costs-cell center-align">
          {loan.is_ufmip_financed === YES && is_usda(loan.type) && (
            <CurrencyField
              className="closing-costs-cell center-align green-text"
              naked
              fixedDigits={0}
              value={estimated_closing_costs_financed(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>
        {estimated_cash_to_close(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property,
          accountSummaries,
          residences
        ).gt(0) ? (
            <CurrencyField
              className="closing-costs-cell right-align"
              naked
              fixedDigits={0}
              value={total_closing_costs(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          ) : (
            <div className="closing-costs-cell" />
          )}
      </div>
    </div>
  );
}
