import React, { useState } from "react";
import TextField from "../components/fields/text_field";
import UserService from "../services/user_service";
import StandardTemplate from "../templates/standard_template/standard_template";
import "./css/password_reset.scss";

export default function PasswordResetRequest() {
  const [email, setEmail] = useState("");
  const [isRequestSubmitted, setRequestSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async () => {
    setErrorMessage("");
    try {
      await UserService.postPasswordResetRequest(email);
      setRequestSubmitted(true);
    } catch (e) {
      setErrorMessage("Request failed, please try again later");
    }
  };

  async function onEnterSubmit(event) {
    if (event.key === "Enter") {
      await onSubmit();
    }
  }

  return (
    <StandardTemplate className="password-reset">
      <div className="password-reset-title">Password Reset Request</div>
      <div className="password-reset-content flex-direction-column align-items-center">
        <div className="error-message">{errorMessage}</div>
        {!isRequestSubmitted && (
          <div className="password-reset-action">
            <TextField
              typeOverride="email"
              name="Account Email"
              fieldName="email"
              value={email}
              onChange={value => setEmail(value.email)}
              onKeyPress={onEnterSubmit}
            />
            <div className="right-align">
              <button className="button" onClick={onSubmit}>
                Submit
              </button>
            </div>
          </div>
        )}
        {isRequestSubmitted && (
          <div className="center-align">
            Please check your email on instruction to reset your password!
          </div>
        )}
      </div>
    </StandardTemplate>
  );
}
