import React from "react";
import {
  basic_monthly,
  // basic_monthly_2,
  basic_yearly
} from "../../constants/subscription_products";

export default function PaymentPlans({
  product,
  setProduct
}) {
  return (
    <div className="signup-payment-plans">
      <div className="title">Payment Plans</div>
      <div className="plan-title">
        Basic Professional Plans
      </div>
      <div className="plan-price">
        <input
          type="radio"
          name="products"
          id="basic-monthly"
          checked={product === basic_monthly}
          value={basic_monthly}
          onChange={e => setProduct(e.target.value)}
        />
        <label htmlFor="basic-monthly">
          Monthly $85.00
        </label>
      </div>
      {/*<div className="plan-price">*/}
      {/*  <input*/}
      {/*    type="radio"*/}
      {/*    name="products"*/}
      {/*    id="basic-monthly-2"*/}
      {/*    checked={product === basic_monthly_2}*/}
      {/*    value={basic_monthly_2}*/}
      {/*    onChange={e => setProduct(e.target.value)}*/}
      {/*  />*/}
      {/*  <label htmlFor="basic-monthly-2">*/}
      {/*    Monthly $95.00*/}
      {/*    <br/>*/}
      {/*    (Price Includes 1 Dedicated Sub-Account)*/}
      {/*  </label>*/}
      {/*</div>*/}
      <div className="plan-price">
        <input
          type="radio"
          name="products"
          id="basic-yearly"
          checked={product === basic_yearly}
          value={basic_yearly}
          onChange={e => setProduct(e.target.value)}
        />
        <label htmlFor="basic-yearly">
          Annual $1,020.00
          <br/>
          (Includes 1 Dedicated Loan Processor Sub-Account FREE for the first year, a $120.00 Value)
        </label>
      </div>
      <div>
        LANIS is offering a 14 Day Free Trial.  Your Credit Card will not be charged today,
        <br />
        it will be charged automatically on the evening of the 14th day.
        <br />
        <br />
        If you have a Special Promo Code, only enter your Promo Code,
        <br />
        No Credit Card is Required.
      </div>
    </div>
  );
}
