import { SELF_EMPLOYED } from "../../constants/wage_forms";
import {
  FNM,
  LANIS_ACCOUNT_SUB_TYPE,
  LANIS_ADDITIONAL_INCOME_TYPES, LANIS_CURRENTLY_RENTING_OR_OWNING,
  LANIS_LIABILITY_TYPE,
  LANIS_MARITAL_STATUS
} from "../fnm_constants";
import { income_source_yearly_amount, year_to_month_income } from "../../calculations/income";
import { AUTOMOBILE, LIFE_INSURANCE_CASH_VALUE } from "../../constants/reserves_account_types";
import { PERCENT_CREDITED_AGENCIES } from "../../constants/reserves_percentage_credited";
import { summary_selected_loan } from "../../constants/utils";
import { OMITTED, RESUBORDINATED, WILL_BE_PAID_OFF } from "../../constants/account_dispositions";
import { PURCHASE } from "../../constants/document_types";
import * as RESERVES_ACCOUNT_TYPES from "../../constants/reserves_account_types";
import { filterParseFnmDecimal, formatFnmDecimal, parseFnmDecimal } from "../util";

export function getBorrowers({ document }) {
  return document.applicant.borrowers;
}

export function exportTitleholderName({ item: borrower }) {
  const borrowerFullName = [
    borrower.first_name,
    borrower.middle_name,
    borrower.last_name,
    borrower.suffix
  ].filter(Boolean).join(" ");
  return {
    titleholderName: borrowerFullName
  }
}

export function exportApplicantData({ document, item: borrower }) {
  const { borrowers } = document.applicant;
  const borrowerIndex = borrowers.indexOf(borrower);
  let applicantCoApplicantIndicator = FNM.APPLICANT_CO_APPLICANT_INDICATOR.APPLICANT,
      crossReferenceNumber;
  // Check for "none" for legacy purposes
  if (borrower.co_borrower && borrower.co_borrower !== "none") {
    const coBorrowerIndex = parseFnmDecimal(borrower.co_borrower).toNumber();
    const coBorrower = borrowers[coBorrowerIndex];
    if (coBorrower) {
      crossReferenceNumber = coBorrower.ssn;
      if (borrowerIndex > coBorrowerIndex) {
        applicantCoApplicantIndicator = FNM.APPLICANT_CO_APPLICANT_INDICATOR.CO_APPLICANT;
      }
    }
  }
  return {
    applicantCoApplicantIndicator,
    applicantSocialSecurityNumber: borrower.ssn,
    applicantFirstName: borrower.first_name,
    applicantMiddleName: borrower.middle_name,
    applicantLastName: borrower.last_name,
    applicantGeneration: borrower.suffix,
    homePhone: removePhoneFormat(borrower.home_phone),
    crossReferenceNumber,
    // TODO: Can this be unset?
    maritalStatus: LANIS_MARITAL_STATUS.export[borrower.marital_status],
    emailAddress: borrower.email,
  }
}

export function exportApplicantAddress({ item: borrower }) {
  const residenceStreetAddress = [
    borrower.current_address,
    borrower.current_address_2
  ].filter(Boolean).join(" ");
  return {
    applicantSocialSecurityNumber: borrower.ssn,
    presentFormer: FNM.PRESENT_FORMER_CODE.PRESENT_ADDRESS,
    residenceStreetAddress,
    residenceCity: borrower.city,
    residenceState: borrower.state,
    residenceZipCode: borrower.zip_code,
    ownRentLivingRentFree: LANIS_CURRENTLY_RENTING_OR_OWNING.export[borrower.currently_renting_or_owning],
    noYrs: borrower.how_long_in_current_residence,
  }
}

export function exportPrimaryCurrentEmployer({ item: borrower }) {
  let employerName,
      employerStreetAddress,
      employerCity,
      employerState,
      employerZipCode,
      selfEmployed,
      yrsOnThisJob,
      monthsOnThisJob,
      yrsEmployedInThisLineOfWorkProfession,
      positionTitleTypeOfBusiness,
      businessPhone;
  const incomeSource = borrower.income.sources[0];
  if (!incomeSource) {
    return;
  }
  employerName = incomeSource.employment_information.employer_name;
  employerStreetAddress = incomeSource.employment_information.address;
  employerCity = incomeSource.employment_information.city;
  employerState = incomeSource.employment_information.state;
  employerZipCode = incomeSource.employment_information.zip;
  selfEmployed = incomeSource.w2_or_self_employed === SELF_EMPLOYED ? 'Y' : 'N';
  yrsOnThisJob = incomeSource.years_on_the_current_job;
  monthsOnThisJob = incomeSource.months_on_the_current_job;
  yrsEmployedInThisLineOfWorkProfession = incomeSource.employment_information.line_of_work_years;
  positionTitleTypeOfBusiness = incomeSource.employment_information.position;
  if (incomeSource.employment_information.phone) {
    businessPhone = removePhoneFormat(incomeSource.employment_information.phone);
  }
  return {
    applicantSocialSecurityNumber: borrower.ssn,
    employerName,
    employerStreetAddress,
    employerCity,
    employerState,
    employerZipCode,
    selfEmployed,
    yrsOnThisJob,
    monthsOnThisJob,
    yrsEmployedInThisLineOfWorkProfession,
    positionTitleTypeOfBusiness,
    businessPhone,
  }
}

export function exportIncome({ item: borrower }) {
  const applicantSocialSecurityNumber = borrower.ssn;
  const sources = [];
  const { primaryIncomeSource } = getPrimaryAndSecondaryIncomeSources({ borrower });
  if (primaryIncomeSource) {
    const base = year_to_month_income(primaryIncomeSource.base_income_0),
        overtime = year_to_month_income(primaryIncomeSource.overtime_0),
        bonuses = year_to_month_income(primaryIncomeSource.bonuses_0),
        commissions = year_to_month_income(primaryIncomeSource.commission_0);

    function addIncomeIfPresent(amount, type) {
      const amountStr = formatFnmDecimal(amount);
      if (amountStr) {
        sources.push({
          applicantSocialSecurityNumber,
          typeOfIncomeCode: type,
          incomeAmountMonthlyIncome: amountStr
        });
      }
    }

    addIncomeIfPresent(base, FNM.INCOME_CODE.BASE_EMPLOYMENT_INCOME);
    addIncomeIfPresent(overtime, FNM.INCOME_CODE.OVERTIME);
    addIncomeIfPresent(bonuses, FNM.INCOME_CODE.BONUSES);
    addIncomeIfPresent(commissions, FNM.INCOME_CODE.COMMISSIONS);
  }
  borrower.income.other_income.forEach(income => {
    const typeOfIncomeCode = LANIS_ADDITIONAL_INCOME_TYPES.export[income.income_type];
    let incomeAmountMonthlyIncome = filterParseFnmDecimal(income.yearly_amount);
    if (incomeAmountMonthlyIncome) {
      incomeAmountMonthlyIncome = incomeAmountMonthlyIncome.div(12);
    }
    incomeAmountMonthlyIncome = formatFnmDecimal(incomeAmountMonthlyIncome);
    sources.push({
      applicantSocialSecurityNumber,
      typeOfIncomeCode,
      incomeAmountMonthlyIncome,
    });
  });
  return sources;
}

export function exportSecondaryEmployer({ item: borrower }) {
  const applicantSocialSecurityNumber = borrower.ssn;
  const sources = [];
  const { secondaryIncomeSources } = getPrimaryAndSecondaryIncomeSources({ borrower });
  secondaryIncomeSources.forEach(incomeSource => {
    let monthlyIncome = filterParseFnmDecimal(income_source_yearly_amount(incomeSource));
    if (monthlyIncome) {
      monthlyIncome = monthlyIncome.div(12);
    }
    monthlyIncome = formatFnmDecimal(monthlyIncome);
    sources.push({
      applicantSocialSecurityNumber,
      employerName: incomeSource.employment_information.employer_name,
      employerStreetAddress: incomeSource.employment_information.address,
      employerCity: incomeSource.employment_information.city,
      employerState: incomeSource.employment_information.state,
      employerZipCode: incomeSource.employment_information.zip,
      selfEmployed: incomeSource.w2_or_self_employed === SELF_EMPLOYED ? 'Y' : 'N',
      currentEmploymentFlag: "Y",
      fromDate: null, // TODO: We can't really do this, since tests will fall out of sync each year
      monthlyIncome,
      positionTitleTypeOfBusiness: incomeSource.employment_information.position,
      businessPhone: removePhoneFormat(incomeSource.employment_information.phone),
    });
  });
  return sources;
}

function getPrimaryAndSecondaryIncomeSources({ borrower }) {
  const primaryIncomeSource = borrower.income.sources
      .slice(0)
      .sort((incomeSourceA, incomeSourceB) => {
        const payA = income_source_yearly_amount(incomeSourceA);
        const payB = income_source_yearly_amount(incomeSourceB);
        return payB - payA;
      })[0];
  const secondaryIncomeSources = borrower.income.sources
      .filter(incomeSource => incomeSource !== primaryIncomeSource);
  return {
    primaryIncomeSource,
    secondaryIncomeSources
  }
}

export function exportLifeInsurance({ item: borrower }) {
  const applicantSocialSecurityNumber = borrower.ssn;
  return borrower.reserves
      .filter(reserve => reserve.sub_type === LIFE_INSURANCE_CASH_VALUE)
      .map(reserve => {
        const lifeInsuranceCashOrMarketValue = formatFnmDecimal(reserve.funds);
        return {
          applicantSocialSecurityNumber,
          lifeInsuranceCashOrMarketValue,
        }
      });
}

export function exportAssets({ item: borrower }) {
  const applicantSocialSecurityNumber = borrower.ssn;
  return borrower.reserves
      // These two are handled as automobiles and life insurance, respectively
      .filter(reserve => reserve.sub_type !== AUTOMOBILE
          && reserve.sub_type !== LIFE_INSURANCE_CASH_VALUE)
      .map(reserve => {
        const creditPercentStr = PERCENT_CREDITED_AGENCIES[reserve.sub_type];
        let cashOrMarketValue,
            depositoryStockBondInstitutionName,
            assetDescription;
        if (creditPercentStr) {
          let creditPercent = filterParseFnmDecimal(creditPercentStr);
          const reserveFunds = filterParseFnmDecimal(reserve.funds);
          if (creditPercent && reserveFunds) {
            creditPercent = creditPercent.dividedBy(100);
            cashOrMarketValue = formatFnmDecimal(reserveFunds.times(creditPercent));
          }
        } else {
          console.warn("Detected unmapped reserve sub-type for credit percentages: ", reserve.sub_type);
        }
        if (reserve.sub_type === RESERVES_ACCOUNT_TYPES.OTHER_TYPE_OF_DOWN_PAYMENT) {
          assetDescription = reserve.bank_name;
        } else {
          depositoryStockBondInstitutionName = reserve.bank_name;
        }
        return {
          applicantSocialSecurityNumber,
          accountAssetType: LANIS_ACCOUNT_SUB_TYPE.export[reserve.sub_type],
          depositoryStockBondInstitutionName,
          assetDescription,
          acctNo: reserve.account_number,
          cashOrMarketValue,
        }
      });
}

export function exportAutomobiles({ item: borrower }) {
  const applicantSocialSecurityNumber = borrower.ssn;
  return borrower.reserves
      .filter(reserve => reserve.sub_type === AUTOMOBILE)
      .map(reserve => {
        const cashOrMarketValue = formatFnmDecimal(reserve.funds);
        return {
          applicantSocialSecurityNumber,
          cashOrMarketValue,
          automobileMakeModel: reserve.bank_name,
        }
      });
}

export function exportOtherExpenses({ document }) {
  return Object.values(document.accountSummaries)
      .map(account => ({
        account,
        expenseType: LANIS_LIABILITY_TYPE.TO_EXPENSE_TYPE.export[account.liability_type]
      }))
      .filter(({ expenseType }) => expenseType)
      .map(({ account, expenseType }) => {
        const applicantSocialSecurityNumber = getDebtAccountBorrowerSsn({ document, account });
        const monthlyPayment = filterParseFnmDecimal(account.monthly_payment);
        const currentBalance = filterParseFnmDecimal(account.current_balance);
        let monthsLeftToPay;
        if (monthlyPayment && currentBalance) {
          monthsLeftToPay = currentBalance.dividedBy(monthlyPayment).ceil();
        }
        return {
          applicantSocialSecurityNumber,
          expenseTypeCode: expenseType,
          monthlyPaymentAmount: formatFnmDecimal(monthlyPayment),
          monthsLeftToPay: monthsLeftToPay ? monthsLeftToPay.toString() : "",
          alimonyChildSupportSeparateMaintenanceOwedTo: account.description
        }
      })
}

export function exportLiabilities({ document }) {
  const loan = summary_selected_loan(document.loans, document.summary);
  return Object.values(document.accountSummaries)
      .map(account => ({
        account,
        liabilityType: LANIS_LIABILITY_TYPE.TO_LIABILITY_TYPE.export[account.liability_type]
      }))
      .filter(({ liabilityType }) => liabilityType)
      .map(({ account, liabilityType }) => {
        const applicantSocialSecurityNumber = getDebtAccountBorrowerSsn({ document, account });
        const monthlyPayment = filterParseFnmDecimal(account.monthly_payment);
        const currentBalance = filterParseFnmDecimal(account.current_balance);
        let monthsLeftToPay;
        if (monthlyPayment && currentBalance) {
          monthsLeftToPay = currentBalance.dividedBy(monthlyPayment).ceil();
        }
        let liabilityWillBePaidPriorToClosing = "N",
            resubordinatedIndicator = "N",
            omittedIndicator = "N";
        if (loan) {
          switch (loan.account_dispositions[account.id]) {
            case WILL_BE_PAID_OFF:
              liabilityWillBePaidPriorToClosing = "Y";
              break;
            case RESUBORDINATED:
              resubordinatedIndicator = "Y";
              break;
            case OMITTED:
              omittedIndicator = "Y";
              break;
            default:
          }
        }
        return {
          applicantSocialSecurityNumber,
          liabilityType,
          creditorName: account.description,
          acctNo: account.account_number,
          monthlyPaymentAmount: formatFnmDecimal(monthlyPayment),
          monthsLeftToPay: monthsLeftToPay ? monthsLeftToPay.toString() : "",
          unpaidBalance: formatFnmDecimal(currentBalance),
          liabilityWillBePaidPriorToClosing,
          resubordinatedIndicator,
          omittedIndicator,
        }
      })
}

function getDebtAccountBorrowerSsn({ document, account }) {
  if (account.borrower) {
    const borrower = document.applicant.borrowers[parseInt(account.borrower)];
    if (borrower) {
      return borrower.ssn;
    }
  }
  return "";
}

export function exportDeclarations({ item: borrower, documentType }) {
  if (documentType !== PURCHASE) {
    return [];
  }
  return {
    applicantSocialSecurityNumber: borrower.ssn,
    doYouIntendToOccupy: "U",
    haveYouHadAnOwnershipInterest: "U",
  }
}

export function exportAcknowledgeAndAgreement({ item: borrower }) {
  if (!borrower.ssn) {
    return;
  }
  return {
    applicantSocialSecurityNumber: borrower.ssn
  }
}

export function exportInformationForGovernment({ item: borrower }) {
  if (!borrower.ssn) {
    return;
  }
  return {
    applicantSocialSecurityNumber: borrower.ssn,
    iDoNotWishToFurnishThisInformation: "N",
    ethnicity: "3",
    sex: "I",
  }
}

export function exportAdditionalDataSegments({ item: borrower }) {
  if (!borrower.ssn) {
    return;
  }
  return [
    {
      identifier: "HMDAGenderType",
      value: `${borrower.ssn}:InformationNotProvidedUnknown`
    },
    {
      identifier: "HMDAGenderRefusalIndicator",
      value: `${borrower.ssn}:N`
    },
    {
      identifier: "HMDAEthnicityType",
      value: `${borrower.ssn}:InformationNotProvidedByApplicantInMIT`
    },
    {
      identifier: "HMDAEthnicityRefusalIndicator",
      value: `${borrower.ssn}:N`
    },
    {
      identifier: "HMDARaceRefusalIndicator",
      value: `${borrower.ssn}:N`
    },
    {
      identifier: "HMDARaceType",
      value: `${borrower.ssn}:1:InformationNotProvidedByApplicantInMIT`
    },
  ]
}

function removePhoneFormat(phone) {
  return phone.replace(/[-() ]/g, "");
}

export function exportRaceForGovernment({ item: borrower }) {
  if (!borrower.ssn) {
    return;
  }
  return {
    applicantSocialSecurityNumber: borrower.ssn,
    raceCodes: "6"
  }
}
