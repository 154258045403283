import { loan_arm_fix, summary_selected_loan } from "../../constants/utils";
import { FNM, LANIS_ARM_FIX, LANIS_LOAN_TYPES } from "../fnm_constants";
import { loan_amount_first_mortgage, loan_term } from "../../calculations/loan";
import { formatFnmDecimal } from "../util";

export function exportMortgageTypeAndTerms({ document, documentType }) {
  let mortgageAppliedFor,
      loanAmount,
      interestRate,
      noOfMonths,
      amortizationType;
  const loan = summary_selected_loan(document.loans, document.summary);

  if (loan) {
    if (loan.type) {
      mortgageAppliedFor = LANIS_LOAN_TYPES.export[loan.type];
    }
    interestRate = formatFnmDecimal(loan.rate, 3);
    if (loan.term) {
      noOfMonths = loan_term(loan).toString();
      const armFix = loan_arm_fix(loan.term);
      amortizationType = LANIS_ARM_FIX.export[armFix];
    }
    loanAmount = formatFnmDecimal(loan_amount_first_mortgage(
      documentType,
      loan,
      document.property,
      document.property_to_be_sold,
      document.is_selling_property,
    ));
  }
  return {
    mortgageAppliedFor,
    loanAmount,
    interestRate,
    noOfMonths,
    amortizationType,
  };
}

export function exportOtherCredits({ document }) {
  const loan = summary_selected_loan(document.loans, document.summary);
  const credits = [];
  if (!loan) {
    return;
  }
  function addCreditIfPresent(amount, type) {
    if (!amount) {
      return;
    }
    const amountOfOtherCredit = formatFnmDecimal(amount);
    if (amountOfOtherCredit) {
      credits.push({
        otherCreditTypeCode: type,
        amountOfOtherCredit,
      })
    }
  }
  const {
    cash_deposit_on_sales_contract,
    seller_credit,
    lender_credit,
    relocation_funds,
    employer_assisted_housing,
    lease_purchase_fund,
    miscellaneous_credit_one,
    borrower_paid_fees,
    miscellaneous_credit_two
  } = loan.other_adjustments;
  addCreditIfPresent(cash_deposit_on_sales_contract, FNM.OTHER_CREDIT_TYPE.CASH_DEPOSIT_ON_SALES_CONTRACT);
  addCreditIfPresent(seller_credit, FNM.OTHER_CREDIT_TYPE.SELLER_CREDIT);
  addCreditIfPresent(lender_credit, FNM.OTHER_CREDIT_TYPE.LENDER_CREDIT);
  addCreditIfPresent(relocation_funds, FNM.OTHER_CREDIT_TYPE.RELOCATION_FUNDS);
  addCreditIfPresent(employer_assisted_housing, FNM.OTHER_CREDIT_TYPE.EMPLOYER_ASSISTED_HOUSING);
  addCreditIfPresent(lease_purchase_fund, FNM.OTHER_CREDIT_TYPE.LEASE_PURCHASE_FUND);
  addCreditIfPresent(miscellaneous_credit_one, FNM.OTHER_CREDIT_TYPE.OTHER);
  addCreditIfPresent(borrower_paid_fees, FNM.OTHER_CREDIT_TYPE.BORROWER_PAID_FEES);
  addCreditIfPresent(miscellaneous_credit_two, FNM.OTHER_CREDIT_TYPE.SWEAT_EQUITY);
  return credits;
}

export function exportProductCharacteristics({ document }) {
  const loan = summary_selected_loan(document.loans, document.summary);
  if (!loan) {
    return;
  }
  let mortgageTerm;
  if (loan.term) {
    mortgageTerm = loan_term(loan).toString();
  }
  return {
    mortgageTerm
  }
}
