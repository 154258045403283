import React from "react";

import TextField from "../fields/text_field";
import PhoneField from "../fields/phone_field";

export default function CompanyProfile({
  companyProfile,
  onCompanyProfileChange,
  readOnly
}) {
  return (
    <>
      <TextField
        fieldName="company_name"
        name="Company Name"
        value={companyProfile.company_name}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="company_nmls_number"
        name="Company NMLS #"
        value={companyProfile.company_nmls_number}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="company_state_license"
        name="Company State License"
        value={companyProfile.company_state_license}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="admin_contact_name"
        name="Admin Contact Name"
        value={companyProfile.admin_contact_name}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        typeOverride="email"
        fieldName="email"
        name="Email"
        value={companyProfile.email}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <PhoneField
        fieldName="contact_phone"
        name="Contact Phone"
        value={companyProfile.contact_phone}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="extension"
        name="Extension"
        value={companyProfile.extension}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="business_address"
        name="Business Address"
        value={companyProfile.business_address}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="business_address_2"
        name="Business Address 2"
        value={companyProfile.business_address_2}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="city"
        name="City"
        value={companyProfile.city}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="state"
        name="State"
        value={companyProfile.state}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="zip"
        name="Zip"
        value={companyProfile.zip}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="company_los_used"
        name="Company (LOS) used"
        value={companyProfile.company_los_used}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
      <TextField
        fieldName="company_web_site"
        name="Company Website"
        value={companyProfile.company_web_site}
        onChange={onCompanyProfileChange}
        readOnly={readOnly}
      />
    </>
  );
}
