import React, { useState, useLayoutEffect, useEffect } from "react";

import Header from "./header";
import LoanToolControls from "./loan_tool_controls";
import LoanToolNavigation from "./loan_tool_navigation";

import "./css/standard_template.scss";
import { useUser } from "../../hooks/user";
import { class_list } from "../../constants/utils";

export default function StandardTemplate({
  children,
  active,
  basic,
  className,
  isDisableNavSave,
  showMoreLinks
}) {
  if (!useUser()) {
    return (
      <div className={class_list("standard-template", className && className)}>
        <Header />
        <div>{children}</div>
      </div>
    );
  }

  const [{ isLoggedIn }] = useUser();
  const [bodyHeight, setBodyHeight] = useState(0);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [atTop, setAtTop] = useState(true);

  useLayoutEffect(() => {
    const height = window.document.body.offsetHeight;
    if (bodyHeight !== height) {
      setBodyHeight(height);
      updateAtTop();
      updateScrollToTop();
    }
  });

  useEffect(() => {
    window.addEventListener("resize", updateScrollToTop);

    return () => {
      window.removeEventListener("resize", updateScrollToTop);
    };
  }, [showScrollToTop]);

  useEffect(() => {
    window.addEventListener("scroll", updateAtTop);

    return () => {
      window.removeEventListener("scroll", updateAtTop);
    };
  }, [atTop]);

  function updateScrollToTop() {
    const isShow = window.innerHeight < window.document.body.offsetHeight;
    if (showScrollToTop !== isShow) {
      setShowScrollToTop(isShow);
    }
  }

  function updateAtTop() {
    const isAtTop = document.documentElement.scrollTop === 0;
    if (atTop !== isAtTop) {
      setAtTop(isAtTop);
    }
  }

  function scrollToTop() {
    window.scrollTo(document.documentElement.scrollLeft, 0);
  }

  return (
    <div className={class_list("standard-template", className && className)}>
      <div className="standard-template-content">
        <Header showMoreLinks={showMoreLinks} />
        {!basic && isLoggedIn && <LoanToolControls />}
        {!basic && isLoggedIn && (
          <LoanToolNavigation
            active={active}
            isDisableNavSave={isDisableNavSave}
          />
        )}

        <div>{children}</div>
        {!atTop && showScrollToTop && (
          <div className="scroll-to-top" onClick={scrollToTop}>
            <div className="arrow-head" />
          </div>
        )}
      </div>
    </div>
  );
}
