import React, { useState } from "react";
import "./css/reserves.scss";

import EditReserve from "../../components/loan_tools/reserves/edit_reserve";
import EquityFromSaleCalculator from "../../components/loan_tools/reserves/equity_from_sale_calculator";
import ReservesMainPage from "../../components/loan_tools/reserves/reserves_main_page";
import StandardTemplate from "../../templates/standard_template/standard_template";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { RESERVES } from "../../constants/navigations";
import NoBorrowers from "./no_borrowers";

export default function ReservesPage() {
  const [view, setView] = useState("RESERVES_MAIN_PAGE");
  const [borrowerIndex, setBorrowerIndex] = useState();
  const [editReserveIndex, setEditReserveIndex] = useState();
  const [document, setDocument] = useDocument();
  const borrower = document.applicant.borrowers[borrowerIndex];

  function showReservesTable() {
    setView("RESERVES_MAIN_PAGE");
    setBorrowerIndex(null);
    setEditReserveIndex(null);
  }

  function showEditReserve(borrowerIndex, editReserveIndex) {
    setView("EDIT_RESERVES_ACCOUNT");
    setBorrowerIndex(borrowerIndex);
    setEditReserveIndex(editReserveIndex);
  }

  function showEquityFromSaleCalculator() {
    setView("EQUITY_FROM_SALE_CALCULATOR");
    setBorrowerIndex(null);
    setEditReserveIndex(null);
  }

  function deleteReserve(borrowerIndex, reserveIndex) {
    const borrowers = [...document.applicant.borrowers];
    const borrower = { ...borrowers[borrowerIndex] };
    borrower.reserves = borrower.reserves.filter((entry, index) => {
      return index !== reserveIndex;
    });
    borrowers[borrowerIndex] = borrower;
    setDocument({
      ...document,
      applicant: {
        ...document.applicant,
        borrowers
      }
    });
  }

  function onChangeReserve(reserve) {
    const borrowers = [...document.applicant.borrowers];
    const borrower = { ...borrowers[borrowerIndex] };
    borrower.reserves = [...borrower.reserves];
    borrower.reserves[editReserveIndex] = reserve;
    borrowers[borrowerIndex] = borrower;
    setDocument({
      ...document,
      applicant: {
        ...document.applicant,
        borrowers
      }
    });
  }

  function onChange(updatedState) {
    setDocument({ ...document, ...updatedState });
  }

  let reserve = null;
  if (borrower) {
    reserve = borrower.reserves[editReserveIndex];
  }

  return (
    <StandardTemplate
      active={RESERVES}
      isDisableNavSave={view !== "RESERVES_MAIN_PAGE"}
    >
      <NoBorrowers />

      <div className="reserves">
        {view === "RESERVES_MAIN_PAGE" && (
          <ReservesMainPage
            showEditReserve={showEditReserve}
            showEquityFromSaleCalculator={showEquityFromSaleCalculator}
            deleteReserve={deleteReserve}
            onChange={onChange}
          />
        )}
        {view === "EDIT_RESERVES_ACCOUNT" && (
          <EditReserve
            reserve={reserve}
            onChange={onChangeReserve}
            showReservesTable={showReservesTable}
          />
        )}
        {view === "EQUITY_FROM_SALE_CALCULATOR" && (
          <EquityFromSaleCalculator
            onChange={onChange}
            showReservesTable={showReservesTable}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
