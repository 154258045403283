import React, { useState, useMemo, useRef, useEffect } from "react";
import Decimal from "decimal.js";

import "./css/currency_field.scss";

import { format_currency } from "../../constants/format";
import TrainingTip from "../ui/training_tip";

/**
 * CurrencyField is responsible for only storing value currency values.
 * In practice, this means storing, as a string, the digits, sign, and decimal
 * characters.
 */
export default function CurrencyField({
  value,
  readOnly,
  onChange,
  fieldName,
  fixedDigits,
  displayFixedDigits,
  className,
  id,
  name,
  title,
  naked,
  allowEmpty,
  tabIndex,
  placeholder,
  showRequired
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const displayValue = useMemo(() => format(value, true), [value]);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.select();
    }
  }, [isFocused]);

  /**
   * Update the state of this currency field.
   * @param {*} event
   */
  function change(event) {
    let newValue;
    let targetValue;
    if (event.target.value === "-") {
      newValue = "-0";
      targetValue = "-";
    } else {
      targetValue = event.target.value.replace(/[^\d.-]/g, "");
      newValue = targetValue === "" ? "0" : targetValue;
    }

    try {
      new Decimal(newValue);
    } catch (e) {
      return;
    }

    setInnerValue(targetValue);

    if (onChange && fieldName) {
      onChange({
        [fieldName]: newValue
      });
    }
  }

  /**
   * Event fired when this currency field is focused.
   */
  function onFocus() {
    if (!readOnly) {
      const stringValue =
        innerValue instanceof Decimal
          ? innerValue.toString()
          : innerValue || "0";
      setIsFocused(true);
      setInnerValue(stringValue.replace(/[^\d.-]/g, ""));
    }
  }

  /**
   * Event fired when this currency field is blurred.
   */
  function onBlur() {
    if (!readOnly) {
      setIsFocused(false);
      setInnerValue(format(value));
    }
  }

  /**
   * Formats the given value into a currency string.
   * @param {*} value
   */
  function format(value, forDisplay) {
    if (allowEmpty && forDisplay && typeof value !== "number" && !value) {
      return "";
    }
    // Defaults to 2 fraction digits.
    let digits = Number.isInteger(fixedDigits) ? fixedDigits : 2;
    if (forDisplay) {
      digits = Number.isInteger(displayFixedDigits)
        ? displayFixedDigits
        : digits;
    }
    const decimalValue = new Decimal(value || 0);
    let formattedValue = format_currency(decimalValue.abs().toString(), {
      minimumFractionDigits: digits,
      maximumFractionDigits: digits
    });

    if (decimalValue.lt(0)) {
      formattedValue = "(" + formattedValue + ")";
    }

    return formattedValue;
  }

  function class_name() {
    let classname = "input-field currency-field";

    if (className) {
      classname += ` ${className}`;
    }

    return classname;
  }

  if (naked) {
    return <span className={`mono-font ${className}`}>{displayValue}</span>;
  }

  return (
    <div className="input-field-container currency-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>

      <input
        ref={inputRef}
        id={id}
        type="text"
        value={isFocused ? innerValue : displayValue}
        onChange={change}
        onFocus={onFocus}
        onBlur={onBlur}
        step="any"
        tabIndex={tabIndex}
        className={class_name()}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </div>
  );
}
