import Decimal from "decimal.js";
import { RANGE_560 } from "../constants/credit_score";

function getCreditScore(borrower) {
  return borrower.lowest_credit_mid_score
    ? new Decimal(borrower.lowest_credit_mid_score)
    : null;
}

export function lowest_credit_score(borrowers) {
  const lowestScore = borrowers
    .map(getCreditScore)
    .reduce((accum, creditScore) => {
      if (!accum) {
        return creditScore;
      }
      // We defined the "values" in the opposite order of the credit score amount.
      if (creditScore) {
        return accum.lt(creditScore) ? creditScore : accum;
      }
      return null;
    }, null);

  // Range 560 being the lowest defined right now.
  return lowestScore ? lowestScore : new Decimal(RANGE_560);
}
