import React from "react";
import { borrower_names, truthy_join } from "../../../constants/utils";
import DateField, { today } from "../../fields/date_field";

import { useDocument } from "../../loan_tools/loan_tool";
import { PURCHASE } from "../../../constants/document_types";
import { useUser } from "../../../hooks/user";
import Image from "../../ui/image";
import PhoneField from "../../fields/phone_field";

export default function CompanyClientInfo() {
  const [{ companyProfile }] = useUser();
  const [document, , { documentType, documentAccountProfile }] = useDocument();

  const isPurchase = documentType === PURCHASE;
  const firstBorrower = document.applicant.borrowers[0] || {};
  const borrowerNames = borrower_names(document.applicant.borrowers);

  return (
    <>
      <tbody>
        <tr>
          <td colSpan="2" />
          <td className="center-align" colSpan="2" rowSpan="4">
            <Image className="company-logo" url="company/company-logo" />
          </td>
          <td className="center-align" colSpan="4" rowSpan="3">
            <div className="bold big-font">TRID - LOAN ESTIMATE</div>
            <div className="bold big-font">SUMMARY OF CLOSING COSTS</div>
            <div>&#9400; 2016 The LANIS Group, LLC.</div>
          </td>
        </tr>
        <tr>
          <td className="left-align big-font" colSpan="2">
            {truthy_join(
              [
                documentAccountProfile.first_name,
                documentAccountProfile.middle_name,
                documentAccountProfile.last_name
              ],
              " "
            )}
          </td>
        </tr>
        <tr>
          <td className="left-align big-font" colSpan="2">
            Broker Owner
          </td>
        </tr>
        <tr>
          <td className="left-align big-font" colSpan="2">
            Direct:{" "}
            <PhoneField
              naked
              value={documentAccountProfile.direct_contact_phone}
            />
          </td>
          <td className="center-align bold big-font gray-bg" colSpan="4">
            {isPurchase ? "PURCHASE" : "REFINANCE"} SUMMARY SHEET
          </td>
        </tr>
        <tr>
          <td colSpan="4" rowSpan="8">
            <div>E-mail {documentAccountProfile.email}</div>
            <div>{companyProfile.company_web_site}</div>
            {companyProfile.company_state_license && (
              <div>
                Company State License #: {companyProfile.company_state_license}
              </div>
            )}
            {documentAccountProfile.state_real_estate_license && (
              <div>
                Personal State License #:{" "}
                {documentAccountProfile.state_real_estate_license}
              </div>
            )}
            <div>
              {companyProfile.company_nmls_number &&
                `Company NMLS #: ${companyProfile.company_nmls_number}`}
            </div>
            <div>
              {documentAccountProfile.nmls_number &&
                `Personal NMLS #: ${documentAccountProfile.nmls_number}`}
            </div>
            <div>
              MAIN OFFICE{" "}
              <PhoneField naked value={companyProfile.contact_phone} />{" "}
              {companyProfile.extension && `ext. ${companyProfile.extension}`}
            </div>
            <div>
              {truthy_join(
                [
                  truthy_join(
                    [
                      companyProfile.business_address,
                      companyProfile.business_address_2
                    ],
                    " "
                  ),
                  companyProfile.city,
                  truthy_join([companyProfile.state, companyProfile.zip], " ")
                ],
                ", "
              )}
            </div>
          </td>
          <td className="with-border bold">CLIENT(S)</td>
          <td className="with-border" colSpan="3" rowSpan="2">
            <div className="flex-space-between">
              <div>{borrowerNames[0]}</div>
              <div>{borrowerNames[2]}</div>
            </div>
            <div className="flex-space-between">
              <div>{borrowerNames[1]}</div>
              <div>{borrowerNames[3]}</div>
            </div>
          </td>
        </tr>
        <tr className="with-td-border">
          <td className="bold">CLIENT(S)</td>
        </tr>
        <tr className="with-td-border">
          <td className="bold">ADDRESS</td>
          <td colSpan="3">{firstBorrower.current_address}</td>
        </tr>
        <tr className="with-td-border">
          <td className="bold">ADDRESS 2</td>
          <td colSpan="3">{firstBorrower.current_address_2}</td>
        </tr>
        <tr className="with-td-border">
          <td className="bold">City, State</td>
          <td className="no-right-border">{firstBorrower.city}</td>
          <td className="center-align no-right-border no-left-border">
            {firstBorrower.state}
          </td>
          <td className="center-align no-left-border">
            {firstBorrower.zip_code}
          </td>
        </tr>
        <tr className="with-td-border">
          <td
            colSpan="2"
            className="thick-top-border thick-left-border no-right-border"
          >
            Loan #
          </td>
          <td colSpan="2" className="thick-top-border no-left-border">
            <div className="flex-space-between">
              <span className="small-font bold">DATE FIRST ISSUED: </span>
              <DateField naked value={document.applicant.first_contact_date} />
            </div>
          </td>
        </tr>
        <tr className="with-td-border">
          <td colSpan="2" className="thick-left-border no-right-border">
            TBD
          </td>
          <td colSpan="2" className="no-left-border">
            <div className="flex-space-between">
              <span className="small-font bold">DATE LAST ISSUED: </span>
              <DateField naked value={today()} />
            </div>
          </td>
        </tr>
      </tbody>
    </>
  );
}
