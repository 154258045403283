export const default_borrower = {
  first_name: "",
  middle_name: "",
  last_name: "",
  suffix: "",
  current_address: "",
  current_address_2: "",
  city: "",
  state: "",
  zip_code: "",
  currently_renting_or_owning: "",
  market_value: "0",
  rental_amount: "0",
  how_long_in_current_residence: "0",
  marital_status: "",
  married_to: "",
  email: "",
  cell_phone: "",
  home_phone: "",
  work_phone: "",
  ssn: "",
  is_veteran: "",
  on_title: "",
  on_loan: "",
  lowest_credit_mid_score: "",
  same_address_as: "",
  co_borrower: "",
  income: {
    sources: [],
    other_income: []
  },
  reserves: []
};
