import {
  createSelectedLoanIfNotExist,
  importMortgageTypeAndTerms,
  importOtherCredits
} from "./importers/loan";
import { importDocumentType } from "./importers/document";
import {
  importDetailsOfTransaction,
  importHousingExpenses, importLoanCharacteristics,
  importPropertyClassification, importPropertyInformation,
  importPropertyTitleVesting,
  importPurposeOfRefinance, importRealEstateOwned
} from "./importers/property";
import {
  createBorrowerFromSsnIfNotExist, createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
  createBorrowerIfNotExist,
  createPrimaryIncomeSourceIfNotExist, createSecondaryIncomeSource,
  importApplicantAddress,
  importApplicantData,
  importAssets,
  importAutomobiles,
  importIncome,
  importLiabilities,
  importLifeInsurance,
  importOtherExpenses,
  importPrimaryCurrentEmployer, importSecondaryEmployer,
  importTitleholderName, skipIfNotCurrent
} from "./importers/borrower";
import { exportMortgageTypeAndTerms, exportOtherCredits, exportProductCharacteristics } from "./exporters/loan";
import {
  exportDetailsOfTransaction, exportFannieMaeTransmittalData,
  exportHousingExpenses, exportLoanCharacteristics, exportNetRentalIncome,
  exportPropertyClassification,
  exportPropertyInformation,
  exportPropertyTitleVesting, exportPurposeOfRefinance, exportRealEstateOwned
} from "./exporters/property";
import {
  exportDocumentType,
  exportLoanOriginationAdditionalData,
  exportLoanOriginatorInformation
} from "./exporters/document";
import {
  exportAcknowledgeAndAgreement, exportAdditionalDataSegments, exportApplicantAddress, exportApplicantData,
  exportAssets, exportAutomobiles, exportDeclarations,
  exportIncome, exportInformationForGovernment, exportLiabilities, exportLifeInsurance, exportOtherExpenses,
  exportPrimaryCurrentEmployer, exportRaceForGovernment, exportSecondaryEmployer,
  exportTitleholderName,
  getBorrowers
} from "./exporters/borrower";

/**
 * @typedef ImportConfigField
 * @property {String} recordId the format identifier as defined by the FNM spec that this field represents
 * @property {Boolean|function({ occurrence: Number, document }):Boolean} skip may be a boolean or a function. If a
 * boolean and <code>true</code>, the field's lines is always skipped. If a function, then each of the field's lines are
 * skipped if this function returns <code>true</code> for the given line.
 * @property {Object<String, { position: Number, length: Number }>} format
 * @property {function({ occurrence: Number, document })} item - get or generate the object this field will populate
 * with values.
 * @property {function({ occurrence: Number, item, document })} populate Use this to set values on
 * the item in question.
 */

/**
 * @typedef ExportConfigField
 * @property {String} recordId the format identifier as defined by the FNM spec that this field represents
 * @property {Object<String, { position: Number, length: Number }>} format
 * @property {function({ document, documentType: String, accountProfile, companyProfile }):(Object[]|Object)} each
 * @property {function({ document, documentType: String, accountProfile, companyProfile }):(Object[]|Object)} data
 */

const skipAlways = { skip: true };

/**
 * @type {ImportConfigField[]}
 */
export const IMPORT_CONFIG_FIELDS = [
  {
    recordId: "EH ",
    ...skipAlways
  },
  {
    recordId: "TH ",
    ...skipAlways
  },
  {
    recordId: "TPI",
    ...skipAlways
  },
  {
    recordId: "000",
    ...skipAlways
  },
  {
    recordId: "00A",
    ...skipAlways
  },
  // 02B must be evaluated before 01A so that the document type is known
  {
    recordId: "02B",
    format: {
      reservedForFutureUse: { position: 4, length: 2 },
      purposeOfLoan: { position: 6, length: 2 },
      purposeOfLoanOther: { position: 8, length: 80 },
      propertyWillBe: { position: 88, length: 1 },
      mannerInWhichTitleWillBeHeld: { position: 89, length: 60 },
      estateWillBeHeldIn: { position: 149, length: 1 },
      estateWillBeHeldInLeaseholdExpirationDate: { position: 150, length: 8 }
    },
    populate: ({ data, document, documentInfo }) => {
      importDocumentType({ data, document, documentInfo });
      importPropertyClassification({ data, document });
      importPropertyTitleVesting({ data, document });
    }
  },
  {
    recordId: "01A",
    format: {
      mortgageAppliedFor: { position: 4, length: 2 },
      mortgageAppliedForOther: { position: 6, length: 80 },
      agencyCaseNumber: { position: 86, length: 30 },
      caseNumber: { position: 116, length: 15 },
      loanAmount: { position: 131, length: 15 },
      interestRate: { position: 146, length: 7 },
      noOfMonths: { position: 153, length: 3 },
      amortizationType: { position: 156, length: 2 },
      amortizationTypeOtherExplanation: { position: 158, length: 80 },
      armTextualDescription: { position: 238, length: 80 }
    },
    item: createSelectedLoanIfNotExist,
    populate: importMortgageTypeAndTerms
  },
  {
    recordId: "02A",
    format: {
      propertyStreetAddress: { position: 4, length: 50 },
      propertyCity: { position: 54, length: 35 },
      propertyState: { position: 89, length: 2 },
      propertyZipCode: { position: 91, length: 5 },
      propertyZipCodePlusFour: { position: 96, length: 4 },
      noOfUnits: { position: 100, length: 3 },
      legalDescriptionOfSubjectPropertyCode: { position: 103, length: 2 },
      legalDescriptionOfSubjectProperty: { position: 105, length: 80 },
      yearBuilt: { position: 185, length: 4 }
    },
    populate: importPropertyInformation
  },
  {
    recordId: "02C",
    format: {
      titleholderName: { position: 4, length: 60 }
    },
    item: createBorrowerIfNotExist,
    populate: importTitleholderName
  },
  {
    recordId: "02D",
    format: {
      yearLotAcquiredConstructionOrYearAcquiredRefinance: { position: 4, length: 4 },
      originalCostConstructionOrRefinance: { position: 8, length: 15 },
      amountExistingLiensConstructionOrRefinance: { position: 23, length: 15 },
      presentValueOfLot: { position: 38, length: 15 },
      costOfImprovements: { position: 53, length: 15 },
      purposeOfRefinance: { position: 68, length: 2 },
      describeImprovements: { position: 70, length: 80 },
      describeImprovementsMadTobeMade: { position: 150, length: 1 },
      describeImprovementsCost: { position: 151, length: 15 }
    },
    populate: importPurposeOfRefinance
  },
  {
    recordId: "02E",
    ...skipAlways
  },
  {
    recordId: "03A",
    format: {
      applicantCoApplicantIndicator: { position: 4, length: 2 },
      applicantSocialSecurityNumber: { position: 6, length: 9 },
      applicantFirstName: { position: 15, length: 35 },
      applicantMiddleName: { position: 50, length: 35 },
      applicantLastName: { position: 85, length: 35 },
      applicantGeneration: { position: 120, length: 4 },
      homePhone: { position: 124, length: 10 },
      age: { position: 134, length: 3 },
      yrsSchool: { position: 137, length: 2 },
      maritalStatus: { position: 139, length: 1 },
      dependentsNo: { position: 140, length: 2 },
      completedJointlyNotJointly: { position: 142, length: 1 },
      crossReferenceNumber: { position: 143, length: 9 },
      dateOfBirth: { position: 152, length: 8 },
      emailAddress: { position: 160, length: 80 }
    },
    item: createBorrowerFromSsnIfNotExist,
    populate: importApplicantData
  },
  {
    recordId: "03C",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      presentFormer: { position: 13, length: 2 },
      residenceStreetAddress: { position: 15, length: 50 },
      residenceCity: { position: 65, length: 35 },
      residenceState: { position: 100, length: 2 },
      residenceZipCode: { position: 102, length: 5 },
      residenceZipCodePlusFour: { position: 107, length: 4 },
      ownRentLivingRentFree: { position: 111, length: 1 },
      noYrs: { position: 112, length: 2 },
      noMonths: { position: 114, length: 2 },
      country: { position: 116, length: 50 }
    },
    item: createBorrowerFromSsnIfNotExist,
    populate: importApplicantAddress
  },
  {
    recordId: "04A",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      employerName: { position: 13, length: 35 },
      employerStreetAddress: { position: 48, length: 35 },
      employerCity: { position: 83, length: 35 },
      employerState: { position: 118, length: 2 },
      employerZipCode: { position: 120, length: 5 },
      employerZipCodePlusFour: { position: 125, length: 4 },
      selfEmployed: { position: 129, length: 1 },
      yrsOnThisJob: { position: 130, length: 2 },
      monthsOnThisJob: { position: 132, length: 2 },
      yrsEmployedInThisLineOfWorkProfession: { position: 134, length: 2 },
      positionTitleTypeOfBusiness: { position: 136, length: 25 },
      businessPhone: { position: 161, length: 10 }
    },
    item: createPrimaryIncomeSourceIfNotExist,
    populate: importPrimaryCurrentEmployer
  },
  {
    recordId: "04B",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      employerName: { position: 13, length: 35 },
      employerStreetAddress: { position: 48, length: 35 },
      employerCity: { position: 83, length: 35 },
      employerState: { position: 118, length: 2 },
      employerZipCode: { position: 120, length: 5 },
      employerZipCodePlusFour: { position: 125, length: 4 },
      selfEmployed: { position: 129, length: 1 },
      currentEmploymentFlag: { position: 130, length: 1 },
      fromDate: { position: 131, length: 8 },
      toDate: { position: 139, length: 8 },
      monthlyIncome: { position: 147, length: 15 },
      positionTitleTypeOfBusiness: { position: 162, length: 25 },
      businessPhone: { position: 187, length: 10 }
    },
    skip: skipIfNotCurrent,
    item: createSecondaryIncomeSource,
    populate: importSecondaryEmployer
  },
  // NOTE: The 06G, LNC, and 07A have been *purposefully* moved above the 05I because
  // the 05I depends on all properties having been populated by the time the 05I
  // is processed. Additionally, the FNM processing sorts all incoming lines by
  // their field's appearance in this config.
  {
    recordId: "06G",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      propertyStreetAddress: { position: 13, length: 35 },
      propertyCity: { position: 48, length: 35 },
      propertyState: { position: 83, length: 2 },
      propertyZipCode: { position: 85, length: 5 },
      propertyZipCodePlusFour: { position: 90, length: 4 },
      propertyDisposition: { position: 94, length: 1 },
      typeOfProperty: { position: 95, length: 2 },
      presentMarketValue: { position: 97, length: 15 },
      amountOfMortgagesLiens: { position: 112, length: 15 },
      grossRentalIncome: { position: 127, length: 15 },
      mortgagePayments: { position: 142, length: 15 },
      insuranceMaintenanceTaxesMisc: { position: 157, length: 15 },
      netRentalIncome: { position: 172, length: 15 },
      currentResidenceIndicator: { position: 187, length: 1 },
      subjectPropertyIndicator: { position: 188, length: 1 },
      reoAssetId: { position: 189, length: 2 },
      reservedForFutureUse: { position: 191, length: 15 }
    },
    populate: importRealEstateOwned
  },
  {
    recordId: "LNC",
    format: {
      lienTypeCode: { position: 4, length: 1 },
      loanDocumentationTypeCode: { position: 5, length: 1 },
      subjectPropertyTypeCode: { position: 6, length: 2 },
      reservedForFutureUse: { position: 8, length: 2 },
      reservedForFutureUse2: { position: 10, length: 2 },
      reservedForFutureUse3: { position: 12, length: 2 },
      reservedForFutureUse4: { position: 14, length: 2 },
      projectClassificationCode: { position: 16, length: 2 },
      negativeAmortizationLimitPercent: { position: 18, length: 7 },
      balloonIndicator: { position: 25, length: 1 },
      filler: { position: 26, length: 1 },
      filler2: { position: 27, length: 1 },
      homebuyerEducationCompletionIndicator: { position: 28, length: 1 },
      maximumLifetimeRateIncrease: { position: 29, length: 7 },
      paymentAdjustmentLifePercentCap: { position: 36, length: 7 },
      paymentAdjustmentLifeAmountCap: { position: 43, length: 15 },
      willEscrowBeWaived: { position: 58, length: 1 },
      scheduledLoanClosingDate: { position: 59, length: 8 },
      scheduledFirstPaymentDate: { position: 67, length: 8 },
      miCoveragePercent: { position: 75, length: 7 },
      miInsurerCode: { position: 82, length: 3 },
      aprSpread: { position: 85, length: 5 },
      hoepa: { position: 90, length: 1 },
      preapproval: { position: 91, length: 1 }
    },
    populate: importLoanCharacteristics
  },
  {
    recordId: "07A",
    format: {
      purchasePrice: { position: 4, length: 15 },
      alterationsImprovementsRepairs: { position: 19, length: 15 },
      land: { position: 34, length: 15 },
      refinanceIncDebtsToBePaidOff: { position: 49, length: 15 },
      estimatedPrepaidItems: { position: 64, length: 15 },
      estimatedClosingCosts: { position: 79, length: 15 },
      pmiMipFundingFee: { position: 94, length: 15 },
      discount: { position: 109, length: 15 },
      subordinateFinancing: { position: 124, length: 15 },
      applicantsClosingCostsPaidBySeller: { position: 139, length: 15 },
      pmiMipFundingFeeFinanced: { position: 154, length: 15 }
    },
    populate: importDetailsOfTransaction
  },
  {
    recordId: "05H",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      presentProposedIndicator: { position: 13, length: 1 },
      housingPaymentTypeCode: { position: 14, length: 2 },
      housingPaymentAmountMonthlyHousingExp: { position: 16, length: 15 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importHousingExpenses
  },
  {
    recordId: "05I",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      typeOfIncomeCode: { position: 13, length: 2 },
      incomeAmountMonthlyIncome: { position: 15, length: 15 }
    },
    populate: importIncome
  },
  {
    recordId: "06B",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      acctNo: { position: 13, length: 30 },
      lifeInsuranceCashOrMarketValue: { position: 43, length: 15 },
      lifeInsuranceFaceAmount: { position: 58, length: 15 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importLifeInsurance
  },
  {
    recordId: "06C",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      accountAssetType: { position: 13, length: 3 },
      depositoryStockBondInstitutionName: { position: 16, length: 35 },
      depositoryStreetAddress: { position: 51, length: 35 },
      depositoryCity: { position: 86, length: 35 },
      depositoryState: { position: 121, length: 2 },
      depositoryZipCode: { position: 123, length: 5 },
      depositoryZipCodePlusFour: { position: 128, length: 4 },
      acctNo: { position: 132, length: 30 },
      cashOrMarketValue: { position: 162, length: 15 },
      numberOfStockBondShares: { position: 177, length: 7 },
      assetDescription: { position: 184, length: 80 },
      reservedForFutureUse: { position: 264, length: 1 },
      reservedForFutureUse2: { position: 265, length: 2 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importAssets
  },
  {
    recordId: "06D",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      automobileMakeModel: { position: 13, length: 30 },
      automobileYear: { position: 43, length: 4 },
      cashOrMarketValue: { position: 47, length: 15 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importAutomobiles
  },
  {
    recordId: "06F",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      expenseTypeCode: { position: 13, length: 3 },
      monthlyPaymentAmount: { position: 16, length: 15 },
      monthsLeftToPay: { position: 31, length: 3 },
      alimonyChildSupportSeparateMaintenanceOwedTo: { position: 34, length: 60 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importOtherExpenses
  },
  {
    recordId: "06L",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      liabilityType: { position: 13, length: 2 },
      creditorName: { position: 15, length: 35 },
      creditorStreetAddress: { position: 50, length: 35 },
      creditorCity: { position: 85, length: 35 },
      creditorState: { position: 120, length: 2 },
      creditorZipCode: { position: 122, length: 5 },
      creditorZipCodePlusFour: { position: 127, length: 4 },
      acctNo: { position: 131, length: 30 },
      monthlyPaymentAmount: { position: 161, length: 15 },
      monthsLeftToPay: { position: 176, length: 3 },
      unpaidBalance: { position: 179, length: 15 },
      liabilityWillBePaidPriorToClosing: { position: 194, length: 1 },
      reoAssetId: { position: 195, length: 2 },
      resubordinatedIndicator: { position: 197, length: 1 },
      omittedIndicator: { position: 198, length: 1 },
      subjectPropertyIndicator: { position: 199, length: 1 },
      rentalPropertyIndicator: { position: 200, length: 1 }
    },
    item: createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower,
    populate: importLiabilities
  },
  {
    recordId: "07B",
    format: {
      otherCreditTypeCode: { position: 4, length: 2 },
      amountOfOtherCredit: { position: 6, length: 15 }
    },
    item: createSelectedLoanIfNotExist,
    populate: importOtherCredits
  },
  {
    recordId: "08A",
    ...skipAlways
  },
  {
    recordId: "09A",
    ...skipAlways
  },
  {
    recordId: "10A",
    ...skipAlways
  },
  {
    recordId: "10B",
    ...skipAlways
  },
  {
    recordId: "10R",
    ...skipAlways
  },
  {
    recordId: "99B",
    ...skipAlways
  },
  {
    recordId: "ADS",
    ...skipAlways
  },
  {
    recordId: "PID",
    ...skipAlways
  },
  {
    recordId: "PCH",
    ...skipAlways
  },
  {
    recordId: "TT ",
    ...skipAlways
  },
  {
    recordId: "ET ",
    ...skipAlways
  }
];

// Note: The export fields will have duplicate record ids, namely for 000. The
// duplicate fields are irrelevant for importing, but required for exporting.
/**
 * @type {ExportConfigField[]}
 */
export const EXPORT_CONFIG_FIELDS = [
  {
    recordId: "EH ",
    format: {
      institutionId: { position: 4, length: 6 },
      institutionName: { position: 10, length: 25 },
      date: { position: 35, length: 11 },
      envelopeControlNumber: { position: 46, length: 9 }
    },
    data: () => ({
      envelopeControlNumber: "0"
    })
  },
  {
    recordId: "TH ",
    format: {
      transactionId: { position: 4, length: 11 },
      transactionControlNumber: { position: 15, length: 9 }
    },
    data: () => ({
      // TODO: Should we generate these?
      transactionId: "T100099-002",
      transactionControlNumber: "1"
    })
  },
  {
    recordId: "TPI",
    format: {
      versionId: { position: 4, length: 5 },
      identifierTypeCode: { position: 9, length: 2 },
      identifier: { position: 11, length: 30 },
      importActionIndicator: { position: 41, length: 1 }
    },
    data: () => ({
      versionId: "1.00",
      identifierTypeCode: "01",
      importActionIndicator: "N"
    })
  },
  {
    recordId: "000",
    format: {
      fileType: { position: 4, length: 3 },
      fileVersionId: { position: 7, length: 5 },
      exportVersionIndicator: { position: 12, length: 1 }
    },
    data: () => ({
      fileType: "1",
      fileVersionId: "3.20",
      exportVersionIndicator: "W"
    })
  },
  {
    recordId: "00A",
    format: {
      nonBorrowerAssetFlag: { position: 4, length: 1 },
      borrowerSpouseLiabilityFlag: { position: 5, length: 1 }
    },
    data: () => ({
      nonBorrowerAssetFlag: "N",
      borrowerSpouseLiabilityFlag: "N"
    })
  },
  {
    recordId: "01A",
    format: {
      mortgageAppliedFor: { position: 4, length: 2 },
      mortgageAppliedForOther: { position: 6, length: 80 },
      agencyCaseNumber: { position: 86, length: 30 },
      caseNumber: { position: 116, length: 15 },
      loanAmount: { position: 131, length: 15 },
      interestRate: { position: 146, length: 7 },
      noOfMonths: { position: 153, length: 3 },
      amortizationType: { position: 156, length: 2 },
      amortizationTypeOtherExplanation: { position: 158, length: 80 },
      armTextualDescription: { position: 238, length: 80 }
    },
    data: exportMortgageTypeAndTerms
  },
  {
    recordId: "02A",
    format: {
      propertyStreetAddress: { position: 4, length: 50 },
      propertyCity: { position: 54, length: 35 },
      propertyState: { position: 89, length: 2 },
      propertyZipCode: { position: 91, length: 5 },
      propertyZipCodePlusFour: { position: 96, length: 4 },
      noOfUnits: { position: 100, length: 3 },
      legalDescriptionOfSubjectPropertyCode: { position: 103, length: 2 },
      legalDescriptionOfSubjectProperty: { position: 105, length: 80 },
      yearBuilt: { position: 185, length: 4 }
    },
    data: exportPropertyInformation
  },
  {
    recordId: "02B",
    format: {
      reservedForFutureUse: { position: 4, length: 2 },
      purposeOfLoan: { position: 6, length: 2 },
      purposeOfLoanOther: { position: 8, length: 80 },
      propertyWillBe: { position: 88, length: 1 },
      mannerInWhichTitleWillBeHeld: { position: 89, length: 60 },
      estateWillBeHeldIn: { position: 149, length: 1 },
      estateWillBeHeldInLeaseholdExpirationDate: { position: 150, length: 8 }
    },
    data: ({ document, documentType }) => ({
      ...exportDocumentType({ documentType }),
      ...exportPropertyClassification({ document }),
      ...exportPropertyTitleVesting({ document }),
    })
  },
  {
    recordId: "02C",
    format: {
      titleholderName: { position: 4, length: 60 }
    },
    // This particular field has a limit of 2 borrowers max.
    each: ({ document }) => getBorrowers({ document }).slice(0, 2),
    data: exportTitleholderName
  },
  {
    recordId: "02D",
    format: {
      yearLotAcquiredConstructionOrYearAcquiredRefinance: { position: 4, length: 4 },
      originalCostConstructionOrRefinance: { position: 8, length: 15 },
      amountExistingLiensConstructionOrRefinance: { position: 23, length: 15 },
      presentValueOfLot: { position: 38, length: 15 },
      costOfImprovements: { position: 53, length: 15 },
      purposeOfRefinance: { position: 68, length: 2 },
      describeImprovements: { position: 70, length: 80 },
      describeImprovementsMadTobeMade: { position: 150, length: 1 },
      describeImprovementsCost: { position: 151, length: 15 }
    },
    data: exportPurposeOfRefinance
  },
  {
    recordId: "02E",
    format: {
      downPaymentTypeCode: { position: 4, length: 2 },
      downPaymentAmount: { position: 6, length: 15 },
      downPaymentExplanation: { position: 21, length: 80 }
    },
    // TODO: This is data we simply do not track at all, I think. Based on the spec.
    data: () => []
  },
  {
    recordId: "03A",
    format: {
      applicantCoApplicantIndicator: { position: 4, length: 2 },
      applicantSocialSecurityNumber: { position: 6, length: 9 },
      applicantFirstName: { position: 15, length: 35 },
      applicantMiddleName: { position: 50, length: 35 },
      applicantLastName: { position: 85, length: 35 },
      applicantGeneration: { position: 120, length: 4 },
      homePhone: { position: 124, length: 10 },
      age: { position: 134, length: 3 },
      yrsSchool: { position: 137, length: 2 },
      maritalStatus: { position: 139, length: 1 },
      dependentsNo: { position: 140, length: 2 },
      completedJointlyNotJointly: { position: 142, length: 1 },
      crossReferenceNumber: { position: 143, length: 9 },
      dateOfBirth: { position: 152, length: 8 },
      emailAddress: { position: 160, length: 80 }
    },
    each: getBorrowers,
    data: exportApplicantData
  },
  {
    recordId: "03C",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      presentFormer: { position: 13, length: 2 },
      residenceStreetAddress: { position: 15, length: 50 },
      residenceCity: { position: 65, length: 35 },
      residenceState: { position: 100, length: 2 },
      residenceZipCode: { position: 102, length: 5 },
      residenceZipCodePlusFour: { position: 107, length: 4 },
      ownRentLivingRentFree: { position: 111, length: 1 },
      noYrs: { position: 112, length: 2 },
      noMonths: { position: 114, length: 2 },
      country: { position: 116, length: 50 }
    },
    each: getBorrowers,
    data: exportApplicantAddress
  },
  {
    recordId: "04A",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      employerName: { position: 13, length: 35 },
      employerStreetAddress: { position: 48, length: 35 },
      employerCity: { position: 83, length: 35 },
      employerState: { position: 118, length: 2 },
      employerZipCode: { position: 120, length: 5 },
      employerZipCodePlusFour: { position: 125, length: 4 },
      selfEmployed: { position: 129, length: 1 },
      yrsOnThisJob: { position: 130, length: 2 },
      monthsOnThisJob: { position: 132, length: 2 },
      yrsEmployedInThisLineOfWorkProfession: { position: 134, length: 2 },
      positionTitleTypeOfBusiness: { position: 136, length: 25 },
      businessPhone: { position: 161, length: 10 }
    },
    each: getBorrowers,
    data: exportPrimaryCurrentEmployer
  },
  {
    recordId: "04B",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      employerName: { position: 13, length: 35 },
      employerStreetAddress: { position: 48, length: 35 },
      employerCity: { position: 83, length: 35 },
      employerState: { position: 118, length: 2 },
      employerZipCode: { position: 120, length: 5 },
      employerZipCodePlusFour: { position: 125, length: 4 },
      selfEmployed: { position: 129, length: 1 },
      currentEmploymentFlag: { position: 130, length: 1 },
      fromDate: { position: 131, length: 8 },
      toDate: { position: 139, length: 8 },
      monthlyIncome: { position: 147, length: 15 },
      positionTitleTypeOfBusiness: { position: 162, length: 25 },
      businessPhone: { position: 187, length: 10 }
    },
    each: getBorrowers,
    data: exportSecondaryEmployer
  },
  {
    recordId: "05H",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      presentProposedIndicator: { position: 13, length: 1 },
      housingPaymentTypeCode: { position: 14, length: 2 },
      housingPaymentAmountMonthlyHousingExp: { position: 16, length: 15 }
    },
    data: exportHousingExpenses
  },
  {
    recordId: "05I",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      typeOfIncomeCode: { position: 13, length: 2 },
      incomeAmountMonthlyIncome: { position: 15, length: 15 }
    },
    each: getBorrowers,
    data: exportIncome
  },
  // Besides the borrowers' individual income sources, net rental income should
  // also be exported in the 05I's, but only once overall.
  {
    recordId: "05I",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      typeOfIncomeCode: { position: 13, length: 2 },
      incomeAmountMonthlyIncome: { position: 15, length: 15 }
    },
    data: exportNetRentalIncome
  },
  {
    recordId: "06B",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      acctNo: { position: 13, length: 30 },
      lifeInsuranceCashOrMarketValue: { position: 43, length: 15 },
      lifeInsuranceFaceAmount: { position: 58, length: 15 }
    },
    each: getBorrowers,
    data: exportLifeInsurance
  },
  {
    recordId: "06C",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      accountAssetType: { position: 13, length: 3 },
      depositoryStockBondInstitutionName: { position: 16, length: 35 },
      depositoryStreetAddress: { position: 51, length: 35 },
      depositoryCity: { position: 86, length: 35 },
      depositoryState: { position: 121, length: 2 },
      depositoryZipCode: { position: 123, length: 5 },
      depositoryZipCodePlusFour: { position: 128, length: 4 },
      acctNo: { position: 132, length: 30 },
      cashOrMarketValue: { position: 162, length: 15 },
      numberOfStockBondShares: { position: 177, length: 7 },
      assetDescription: { position: 184, length: 80 },
      reservedForFutureUse: { position: 264, length: 1 },
      reservedForFutureUse2: { position: 265, length: 2 }
    },
    each: getBorrowers,
    data: exportAssets
  },
  {
    recordId: "06D",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      automobileMakeModel: { position: 13, length: 30 },
      automobileYear: { position: 43, length: 4 },
      cashOrMarketValue: { position: 47, length: 15 }
    },
    each: getBorrowers,
    data: exportAutomobiles
  },
  {
    recordId: "06F",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      expenseTypeCode: { position: 13, length: 3 },
      monthlyPaymentAmount: { position: 16, length: 15 },
      monthsLeftToPay: { position: 31, length: 3 },
      alimonyChildSupportSeparateMaintenanceOwedTo: { position: 34, length: 60 }
    },
    data: exportOtherExpenses
  },
  {
    recordId: "06G",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      propertyStreetAddress: { position: 13, length: 35 },
      propertyCity: { position: 48, length: 35 },
      propertyState: { position: 83, length: 2 },
      propertyZipCode: { position: 85, length: 5 },
      propertyZipCodePlusFour: { position: 90, length: 4 },
      propertyDisposition: { position: 94, length: 1 },
      typeOfProperty: { position: 95, length: 2 },
      presentMarketValue: { position: 97, length: 15 },
      amountOfMortgagesLiens: { position: 112, length: 15 },
      grossRentalIncome: { position: 127, length: 15 },
      mortgagePayments: { position: 142, length: 15 },
      insuranceMaintenanceTaxesMisc: { position: 157, length: 15 },
      netRentalIncome: { position: 172, length: 15 },
      currentResidenceIndicator: { position: 187, length: 1 },
      subjectPropertyIndicator: { position: 188, length: 1 },
      reoAssetId: { position: 189, length: 2 },
      reservedForFutureUse: { position: 191, length: 15 }
    },
    data: exportRealEstateOwned
  },
  {
    recordId: "06L",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      liabilityType: { position: 13, length: 2 },
      creditorName: { position: 15, length: 35 },
      creditorStreetAddress: { position: 50, length: 35 },
      creditorCity: { position: 85, length: 35 },
      creditorState: { position: 120, length: 2 },
      creditorZipCode: { position: 122, length: 5 },
      creditorZipCodePlusFour: { position: 127, length: 4 },
      acctNo: { position: 131, length: 30 },
      monthlyPaymentAmount: { position: 161, length: 15 },
      monthsLeftToPay: { position: 176, length: 3 },
      unpaidBalance: { position: 179, length: 15 },
      liabilityWillBePaidPriorToClosing: { position: 194, length: 1 },
      reoAssetId: { position: 195, length: 2 },
      resubordinatedIndicator: { position: 197, length: 1 },
      omittedIndicator: { position: 198, length: 1 },
      subjectPropertyIndicator: { position: 199, length: 1 },
      rentalPropertyIndicator: { position: 200, length: 1 }
    },
    data: exportLiabilities
  },
  {
    recordId: "07A",
    format: {
      purchasePrice: { position: 4, length: 15 },
      alterationsImprovementsRepairs: { position: 19, length: 15 },
      land: { position: 34, length: 15 },
      refinanceIncDebtsToBePaidOff: { position: 49, length: 15 },
      estimatedPrepaidItems: { position: 64, length: 15 },
      estimatedClosingCosts: { position: 79, length: 15 },
      pmiMipFundingFee: { position: 94, length: 15 },
      discount: { position: 109, length: 15 },
      subordinateFinancing: { position: 124, length: 15 },
      applicantsClosingCostsPaidBySeller: { position: 139, length: 15 },
      pmiMipFundingFeeFinanced: { position: 154, length: 15 }
    },
    data: exportDetailsOfTransaction
  },
  {
    recordId: "07B",
    format: {
      otherCreditTypeCode: { position: 4, length: 2 },
      amountOfOtherCredit: { position: 6, length: 15 }
    },
    data: exportOtherCredits
  },
  {
    recordId: "08A",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      areThereAnyOutstandingJudgmentsAgainstYou: { position: 13, length: 1 },
      haveYouBeenDeclaredBankruptWithinThePast7Years: { position: 14, length: 1 },
      haveYouHadPropertyForeclosedUponOrGivenTitleOrDeedInLieuThereofInTheLast7Years: { position: 15, length: 1 },
      areYouAPartyToALawsuit: { position: 16, length: 1 },
      haveYouDirectlyOrIndirectlyBeenObligatedOnAnyLoan: { position: 17, length: 1 },
      areYouPresentlyDelinquentOrInDefaultOnAnyFederalDebt: { position: 18, length: 1 },
      areYouObligatedToPayAlimonyChildSupportOrSeparateMaintenance: { position: 19, length: 1 },
      isAnyPartOfTheDownPaymentBorrowed: { position: 20, length: 1 },
      areYouACoMakerOr: { position: 21, length: 1 },
      areYouAUSCitizenKAreYouAPermanentResidentAlien: { position: 22, length: 2 },
      doYouIntendToOccupy: { position: 24, length: 1 },
      haveYouHadAnOwnershipInterest: { position: 25, length: 1 },
      whatTypeOfProperty: { position: 26, length: 1 },
      howDidYouHoldTitle: { position: 27, length: 2 }
    },
    each: getBorrowers,
    data: exportDeclarations
  },
  {
    recordId: "09A",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      signatureDate: { position: 13, length: 8 }
    },
    each: getBorrowers,
    data: exportAcknowledgeAndAgreement
  },
  {
    recordId: "10A",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      iDoNotWishToFurnishThisInformation: { position: 13, length: 1 },
      ethnicity: { position: 14, length: 1 },
      filler: { position: 15, length: 30 },
      sex: { position: 45, length: 1 }
    },
    each: getBorrowers,
    data: exportInformationForGovernment
  },
  {
    recordId: "10B",
    format: {
      thisApplicationWasTakenBy: { position: 4, length: 1 },
      loanOriginatorsName: { position: 5, length: 60 },
      interviewDate: { position: 65, length: 8 },
      loanOriginatorsPhoneNumber: { position: 73, length: 10 },
      loanOriginationCompanysName: { position: 83, length: 35 },
      loanOriginationCompanysStreetAddress: { position: 118, length: 35 },
      loanOriginationCompanysStreetAddress2: { position: 153, length: 35 },
      loanOriginationCompanysCity: { position: 188, length: 35 },
      loanOriginationCompanysStateCode: { position: 223, length: 2 },
      loanOriginationCompanysZipCode: { position: 225, length: 5 },
      loanOriginationCompanysZipCodePlusFour: { position: 230, length: 4 }
    },
    data: exportLoanOriginatorInformation
  },
  {
    recordId: "10R",
    format: {
      applicantSocialSecurityNumber: { position: 4, length: 9 },
      raceCodes: { position: 13, length: 2 }
    },
    each: getBorrowers,
    data: exportRaceForGovernment
  },
  {
    recordId: "000",
    format: {
      fileType: { position: 4, length: 3 },
      fileVersionId: { position: 7, length: 5 }
    },
    data: () => ({
      fileType: "70",
      fileVersionId: "3.20"
    })
  },
  {
    recordId: "99B",
    format: {
      sellerProvidedBelowMarketFinancing: { position: 4, length: 1 },
      ownerOfExistingMortgage: { position: 5, length: 2 },
      propertyAppraisedValue: { position: 7, length: 15 },
      buydownRate: { position: 22, length: 7 },
      actualVsEstimatedAppraisedValueIndicator: { position: 29, length: 2 },
      appraisalFieldworkOrdered: { position: 31, length: 3 },
      appraiserName: { position: 34, length: 60 },
      appraiserCompany: { position: 94, length: 35 },
      appraiserLicenseNumber: { position: 129, length: 15 },
      appraiserLicenseStateCode: { position: 144, length: 2 }
    },
    data: exportFannieMaeTransmittalData
  },
  {
    recordId: "ADS",
    format: {
      identifier: { position: 4, length: 35 },
      value: { position: 39, length: 50 }
    },
    data: exportLoanOriginationAdditionalData
  },
  {
    recordId: "ADS",
    format: {
      identifier: { position: 4, length: 35 },
      value: { position: 39, length: 50 }
    },
    each: getBorrowers,
    data: exportAdditionalDataSegments
  },
  {
    recordId: "000",
    format: {
      fileType: { position: 4, length: 3 },
      fileVersionId: { position: 7, length: 5 }
    },
    data: () => ({
      fileType: "11",
      fileVersionId: "3.20"
    })
  },
  {
    recordId: "LNC",
    format: {
      lienTypeCode: { position: 4, length: 1 },
      loanDocumentationTypeCode: { position: 5, length: 1 },
      subjectPropertyTypeCode: { position: 6, length: 2 },
      reservedForFutureUse: { position: 8, length: 2 },
      reservedForFutureUse2: { position: 10, length: 2 },
      reservedForFutureUse3: { position: 12, length: 2 },
      reservedForFutureUse4: { position: 14, length: 2 },
      projectClassificationCode: { position: 16, length: 2 },
      negativeAmortizationLimitPercent: { position: 18, length: 7 },
      balloonIndicator: { position: 25, length: 1 },
      filler: { position: 26, length: 1 },
      filler2: { position: 27, length: 1 },
      homebuyerEducationCompletionIndicator: { position: 28, length: 1 },
      maximumLifetimeRateIncrease: { position: 29, length: 7 },
      paymentAdjustmentLifePercentCap: { position: 36, length: 7 },
      paymentAdjustmentLifeAmountCap: { position: 43, length: 15 },
      willEscrowBeWaived: { position: 58, length: 1 },
      scheduledLoanClosingDate: { position: 59, length: 8 },
      scheduledFirstPaymentDate: { position: 67, length: 8 },
      miCoveragePercent: { position: 75, length: 7 },
      miInsurerCode: { position: 82, length: 3 },
      aprSpread: { position: 85, length: 5 },
      hoepa: { position: 90, length: 1 },
      preapproval: { position: 91, length: 1 }
    },
    data: exportLoanCharacteristics
  },
  {
    recordId: "PID",
    format: {
      productDescription: { position: 4, length: 30 },
      productCode: { position: 34, length: 15 },
      productPlanNumber: { position: 49, length: 5 }
    },
    data: () => ({})
  },
  {
    recordId: "PCH",
    format: {
      mortgageTerm: { position: 4, length: 3 },
      assumableLoanIndicator: { position: 7, length: 1 },
      paymentFrequencyCode: { position: 8, length: 2 },
      prepaymentPenaltyIndicator: { position: 10, length: 1 },
      prepaymentRestrictedIndicator: { position: 11, length: 1 },
      repaymentTypeCode: { position: 12, length: 2 }
    },
    data: exportProductCharacteristics
  },
  {
    recordId: "TT ",
    format: {
      transactionControlNumber: { position: 4, length: 9 }
    },
    data: () => ({
      transactionControlNumber: "1"
    })
  },
  {
    recordId: "ET ",
    format: {
      envelopeControlNumber: { position: 4, length: 9 }
    },
    data: () => ({
      envelopeControlNumber: "0"
    })
  }
];
