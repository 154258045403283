import React, { useEffect, useState, useRef } from "react";
import "./css/schedule_e_calculator.scss";

import NumberField from "../../fields/number_field";
import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";

import { VIEWS } from "../../../constants/investment_reo_views";
import {
  total_payment,
  total_gross_rental_income_from_tax_returns,
  total_gross_rental_income_from_rental_lease,
  total_net_monthly_rental_income_or_loss, wrap_subject_property_if_needed
} from "../../../calculations/investment_reo";
import { default_property } from "../../../schema/investment_property";

import TaxReturnColumn from "./tax_return_column";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import TextField from "../../fields/text_field";

export default function ScheduleECalculator({ property_index, openView }) {
  const [document, setDocument, , { saveDocument }] = useDocument();
  const [property, setProperty] = useState({
    ...default_property,
    ...document.investment_properties[property_index]
  });
  const callback = useRef();

  // update callback with current state values
  useEffect(() => {
    callback.current = () => {
      if (property.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in calculator. Would you like to save it before you leave?"
          )
        ) {
          save();
        } else {
          //do nothing
        }
      }
    };
  }, [property]);

  // prompt save modal on unmount
  useEffect(() => {
    // do nothing

    //cleanup
    return () => {
      callback.current();
    };
  }, []);

  /**
   * Event fired when the back button is pressed
   */
  function back() {
    openView(VIEWS.INVESTMENT_CALCULATOR);
  }

  /**
   * Event fired when the cancel button is pressed
   */
  function cancel() {
    openView(VIEWS.PROPERTY_TABLE);
  }

  /**
   * Event fired when the save button is pressed
   */
  function save() {
    delete property.is_modified;
    document.investment_properties[property_index] = property;

    setDocument({ ...document });
    saveDocument();
    openView(VIEWS.PROPERTY_TABLE);
  }

  function onChange(newState) {
    setProperty({ ...property, ...newState, is_modified: true });
  }

  function onChangeTaxReturn(updatedTaxReturn, taxReturnIndex) {
    const tax_returns = [...property.tax_returns];
    tax_returns[taxReturnIndex] = {
      ...tax_returns[taxReturnIndex],
      ...updatedTaxReturn
    };
    setProperty({ ...property, tax_returns, is_modified: true });
  }

  const propertyDescription =
    property_index === null || property_index === undefined
      ? "New Rental Property"
      : `Rental Property #${property_index + 1}`;

  const wrapped_property = wrap_subject_property_if_needed(document.property, property);

  const {
    gross_rent_percent_used_in_calculations,
    gross_rent_payment,
    property_address,
    year_of_purchase,
    tax_returns,
    months_included_in_income
  } = wrapped_property;

  //Rental lease information.
  const totalGrossIncomeRentalLease = total_gross_rental_income_from_rental_lease(
    wrapped_property
  );
  const totalNetMonthlyIncomeLoss = total_net_monthly_rental_income_or_loss(
    totalGrossIncomeRentalLease,
    total_payment(wrapped_property)
  );

  //Tax return information.
  const totalGrossRentalIncome = total_gross_rental_income_from_tax_returns(
    tax_returns,
    months_included_in_income
  );
  const totalNetMonthlyIncomeTaxReturns = total_net_monthly_rental_income_or_loss(
    totalGrossRentalIncome,
    total_payment(wrapped_property)
  );

  return (
    <div className="schedule-e-calculator">
      <div className="flex">
        <div className="third-width">{propertyDescription}</div>
        <div className="third-width">
          <TextField
            readOnly
            name="Invest. Property Name"
            value={property_address}
          />
        </div>
        <div className="third-width right-align">
          <b>Schedule E</b>
        </div>
      </div>
      <div>
        <i>
          Select box next to year(s) to be included in determining average
          monthly qualifying income
        </i>
      </div>

      <Section name="Rental Lease">
        <NumberField
          name="Rental Lease"
          fieldName="year_of_purchase"
          onChange={onChange}
          value={year_of_purchase}
        />

        <CurrencyField
          name="Gross Monthly Rent"
          fieldName="gross_rent_payment"
          onChange={onChange}
          value={gross_rent_payment}
        />

        <PercentField
          name="Percentage of Gross Monthly Rents used in Calculations"
          fieldName="gross_rent_percent_used_in_calculations"
          onChange={onChange}
          value={gross_rent_percent_used_in_calculations}
        />

        <CurrencyField
          readOnly
          name="Total Gross Rental Income (amounts to be entered on REO Screen)"
          value={totalGrossIncomeRentalLease.toString()}
        />

        <CurrencyField
          name="Total Current P&amp;I (1st &amp; 2nd mortgages), Taxes, Insurance (Hazard &amp; Flood), HOA etc."
          readOnly
          value={total_payment(property)}
        />

        <CurrencyField
          readOnly
          name="Total Net Monthly Rental Income or Loss"
          value={totalNetMonthlyIncomeLoss.toString()}
        />
      </Section>

      <Section name="Tax Returns">
        <div className="tax-returns">
          <div className="flex-space-between">
            <div>
              <div className="tax-return-label">Tax Returns:</div>
              <div className="flex">
                <div>
                  <div className="tax-return-label">Income(Loss):</div>
                  <div className="tax-return-label">Depreciation:</div>
                  <div className="tax-return-label">Insurance:</div>
                  <div className="tax-return-label">Mortgage Interest:</div>
                  <div className="tax-return-label">Taxes:</div>
                  <div className="tax-return-label">HOA:</div>
                  <div className="tax-return-label">
                    Repairs (non-recurring):
                  </div>
                  <div className="tax-return-label">Add back: Other:</div>
                  <div className="tax-return-label">
                    Total Gross Rental Income from Tax Returns:
                  </div>
                </div>
                <div>
                  <div className="tax-return-label">
                    Sch E Line 21 / Form 8825 Line 17
                  </div>
                  <div className="tax-return-label">
                    Sch E Line 18 / Form 8825 Line 14
                  </div>
                  <div className="tax-return-label">
                    Sch E Line 9 / Form 8825 Line 7
                  </div>
                  <div className="tax-return-label">
                    Sch E Lines 12 - 13 / Form 8825 Line 9
                  </div>
                  <div className="tax-return-label">
                    Sch E Line 16 / Form 8825 Line 11
                  </div>
                  <div className="tax-return-label">
                    Sch E Lines 19 / Form 8825 Line 15
                  </div>
                  <div className="tax-return-label">
                    Sch E Lines 14 / Form 8825 Line 15
                  </div>
                </div>
              </div>
            </div>
            <div className="flex">
              {tax_returns.map((taxReturn, index) => (
                <TaxReturnColumn
                  key={index}
                  taxReturnIndex={index}
                  onChange={onChangeTaxReturn}
                  taxReturn={taxReturn}
                />
              ))}
            </div>
          </div>
          <div className="divider" />
          <div className="tax-return-totals">
            <NumberField
              name="Total Number of Months included in Income"
              fieldName="months_included_in_income"
              onChange={onChange}
              value={months_included_in_income}
            />
            <CurrencyField
              readOnly
              name="Total Gross Rental Income (amount to be entered on REO Screen)"
              value={totalGrossIncomeRentalLease.toString()}
            />

            <CurrencyField
              readOnly
              name="Total Current P&amp;I (1st &amp; 2nd mortgages), Taxes, Insurance, HOA, etc."
              value={total_payment(wrapped_property)}
            />

            <CurrencyField
              readOnly
              name={
                <div className="bold">
                  Total NET Monthly Rental Income or Loss
                </div>
              }
              value={totalNetMonthlyIncomeTaxReturns.toString()}
            />
          </div>
        </div>

        <div>
          <button className="button" onClick={cancel}>
            Cancel
          </button>
          <button className="button" onClick={back}>
            Back
          </button>
          <button className="button" onClick={save}>
            Save
          </button>
        </div>
      </Section>
    </div>
  );
}
