import Decimal from "decimal.js";

import * as FREQUENCY from "../constants/frequencies";
import { HOA_PROPERTY_TYPES } from "../constants/property_type";
import { YES } from "../constants/yes_no";
import { REFINANCE } from "../constants/document_types";
import { selected_balance_pay_offs_total } from "./debt_info";
import { LENDER_FEE } from "../constants/fixed_values";

/**
 * Selling Property - 1st Current Monthly Payment (Principal & Interest)
 */
export function first_current_monthly_payment(property_to_be_sold) {
  return new Decimal(
    property_to_be_sold.first_current_monthly_payment_principal
  ).add(property_to_be_sold.first_current_monthly_payment_interest);
}

/**
 * Calculation for the total of loans
 */
export function total_of_loans(
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loans,
  summary
) {
  return new Decimal(property_to_be_sold.first_loan_balance)
    .add(property_to_be_sold.second_loan_balance)
    .add(
      new Decimal(property_to_be_sold.miscellaneous_housing_payment).gt(0)
        ? property_to_be_sold.third_loan_balance
        : 0
    )
    .add(
      documentType === REFINANCE
        ? selected_balance_pay_offs_total(
          loans,
          summary,
          accountSummaries,
          residences
        )
        : 0
    );
}

/**
 * Calculation for the total of payments
 */
export function total_of_payments(property_to_be_sold) {
  return new Decimal(first_current_monthly_payment(property_to_be_sold))
    .plus(property_to_be_sold.second_current_monthly_payment)
    .plus(property_to_be_sold.miscellaneous_housing_payment)
    .plus(property_to_be_sold.existing_mortgage_insurance);
}

/**
 * Calculation for Home Owners Insurance (Monthly)
 */
export function home_owners_insurance_monthly(documentType, property) {
  if (documentType === REFINANCE) {
    const value = property.home_owners_insurance;
    switch (property.home_owners_insurance_frequency) {
      case FREQUENCY.ANNUALLY:
        return new Decimal(value).div(12);
      case FREQUENCY.QUARTERLY:
        return new Decimal(value).div(3);
      default:
        return new Decimal(value);
    }
  }
  return new Decimal(property.home_owners_insurance).div(12);
}

/**
 * Helper - If the property is of type that includes HOA
 */
function is_hoa_property(property) {
  return HOA_PROPERTY_TYPES.includes(property.property_type);
}

/**
 * Calculation for HOA Dues 1
 */
export function hoa_dues_1(property, loan) {
  if (
    !is_hoa_property(property) &&
    (!loan || !HOA_PROPERTY_TYPES.includes(loan.property_type))
  ) {
    return new Decimal(0);
  }

  let frequency = property.hoa1_frequency;
  let value = property.hoa1;
  if (loan && HOA_PROPERTY_TYPES.includes(loan.property_type)) {
    frequency = loan.hoa1_frequency;
    value = loan.hoa1;
  }
  switch (frequency) {
    case FREQUENCY.ANNUALLY:
      return new Decimal(value).div(12);
    case FREQUENCY.QUARTERLY:
      return new Decimal(value).div(3);
    default:
      return new Decimal(value);
  }
}

/**
 * Calculation for HOA Dues 2
 */
export function hoa_dues_2(property, loan) {
  if (
    !is_hoa_property(property) &&
    (!loan || !HOA_PROPERTY_TYPES.includes(loan.property_type))
  ) {
    return new Decimal(0);
  }

  let frequency = property.hoa2_frequency;
  let value = property.hoa2;
  if (loan && HOA_PROPERTY_TYPES.includes(loan.property_type)) {
    frequency = loan.hoa2_frequency;
    value = loan.hoa2;
  }
  switch (frequency) {
    case FREQUENCY.ANNUALLY:
      return new Decimal(value).div(12);
    case FREQUENCY.QUARTERLY:
      return new Decimal(value).div(3);
    default:
      return new Decimal(value);
  }
}

/**
 * Calculation for Mello Roos
 */
export function mello_roos(property, loan) {
  if (
    (!is_hoa_property(property) || property.mello_roos !== YES) &&
    (!loan || !HOA_PROPERTY_TYPES.includes(loan.property_type))
  ) {
    return new Decimal(0);
  }

  let frequency = property.mello_roos_frequency;
  let value = property.mello_roos_amount;
  if (loan && HOA_PROPERTY_TYPES.includes(loan.property_type)) {
    frequency = loan.mello_roos_frequency;
    value = loan.mello_roos_amount;
  }
  switch (frequency) {
    case FREQUENCY.ANNUALLY:
      return new Decimal(value).div(12);
    case FREQUENCY.QUARTERLY:
      return new Decimal(value).div(3);
    default:
      return new Decimal(value);
  }
}

/**
 * Total HOA per month.
 */
export function total_hoas_monthly(property, loan) {
  return hoa_dues_1(property, loan).add(hoa_dues_2(property, loan));
}

/**
 * Gets the renderable value of reported on 1040
 * @param {*} property
 */
export function reported_on_1040(property) {
  switch (property.reported_on_1040) {
    case "1":
      return "Yes Rpt";
    case "2":
      return "Not Rpt";
    default:
      throw Error(
        `Invalid reported_on_1040 value: ${property.reported_on_1040}`
      );
  }
}

/**
 * Summary Sheet - Refinance - General Info Section.
 */
export function first_and_second_current_monthly_payment(property_to_be_sold) {
  return first_current_monthly_payment(property_to_be_sold).add(
    property_to_be_sold.second_current_monthly_payment
  );
}

/**
 * Summary Sheet - Refinance - General Info Section.
 */
export function first_and_second_current_monthly_payment_with_lender_fee(
  property_to_be_sold
) {
  const secondPayment = new Decimal(
    property_to_be_sold.second_current_monthly_payment
  );
  return new Decimal(0)
    .add(
      first_current_monthly_payment(property_to_be_sold).gt(0)
        ? first_current_monthly_payment(property_to_be_sold).add(LENDER_FEE)
        : 0
    )
    .add(secondPayment.gt(0) ? secondPayment.add(LENDER_FEE) : 0);
}

/**
 * Summary Sheet - Refinance - FHA / PMI Mo Pmt.
 */
export function fha_or_pmi_mo_pmt(property_to_be_sold) {
  return new Decimal(property_to_be_sold.existing_mortgage_insurance).add(
    property_to_be_sold.miscellaneous_housing_payment
  );
}

/**
 * Summary Sheet - Refinance - Total PI MI Fees
 */
export function total_pi_mi_fees(property_to_be_sold) {
  return first_and_second_current_monthly_payment(property_to_be_sold).add(
    fha_or_pmi_mo_pmt(property_to_be_sold)
  );
}

/**
 * Summary Sheet - Refinance - FHA / PMI Pay Off
 */
export function fha_or_pmi_pay_off(property_to_be_sold) {
  const miscellaneousHousingPayment = new Decimal(
    property_to_be_sold.miscellaneous_housing_payment
  );
  return miscellaneousHousingPayment.gt(0)
    ? miscellaneousHousingPayment
    : new Decimal(property_to_be_sold.existing_mortgage_insurance);
}

/**
 * Summary Sheet - Refinance - Total PI & MI
 */
export function total_pi_and_mi(property_to_be_sold) {
  return first_and_second_current_monthly_payment_with_lender_fee(
    property_to_be_sold
  ).add(fha_or_pmi_pay_off(property_to_be_sold));
}
