import React from "react";
import {
  home_owners_insurance_monthly,
  mello_roos
} from "../../../calculations/property";
import NumberField from "../../fields/number_field";
import {
  annual_property_tax,
  initial_escrow_payment_at_closing_homeowners_insurance,
  initial_escrow_payment_at_closing_mortgage_insurance,
  initial_escrow_payment_at_closing_property_taxes,
  initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly,
  mortgage_insurance_premium_monthly,
  total_initial_escrow_payment_at_closing
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../loan_tool";
import { is_fha, is_usda } from "../../../constants/utils";

export default function InitialEscrowPaymentAtClosing({
  loan,
  property,
  readOnly,
  updateLoan
}) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const melloRoos = mello_roos(property, loan);

  return (
    <div className="initial-escrow-payment-at-closing">
      <div className="closing-costs-row top-border gray-bg">
        <div className="section-title">
          G. Initial Escrow Payment at Closing
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_initial_escrow_payment_at_closing(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Homeowner&apos;s Insurance
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={home_owners_insurance_monthly(documentType, property)}
        />
        <NumberField
          className="closing-costs-cell center-align"
          fieldName="initial_escrow_payment_at_closing_homeowners_insurance_months"
          onChange={updateLoan}
          value={
            loan.initial_escrow_payment_at_closing_homeowners_insurance_months
          }
          readOnly={readOnly}
        />
        <div className="closing-costs-cell">Months</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={initial_escrow_payment_at_closing_homeowners_insurance(
            documentType,
            loan,
            property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Mortgage Insurance
        </div>
        {is_fha(loan.type) || is_usda(loan.type) ? (
          <>
            <div className="closing-costs-cell gray-bg" />
            <div className="closing-costs-cell gray-bg" />
            <div className="closing-costs-cell">Months</div>
            <div className="closing-costs-cell gray-bg" />
          </>
        ) : (
          <>
            <CurrencyField
              className="closing-costs-cell right-align gray-bg"
              naked
              fixedDigits={0}
              value={mortgage_insurance_premium_monthly(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
            <NumberField
              className="closing-costs-cell center-align"
              fieldName="initial_escrow_payment_at_closing_mortgage_insurance_months"
              onChange={updateLoan}
              value={
                loan.initial_escrow_payment_at_closing_mortgage_insurance_months
              }
              readOnly={readOnly}
            />
            <div className="closing-costs-cell">Months</div>
            <CurrencyField
              className="closing-costs-cell right-align gray-bg"
              naked
              fixedDigits={0}
              value={initial_escrow_payment_at_closing_mortgage_insurance(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          </>
        )}
      </div>
      <div className="closing-costs-row">
        <div className="closing-costs-cell-shell triple-width">
          Property Taxes{melloRoos.gt(0) && " + Mello Roos"}
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
            documentType,
            loan,
            property
          )}
        />
        <NumberField
          className="closing-costs-cell center-align"
          fieldName="initial_escrow_payment_at_closing_property_taxes_months"
          onChange={updateLoan}
          value={loan.initial_escrow_payment_at_closing_property_taxes_months}
          readOnly={readOnly}
        />
        <div className="closing-costs-cell">Months</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={initial_escrow_payment_at_closing_property_taxes(
            documentType,
            loan,
            property
          )}
        />
      </div>
      <div className="closing-costs-row flex-start">
        <div className="closing-costs-cell-shell triple-width">
          Property Taxes Per Year
        </div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={annual_property_tax(documentType, loan, property)}
        />
      </div>
    </div>
  );
}
