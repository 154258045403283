import React from "react";

import {
  year_to_payment,
  payment_to_year,
  two_year_average,
  year_to_month_income
} from "../../calculations/income";

import CurrencyField from "../fields/currency_field";
import NumberField from "../fields/number_field";

export default function HourlyIncomeCalculator({
  incomeSource,
  onChangeIncome
}) {
  const {
    year_0,
    year_1,
    base_income_0,
    base_hours_per_week,
    base_weeks_per_year,
    bonuses_0,
    bonuses_1,
    commission_0,
    commission_1,
    overtime_0,
    overtime_1,
    overtime_hours_per_week,
    overtime_weeks_per_year
  } = incomeSource;

  const paymentPerWeek = year_to_payment(base_income_0, base_weeks_per_year);
  const paymentPerHour = year_to_payment(paymentPerWeek, base_hours_per_week);
  const overtimePaymentPerWeek = year_to_payment(
    overtime_0,
    overtime_weeks_per_year
  );

  const overtimePaymentPerHour = year_to_payment(
    overtimePaymentPerWeek,
    overtime_hours_per_week
  );

  const overtimeAverage = two_year_average(overtime_0, overtime_1);

  function onUpdateIncome(updatedState) {
    const prevPaymentPerWeek = year_to_payment(
      incomeSource.base_income_0,
      incomeSource.base_weeks_per_year
    );
    const prevPaymentPerHour = year_to_payment(
      prevPaymentPerWeek,
      incomeSource.base_hours_per_week
    );

    const base_weeks_per_year = updatedState.hasOwnProperty(
      "base_weeks_per_year"
    )
      ? updatedState.base_weeks_per_year
      : incomeSource.base_weeks_per_year;
    const base_hours_per_week = updatedState.hasOwnProperty(
      "base_hours_per_week"
    )
      ? updatedState.base_hours_per_week
      : incomeSource.base_hours_per_week;
    const paymentPerHour = updatedState.hasOwnProperty("payment_per_hour")
      ? updatedState.payment_per_hour
      : prevPaymentPerHour;
    const paymentPerWeek = payment_to_year(paymentPerHour, base_weeks_per_year);
    const yearly_income = payment_to_year(paymentPerWeek, base_hours_per_week);

    onChangeIncome({
      base_income_0: yearly_income,
      base_weeks_per_year,
      base_hours_per_week
    });
  }

  function onChange(updatedState) {
    onChangeIncome(updatedState);
  }

  function onChangeOvertime(updatedState) {
    const prevOvertimePerWeek = year_to_payment(
      incomeSource.overtime_0,
      incomeSource.overtime_weeks_per_year
    );
    const prevOvertimePerHour = year_to_payment(
      prevOvertimePerWeek,
      incomeSource.overtime_hours_per_week
    );

    const overtime_weeks_per_year = updatedState.hasOwnProperty(
      "overtime_weeks_per_year"
    )
      ? updatedState.overtime_weeks_per_year
      : incomeSource.overtime_weeks_per_year;
    const overtime_hours_per_week = updatedState.hasOwnProperty(
      "overtime_hours_per_week"
    )
      ? updatedState.overtime_hours_per_week
      : incomeSource.overtime_hours_per_week;

    const overtimePerHour = updatedState.hasOwnProperty("overtime_per_hour")
      ? updatedState.overtime_per_hour
      : prevOvertimePerHour;
    const overtimePerWeek = payment_to_year(
      overtimePerHour,
      overtime_weeks_per_year
    );
    const yearly_overtime = payment_to_year(
      overtimePerWeek,
      overtime_hours_per_week
    );

    onChangeIncome({
      overtime_0: yearly_overtime,
      overtime_weeks_per_year,
      overtime_hours_per_week
    });
  }

  return (
    <div className="edit-borrower-income-calculator">
      <table>
        <thead>
          <tr>
            <th />
            <th>Hourly Pay</th>
            <th>Hrs per Week</th>
            <th>Weeks Per Year</th>
            <th>Hourly Inc/Yr</th>
            <th>Gross Per Year</th>
            <th>Gross Per Month</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <b>Base</b>
            </td>
            <td>
              <CurrencyField
                fieldName="payment_per_hour"
                onChange={onUpdateIncome}
                value={paymentPerHour}
              />
            </td>
            <td>
              <NumberField
                fieldName="base_hours_per_week"
                onChange={onUpdateIncome}
                value={base_hours_per_week}
              />
            </td>
            <td>
              <NumberField
                fieldName="base_weeks_per_year"
                onChange={onUpdateIncome}
                value={base_weeks_per_year}
              />
            </td>
            <td>
              <CurrencyField readOnly value={base_income_0} />
            </td>
            <td>
              <CurrencyField readOnly value={base_income_0} />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_payment(base_income_0, "12")}
              />
            </td>
          </tr>

          <tr>
            <td>
              <b>Overtime</b>
            </td>
            <td>
              <CurrencyField
                fieldName="overtime_per_hour"
                onChange={onChangeOvertime}
                value={overtimePaymentPerHour}
              />
            </td>
            <td>
              <NumberField
                fieldName="overtime_weeks_per_year"
                onChange={onChangeOvertime}
                value={overtime_weeks_per_year}
              />
            </td>
            <td>
              <NumberField
                fieldName="overtime_hours_per_week"
                onChange={onChangeOvertime}
                value={overtime_hours_per_week}
              />
            </td>
            <td>
              <CurrencyField readOnly value={overtime_0} />
            </td>
            <td>
              <CurrencyField readOnly value={overtime_0} />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_payment(overtime_0, "12")}
              />
            </td>
          </tr>

          <tr>
            <td className="right-align" colSpan="5">
              <b>Overtime in Prev. Year</b>
            </td>
            <td>
              <CurrencyField
                fieldName="overtime_1"
                onChange={onChange}
                value={overtime_1}
              />
            </td>
            <td />
          </tr>
        </tbody>

        <tbody>
          <tr>
            <td className="right-align" colSpan="5">
              <b>2 Year Average Overtime</b>
            </td>
            <td>
              <CurrencyField readOnly value={overtimeAverage} />
            </td>
            <td />
          </tr>
        </tbody>
      </table>

      <table>
        <thead>
          <tr>
            <th>
              <NumberField
                fieldName="year_0"
                onChange={onChange}
                value={year_0}
              />
            </th>
            <th>Per Year</th>
            <th>Per Month</th>

            <th>
              <NumberField
                fieldName="year_1"
                onChange={onChange}
                value={year_1}
              />
            </th>
            <th>Per Month</th>
            <th>2 Yr Avg.</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <b>Bonuses</b>
            </td>
            <td>
              <CurrencyField
                fieldName="bonuses_0"
                onChange={onChange}
                value={bonuses_0}
              />
            </td>

            <td>
              <CurrencyField readOnly value={year_to_month_income(bonuses_0)} />
            </td>

            <td>
              <CurrencyField
                fieldName="bonuses_1"
                onChange={onChange}
                value={bonuses_1}
              />
            </td>
            <td>
              <CurrencyField readOnly value={year_to_month_income(bonuses_1)} />
            </td>

            <td>
              <CurrencyField
                tabIndex="-1"
                readOnly
                value={two_year_average(bonuses_0, bonuses_1)}
              />
            </td>
          </tr>

          <tr>
            <td>
              <b>Commission</b>
            </td>
            <td>
              <CurrencyField
                fieldName="commission_0"
                onChange={onChange}
                value={commission_0}
              />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_month_income(commission_0)}
              />
            </td>

            <td>
              <CurrencyField
                fieldName="commission_1"
                onChange={onChange}
                value={commission_1}
              />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_month_income(commission_1)}
              />
            </td>

            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={two_year_average(commission_0, commission_1)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
