export const RATE_0_45 = "0.45";
export const RATE_0_70 = "0.70";
export const RATE_0_80 = "0.80";
export const RATE_0_85 = "0.85";
export const RATE_0_95 = "0.95";
export const RATE_1_00 = "1.00";
export const RATE_1_05 = "1.05";

export default Object.freeze({
  [RATE_0_45]: "0.45%",
  [RATE_0_70]: "0.70%",
  [RATE_0_80]: "0.80%",
  [RATE_0_85]: "0.85%",
  [RATE_0_95]: "0.95%",
  [RATE_1_00]: "1.00%",
  [RATE_1_05]: "1.05%"
});

export const RATE_FACTOR_PMI = Object.freeze({
  "0.89": "0.89%",
  "0.72": "0.72%",
  "0.64": "0.64%",
  "0.62": "0.62%",
  "0.44": "0.44%",
  "0.42": "0.42%",
  "0.35": "0.35%",
  "0.33": "0.33%",
  "1.08": "1.08%"
});
