export const PRINCIPAL_INTEREST = "1";
export const INTEREST_ONLY = "2";

export default Object.freeze({
  [PRINCIPAL_INTEREST]: "Pri. & Int.",
  [INTEREST_ONLY]: "Interest Only"
});

export const PAYMENT_PERIODS = Object.freeze({
  "12": "12",
  "24": "24",
  "36": "36",
  "48": "48",
  "60": "60",
  "72": "72",
  "84": "84",
  "120": "120",
  "180": "180"
});
