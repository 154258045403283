import React, { useEffect } from "react";
import { Router } from "@reach/router";
import { useGovernor } from "@techempower/react-governor";
import LoginPage from "../pages/login";
import UserService from "../services/user_service";
import {
  default_account_profile,
  default_company_profile
} from "../schema/profile";
import { setAccessToken, removeAccessToken } from "../services/utils";
import SignUpPage from "../pages/sign_up";

const UserContext = React.createContext();

/**
 * Helper to format user profile from the backend into account profile for the
 * frontend.
 */
export function processUserProfile(userProfile) {
  const profile = { ...userProfile };
  const content = profile.content;
  delete profile.content;

  return {
    ...default_account_profile,
    ...(content ? JSON.parse(content) : {}),
    ...profile
  };
}

export function processCompanyProfile(companyProfile) {
  const profile = { ...companyProfile };
  const content = profile.content;
  delete profile.content;

  return {
    ...default_company_profile,
    ...(content ? JSON.parse(content) : {}),
    ...profile
  };
}

const initialState = {
  isLoggedIn: UserService.isLoggedIn(),
  accountProfile: { ...default_account_profile },
  companyProfile: { ...default_company_profile }
};

const contract = {
  /**
   * Attempts to login the user given by username and password.
   * @param {*} username
   * @param {*} password
   */
  async login(username, password, setErrorMessage) {
    try {
      const success = await UserService.login(username, password);

      if (success) {
        return state => ({
          ...state,
          isLoggedIn: true
        });
      } else {
        // TODO - handle this better
        setErrorMessage("Failed login");
      }
    } catch (e) {
      // TODO - handle this better
      setErrorMessage(e.statusText);
    }
    return state => ({
      ...state,
      isLoggedIn: false
    });
  },

  async setAccessToken(token) {
    if (token) {
      setAccessToken(token);
    } else {
      removeAccessToken();
    }

    return state => ({
      ...state,
      isLoggedIn: !!token
    });
  },

  /**
   * Attempts to logout the currently logged in user out.
   */
  async logout(setErrorMessage) {
    try {
      await UserService.logout();
    } catch (e) {
      // TODO - handle this better
      setErrorMessage(e.statusText);
    }

    return state => ({
      ...state,
      isLoggedIn: false
    });
  },

  async getProfile() {
    try {
      const profile = await UserService.getProfile();
      return state => ({
        ...state,
        accountProfile: processUserProfile(profile.userProfile),
        companyProfile: processCompanyProfile(profile.companyProfile)
      });
    } catch (e) {
      // TODO
    }
    return state => ({
      ...state
    });
  },

  async setAccountProfile(accountProfile) {
    return state => ({
      ...state,
      accountProfile: accountProfile
    });
  },

  async saveAccountProfile(accountProfile) {
    try {
      const profile = await UserService.postUserProfile(accountProfile);
      return state => ({
        ...state,
        accountProfile: processUserProfile(profile)
      });
    } catch (e) {
      // TODO
    }
    return state => ({
      ...state
    });
  },

  async setCompanyProfile(companyProfile) {
    return state => ({
      ...state,
      companyProfile: companyProfile
    });
  },

  async saveCompanyProfile(companyProfile, companyLogo) {
    try {
      const profile = await UserService.postCompanyProfile(
        companyProfile,
        companyLogo
      );
      return state => ({
        ...state,
        companyProfile: processCompanyProfile(profile)
      });
    } catch (e) {
      // TODO
    }
    return state => ({
      ...state
    });
  }
};

/**
 * UserProvider is a context provider of the user state. Children (at
 * any depth) may call `useUser()`.
 * @see `useUser`
 * @param {*} props
 */
export function UserProvider(props) {
  const [state, actions] = useGovernor(initialState, contract);

  useEffect(() => {
    if (state.isLoggedIn) {
      actions.getProfile();
    }
  }, [state.isLoggedIn]);

  return (
    <UserContext.Provider value={[state, actions]}>
      {state.isLoggedIn ? props.children : (
        <Router>
          <SignUpPage path="/sign-up" />
          <LoginPage default />
        </Router>
      )}
    </UserContext.Provider>
  );
}

/**
 * Custom hook to consume the provided user context.
 * @returns {Object[]} [state, actions]
 */
export function useUser() {
  return React.useContext(UserContext);
}
