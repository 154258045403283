import React from "react";
import "./css/misc.scss";
import { useDocument } from "../../components/loan_tools/loan_tool";
import { Link } from "@reach/router";

export default function NoBorrowers() {
  const [document, , { documentId }] = useDocument();

  const isShow =
    !document ||
    !document.applicant ||
    !document.applicant.borrowers ||
    document.applicant.borrowers.length === 0;

  return (
    <>
      {isShow && (
        <div className="no-borrowers">
          Borrowers have not been added yet. Please go to the{" "}
          <Link
            className="button"
            to={`/loan-tool/${documentId}/applicant-info`}
          >
            Applicant Info
          </Link>{" "}
          page to set them up.
        </div>
      )}
    </>
  );
}
