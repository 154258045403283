import React, { useEffect, useState, useRef } from "react";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import { default_loan } from "../../../schema/loan";
import PREPAIDS_DAYS_OF_INTEREST from "../../../constants/prepaids_days_of_interest";
import { prepaids_calculator } from "../../../calculations/loan";
import SelectField from "../../fields/select_field";
import { useDocument } from "../loan_tool";
import Section from "../../ui/section";

export default function PrepaidsCalculator({
  loan,
  readOnly,
  returnBack,
  updateLoan
}) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType },
    { saveDocument }
  ] = useDocument();
  const [prepaids, setPrepaids] = useState({
    ...default_loan.prepaids,
    ...loan.prepaids
  });
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (prepaids.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Prepaids Form. Would you like to save it before you leave?"
          )
        ) {
          onSave();
        } else {
          // do nothing
        }
      }
    };
  }, [prepaids]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function updatePrepaids(updatedEntry) {
    setPrepaids({
      ...prepaids,
      ...updatedEntry,
      is_modified: true
    });
  }

  function onSave() {
    delete prepaids.is_modified;
    updateLoan({ prepaids });
    saveDocument();
    returnBack();
  }

  return (
    <Section className="prepaids-calculator" name="Prepaids section F">
      <SelectField
        fieldName="days_of_interest"
        name="Days of Interest"
        value={prepaids.days_of_interest}
        selectOptions={PREPAIDS_DAYS_OF_INTEREST}
        onChange={updatePrepaids}
        readOnly={readOnly}
        title="LANIS Training Tip: Some lenders use 360 or 365 days to calculate Prepaid Interest. Check with you lender because it can based on the number of days have an effect on the APR and re-disclosing your Loan / LE."
      />
      <CurrencyField
        name="Prepaid Interest"
        readOnly
        fixedDigits={4}
        value={prepaids_calculator.prepaid_interest(
          documentType,
          loan,
          prepaids,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />
      <NumberField
        fieldName="per_days"
        name="Per Days"
        value={prepaids.per_days}
        onChange={updatePrepaids}
        readOnly={readOnly}
        title="LANIS Training Tip: Federal Law States you must have a minimum of 15 days on the LE for Agency loans."
      />
      <CurrencyField
        name="Total Interest"
        readOnly
        value={prepaids_calculator.total_interest(
          documentType,
          loan,
          prepaids,
          property,
          property_to_be_sold,
          is_selling_property
        )}
      />

      <button className="button" onClick={returnBack}>
        Cancel
      </button>
      <button className="button" onClick={onSave}>
        Save
      </button>
    </Section>
  );
}
