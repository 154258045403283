export const BRIDGE_LOAN = "1";
export const CASH_ON_HAND = "2";
export const CHECKING_SAVINGS = "3";
export const DEPOSIT_ON_SALES_CONTRACT = "4";
export const EQUITY_FROM_PENDING_SALE = "5";
export const EQUITY_FROM_SUBJECT_PROPERTY = "6";
export const EQUITY_ON_SOLD_PROPERTY = "7";
export const GIFT_EMPLOYER = "8";
export const GIFT_GOVERNMENT_ASSISTANCE = "9";
export const GIFT_NON_SELLER_FUNDED_NON_PROFIT = "10";
export const GIFT_RELATIVE = "11";
export const GIFT_SELLER_FUNDED_NON_PROFIT = "12";
export const GIFT_SOURCE_N_A = "13";
export const LIFE_INSURANCE_CASH_VALUE = "14";
export const LOT_EQUITY = "15";
export const OTHER_TYPE_OF_DOWN_PAYMENT = "16";
export const RENT_WITH_OPTION_TO_PURCHASE = "17";
export const RETIREMENT_FUNDS = "18";
export const SALE_OF_CHATTEL = "19";
export const SECURED_BORROWED_FUNDS = "20";
export const STOCKS_BONDS = "21";
export const SWEAT_EQUITY = "22";
export const TRADE_EQUITY = "23";
export const TRUST_FUNDS = "24";
export const UNSECURED_BORROWED_FUNDS = "25";
export const AUTOMOBILE = "29";

export const TYPE_ASSET = "26";
export const TYPE_GIFT = "27";
export const TYPE_RETIREMENT = "28";
export const RESERVE_TYPES = {
  [TYPE_ASSET]: "Asset",
  [TYPE_GIFT]: "Gift",
  [TYPE_RETIREMENT]: "Retirement"
};

export const ASSET_SUBTYPES = Object.freeze({
  [BRIDGE_LOAN]: "Bridge Loan",
  [CASH_ON_HAND]: "Cash on Hand",
  [CHECKING_SAVINGS]: "Checking/Savings",
  [DEPOSIT_ON_SALES_CONTRACT]: "Deposit on Sales Contract",
  [EQUITY_FROM_PENDING_SALE]: "Equity from Pending Sale",
  [EQUITY_FROM_SUBJECT_PROPERTY]: "Equity from Subject Property",
  [EQUITY_ON_SOLD_PROPERTY]: "Equity on Sold Property",
  [LIFE_INSURANCE_CASH_VALUE]: "Life Insurance Cash Value",
  [LOT_EQUITY]: "Lot Equity",
  [OTHER_TYPE_OF_DOWN_PAYMENT]: "Other Type of Down Payment",
  [RENT_WITH_OPTION_TO_PURCHASE]: "Rent with Option to Purchase",
  [SALE_OF_CHATTEL]: "Sale of Chattel",
  [SECURED_BORROWED_FUNDS]: "Secured Borrowed Funds",
  [STOCKS_BONDS]: "Stocks & Bonds",
  [SWEAT_EQUITY]: "Sweat Equity",
  [TRADE_EQUITY]: "Trade Equity",
  [TRUST_FUNDS]: "Trust Funds",
  [UNSECURED_BORROWED_FUNDS]: "Unsecured Borrowed Funds",
  [AUTOMOBILE]: "Automobile",
});

export const GIFT_SUBTYPES = Object.freeze({
  [GIFT_EMPLOYER]: "Gift (Employer)",
  [GIFT_GOVERNMENT_ASSISTANCE]: "Gift (Government Assistance)",
  [GIFT_NON_SELLER_FUNDED_NON_PROFIT]: "Gift (Non Seller Funded Non-Profit)",
  [GIFT_RELATIVE]: "Gift (Relative)",
  [GIFT_SELLER_FUNDED_NON_PROFIT]: "Gift (Seller Funded Non-Profit)",
  [GIFT_SOURCE_N_A]: "Gift (Source N/A)"
});

export const RETIREMENT_SUBTYPES = Object.freeze({
  [RETIREMENT_FUNDS]: "Retirement Funds"
});

export const RESERVE_SUBTYPES = Object.freeze({
  ...ASSET_SUBTYPES,
  ...GIFT_SUBTYPES,
  ...RETIREMENT_SUBTYPES
});
