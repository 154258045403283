import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";

import "./css/date_field.scss";
import TrainingTip from "../ui/training_tip";

/**
 * DateField is suitable for rendering a date-input component.
 */
const DateField = React.forwardRef(
  (
    {
      value,
      fieldName,
      onChange,
      className,
      naked,
      id,
      title,
      tabIndex,
      readOnly,
      name,
      onBlurChange,
      onBlur,
      showRequired
    },
    ref
  ) => {
    const [isBlurChanged, setBlurChanged] = useState(false);

    /**
     * Update the state of this text field.
     */
    function change(newDate) {
      const newValue = newDate ? moment(newDate).format("YYYY-MM-DD") : "";
      if (newValue !== value) {
        setBlurChanged(true);
        if (onChange && fieldName) {
          onChange({
            [fieldName]: newValue
          });
        }
      }
    }

    function class_name() {
      let classname = "input-field date-field";

      if (className) {
        classname += ` ${className}`;
      }

      return classname;
    }

    function onBlurred() {
      if (isBlurChanged && onBlurChange && fieldName) {
        onBlurChange({ [fieldName]: value });
      }
      if (onBlur) {
        onBlur();
      }
      setBlurChanged(false);
    }

    if (naked) {
      return <span className={className}>{format(value)}</span>;
    }

    return (
      <div className="input-field-container date-field-container">
        <label htmlFor={id}>
          {name}
          {showRequired && <span className="required-asterisk">*</span>}
          {title && (
            <>
              {" "}
              <TrainingTip content={title} />
            </>
          )}
        </label>
        <div className={class_name()}>
          <DatePicker
            ref={ref}
            id={id}
            className="date-field-input"
            selected={value ? moment(value).toDate() : ""}
            dateFormat="MM/dd/yyyy"
            onChange={change}
            tabIndex={tabIndex}
            readOnly={readOnly}
            onBlur={onBlurred}
            popperPlacement="bottom-start"
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false,
                boundariesElement: "viewport"
              }
            }}
          />
        </div>
      </div>
    );
  }
);

export default DateField;

function format(value) {
  if (!value) {
    return value;
  }

  const values = value.split("-");
  return values[1] + "/" + values[2] + "/" + values[0];
}

export function today() {
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1;
  let dd = today.getDate();
  mm = mm < 10 ? "0" + mm : mm;
  dd = dd < 10 ? "0" + dd : dd;

  return yyyy + "-" + mm + "-" + dd;
}
