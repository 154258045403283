import React from "react";
import "./css/adjustable_interest_rate_table.css";
import {
  ADJUSTMENT_CAP_PERCENTS,
  ADJUSTMENT_LIFE_CAP,
  ARM_INDEX_TYPES,
  FIRST_ADJUSTMENT_CAP_PERCENTS
} from "../../../constants/loan_arm_properties";
import PercentField from "../../fields/percent_field";
import {
  arm_beginning_month,
  arm_max_interest_rate
} from "../../../calculations/loan";
import NumberField from "../../fields/number_field";

export default function AdjustableInterestRateTable({ loan }) {
  return (
    <table className="adjustable-interest-rate-table">
      <colgroup>
        <col />
        <col />
        <col />
      </colgroup>
      <tbody>
        <tr>
          <td className="big-font" colSpan="3">
            Adjustable Interest Rate (AIR) Table
          </td>
        </tr>
        <tr>
          <td>Index + Margin</td>
          <td className="center-align">
            Index Type: {ARM_INDEX_TYPES[loan.arm_index_type]}
          </td>
          <td className="right-align">
            <PercentField naked fixedDigits={2} value={loan.arm_margin} />
          </td>
        </tr>
        <tr>
          <td colSpan="2">Initial Interest Rate</td>
          <td className="right-align">
            <PercentField naked decimalPlaces={3} value={loan.rate} />
          </td>
        </tr>
        <tr>
          <td>Minimum/Maximum Interest Rate</td>
          <td className="center-align">
            <PercentField naked decimalPlaces={3} value={loan.rate} />
          </td>
          <td className="right-align">
            <PercentField
              naked
              fixedDigits={3}
              value={arm_max_interest_rate(loan)}
            />
          </td>
        </tr>
        <tr>
          <td className="bold" colSpan="3">
            Change Frequency
          </td>
        </tr>
        <tr>
          <td>First Change</td>
          <td className="right-align" colSpan="2">
            Beginning of month{" "}
            <NumberField naked value={arm_beginning_month(loan)} />
          </td>
        </tr>
        <tr>
          <td>Subsequent Changes</td>
          <td className="right-align" colSpan="2">
            Every 12th month after first change
          </td>
        </tr>
        <tr>
          <td className="bold" colSpan="3">
            Limits on Interest Rate Changes
          </td>
        </tr>
        <tr>
          <td>First Change</td>
          <td className="right-align" colSpan="2">
            <PercentField
              naked
              fixedDigits={0}
              value={FIRST_ADJUSTMENT_CAP_PERCENTS[loan.arm_caps]}
            />
          </td>
        </tr>
        <tr>
          <td>Subsequent Changes</td>
          <td className="right-align" colSpan="2">
            <PercentField
              naked
              fixedDigits={0}
              value={ADJUSTMENT_CAP_PERCENTS[loan.arm_caps]}
            />
          </td>
        </tr>
        <tr>
          <td>Life Maximum Interest Rate Cap</td>
          <td className="right-align" colSpan="2">
            <PercentField
              naked
              fixedDigits={0}
              value={ADJUSTMENT_LIFE_CAP[loan.arm_caps]}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}
