import {
  AUTOMOBILE,
  BRIDGE_LOAN,
  CASH_ON_HAND,
  CHECKING_SAVINGS,
  DEPOSIT_ON_SALES_CONTRACT,
  EQUITY_FROM_PENDING_SALE,
  EQUITY_FROM_SUBJECT_PROPERTY,
  EQUITY_ON_SOLD_PROPERTY,
  GIFT_EMPLOYER,
  GIFT_GOVERNMENT_ASSISTANCE,
  GIFT_NON_SELLER_FUNDED_NON_PROFIT,
  GIFT_RELATIVE,
  GIFT_SELLER_FUNDED_NON_PROFIT,
  GIFT_SOURCE_N_A,
  LIFE_INSURANCE_CASH_VALUE,
  LOT_EQUITY,
  OTHER_TYPE_OF_DOWN_PAYMENT,
  RENT_WITH_OPTION_TO_PURCHASE,
  RETIREMENT_FUNDS,
  SALE_OF_CHATTEL,
  SECURED_BORROWED_FUNDS,
  STOCKS_BONDS,
  SWEAT_EQUITY,
  TRADE_EQUITY,
  TRUST_FUNDS,
  UNSECURED_BORROWED_FUNDS
} from "./reserves_account_types";

export const PERCENT_CREDITED_FHA = {
  [BRIDGE_LOAN]: "100",
  [CASH_ON_HAND]: "100",
  [CHECKING_SAVINGS]: "100",
  [DEPOSIT_ON_SALES_CONTRACT]: "100",
  [EQUITY_FROM_PENDING_SALE]: "100",
  [AUTOMOBILE]: "100",
  [EQUITY_FROM_SUBJECT_PROPERTY]: "100",
  [EQUITY_ON_SOLD_PROPERTY]: "100",
  [GIFT_EMPLOYER]: "100",
  [GIFT_GOVERNMENT_ASSISTANCE]: "100",
  [GIFT_NON_SELLER_FUNDED_NON_PROFIT]: "100",
  [GIFT_RELATIVE]: "100",
  [GIFT_SELLER_FUNDED_NON_PROFIT]: "100",
  [GIFT_SOURCE_N_A]: "100",
  [LIFE_INSURANCE_CASH_VALUE]: "100",
  [LOT_EQUITY]: "70",
  [OTHER_TYPE_OF_DOWN_PAYMENT]: "100",
  [RENT_WITH_OPTION_TO_PURCHASE]: "100",
  [RETIREMENT_FUNDS]: "70",
  [SALE_OF_CHATTEL]: "100",
  [SECURED_BORROWED_FUNDS]: "100",
  [STOCKS_BONDS]: "100",
  [SWEAT_EQUITY]: "100",
  [TRADE_EQUITY]: "100",
  [TRUST_FUNDS]: "70",
  [UNSECURED_BORROWED_FUNDS]: "100"
};

export const PERCENT_CREDITED_AGENCIES = {
  [BRIDGE_LOAN]: "100",
  [CASH_ON_HAND]: "100",
  [CHECKING_SAVINGS]: "100",
  [DEPOSIT_ON_SALES_CONTRACT]: "100",
  [EQUITY_FROM_PENDING_SALE]: "100",
  [AUTOMOBILE]: "100",
  [EQUITY_FROM_SUBJECT_PROPERTY]: "100",
  [EQUITY_ON_SOLD_PROPERTY]: "100",
  [GIFT_EMPLOYER]: "100",
  [GIFT_GOVERNMENT_ASSISTANCE]: "100",
  [GIFT_NON_SELLER_FUNDED_NON_PROFIT]: "100",
  [GIFT_RELATIVE]: "100",
  [GIFT_SELLER_FUNDED_NON_PROFIT]: "100",
  [GIFT_SOURCE_N_A]: "100",
  [LIFE_INSURANCE_CASH_VALUE]: "100",
  [LOT_EQUITY]: "70",
  [OTHER_TYPE_OF_DOWN_PAYMENT]: "100",
  [RENT_WITH_OPTION_TO_PURCHASE]: "100",
  [RETIREMENT_FUNDS]: "60",
  [SALE_OF_CHATTEL]: "100",
  [SECURED_BORROWED_FUNDS]: "100",
  [STOCKS_BONDS]: "70",
  [SWEAT_EQUITY]: "100",
  [TRADE_EQUITY]: "100",
  [TRUST_FUNDS]: "70",
  [UNSECURED_BORROWED_FUNDS]: "100"
};
