export const NO = "1";
export const YES = "2";
export const N_A = "3";
export const TBD = "TBD";
export const TBD_CREDIT = "TBD_CREDIT";
export const TBD_DEBT = "TBD_DEBT";
export const TBD_OTHER = "TBD_OTHER";

export const YES_NO = Object.freeze({
  [YES]: "Yes",
  [NO]: "No"
});

export const YES_NO_TBD = Object.freeze({
  [YES]: "Yes",
  [NO]: "No",
  [TBD]: "TBD"
});

export const YES_NO_NA = Object.freeze({
  [YES]: "Yes",
  [NO]: "No",
  [N_A]: "N/A"
});

export const YES_NO_TBD_CREDIT_DEBT_OTHER = Object.freeze({
  [YES]: "Yes",
  [NO]: "No",
  [TBD_CREDIT]: "TBD - Credit",
  [TBD_DEBT]: "TBD - Debt",
  [TBD_OTHER]: "TBD - Other"
});
