import React from "react";

import NumberField from "../../fields/number_field";
import CurrencyField from "../../fields/currency_field";

import { total_gross_rental_income_from_individual_tax_return } from "../../../calculations/investment_reo";

export default function TaxReturnColumn({
  taxReturnIndex,
  taxReturn,
  onChange
}) {
  const totalGrossRentalIncome = total_gross_rental_income_from_individual_tax_return(
    taxReturn
  ).toString();
  const {
    year,
    income,
    depreciation,
    insurance,
    mortgage_interest,
    taxes,
    hoa,
    repairs,
    other
  } = taxReturn;

  function change(updatedState) {
    onChange(updatedState, taxReturnIndex);
  }

  return (
    <div className="tax-return-column">
      <NumberField fieldName="year" onChange={change} value={year} />
      <CurrencyField fieldName="income" onChange={change} value={income} />
      <CurrencyField
        fieldName="depreciation"
        onChange={change}
        value={depreciation}
      />
      <CurrencyField
        fieldName="insurance"
        onChange={change}
        value={insurance}
      />
      <CurrencyField
        fieldName="mortgage_interest"
        onChange={change}
        value={mortgage_interest}
      />
      <CurrencyField fieldName="taxes" onChange={change} value={taxes} />
      <CurrencyField fieldName="hoa" onChange={change} value={hoa} />
      <CurrencyField fieldName="repairs" onChange={change} value={repairs} />
      <CurrencyField fieldName="other" onChange={change} value={other} />
      <CurrencyField readOnly value={totalGrossRentalIncome} />
    </div>
  );
}
