import { deep_clone_simple, formatPhone } from "../../constants/utils";
import { default_borrower } from "../../schema/borrower";
import { default_hourly_source, default_monthly_source } from "../../schema/income_source";
import { SELF_EMPLOYED, W2 } from "../../constants/wage_forms";
import { default_reserve } from "../../schema/reserve";
import {
  ASSET_SUBTYPES,
  AUTOMOBILE,
  GIFT_SUBTYPES,
  LIFE_INSURANCE_CASH_VALUE,
  RETIREMENT_SUBTYPES,
  TYPE_ASSET,
  TYPE_GIFT,
  TYPE_RETIREMENT
} from "../../constants/reserves_account_types";
import { PERCENT_CREDITED_AGENCIES } from "../../constants/reserves_percentage_credited";
import { month_to_year_income } from "../../calculations/income";
import { default_additional_income } from "../../schema/additional_income";
import { HOURLY } from "../../constants/income_calculator_types";
import { default_debt_info_account } from "../../schema/debt_info";
import generate_id from "../../constants/id-generator";
import { ACCOUNT_ID_PREFIX } from "../../constants/debt-info";
import { createSelectedLoanIfNotExist } from "./loan";
import { INCLUDE_IN_DTI, OMITTED, RESUBORDINATED, WILL_BE_PAID_OFF } from "../../constants/account_dispositions";
import {
  FNM,
  LANIS_ACCOUNT_SUB_TYPE,
  LANIS_ADDITIONAL_INCOME_TYPES,
  LANIS_CURRENTLY_RENTING_OR_OWNING,
  LANIS_LIABILITY_TYPE,
  LANIS_MARITAL_STATUS
} from "../fnm_constants";
import moment from "moment-timezone";
import { parseFnmDecimal } from "../util";
import * as RESERVES_ACCOUNT_TYPES from "../../constants/reserves_account_types";
import * as LIABILITY_TYPE from "../../constants/liability_types";

export function createBorrowerFromSsnIfNotExist({
                                                  data,
                                                  occurrence,
                                                  document,
                                                  getBorrowerIfNotExist = createBorrowerIfNotExist
}) {
  const { applicantSocialSecurityNumber } = data;
  let borrower;
  if (applicantSocialSecurityNumber) {
    borrower = document.applicant.borrowers
        .find(({ ssn: borrowerSsn }) => borrowerSsn === applicantSocialSecurityNumber)
  }
  if (!borrower) {
    borrower = getBorrowerIfNotExist({ occurrence, document });
    if (applicantSocialSecurityNumber) {
      borrower.ssn = applicantSocialSecurityNumber;
    }
  }
  return borrower;
}

export function createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower({ data, occurrence, document }) {
  return createBorrowerFromSsnIfNotExist({
    data,
    occurrence,
    document,
    getBorrowerIfNotExist: guessBorrowerIfNotExist
  });
}

/**
 * @param Number occurrence
 * @param document
 * @return {*}
 */
export function createBorrowerIfNotExist({ occurrence, document }) {
  if (document.applicant.borrowers.length > occurrence) {
    return document.applicant.borrowers[occurrence];
  }
  const borrower = deep_clone_simple(default_borrower);
  document.applicant.borrowers.push(borrower);
  return borrower;
}

/**
 * @param Number occurrence
 * @param document
 * @return {*}
 */
export function guessBorrowerIfNotExist({ occurrence, document }) {
  if (document.applicant.borrowers.length > occurrence) {
    return document.applicant.borrowers[occurrence];
  }
  if (document.applicant.borrowers.length) {
    return document.applicant.borrowers[0];
  }
  return createBorrowerIfNotExist({ occurrence: 0, document });
}

export function createPrimaryIncomeSourceIfNotExist({ data, occurrence, document }) {
  const borrower = createBorrowerFromSsnIfNotExist({ data, occurrence, document });
  if (!borrower.income.sources.length) {
    const incomeSource = deep_clone_simple(default_monthly_source);
    // TODO is this a safe default?
    incomeSource.w2_or_self_employed = W2;
    borrower.income.sources.push(incomeSource);
  }
  return {
    borrower,
    incomeSource: borrower.income.sources[0]
  };
}

export function createSecondaryIncomeSource({ data, document }) {
  const borrower = createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower({ data, document });
  const incomeSource = deep_clone_simple(default_monthly_source);
  // TODO is this a safe default?
  incomeSource.w2_or_self_employed = W2;
  borrower.income.sources.push(incomeSource);
  return incomeSource;
}

export function importTitleholderName({ data, item: borrower }) {
  borrower.first_name = data.titleholderName;
}

export function importApplicantData({ data, item: borrower, document }) {
  const {
    applicantFirstName,
    applicantMiddleName,
    applicantLastName,
    applicantGeneration,
    homePhone,
    maritalStatus,
    emailAddress,
    crossReferenceNumber
  } = data;
  borrower.first_name = applicantFirstName;
  borrower.middle_name = applicantMiddleName;
  borrower.last_name = applicantLastName;
  borrower.suffix = applicantGeneration;
  borrower.home_phone = homePhone;
  if (maritalStatus) {
    const lanisMaritalStatus = LANIS_MARITAL_STATUS.import[maritalStatus];
    if (lanisMaritalStatus) {
      borrower.marital_status = lanisMaritalStatus;
    } else {
      console.warn("Detected unsupported marital status: ", maritalStatus)
    }
  }
  borrower.email = emailAddress;
  if (crossReferenceNumber) {
    const { borrowers } = document.applicant;
    const borrowerIndex = borrowers.indexOf(borrower);
    const referencedBorrower = borrowers.find((otherBorrower, index) =>
        index !== borrowerIndex && otherBorrower.ssn === crossReferenceNumber);
    if (referencedBorrower) {
      referencedBorrower.co_borrower = borrowerIndex.toString();
      borrower.co_borrower = borrowers.indexOf(referencedBorrower).toString();
    }
  }
}

export function importIncome({ data, document, occurrenceLinesSoFar, occurrence, field }) {
  const {
    typeOfIncomeCode,
    incomeAmountMonthlyIncome,
    applicantSocialSecurityNumber,
  } = data;
  const parsedIncomeAmountMonthlyIncome = parseFnmDecimal(incomeAmountMonthlyIncome);
  let yearlyAmount;
  if (parsedIncomeAmountMonthlyIncome) {
    yearlyAmount = month_to_year_income(incomeAmountMonthlyIncome).toString();
  }
  switch (typeOfIncomeCode) {
    case FNM.INCOME_CODE.BASE_EMPLOYMENT_INCOME:
    case FNM.INCOME_CODE.OVERTIME:
    case FNM.INCOME_CODE.BONUSES:
    case FNM.INCOME_CODE.COMMISSIONS: {
      occurrence = occurrenceLinesSoFar
          .filter((_, index) => index < occurrence)
          .map(field.parse)
          .map(({ typeOfIncomeCode }) => typeOfIncomeCode)
          .filter(incomeCode => incomeCode === typeOfIncomeCode)
          .length;
      let { incomeSource } = createPrimaryIncomeSourceIfNotExist({ data, occurrence, document });
      if (typeOfIncomeCode === FNM.INCOME_CODE.BASE_EMPLOYMENT_INCOME) {
        if (yearlyAmount) {
          incomeSource.base_income_0 = yearlyAmount;
        }
      } else if (typeOfIncomeCode === FNM.INCOME_CODE.OVERTIME) {
        // Switch the income source to an hourly income source
        if (incomeSource.calculator !== HOURLY) {
          const borrower = createBorrowerFromSsnIfNotExist({ data, occurrence, document });
          const hourlyIncomeSource = deep_clone_simple(default_hourly_source);
          // Remove the calculator property, as we don't want to include that
          delete incomeSource.calculator;
          // Copy over all other properties into the hourly income source.
          Object.assign(hourlyIncomeSource, incomeSource);
          borrower.income.sources[0] = hourlyIncomeSource;
          incomeSource = hourlyIncomeSource;
        }
        if (yearlyAmount) {
          incomeSource.overtime_0 = yearlyAmount;
        }
      } else if (typeOfIncomeCode === FNM.INCOME_CODE.BONUSES) {
        if (yearlyAmount) {
          incomeSource.bonuses_0 = yearlyAmount;
        }
      } else {
        if (yearlyAmount) {
          incomeSource.commission_0 = yearlyAmount;
        }
      }
      break;
    }
    case FNM.INCOME_CODE.NET_RENTAL_INCOME: {
      // Ignore, this is the result of various other fields
      break;
    }
    default: {
      const additionalIncomeType = LANIS_ADDITIONAL_INCOME_TYPES.import[typeOfIncomeCode];
      if (additionalIncomeType) {
        const borrower = createBorrowerFromSsnIfNotExistOtherwiseGuessBorrower({
          data: { applicantSocialSecurityNumber },
          document
        });
        const additionalIncome = deep_clone_simple(default_additional_income);
        additionalIncome.income_type = additionalIncomeType;
        if (yearlyAmount) {
          additionalIncome.yearly_amount = yearlyAmount;
        }
        borrower.income.other_income.push(additionalIncome);
      } else {
        console.warn("Detected unsupported type of income code: ", typeOfIncomeCode);
      }
    }
  }
}

export function skipIfNotCurrent({ data }) {
  const {
    currentEmploymentFlag,
  } = data;
  return currentEmploymentFlag !== 'Y';
}

export function importSecondaryEmployer({ data, item: incomeSource, }) {
  const {
    employerName,
    employerStreetAddress,
    employerCity,
    employerState,
    employerZipCode,
    selfEmployed,
    fromDate,
    monthlyIncome,
    positionTitleTypeOfBusiness,
    businessPhone,
  } = data;
  const parsedIncomeAmountMonthlyIncome = parseFnmDecimal(monthlyIncome);
  let yearlyAmount;
  if (parsedIncomeAmountMonthlyIncome) {
    yearlyAmount = month_to_year_income(parsedIncomeAmountMonthlyIncome).toString();
  }
  if (yearlyAmount) {
    incomeSource.base_income_0 = yearlyAmount;
  }
  incomeSource.employment_information.employer_name = employerName;
  incomeSource.employment_information.address = employerStreetAddress;
  incomeSource.employment_information.city = employerCity;
  incomeSource.employment_information.state = employerState;
  incomeSource.employment_information.zip = employerZipCode;
  const isSelfEmployed = selfEmployed === 'Y';
  if (isSelfEmployed) {
    incomeSource.w2_or_self_employed = SELF_EMPLOYED;
  } else {
    // TODO is this a safe assumption?
    incomeSource.w2_or_self_employed = W2;
  }
  if (fromDate) {
    const jobStart = moment(fromDate, "YYYYMMDD");
    incomeSource.years_on_the_current_job = "" + moment().diff(jobStart, "years");
    incomeSource.months_on_the_current_job = "" + (moment().diff(jobStart, "months") % 12);
  }
  incomeSource.employment_information.position = positionTitleTypeOfBusiness;
  if (businessPhone) {
    incomeSource.employment_information.phone = formatPhone(businessPhone);
  }
}

export function importPrimaryCurrentEmployer({ data, item: { borrower, incomeSource } }) {
  const {
    employerName,
    employerStreetAddress,
    employerCity,
    employerState,
    employerZipCode,
    selfEmployed,
    yrsOnThisJob,
    monthsOnThisJob,
    yrsEmployedInThisLineOfWorkProfession,
    positionTitleTypeOfBusiness,
    businessPhone,
  } = data;
  incomeSource.employment_information.employer_name = employerName;
  incomeSource.employment_information.address = employerStreetAddress;
  incomeSource.employment_information.city = employerCity;
  incomeSource.employment_information.state = employerState;
  incomeSource.employment_information.zip = employerZipCode;
  const isSelfEmployed = selfEmployed === 'Y';
  if (isSelfEmployed) {
    incomeSource.w2_or_self_employed = SELF_EMPLOYED;
  } else {
    // TODO is this a safe assumption?
    incomeSource.w2_or_self_employed = W2;
  }
  incomeSource.years_on_the_current_job = yrsOnThisJob;
  incomeSource.months_on_the_current_job = monthsOnThisJob;
  incomeSource.employment_information.line_of_work_years = yrsEmployedInThisLineOfWorkProfession;
  incomeSource.employment_information.position = positionTitleTypeOfBusiness;
  if (businessPhone) {
    const phone = formatPhone(businessPhone);
    borrower.work_phone = phone;
    incomeSource.employment_information.phone = phone;
  }
}

export function importLifeInsurance({ data, item: borrower }) {
  const {
    lifeInsuranceCashOrMarketValue,
  } = data;
  const reserve = deep_clone_simple(default_reserve);
  reserve.type = TYPE_ASSET;
  reserve.sub_type = LIFE_INSURANCE_CASH_VALUE;
  reserve.funds = parseFnmDecimal(lifeInsuranceCashOrMarketValue, "0").toString();
  borrower.reserves.push(reserve);
}

export function importAutomobiles({ data, item: borrower }) {
  const {
    cashOrMarketValue,
    automobileMakeModel
  } = data;
  const reserve = deep_clone_simple(default_reserve);
  reserve.type = TYPE_ASSET;
  reserve.sub_type = AUTOMOBILE;
  reserve.funds = parseFnmDecimal(cashOrMarketValue, "0").toString();
  reserve.bank_name = automobileMakeModel;
  borrower.reserves.push(reserve);
}

export function importOtherExpenses({ data, item: borrower, document, documentInfo }) {
  const {
    expenseTypeCode,
    monthlyPaymentAmount,
    monthsLeftToPay,
    alimonyChildSupportSeparateMaintenanceOwedTo
  } = data;

  if (expenseTypeCode) {
    const liabilityType = LANIS_LIABILITY_TYPE.TO_EXPENSE_TYPE.import[expenseTypeCode];
    if (liabilityType) {
      documentInfo.accountId = documentInfo.accountId || 0;
      const account = deep_clone_simple(default_debt_info_account);
      account.id = generate_id(ACCOUNT_ID_PREFIX, true, documentInfo.accountId++);
      account.liability_type = liabilityType;
      account.borrower = "" + document.applicant.borrowers.indexOf(borrower);
      const monthlyPayment = parseFnmDecimal(monthlyPaymentAmount);
      if (monthlyPayment) {
        account.monthly_payment = monthlyPayment.toString();
        if (monthsLeftToPay) {
          account.current_balance = monthlyPayment.times(monthsLeftToPay).toString();
        }
      }
      account.description = alimonyChildSupportSeparateMaintenanceOwedTo;
      document.accountSummaries[account.id] = account;
      const loan = createSelectedLoanIfNotExist({ document });
      loan.account_dispositions[account.id] = INCLUDE_IN_DTI;
    } else {
      console.warn("Detected unsupported expense type: ", expenseTypeCode);
    }
  } else {
    console.warn("Expense type not provided");
  }
}

export function importLiabilities({ data, item: borrower, document, documentInfo }) {
  const {
    liabilityType,
    creditorName,
    acctNo,
    monthlyPaymentAmount,
    unpaidBalance,
    monthsLeftToPay,
    liabilityWillBePaidPriorToClosing,
    resubordinatedIndicator,
    omittedIndicator,
    reoAssetId,
  } = data;

  if (liabilityType) {
    const lanisLiabilityType = LANIS_LIABILITY_TYPE.TO_LIABILITY_TYPE.import[liabilityType];
    if (lanisLiabilityType) {
      documentInfo.accountId = documentInfo.accountId || 0;
      const account = deep_clone_simple(default_debt_info_account);
      account.id = generate_id(ACCOUNT_ID_PREFIX, true, documentInfo.accountId++);
      account.liability_type = lanisLiabilityType;
      account.borrower = "" + document.applicant.borrowers.indexOf(borrower);
      account.description = creditorName;
      account.account_number = acctNo;
      let monthlyPayment;
      if (monthlyPaymentAmount) {
        monthlyPayment = parseFnmDecimal(monthlyPaymentAmount);
        if (!monthlyPayment || monthlyPayment.isZero()) {
          // To match the default formatting.
          account.monthly_payment = "0.00";
        } else {
          account.monthly_payment = monthlyPayment.toString();
        }
      }
      const parsedUnpaidBalance = parseFnmDecimal(unpaidBalance);
      if (parsedUnpaidBalance) {
        account.current_balance = parsedUnpaidBalance.toString();
      } else if (monthsLeftToPay) {
        if (monthlyPayment) {
          account.current_balance = monthlyPayment.times(monthsLeftToPay).toString();
        } else {
          console.warn("Months left to pay defined without a monthly payment.");
        }
      }
      document.accountSummaries[account.id] = account;
      const loan = createSelectedLoanIfNotExist({ document });
      if (liabilityWillBePaidPriorToClosing === 'Y') {
        loan.account_dispositions[account.id] = WILL_BE_PAID_OFF;
      } else if (resubordinatedIndicator === 'Y') {
        loan.account_dispositions[account.id] = RESUBORDINATED;
      } else if (omittedIndicator === 'Y') {
        loan.account_dispositions[account.id] = OMITTED;
      } else {
        loan.account_dispositions[account.id] = INCLUDE_IN_DTI;
      }
      const reoAsset = documentInfo.reoAssetData[reoAssetId];
      if (reoAsset && parsedUnpaidBalance) {
        const isHomeEquity = lanisLiabilityType === LIABILITY_TYPE.HOME_EQUITY;
        const isMortgage = lanisLiabilityType === LIABILITY_TYPE.MORTGAGE;
        if (isHomeEquity || isMortgage) {
          if (!reoAsset.clearedLoanBalance) {
            if (reoAsset.isSubjectProperty) {
              document.property_to_be_sold.first_loan_balance = "0";
            } else {
              reoAsset.property.principal_balance = "0";
            }
            reoAsset.clearedLoanBalance = true;
          }
          if (reoAsset.isSubjectProperty) {
            if (!documentInfo.seenFirstLoanBalance) {
              documentInfo.seenFirstLoanBalance = true;
              document.property_to_be_sold.first_loan_balance = parsedUnpaidBalance.toString();
            } else {
              document.property_to_be_sold.second_loan_balance = parsedUnpaidBalance.toString();
            }
          } else {
            reoAsset.property.principal_balance = parseFnmDecimal(reoAsset.property.principal_balance)
                .plus(parsedUnpaidBalance)
                .toString();
          }
          loan.account_dispositions[account.id] = OMITTED;
        }
      }
    } else {
      console.warn("Detected unsupported liability type: ", liabilityType);
    }
  } else {
    console.warn("Liability type not provided.");
  }
}

export function importAssets({ data, item: borrower }) {
  const {
    accountAssetType,
    depositoryStockBondInstitutionName,
    assetDescription,
    acctNo,
    cashOrMarketValue
  } = data;
  const reserve = deep_clone_simple(default_reserve);
  if (accountAssetType) {
    const accountSubType = LANIS_ACCOUNT_SUB_TYPE.import[accountAssetType];
    if (accountSubType) {
      let isValidType;
      if (ASSET_SUBTYPES[accountSubType]) {
        reserve.type = TYPE_ASSET;
        isValidType = true
      } else if (GIFT_SUBTYPES[accountSubType]) {
        reserve.type = TYPE_GIFT;
        isValidType = true;
      } else if (RETIREMENT_SUBTYPES[accountSubType]) {
        reserve.type = TYPE_RETIREMENT;
        isValidType = true;
      } else {
        console.warn("Could not determine account type from subtype: ", accountSubType)
        isValidType = false;
      }
      if (isValidType) {
        reserve.sub_type = accountSubType;
      }
    } else {
      console.warn("Detected unsupported accountAssetType code: ", accountAssetType);
    }
  }
  if (!reserve.type || !reserve.sub_type) {
    // At least try to preserve the numerical data if something went wrong,
    // and default to *some* asset type
    reserve.type = TYPE_ASSET;
    reserve.sub_type = RESERVES_ACCOUNT_TYPES.OTHER_TYPE_OF_DOWN_PAYMENT;
  }
  if (depositoryStockBondInstitutionName) {
    reserve.bank_name = depositoryStockBondInstitutionName;
  } else if (assetDescription) {
    reserve.bank_name = assetDescription;
  }
  if (acctNo) {
    reserve.account_number = acctNo;
  }
  const parsedCashOrMarketValue = parseFnmDecimal(cashOrMarketValue);
  if (parsedCashOrMarketValue) {
    // This isn't technically an FNM-originating decimal but that's fine
    let creditPercent = parseFnmDecimal(PERCENT_CREDITED_AGENCIES[reserve.sub_type]);
    if (creditPercent) {
      creditPercent = creditPercent.dividedBy(100);
      reserve.funds = parsedCashOrMarketValue
          .dividedBy(creditPercent)
          .toDP(2)
          .toString();
    } else {
      console.warn("Detected unmapped reserve sub-type for credit percentages: ", reserve.sub_type);
    }
  }
  borrower.reserves.push(reserve);
}

export function importApplicantAddress({ data, item: borrower }) {
  const {
    presentFormer,
    residenceStreetAddress,
    residenceCity,
    residenceState,
    residenceZipCode,
    ownRentLivingRentFree,
    noYrs,
  } = data;
  const isPresentAddress = !presentFormer
      || presentFormer === FNM.PRESENT_FORMER_CODE.PRESENT_ADDRESS;
  if (!isPresentAddress) {
    // This is not the present address.
    return;
  }
  borrower.current_address = residenceStreetAddress;
  borrower.city = residenceCity;
  borrower.state = residenceState;
  borrower.zip_code = residenceZipCode;
  if (ownRentLivingRentFree) {
    const currentlyRentingOrOwning =
        LANIS_CURRENTLY_RENTING_OR_OWNING.import[ownRentLivingRentFree];
    if (currentlyRentingOrOwning) {
      borrower.currently_renting_or_owning = currentlyRentingOrOwning;
    } else {
      console.warn("Detected unmapped own/rent/living rent free code: ", ownRentLivingRentFree);
    }
  }
  if (noYrs) {
    borrower.how_long_in_current_residence = noYrs;
  }
}
