import React from "react";
import { total_other_costs } from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../loan_tool";

export default function TotalOtherCosts({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  return (
    <div className="total-other-costs">
      <div className="closing-costs-row top-border gray-bg">
        <div className="section-title">
          I. Total Other Costs (E + F + G + H)
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          value={total_other_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
    </div>
  );
}
