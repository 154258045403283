import React, { useState, useMemo, useRef, useEffect } from "react";
import "./css/ssn_field.scss";
import TrainingTip from "../ui/training_tip";
import { formatSsn } from "../../constants/utils";

export default function SsnField({
  value,
  readOnly,
  onChange,
  fieldName,
  className,
  id,
  name,
  title,
  naked,
  tabIndex,
  placeholder,
  showRequired
}) {
  const [isFocused, setIsFocused] = useState(false);
  const [innerValue, setInnerValue] = useState(value);
  const displayValue = useMemo(() => formatSsn(value), [value]);
  const inputRef = useRef();

  useEffect(() => {
    if (isFocused && inputRef.current) {
      inputRef.current.select();
    }
  }, [isFocused]);

  function change(event) {
    let targetValue = event.target.value.replace(/[^\d]/g, "");
    if (targetValue.length > 10) {
      targetValue = targetValue.slice(0, 10);
    }
    setInnerValue(targetValue);

    if (onChange && fieldName) {
      onChange({
        [fieldName]: targetValue
      });
    }
  }

  function onFocus() {
    if (!readOnly) {
      setIsFocused(true);
      setInnerValue(innerValue ? innerValue.replace(/[^\d]/g, "") : "");
    }
  }

  function onBlur() {
    if (!readOnly) {
      setIsFocused(false);
      setInnerValue(formatSsn(value));
    }
  }

  function classNames() {
    let classname = "input-field ssn-field";
    if (className) {
      classname += ` ${className}`;
    }
    return classname;
  }

  if (naked) {
    return <span className={className}>{displayValue}</span>;
  }

  return (
    <div className="input-field-container ssn-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>
      <input
        ref={inputRef}
        id={id}
        type="text"
        value={isFocused ? innerValue : displayValue}
        onChange={change}
        onFocus={onFocus}
        onBlur={onBlur}
        step="any"
        tabIndex={tabIndex}
        className={classNames()}
        readOnly={readOnly}
        placeholder={placeholder}
      />
    </div>
  );
}
