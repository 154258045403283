import React from "react";
import "./css/misc.scss";
import { useDocument } from "../../components/loan_tools/loan_tool";
import { Link } from "@reach/router";

export default function NoLoans() {
  const [document, , { documentId }] = useDocument();

  const isShow = !document || !document.loans || document.loans.length === 0;

  return (
    <>
      {isShow && (
        <div className="no-loans">
          Loans have not been set yet. Please go to the{" "}
          <Link className="button" to={`/loan-tool/${documentId}/loan-type`}>
            Loan Type
          </Link>{" "}
          page to set them up.
        </div>
      )}
    </>
  );
}
