import React from "react";

import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import {
  annual_property_tax,
  loan_term,
  property_tax,
  qualifying_arm_interest_rate
} from "../../../calculations/loan";
import {
  hoa_dues_1,
  hoa_dues_2,
  home_owners_insurance_monthly,
  mello_roos
} from "../../../calculations/property";

import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import TextField from "../../fields/text_field";
import PercentField from "../../fields/percent_field";

import { useDocument } from "../../loan_tools/loan_tool";
import Section from "../../ui/section";
import { is_va, loan_product } from "../../../constants/utils";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import { HOA_PROPERTY_TYPES } from "../../../constants/property_type";
import { ARM_TERMS } from "../../../constants/loan_terms";

export default function InformationRecap({ loan, updateLoan }) {
  const [document, , { documentType }] = useDocument();
  const { property } = document;
  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;
  const isArm = ARM_TERMS.includes(loan.term);

  return (
    <Section
      className="down-payment-information-recap"
      name="Information Recap"
    >
      <div className="flex">
        <div className="third-width">
          <CurrencyField
            readOnly
            name={isRefinance ? "Market Value" : "List Price"}
            value={property.listed_price}
          />
          {isPurchase && (
            <CurrencyField
              name="Offer Price"
              fieldName="offer_price"
              value={loan.offer_price}
              onChange={updateLoan}
            />
          )}
          <TextField
            className="right-align"
            name="Loan Type"
            readOnly
            value={LOAN_TYPE_CATEGORY_NAMES[loan.type]}
          />
          {is_va(loan.type) && (
            <a
              className="button inverse-button"
              href="https://www.fhfa.gov/DataTools/Downloads/Documents/Conforming-Loan-Limits/FullCountyLoanLimitList2021_HERA-BASED_FINAL_FLAT.PDF"
              target="_blank"
              rel="noopener noreferrer"
            >
              Check Max Loan Limit
            </a>
          )}
          <NumberField
            name="Loan Term (Months)"
            readOnly
            value={loan_term(loan)}
          />
        </div>

        <div className="third-width">
          {isArm && qualifying_arm_interest_rate(loan).gt(0) && (
            <PercentField
              name="Qualifying ARM Interest Rate"
              readOnly
              value={qualifying_arm_interest_rate(loan)}
            />
          )}

          <TextField
            className="right-align"
            name="Product"
            readOnly
            value={loan_product(loan.term)}
          />

          <PercentField
            name="Interest Rate (1st Mortgage)"
            readOnly
            decimalPlaces={3}
            value={loan.rate}
          />

          {isPurchase && (
            <>
              <PercentField
                name="County Tax Base"
                readOnly
                value={property.property_tax_percent}
              />
              <CurrencyField
                readOnly
                name="Property Tax (Annual)"
                value={annual_property_tax(documentType, loan, property)}
              />
            </>
          )}
          {isRefinance && (
            <CurrencyField
              readOnly
              name="Property Tax (Monthly)"
              value={property_tax(documentType, loan, property)}
            />
          )}

          <CurrencyField
            readOnly
            name="Home Owners Insurance (Monthly)"
            value={home_owners_insurance_monthly(documentType, property)}
          />
        </div>

        <div className="third-width">
          {(HOA_PROPERTY_TYPES.includes(property.property_type) ||
            HOA_PROPERTY_TYPES.includes(loan.property_type)) && (
            <>
              <CurrencyField
                readOnly
                name="HOA Dues 1"
                value={hoa_dues_1(property, loan)}
              />

              <CurrencyField
                readOnly
                name="HOA Dues 2"
                value={hoa_dues_2(property, loan)}
              />

              <CurrencyField
                readOnly
                name="Mello Roos"
                value={mello_roos(property, loan)}
              />
            </>
          )}
        </div>
      </div>
    </Section>
  );
}
