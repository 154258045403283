export const MONTHLY = "1";
export const QUARTERLY = "2";
export const ANNUALLY = "3";
/**
 * Enum for expressing all the frequencies that can be in the system.
 */
export default Object.freeze({
  [MONTHLY]: "Monthly",
  [QUARTERLY]: "Quarterly",
  [ANNUALLY]: "Annually"
});
