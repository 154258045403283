import React, { useState } from "react";

import StandardTemplate from "../templates/standard_template/standard_template";

import "./css/login.scss";
import { useUser } from "../hooks/user";
import TextField from "../components/fields/text_field";
import { Link } from "@reach/router";

export default function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [, { login }] = useUser();

  /**
   * Controller for managing the logging in of a user.
   * @param {*} event The event fired when the login form is submitted
   */
  async function onSubmit() {
    login(email, password, setErrorMessage);
  }

  async function onEnterSubmit(event) {
    if (event.key === "Enter") {
      await onSubmit();
    }
  }

  return (
    <StandardTemplate>
      <div className="login-page">
        <div className="login-title">Login</div>
        <div className="login-section flex-direction-column align-items-center">
          <div className="error-message">{errorMessage}</div>
          <div className="login-action">
            <TextField
              fieldName="email"
              typeOverride="email"
              name="Email"
              value={email}
              onChange={value => setEmail(value.email)}
              onKeyPress={onEnterSubmit}
            />
            <TextField
              fieldName="password"
              name="Password"
              value={password}
              typeOverride="password"
              onChange={value => setPassword(value.password)}
              onKeyPress={onEnterSubmit}
            />
            <div className="flex-start-with-front-spacing align-items-center">
              <Link to={"/password-reset-request"}>Forgot Password?</Link>
              <button className="button" onClick={onSubmit}>
                Log In
              </button>
            </div>
          </div>
        </div>
        <div className="sign-up-section flex-space-evenly">
          <div className="sign-up-content">
            {`Don't have an account? Sign up and subscribe here `}
            <Link to="/sign-up" className="button">
              Sign Up
            </Link>
          </div>
        </div>
        <div className="login-disclaimer">
          NOTE: Complete program guidelines are NOT Built into the software.
          Please refer to full guidelines of your Company or Lender for specific
          restrictions and /or requirements. The information is subject to
          change at any time without notice. The LANIS Group, LLC is not liable
          and will be held harmless for results provided. The results provided
          are only estimates based on the information entered into the system.
          The Subscriber must confirm results with specific Company or Lender
          Guidelines and program details. This information is provided to
          mortgage and real estate professionals only and is not intended or
          authorized for consumer distribution. Call for additional details.
          This is not a credit decision, an offer of credit or rate lock, or a
          commitment to lend. Rate, fees, and other terms will depend on various
          factors including loan product, credit profile, property value,
          occupancy, loan size, etc. Rates and program availability may vary
          based on the state or region in which the financed property is
          located. Program restrictions apply. Rev. 6/28/2019
        </div>
      </div>
    </StandardTemplate>
  );
}
