import {
  FNM,
  LANIS_PROPERTY_CLASSIFICATION,
  LANIS_PROPERTY_TYPE,
  LANIS_PURPOSE_OF_REFINANCE,
  LANIS_REO_PROPERTY_TYPE
} from "../fnm_constants";
import TITLE_VESTING from "../../constants/title_vesting";
import * as DOCUMENT_TYPES from "../../constants/document_types";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import Decimal from "decimal.js";
import { HOA_PROPERTY_TYPES } from "../../constants/property_type";
import { investment_properties_monthly_amount } from "../../calculations/income";
import { total_of_payments } from "../../calculations/property";
import { total_monthly_payment } from "../../calculations/loan";
import { summary_selected_loan } from "../../constants/utils";
import {
  INVESTMENT_PROPERTY as INVESTMENT_PROPERTY_CLASSIFICATION,
  PRIMARY_RESIDENCE
} from "../../constants/property_classification";
import {
  net_income_or_loss, total_misc_monthly_fees,
  wrap_investment_properties,
  wrap_subject_property
} from "../../calculations/investment_reo";
import { YES } from "../../constants/yes_no";
import { filterParseFnmDecimal, formatFnmDecimal, parseFnmDecimal } from "../util";
import { toMonthly } from "../../calculations/utils";
import { OWN, RENT } from "../../constants/renting_or_owning";

export function exportPropertyInformation({ document }) {
  const { property } = document;
  return {
    propertyStreetAddress: property.address,
    propertyCity: property.city,
    propertyState: property.state,
    propertyZipCode: property.zip,
    legalDescriptionOfSubjectPropertyCode: FNM.LEGAL_DESCRIPTION_OF_SUBJECT_PROPERTY.OTHER
  }
}

export function exportPropertyClassification({ document }) {
  const { property } = document;
  return {
    propertyWillBe: LANIS_PROPERTY_CLASSIFICATION.export[property.classification]
  }
}

export function exportPropertyTitleVesting({ document }) {
  const { property } = document;
  return {
    mannerInWhichTitleWillBeHeld: TITLE_VESTING[property.title_vesting],
    estateWillBeHeldIn: FNM.ESTATE_WILL_BE_HELD_IN.FEE_SIMPLE
  }
}

export function exportPurposeOfRefinance({ document, documentType }) {
  if (documentType !== DOCUMENT_TYPES.REFINANCE) {
    return [];
  }
  const { property } = document;
  return {
    purposeOfRefinance: LANIS_PURPOSE_OF_REFINANCE.export[property.purpose_of_refinance],
    describeImprovementsMadTobeMade: "U"
  }
}

export function exportHousingExpenses({ document, documentType }) {
  let applicantSocialSecurityNumber,
      presentProposedIndicator = FNM.PRESENT_PROPOSED_INDICATOR.PRESENT_HOUSING_EXPENSE;
  const {
    property,
    property_to_be_sold
  } = document;
  if (document.applicant.borrowers.length) {
    applicantSocialSecurityNumber = document.applicant.borrowers[0].ssn;
  }
  // TODO: Return a thing for each expense in importHousingExpenses
  const expenses = [];
  if (documentType === REFINANCE) {
    if (isNonZero(property.refinance_property_tax_amount)) {
      const realEstateTaxes = formatFnmDecimal(toMonthly(property.refinance_property_tax_amount,
          property.refinance_property_tax_frequency));
      if (realEstateTaxes) {
        expenses.push({
          applicantSocialSecurityNumber,
          presentProposedIndicator,
          housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.REAL_ESTATE_TAXES,
          housingPaymentAmountMonthlyHousingExp: realEstateTaxes,
        });
      }
    }
  } else {
    if (isNonZero(property.property_tax_percent) && isNonZero(property.listed_price)) {
      let value = filterParseFnmDecimal(property.property_tax_percent);
      const listedPrice = filterParseFnmDecimal(property.listed_price);
      if (value && listedPrice) {
        value = value.div(100).mul(listedPrice);
      } else {
        value = null;
      }
      value = formatFnmDecimal(value);
      if (value) {
        expenses.push({
          applicantSocialSecurityNumber,
          presentProposedIndicator,
          housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.REAL_ESTATE_TAXES,
          housingPaymentAmountMonthlyHousingExp: value,
        });
      }
    }
  }
  const firstCurrentMonthlyPaymentPrincipal = formatFnmDecimal(
      property_to_be_sold.first_current_monthly_payment_principal);
  if (firstCurrentMonthlyPaymentPrincipal) {
    expenses.push({
      applicantSocialSecurityNumber,
      presentProposedIndicator,
      housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.FIRST_MORTGAGE_P_AND_I,
      housingPaymentAmountMonthlyHousingExp: firstCurrentMonthlyPaymentPrincipal,
    });
  }
  const secondCurrentMonthlyPayment = formatFnmDecimal(
      property_to_be_sold.second_current_monthly_payment);
  if (secondCurrentMonthlyPayment) {
    expenses.push({
      applicantSocialSecurityNumber,
      presentProposedIndicator,
      housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.OTHER_FINANCING_P_AND_I,
      housingPaymentAmountMonthlyHousingExp: secondCurrentMonthlyPayment,
    });
  }
  if (isNonZero(property.home_owners_insurance)) {
    const homeOwnersInsurance = formatFnmDecimal(toMonthly(property.home_owners_insurance,
        property.home_owners_insurance_frequency));
    expenses.push({
      applicantSocialSecurityNumber,
      presentProposedIndicator,
      housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.HAZARD_INSURANCE,
      housingPaymentAmountMonthlyHousingExp: homeOwnersInsurance,
    });
  }
  if (HOA_PROPERTY_TYPES.includes(property.property_type)) {
    if (isNonZero(property.hoa1)) {
      const value = formatFnmDecimal(toMonthly(property.hoa1, property.hoa1_frequency)
          .plus(toMonthly(property.hoa2, property.hoa2_frequency)));
      if (value) {
        expenses.push({
          applicantSocialSecurityNumber,
          presentProposedIndicator,
          housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.HOMEOWNER_ASSOCIATION_DUES,
          housingPaymentAmountMonthlyHousingExp: value,
        });
      }
    }
  }
  if (isNonZero(property_to_be_sold.existing_mortgage_insurance)) {
    const value = formatFnmDecimal(property_to_be_sold.existing_mortgage_insurance);
    if (value) {
      expenses.push({
        applicantSocialSecurityNumber,
        presentProposedIndicator,
        housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.MORTGAGE_INSURANCE,
        housingPaymentAmountMonthlyHousingExp: value,
      });
    }
  }
  document.applicant.borrowers.forEach(borrower => {
    const applicantSocialSecurityNumber = borrower.ssn;
    const monthlyRentalIncome = filterParseFnmDecimal(borrower.rental_amount);
    if (borrower.currently_renting_or_owning === RENT && monthlyRentalIncome) {
      expenses.push({
        applicantSocialSecurityNumber,
        presentProposedIndicator,
        housingPaymentTypeCode: FNM.HOUSING_EXPENSE_TYPE.RENT,
        housingPaymentAmountMonthlyHousingExp: formatFnmDecimal(monthlyRentalIncome),
      });
    }
  });
  return expenses;
}

export function exportNetRentalIncome({ document }) {
  let applicantSocialSecurityNumber;
  const {
    property,
    investment_properties
  } = document;
  if (document.applicant.borrowers.length) {
    applicantSocialSecurityNumber = document.applicant.borrowers[0].ssn;
  }
  if (!investment_properties.length) {
    return [];
  }
  const incomeAmountMonthlyIncome = formatFnmDecimal(
      investment_properties_monthly_amount(
          wrap_investment_properties(property, investment_properties)));
  return {
    applicantSocialSecurityNumber,
    typeOfIncomeCode: FNM.INCOME_CODE.NET_RENTAL_INCOME,
    incomeAmountMonthlyIncome
  };
}

function isNonZero(value) {
  return value && !new Decimal(value).isZero();
}

export function exportRealEstateOwned({ document, documentType }) {
  let applicantSocialSecurityNumber;
  if (document.applicant.borrowers.length) {
    applicantSocialSecurityNumber = document.applicant.borrowers[0].ssn;
  }
  const properties = [];
  let reoAssetId = 1;
  const {
    property_to_be_sold
  } = document;
  if (documentType === REFINANCE) {
    const {
      property,
      is_selling_property
    } = document;
    const subjectPropertyReo = document.investment_properties.find(function (property) {
      return property.is_subject_property === YES;
    });
    const loan = summary_selected_loan(document.loans, document.summary);
    const presentMarketValue = formatFnmDecimal(property.listed_price);
    let amountOfMortgagesLiensStr;
    let grossRentalIncome;
    let mortgagePayments;
    let netRentalIncome;
    let typeOfProperty;
    if (!subjectPropertyReo) {
      const firstLoanBalance = parseFnmDecimal(property_to_be_sold.first_loan_balance) || new Decimal(0);
      const secondLoanBalance = parseFnmDecimal(property_to_be_sold.second_loan_balance) || new Decimal(0);
      amountOfMortgagesLiensStr = formatFnmDecimal(firstLoanBalance.plus(secondLoanBalance));
      mortgagePayments = formatFnmDecimal(total_of_payments(document.property_to_be_sold));
      typeOfProperty = LANIS_PROPERTY_TYPE.TO_TYPE_OF_PROPERTY.export[property.property_type];
    } else {
      const wrappedProperty = wrap_subject_property(property, subjectPropertyReo);
      amountOfMortgagesLiensStr = formatFnmDecimal(wrappedProperty.principal_balance);
      grossRentalIncome = formatFnmDecimal(wrappedProperty.gross_rent_payment);
      netRentalIncome = formatFnmDecimal(net_income_or_loss(wrappedProperty));
      mortgagePayments = parseFnmDecimal(wrappedProperty.first_mortgage);
      typeOfProperty = LANIS_REO_PROPERTY_TYPE.export[wrappedProperty.reo_property_type];
      if (mortgagePayments) {
        const secondMortgage = parseFnmDecimal(wrappedProperty.second_mortgage);
        if (secondMortgage) {
          mortgagePayments = mortgagePayments.plus(secondMortgage);
        }
      }
    }
    const amountOfMortgagesLiens = parseFnmDecimal(amountOfMortgagesLiensStr);

    const insuranceMaintenanceTaxesMisc = loan ? formatFnmDecimal(total_monthly_payment(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
    )) : "";
    // Per the spec, only specify mortgagePayments if amountOfMortgagesLiens is greater than 0
    const includeMortgagePayments = amountOfMortgagesLiens && amountOfMortgagesLiens.isPositive();
    properties.push({
      applicantSocialSecurityNumber,
      propertyStreetAddress: property.address,
      propertyCity: property.city,
      propertyState: property.state,
      propertyZipCode: property.zip,
      propertyDisposition: property.classification === INVESTMENT_PROPERTY_CLASSIFICATION
          ? FNM.PROPERTY_DISPOSITION.RENTAL
          : FNM.PROPERTY_DISPOSITION.RETAINED,
      typeOfProperty,
      presentMarketValue,
      amountOfMortgagesLiens: amountOfMortgagesLiensStr,
      grossRentalIncome,
      mortgagePayments: includeMortgagePayments ? mortgagePayments : null,
      insuranceMaintenanceTaxesMisc,
      netRentalIncome,
      currentResidenceIndicator: property.classification === PRIMARY_RESIDENCE ? "Y" : "N",
      subjectPropertyIndicator: "Y",
      reoAssetId: "" + reoAssetId++
    });
    if (subjectPropertyReo) {
      const residences = [];
      document.applicant.borrowers.forEach(borrower => {
        const residence = [
            borrower.current_address,
            borrower.current_address_2,
            borrower.city,
            borrower.state,
            borrower.zip_code,
        ].join("\n");
        if (residences.includes(residence)) {
          return;
        }
        const ownsPrimaryResidence = borrower.currently_renting_or_owning === OWN;
        const presentMarketValue = ownsPrimaryResidence ? formatFnmDecimal(borrower.market_value) : null;
        const propertyStreetAddress = [
          borrower.current_address,
          borrower.current_address_2
        ].filter(a => !!a).join(" ");
        residences.push(residence);
        properties.push({
          applicantSocialSecurityNumber: borrower.ssn,
          propertyStreetAddress,
          presentMarketValue,
          propertyCity: borrower.city,
          propertyState: borrower.state,
          propertyZipCode: borrower.zip_code,
          propertyDisposition: FNM.PROPERTY_DISPOSITION.RETAINED,
          typeOfProperty: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
          currentResidenceIndicator: "Y",
          subjectPropertyIndicator: "N",
          reoAssetId: "" + reoAssetId++
        });
      })
    }
  }
  if (documentType === PURCHASE && document.is_selling_property === YES) {
    const presentMarketValue = formatFnmDecimal(property_to_be_sold.current_market_value);
    properties.push({
      applicantSocialSecurityNumber,
      propertyDisposition: FNM.PROPERTY_DISPOSITION.PENDING_SALE,
      // TODO: We just don't track this for the property to be sold
      typeOfProperty: FNM.TYPE_OF_PROPERTY.CONDOMINIUM,
      presentMarketValue,
      // TODO: This might be difficult to determine given data stored by Lanis
      currentResidenceIndicator: "N",
      subjectPropertyIndicator: "N",
      reoAssetId: "" + reoAssetId++
    })
  }
  document.investment_properties.forEach(property => {
    if (property.is_subject_property === YES) {
      // Skip. Nothing here needs to be exported as it's
      // already handled by the subject property.
      return;
    }
    const presentMarketValue = formatFnmDecimal(property.market_value);
    const amountOfMortgagesLiensStr = formatFnmDecimal(property.principal_balance);
    const amountOfMortgagesLiens = parseFnmDecimal(amountOfMortgagesLiensStr);
    const grossRentalIncome = formatFnmDecimal(property.gross_rent_payment);
    let mortgagePayments = parseFnmDecimal(property.first_mortgage);
    if (mortgagePayments) {
      const secondMortgage = parseFnmDecimal(property.second_mortgage);
      if (secondMortgage) {
        mortgagePayments = mortgagePayments.plus(secondMortgage);
      }
    }
    const insuranceMaintenanceTaxesMisc = formatFnmDecimal(total_misc_monthly_fees(property));
    const netRentalIncome = formatFnmDecimal(net_income_or_loss(property));
    // Per the spec, only specify mortgagePayments if amountOfMortgagesLiens is greater than 0
    const includeMortgagePayments = amountOfMortgagesLiens && amountOfMortgagesLiens.isPositive();
    properties.push({
      applicantSocialSecurityNumber,
      propertyStreetAddress: property.property_address,
      propertyCity: property.property_city,
      propertyState: property.property_state,
      propertyZipCode: property.property_zip,
      propertyDisposition: FNM.PROPERTY_DISPOSITION.RENTAL,
      typeOfProperty: LANIS_REO_PROPERTY_TYPE.export[property.reo_property_type],
      presentMarketValue,
      amountOfMortgagesLiens: amountOfMortgagesLiensStr,
      grossRentalIncome,
      mortgagePayments: includeMortgagePayments ? mortgagePayments : null,
      insuranceMaintenanceTaxesMisc,
      netRentalIncome,
      // TODO: This might be difficult to determine given data stored by Lanis
      currentResidenceIndicator: "N",
      subjectPropertyIndicator: "N",
      reoAssetId: "" + reoAssetId++
    })
  });
  return properties;
}

export function exportDetailsOfTransaction({ document, documentType }) {
  let purchasePrice;
  if (documentType === PURCHASE) {
    purchasePrice = formatFnmDecimal(document.property.listed_price);
  }
  return { purchasePrice };
}

export function exportFannieMaeTransmittalData({ document }) {
  const propertyAppraisedValue = formatFnmDecimal(document.property.listed_price);
  return {
    ownerOfExistingMortgage: FNM.OWNER_OF_EXISTING_MORTGAGE.UNKNOWN,
    propertyAppraisedValue,
    actualVsEstimatedAppraisedValueIndicator: FNM.ACTUAL_VS_ESTIMATED_APPRAISED_INDICATOR.ACTUAL,
  }
}

export function exportLoanCharacteristics({ document }) {
  const subjectPropertyTypeCode = LANIS_PROPERTY_TYPE.TO_SUBJECT_PROPERTY_TYPE_CODE
      .export[document.property.property_type];
  return {
    lienTypeCode: FNM.LIEN_TYPE_CODE.FIRST_MORTGAGE,
    subjectPropertyTypeCode,
    balloonIndicator: "N",
    willEscrowBeWaived: "N",
    hoepa: "N",
    preapproval: "N",
  }
}
