import React, { useEffect, useState, useRef } from "react";

import ADDITIONAL_INCOME_TYPES, {
  SOCIAL_SECURITY
} from "../../constants/additional_income_types";

import SocialSecurityIncomeCalculator from "./social_security_income_calculator";

import SelectField from "../fields/select_field";
import TextField from "../fields/text_field";
import CurrencyField from "../fields/currency_field";
import Section from "../ui/section";
import { default_additional_income } from "../../schema/additional_income";
import { useDocument } from "../loan_tools/loan_tool";

export default function EditAdditionalIncome({
  borrowerIndex,
  borrower,
  editedEntryIndex,
  incomeSource,
  onChangeBorrowerIncome,
  backToTable
}) {
  const [,,,{ saveDocument }] = useDocument();
  const [incomeSourceEdit, setIncomeSourceEdit] = useState({
    ...default_additional_income,
    ...incomeSource
  });

  const {
    income_type,
    description,
    yearly_amount,
    monthly_amount,
    increase
  } = incomeSourceEdit;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (incomeSourceEdit.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in the income form. Would you like to save it?"
          )
        ) {
          save();
        } else {
          // do nothing
        }
      }
    };
  }, [incomeSourceEdit]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function onChange(updatedState) {
    setIncomeSourceEdit({
      ...incomeSourceEdit,
      ...updatedState,
      is_modified: true
    });
  }

  function save() {
    delete incomeSourceEdit.is_modified;
    const other_income = [...borrower.income.other_income];
    other_income[editedEntryIndex] = incomeSourceEdit;
    onChangeBorrowerIncome({ other_income });
    saveDocument();
    backToTable();
  }

  return (
    <Section
      className="edit-additional-income"
      name={`Editing Additional Income For ${borrower.first_name} ${
        borrower.middle_name
      } ${borrower.last_name} (Borrower ${borrowerIndex + 1})`}
    >
      <div className="additional-income-fields">
        <SelectField
          fieldName="income_type"
          name="Income Type"
          value={income_type}
          onChange={onChange}
          selectOptions={ADDITIONAL_INCOME_TYPES}
        />

        <TextField
          fieldName="description"
          name="Description"
          value={description}
          onChange={onChange}
        />

        {income_type !== SOCIAL_SECURITY && (
          <CurrencyField
            fieldName="yearly_amount"
            name="Yearly amount"
            value={yearly_amount}
            onChange={onChange}
          />
        )}

        {income_type === SOCIAL_SECURITY && (
          <SocialSecurityIncomeCalculator
            yearly_amount={yearly_amount}
            monthly_amount={monthly_amount}
            increase={increase}
            onChange={onChange}
          />
        )}
      </div>

      <button className="button" onClick={backToTable}>
        Cancel
      </button>
      <button className="button" onClick={save}>
        Save
      </button>
    </Section>
  );
}
