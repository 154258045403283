import React from "react";
import Decimal from "decimal.js";

import { year_to_payment, payment_to_year } from "../../calculations/income";

import CurrencyField from "../fields/currency_field";
import NumberField from "../fields/number_field";

export default function TeacherIncomeCalculator({
  incomeSource,
  onChangeIncome
}) {
  const {
    base_income_0,
    number_of_months_0,
    base_income_1,
    number_of_months_1
  } = incomeSource;

  function onChange(updatedState) {
    Object.keys(updatedState).forEach(fieldKey => {
      if (fieldKey === "base_income_0") {
        updatedState[fieldKey] = payment_to_year(
          updatedState[fieldKey],
          incomeSource.number_of_months_0
        ).toString();
      }
      if (fieldKey === "base_income_1") {
        updatedState[fieldKey] = payment_to_year(
          updatedState[fieldKey],
          incomeSource.number_of_months_1
        ).toString();
      }
      if (fieldKey === "number_of_months_0") {
        const payment = year_to_payment(
          incomeSource.base_income_0,
          incomeSource.number_of_months_0
        );
        updatedState["base_income_0"] = payment_to_year(
          payment,
          updatedState.number_of_months_0
        ).toString();
      }
      if (fieldKey === "number_of_months_1") {
        const payment = year_to_payment(
          incomeSource.base_income_1,
          incomeSource.number_of_months_1
        );
        updatedState["base_income_1"] = payment_to_year(
          payment,
          updatedState.number_of_months_1
        ).toString();
      }
    });
    onChangeIncome(updatedState);
  }

  return (
    <div className="edit-borrower-income-calculator">
      <table>
        <thead>
          <tr>
            <th>Pay/Mo.</th>
            <th># of Mos.</th>
            <th>Pay/Year</th>
            <th>Avg/Mo.</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <CurrencyField
                fieldName="base_income_0"
                onChange={onChange}
                value={year_to_payment(base_income_0, number_of_months_0)}
              />
            </td>
            <td>
              <NumberField
                fieldName="number_of_months_0"
                onChange={onChange}
                value={number_of_months_0}
              />
            </td>
            <td>
              <CurrencyField readOnly value={base_income_0} />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_payment(base_income_0, "12")}
              />
            </td>
          </tr>

          <tr>
            <td>
              <CurrencyField
                fieldName="base_income_1"
                onChange={onChange}
                value={year_to_payment(base_income_1, number_of_months_1)}
              />
            </td>
            <td>
              <NumberField
                fieldName="number_of_months_1"
                onChange={onChange}
                value={number_of_months_1}
              />
            </td>
            <td>
              <CurrencyField readOnly value={base_income_1} />
            </td>
            <td>
              <CurrencyField
                readOnly
                value={year_to_payment(base_income_1, "12")}
              />
            </td>
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <td>
              <b>Total/Year</b>
            </td>
            <td />
            <td>
              <CurrencyField
                readOnly
                value={new Decimal(base_income_0).plus(base_income_1)}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
