import React from "react";

import "./css/select_field.scss";
import { class_list } from "../../constants/utils";
import TrainingTip from "../ui/training_tip";
import Decimal from "decimal.js";

/**
 * SelectField is suitable for rendering a select component with a label.
 */
export default function SelectField({
  value,
  className,
  fieldName,
  onChange,
  emptyDefault,
  selectOptions,
  naked,
  containerClassname,
  id,
  name,
  title,
  tabIndex,
  readOnly,
  showDefaultOption,
  sortLabel,
  sortValueAsNumber,
  children,
  defaultOptionValue,
  optionClassNameFunction,
  showRequired
}) {
  function isTbd(testValue) {
    return (
      testValue &&
      (testValue.toUpperCase() === "TBD" || testValue.startsWith("TBD"))
    );
  }

  function class_name() {
    let classname = "input-field select-field";

    if (className) {
      classname += ` ${className}`;
    }

    if (isTbd(value)) {
      classname += " tbd";
    }

    return classname;
  }

  function change(event) {
    if (fieldName && onChange) {
      onChange({
        [fieldName]: event.target.value
      });
    }
  }

  function renderOption([value, label]) {
    const tbdClass = isTbd(value) ? "tbd" : "";
    return (
      <option
        key={value}
        value={value}
        className={class_list(
          tbdClass,
          optionClassNameFunction && optionClassNameFunction(value, label)
        )}
      >
        {label}
      </option>
    );
  }

  function getOptions() {
    const options = Object.entries(selectOptions);
    if (sortValueAsNumber) {
      // Assume all values are proper numbers.
      options.sort((a, b) => {
        const numberA = new Decimal(a[0]);
        const numberB = new Decimal(b[0]);
        return numberA.lt(numberB) ? -1 : numberA.gt(numberB) ? 1 : 0;
      });
    }
    if (sortLabel) {
      options.sort((a, b) => a[1].localeCompare(b[1]));
    }
    return options;
  }

  function defaultLabel() {
    return emptyDefault ? "" : "Select...";
  }

  function renderNaked() {
    const label = value ? selectOptions[value] : defaultLabel();
    return <span className={className}>{label}</span>;
  }

  if (naked) {
    return renderNaked();
  }

  return (
    <div
      className={
        "input-field-container select-field-container " + containerClassname
      }
    >
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>

      <select
        id={id}
        value={value || ""}
        onChange={change}
        step="any"
        tabIndex={tabIndex}
        disabled={readOnly}
        className={class_name()}
      >
        <option
          value={defaultOptionValue ? defaultOptionValue : ""}
          disabled={!showDefaultOption}
          hidden={!showDefaultOption}
        >
          {defaultLabel()}
        </option>
        {/*
         * For selectOptions this field expects an object with each key being a
         * value, and each value being a label; For example:
         * {
         *   "0": "Yes",
         *   "1": "No",
         *   "2": "Tbd"
         * }
         */}
        {selectOptions && getOptions().map(renderOption)}
        {children}
      </select>
    </div>
  );
}
