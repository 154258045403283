/**
 * Constants that are used for calculations.
 */

export const LENDER_FEE = "100";

export const SHOCK_RATE_PERCENT = "0.0018";

export const USDA_MONTHLY_MORTGAGE_INSURANCE_RATE = "0.35";

export const ESCROW_FEE_PERCENT = "0.001";

export const LTV_MORTGAGE_INSURANCE_PERCENT_MINIMUM = "80.01";

export const AGENCY_PLUS_SUPER_CONFORMING_MINIMUM = "453101";
