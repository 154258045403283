import React, { useState, useEffect } from "react";
import Section from "../ui/section";
import UserProfile from "../profile/user_profile";
import { default_account_profile } from "../../schema/profile";
import UserService from "../../services/user_service";
import { processUserProfile } from "../../hooks/user";
import ErrorMessages from "../ui/error_messages";
import USER_ROLES from "../../constants/user_roles";
import { handleErrorMessages } from "../../services/utils";

// TODO: enhance it to support more than just subaccounts.
export default function EditUser({
  isNewUser,
  userId,
  parentUserId,
  onExit,
  role
}) {
  const [currentUserProfile, setCurrentUserProfile] = useState({
    ...default_account_profile
  });
  const [profile, setProfile] = useState({
    ...default_account_profile
  });
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  function onProfileChange(updatedProfile) {
    setProfile({ ...profile, ...updatedProfile });
  }

  async function onProfileSave() {
    if (isNewUser) {
      await UserService.postCompanyNewUser(
        role,
        parentUserId,
        profile.email,
        password,
        profile.first_name,
        profile.last_name,
        { ...profile }
      )
        .then(() => onExit())
        .catch(error => handleErrorMessages(error, setErrors));
    } else {
      await UserService.postCompanyUser(userId, profile.email, password, {
        ...profile
      })
        .then(() => onExit())
        .catch(error => handleErrorMessages(error, setErrors));
    }
  }

  useEffect(() => {
    if (!isNewUser) {
      UserService.getCompanyUser(userId)
        .then(userProfile => {
          setCurrentUserProfile(processUserProfile(userProfile));
          setProfile(processUserProfile(userProfile));
        })
        .catch(error => handleErrorMessages(error, setErrors));
    } else {
      setProfile({ ...default_account_profile });
    }
  }, [userId]);

  return (
    <Section
      name={
        isNewUser
          ? `New ${USER_ROLES[role]}`
          : `Edit User (${currentUserProfile.email})`
      }
      className="edit-user"
    >
      <ErrorMessages messages={errors} />
      <div>
        <div className="half-width">
          <UserProfile
            profile={profile}
            onProfileChange={onProfileChange}
            isNewUser={isNewUser}
            password={password}
            onPasswordChange={setPassword}
          />
        </div>
        <div>
          <button className="button" onClick={onExit}>
            Cancel
          </button>
          <button className="button" onClick={onProfileSave}>
            Save
          </button>
        </div>
      </div>
    </Section>
  );
}
