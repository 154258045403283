export const ANNUAL = "Annual";
export const MONTHLY = "Monthly";
export const MONTHLY_24 = "Monthly 24";
export const MONTHLY_26 = "Monthly 26";
export const WEEKLY = "Weekly";
export const PROFESSOR_TEACHER = "Professor/Teacher";
export const HOURLY = "Hourly";

export default Object.freeze({
  [ANNUAL]: ANNUAL,
  [MONTHLY]: MONTHLY,
  [MONTHLY_24]: MONTHLY_24,
  [MONTHLY_26]: MONTHLY_26,
  [WEEKLY]: WEEKLY,
  [PROFESSOR_TEACHER]: PROFESSOR_TEACHER,
  [HOURLY]: HOURLY
});
