import React from "react";
import { Link, navigate } from "@reach/router";
import { useDocument } from "./loan_tool";

const SaveLoan = React.forwardRef(
  ({ name, path, useAnchor, className, disabled, onSave }, ref) => {
    const [, , , { saveDocument }] = useDocument();
    const displayName = name || "Save And Next";

    const onSubmit = e => {
      e.preventDefault();
      onSave && onSave();
      saveDocument();
      if (path) {
        navigate(path);
      }
    };

    if (useAnchor) {
      return (
        <Link
          to={path || "/"}
          ref={ref}
          className={className}
          onClick={() => {
            onSave && onSave();
            saveDocument();
          }}
        >
          {displayName}
        </Link>
      );
    }

    return (
      <button
        ref={ref}
        className={className}
        onClick={onSubmit}
        disabled={disabled}
      >
        {displayName}
      </button>
    );
  }
);

export default SaveLoan;
