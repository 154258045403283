import React, { useState, useEffect } from "react";
import "./css/company_management.scss";

import Section from "../ui/section";
import SystemAdminService from "../../services/system_admin_service";
import ErrorMessages from "../ui/error_messages";
import { company_metadata } from "../../schema/system_admin/company_metadata";
import moment from "moment-timezone";
import { SUBSCRIPTION_STATUSES } from "../../schema/system_admin/company_details";
import EditCompany from "./edit_company";
import AddCompany from "./add_company";
import { handleErrorMessages } from "../../services/utils";

export default function CompanyManagement() {
  const [activeSection, setActiveSection] = useState("MANAGEMENT");
  const [errorMessages, setErrorMessages] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [editCompanyId, setEditCompanyId] = useState(0);

  function loadCompanies() {
    SystemAdminService.getCompanies()
      .then(metadatas =>
        setCompanies(
          metadatas.map(metadata => ({
            ...company_metadata,
            ...metadata
          }))
        )
      )
      .catch(error => handleErrorMessages(error, setErrorMessages));
  }

  function editCompany(id) {
    setEditCompanyId(id);
    setActiveSection("EDIT_COMPANY");
  }

  useEffect(() => {
    if (activeSection === "MANAGEMENT") {
      loadCompanies();
    }
  }, [activeSection]);

  return (
    <div className="company-management">
      {activeSection === "MANAGEMENT" && (
        <Section name="Company Management" className="companies">
          <ErrorMessages messages={errorMessages} />
          <div>
            <button
              className="button"
              onClick={() => setActiveSection("ADD_COMPANY")}
            >
              Add New Company
            </button>
          </div>
          <div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Company Name</th>
                  <th>Company Email</th>
                  <th>Subscription Active</th>
                  <th>Subscription End Date</th>
                  <th>Subscription Status</th>
                  <th>Current Seats (Limit)</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {companies.map(company => (
                  <tr key={company.id}>
                    <td>{company.id}</td>
                    <td>{company.name}</td>
                    <td>{company.email}</td>
                    <td>{company.subscription_active ? "Yes" : "No"}</td>
                    <td>
                      {company.subscription_end_date > 0 &&
                        moment(company.subscription_end_date)
                          .tz(moment.tz.guess())
                          .format("MM/DD/YYYY hh:mm A")}
                    </td>
                    <td>
                      {SUBSCRIPTION_STATUSES[company.subscription_status]}
                    </td>
                    <td>
                      {company.current_seat_amount} ({company.seat_limit})
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() => editCompany(company.id)}
                      >
                        Edit
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Section>
      )}
      {activeSection === "EDIT_COMPANY" && (
        <EditCompany
          companyId={editCompanyId}
          onExit={() => {
            setActiveSection("MANAGEMENT");
          }}
        />
      )}
      {activeSection === "ADD_COMPANY" && (
        <AddCompany
          onExit={() => {
            setActiveSection("MANAGEMENT");
          }}
        />
      )}
    </div>
  );
}
