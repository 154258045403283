import React, { useState } from "react";
import "./css/applicant_info.scss";

import { useDocument } from "../../components/loan_tools/loan_tool";

import ApplicantTable from "../../components/loan_tools/applicant/applicant_table";
import EditBorrower from "../../components/loan_tools/applicant/edit_borrower";

import StandardTemplate from "../../templates/standard_template/standard_template";
import { APPLICANT_INFO } from "../../constants/navigations";

export default function ApplicantInfoPage() {
  const [activePage, setActivePage] = useState("BORROWER_TABLE");
  const [borrowerIndex, setBorrowerIndex] = useState(0);
  const [document, setDocument] = useDocument();

  function showEditBorrower(borrowerIndex) {
    setActivePage("EDIT_BORROWER");
    setBorrowerIndex(borrowerIndex);
  }

  function showBorrowerTable() {
    setActivePage("BORROWER_TABLE");
  }

  function onChangeApplicant(updatedState) {
    const applicant = {
      ...document.applicant,
      ...updatedState
    };
    setDocument({ ...document, applicant });
  }

  function onChangeBorrowers(borrowers) {
    const applicant = { ...document.applicant };
    applicant.borrowers = borrowers;
    setDocument({ ...document, applicant });
  }

  function deleteBorrower(borrowerIndex) {
    // TODO: does anything else reference a borrower's index?
    // Do we need to update some other property?
    const borrowers = [...document.applicant.borrowers];
    borrowers.splice(borrowerIndex, 1);
    const applicant = { ...document.applicant };
    applicant.borrowers = borrowers;
    setDocument({ ...document, applicant });
  }

  const borrower = document.applicant.borrowers[borrowerIndex];
  const subjectPropertyIsReo = document.investment_properties
      .some(property => property.is_subject_property);

  return (
    <StandardTemplate
      active={APPLICANT_INFO}
      isDisableNavSave={activePage !== "BORROWER_TABLE"}
    >
      <div className="applicant-info">
        {activePage === "BORROWER_TABLE" && (
          <ApplicantTable
            onChangeApplicant={onChangeApplicant}
            showEditBorrower={showEditBorrower}
            deleteBorrower={deleteBorrower}
          />
        )}
        {activePage === "EDIT_BORROWER" && (
          <EditBorrower
            borrower={borrower}
            borrowerIndex={borrowerIndex}
            subjectPropertyIsReo={subjectPropertyIsReo}
            onChangeBorrowers={onChangeBorrowers}
            showApplicantTable={showBorrowerTable}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
