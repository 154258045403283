import React from "react";
import "./css/refinance_total_cost_analysis.scss";
import { useDocument } from "../loan_tool";
import PercentField from "../../fields/percent_field";
import TrainingTip from "../../ui/training_tip";
import NumberField from "../../fields/number_field";
import CurrencyField from "../../fields/currency_field";
import {
  cash_flow_over_years,
  net_savings_over_years,
  number_of_months_to_recoup_closing_costs,
  positive_cash_flow_per_year,
  return_on_investment_cash_flow
} from "../../../calculations/total_cost_analysis";
import { class_list } from "../../../constants/utils";

export default function RefinanceTotalCostAnalysis({ updateSummary }) {
  const [
    {
      loans,
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences,
      summary
    },
    ,
    { documentType }
  ] = useDocument();

  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];

  function updateCashFlowOverYears(index, years) {
    const refinanceCashFlowOverYears = [
      ...summary.refinance_cash_flow_over_years
    ];
    refinanceCashFlowOverYears[index] = years;
    updateSummary({
      refinance_cash_flow_over_years: refinanceCashFlowOverYears
    });
  }

  return (
    <>
      <tbody className="refinance-total-cost-analysis loan-summaries">
        <tr>
          <td colSpan="2">Return on Investment</td>
          <td>
            Cash Flow{" "}
            <TrainingTip content="LANIS Training Note: The question to ask is what is the most important financial concern you have right now?  Is it Cash Flow or protecting your Equity?  If it is Equity then focus on the Cost of Money Over Time!" />
          </td>
          {fiveLoans.map((loan, index) => {
            const returnOnInvestmentCashFlow =
              loan &&
              return_on_investment_cash_flow(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              );
            return (
              <td
                className={class_list(
                  loan && returnOnInvestmentCashFlow.lt(0) && "red-text"
                )}
                key={index}
              >
                {loan && (
                  <PercentField
                    naked
                    fixedDigits={2}
                    value={returnOnInvestmentCashFlow}
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className="right-align" colSpan="3">
            # Months to recoup closing costs &gt; &gt; &gt;
          </td>
          {fiveLoans.map((loan, index) => {
            const numberOfMonths =
              loan &&
              number_of_months_to_recoup_closing_costs(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              );
            return (
              <td
                className={class_list(
                  loan && numberOfMonths.lt(0) && "red-text"
                )}
                key={index}
              >
                {loan && (
                  <NumberField naked decimalPlaces={2} value={numberOfMonths} />
                )}
              </td>
            );
          })}
        </tr>
        <tr>
          <td className="right-align" colSpan="3">
            Positive Cash Flow Per Year &gt; &gt; &gt;
          </td>
          {fiveLoans.map((loan, index) => {
            const cashFlow =
              loan &&
              positive_cash_flow_per_year(
                documentType,
                property_to_be_sold,
                accountSummaries,
                residences,
                loan,
                property,
                is_selling_property
              );
            return (
              <td
                className={class_list(loan && cashFlow.lt(0) && "red-text")}
                key={index}
              >
                {loan && (
                  <CurrencyField naked fixedDigits={0} value={cashFlow} />
                )}
              </td>
            );
          })}
        </tr>
        {summary.refinance_cash_flow_over_years.map((years, index) => (
          <tr key={index}>
            <td colSpan="2">{index === 0 && "Cash Flow Over Years"}</td>
            <td>
              <div className="no-print">
                <NumberField
                  fieldName="years"
                  value={years}
                  onChange={updates =>
                    updateCashFlowOverYears(index, updates.years)
                  }
                />
              </div>
              <div className="print-only center-align">
                <NumberField naked value={years} />
              </div>
            </td>
            {fiveLoans.map((loan, loanIndex) => {
              const cashFlow =
                loan &&
                cash_flow_over_years(
                  years,
                  documentType,
                  property_to_be_sold,
                  accountSummaries,
                  residences,
                  loan,
                  property,
                  is_selling_property
                );
              return (
                <td
                  className={class_list(loan && cashFlow.lt(0) && "red-text")}
                  key={loanIndex}
                >
                  {loan && (
                    <CurrencyField naked fixedDigits={0} value={cashFlow} />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
        <tr>
          <td colSpan="8">
            <div className="indent">
              Cost of Money Over Time (NET Savings or Cost minus closings costs)
            </div>
          </td>
        </tr>
        {summary.refinance_cash_flow_over_years.map((years, index) => (
          <tr key={index}>
            <td colSpan="2">{index === 0 && "Net Savings Over Years"}</td>
            <td className="center-align">
              <NumberField naked value={years} />
            </td>
            {fiveLoans.map((loan, loanIndex) => {
              const netSavings =
                loan &&
                net_savings_over_years(
                  years,
                  documentType,
                  property_to_be_sold,
                  accountSummaries,
                  residences,
                  loan,
                  property,
                  is_selling_property
                );
              return (
                <td
                  className={class_list(loan && netSavings.lt(0) && "red-text")}
                  key={loanIndex}
                >
                  {loan && (
                    <CurrencyField naked fixedDigits={0} value={netSavings} />
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
      <tbody>
        <tr>
          <td colSpan="8" />
        </tr>
      </tbody>
    </>
  );
}
