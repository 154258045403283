import React from "react";
import CurrencyField from "../../fields/currency_field";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import LOAN_PAYMENT_TYPE from "../../../constants/loan_payment_type";
import { has_broker_loan, loan_term_name } from "../../../constants/utils";
import {
  final_total_loan_to_value,
  loan_amount_first_mortgage,
  loan_amount_second_mortgage,
  loan_to_value,
  offer_price_or_market_value,
  total_of_loans
} from "../../../calculations/loan";
import PercentField from "../../fields/percent_field";
import PROPERTY_TYPE from "../../../constants/property_type";
import SelectField from "../../fields/select_field";

import { useDocument } from "../../loan_tools/loan_tool";
import TrainingTip from "../../ui/training_tip";

export default function LoanSummaries({ updateSummary }) {
  const [
    { summary, loans, property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];
  const antiSteeringOptions = { "": "", "1": "1", "2": "2", "3": "3" };

  function updateAntiSteeringSelection(loanIndex, updates) {
    const loans = summary.loans;
    Object.assign(loans[loanIndex], updates);
    updateSummary({ loans });
  }

  return (
    <>
      <tbody className="loan-summaries-align-center">
        <tr>
          <td className="blue-text" colSpan="3">
            {has_broker_loan(loans) && (
              <>
                Anti Steering Disclosure{" "}
                <TrainingTip
                  content="LANIS Training Tip: Please make Loan Selection for the Anti-Steering Disclosure to the right and place a 1, 2 or 3 in the appropriate field above the loan. LANIS will fill in the Disclosure for you.
1. Loan with the lowest interest rate,
2. Loan with the lowest interest rate without negative amortization, a prepayment penalty, interest-only payments, a balloon payment in the first 7 years of the life of the loan, a demand feature, shared equity, or shared appreciation,
3. Loan with the lowest dollar amount for origination fees and discount points."
                />
              </>
            )}
          </td>
          {summary.loans.map((loan, index) => (
            <td className="gray-bg anti-steering-select" key={index}>
              {loan && has_broker_loan(loans) && (
                <>
                  <div className="no-print">
                    <SelectField
                      emptyDefault
                      fieldName="anti_steering_selection"
                      value={loan.anti_steering_selection}
                      selectOptions={antiSteeringOptions}
                      onChange={updates =>
                        updateAntiSteeringSelection(index, updates)
                      }
                    />
                  </div>
                  <div className="print-only">
                    <SelectField
                      naked
                      emptyDefault
                      value={loan.anti_steering_selection}
                      selectOptions={antiSteeringOptions}
                    />
                  </div>
                </>
              )}
            </td>
          ))}
        </tr>
        <tr className="bold">
          <td colSpan="3" />
          <td>Loan 1</td>
          <td>Loan 2</td>
          <td>Loan 3</td>
          <td>Loan 4</td>
          <td>Loan 5</td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">Sales Price or Offer Price</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={offer_price_or_market_value(
                    documentType,
                    loan,
                    property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            Loan Type
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && LOAN_TYPE_CATEGORY_NAMES[loan.type]}</td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            Lender
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && loan.lender}</td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            Amortization
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && LOAN_PAYMENT_TYPE[loan.payment_type]}</td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            Loan Type / Term
          </td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && loan_term_name(loan.term)}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            LTV
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={loan_to_value(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="italic" colSpan="3">
            TLTV / CLTV
          </td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={final_total_loan_to_value(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Property Type</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && PROPERTY_TYPE[loan.property_type]}</td>
          ))}
        </tr>
      </tbody>
      <tbody className="loan-summaries">
        <tr>
          <td colSpan="3">Amount of 1st Loan</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan_amount_first_mortgage(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Amount of 2nd Loan</td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan_amount_second_mortgage(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Total of Loans</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_of_loans(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
      </tbody>
    </>
  );
}
