import React from "react";
import {
  annual_property_tax,
  d_plus_i,
  initial_escrow_payment_at_closing_homeowners_insurance,
  initial_escrow_payment_at_closing_mortgage_insurance,
  initial_escrow_payment_at_closing_property_taxes,
  initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly,
  lender_credits,
  mortgage_insurance_premium_monthly,
  payoff_request_fee,
  prepaids_calculator,
  prepaids_homeowners_insurance_premium,
  prepaids_mortgage_insurance_premium,
  prepaids_property_taxes,
  property_tax,
  rcd_fees,
  recording_fees_and_other_taxes,
  total_closing_costs,
  total_initial_escrow_payment_at_closing,
  total_other,
  total_other_costs,
  total_prepaids,
  total_taxes_and_other_government_fees,
  transfer_tax_purchase,
  transfer_taxes_calculator
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import PercentField from "../../fields/percent_field";
import { NON_OWNER } from "../../../constants/occupancy";
import Decimal from "decimal.js";
import {
  RCD_FEE_GD,
  RCD_FEE_GD_TR,
  RCD_FEE_TD
} from "../../../constants/loan_fees";
import {
  home_owners_insurance_monthly,
  mello_roos
} from "../../../calculations/property";
import OTHER_SERVICES from "../../../constants/other_services";
import { useDocument } from "../../loan_tools/loan_tool";
import TrainingTip from "../../ui/training_tip";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";

export default function OtherCosts({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  const isNonOwner = property.occupancy === NON_OWNER;
  const isInOutTrust = new Decimal(loan.in_out_trust).gt(0);
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;

  return (
    <>
      <table className="right-table">
        <colgroup>
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <td className="summary-header" colSpan="3">
              Other Costs
            </td>
            <td colSpan="4" />
          </tr>
        </tbody>
      </table>
      <div className="other-costs-content flex-column-space-between">
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="gray-bg bold" colSpan="6">
                E. Taxes and Other Government Fees{" "}
                <TrainingTip content="LANIS Training Note:  Recording Fee and other taxes 10%.  Transfer Taxes 0%." />
              </td>
              <td className="right-align gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_taxes_and_other_government_fees(
                    documentType,
                    loan,
                    property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="6">Recording Fees and Other Taxes</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={recording_fees_and_other_taxes(loan)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="6">
                <div className="flex-start-with-spacing">
                  <div>Trust Deed</div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={loan.trust_deed}
                    />
                  </div>
                  <div>Grant Deed</div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={loan.grand_deed}
                    />
                  </div>
                  <div>In/Out Trust</div>
                  <div>
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={loan.in_out_trust}
                    />
                  </div>
                </div>
              </td>
              <td />
            </tr>
            {isRefinance && (
              <tr>
                <td colSpan="6">
                  CA Affordable Housing Recording Fee Co. Sur Charge
                </td>
                <td className="right-align">
                  <CurrencyField
                    naked
                    displayFixedDigits={0}
                    value={
                      loan.ca_affordable_housing_recording_fee_co_sur_charge
                    }
                  />
                </td>
              </tr>
            )}
            {isPurchase && (
              <>
                <tr>
                  <td colSpan="6">
                    <div className="flex-space-between">
                      <div>Transfer Tax - Purchase</div>
                      <div>
                        <CurrencyField
                          naked
                          fixedDigits={2}
                          value={loan.transfer_taxes.base_value_k}
                        />
                      </div>
                      <div className="smaller-font">Base Value</div>
                      <div>
                        <NumberField
                          naked
                          value={transfer_taxes_calculator.transfer_total(
                            documentType,
                            loan,
                            loan.transfer_taxes,
                            property
                          )}
                        />
                      </div>
                      <div className="smaller-font">Per Thousand</div>
                    </div>
                  </td>
                  <td className="right-align">
                    <CurrencyField
                      naked
                      fixedDigits={0}
                      value={transfer_tax_purchase(
                        documentType,
                        loan,
                        property
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" />
                  <td className="center-align smaller-font">Base Value</td>
                  <td className="center-align smaller-font">Buyer/Seller</td>
                  <td />
                </tr>
                <tr>
                  <td colSpan="2">
                    <div className="flex-start-with-spacing">
                      <div>Country:</div>
                      <div>
                        <CurrencyField
                          naked
                          fixedDigits={2}
                          value={transfer_taxes_calculator.county_transfer_amount(
                            documentType,
                            loan,
                            loan.transfer_taxes,
                            property
                          )}
                        />
                      </div>
                    </div>
                  </td>
                  <td colSpan="2">
                    <div className="flex-start-with-spacing">
                      <div>City:</div>
                      <div>
                        <CurrencyField
                          naked
                          fixedDigits={2}
                          value={transfer_taxes_calculator.city_transfer_amount(
                            documentType,
                            loan,
                            loan.transfer_taxes,
                            property
                          )}
                        />
                      </div>
                    </div>
                  </td>
                  <td className="center-align">
                    <CurrencyField
                      naked
                      fixedDigits={2}
                      value={transfer_taxes_calculator.transfer_total(
                        documentType,
                        loan,
                        loan.transfer_taxes,
                        property
                      )}
                    />
                  </td>
                  <td className="center-align">
                    <PercentField
                      naked
                      fixedDigits={0}
                      value={loan.transfer_taxes.percent_split}
                    />
                  </td>
                  <td />
                </tr>
                {rcd_fees(loan, property).gt(0) && (
                  <tr>
                    <td className="smaller-font" colSpan="2">
                      <div className="flex-start-with-spacing">
                        <div>{isNonOwner && "Rcd Fee TD"}</div>
                        <div>
                          {isNonOwner && (
                            <CurrencyField
                              naked
                              fixedDigits={0}
                              value={RCD_FEE_TD}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="smaller-font" colSpan="2">
                      <div className="flex-start-with-spacing">
                        <div>{isNonOwner && "Rcd Fee GD"}</div>
                        <div>
                          {isNonOwner && (
                            <CurrencyField
                              naked
                              fixedDigits={0}
                              value={RCD_FEE_GD}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="smaller-font" colSpan="2">
                      <div className="flex-start-with-spacing">
                        <div>{isInOutTrust && "Rcd Fee GD Tr"}</div>
                        <div>
                          {isInOutTrust && (
                            <CurrencyField
                              naked
                              fixedDigits={0}
                              value={RCD_FEE_GD_TR}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="right-align">
                      <CurrencyField
                        naked
                        fixedDigits={0}
                        value={rcd_fees(loan, property)}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="6">
                F. Prepaids
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_prepaids(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Homeowner&apos;s Insurance Premium</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={home_owners_insurance_monthly(documentType, property)}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={loan.prepaids_homeowners_insurance_premium_months}
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={prepaids_homeowners_insurance_premium(
                    documentType,
                    loan,
                    property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">(HOI Per Year)</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={property.home_owners_insurance}
                />
              </td>
              <td colSpan="3" />
            </tr>
            <tr>
              <td colSpan="3">Mortgage Insurance Premium</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={loan.prepaids_mortgage_insurance_premium_months}
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={prepaids_mortgage_insurance_premium(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>Prepaid Int</td>
              <td>
                <NumberField naked value={loan.prepaids.days_of_interest} />
              </td>
              <td>
                <CurrencyField
                  naked
                  fixedDigits={4}
                  value={prepaids_calculator.prepaid_interest(
                    documentType,
                    loan,
                    loan.prepaids,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td className="center-align smallest-font">
                Per Day X{" "}
                <TrainingTip content="LANIS Training Note: Federal Law states you must have a minimum of 15 days on the LE." />
              </td>
              <td className="center-align">
                <NumberField naked value={loan.prepaids.per_days} />
              </td>
              <td className="center-align">
                <PercentField naked decimalPlaces={3} value={loan.rate} />
              </td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={prepaids_calculator.total_interest(
                    documentType,
                    loan,
                    loan.prepaids,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Property Taxes</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={property_tax(documentType, loan, property)}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={loan.prepaids_property_taxes_months}
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={prepaids_property_taxes(documentType, loan, property)}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="6">
                REAL ESTATE TAXES DUE (Depends on your states due dates)
              </td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan.prepaids_real_estate_taxes_due}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="6">
                G. Initial Escrow Payment at Closing
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_initial_escrow_payment_at_closing(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Homeowner&apos;s Insurance</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={home_owners_insurance_monthly(documentType, property)}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={
                    loan.initial_escrow_payment_at_closing_homeowners_insurance_months
                  }
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={initial_escrow_payment_at_closing_homeowners_insurance(
                    documentType,
                    loan,
                    property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Mortgage Insurance</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={mortgage_insurance_premium_monthly(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={
                    loan.initial_escrow_payment_at_closing_mortgage_insurance_months
                  }
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={initial_escrow_payment_at_closing_mortgage_insurance(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                Property Taxes{" "}
                {mello_roos(property, loan).gt(0) ? "+ Mello Roos" : ""}
              </td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
                    documentType,
                    loan,
                    property
                  )}
                />
              </td>
              <td className="center-align">
                <NumberField
                  naked
                  value={
                    loan.initial_escrow_payment_at_closing_property_taxes_months
                  }
                />
              </td>
              <td>Months</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={initial_escrow_payment_at_closing_property_taxes(
                    documentType,
                    loan,
                    property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="3">Property Taxes Per Year</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={2}
                  value={annual_property_tax(documentType, loan, property)}
                />
              </td>
              <td colSpan="3" />
            </tr>
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="6">
                H. Other
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_other(
                    documentType,
                    loan,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            {isRefinance && (
              <tr>
                <td colSpan="6">Payoff Request Fee</td>
                <td className="right-align">
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={payoff_request_fee(
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </td>
              </tr>
            )}
            {Object.entries(loan.other_services)
              .sort((a, b) =>
                OTHER_SERVICES[a[0]].localeCompare(OTHER_SERVICES[b[0]])
              )
              .map(([name, value]) => (
                <tr key={name}>
                  <td colSpan="6">{OTHER_SERVICES[name]}</td>
                  <td className="right-align">
                    <CurrencyField naked fixedDigits={0} value={value} />
                  </td>
                </tr>
              ))}
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border gray-bg bold" colSpan="6">
                I. Total Other Costs (E + F + G + H)
              </td>
              <td className="right-align top-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_other_costs(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="7" />
            </tr>
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
        <table className="right-table">
          <colgroup>
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <tbody>
            <tr>
              <td className="top-border bottom-border gray-bg bold" colSpan="6">
                J. Total Closing Costs
              </td>
              <td className="right-align top-border bottom-border gray-bg">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={total_closing_costs(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="6">D + I</td>
              <td className="right-align">
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={d_plus_i(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </td>
            </tr>
            {lender_credits(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            ).lt(0) && (
              <tr>
                <td colSpan="6">Lender Credits</td>
                <td className="right-align">
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={lender_credits(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                  />
                </td>
              </tr>
            )}
            <tr />
            <tr>
              <td colSpan="7" />
            </tr>
            <tr>
              <td colSpan="7" />
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
