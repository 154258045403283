export const FANNIEMAE = "1";
export const FREDDIEMAC = "2";
export const JUMBO = "3";
export const PORTFOLIO = "4";
export const FHA_PURCHASE = "5";
export const HARD_MONEY = "6";
export const USDA_PURCHASE = "7";
export const VA_PURCHASE = "8";
export const OTHER = "9";
export const CONSTRUCTION = "10";
export const NON_QM = "11";
export const FIX_AND_FLIP = "12";
export const FHA_STREAMLINE = "13";
export const VA_IRRRL = "14";

export const CONVENTIONAL_LOANS = [FANNIEMAE, FREDDIEMAC];

export const AGENCY_LOAN_TYPES = [
  FANNIEMAE,
  FREDDIEMAC,
  FHA_PURCHASE,
  HARD_MONEY,
  PORTFOLIO,
  USDA_PURCHASE,
  VA_PURCHASE,
  FHA_STREAMLINE,
  VA_IRRRL
];

export const LOANS_WITH_RATE_CALCULATOR = [
  HARD_MONEY,
  JUMBO,
  NON_QM,
  OTHER,
  CONSTRUCTION,
  FIX_AND_FLIP,
  PORTFOLIO
];

export const LOAN_TYPE_CATEGORY_NAMES = Object.freeze({
  [CONSTRUCTION]: "Construction",
  [FANNIEMAE]: "Conventional",
  [FREDDIEMAC]: "Conventional",
  [FHA_PURCHASE]: "FHA",
  [FHA_STREAMLINE]: "FHA",
  [FIX_AND_FLIP]: "Fix and Flip",
  [HARD_MONEY]: "Hard Money",
  [JUMBO]: "Jumbo",
  [NON_QM]: "Non - QM",
  [OTHER]: "Small Commercial",
  [PORTFOLIO]: "Portfolio",
  [USDA_PURCHASE]: "USDA",
  [VA_PURCHASE]: "VA",
  [VA_IRRRL]: "VA"
});

const LOAN_TYPES = Object.freeze({
  [CONSTRUCTION]: "Construction",
  [FANNIEMAE]: "FannieMae",
  [FREDDIEMAC]: "FreddieMac",
  [FHA_PURCHASE]: "FHA",
  [FIX_AND_FLIP]: "Fix and Flip",
  [HARD_MONEY]: "Hard Money",
  [JUMBO]: "Jumbo",
  [NON_QM]: "Non - QM",
  [OTHER]: "Small Commercial",
  [PORTFOLIO]: "Portfolio",
  [USDA_PURCHASE]: "USDA",
  [VA_PURCHASE]: "VA"
});
export default LOAN_TYPES;

export const REFINANCE_LOAN_TYPES = Object.freeze({
  [CONSTRUCTION]: "Construction",
  [FANNIEMAE]: "FannieMae",
  [FREDDIEMAC]: "FreddieMac",
  [FHA_PURCHASE]: "FHA",
  [FHA_STREAMLINE]: "FHA Streamline",
  [FIX_AND_FLIP]: "Fix and Flip",
  [HARD_MONEY]: "Hard Money",
  [JUMBO]: "Jumbo",
  [NON_QM]: "Non - QM",
  [OTHER]: "Small Commercial",
  [PORTFOLIO]: "Portfolio",
  [USDA_PURCHASE]: "USDA",
  [VA_PURCHASE]: "VA",
  [VA_IRRRL]: "VA IRRRL"
});
