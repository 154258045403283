import React, { useState } from "react";
import "./css/le_price_engine.scss";

import StandardTemplate from "../../templates/standard_template/standard_template";
import LoanEstimatePriceEngineMainPage from "../../components/loan_tools/loan_estimate_price_engine/loan_estimate_price_engine_main_page";
import EditLoanEstimate from "../../components/loan_tools/loan_estimate_price_engine/edit_loan_estimate";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { LE_PRICE_ENG } from "../../constants/navigations";
import NoLoans from "./no_loans";

export default function LePriceEnginePage() {
  const [document, setDocument] = useDocument();
  const [activeView, setActiveView] = useState("MAIN_PAGE");
  const [loanIndex, setLoanIndex] = useState();

  function onChangeApplicant(updatedState) {
    setDocument({
      ...document,
      applicant: {
        ...document.applicant,
        ...updatedState
      }
    });
  }

  function onChangeLoan(loan) {
    const loans = [...document.loans];
    loans[loanIndex] = loan;
    setDocument({
      ...document,
      loans
    });
  }

  function showEditLoan(loanIndex) {
    setActiveView("EDIT_LOAN");
    setLoanIndex(loanIndex);
  }

  function showMainPage() {
    setActiveView("MAIN_PAGE");
  }

  return (
    <StandardTemplate
      active={LE_PRICE_ENG}
      isDisableNavSave={activeView !== "MAIN_PAGE"}
    >
      <NoLoans />

      <div className="le-price-engine">
        {activeView === "MAIN_PAGE" && (
          <LoanEstimatePriceEngineMainPage
            onChangeApplicant={onChangeApplicant}
            showEditLoan={showEditLoan}
          />
        )}
        {activeView === "EDIT_LOAN" && (
          <EditLoanEstimate
            onChangeLoan={onChangeLoan}
            showMainPage={showMainPage}
            loanIndex={loanIndex}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
