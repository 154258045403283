import React from "react";
import "./css/adjustable_payment_table.css";
import { first_mortgage_payment_p_and_i } from "../../../calculations/loan";
import TextField from "../../fields/text_field";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import { useDocument } from "../loan_tool";
import Decimal from "decimal.js";

export default function AdjustablePaymentTable({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  return (
    <table className="adjustable-payment-table">
      <colgroup>
        <col />
        <col />
      </colgroup>
      <tbody>
        <tr>
          <td className="summary-header">Adjustable Payment (AP) Table</td>
          <td />
        </tr>
      </tbody>
      <tbody className="with-td-border">
        <tr>
          <td>Interest Only Payments?</td>
          <td>
            Yes for your first{" "}
            <TextField naked value={loan.interest_only_period} /> Payments
          </td>
        </tr>
        <tr>
          <td>Optional Payments?</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Step Payments?</td>
          <td>No</td>
        </tr>
        <tr>
          <td>Seasonal Payments?</td>
          <td>No</td>
        </tr>
        <tr>
          <td colSpan="2" className="bold">
            Monthly Principal and Interest Payments.
          </td>
        </tr>
        <tr>
          <td className="no-right-border">First Change/Amount</td>
          <td className="no-left-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />{" "}
            at{" "}
            <NumberField
              naked
              value={new Decimal(loan.interest_only_period).add(1)}
            />
            st payment
          </td>
        </tr>
        <tr>
          <td className="no-right-border">Subsequent Changes</td>
          <td className="no-left-border">No Subsequent Changes</td>
        </tr>
        <tr>
          <td className="no-right-border">Maximum Payment</td>
          <td className="no-left-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />{" "}
            Starting at{" "}
            <NumberField
              naked
              value={new Decimal(loan.interest_only_period).add(1)}
            />{" "}
            Payment
          </td>
        </tr>
      </tbody>
    </table>
  );
}
