import React, { useState, useEffect } from "react";
import UserService from "../../services/user_service";
import Section from "../ui/section";
import { get_role_names } from "../../constants/utils";
import { useUser } from "../../hooks/user";
import EditUser from "./edit_user";
import { LOAN_OFFICER, LOAN_PROCESSOR } from "../../constants/user_roles";
import { handleErrorMessages } from "../../services/utils";
import ErrorMessages from "../ui/error_messages";

export default function UserManagement() {
  const [{ companyProfile }] = useUser();
  const [users, setUsers] = useState([]);
  const [loanOfficerCount, setLoanOfficerCount] = useState(0);
  const [activeSection, setActiveSection] = useState("MANAGEMENT");
  const [errors, setErrors] = useState([]);

  const [editUserId, setEditUserId] = useState(0);
  const [editUserParentUserId, setEditUserParentUserId] = useState(0);
  const [editNewUser, setEditNewUser] = useState(true);
  const [editUserRole, setEditUserRole] = useState(LOAN_OFFICER);

  function loadUsers() {
    UserService.getCompanyUsers()
      .then(companyUsers => {
        setUsers(companyUsers);
        setLoanOfficerCount(
          companyUsers.filter(user => user.user.loan_officer_role).length
        );
      })
      .catch(error => handleErrorMessages(error, setErrors));
  }

  function editUser(role, isNewUser, userId, parentUserId) {
    setEditNewUser(isNewUser);
    setEditUserParentUserId(parentUserId);
    setEditUserRole(role);
    setEditUserId(userId || 0);
    setActiveSection("EDIT_USER");
  }

  function deleteUser(userId) {
    if (window.confirm("Are you sure you want to delete this user?")) {
      UserService.deleteCompanyUser(userId)
        .then(() => loadUsers())
        .catch(error => handleErrorMessages(error, setErrors));
    }
  }

  useEffect(() => {
    if (activeSection === "MANAGEMENT") {
      loadUsers();
    }
  }, [activeSection]);

  return (
    <div className="user-management">
      {activeSection === "MANAGEMENT" && (
        <Section name="User Management">
          <ErrorMessages messages={errors} />
          <button
            className="button"
            disabled={companyProfile.seat_limit <= loanOfficerCount}
            onClick={() => editUser(LOAN_OFFICER, true)}
          >
            Add Loan Officer
          </button>
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Role</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <React.Fragment key={user.user.id}>
                  <tr>
                    <td>{user.user.email}</td>
                    <td>{user.user.first_name}</td>
                    <td>{user.user.last_name}</td>
                    <td>
                      {get_role_names(user.user).map((roleName, index) => (
                        <div
                          key={`${user.user.id}_role_${index}`}
                          className="user-role"
                        >
                          {roleName}
                        </div>
                      ))}
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          editUser(LOAN_PROCESSOR, false, user.user.id)
                        }
                      >
                        Edit
                      </button>
                      {user.user.loan_officer_role && (
                        <button
                          className="button"
                          onClick={() =>
                            editUser(LOAN_PROCESSOR, true, 0, user.user.id)
                          }
                          disabled={
                            !user.loanProcessors ||
                            companyProfile.subaccount_limit <=
                              user.loanProcessors.length
                          }
                        >
                          Add Loan Processor
                        </button>
                      )}
                    </td>
                  </tr>
                  {user.loanProcessors &&
                    user.loanProcessors.map(loanProcessor => (
                      <tr key={loanProcessor.id} className="subaccount">
                        <td>
                          <span className="subaccount-arrow">&#8600;</span>
                          <span className="italic">{loanProcessor.email}</span>
                        </td>
                        <td>{loanProcessor.first_name}</td>
                        <td>{loanProcessor.last_name}</td>
                        <td>
                          {get_role_names(loanProcessor).map(
                            (roleName, index) => (
                              <div
                                key={`${loanProcessor.id}_role_${index}`}
                                className="user-role"
                              >
                                {roleName}
                              </div>
                            )
                          )}
                        </td>
                        <td>
                          <button
                            className="button"
                            onClick={() =>
                              editUser(LOAN_PROCESSOR, false, loanProcessor.id)
                            }
                          >
                            Edit
                          </button>
                          <button
                            className="button"
                            onClick={() => deleteUser(loanProcessor.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Section>
      )}
      {activeSection === "EDIT_USER" && (
        <EditUser
          role={editUserRole}
          isNewUser={editNewUser}
          userId={editUserId}
          parentUserId={editUserParentUserId}
          onExit={() => setActiveSection("MANAGEMENT")}
        />
      )}
    </div>
  );
}
