import React, { useState } from "react";
import "./css/loan_type.scss";

import StandardTemplate from "../../templates/standard_template/standard_template";

import LoanTypeMainPage from "../../components/loan_types/loan_type_main_page";
import EditLoanPage from "../../components/loan_types/edit_loan";

import { YES } from "../../constants/yes_no";

import { useDocument } from "../../components/loan_tools/loan_tool";
import { LOAN_TYPE } from "../../constants/navigations";
import { deep_clone_simple } from "../../constants/utils";
import { default_loan } from "../../schema/loan";

const sameLoanFields = [
  "type",
  "term",
  "arm_caps",
  "arm_margin",
  "arm_index_type",
  "arm_index_percent",
  "purpose",
  "jumbo_months_of_reserves",
  "jumbo_reserves_for_other_properties",
  "jumbo_arm_months_of_reserves"
];

export default function LoanTypePage() {
  const [document, setDocument] = useDocument();
  const [activeView, setActiveView] = useState("MAIN_PAGE");
  const [loanIndex, setLoanIndex] = useState();
  const { loans } = document;

  function showMainPage() {
    setActiveView("MAIN_PAGE");
    setLoanIndex(null);
  }

  function showEditLoan(loanIndex) {
    setActiveView("EDIT_LOAN");
    setLoanIndex(loanIndex);
  }

  function onChangeAllLoanTypesTheSame(updatedState) {
    const applicant = {
      ...document.applicant,
      ...updatedState
    };
    let loans = [...document.loans];
    if (applicant.all_loan_types_the_same === YES && loans.length > 0) {
      const firstLoan = loans[0];
      for (let i = 1; i < 5; i++) {
        loans[i] = loans[i] || deep_clone_simple(default_loan);
        sameLoanFields.forEach(field => (loans[i][field] = firstLoan[field]));
      }
    }
    setDocument({ ...document, applicant, loans });
  }

  function onChangeLoan(loan) {
    if (document.applicant.all_loan_types_the_same === YES) {
      const loans = [loan, { ...loan }, { ...loan }, { ...loan }, { ...loan }];
      setDocument({ ...document, loans });
    } else {
      const loans = [...document.loans];
      loans[loanIndex] = loan;
      setDocument({ ...document, loans });
    }
  }

  function onDeleteLoan(loanIndex) {
    if (document.applicant.all_loan_types_the_same === YES) {
      const loans = [];
      setDocument({ ...document, loans });
    } else {
      const loans = [...document.loans];
      loans.splice(loanIndex, 1);
      setDocument({ ...document, loans });
    }
  }

  return (
    <StandardTemplate
      active={LOAN_TYPE}
      isDisableNavSave={activeView !== "MAIN_PAGE"}
    >
      <div className="loan-type">
        {activeView === "MAIN_PAGE" && (
          <LoanTypeMainPage
            showEditLoan={showEditLoan}
            onChangeAllLoanTypesTheSame={onChangeAllLoanTypesTheSame}
            onDeleteLoan={onDeleteLoan}
          />
        )}
        {activeView === "EDIT_LOAN" && (
          <EditLoanPage
            loan={loans[loanIndex]}
            showMainPage={showMainPage}
            onChangeLoan={onChangeLoan}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
