import React, { useState, useEffect } from "react";

import CurrencyField from "../fields/currency_field";
import PercentField from "../fields/percent_field";

import {
  percent_increase_income,
  payment_to_year
} from "../../calculations/income";

export default function SocialSecurityIncomeCalculator({
  yearly_amount,
  monthly_amount,
  increase,
  onChange
}) {
  const [ssi, setSsi] = useState({
    monthly_income: monthly_amount || "0",
    percent_increase: increase || "25"
  });
  const { monthly_income, percent_increase } = ssi;
  const per_month_amount = percent_increase_income(
    monthly_income,
    percent_increase
  );

  function change(updatedState) {
    const { monthly_income } = updatedState.monthly_income ? updatedState : ssi;
    const { percent_increase } = updatedState.percent_increase
      ? updatedState
      : ssi;
    const per_month_amount = percent_increase_income(
      monthly_income,
      percent_increase
    );
    const yearly_amount = payment_to_year(per_month_amount, "12").toString();
    onChange({
      yearly_amount,
      monthly_amount: monthly_income,
      increase: percent_increase
    });
    setSsi({ ...ssi, ...updatedState });
  }

  useEffect(() => {
    // Just to update the Per Year calculation on mount.
    change({});
  }, []);

  return (
    <div>
      <div className="social-security-header">Social Security Income</div>
      <CurrencyField
        fieldName="monthly_income"
        name="Social Security Payment per Month"
        value={monthly_income}
        onChange={change}
      />

      <PercentField
        fieldName="percent_increase"
        name="Increase"
        value={percent_increase}
        onChange={change}
      />

      <CurrencyField name="Per Month" readOnly value={per_month_amount} />
      <CurrencyField name="Per Year" readOnly value={yearly_amount} />
    </div>
  );
}
