import React, { useEffect, useState, useRef } from "react";
import "./css/add_account.scss";

import { residence_total_payment } from "../../../calculations/debt_info";

import LIABILITY_TYPES, {
  PRIMARY_RESIDENCE,
  SECOND_HOME
} from "../../../constants/liability_types";
import generate_id from "../../../constants/id-generator";

import CurrencyField from "../../../components/fields/currency_field";
import SelectField from "../../../components/fields/select_field";
import TextField from "../../../components/fields/text_field";

import ResidenceReserveCalculator from "./residence_reserve_calculator";
import { useDocument } from "../../../components/loan_tools/loan_tool";
import Section from "../../../components/ui/section";
import { INCLUDE_IN_DTI } from "../../../constants/account_dispositions";
import { ACCOUNT_ID_PREFIX, RESIDENCE_ID_PREFIX } from "../../../constants/debt-info";
import { default_debt_info_account, default_debt_info_residence } from "../../../schema/debt_info";

export default function DebtInfoAddAccount({
  editAccountId,
  showAccountTable
}) {
  const [document, setDocument, , { saveDocument }] = useDocument();
  const { accountSummaries, residences, applicant, loans } = document;
  const { borrowers } = applicant;
  const [account, setAccount] = useState({
    ...default_debt_info_account,
    id: generate_id(ACCOUNT_ID_PREFIX)
  });
  const [residence, setResidence] = useState({
    ...default_debt_info_residence,
    id: generate_id(RESIDENCE_ID_PREFIX)
  });
  const {
    borrower,
    liability_type,
    description,
    account_number,
    monthly_payment,
    current_balance,
    high_credit_limit
  } = account;
  const callback = useRef();

  // update callback with current state values
  useEffect(() => {
    callback.current = () => {
      if (account.borrower && account.liability_type) {
        if (account.is_modified || residence.is_modified) {
          if (
            window.confirm(
              "You have unsaved data in account form. Would you like to save it before you leave?"
            )
          ) {
            save();
          } else {
            // do nothing
          }
        }
      } else {
        alert(
          "Not all necessary fields were entered. Therefore changes would be dismissed."
        );
      }
    };
  }, [account, residence]);

  useEffect(() => {
    //do nothing

    //cleanup
    return () => {
      callback.current();
    };
  }, []);

  const isResidence = needsResidenceInformation();
  const monthlyPayment = isResidence
    ? residence_total_payment(
      residence.first_mortgage,
      residence.second_mortgage,
      residence.taxes,
      residence.insurance,
      residence.hoa
    ).toString()
    : monthly_payment;

  const currentBalance = isResidence
    ? residence.principal_balance
    : current_balance;

  useEffect(() => {
    const accountToEdit = accountSummaries[editAccountId];
    if (editAccountId && accountToEdit) {
      setAccount(accountToEdit);
      if (accountToEdit.residence) {
        setResidence(residences[accountToEdit.residence]);
      }
    }
  }, []);

  function renderBorrower(borrower, index) {
    const borrowerName = `${borrower.first_name} ${borrower.last_name}`;
    return (
      <option key={borrowerName} value={index}>
        {borrowerName}
      </option>
    );
  }

  function needsResidenceInformation() {
    const liabilityTypes = [PRIMARY_RESIDENCE, SECOND_HOME];
    return liabilityTypes.includes(account.liability_type);
  }

  function updateAccount(newAccountState) {
    const accountEdit = {
      ...account,
      ...newAccountState,
      is_modified: true
    };
    setAccount(accountEdit);
  }

  function updateResidence(newResidence) {
    const residenceEdit = { ...residence, ...newResidence, is_modified: true };
    account.monthly_payment = residence_total_payment(
      residenceEdit.first_mortgage,
      residenceEdit.second_mortgage,
      residenceEdit.taxes,
      residenceEdit.insurance,
      residenceEdit.hoa
    ).toString();
    account.current_balance = residenceEdit.principal_balance;
    setAccount(account);
    setResidence(residenceEdit);
  }
  function save() {
    const isResidence = needsResidenceInformation();
    delete account.is_modified;
    delete residence.is_modified;

    if (isResidence) {
      account.residence = residence.id;
      residences[residence.id] = residence;
    } else {
      delete residences[account.residence];
      delete account.residence;
    }

    const accountSummariesEdit = {
      ...accountSummaries,
      [account.id]: account
    };

    const loansEdit = loans.map(loan => {
      const updatedLoan = { ...loan };
      updatedLoan.account_dispositions = {
        [account.id]: INCLUDE_IN_DTI,
        ...loan.account_dispositions
      };
      return updatedLoan;
    });

    setDocument({
      ...document,
      accountSummaries: accountSummariesEdit,
      loans: loansEdit,
      residences
    });
    saveDocument();
    showAccountTable();
  }

  return (
    <div className="debt-info-add-account">
      <Section name={editAccountId ? "Edit Account" : "Add Account"}>
        <div className="half-width">
          <SelectField
            id="borrower_name"
            name="Borrower"
            value={borrower}
            fieldName="borrower"
            onChange={updateAccount}
          >
            {borrowers.map(renderBorrower)}
          </SelectField>

          <SelectField
            id="liability_type"
            name="Liability Type"
            value={liability_type}
            fieldName="liability_type"
            onChange={updateAccount}
            selectOptions={LIABILITY_TYPES}
          />

          <TextField
            id="account_description"
            name="Company"
            value={description}
            fieldName="description"
            onChange={updateAccount}
          />

          <TextField
            id="account_number"
            name="Account number"
            value={account_number}
            fieldName="account_number"
            onChange={updateAccount}
            title="LANIS Training Tip: Only use the last 4 digits of the account number and cross reference it to the credit report."
          />

          <CurrencyField
            id="monthly_payment"
            name="Monthly Payment"
            readOnly={isResidence}
            value={monthlyPayment}
            fieldName="monthly_payment"
            onChange={updateAccount}
          />

          <CurrencyField
            name="Current Balance"
            value={currentBalance}
            readOnly={isResidence}
            fieldName="current_balance"
            onChange={updateAccount}
          />

          <CurrencyField
            name="High Credit Limit"
            value={high_credit_limit}
            fieldName="high_credit_limit"
            onChange={updateAccount}
          />

          {needsResidenceInformation() && (
            <ResidenceReserveCalculator
              residence={residence}
              onChange={updateResidence}
            />
          )}
        </div>

        <div>
          <button className="button" onClick={showAccountTable}>
            Cancel
          </button>
          <button className="button" onClick={save}>
            Save
          </button>
        </div>
      </Section>
    </div>
  );
}
