import Decimal from "decimal.js";

/**
 * @param {string|null|Decimal} value
 * @param {string|null|Decimal} defaultValue
 * @return {Decimal|null}
 */
export function parseFnmDecimal(value, defaultValue = null) {
  if (value) {
    return new Decimal(value);
  } else if (defaultValue) {
    return new Decimal(defaultValue);
  }
  return null;
}

/**
 * @param {string|null|Decimal} value
 * @param {number} decimalPlaces
 * @return {string|null}
 */
export function formatFnmDecimal(value, decimalPlaces = 2) {
  if (value) {
    const num = new Decimal(value);
    if (!num.isZero() && !num.isNaN()) {
      return num.toFixed(decimalPlaces).toString();
    }
  }
  return null;
}

/**
 * @param {string|null|Decimal} value
 * @param {string|null|Decimal} defaultValue
 * @return {Decimal|null}
 */
export function filterParseFnmDecimal(value, defaultValue = null) {
  return parseFnmDecimal(formatFnmDecimal(value), defaultValue);
}
