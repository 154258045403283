import {
  ANNUAL,
  MONTHLY,
  MONTHLY_24,
  MONTHLY_26,
  WEEKLY,
  PROFESSOR_TEACHER,
  HOURLY
} from "../constants/income_calculator_types";

export const default_employment_information = {
  employer_name: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  position: "",
  start_date: "",
  line_of_work_years: "0",
  ownership: "",
  employed_by_party_to_transaction: ""
};

export const default_income_source = {
  calculator: "",
  year_0: new Date().getFullYear().toString(),
  year_1: (new Date().getFullYear() - 1).toString(),
  description: "",
  w2_or_self_employed: "",
  years_on_the_current_job: "0",
  months_on_the_current_job: "0",
  base_income_0: "0",
  overtime_0: "0",
  bonuses_0: "0",
  commission_0: "0",
  base_income_1: "0",
  overtime_1: "0",
  bonuses_1: "0",
  commission_1: "0",
  employment_information: default_employment_information
};

export const default_annual_source = {
  ...default_income_source,
  calculator: ANNUAL
};

export const default_monthly_source = {
  ...default_income_source,
  calculator: MONTHLY
};

export const default_weekly_source = {
  ...default_income_source,
  calculator: WEEKLY,
  paymentsPerYear: "52"
};

export const default_monthly_24_source = {
  ...default_income_source,
  calculator: MONTHLY_24,
  paymentsPerYear: "24"
};

export const default_monthly_26_source = {
  ...default_income_source,
  calculator: MONTHLY_26,
  paymentsPerYear: "26"
};

export const default_teacher_source = {
  ...default_income_source,
  calculator: PROFESSOR_TEACHER,
  number_of_months_0: "12",
  number_of_months_1: "12"
};

export const default_hourly_source = {
  ...default_income_source,
  calculator: HOURLY,
  base_hours_per_week: "40",
  base_weeks_per_year: "52",
  overtime_hours_per_week: "1",
  overtime_weeks_per_year: "1"
};
