import React from "react";
import DateField from "../../fields/date_field";
import { borrower_names, truthy_join } from "../../../constants/utils";
import TITLE_VESTING from "../../../constants/title_vesting";
import TimeField from "../../fields/time_field";
import SelectField from "../../fields/select_field";
import { useDocument } from "../../loan_tools/loan_tool";

export default function LoanSelection({ updateSummary }) {
  const [
    { loans, summary, applicant, property },
    ,
    { documentAccountProfile }
  ] = useDocument();
  const borrowerNames = borrower_names(applicant.borrowers);
  const loanSelections = loans.reduce((selections, loan, index) => {
    selections[String(index + 1)] = String(index + 1);
    return selections;
  }, {});
  const loanOfficerName = truthy_join(
    [
      documentAccountProfile.first_name,
      documentAccountProfile.middle_name,
      documentAccountProfile.last_name
    ],
    " "
  );

  return (
    <>
      <tbody>
        <tr>
          <td colSpan="8">
            <div>
              Appraisal fees will not be able to be charged, nor credit card
              information collected, prior to borrower confirmation of the
              intent to proceed with the loan after issuance of the Loan
              Estimate, which must be delivered to the consumer within three
              days of application.
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="8">
            <div>
              Please indicate which loan you intend to Proceed with. Once you
              have selected the appropriate Loan, we will issue a formal Loan
              Estimate and Loan Disclosures.
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="8">
            <div className="no-print loan-selection flex-space-between">
              <SelectField
                emptyDefault
                showDefaultOption
                name="I / We select Loan Number:"
                fieldName="selection_loan_number"
                value={summary.selection_loan_number}
                selectOptions={loanSelections}
                onChange={updateSummary}
              />
              <DateField
                name="Date:"
                fieldName="selection_date"
                value={summary.selection_date}
                onChange={updateSummary}
              />
              <TimeField
                name="Time:"
                fieldName="selection_time"
                value={summary.selection_time}
                onChange={updateSummary}
              />
            </div>
            <div className="print-only-flex loan-selection flex-space-between">
              <div>
                <span>I / We select Loan Number:</span>
                <span className="printable gray-bg">
                  &nbsp;
                  <SelectField
                    emptyDefault
                    naked
                    value={summary.selection_loan_number}
                    selectOptions={loanSelections}
                  />
                  &nbsp;
                </span>
              </div>
              <div>
                <span>Date:</span>
                <span className="printable gray-bg">
                  &nbsp;
                  <DateField naked value={summary.selection_date} />
                  &nbsp;
                </span>
              </div>
              <div>
                <span>Time:</span>
                <span className="printable gray-bg">
                  &nbsp;
                  <TimeField naked value={summary.selection_time} />
                  &nbsp;
                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-line" />
              <div className="signature-line" />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>Borrower</div>
                <div>Date</div>
              </div>
              <div className="signature-block">
                <div>Borrower</div>
                <div>Date</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>{borrowerNames[0]}</div>
              </div>
              <div className="signature-block">
                <div>{borrowerNames[1]}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-line" />
              <div className="signature-line" />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>Borrower</div>
                <div>Date</div>
              </div>
              <div className="signature-block">
                <div>Borrower</div>
                <div>Date</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>{borrowerNames[2]}</div>
              </div>
              <div className="signature-block">
                <div>{borrowerNames[3]}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          {/* Formatted this way to match the row below due to chrome border bug */}
          <td colSpan="3" />
          <td colSpan="3" />
          <td colSpan="2" />
        </tr>
        <tr>
          <td className="with-border" colSpan="3">
            Manner in which title will be held.
          </td>
          <td className="with-border" colSpan="3">
            {TITLE_VESTING[property.title_vesting]}
          </td>
          <td colSpan="2" />
        </tr>
        <tr>
          {/* Formatted this way to match the row below due to chrome border bug */}
          <td colSpan="3" />
          <td colSpan="3" />
          <td colSpan="2" />
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-line" />
              <div className="signature-line" />
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>{loanOfficerName}</div>
                <div>Date</div>
              </div>
              <div className="signature-block">
                <div>{loanOfficerName}</div>
                <div>Date</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan="8">
            <div className="signature-container">
              <div className="signature-block">
                <div>Loan Officer</div>
              </div>
              <div className="signature-block">
                <div>Broker</div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td className="gray-bg" colSpan="8" />
        </tr>
      </tbody>
    </>
  );
}
