import React from "react";
import CurrencyField from "../../fields/currency_field";
import {
  d_plus_i,
  lender_credits,
  total_closing_costs
} from "../../../calculations/loan";
import { useDocument } from "../loan_tool";

export default function TotalClosingCosts({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const lenderCredits = lender_credits(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );

  return (
    <div className="total-closing-costs">
      <div className="closing-costs-row top-border bottom-border gray-bg">
        <div className="section-title">J. Total Closing Costs</div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_closing_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      <div className="closing-costs-row">
        <div>D + I</div>
        <CurrencyField
          className="closing-costs-cell right-align gray-bg"
          naked
          fixedDigits={0}
          value={d_plus_i(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </div>
      {lenderCredits.lt(0) && (
        <div className="closing-costs-row">
          <div>Lender Credits</div>
          <CurrencyField
            className="closing-costs-cell right-align gray-bg"
            naked
            fixedDigits={0}
            value={lenderCredits}
          />
        </div>
      )}
    </div>
  );
}
