export const CASH_OUT_DEBT_CONSOLIDATION = "1";
export const CASH_OUT_HOME_IMPROVEMENT = "2";
export const LIMITED_CASH_OUT_RATE_TERM = "3";
export const CASH_OUT_OTHER = "4";
export const CHANGE_IN_RATE_TERM = "5";
export const NO_CASH_OUT_FHA_STREAMLINED_REFINANCE = "6";
export const NO_CASH_OUT_OTHER = "7";

export default Object.freeze({
  [CASH_OUT_DEBT_CONSOLIDATION]: "Cash-Out/Debt Consolidation",
  [CASH_OUT_HOME_IMPROVEMENT]: "Cash-Out/Home Improvement",
  [LIMITED_CASH_OUT_RATE_TERM]: "Limited Cash-Out Rate/Term",
  [CASH_OUT_OTHER]: "Cash-Out/Other",
  [CHANGE_IN_RATE_TERM]: "Change in Rate Term",
  [NO_CASH_OUT_FHA_STREAMLINED_REFINANCE]:
    "No Cash-Out FHA Streamlined Refinance",
  [NO_CASH_OUT_OTHER]: "No Cash-Out/Other"
});
