import React from "react";

import {
  year_to_payment,
  compute_total_income,
  investment_properties_yearly_amount,
  investment_properties_monthly_amount
} from "../../calculations/income";

import BorrowerIncomeInformation from "./borrower_income_information";

import CurrencyField from "../fields/currency_field";

import { useDocument } from "../loan_tools/loan_tool";
import Section from "../ui/section";
import { wrap_investment_properties } from "../../calculations/investment_reo";

export default function BorrowerIncomeTable({
  showEditIncomeSource,
  showEditAdditionalIncome,
  onChangeBorrowerIncome
}) {
  const [{ investment_properties, applicant, property }] = useDocument();
  const { borrowers } = applicant;
  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties);
  const investReoMonthly = investment_properties_monthly_amount(
    wrapped_investment_properties
  );
  const investReoYearly = investment_properties_yearly_amount(
    wrapped_investment_properties
  );

  return (
    <div>
      <div>
        {borrowers.map((borrower, index) => (
          <BorrowerIncomeInformation
            key={index}
            borrower={borrower}
            borrowerIndex={index}
            showEditAdditionalIncome={showEditAdditionalIncome}
            showEditIncomeSource={showEditIncomeSource}
            onChangeBorrowerIncome={onChangeBorrowerIncome}
          />
        ))}
      </div>
      {borrowers.length !== 0 && (
        <Section name="Summary" className="income-summary half-width">
          {investReoMonthly.gt(0) && (
            <CurrencyField
              readOnly
              name="Positive Income from Investment REOs Monthly"
              value={investReoMonthly}
            />
          )}
          {investReoYearly.gt(0) && (
            <CurrencyField
              readOnly
              name="Positive Income from Investment REOs Yearly"
              value={investReoYearly}
            />
          )}
          <CurrencyField
            readOnly
            name="Combined Monthly Total of ALL Income"
            value={year_to_payment(
              compute_total_income(borrowers, wrapped_investment_properties),
              12
            )}
          />
          <CurrencyField
            readOnly
            name="Combined Yearly Total of ALL Income"
            value={compute_total_income(borrowers, wrapped_investment_properties)}
          />
        </Section>
      )}
    </div>
  );
}
