import React, { useEffect, useState, useRef } from "react";

import { YES_NO } from "../../constants/yes_no";
import OWNERSHIP_OPTIONS from "../../constants/ownership_options";
import { default_employment_information } from "../../schema/income_source";

import SelectField from "../fields/select_field";
import DateField from "../fields/date_field";
import TextField from "../fields/text_field";
import Section from "../ui/section";
import { useDocument } from "../loan_tools/loan_tool";
import PhoneField from "../fields/phone_field";

export default function EditEmploymentInformation({
  borrower,
  employmentInformation,
  incomeSourceIndex,
  onChangeBorrowerIncome,
  backToTable
}) {
  const [,,,{ saveDocument }] = useDocument();
  const [employmentInformationEdit, setEmploymentInformationEdit] = useState({
    ...default_employment_information,
    ...employmentInformation
  });
  const {
    employer_name,
    phone,
    address,
    city,
    state,
    zip,
    position,
    start_date,
    ownership,
    employed_by_party_to_transaction
  } = employmentInformationEdit;
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (employmentInformationEdit.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Employment Information Form. Would you like to save it before you leave?"
          )
        ) {
          onSave();
        } else {
          // do nothing
        }
      }
    };
  }, [employmentInformationEdit]);

  useEffect(() => {
    //do nothing

    //cleanup
    return () => {
      callback.current();
    };
  }, []);

  function onChange(updatedEntry) {
    setEmploymentInformationEdit({
      ...employmentInformationEdit,
      ...updatedEntry,
      is_modified: true
    });
  }

  function onCancel() {
    backToTable();
  }

  function onSave() {
    delete employmentInformationEdit.is_modified;
    const sources = [...borrower.income.sources];
    sources[incomeSourceIndex] = {
      ...sources[incomeSourceIndex],
      employment_information: employmentInformationEdit
    };
    onChangeBorrowerIncome({ sources });
    saveDocument();
    backToTable();
  }

  return (
    <Section
      className="income-employment-information"
      name="Employment Information"
    >
      <div className="employment-information-fields">
        <TextField
          fieldName="employer_name"
          value={employer_name}
          name="Employer or Business Name"
          onChange={onChange}
        />
        <PhoneField
          fieldName="phone"
          name="Phone"
          value={phone}
          onChange={onChange}
        />
        <TextField
          fieldName="address"
          value={address}
          name="Address"
          onChange={onChange}
        />
        <TextField
          fieldName="city"
          value={city}
          name="City"
          onChange={onChange}
        />
        <TextField
          fieldName="state"
          value={state}
          name="State"
          onChange={onChange}
        />
        <TextField fieldName="zip" value={zip} name="Zip" onChange={onChange} />
        <TextField
          fieldName="position"
          value={position}
          name="Position or Title"
          onChange={onChange}
        />
        <DateField
          fieldName="start_date"
          value={start_date}
          name="Start Date (mm/yyyy)"
          onChange={onChange}
        />
        <SelectField
          fieldName="ownership"
          value={ownership}
          name="Check if you are the Business owner or Self-Employed"
          selectOptions={OWNERSHIP_OPTIONS}
          onChange={onChange}
        />

        <SelectField
          fieldName="employed_by_party_to_transaction"
          value={employed_by_party_to_transaction}
          name="I am employed by a family member, property seller, real estate agent, or other party to the transaction"
          onChange={onChange}
          selectOptions={YES_NO}
        />
      </div>

      <button className="button" onClick={onCancel}>
        Cancel
      </button>
      <button className="button" onClick={onSave}>
        Save
      </button>
    </Section>
  );
}
