import config from "../config/config.js";
import { getHeaders, handleResponse } from "./utils";

const SystemAdminService = {
  async getCompanies() {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + "admin/companies", {
      method: "GET",
      headers
    });
    return handleResponse(response);
  },

  async getCompany(companyId) {
    const headers = getHeaders();
    const response = await fetch(
      config.serviceHost + `admin/company/${companyId}`,
      {
        method: "GET",
        headers
      }
    );
    return handleResponse(response);
  },

  async postCompany(companyId, companyProfile, seatLimit, subscriptionStatus) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("companyProfile", JSON.stringify(companyProfile));
    data.append("seatLimit", seatLimit);
    data.append("subscriptionStatus", subscriptionStatus);
    const response = await fetch(
      config.serviceHost + `admin/company/${companyId}`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  },

  async postNewCompany(
    companyProfile,
    adminEmail,
    password,
    adminFirstName,
    adminLastName,
    accountProfile,
    loanOfficerRole
  ) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("companyProfile", JSON.stringify(companyProfile));
    data.append("adminEmail", adminEmail);
    data.append("password", password);
    data.append("adminFirstName", adminFirstName);
    data.append("adminLastName", adminLastName);
    data.append("accountProfile", JSON.stringify(accountProfile));
    data.append("loanOfficerRole", loanOfficerRole);
    const response = await fetch(config.serviceHost + `admin/company`, {
      method: "POST",
      body: data,
      headers
    });
    return handleResponse(response);
  },

  async postAddMultipleUsers(companyId, csvFile) {
    const headers = getHeaders();
    const data = new FormData();
    data.append("csvFile", csvFile);
    const response = await fetch(
      config.serviceHost + `admin/add-multiple-users/${companyId}`,
      {
        method: "POST",
        body: data,
        headers
      }
    );
    return handleResponse(response);
  }
};

export default SystemAdminService;
