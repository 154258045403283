import React, { useState } from "react";
import { Link } from "@reach/router";
import tierOneLogo from "../../images/Lanis_TierOne.png";

import "./css/header.scss";
import { useUser } from "../../hooks/user";

export default function Header({ showMoreLinks }) {
  if (!useUser()) {
    return (
      <section className="sitenav">
        <div className="brand">
          <Link to="/">
            <img className="tier-one-logo" src={tierOneLogo} alt="TIER|ONE" />
          </Link>
        </div>
      </section>
    );
  }

  const [setErrorMessage] = useState("");
  const [userState, { logout }] = useUser();

  return (
    <section className="sitenav">
      <div className="brand">
        <Link to="/">
          <img className="tier-one-logo" src={tierOneLogo} alt="TIER|ONE" />
        </Link>
      </div>
      {userState.isLoggedIn && (
        <div className="header-links">
          {showMoreLinks && (
            <>
              {userState.accountProfile.system_admin_role && (
                <Link to="/system-admin-panel" className="button admin-button">
                  System Admin Panel
                </Link>
              )}
              {userState.accountProfile.company_admin_role && (
                <Link to="/admin-panel" className="button admin-button">
                  Admin Panel
                </Link>
              )}
              <Link to="/account-profile" className="button">
                My Account
              </Link>
            </>
          )}
          <Link
            to="/"
            className="button"
            onClick={() => logout(setErrorMessage)}
          >
            Log Out
          </Link>
        </div>
      )}
    </section>
  );
}
