import React, { useEffect, useState } from "react";
import "./css/company_admin_panel.scss";

import StandardTemplate from "../templates/standard_template/standard_template";
import { useUser } from "../hooks/user";
import { Link, navigate } from "@reach/router";
import UserManagement from "../components/company_admin_panel/user_management";
import Unsubscribe from "../components/company_admin_panel/unsubscribe";
import { class_list } from "../constants/utils";
import CompanyInformation from "../components/company_admin_panel/company_information";

export default function CompanyAdminPanel() {
  const [{ accountProfile, companyProfile }, { getProfile }] = useUser();
  const [activeTab, setActiveTab] = useState("MAIN");

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    // Access check once we receive the account profile info.
    // (user_id should not be 0)
    if (accountProfile.user_id && !accountProfile.company_admin_role) {
      navigate("/");
    }
  }, [accountProfile]);

  if (!accountProfile.company_admin_role) {
    return <StandardTemplate basic showMoreLinks />;
  }

  if (!companyProfile) {
    return (
      <StandardTemplate basic showMoreLinks>
        <p>No Company Found</p>
      </StandardTemplate>
    );
  }

  return (
    <StandardTemplate basic showMoreLinks className="company-admin-panel">
      <div className="top-header-section">
        <div className="top-header-section-title">ADMIN PANEL</div>
        <button
          className={class_list(
            "button",
            "admin-button",
            activeTab === "MAIN" && "active"
          )}
          onClick={() => setActiveTab("MAIN")}
        >
          Company Information
        </button>
        <button
          className={class_list(
            "button",
            "admin-button",
            activeTab === "USER_MANAGEMENT" && "active"
          )}
          onClick={() => setActiveTab("USER_MANAGEMENT")}
        >
          User Management
        </button>
        {activeTab === "MAIN" && companyProfile.normal_subscription && (
          <div className="subscription-panel">
            {companyProfile.subscription_active && (
              <button
                className="button"
                onClick={() => setActiveTab("UNSUBSCRIBE")}
              >
                Unsubscribe
              </button>
            )}
            {!companyProfile.subscription_active && (
              <Link className="button" to="/subscribe">
                Subscribe
              </Link>
            )}
          </div>
        )}
      </div>
      {activeTab === "MAIN" && <CompanyInformation />}
      {activeTab === "USER_MANAGEMENT" && <UserManagement />}
      {activeTab === "UNSUBSCRIBE" && (
        <Unsubscribe onExit={() => setActiveTab("MAIN")} />
      )}
    </StandardTemplate>
  );
}
