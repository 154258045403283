import { HOA_PROPERTY_TYPES } from "./property_type";
import { ARM, ARM_TERMS, FIXED, LOAN_TERMS } from "./loan_terms";
import Decimal from "decimal.js";
import { YES } from "./yes_no";
import { MARRIED } from "./marital_status";
import { default_metadata } from "../schema/metadata";
import {
  current_second_loan_amount,
  current_third_loan_amount,
  loan_amount_first_mortgage,
  loan_amount_second_mortgage,
  ltv_first_td
} from "../calculations/loan";
import { LTV_MORTGAGE_INSURANCE_PERCENT_MINIMUM } from "./fixed_values";
import {
  CONVENTIONAL_LOANS,
  FHA_PURCHASE,
  FHA_STREAMLINE,
  JUMBO,
  USDA_PURCHASE,
  VA_IRRRL,
  VA_PURCHASE
} from "./loan_types";
import moment from "moment-timezone";
import { REFINANCE } from "./document_types";
import { COMPANY_ADMIN, LOAN_OFFICER, LOAN_PROCESSOR } from "./user_roles";
import USER_ROLES from "./user_roles";

/**
 * Joins the array with the separator but skipping falsy entries.
 */
export function truthy_join(stringArray, separator) {
  return (stringArray || []).reduce(
    (result, part) => result + (!part ? "" : result ? separator + part : part),
    ""
  );
}

/**
 * Return a new clone of the given value. If the value is an object or array,
 * a new object or array is created and values are copied into the clone. The
 * values are also recursively passed to this function, meaning all nested
 * objects and arrays are cloned. If the value is not a basic array or object,
 * the value is returned without modification.
 * @template T
 * @param {T} value
 * @return {T}
 */
export function deep_clone_simple(value) {
  if (Array.isArray(value)) {
    const clone = [];
    for (const arrItem of value) {
      clone.push(deep_clone_simple(arrItem));
    }
    return clone;
  }

  if (typeof value === 'object' && !!value) {
    const clone = {};
    for (const [key, objValue] of Object.entries(value)) {
      clone[key] = deep_clone_simple(objValue);
    }
    return clone;
  }

  return value;
}

export function borrower_name(borrower) {
  if (!borrower) {
    return "";
  }
  return truthy_join(
    [
      borrower.first_name,
      borrower.middle_name,
      borrower.last_name,
      borrower.suffix
    ],
    " "
  );
}

export function borrower_names(borrowers) {
  return (borrowers || []).reduce((names, borrower) => {
    names.push(borrower_name(borrower));
    return names;
  }, []);
}

export function show_hoas(property) {
  return HOA_PROPERTY_TYPES.includes(property.property_type);
}

export function loan_term_name(loanTerm) {
  return (
    LOAN_TERMS[loanTerm] + (ARM_TERMS.includes(loanTerm) ? "" : " Year Fix")
  );
}

export function loan_arm_fix(loanTerm) {
  if (ARM_TERMS.includes(loanTerm)) {
    return ARM;
  }
  return FIXED;
}

/**
 * Returns the loan selected in the summary sheet.
 */
export function summary_selected_loan(loans, summary) {
  return loans[new Decimal(summary.selection_loan_number || 0).toNumber() - 1];
}

export function is_government_fee_financed_into_loan(loan) {
  if (is_fha(loan.type) || is_usda(loan.type)) {
    return loan.is_ufmip_financed === YES;
  }
  if (is_va(loan.type)) {
    return new Decimal(loan.va_add_to_loan).gt(1);
  }
  return false;
}

export function borrower_spouse(borrowers, borrower) {
  return borrower.marital_status === MARRIED
    ? borrowers[borrower.married_to]
    : undefined;
}

/**
 * Browser download with provided data.
 * https://stackoverflow.com/a/18197341
 */
export function download(filename, text) {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function metadata(documentType, loan_document, base_metadata) {
  const metadata = base_metadata || default_metadata;
  const loan =
    summary_selected_loan(loan_document.loans, loan_document.summary) ||
    loan_document.loans[0];

  metadata.borrower_names = borrower_names(loan_document.applicant.borrowers);
  if (loan) {
    metadata.lender = loan.lender;
    metadata.loan_type = loan.type;
    metadata.first_loan_amount = loan_amount_first_mortgage(
      documentType,
      loan,
      loan_document.property,
      loan_document.property_to_be_sold,
      loan_document.is_selling_property
    ).toString();
    metadata.second_loan_amount = loan_amount_second_mortgage(
      documentType,
      loan,
      loan_document.property,
      loan_document.property_to_be_sold,
      loan_document.is_selling_property
    ).toString();
  }
  metadata.locked_exp_date =
    loan_document.applicant.loan_locked === YES
      ? add_days(
        loan_document.applicant.loan_lock_date,
        loan_document.applicant.pricing_lock_period
      )
      : "";
  metadata.time_sensitive = loan_document.property.time_sensitive;
  return metadata;
}

export function is_show_lpmi_adjustment_fees(documentType, loan, property) {
  return (
    ltv_first_td(documentType, loan, property).gt(
      LTV_MORTGAGE_INSURANCE_PERCENT_MINIMUM
    ) &&
    loan.mortgage_insurance_is_lender_paid === YES &&
    (CONVENTIONAL_LOANS.includes(loan.type) || loan.type === JUMBO)
  );
}

/**
 * Helper to define multiple css classes.
 */
export function class_list(...classes) {
  return classes.filter(item => !!item).join(" ");
}

/**
 * Helper to determine is a nav button is considered marked.
 */
export function is_nav_marked(navArray, activeNav, targetNav) {
  return navArray.indexOf(activeNav) > navArray.indexOf(targetNav);
}

export function is_fha(loanType) {
  return [FHA_PURCHASE, FHA_STREAMLINE].includes(loanType);
}

export function is_usda(loanType) {
  return [USDA_PURCHASE].includes(loanType);
}

export function is_va(loanType) {
  return [VA_PURCHASE, VA_IRRRL].includes(loanType);
}

export function has_broker_loan(loans) {
  return loans.some(loan => loan.brokered_loan === YES);
}

/**
 * Adding days to a date field value.
 * Expecting dateValue to be YYYY-MM-DD.
 */
export function add_days(dateValue, days) {
  if (!dateValue || (days !== 0 && !days)) {
    return "";
  }
  return moment(dateValue, "YYYY-MM-DD")
    .add(days, "days")
    .format("YYYY-MM-DD");
}

/**
 * Getting the bounding info for the navigation lines.
 */
export function get_bounding_offset_rect(element) {
  const width = element.offsetWidth;
  const height = element.offsetHeight;
  let top = element.offsetTop;
  let left = element.offsetLeft;
  while ((element = element.offsetParent)) {
    if (element.classList.contains("standard-template-content")) {
      break;
    }
    top += element.offsetTop;
    left += element.offsetLeft;
  }
  return {
    width,
    height,
    top,
    left
  };
}

/**
 * Returns the loan product name.
 */
export function loan_product(term) {
  if (!term) {
    return "";
  }
  return LOAN_TERMS[term] + (ARM_TERMS.includes(term) ? "" : " Years Fix");
}

/**
 * Refinance - Will 2nd Subordinate logic.
 */
export function will_second_subordinate(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (documentType !== REFINANCE || is_selling_property !== YES) {
    return false;
  }
  return (
    (current_second_loan_amount(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    ).gt(0) ||
      new Decimal(property_to_be_sold.second_current_monthly_payment).gt(0)) &&
    loan.will_second_subordinate === YES
  );
}

/**
 * Refinance - Will 3rd Subordinate logic.
 */
export function will_third_subordinate(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (documentType !== REFINANCE || is_selling_property !== YES) {
    return false;
  }
  return (
    (current_third_loan_amount(
      loan,
      property_to_be_sold,
      is_selling_property
    ).gt(0) ||
      new Decimal(property_to_be_sold.miscellaneous_housing_payment).gt(0)) &&
    loan.will_third_subordinate === YES
  );
}

/**
 * Profile - Gets a list of user roles for the given profile.
 */
export function get_role_names(accountProfile) {
  const roles = [];
  if (accountProfile.company_admin_role) {
    roles.push(USER_ROLES[COMPANY_ADMIN]);
  }
  if (accountProfile.loan_officer_role) {
    roles.push(USER_ROLES[LOAN_OFFICER]);
  }
  if (accountProfile.loan_processor_role) {
    roles.push(USER_ROLES[LOAN_PROCESSOR]);
  }
  return roles;
}

export function is_valid_email(email) {
  const reg = /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_+\-.])+\.([A-Za-z]{2,4})$/;
  return reg.test(email);
}

export function formatPhone(value) {
  let formattedValue = value ? value.replace(/[^\d]/g, "") : "";
  if (formattedValue.length) {
    formattedValue =
        `(${formattedValue.slice(0, 3)}) ` +
        `${formattedValue.slice(3, 6)}-${formattedValue.slice(6, 10)}`;
  }
  return formattedValue;
}

export function formatSsn(value) {
  let formattedValue = value ? value.replace(/[^\d]/g, "") : "";
  if (formattedValue.length) {
    formattedValue =
        `${formattedValue.slice(0, 3)}-` +
        `${formattedValue.slice(3, 5)}-${formattedValue.slice(5, 9)}`;
  }
  return formattedValue;
}
