export const COMMUNITY_PROPERTY = "0";
export const JOINT_TENANTS = "1";
export const MARRIED_MAN_SOLE_SEPARATE = "2";
export const MARRIED_WOMAN_SOLE_SEPARATE = "3";
export const SINGLE_MAN = "4";
export const TENANTS_IN_COMMON = "5";
export const TBD_ESCROW = "TBD";
export const UNMARRIED_WOMAN = "7";
export const UNMARRIED_WOMAN_SOLE_SEPARATE_PROPERTY = "8";
export const MARRIED_MAN = "9";
export const MARRIED_WOMAN = "10";
export const SINGLE_WOMAN = "11";
export const UNMARRIED_MAN = "12";
export const UNMARRIED_MAN_SOLE_SEPARATE_PROPERTY = "13";

export default Object.freeze({
  [COMMUNITY_PROPERTY]: "Community Property",
  [JOINT_TENANTS]: "Joint Tenants",
  [MARRIED_MAN]: "Married Man",
  [MARRIED_MAN_SOLE_SEPARATE]: "Married Man Sole and Separate",
  [MARRIED_WOMAN]: "Married Woman",
  [MARRIED_WOMAN_SOLE_SEPARATE]: "Married Woman Sole and Separate",
  [SINGLE_MAN]: "Single Man",
  [SINGLE_WOMAN]: "Single Woman",
  [TENANTS_IN_COMMON]: "Tenants in common",
  [TBD_ESCROW]: "TBD - in Escrow",
  [UNMARRIED_MAN]: "Unmarried Man",
  [UNMARRIED_MAN_SOLE_SEPARATE_PROPERTY]:
    "Unmarried Man Sole and Separate Property",
  [UNMARRIED_WOMAN]: "Unmarried Woman",
  [UNMARRIED_WOMAN_SOLE_SEPARATE_PROPERTY]:
    "Unmarried Woman Sole and Separate Property"
});
