import React, { useState } from "react";

import "./css/text_field.scss";
import TrainingTip from "../ui/training_tip";

/**
 * TextField is suitable for rendering a text-input component.
 */
export default function TextField({
  className,
  onChange,
  fieldName,
  naked,
  id,
  title,
  tabIndex,
  readOnly,
  placeholder,
  datalist,
  name,
  value,
  typeOverride,
  onBlur,
  onBlurChange,
  onKeyPress,
  showRequired
}) {
  const [isBlurChanged, setBlurChanged] = useState(false);

  function class_name() {
    let classname = "input-field text-field";

    if (className) {
      classname += ` ${className}`;
    }
    return classname;
  }

  function onClick(event) {
    if (!readOnly) {
      event.target.select();
    }
  }

  function change(event) {
    setBlurChanged(true);
    if (onChange && fieldName) {
      onChange({
        [fieldName]: event.target.value
      });
    }
  }

  function onBlurred() {
    if (isBlurChanged && onBlurChange && fieldName) {
      onBlurChange({ [fieldName]: value });
    }
    if (onBlur) {
      onBlur();
    }
    setBlurChanged(false);
  }

  if (naked) {
    return <span className={className}>{value}</span>;
  }

  return (
    <div className="input-field-container text-field-container">
      <label htmlFor={id}>
        {name}
        {showRequired && <span className="required-asterisk">*</span>}
        {title && (
          <>
            {" "}
            <TrainingTip content={title} />
          </>
        )}
      </label>

      <input
        id={id}
        type={typeOverride ? typeOverride : "text"}
        value={value || ""}
        onChange={change}
        onBlur={onBlurred}
        step="any"
        tabIndex={tabIndex}
        className={class_name()}
        readOnly={readOnly}
        onClick={onClick}
        onKeyPress={onKeyPress}
        placeholder={placeholder}
        list={id && id + "-datalist"}
      />
      {datalist && (
        <datalist id={id + "-datalist"}>
          {datalist.map(value => (
            <option key={value} value={value} />
          ))}
        </datalist>
      )}
    </div>
  );
}
