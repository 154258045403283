import React, { useState } from "react";
import "./css/system_admin_panel.scss";

import StandardTemplate from "../templates/standard_template/standard_template";
import CompanyManagement from "../components/system_admin_panel/company_management";
import { useUser } from "../hooks/user";

export default function SystemAdminPanel() {
  const [{ accountProfile }] = useUser();
  const [activeTab] = useState("COMPANY_MANAGEMENT");

  if (!accountProfile.system_admin_role) {
    return <></>;
  }

  return (
    <StandardTemplate basic showMoreLinks className="system-admin-panel">
      <div className="top-header-section">
        <div className="top-header-section-title">SYSTEM ADMIN PANEL</div>
        <button className="button admin-button active">
          Company Management
        </button>
      </div>
      {activeTab === "COMPANY_MANAGEMENT" && <CompanyManagement />}
    </StandardTemplate>
  );
}
