export const COMPANY_ADMIN = 500;
export const LOAN_OFFICER = 110;
export const LOAN_PROCESSOR = 100;

const USER_ROLES = Object.freeze({
  [COMPANY_ADMIN]: "Administrator",
  [LOAN_OFFICER]: "Loan Officer",
  [LOAN_PROCESSOR]: "Loan Processor"
});

export default USER_ROLES;
