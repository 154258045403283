import React from "react";
import { total_services_you_can_shop_for } from "../../../calculations/loan";
import SERVICES_YOU_CAN_SHOP_FOR from "../../../constants/services_you_can_shop_for";
import LoanFees from "./loan_fees";
import CurrencyField from "../../fields/currency_field";
import TrainingTip from "../../ui/training_tip";

export default function ServicesYouCanShopFor({
  loan,
  readOnly,
  removeServicesYouCanShopFor,
  updateServicesYouCanShopFor
}) {
  return (
    <div className="services-you-can-shop-for">
      <div className="closing-costs-row gray-bg top-border">
        <div className="flex align-items-center">
          <div className="section-title">C. Services You Can Shop For</div>
          <TrainingTip content="LANIS Training Tip: Tolerance Level 10%" />
        </div>
        <CurrencyField
          className="closing-costs-cell right-align"
          naked
          fixedDigits={0}
          value={total_services_you_can_shop_for(loan)}
        />
      </div>
      <LoanFees
        loanFees={loan.services_you_can_shop_for}
        allFees={SERVICES_YOU_CAN_SHOP_FOR}
        removeFee={removeServicesYouCanShopFor}
        updateFees={updateServicesYouCanShopFor}
        readOnly={readOnly}
      />
    </div>
  );
}
