import React from "react";
import "./css/confirm_receipt.scss";
import { borrower_names } from "../../../constants/utils";
import { useDocument } from "../../loan_tools/loan_tool";

export default function ConfirmReceipt() {
  const [{ applicant }] = useDocument();

  const borrowerNames = borrower_names(applicant.borrowers);

  return (
    <div className="confirm-receipt">
      <div className="content-section">
        <div className="summary-header content-title">Confirm Receipt</div>
      </div>
      <div className="receipt-content">
        By signing, you are only confirming that you have received this form.
        You do not have to accept this loan because you have signed or received
        this form.
        <br />
        Appraisal fees will not be charged, nor credit card information
        collected, prior to borrower confirmation of the intent to proceed with
        the loan after issuance
        <br />
        of the Formal Loan Estimate, which must be delivered to the consumer
        within three days of application.
      </div>
      <div className="signature-container">
        <div className="signature-line" />
        <div className="signature-line" />
      </div>
      <div className="signature-container">
        <div className="signature-block">
          <div>
            <div>Applicants Signature</div>
            <div>{borrowerNames[0]}</div>
          </div>
          <div>Date</div>
        </div>
        <div className="signature-block">
          <div>
            <div>Co-Applicants Signature</div>
            <div>{borrowerNames[1]}</div>
          </div>
          <div>Date</div>
        </div>
      </div>
      <div className="signature-container">
        <div className="signature-line" />
        <div className="signature-line" />
      </div>
      <div className="signature-container">
        <div className="signature-block">
          <div>
            <div>Co-Applicants Signature</div>
            <div>{borrowerNames[2]}</div>
          </div>
          <div>Date</div>
        </div>
        <div className="signature-block">
          <div>
            <div>Co-Applicants Signature</div>
            <div>{borrowerNames[3]}</div>
          </div>
          <div>Date</div>
        </div>
      </div>
    </div>
  );
}
