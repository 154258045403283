import React, { useMemo } from "react";
import SelectField from "../../fields/select_field";

export default function EditFeeList({ save, loanFees, allFees }) {
  const selectOptions = useMemo(
    () =>
      Object.keys(allFees)
        .filter(
          feeType =>
            !Object.keys(loanFees).some(loanFeeKey => loanFeeKey === feeType)
        )
        .sort((a, b) => allFees[a].localeCompare(allFees[b]))
        .reduce((options, key) => {
          options[key] = allFees[key];
          return options;
        }, []),
    [loanFees]
  );

  function onChange(selection) {
    const selected = selection.loan_fee;
    const newLoanFees = { ...loanFees };
    newLoanFees[selected] = "0";
    save(newLoanFees);
  }

  if (selectOptions.length === 0) {
    return <></>;
  }

  return (
    <div className="edit-fee-list">
      <div className="closing-costs-row">
        <SelectField
          fieldName="loan_fee"
          value=""
          onChange={onChange}
          selectOptions={selectOptions}
          sortLabel
        />
        <div className="closing-costs-cell" />
      </div>
    </div>
  );
}
