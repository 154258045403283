export const RATE_2_5 = "2.5";
export const RATE_2_625 = "2.625";
export const RATE_2_75 = "2.75";
export const RATE_2_875 = "2.875";
export const RATE_2_99 = "2.99";
export const RATE_3 = "3";
export const RATE_3_125 = "3.125";
export const RATE_3_25 = "3.25";
export const RATE_3_375 = "3.375";
export const RATE_3_5 = "3.5";
export const RATE_3_625 = "3.625";
export const RATE_3_75 = "3.75";
export const RATE_3_875 = "3.875";
export const RATE_3_99 = "3.99";
export const RATE_4 = "4";
export const RATE_4_125 = "4.125";
export const RATE_4_25 = "4.25";
export const RATE_4_375 = "4.375";
export const RATE_4_49 = "4.49";
export const RATE_4_5 = "4.5";
export const RATE_4_625 = "4.625";
export const RATE_4_75 = "4.75";
export const RATE_4_875 = "4.875";
export const RATE_4_99 = "4.99";
export const RATE_5 = "5";
export const RATE_5_125 = "5.125";
export const RATE_5_25 = "5.25";
export const RATE_5_375 = "5.375";
export const RATE_5_49 = "5.49";
export const RATE_5_5 = "5.5";
export const RATE_5_625 = "5.625";
export const RATE_5_75 = "5.75";
export const RATE_5_875 = "5.875";
export const RATE_5_99 = "5.99";
export const RATE_6 = "6";
export const RATE_6_125 = "6.125";
export const RATE_6_25 = "6.25";
export const RATE_6_375 = "6.375";
export const RATE_6_5 = "6.5";
export const RATE_6_625 = "6.625";
export const RATE_6_75 = "6.75";
export const RATE_6_875 = "6.875";
export const RATE_6_99 = "6.99";
export const RATE_7 = "7";
export const RATE_7_125 = "7.125";
export const RATE_7_25 = "7.25";
export const RATE_7_375 = "7.375";
export const RATE_7_5 = "7.5";
export const RATE_7_625 = "7.625";
export const RATE_7_75 = "7.75";
export const RATE_7_875 = "7.875";
export const RATE_7_99 = "7.99";
export const RATE_8 = "8";
export const RATE_8_125 = "8.125";
export const RATE_8_25 = "8.25";
export const RATE_8_375 = "8.375";
export const RATE_8_5 = "8.5";
export const RATE_8_625 = "8.625";
export const RATE_8_75 = "8.75";
export const RATE_8_875 = "8.875";
export const RATE_8_99 = "8.99";
export const RATE_9 = "9";
export const RATE_9_125 = "9.125";
export const RATE_9_25 = "9.25";
export const RATE_9_375 = "9.375";
export const RATE_9_5 = "9.5";
export const RATE_9_625 = "9.625";
export const RATE_9_75 = "9.75";
export const RATE_9_875 = "9.875";
export const RATE_9_99 = "9.99";
export const RATE_10 = "10";

export default Object.freeze({
  [RATE_2_5]: "2.5%",
  [RATE_2_625]: "2.625%",
  [RATE_2_75]: "2.75%",
  [RATE_2_875]: "2.875%",
  [RATE_2_99]: "2.99%",
  [RATE_3]: "3%",
  [RATE_3_125]: "3.125%",
  [RATE_3_25]: "3.25%",
  [RATE_3_375]: "3.375%",
  [RATE_3_5]: "3.5%",
  [RATE_3_625]: "3.625%",
  [RATE_3_75]: "3.75%",
  [RATE_3_875]: "3.875%",
  [RATE_3_99]: "3.99%",
  [RATE_4]: "4%",
  [RATE_4_125]: "4.125%",
  [RATE_4_25]: "4.25%",
  [RATE_4_375]: "4.375%",
  [RATE_4_49]: "4.49%",
  [RATE_4_5]: "4.5%",
  [RATE_4_625]: "4.625%",
  [RATE_4_75]: "4.75%",
  [RATE_4_875]: "4.875%",
  [RATE_4_99]: "4.99%",
  [RATE_5]: "5%",
  [RATE_5_125]: "5.125%",
  [RATE_5_25]: "5.25%",
  [RATE_5_375]: "5.375%",
  [RATE_5_49]: "5.49%",
  [RATE_5_5]: "5.5%",
  [RATE_5_625]: "5.625%",
  [RATE_5_75]: "5.75%",
  [RATE_5_875]: "5.875%",
  [RATE_5_99]: "5.99%",
  [RATE_6]: "6%",
  [RATE_6_125]: "6.125%",
  [RATE_6_25]: "6.25%",
  [RATE_6_375]: "6.375%",
  [RATE_6_5]: "6.5%",
  [RATE_6_625]: "6.625%",
  [RATE_6_75]: "6.75%",
  [RATE_6_875]: "6.875%",
  [RATE_6_99]: "6.99%",
  [RATE_7]: "7%",
  [RATE_7_125]: "7.125%",
  [RATE_7_25]: "7.25%",
  [RATE_7_375]: "7.375%",
  [RATE_7_5]: "7.5%",
  [RATE_7_625]: "7.625%",
  [RATE_7_75]: "7.75%",
  [RATE_7_875]: "7.875%",
  [RATE_7_99]: "7.99%",
  [RATE_8]: "8%",
  [RATE_8_125]: "8.125%",
  [RATE_8_25]: "8.25%",
  [RATE_8_375]: "8.375%",
  [RATE_8_5]: "8.5%",
  [RATE_8_625]: "8.625%",
  [RATE_8_75]: "8.75%",
  [RATE_8_875]: "8.875%",
  [RATE_8_99]: "8.99%",
  [RATE_9]: "9%",
  [RATE_9_125]: "9.125%",
  [RATE_9_25]: "9.25%",
  [RATE_9_375]: "9.375%",
  [RATE_9_5]: "9.5%",
  [RATE_9_625]: "9.625%",
  [RATE_9_75]: "9.75%",
  [RATE_9_875]: "9.875%",
  [RATE_9_99]: "9.99%",
  [RATE_10]: "10%"
});

export const FIXED_RATE = "1";
export const VARIABLE_RATE = "2";
export const FHA_UFMIP = "1.75";
export const USDA_UFMIP = "1";
