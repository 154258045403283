import React from "react";

export default function ErrorMessages({ messages }) {
  return (
    <>
      {messages && messages.length > 0 && (
        <div className="error-messages">
          {messages.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      )}
    </>
  );
}
