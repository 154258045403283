import React from "react";
import { useDocument } from "../loan_tool";
import CREDIT_SCORE from "../../../constants/credit_score";
import { NO, YES, YES_NO_TBD } from "../../../constants/yes_no";
import { estimated_loan_amount } from "../../../calculations/investment_reo";
import OCCUPANCY from "../../../constants/occupancy";
import IMPOUNDS_FOR_LOAN from "../../../constants/impounds_for_loan";
import CurrencyField from "../../fields/currency_field";
import TrainingTip from "../../ui/training_tip";
import DateField from "../../fields/date_field";
import PercentField from "../../fields/percent_field";
import {
  fha_or_pmi_mo_pmt,
  fha_or_pmi_pay_off,
  first_and_second_current_monthly_payment,
  first_and_second_current_monthly_payment_with_lender_fee,
  first_current_monthly_payment,
  total_pi_and_mi,
  total_pi_mi_fees
} from "../../../calculations/property";
import { LENDER_FEE } from "../../../constants/fixed_values";
import Decimal from "decimal.js";
import PROPERTY_TYPES from "../../../constants/property_type";
import PURPOSE_OF_REFINANCE from "../../../constants/purpose_of_refinance";
import {
  has_broker_loan,
  loan_term_name,
  show_hoas
} from "../../../constants/utils";
import SelectField from "../../fields/select_field";
import { LOAN_TYPE_CATEGORY_NAMES } from "../../../constants/loan_types";
import LOAN_PAYMENT_TYPE from "../../../constants/loan_payment_type";
import {
  final_total_loan_to_value,
  loan_to_value
} from "../../../calculations/loan";

export default function RefinanceGeneralInfoLoanSummary({ updateSummary }) {
  const [
    {
      applicant,
      property,
      property_to_be_sold,
      loans,
      summary,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const firstBorrower = applicant.borrowers[0] || {};
  const antiSteeringOptions = { "": "", "1": "1", "2": "2", "3": "3" };
  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];

  function updateAntiSteeringSelection(loanIndex, updates) {
    const loans = summary.loans;
    Object.assign(loans[loanIndex], updates);
    updateSummary({ loans });
  }

  return (
    <>
      <tbody className="with-border with-td-border">
        <tr>
          <td className="center-align thick-top-border no-right-border">
            Loan Locked
          </td>
          <td className="center-align thick-top-border no-left-border">Date</td>
          <td className="thick-top-border no-border" />
          <td className="center-align thick-top-border no-border bold">
            Market Value
          </td>
          <td className="center-align thick-top-border no-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={property.listed_price}
            />
          </td>
          <td className="thick-top-border no-right-border no-bottom-border bold">
            Credit Score
          </td>
          <td className="thick-top-border no-border">
            {CREDIT_SCORE[firstBorrower.lowest_credit_mid_score]}{" "}
            <TrainingTip content="LANIS Training Tip: The Score displayed is the Lowest Middle Credit Score from All Borrowers and comes from the Applicant Page.  The Add On Fees based on this score and the LLPA Table determines the pricing on the loan.   With many Lenders, if you are over 740 or 760, there is no additional range as you have the best pricing available." />
          </td>
          <td className="thick-top-border no-border" />
        </tr>
        <tr>
          <td className="center-align">{YES_NO_TBD[applicant.loan_locked]}</td>
          <td className="center-align">
            <DateField naked value={applicant.loan_lock_date} />
          </td>
          <td className="right-align no-border">Target LTV</td>
          <td className="center-align no-border">
            <PercentField naked fixedDigits={0} value={property.target_ltv} />
          </td>
          <td className="center-align no-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={estimated_loan_amount(property)}
            />
          </td>
          <td className="no-top-border italic" colSpan="3">
            (Score or range for Pricing of LLPA&apos;s)
          </td>
        </tr>
        <tr>
          <td
            className="center-align no-right-border no-bottom-border"
            rowSpan="2"
          >
            <div>Mo. P & I</div>
            <div>Current Pymt</div>
          </td>
          <td
            className="center-align no-left-border no-bottom-border"
            rowSpan="2"
          >
            <div>Lender</div>
            <div>Fees</div>
          </td>
          <td colSpan="3" rowSpan="2">
            <div className="bold italic">Occupancy Type</div>
            <div>{OCCUPANCY[property.occupancy]}</div>
          </td>
          <td colSpan="3" rowSpan="2">
            <div className="bold italic">Impounds For This Loan?</div>
            <div>{IMPOUNDS_FOR_LOAN[property.impounds_for_loan]}</div>
          </td>
        </tr>
        <tr />
        <tr>
          <td className="right-align">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_current_monthly_payment(property_to_be_sold)}
            />
          </td>
          <td className="right-align">
            {first_current_monthly_payment(property_to_be_sold).gt(0) && (
              <CurrencyField
                naked
                fixedDigits={0}
                value={first_current_monthly_payment(property_to_be_sold).add(
                  LENDER_FEE
                )}
              />
            )}{" "}
            <TrainingTip
              content={`LANIS Training Note:  Remember the current lender will add the recording and reconveyance fee plus a "Fax" fee for the payoff demand.`}
            />
          </td>
          <td colSpan="3" rowSpan="2">
            <div className="bold italic">Property Type</div>
            <div>{PROPERTY_TYPES[property.property_type]}</div>
          </td>
          <td colSpan="3" rowSpan="2">
            <div className="bold italic">Purpose of Refinance</div>
            <div>{PURPOSE_OF_REFINANCE[property.purpose_of_refinance]}</div>
          </td>
        </tr>
        <tr>
          <td className="right-align">
            <CurrencyField
              naked
              fixedDigits={0}
              value={property_to_be_sold.second_current_monthly_payment}
            />
          </td>
          <td className="right-align">
            {new Decimal(property_to_be_sold.second_current_monthly_payment).gt(
              0
            ) && (
              <CurrencyField
                naked
                fixedDigits={0}
                value={new Decimal(
                  property_to_be_sold.second_current_monthly_payment
                ).add(LENDER_FEE)}
              />
            )}
          </td>
        </tr>
        <tr>
          <td className="right-align">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_and_second_current_monthly_payment(
                property_to_be_sold
              )}
            />
          </td>
          <td className="right-align">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_and_second_current_monthly_payment_with_lender_fee(
                property_to_be_sold
              )}
            />
          </td>
          <td colSpan="2">
            {show_hoas(property) && (
              <div className="flex-space-evenly">
                <div>HOA 1</div>
                <CurrencyField naked fixedDigits={0} value={property.hoa1} />
              </div>
            )}
          </td>
          <td colSpan="2">
            {show_hoas(property) && (
              <div className="flex-space-evenly">
                <div>HOA 2</div>
                <CurrencyField naked fixedDigits={0} value={property.hoa2} />
              </div>
            )}
          </td>
          <td colSpan="2" />
        </tr>
      </tbody>
      <tbody className="loan-summaries-align-center">
        <tr>
          <td className="right-align with-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={fha_or_pmi_mo_pmt(property_to_be_sold)}
            />{" "}
            <TrainingTip content="LANIS Trainig TIP: If this is FHA MI, you may need to add a FULL 30 Days of Interest into your Loan Amount. Check the Case File ID # and Date or the Closing Agent Pay Off Demand or your Pay Off could be way off and you will need to change you New Loan Amount to cover the fees and Interest." />
          </td>
          <td className="with-border">
            {property_to_be_sold.fha_loan === YES && "< FHA Mo Pmt"}
            {property_to_be_sold.fha_loan === NO && "< PMI Mo Pmt"}
          </td>
          <td className="refinance-anti-steering blue-text with-border no-bottom-border">
            {has_broker_loan(loans) && (
              <>
                Anti Steering Disc.{" "}
                <TrainingTip
                  content="LANIS Training Tip: Please make Loan Selection for the Anti-Steering Disclosure to the right and place a 1, 2 or 3 in the appropriate field above the loan. LANIS will fill in the Disclosure for you.
1. Loan with the lowest interest rate,
2. Loan with the lowest interest rate without negative amortization, a prepayment penalty, interest-only payments, a balloon payment in the first 7 years of the life of the loan, a demand feature, shared equity, or shared appreciation,
3. Loan with the lowest dollar amount for origination fees and discount points."
                />
              </>
            )}
          </td>
          {summary.loans.map((loan, index) => (
            <td className="no-border gray-bg anti-steering-select" key={index}>
              {loan && has_broker_loan(loans) && (
                <>
                  <div className="no-print">
                    <SelectField
                      emptyDefault
                      fieldName="anti_steering_selection"
                      value={loan.anti_steering_selection}
                      selectOptions={antiSteeringOptions}
                      onChange={updates =>
                        updateAntiSteeringSelection(index, updates)
                      }
                    />
                  </div>
                  <div className="print-only">
                    <SelectField
                      naked
                      emptyDefault
                      value={loan.anti_steering_selection}
                      selectOptions={antiSteeringOptions}
                    />
                  </div>
                </>
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="right-align with-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={total_pi_mi_fees(property_to_be_sold)}
            />
          </td>
          <td className="small-font with-border">&lt; Total PI MI Fees</td>
          <td className="small-font blue-text center-align with-border no-top-border">
            {has_broker_loan(loans) && (
              <>
                <span>Pick loan 1, 2, 3</span>{" "}
                <TrainingTip content="LANIS Training Tip: Please make Loan Selection for the Anti-Steering Disclosure to the right and place a 1, 2 or 3 in the appropriate box above the loan. LANIS will fill in the Disclosure for you. 1. Loan with the lowest interest rate, 2. Loan with the lowest interest rate without negative amortization, a prepayment penalty, interest-only payments, a balloon payment in the first 7 years of the life of the loan, a demand feature, shared equity, or  shared appreciation, 3. Loan with the lowest dollar amount for origination fees and discount points." />
              </>
            )}
          </td>
          <td className="bold">Loan 1</td>
          <td className="bold">Loan 2</td>
          <td className="bold">Loan 3</td>
          <td className="bold">Loan 4</td>
          <td className="bold">Loan 5</td>
        </tr>
        <tr>
          <td className="left-border" colSpan="2" />
          <td className="right-align italic">Loan Type</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && LOAN_TYPE_CATEGORY_NAMES[loan.type]}</td>
          ))}
        </tr>
        <tr>
          <td className="right-align with-border no-bottom-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_and_second_current_monthly_payment_with_lender_fee(
                property_to_be_sold
              )}
            />
          </td>
          <td className="with-border no-bottom-border">&lt; Payoff Demand</td>
          <td className="right-align italic">Lender</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && loan.lender}</td>
          ))}
        </tr>
        <tr>
          <td className="right-align with-border no-top-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={fha_or_pmi_pay_off(property_to_be_sold)}
            />
          </td>
          <td className="with-border no-top-border">
            {property_to_be_sold.fha_loan === YES && "< FHA Pay Off"}
            {property_to_be_sold.fha_loan === NO && "< PMI Pay Off"}
          </td>
          <td className="right-align italic">Amortization</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>{loan && LOAN_PAYMENT_TYPE[loan.payment_type]}</td>
          ))}
        </tr>
        <tr>
          <td className="right-align with-border">
            <CurrencyField
              naked
              fixedDigits={0}
              value={total_pi_and_mi(property_to_be_sold)}
            />
          </td>
          <td className="with-border">Total PI & MI*</td>
          <td className="right-align italic">Loan Type / Term</td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && loan_term_name(loan.term)}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="2">*Total Pay Off Demand</td>
          <td className="right-align italic">LTV</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={loan_to_value(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td className="bottom-border" colSpan="2">
            from Current Lender
          </td>
          <td className="right-align italic bottom-border">TLTV / CLTV</td>
          {fiveLoans.map((loan, index) => (
            <td className="bottom-border" key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={2}
                  value={final_total_loan_to_value(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </>
  );
}
