import React, { useEffect, useState } from "react";
import BasicService from "../../services/basic_service";

export default function Image({ url, className }) {
  const [imageUrl, setImageUrl] = useState();

  async function getImage(imageUrl) {
    try {
      const response = await BasicService.get(imageUrl);
      response.blob().then(blob => {
        setImageUrl(URL.createObjectURL(blob));
      });
    } catch (e) {
      // TODO
    }
  }

  useEffect(() => {
    getImage(url);
  }, [url]);

  if (!imageUrl) {
    return <></>;
  }

  return (
    <img className={className} src={imageUrl} alt=""/>
  );
}
