import React, { useState } from "react";
import "./css/account_overview.scss";

import { YES_NO, YES } from "../../../constants/yes_no";

import SelectField from "../../../components/fields/select_field";
import AccountSummaryTable from "./account_table";

import { useDocument } from "../../../components/loan_tools/loan_tool";
import Section from "../../../components/ui/section";

export default function AccountOverview({ showEditAccount }) {
  const [activeLoanTab, setActiveLoanTab] = useState(0);
  const [document, setDocument, , { saveDocument }] = useDocument();
  const {
    investment_properties,
    accountSummaries,
    applicant,
    loans
  } = document;
  const {
    pay_off_debt_to_qualify,
    different_loan_options_for_paying_off_debt,
    borrowers
  } = applicant;
  const showLoanTabs = different_loan_options_for_paying_off_debt === YES;
  const loanIndex = showLoanTabs ? activeLoanTab : 0;
  const hasAccounts =
    Object.keys(accountSummaries).length > 0 ||
    investment_properties.length > 0;
  const hasLoans = loans.length > 0;

  function updateApplicant(updatedState) {
    const applicantEdit = { ...applicant, ...updatedState };

    if (updatedState.different_loan_options_for_paying_off_debt) {
      const loansEdit = loans.map(loan => {
        const updatedLoan = { ...loan };
        updatedLoan.account_dispositions = { ...loans[0].account_dispositions };
        return updatedLoan;
      });
      setDocument({ ...document, applicant: applicantEdit, loans: loansEdit });
    } else {
      setDocument({ ...document, applicant: applicantEdit });
    }
  }

  return (
    <div className="debt-info-account-overview">
      {borrowers.length > 0 && (
        <div>
          {hasLoans && (
            <div className="account-overview-top">
              <SelectField
                name="Will the Borrower Need to Pay off Debt to Qualify?"
                fieldName="pay_off_debt_to_qualify"
                value={pay_off_debt_to_qualify}
                onChange={updateApplicant}
                selectOptions={YES_NO}
              />
              <SelectField
                name="Could paying off Debt be different for each loan option to make the DTI in line with guidelines?"
                fieldName="different_loan_options_for_paying_off_debt"
                value={different_loan_options_for_paying_off_debt}
                onChange={updateApplicant}
                selectOptions={YES_NO}
              />
            </div>
          )}

          <Section name="Summary of Accounts">
            {hasLoans && (
              <div className="account-overview-loan-tabs">
                {showLoanTabs ? (
                  loans.map((loan, loanIndex) => (
                    <div
                      className={`loan-tab ${
                        activeLoanTab === loanIndex ? "active" : ""
                      }`}
                      data-index={loanIndex}
                      key={loanIndex}
                      onClick={async e => {
                        const tabIndex = parseInt(e.target.dataset.index, 10);
                        await saveDocument();
                        setActiveLoanTab(tabIndex);
                      }}
                    >
                      {`Loan ${loanIndex + 1}`}
                    </div>
                  ))
                ) : (
                  <div
                    key="aggregated-loan"
                    className="button inverse-button loan-tab active"
                  >
                    Loans 1-5
                  </div>
                )}
              </div>
            )}

            <div className="account-overview-section">
              {hasAccounts && (
                <AccountSummaryTable
                  showEditAccount={showEditAccount}
                  loanIndex={loanIndex}
                  groupedLoansTab={!showLoanTabs}
                />
              )}
            </div>
            <button
              className="button"
              onClick={showEditAccount}
              disabled={borrowers.length === 0}
            >
              Add Account
            </button>
          </Section>
        </div>
      )}
    </div>
  );
}
