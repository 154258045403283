export const company_metadata = {
  id: "",
  name: "",
  email: "",
  subscription_status: -1,
  subscription_active: false,
  subscription_end_date: 0,
  subaccount_limit: 1,
  seat_limit: 1
};
