import {
  loan_amount_first_mortgage,
  loan_term_in_years,
  savings_with_new_loan,
  total_closing_costs
} from "./loan";
import { pmt } from "./utils";
import Decimal from "decimal.js";

/**
 * Total Cost Analysis - Years fully Amortized to Pay Off the Loan Balance
 */
export function years_fully_amortized(
  documentType,
  loan,
  years,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const termYears = Decimal.min(loan_term_in_years(loan), years);
  const ir = new Decimal(loan.rate || 0).div(12).div(100);
  const np = new Decimal(termYears).mul(12);
  const pv = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
  return pmt(ir, np, pv);
}

/**
 * Total Cost Analysis - Cost of Loan Over Time
 */
export function cost_over_time(
  documentType,
  loan,
  years,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const termYears = Decimal.min(loan_term_in_years(loan), years);
  return years_fully_amortized(
    documentType,
    loan,
    termYears,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .mul(termYears)
    .mul(12);
}

/**
 * Total Cost Analysis - Total Cost of Purchasing the home over time
 */
export function total_cost_of_purchasing_over_time(
  documentType,
  loan,
  years,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return cost_over_time(
    documentType,
    loan,
    years,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    total_closing_costs(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Refinance - Summary Sheet - Return on Investment Cash Flow
 */
export function return_on_investment_cash_flow(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  accountSummaries,
  residences
) {
  const totalClosingCosts = total_closing_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  if (totalClosingCosts.eq(0)) {
    return new Decimal(0);
  }
  return savings_with_new_loan(
    documentType,
    property_to_be_sold,
    accountSummaries,
    residences,
    loan,
    property,
    is_selling_property
  )
    .mul(12)
    .mul(100)
    .div(totalClosingCosts);
}

/**
 * Refinance - Summary Sheet - # Months to recoup closing costs
 */
export function number_of_months_to_recoup_closing_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  accountSummaries,
  residences
) {
  const totalClosingCosts = total_closing_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  if (totalClosingCosts.eq(0)) {
    return new Decimal(0);
  }
  return totalClosingCosts.div(
    savings_with_new_loan(
      documentType,
      property_to_be_sold,
      accountSummaries,
      residences,
      loan,
      property,
      is_selling_property
    )
  );
}

/**
 * Refinance - Summary Sheet - Positive Cash Flow Per Year
 */
export function positive_cash_flow_per_year(
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  property,
  is_selling_property
) {
  return savings_with_new_loan(
    documentType,
    property_to_be_sold,
    accountSummaries,
    residences,
    loan,
    property,
    is_selling_property
  ).mul(12);
}

/**
 * Refinance - Summary Sheet - Cash Flow Over Years
 */
export function cash_flow_over_years(
  years,
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  property,
  is_selling_property
) {
  return savings_with_new_loan(
    documentType,
    property_to_be_sold,
    accountSummaries,
    residences,
    loan,
    property,
    is_selling_property
  )
    .mul(years)
    .mul(12);
}

/**
 * Refinance - Summary Sheet - Net Savings Over Years
 */
export function net_savings_over_years(
  years,
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  property,
  is_selling_property
) {
  return savings_with_new_loan(
    documentType,
    property_to_be_sold,
    accountSummaries,
    residences,
    loan,
    property,
    is_selling_property
  )
    .mul(years)
    .mul(12)
    .sub(
      total_closing_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}
