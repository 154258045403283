import React from "react";

import { YES, NO, TBD, YES_NO } from "../../../constants/yes_no";

import BorrowerReserveEntry from "./borrower_reserve_entry";
import ReservesTotalFooter from "./reserves_total_footer";
import TotalReserveCalculator from "./total_reserve_calculator";

import SelectField from "../../fields/select_field";

import { useDocument } from "../../loan_tools/loan_tool";
import { PURCHASE } from "../../../constants/document_types";

export default function ReservesMainPage({
  showEditReserve,
  showEquityFromSaleCalculator,
  deleteReserve,
  onChange
}) {
  const [
    { applicant, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  function onChangeApplicant(updatedState) {
    onChange({
      applicant: {
        ...applicant,
        ...updatedState
      }
    });
  }

  const showShowEquityFromSaleCalculatorButton =
    documentType === PURCHASE &&
    (is_selling_property === YES ||
      applicant.enough_reserves_to_purchase_home === NO);

  return (
    <div>
      {applicant.borrowers.length > 0 && (
        <>
          {applicant.borrowers.map((borrower, index) => (
            <BorrowerReserveEntry
              key={index}
              borrowerIndex={index}
              borrower={borrower}
              showEditReserve={showEditReserve}
              deleteReserve={deleteReserve}
            />
          ))}

          <div className="reserves-middle-section">
            {is_selling_property === TBD && (
              <SelectField
                fieldName="enough_reserves_to_purchase_home"
                name="Does the borrower have enough Reserves to purchase home without the sale of a property?"
                value={applicant.enough_reserves_to_purchase_home}
                selectOptions={YES_NO}
                onChange={onChangeApplicant}
              />
            )}
            {showShowEquityFromSaleCalculatorButton && (
              <button
                className="button show-equity-from-sale-calculator-button"
                onClick={showEquityFromSaleCalculator}
              >
                Show Equity From Sale Calculator
              </button>
            )}
          </div>

          {applicant.borrowers.length > 0 && <ReservesTotalFooter />}
          <TotalReserveCalculator />
        </>
      )}
    </div>
  );
}
