import React from "react";

import { va_total_fee, va_total_add_to_loan } from "../../../calculations/loan";

import { UPFRONT_FUND_FEE } from "../../../constants/va_constants";

import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../loan_tool";
import PercentField from "../../fields/percent_field";
import Decimal from "decimal.js";

const SORTED_UPFRONT_FUND_FEES = Object.entries(UPFRONT_FUND_FEE)
    .sort(([a], [b]) => {
      return new Decimal(a).greaterThan(b) ? 1 : -1;
    })
    .map(([, value]) => value);

export default function VAPricingTool({ loan, updateLoan, readOnly }) {
  const [{ property }, , { documentType }] = useDocument();
  const { va_upfront_funding_fee, va_add_to_loan } = loan;
  return (
    <div className="va-pricing-tool">
      <div className="down-payment-section-title">VA Pricing Tool </div>
      <PercentField
          id="va_upfront_funding_fee"
          fieldName="va_upfront_funding_fee"
          onChange={updateLoan}
          name="VA Upfront Funding Fee"
          value={va_upfront_funding_fee}
          roundTo={3}
          decimalPlaces={3}
          datalist={SORTED_UPFRONT_FUND_FEES}
          title="LANIS Training Tip: Use the chart to determine the VA Upfront Funding Fee. If the Veteran has been classified as having a Disability as a result of their Service, the Funding Fee may be waived. Make sure you ask the questions and collect the necessary VA paperwork."
      />
      <CurrencyField
        name="Total Fee"
        readOnly
        value={va_total_fee(documentType, loan, property)}
      />
      <CurrencyField
        name="Add to Loan"
        fieldName="va_add_to_loan"
        readOnly={readOnly}
        value={va_add_to_loan}
        onChange={updateLoan}
      />
      <CurrencyField
        name="Total Add To Fees"
        readOnly
        value={va_total_add_to_loan(documentType, loan, property)}
      />
    </div>
  );
}
