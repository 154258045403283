export const CHILDREN_BUYING_FOR_PARENTS = "1";
export const DOWN_SIZING = "2";
export const FIRST_TIME_BUYER = "3";
export const INVESTMENT_PROPERTY = "4";
export const MOVE_UP_BUYER = "5";
export const PARENTS_BUYING_FOR_CHILDREN = "6";
export const RELOCATION_FOR_JOB = "7";
export const SECOND_HOME = "8";
export const SECOND_HOME_COMMUTING_FOR_JOB = "9";

export default Object.freeze({
  [CHILDREN_BUYING_FOR_PARENTS]: "Children Buying for Parents",
  [DOWN_SIZING]: "Down Sizing",
  [FIRST_TIME_BUYER]: "First Time Buyer",
  [INVESTMENT_PROPERTY]: "Investment Property",
  [MOVE_UP_BUYER]: "Move up Buyer",
  [PARENTS_BUYING_FOR_CHILDREN]: "Parents Buying for Children",
  [RELOCATION_FOR_JOB]: "Relocation for Job",
  [SECOND_HOME]: "Second Home",
  [SECOND_HOME_COMMUTING_FOR_JOB]: "Second Home / Commuting for Job"
});
