export const THIRD_PARTY_LOAN_PROCESSING_FEE = "1";
export const APPRAISAL_FEE = "2";
export const APPRAISAL_FEE_POC = "3";
export const APPRAISAL_FIELD_REVIEW_FEE = "4";
export const BOND_COMMITMENT_FEE = "5";
export const CONDO_CERTIFICATION = "6";
export const CREDIT_REPORT_FEE = "7";
export const FLOOD_CERTIFICATION_FEE = "8";
export const FLOOD_DETERMINATION_FEE = "9";
export const FLOOD_MONITORING_FEE = "10";
export const HOA_CERTIFICATION = "11";
export const MERS_REGISTRATION_FEE = "12";
export const PMI_MIP_USDA = "13";
export const SUBORDINATION_SECOND_HELOC_LOAN = "14";
export const TAX_MONITORING_FEE = "15";
export const TAX_SERVICE_FEE = "16";
export const TAX_STATUS_RESEARCH_FEE = "17";
export const TITLE_ABSTRACT_TITLE_SEARCH_FEE = "18";
export const TITLE_CLOSING_FEE_ESCROW_FEE = "19";
export const TITLE_COPIER_OF_FAX_FEE = "20";
export const TITLE_COURIER_FEE = "21";
export const TITLE_DOC_PREP_FEE_FOR_2ND_LOAN = "22";
export const TITLE_DOCUMENT_SIGNING_FEE = "23";
export const TITLE_ELECTRONIC_DOCUMENT_FEE = "24";
export const TITLE_EXAMINATION_FEE = "25";
export const TITLE_INSURANCE_BINDING_FEE = "26";
export const TITLE_LENDER_ENDORSEMENTS = "27";
export const TITLE_LENDER_TITLE_POLICY = "28";
export const TITLE_LOAN_TIE_IN_FEE = "29";
export const TITLE_NOTARY_FEE = "30";
export const TITLE_OWNERS_TITLE_INSURANCE_PAID_BY_SELLER = "31";
export const TITLE_RECORDING_FEE = "32";
export const TITLE_RECORDING_SERVICE_FEE = "33";
export const TITLE_SUB_ESCROW_FEE = "34";
export const TITLE_TITLE_SEARCH = "35";
export const TITLE_WIRE_DISBURSEMENT_FEE = "36";
export const VA_FUNDING_FEE = "37";
export const VERIFICATION_OF_EMPLOYMENT = "38";
export const SUBORDINATION_SOLAR_CONTRACT = "39";
export const SUBORDINATION_SOLAR_TRUST_DEED = "40";
export const SUBORDINATION_SOLAR_UCC = "41";
export const MISCELLANEOUS = "42";
export const MISCELLANEOUS_2 = "43";

export const SERVICES_YOU_CANNOT_SHOP_FOR_APR = [
  THIRD_PARTY_LOAN_PROCESSING_FEE,
  BOND_COMMITMENT_FEE,
  CONDO_CERTIFICATION,
  FLOOD_CERTIFICATION_FEE,
  FLOOD_DETERMINATION_FEE,
  FLOOD_MONITORING_FEE,
  HOA_CERTIFICATION,
  MERS_REGISTRATION_FEE,
  PMI_MIP_USDA,
  SUBORDINATION_SECOND_HELOC_LOAN,
  TAX_MONITORING_FEE,
  TAX_SERVICE_FEE,
  TAX_STATUS_RESEARCH_FEE,
  TITLE_CLOSING_FEE_ESCROW_FEE,
  TITLE_COPIER_OF_FAX_FEE,
  TITLE_COURIER_FEE,
  TITLE_DOC_PREP_FEE_FOR_2ND_LOAN,
  TITLE_DOCUMENT_SIGNING_FEE,
  TITLE_ELECTRONIC_DOCUMENT_FEE,
  TITLE_LOAN_TIE_IN_FEE,
  TITLE_NOTARY_FEE,
  TITLE_SUB_ESCROW_FEE,
  TITLE_WIRE_DISBURSEMENT_FEE,
  VA_FUNDING_FEE,
  VERIFICATION_OF_EMPLOYMENT
];

export default Object.freeze({
  [THIRD_PARTY_LOAN_PROCESSING_FEE]: "3rd Party Loan Processing Fee",
  [APPRAISAL_FEE]: "Appraisal Fee",
  [APPRAISAL_FEE_POC]: "Appraisal Fee (POC)",
  [APPRAISAL_FIELD_REVIEW_FEE]: "Appraisal Field Review Fee",
  [BOND_COMMITMENT_FEE]: "Bond Commitment Fee",
  [CONDO_CERTIFICATION]: "Condo Certification",
  [CREDIT_REPORT_FEE]: "Credit Report Fee",
  [FLOOD_CERTIFICATION_FEE]: "Flood Certification Fee",
  [FLOOD_DETERMINATION_FEE]: "Flood Determination Fee",
  [FLOOD_MONITORING_FEE]: "Flood Monitoring Fee",
  [HOA_CERTIFICATION]: "HOA Certification",
  [MISCELLANEOUS]: "Miscellaneous",
  [MISCELLANEOUS_2]: "Miscellaneous",
  [MERS_REGISTRATION_FEE]: "MERS Registration Fee",
  [PMI_MIP_USDA]: "PMI/MIP/USDA",
  [SUBORDINATION_SOLAR_CONTRACT]: "Subordination Solar Contract",
  [SUBORDINATION_SOLAR_TRUST_DEED]: "Subordination Solar Trust Deed",
  [SUBORDINATION_SOLAR_UCC]: "Subordination Solar UCC",
  [SUBORDINATION_SECOND_HELOC_LOAN]: "Subordination (2nd) HELOC Loan",
  [TAX_MONITORING_FEE]: "Tax Monitoring Fee",
  [TAX_SERVICE_FEE]: "Tax Service Fee",
  [TAX_STATUS_RESEARCH_FEE]: "Tax Status Research Fee",
  [TITLE_ABSTRACT_TITLE_SEARCH_FEE]: "Title - Abstract / Title Search Fee",
  [TITLE_CLOSING_FEE_ESCROW_FEE]: "Title - Closing Fee/Escrow Fee",
  [TITLE_COPIER_OF_FAX_FEE]: "Title - Copier of Fax Fee",
  [TITLE_COURIER_FEE]: "Title - Courier Fee",
  [TITLE_DOC_PREP_FEE_FOR_2ND_LOAN]: "Title - Doc Prep Fee for 2nd Loan",
  [TITLE_DOCUMENT_SIGNING_FEE]: "Title - Document Signing Fee",
  [TITLE_ELECTRONIC_DOCUMENT_FEE]: "Title - Electronic Document Fee",
  [TITLE_EXAMINATION_FEE]: "Title - Examination Fee",
  [TITLE_INSURANCE_BINDING_FEE]: "Title - Insurance Binding Fee",
  [TITLE_LENDER_ENDORSEMENTS]: "Title - Lender Endorsements",
  [TITLE_LENDER_TITLE_POLICY]: "Title - Lender’s Title Policy",
  [TITLE_LOAN_TIE_IN_FEE]: "Title - Loan Tie In Fee",
  [TITLE_NOTARY_FEE]: "Title - Notary Fee",
  [TITLE_OWNERS_TITLE_INSURANCE_PAID_BY_SELLER]:
    "Title - Owners Title Insurance Paid by Seller",
  [TITLE_RECORDING_FEE]: "Title - Recording Fee",
  [TITLE_RECORDING_SERVICE_FEE]: "Title - Recording Service Fee",
  [TITLE_SUB_ESCROW_FEE]: "Title - Sub Escrow Fee",
  [TITLE_TITLE_SEARCH]: "Title - Title Search",
  [TITLE_WIRE_DISBURSEMENT_FEE]: "Title - Wire / Disbursement fee",
  [VA_FUNDING_FEE]: "VA Funding Fee",
  [VERIFICATION_OF_EMPLOYMENT]: "Verification of Employment"
});
