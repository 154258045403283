import React, { useEffect, useState, useRef } from "react";
import PercentField from "../../fields/percent_field";
import CurrencyField from "../../fields/currency_field";
import NumberField from "../../fields/number_field";
import { default_loan } from "../../../schema/loan";
import { transfer_taxes_calculator } from "../../../calculations/loan";
import Section from "../../ui/section";
import { useDocument } from "../loan_tool";

export default function TransferTaxesCalculator({
  loan,
  readOnly,
  returnBack,
  updateLoan
}) {
  const [{ property }, , { documentType }, { saveDocument }] = useDocument();
  const [transferTaxes, setTransferTaxes] = useState({
    ...default_loan.transfer_taxes,
    ...loan.transfer_taxes
  });
  const callback = useRef();

  useEffect(() => {
    callback.current = () => {
      if (transferTaxes.is_modified) {
        if (
          window.confirm(
            "You have unsaved data in Transfer Taxes Form. Would you like to save it before you leave?"
          )
        ) {
          onSave();
        } else {
          // do nothing
        }
      }
    };
  }, [transferTaxes]);

  useEffect(() => {
    // do nothing

    return () => {
      callback.current();
    };
  }, []);

  function updateTransferTaxes(updatedEntry) {
    setTransferTaxes({
      ...transferTaxes,
      ...updatedEntry,
      is_modified: true
    });
  }

  function onSave() {
    delete transferTaxes.is_modified;
    updateLoan({
      transfer_taxes: transferTaxes
    });
    saveDocument();
    returnBack();
  }

  return (
    <Section
      className="transfer-taxes-calculator"
      name="Transfer Taxes Section E"
    >
      <PercentField
        name="Percentage Split?"
        readOnly={readOnly}
        fieldName="percent_split"
        fixedDigits={2}
        value={transferTaxes.percent_split}
        onChange={updateTransferTaxes}
      />
      <CurrencyField
        name="Base Value (/K)"
        readOnly={readOnly}
        fieldName="base_value_k"
        value={transferTaxes.base_value_k}
        onChange={updateTransferTaxes}
      />
      <NumberField
        name="Transfer Amount"
        readOnly
        value={transfer_taxes_calculator.transfer_amount(
          documentType,
          loan,
          property
        )}
      />
      <CurrencyField
        name="Transfer Total"
        readOnly
        value={transfer_taxes_calculator.transfer_total(
          documentType,
          loan,
          transferTaxes,
          property
        )}
      />
      <CurrencyField
        name="County Base Tax (/K)"
        readOnly={readOnly}
        fieldName="county_base_tax_k"
        value={transferTaxes.county_base_tax_k}
        onChange={updateTransferTaxes}
      />
      <CurrencyField
        name="Co. Transfer Amount"
        readOnly
        value={transfer_taxes_calculator.county_transfer_amount(
          documentType,
          loan,
          transferTaxes,
          property
        )}
      />
      <CurrencyField
        name="City Base Tax (/K)"
        readOnly={readOnly}
        fieldName="city_base_tax_k"
        value={transferTaxes.city_base_tax_k}
        onChange={updateTransferTaxes}
      />
      <CurrencyField
        name="City Transfer Amount"
        readOnly
        value={transfer_taxes_calculator.city_transfer_amount(
          documentType,
          loan,
          transferTaxes,
          property
        )}
      />
      <CurrencyField
        name="Total of Transfer Taxes"
        readOnly
        value={transfer_taxes_calculator.total_of_transfer_taxes(
          documentType,
          loan,
          transferTaxes,
          property
        )}
      />
      <CurrencyField
        name="Borrower Cost"
        readOnly
        value={transfer_taxes_calculator.borrower_cost(
          documentType,
          loan,
          transferTaxes,
          property
        )}
      />

      <button className="button" onClick={returnBack}>
        Cancel
      </button>
      <button className="button" onClick={onSave}>
        Save
      </button>
    </Section>
  );
}
