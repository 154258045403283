import React from "react";
import { Router } from "@reach/router";
import config from "../config/config.js";

//
// CSS
//
import "./css/application.css";

//
// Services
//

//
// Components
//

//
// Pages
//
import HomePage from "../pages/home";

// Purchase Loan Tool Pages
import ApplicantInfoPage from "../pages/loan_tool/applicant_info";
import ClosingCostsWorksheetPage from "../pages/loan_tool/closing_costs_worksheet";
import DebtInfoPage from "../pages/loan_tool/debt_info/debt_info";
import DownPaymentCalculatorPage from "../pages/loan_tool/down_payment_calculator";
import IncomePage from "../pages/loan_tool/income";
import InvestReoPage from "../pages/loan_tool/invest_reo";
import LoanEstimatePriceEngineOptionsPage from "../pages/loan_tool/loan_estimate_price_engine_options";
import LoanEstimatePriceEnginePage from "../pages/loan_tool/loan_estimate_price_engine";
import LoanTypePage from "../pages/loan_tool/loan_type";
import NewPropertyPage from "../pages/loan_tool/new_property";
import ReservesPage from "../pages/loan_tool/reserves";
import SellingPropertyPage from "../pages/loan_tool/selling_property";
import SummarySheetPage from "../pages/loan_tool/summary_sheet";

import { UserProvider } from "../hooks/user";
import LoanTool from "./loan_tools/loan_tool";
import LoanToolRoot from "./loan_tools/loan_tool_root";
import PasswordReset from "../pages/password_reset";
import NotFound from "../pages/not_found";
import PasswordResetRequest from "../pages/password_reset_request";
import AccountProfile from "../pages/account_profile";
import CompanyAdminPanel from "../pages/company_admin_panel";
import { StripeProvider } from "react-stripe-elements";
import Subscribe from "../pages/subscribe";
import SystemAdminPanel from "../pages/system_admin_panel";
import SignUpReceipt from "../pages/sign_up_receipt";
import SignUpProfile from "../pages/sign_up_profile.js";
import SignUpPage from "../pages/sign_up";

/**
 * Application is the highest-order component in the system.
 */
export default function Application() {
  return (
    <StripeProvider apiKey={config.stripePublicApiKey}>
      <Router>
        <UserProvider path="/">
          <HomePage path="/" />
          <SignUpPage path="/sign-up" />
          <SignUpReceipt path="/sign-up/receipt" />
          <SignUpProfile path="/sign-up/profile" />
          <AccountProfile path="/account-profile" />
          <CompanyAdminPanel path="/admin-panel" />
          <SystemAdminPanel path="system-admin-panel" />
          <Subscribe path="/subscribe" />

          <LoanTool path="/loan-tool/:documentId">
            <LoanToolRoot path="/" />
            <ApplicantInfoPage path="/applicant-info" />
            <ClosingCostsWorksheetPage path="/closing-costs" />
            <DebtInfoPage path="/debt-info" />
            <DownPaymentCalculatorPage path="/down-payment-calculator" />
            <IncomePage path="/income" />
            <InvestReoPage path="/invest-reo" />
            <LoanEstimatePriceEnginePage path="/loan-estimate-price-engine" />
            <LoanEstimatePriceEngineOptionsPage path="/loan-estimate-price-engine-options" />
            <LoanTypePage path="/loan-type" />
            <NewPropertyPage path="/new-property" />
            <ReservesPage path="/reserves" />
            <SellingPropertyPage path="/selling-property" />
            <SummarySheetPage path="/summary-sheet" />

            {/* Refinance */}
            <NewPropertyPage path="/subject-property" />
            <SellingPropertyPage path="/current-prop-info" />
            <DownPaymentCalculatorPage path="/loan-configurator" />

            <NotFound default />
          </LoanTool>

          <NotFound default />
        </UserProvider>

        <PasswordReset path="/password-reset/auth" />
        <PasswordResetRequest path="/password-reset-request" />
        <NotFound default />
      </Router>
    </StripeProvider>
  );
}
