import React from "react";

import CurrencyField from "../../../components/fields/currency_field";
import TextField from "../../../components/fields/text_field";
import NumberField from "../../../components/fields/number_field";

import {
  residence_total_payment,
  reserve_total_payment
} from "../../../calculations/debt_info";

export default function DebtInfoResidence({ residence, onChange }) {
  const totalPayment = residence_total_payment(
    residence.first_mortgage,
    residence.second_mortgage,
    residence.taxes,
    residence.insurance,
    residence.hoa
  ).toString();
  const reservesTotalPayment = reserve_total_payment(
    residence.reserves,
    totalPayment
  ).toString();

  return (
    <div className="residence-reserve-calculator">
      <div>
        <div className="section-title">Residence reserve calculator</div>
        <CurrencyField
          name="Principal Balance"
          fieldName="principal_balance"
          value={residence.principal_balance}
          onChange={onChange}
        />

        <TextField
          name="Address"
          value={residence.address}
          fieldName="address"
          onChange={onChange}
        />

        <CurrencyField
          name="1st Mortgage"
          value={residence.first_mortgage}
          fieldName="first_mortgage"
          onChange={onChange}
        />

        <CurrencyField
          name="2nd Mortgage"
          value={residence.second_mortgage}
          fieldName="second_mortgage"
          onChange={onChange}
        />

        <CurrencyField
          name="Taxes"
          value={residence.taxes}
          fieldName="taxes"
          onChange={onChange}
        />

        <CurrencyField
          name="insurance"
          value={residence.insurance}
          fieldName="insurance"
          onChange={onChange}
        />

        <div className="divider" />

        <CurrencyField
          name="Total Payment"
          value={totalPayment}
          readOnly
          fieldName="insurance"
        />
      </div>

      <div>
        <div className="section-title">Reserves</div>
        <NumberField
          value={residence.reserves}
          fieldName="reserves"
          onChange={onChange}
        />
        <CurrencyField value={reservesTotalPayment} readOnly />
      </div>
    </div>
  );
}
