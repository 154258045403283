import React, { useEffect } from "react";

import { navigate } from "@reach/router";
import DocumentService from "../../services/document_service";
import { useGovernor } from "@techempower/react-governor";
import { fake_state_todo_remove } from "../../constants/fake_state_todo_remove";
import { default_loan } from "../../schema/loan";
import { metadata } from "../../constants/utils";
import { default_account_profile } from "../../schema/profile";
import { processUserProfile } from "../../hooks/user";

export const DocumentContext = React.createContext();

const saveDocumentPromises = {};
const contract = {
  setDocument(document) {
    return state => ({
      ...state,
      document: { ...document }
    });
  },

  setDocumentName(name) {
    return state => ({
      ...state,
      name: name
    });
  },

  async loadDocument(id) {
    try {
      const timestamp = +new Date();
      const response = await DocumentService.getDocument(id);
      const documentAccountProfile = processUserProfile(
        response.documentUserProfile
      );
      const document = response.document;
      if (saveDocumentPromises[id]) {
        // The document has been saved at some point. Try to pull this saved document, possibly using it instead.
        try {
          const {
            documentType,
            document: documentState,
            name,
            metadata,
            timestamp: saveTimestamp
          } = await saveDocumentPromises[id];
          if (saveTimestamp > timestamp) {
            // The document was saved since the load was requested. Try to pull this saved document instead.
            document.type = documentType;
            document.name = name;
            document.content = JSON.stringify(documentState);
            document.metadata = JSON.stringify(metadata);
          }
        } catch (ignored) {
          // If the save failed, just use the requested version instead.
        }
      }

      if (document) {
        const documentState = JSON.parse(document.content);
        // Take care defaults.
        Object.entries(fake_state_todo_remove).forEach(([key, value]) => {
          if (value && typeof value === "object" && !Array.isArray(value)) {
            documentState[key] = {
              ...value,
              ...documentState[key]
            };
          }
        });
        // Loan defaults.
        documentState.loans.forEach((loan, index) => {
          documentState.loans[index] = {
            ...default_loan,
            ...documentState.loans[index]
          };
          Object.entries(default_loan).forEach(([key, value]) => {
            if (value && typeof value === "object" && !Array.isArray(value)) {
              documentState.loans[index][key] = {
                ...value,
                ...documentState.loans[index][key]
              };
            }
          });
        });
        return state => ({
          ...state,
          documentAccountProfile,
          document: documentState,
          metadata: JSON.parse(document.metadata),
          documentId: document.id,
          name: document.name,
          documentType: document.type
        });
      } else {
        navigate("/");
      }
    } catch (e) {
      navigate("/");
    }
    return state => ({
      ...state
    });
  },

  async saveDocument(getState) {
    try {
      const {
        documentType,
        document,
        documentId,
        name,
      } = getState();
      const meta = metadata(
        documentType,
        document,
        getState().metadata
      );
      const promise = (async () => {
        const { id } = await DocumentService.saveDocument(
          document,
          meta,
          documentId,
          name
        );
        // Add some extra data in the promise so loadDocument can read the saved document instead.
        return {
          documentType,
          document,
          id,
          name,
          metadata: meta,
          timestamp: +new Date()
        }
      })();

      if (documentId) {
        saveDocumentPromises[documentId] = promise;
      }

      const { id } = await promise;

      return state => ({
        ...state,
        metadata: meta,
        documentId: id,
        lastSaveDate: Date.now()
      });
    } catch (e) {
      // TODO - error handling
    }
    return state => ({
      ...state
    });
  },

  setNavConnectorLinePositionOne(position) {
    return state => ({
      ...state,
      navConnectorLinePositionOne: position
    });
  },

  setNavConnectorLinePositionTwo(position) {
    return state => ({
      ...state,
      navConnectorLinePositionTwo: position
    });
  }
};

export function useDocument() {
  return React.useContext(DocumentContext);
}

export default function LoanTool({ documentId, children }) {
  const [state, actions] = useGovernor(
    {
      documentAccountProfile: { ...default_account_profile },
      document: { ...fake_state_todo_remove },
      documentId: { documentId },
      name: "",
      lastSaveDate: 0,
      navConnectorLinePositionOne: null,
      navConnectorLinePositionTwo: null
    },
    contract
  );

  // const autoSaveTimeout = useRef(0);

  useEffect(() => {
    if (documentId) {
      actions.loadDocument(documentId);
    } else if (!state.document) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    document.title = state.name;

    return () => {
      document.title = "LANIS TIER|ONE";
    };
  }, [state.name]);

  // useEffect(() => {
  //   if (autoSaveTimeout.current) {
  //     clearTimeout(autoSaveTimeout.current);
  //   }
  //   // Auto save every 2 minutes.
  //   autoSaveTimeout.current = setTimeout(() => {
  //     actions.saveDocument();
  //   }, 120000);
  //
  //   return () => clearTimeout(autoSaveTimeout.current);
  // }, [state.lastSaveDate]);

  return (
    <DocumentContext.Provider
      value={[state.document, actions.setDocument, state, actions]}
    >
      {state.document && state.documentType && <>{children}</>}
    </DocumentContext.Provider>
  );
}
