import { deep_clone_simple, loan_arm_fix, summary_selected_loan } from "../../constants/utils";
import { default_loan } from "../../schema/loan";
import { FNM, LANIS_ARM_FIX, LANIS_LOAN_TYPES } from "../fnm_constants";
import { formatFnmDecimal, parseFnmDecimal } from "../util";
import { REFINANCE } from "../../constants/document_types";

export function createSelectedLoanIfNotExist({ document }) {
  let selectedLoan = summary_selected_loan(document.loans, document.summary);
  if (!selectedLoan) {
    selectedLoan = deep_clone_simple(default_loan);
    const indexOfLoanFromOne = document.loans.push(selectedLoan);
    document.summary.selection_loan_number = "" + indexOfLoanFromOne;
  }
  return selectedLoan;
}

export function importMortgageTypeAndTerms({ data, item: loan, documentInfo }) {
  const {
    mortgageAppliedFor,
    noOfMonths,
    amortizationType,
    interestRate,
    loanAmount,
  } = data;
  if (mortgageAppliedFor) {
    const loanType = LANIS_LOAN_TYPES.import[mortgageAppliedFor];
    if (loanType) {
      loan.type = loanType;
    } else {
      console.warn("Detected unsupported FNM loan type: ", mortgageAppliedFor);
    }
  }
  const numberOfMonths = parseFnmDecimal(noOfMonths);
  if (numberOfMonths) {
    let numberOfYears = numberOfMonths.div(12);
    if (numberOfYears % 1 !== 0) {
      console.warn("Non-whole loan term detected while importing");
      numberOfYears = numberOfYears.round();
    }
    loan.term = numberOfYears.toString();
  } else {
    loan.term = "30";
  }
  if (amortizationType) {
    const effectiveArmFix = loan_arm_fix(loan.term);
    const requestedArmFix = LANIS_ARM_FIX.import[amortizationType];
    if (effectiveArmFix !== requestedArmFix) {
      console.warn("The imported Amortization Type does not match effective " +
          "arm/fix value given the loan term");
    }
  }
  const parsedLoanAmount = parseFnmDecimal(loanAmount);
  if (parsedLoanAmount) {
    if (documentInfo.documentType === REFINANCE) {
      loan.refinance_loan_calculator.total_of_new_first_loan = parsedLoanAmount.toString();
    } else {
      loan.offer_price = parsedLoanAmount.toString();
    }
  }
  const parsedInterestRate = parseFnmDecimal(interestRate);
  if (parsedInterestRate) {
    loan.rate = parsedInterestRate.toString();
  }
}

export function importOtherCredits({ data, item: loan }) {
  const {
    otherCreditTypeCode,
    amountOfOtherCredit,
  } = data;
  const creditAmount = formatFnmDecimal(amountOfOtherCredit, 0);
  if (otherCreditTypeCode) {
    if (!creditAmount) {
      console.warn("Other credit amount not provided for other credit with FNM type code: ",
          otherCreditTypeCode);
      return;
    }
    switch (otherCreditTypeCode) {
      case FNM.OTHER_CREDIT_TYPE.CASH_DEPOSIT_ON_SALES_CONTRACT:
        loan.other_adjustments.cash_deposit_on_sales_contract = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.SELLER_CREDIT:
        loan.other_adjustments.seller_credit = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.LENDER_CREDIT:
        loan.other_adjustments.lender_credit = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.RELOCATION_FUNDS:
        loan.other_adjustments.relocation_funds = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.EMPLOYER_ASSISTED_HOUSING:
        loan.other_adjustments.employer_assisted_housing = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.LEASE_PURCHASE_FUND:
        loan.other_adjustments.lease_purchase_fund = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.OTHER:
        loan.other_adjustments.miscellaneous_credit_one = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.BORROWER_PAID_FEES:
        loan.other_adjustments.borrower_paid_fees = creditAmount;
        break;
      case FNM.OTHER_CREDIT_TYPE.SWEAT_EQUITY:
        loan.other_adjustments.miscellaneous_credit_two = creditAmount;
        break;
      default:
        console.warn("Detected unsupported other credit type: ", otherCreditTypeCode);
    }
  } else {
    console.warn("Other credit type code not provided.");
  }
}
