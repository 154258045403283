export const ATTACHED = "1";
export const CONDOMINIUM = "2";
export const COOPERATIVE = "3";
export const DETACHED = "4";
export const DETACHED_CONDOMINIUM = "5";
export const DUPLEX_1_2 = "6";
export const HIGH_RISE_CONDOMINIUM = "7";
export const MANUFACTURED_SINGLE_WIDE = "8";
export const MANUFACTURED_DOUBLE_WIDE = "9";
export const MANUFACTURED_MULTI_WIDE = "10";
export const MANUFACTURED_CONDO_PUD_COOP = "11";
export const MODULAR_PRE_FABRICATED_HOME = "12";
export const MULTI_UNIT_3_4 = "13";
export const PUD = "14";

export const HOA_PROPERTY_TYPES = [
  ATTACHED,
  CONDOMINIUM,
  COOPERATIVE,
  DETACHED_CONDOMINIUM,
  HIGH_RISE_CONDOMINIUM,
  MODULAR_PRE_FABRICATED_HOME,
  PUD
];

export const MANUFACTURED_PROPERTY_TYPES = [
  MANUFACTURED_SINGLE_WIDE,
  MANUFACTURED_DOUBLE_WIDE,
  MANUFACTURED_MULTI_WIDE,
  MANUFACTURED_CONDO_PUD_COOP,
  MODULAR_PRE_FABRICATED_HOME
];

export const MULTI_UNIT_TYPES = [DUPLEX_1_2, MULTI_UNIT_3_4];

export const CONDOMINIUM_TYPES = [
  CONDOMINIUM,
  DETACHED_CONDOMINIUM,
  COOPERATIVE,
  HIGH_RISE_CONDOMINIUM
];

export default Object.freeze({
  [ATTACHED]: "Attached",
  [CONDOMINIUM]: "Condominium",
  [COOPERATIVE]: "Cooperative",
  [DETACHED]: "Detached",
  [DETACHED_CONDOMINIUM]: "Detached Condominium",
  [DUPLEX_1_2]: "Duplex 1-2",
  [HIGH_RISE_CONDOMINIUM]: "High Rise Condominium",
  [MANUFACTURED_SINGLE_WIDE]: "Manufactured Single Wide",
  [MANUFACTURED_DOUBLE_WIDE]: "Manufactured Double Wide",
  [MANUFACTURED_MULTI_WIDE]: "Manufactured Multi Wide",
  [MANUFACTURED_CONDO_PUD_COOP]: "Manufactured Condo/PUD/Co-OP",
  [MODULAR_PRE_FABRICATED_HOME]: "Modular/Pre-Fabricated Home",
  [MULTI_UNIT_3_4]: "Multi Unit 3-4",
  [PUD]: "PUD"
});
