export const default_metadata = {
  borrower_names: [],
  loan_number: "",
  first_loan_amount: "0",
  second_loan_amount: "0",
  lender: "",
  loan_type: "",
  locked_exp_date: "",
  time_sensitive: ""
};
