import React, { useState } from "react";

import StandardTemplate from "../../../templates/standard_template/standard_template";

import AccountOverview from "./account_overview";
import DebtInfoAddAccount from "./add_account";
import { DEBT_INFO } from "../../../constants/navigations";
import NoBorrowers from "../no_borrowers";

export default function DebtInfoPage() {
  const [activeView, setActiveView] = useState("ACCOUNT_SUMMARY");
  // Only relevant to the AddAccount view; determines which account is being edited.
  // This should be null when adding a new one.
  const [editAccountId, setEditAccountId] = useState(null);

  function showAccountTable() {
    setActiveView("ACCOUNT_SUMMARY");
    setEditAccountId(null);
  }

  function showEditAccount(editAccountId) {
    setActiveView("ADD_ACCOUNT");
    setEditAccountId(editAccountId);
  }

  return (
    <StandardTemplate
      active={DEBT_INFO}
      isDisableNavSave={activeView !== "ACCOUNT_SUMMARY"}
    >
      <NoBorrowers />

      {activeView === "ACCOUNT_SUMMARY" && (
        <AccountOverview showEditAccount={showEditAccount} />
      )}
      {activeView === "ADD_ACCOUNT" && (
        <DebtInfoAddAccount
          editAccountId={editAccountId}
          showAccountTable={showAccountTable}
        />
      )}
    </StandardTemplate>
  );
}
