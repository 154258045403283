export const CONSTRUCTION = "1";
export const HOME_EQUITY = "2";
export const PURCHASE = "3";
export const CASH_OUT_DEBT_CONSOLIDATION = "4";
export const CASH_OUT_HOME_IMPROVEMENT = "5";
export const LIMITED_CASH_OUT_RATE_TERM = "6";
export const CASH_OUT_OTHER = "7";
export const CHANGE_IN_RATE_TERM = "8";
export const NO_CASH_OUT_FHA_STREAMLINED_REFINANCE = "9";
export const NO_CASH_OUT_OTHER = "10";

export default Object.freeze({
  [CONSTRUCTION]: "Construction",
  [HOME_EQUITY]: "Home Equity",
  [PURCHASE]: "Purchase"
});

export const REFINANCE_LOAN_PURPOSES = Object.freeze({
  [CASH_OUT_DEBT_CONSOLIDATION]: "Cash-Out/Debt Consolidation",
  [CASH_OUT_HOME_IMPROVEMENT]: "Cash-Out/Home Improvement",
  [CONSTRUCTION]: "Construction",
  [LIMITED_CASH_OUT_RATE_TERM]: "Limited Cash-Out Rate/Term",
  [CASH_OUT_OTHER]: "Cash-Out/Other",
  [CHANGE_IN_RATE_TERM]: "Change in Rate Term",
  [NO_CASH_OUT_FHA_STREAMLINED_REFINANCE]:
    "No Cash-Out FHA Streamlined Refinance",
  [NO_CASH_OUT_OTHER]: "No Cash-Out/Other",
  [HOME_EQUITY]: "Home Equity Loan"
});
