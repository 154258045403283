import { PURCHASE as PURCHASE_TYPE } from "../../constants/document_types";
import { PURCHASE as PURCHASE_PURPOSE } from "../../constants/loan_purpose";
import { createSelectedLoanIfNotExist } from "./loan";
import { LANIS_DOCUMENT_TYPES } from "../fnm_constants";

export function importDocumentType({ data, document, documentInfo }) {
  const {
    purposeOfLoan
  } = data;
  if (purposeOfLoan) {
    const documentType = LANIS_DOCUMENT_TYPES.import[purposeOfLoan];
    if (documentType) {
      documentInfo.documentType = documentType;
      if (documentType === PURCHASE_TYPE) {
        const loan = createSelectedLoanIfNotExist({ document });
        loan.purpose = PURCHASE_PURPOSE;
      }
    } else {
      console.warn("Purpose of loan did not map to a supported document type", purposeOfLoan);
    }
  }
}