import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";

import Application from "./components/application";

import "./index.scss";
import "./css/common.scss";

ReactDOM.render(<Application />, document.getElementById("root"));
