import React, { useState, useEffect } from "react";
import "./css/summary_sheet.scss";

import StandardTemplate from "../../templates/standard_template/standard_template";
import CompanyClientInfo from "../../components/loan_tools/summary_sheet/company_client_info";
import GeneralInfo from "../../components/loan_tools/summary_sheet/general_info";
import LoanSummaries from "../../components/loan_tools/summary_sheet/loan_summaries";
import CalculatingCashToClose from "../../components/loan_tools/summary_sheet/calculating_cash_to_close";
import Comparisons from "../../components/loan_tools/summary_sheet/comparisons";
import LoanSelection from "../../components/loan_tools/summary_sheet/loan_selection";
import Trid from "./trid";
import TotalCostAnalysis from "../../components/loan_tools/summary_sheet/total_cost_analysis";

import { useDocument } from "../../components/loan_tools/loan_tool";
import NoLoans from "./no_loans";
import NoBorrowers from "./no_borrowers";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import RefinanceGeneralInfoLoanSummary from "../../components/loan_tools/summary_sheet/refinance_general_info_loan_summary";
import RefinanceCalculatingCashToClose from "../../components/loan_tools/summary_sheet/refinance_calculating_cash_to_close";

/**
 * Purchase Summary Sheet
 */
export default function PurchaseSummarySheet() {
  const [
    document,
    setDocument,
    { documentType, documentId },
    { saveDocument, loadDocument }
  ] = useDocument();
  const [activePage, setActivePage] = useState("PURCHASE_SUMMARY");

  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;

  async function switchTab(activePage) {
    await saveDocument();
    setActivePage(activePage);
  }

  function updateSummary(updatedSummary) {
    const summary = {
      ...document.summary,
      ...updatedSummary
    };
    setDocument({ ...document, summary });
  }

  function updateLoan(updatedLoan, loanIndex) {
    const loans = [...document.loans];
    loans[loanIndex] = {
      ...loans[loanIndex],
      ...updatedLoan
    };
    setDocument({ ...document, loans });
  }

  useEffect(() => {
    loadDocument(documentId);
  }, []);

  return (
    <StandardTemplate className="summary-standard-template">
      <NoBorrowers />
      <NoLoans />

      {document.loans.length > 0 && document.applicant.borrowers.length > 0 && (
        <div className="summary-container">
          <div className="summary-tab-container">
            <div
              key="-1"
              className={`summary-tab ${activePage === "PURCHASE_SUMMARY" &&
                "active"}`}
              onClick={() => switchTab("PURCHASE_SUMMARY")}
            >
              {isPurchase ? "Purchase" : "Refinance"} Summary
            </div>
            {isPurchase && (
              <div
                key="0"
                className={`summary-tab ${activePage ===
                  "TOTAL_COST_ANALYSIS" && "active"}`}
                onClick={() => switchTab("TOTAL_COST_ANALYSIS")}
              >
                Total Cost Analysis
              </div>
            )}
            {document.loans.map((loan, index) => {
              const tab = "LOAN_" + (index + 1);
              return (
                <div
                  key={index + 1}
                  className={`summary-tab ${activePage === tab && "active"}`}
                  onClick={() => switchTab(tab)}
                >
                  Loan {index + 1} TRID
                </div>
              );
            })}
          </div>
          {activePage === "PURCHASE_SUMMARY" && (
            <div className="summary-sheet">
              <table className="summary-sheet-main-table">
                <colgroup>
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                  <col />
                </colgroup>
                <CompanyClientInfo />
                {isPurchase && (
                  <>
                    <GeneralInfo />
                    <LoanSummaries updateSummary={updateSummary} />
                    <CalculatingCashToClose />
                  </>
                )}
                {isRefinance && (
                  <>
                    <RefinanceGeneralInfoLoanSummary
                      updateSummary={updateSummary}
                    />
                    <RefinanceCalculatingCashToClose
                      updateSummary={updateSummary}
                    />
                  </>
                )}
                <Comparisons />
                <LoanSelection updateSummary={updateSummary} />
              </table>
            </div>
          )}
          {isPurchase && activePage === "TOTAL_COST_ANALYSIS" && (
            <TotalCostAnalysis />
          )}
          {document.loans.map(
            (loan, index) =>
              activePage === "LOAN_" + (index + 1) && (
                <Trid
                  key={index}
                  updateLoan={updatedLoan => updateLoan(updatedLoan, 0)}
                  loan={loan}
                  loanNumber={index + 1}
                />
              )
          )}
        </div>
      )}
    </StandardTemplate>
  );
}
