import React from "react";
import { useDocument } from "../loan_tool";
import {
  additional_cash_out_needed,
  additional_funds_to_from_borrower,
  apr,
  current_loan_balance_second_td,
  first_mortgage_payment_p_and_i,
  loan_amount_first_mortgage,
  loan_amount_second_mortgage,
  monthly_payment_amount_subordinated_second_td,
  monthly_payment_amount_subordinated_third_td,
  net_tangible_benefit,
  new_payment,
  savings_with_new_loan,
  subordinated_second_td,
  subordinated_third_td,
  third_loan_balance,
  total_closing_costs,
  total_of_existing_payments
} from "../../../calculations/loan";
import CurrencyField from "../../fields/currency_field";
import {
  balance_pay_offs_total,
  payment_savings_total
} from "../../../calculations/debt_info";
import { class_list } from "../../../constants/utils";
import PercentField from "../../fields/percent_field";
import {
  ActualPitiHoaWithoutShock,
  ActualPrincipalAndInterestMonthlyPayment,
  AmortizedCombinedPaymentIOLoan,
  AmountOffDebtToPayOffToQualify,
  ArmTerms,
  BottomEndDebtToIncomeRatio,
  BottomEndHomeExpenseRatioIOLoan,
  DtiBottomEndExpenseRatio,
  DtiTopFrontExpenseRatio,
  FhaAdditionalMonthlyPayForMipInsurance,
  FmFmacMonthlyPaymentForInsurance,
  InterestOnlyPayment,
  InterestRateOnNewSecondTd,
  MonthlyPaymentAmountNewSecondTd,
  MonthlyPaymentSavings,
  PaymentShockOnNewSecondLoanQualifying,
  PayOffDebtToQualifyBottomEndExpRatio,
  QualifyingArmPaymentPitiHoaMiIncluded,
  QualifyingPrincipalInterestTaxesInsuranceHoaAndMi,
  ReservesPositiveOrShortage,
  TopFrontEndHomeExpenseRatioIOLoan,
  TopFrontHomeExpenseRatio,
  TotalOfAllNewLoans,
  TotalReserveRequirement
} from "./calculating_cash_to_close_line_items";
import { SECOND_LOAN_PAYMENT_TYPES } from "../../../constants/payment_type";
import { calculate_reserve_total } from "../../../calculations/reserve";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { total_pi_mi_fees } from "../../../calculations/property";
import RefinanceTotalCostAnalysis from "./refinance_total_cost_analysis";
import { is_any_non_zero } from "../../../calculations/utils";
import Decimal from "decimal.js";

export default function RefinanceCalculatingCashToClose({ updateSummary }) {
  const [
    {
      applicant,
      loans,
      property,
      property_to_be_sold,
      is_selling_property,
      accountSummaries,
      residences
    },
    ,
    { documentType }
  ] = useDocument();

  const { borrowers } = applicant;
  const fiveLoans = [loans[0], loans[1], loans[2], loans[3], loans[4]];
  const hasSecondLoanType = loans.some(loan =>
    SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)
  );
  const hasInterestOnly = loans.some(
    loan => loan.payment_type === INTEREST_ONLY
  );
  const hasArm = loans.some(loan => ARM_TERMS.includes(loan.term));

  return (
    <>
      <tbody className="loan-summaries">
        <tr>
          <td className="summary-header" colSpan="3">
            Calculating Cash to Close
          </td>
          <td className="left-align" colSpan="5">
            (Page 2 of Loan Estimates)
          </td>
        </tr>
        <tr>
          <td colSpan="3">Total of New 1st Loan</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={loan_amount_first_mortgage(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <TotalOfNewSecondLoan fiveLoans={fiveLoans} />
        <SubordinatedSecondTd fiveLoans={fiveLoans} />
        <SubordinatedThirdTd fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="3">Current Loan Balance 1st TD</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={property_to_be_sold.first_loan_balance}
                />
              )}
            </td>
          ))}
        </tr>
        <CurrentLoanBalanceSecondTd fiveLoans={fiveLoans} />
        <CurrentLoanBalanceThirdTd fiveLoans={fiveLoans} />
        <PayOffDebt fiveLoans={fiveLoans} />
        <PrepaymentPenalty fiveLoans={fiveLoans} />
        <AdditionalCashOutNeeded fiveLoans={fiveLoans} />
        <EstimateOfTotalClosingCosts fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="3" className="top-border">
            Additional Funds To / <span className="red-text">From</span>{" "}
            Borrower (COE)
          </td>
          {fiveLoans.map((loan, index) => {
            const additionalFundsToFromBorrower =
              loan &&
              additional_funds_to_from_borrower(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property,
                accountSummaries,
                residences
              );
            return (
              <td
                className={class_list(
                  "top-border",
                  loan && additionalFundsToFromBorrower.lt(0) && "red-text"
                )}
                key={index}
              >
                {loan && (
                  <CurrencyField
                    naked
                    fixedDigits={0}
                    value={additionalFundsToFromBorrower}
                  />
                )}
              </td>
            );
          })}
        </tr>
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">
            <div className="right-align">
              Interest Rate on NEW 1st TD &gt; &gt; &gt;
            </div>
          </td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField naked decimalPlaces={3} value={loan.rate} />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Monthly Payment Amount New 1st TD</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={first_mortgage_payment_p_and_i(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <MonthlyPaymentAmountSubordinatedSecondTd fiveLoans={fiveLoans} />
        <MonthlyPaymentAmountSubordinatedThirdTd fiveLoans={fiveLoans} />
        <InterestRateOnNewSecondTd fiveLoans={fiveLoans} />
        <MonthlyPaymentAmountNewSecondTd
          documentType={documentType}
          fiveLoans={fiveLoans}
          property={property}
        />
        <PaymentShockOnNewSecondLoanQualifying
          documentType={documentType}
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <FhaAdditionalMonthlyPayForMipInsurance
          fiveLoans={fiveLoans}
          property={property}
        />
        <FmFmacMonthlyPaymentForInsurance
          fiveLoans={fiveLoans}
          property={property}
        />
        <TotalOfAllNewLoans fiveLoans={fiveLoans} property={property} />
        <ActualPrincipalAndInterestMonthlyPayment
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <QualifyingPrincipalInterestTaxesInsuranceHoaAndMi
          fiveLoans={fiveLoans}
          property={property}
        />
        <ActualPitiHoaWithoutShock
          fiveLoans={fiveLoans}
          hasSecondLoanType={hasSecondLoanType}
          property={property}
        />
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">Total of Reserves Available (Estimate)</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={calculate_reserve_total(
                    documentType,
                    borrowers,
                    accountSummaries,
                    residences,
                    property_to_be_sold,
                    loan,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <TotalReserveRequirement fiveLoans={fiveLoans} />
        <ReservesPositiveOrShortage fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <TopFrontHomeExpenseRatio fiveLoans={fiveLoans} />
        <BottomEndDebtToIncomeRatio fiveLoans={fiveLoans} />
        <MonthlyPaymentSavings fiveLoans={fiveLoans} />
        <PayOffDebtToQualifyBottomEndExpRatio fiveLoans={fiveLoans} />
        <AmountOffDebtToPayOffToQualify fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="3">Net Tangible Benefit &gt; 105%</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={1}
                  value={net_tangible_benefit(
                    documentType,
                    property_to_be_sold,
                    accountSummaries,
                    residences,
                    loan,
                    property,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <InterestOnlyPayment
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <AmortizedCombinedPaymentIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <TopFrontEndHomeExpenseRatioIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <BottomEndHomeExpenseRatioIOLoan
          fiveLoans={fiveLoans}
          hasInterestOnly={hasInterestOnly}
        />
        <ArmTerms fiveLoans={fiveLoans} hasArm={hasArm} />
        <QualifyingArmPaymentPitiHoaMiIncluded
          fiveLoans={fiveLoans}
          hasArm={hasArm}
        />
        <DtiTopFrontExpenseRatio fiveLoans={fiveLoans} hasArm={hasArm} />
        <DtiBottomEndExpenseRatio fiveLoans={fiveLoans} hasArm={hasArm} />
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">New Payment</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <CurrencyField
                  naked
                  fixedDigits={0}
                  value={new_payment(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <ExistingMonthlyPaymentsPIIncludesShortage fiveLoans={fiveLoans} />
        <ExistingPaymentsPayOff fiveLoans={fiveLoans} />
        <TotalOfExistingPayments fiveLoans={fiveLoans} />
        <SavingsWithNewLoan fiveLoans={fiveLoans} />
        <tr>
          <td colSpan="8" />
        </tr>
        <tr>
          <td colSpan="3">Annual Percentage Rate</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField
                  naked
                  fixedDigits={3}
                  value={apr(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              )}
            </td>
          ))}
        </tr>
        <tr>
          <td colSpan="3">Interest Rate on NEW 1st TD</td>
          {fiveLoans.map((loan, index) => (
            <td key={index}>
              {loan && (
                <PercentField naked decimalPlaces={3} value={loan.rate} />
              )}
            </td>
          ))}
        </tr>
      </tbody>
      <RefinanceTotalCostAnalysis updateSummary={updateSummary} />
    </>
  );
}

// Subordinated 2nd TD
function SubordinatedSecondTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      subordinated_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Subordinated 2nd TD</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Subordinated 3rd TD
function SubordinatedThirdTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      subordinated_third_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Subordinated 3rd TD</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Total of New 2nd Loan
function TotalOfNewSecondLoan({ fiveLoans }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Total of New 2nd Loan</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Current Loan Balance 2nd TD (Pay Off)
function CurrentLoanBalanceSecondTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      current_loan_balance_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Current Loan Balance 2nd TD (Pay Off)</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Current Loan Balance 3rd TD (Pay Off)
function CurrentLoanBalanceThirdTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      third_loan_balance(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Current Loan Balance 3rd TD (Pay Off)</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Pay Off Debt
function PayOffDebt({ fiveLoans }) {
  const [{ accountSummaries, residences }] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      balance_pay_offs_total(Object.values(accountSummaries), residences, loan)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Pay Off Debt</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Prepayment Penalty
function PrepaymentPenalty({ fiveLoans }) {
  const values = fiveLoans.map(
    loan => loan && new Decimal(loan.refinance_loan_calculator.prepay_penalty)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Prepayment Penalty</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Additional CASH OUT NEEDED
function AdditionalCashOutNeeded({ fiveLoans }) {
  const [{ property }, , { documentType }] = useDocument();
  const values = fiveLoans.map(
    loan => loan && additional_cash_out_needed(documentType, loan, property)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Additional CASH OUT NEEDED</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Estimate of Total Closing Costs
function EstimateOfTotalClosingCosts({ fiveLoans }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_closing_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Estimate of Total Closing Costs</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Monthly Payment Amount Subordinated 2nd TD
function MonthlyPaymentAmountSubordinatedSecondTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      monthly_payment_amount_subordinated_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Monthly Payment Amount Subordinated 2nd TD</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Monthly Payment Amount Subordinated 3rd TD
function MonthlyPaymentAmountSubordinatedThirdTd({ fiveLoans }) {
  const [
    { property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      monthly_payment_amount_subordinated_third_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Monthly Payment Amount Subordinated 3rd TD</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Existing Mo. Payment(s) P&amp;I Includes Shortage
function ExistingMonthlyPaymentsPIIncludesShortage({ fiveLoans }) {
  const [{ property_to_be_sold }] = useDocument();
  const values = fiveLoans.map(
    loan => loan && total_pi_mi_fees(property_to_be_sold)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Existing Mo. Payment(s) P&amp;I Includes Shortage</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Existing Payments P/Off
function ExistingPaymentsPayOff({ fiveLoans }) {
  const [{ accountSummaries, residences }] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      payment_savings_total(Object.values(accountSummaries), residences, loan)
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3">Existing Payments P/Off</td>
        {values.map((value, index) => (
          <td key={index}>
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// TOTAL OF EXISTING PAYMENTS
function TotalOfExistingPayments({ fiveLoans }) {
  const [{ property_to_be_sold, accountSummaries, residences }] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      total_of_existing_payments(
        property_to_be_sold,
        accountSummaries,
        residences,
        loan
      )
  );
  if (is_any_non_zero(values)) {
    return (
      <tr>
        <td colSpan="3" className="top-border">
          TOTAL OF EXISTING PAYMENTS
        </td>
        {values.map((value, index) => (
          <td key={index} className="top-border">
            {value && <CurrencyField naked fixedDigits={0} value={value} />}
          </td>
        ))}
      </tr>
    );
  }
  return <></>;
}

// Savings with NEW Loan
function SavingsWithNewLoan({ fiveLoans }) {
  const [
    {
      property_to_be_sold,
      accountSummaries,
      residences,
      property,
      is_selling_property
    },
    ,
    { documentType }
  ] = useDocument();
  const values = fiveLoans.map(
    loan =>
      loan &&
      savings_with_new_loan(
        documentType,
        property_to_be_sold,
        accountSummaries,
        residences,
        loan,
        property,
        is_selling_property
      )
  );
  return (
    <tr>
      <td className="thick-top-border thick-bottom-border" colSpan="3">
        Savings with NEW Loan
      </td>
      {values.map((value, index) => (
        <td
          className={class_list(
            value && value.lt(0) && "red-text",
            "thick-top-border",
            "thick-bottom-border",
            "bold"
          )}
          key={index}
        >
          {value && <CurrencyField naked fixedDigits={0} value={value} />}
        </td>
      ))}
    </tr>
  );
}
