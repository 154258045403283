import React, { cloneElement } from "react";
import "./css/training_tip.scss";
import { TooltipPopup, useTooltip } from "@reach/tooltip";

// Copied from Reach Tooltip then modified for our use.
const positionDefault = function positionDefault(triggerRect, tooltipRect) {
  const OFFSET = 8;

  const collisions = {
    top: triggerRect.top - tooltipRect.height - OFFSET < 0,
    right: window.innerWidth < triggerRect.left + tooltipRect.width,
    bottom: window.innerHeight < triggerRect.bottom + tooltipRect.height,
    left: triggerRect.left - tooltipRect.width < 0
  };

  const directionRight = collisions.right && !collisions.left;
  const directionDown = collisions.top && !collisions.bottom;

  return {
    left: directionRight
      ? triggerRect.right - tooltipRect.width + window.pageXOffset + "px"
      : triggerRect.left + window.pageXOffset + "px",
    top: directionDown
      ? triggerRect.top +
        OFFSET +
        triggerRect.height +
        window.pageYOffset +
        "px"
      : triggerRect.top -
        OFFSET -
        tooltipRect.height +
        window.pageYOffset +
        "px"
  };
};

function TrainingToolTip({ children, label, ariaLabel }) {
  const [trigger, tooltip] = useTooltip();

  return (
    <>
      {cloneElement(children, trigger)}
      <TooltipPopup
        {...tooltip}
        label={label}
        ariaLabel={ariaLabel}
        position={positionDefault}
      />
    </>
  );
}

export default function TrainingTip({ content }) {
  if (!content) {
    return <></>;
  }

  return (
    <TrainingToolTip label={content}>
      <div className="training-tip-question-mark">?</div>
    </TrainingToolTip>
  );
}
