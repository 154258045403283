import Decimal from "decimal.js";

import {
  FHA_UFMIP,
  FIXED_RATE,
  USDA_UFMIP,
  VARIABLE_RATE
} from "../constants/loan_rates";
import {
  AGENCY_PLUS_SUPER_CONFORMING_MINIMUM,
  ESCROW_FEE_PERCENT,
  LENDER_FEE,
  SHOCK_RATE_PERCENT,
  USDA_MONTHLY_MORTGAGE_INSURANCE_RATE
} from "../constants/fixed_values";
import {
  SECOND_LOAN_PERCENT,
  SECOND_LOAN_AMOUNT
} from "../constants/second_loan_options";
import { ARM_3_1, ARM_5_1, ARM_TERMS } from "../constants/loan_terms";
import { INVESTMENT_PROPERTY } from "../constants/property_classification";
import {
  FREDDIEMAC,
  CONVENTIONAL_LOANS,
  FHA_STREAMLINE,
  VA_IRRRL
} from "../constants/loan_types";
import {
  FIXED_LOAN_AMOUNT_PAYMENT_TYPES,
  CASH_DOWN_PAYMENT_TYPES,
  PERCENT_PAYMENT_TYPES,
  SECOND_LOAN_PAYMENT_TYPES
} from "../constants/payment_type";
import {
  CONDOMINIUM_TYPES,
  MANUFACTURED_PROPERTY_TYPES,
  MULTI_UNIT_TYPES
} from "../constants/property_type";
import { SERVICES_YOU_CANNOT_SHOP_FOR_APR } from "../constants/services_you_cannot_shop_for";
import { SERVICES_YOU_CAN_SHOP_FOR_APR } from "../constants/services_you_can_shop_for";

import { total_reserves_available } from "./reserve";
import * as property_calc from "./property";
import {
  BORROWER_PAID_FEE,
  LENDER_PAID_FEE
} from "../constants/origination_fee_paid_by";
import { NON_OWNER } from "../constants/occupancy";
import { RCD_FEE_GD, RCD_FEE_GD_TR, RCD_FEE_TD } from "../constants/loan_fees";
import { TITLE_OWNERS_TITLE_INSURANCE_OPTIONAL } from "../constants/other_services";
import { NO, YES } from "../constants/yes_no";
import {
  ADJUSTMENT_LIFE_CAP,
  ALT_INDEX,
  ARM_FIRST_CHANGE_MONTHS,
  FIRST_ADJUSTMENT_CAP_PERCENTS
} from "../constants/loan_arm_properties";
import {
  ADMINISTRATION_FEE,
  UNDERWRITING_FEE
} from "../constants/origination_fees";
import { pmt, principal_balance_as_of_term, rate } from "./utils";
import { compute_total_income } from "./income";
import {
  balance_pay_offs_total,
  payment_for_DTI_calc_total,
  payment_savings_total
} from "./debt_info";
import {
  is_fha,
  is_show_lpmi_adjustment_fees,
  is_usda,
  is_va,
  will_second_subordinate,
  will_third_subordinate
} from "../constants/utils";
import { INTEREST_ONLY } from "../constants/loan_payment_type";
import { PURCHASE, REFINANCE } from "../constants/document_types";
import { total_of_payments } from "./property";
import { mello_roos } from "./property";
import * as FREQUENCY from "../constants/frequencies";
import { total_pi_mi_fees } from "./property";
import { first_current_monthly_payment } from "./property";

/**
 * Loan Term (Years)
 */
export function loan_term_in_years(loan) {
  if (ARM_TERMS.includes(loan.term)) {
    return new Decimal(loan.arm_years);
  }
  return new Decimal(loan.term);
}

/**
 * Calculation for Loan Term (Months)
 */
export function loan_term(loan) {
  return loan_term_in_years(loan).mul(12);
}

/**
 * Calculation for LTV 1s TD
 */
export function ltv_first_td(documentType, loan, property) {
  if (CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(100).minus(
      new Decimal(loan.cash_down)
        .div(offer_price_or_market_value(documentType, loan, property))
        .mul(100)
    );
  }
  if (FIXED_LOAN_AMOUNT_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(loan.fixed_loan_amount)
      .div(offer_price_or_market_value(documentType, loan, property))
      .mul(100);
  }
  // Assume percent down.
  return new Decimal(100).minus(new Decimal(loan.percent_down));
}

/**
 * Calculation for EMD (Earnest Money Deposit)
 */
export function emd(documentType, loan, property) {
  return new Decimal(loan.emd)
    .div(100)
    .mul(offer_price_or_market_value(documentType, loan, property))
    .add(new Decimal(loan.emd_amount));
}

/**
 * Calculation for Seller Concessions (Optional)
 */
export function seller_concessions(documentType, loan, property) {
  return new Decimal(loan.seller_concessions)
    .div(100)
    .mul(offer_price_or_market_value(documentType, loan, property))
    .add(new Decimal(loan.seller_concessions_amount));
}

/**
 * Calculation for Final Total Loan to Value (TLTV)
 */
export function final_total_loan_to_value(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return loan_to_value(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).plus(
    combined_loan_to_value(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Calculation for Loan to Value (LTV)
 */
export function loan_to_value(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const offerPrice = offer_price_or_market_value(documentType, loan, property);
  if (offerPrice.equals(0)) {
    return new Decimal(0);
  }
  return loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .dividedBy(offerPrice)
    .mul(100);
}

/**
 * Calculation for Combined Loan to Value (CLTV)
 */
export function combined_loan_to_value(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const offerPrice = offer_price_or_market_value(documentType, loan, property);
  if (offerPrice.equals(0)) {
    return new Decimal(0);
  }
  return loan_amount_second_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      documentType === REFINANCE &&
        will_second_subordinate(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        ? current_second_loan_amount(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        : 0
    )
    .add(
      documentType === REFINANCE &&
        will_third_subordinate(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        ? current_third_loan_amount(
          loan,
          property_to_be_sold,
          is_selling_property
        )
        : 0
    )
    .dividedBy(offerPrice)
    .mul(100);
}

export function base_loan_amount_first_mortgage_percent_based(
  documentType,
  loan,
  property
) {
  return offer_price_or_market_value(documentType, loan, property).minus(
    offer_price_or_market_value(documentType, loan, property).mul(
      new Decimal(loan.percent_down).div(100)
    )
  );
}

export function upfront_mortgage_insurance_fee_fha(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (!is_fha(loan.type)) {
    return new Decimal(0);
  }
  const baseLoanAmount =
    documentType === PURCHASE
      ? loan_amount_first_mortgage_base(documentType, loan, property)
      : new Decimal(loan.refinance_loan_calculator.total_of_new_first_loan).sub(
        total_closing_costs_without_upfront_fees_and_lender_credits(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      );
  return baseLoanAmount.mul(FHA_UFMIP).div(100);
}

export function upfront_mortgage_insurance_fee_usda(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (!is_usda(loan.type)) {
    return new Decimal(0);
  }
  const baseLoanAmount =
    documentType === PURCHASE
      ? loan_amount_first_mortgage_base(documentType, loan, property)
      : new Decimal(loan.refinance_loan_calculator.total_of_new_first_loan).sub(
        total_closing_costs_without_upfront_fees_and_lender_credits(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      );
  return baseLoanAmount.mul(USDA_UFMIP).div(100);
}

export function upfront_funding_fee(documentType, loan, property) {
  return is_va(loan.type) && loan.va_upfront_funding_fee
    ? new Decimal(loan.va_upfront_funding_fee)
      .mul(loan_amount_first_mortgage_base(documentType, loan, property))
      .div(100)
    : new Decimal(0);
}

/**
 * Base first mortgage loan amount before considering loan types.
 */
export function loan_amount_first_mortgage_base(documentType, loan, property) {
  if (CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return offer_price_or_market_value(documentType, loan, property).sub(
      loan.cash_down
    );
  }
  if (FIXED_LOAN_AMOUNT_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(loan.fixed_loan_amount);
  }
  return base_loan_amount_first_mortgage_percent_based(
    documentType,
    loan,
    property
  );
}

/**
 * Calculation for Loan Amount (1st Mortgage)
 */
export function loan_amount_first_mortgage(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (documentType === REFINANCE) {
    return new Decimal(loan.refinance_loan_calculator.total_of_new_first_loan);
  }

  const baseAmount = loan_amount_first_mortgage_base(
    documentType,
    loan,
    property
  );
  if (is_va(loan.type)) {
    return baseAmount.add(loan.va_add_to_loan);
  }
  if (is_fha(loan.type) && loan.is_ufmip_financed === YES) {
    return baseAmount.plus(
      upfront_mortgage_insurance_fee_fha(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  if (is_usda(loan.type) && loan.is_ufmip_financed === YES) {
    return baseAmount.plus(
      upfront_mortgage_insurance_fee_usda(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return baseAmount;
}

export function loan_amount_second_mortgage(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (!SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(0);
  }
  // If refinance and 2nd subordinate, we don't have a second mortgage amount.
  if (
    documentType === REFINANCE &&
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  if (loan.second_mortgage_loan_percent_or_amount === SECOND_LOAN_PERCENT) {
    const loanPercent = new Decimal(loan.second_mortgage_loan_percent);
    return loanPercent
      .times(offer_price_or_market_value(documentType, loan, property))
      .dividedBy(100);
  } else if (
    loan.second_mortgage_loan_percent_or_amount === SECOND_LOAN_AMOUNT
  ) {
    return new Decimal(loan.second_mortgage_loan_amount);
  } else {
    return new Decimal(0);
  }
}

/**
 * Summary Sheet - Total of Loans
 */
export function total_of_loans(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    loan_amount_second_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Calculation for Actual Cash Down Payment
 */
export function actual_cash_down_payment(documentType, loan, property) {
  if (PERCENT_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return offer_price_or_market_value(documentType, loan, property).mul(
      new Decimal(loan.percent_down).div(100)
    );
  } else if (CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(loan.cash_down);
  } else if (FIXED_LOAN_AMOUNT_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return offer_price_or_market_value(documentType, loan, property).sub(
      loan.fixed_loan_amount
    );
  } else {
    return new Decimal(0);
  }
}

/**
 * Calculation for Estimated Cash to Close (Down Payment with Closing Costs)
 */
export function estimated_cash_to_close_down_payment_with_closing_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return actual_cash_down_payment(documentType, loan, property)
    .plus(
      total_closing_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .minus(emd(documentType, loan, property));
}

export function escrow_attorney_fee(documentType, loan, property) {
  return new Decimal(loan.escrow_loan_amount_fee)
    .times(ESCROW_FEE_PERCENT)
    .times(offer_price_or_market_value(documentType, loan, property));
}

export function escrow_fees_total(documentType, loan, property) {
  return escrow_attorney_fee(documentType, loan, property).plus(
    loan.escrow_flat_fee
  );
}

/**
 * Calculation for Liq Reserves After Close of Escrow
 */
export function liquid_reserves_after_close_of_escrow(
  documentType,
  borrowers,
  accountSummaries,
  residences,
  property_to_be_sold,
  loan,
  property,
  is_selling_property
) {
  const totalReserves = total_reserves_available(
    documentType,
    borrowers,
    accountSummaries,
    residences,
    property_to_be_sold,
    loan,
    is_selling_property
  );
  return totalReserves.minus(
    total_cost_to_purchase(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Calculation for 1st Mortgage Payment (P & I)
 */
export function first_mortgage_payment_p_and_i(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const interestOnlyTerms =
    loan.payment_type === INTEREST_ONLY
      ? new Decimal(loan.interest_only_period)
      : new Decimal(0);
  const ir = loan.rate
    ? new Decimal(loan.rate).div(12).div(100)
    : new Decimal(0);
  const np = loan_term(loan).sub(interestOnlyTerms);
  const pv = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
  return pmt(ir, np, pv);
}

export function second_mortgage_payment_p_and_i(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (!SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return new Decimal(0);
  }
  if (
    documentType === REFINANCE &&
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  let R, n, Pv;
  try {
    if (loan.second_mortgage_loan_rate_type === VARIABLE_RATE) {
      R = new Decimal(heloc_rate(loan)).dividedBy(12);
    } else if (loan.second_mortgage_loan_rate_type === FIXED_RATE) {
      R = new Decimal(loan.second_mortgage_fixed_rate).dividedBy(12);
    } else {
      R = new Decimal(0);
    }

    R = R.div(100);
    n = new Decimal(loan.second_mortgage_loan_term_monthly);
    Pv = new Decimal(
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    ).neg();
    const result = pmt(R, n, Pv);
    if (result.isNaN()) {
      return new Decimal(0);
    }
    return result;
  } catch (e) {
    return new Decimal(0);
  }
}

/**
 * Down Payment Calculator - Qualifying ARM Interest Rate
 */
export function qualifying_arm_interest_rate(loan) {
  if (
    ARM_TERMS.includes(loan.term) &&
    !ARM_qualifying_rate(loan).eq(new Decimal(loan.rate))
  ) {
    return ARM_qualifying_rate(loan);
  }
  return new Decimal(0);
}

/**
 * Down Payment Calculator - Qualifying ARM 1st Mort. (P & I)
 */
export function qualifying_arm_first_mortgage_p_and_i(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (qualifying_arm_interest_rate(loan).gt(0)) {
    return pmt(
      qualifying_arm_interest_rate(loan)
        .div(100)
        .div(12),
      loan_term(loan),
      loan_amount_first_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      ).neg()
    );
  }
  return new Decimal(0);
}

/**
 * Down Payment Calculator - Qualifying ARM 1st Mort. (P & I)
 */
export function qualifying_arm_first_mortgage_piti_hoa_mi_included(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (qualifying_arm_interest_rate(loan).gt(0)) {
    return qualifying_arm_first_mortgage_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        second_mortgage_payment_p_and_i(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(
        payment_shock_rate(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(property_tax(documentType, loan, property))
      .add(property_calc.home_owners_insurance_monthly(documentType, property))
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(property_calc.hoa_dues_1(property, loan))
      .add(property_calc.hoa_dues_2(property, loan))
      .add(property_calc.mello_roos(property, loan))
      .add(
        documentType === REFINANCE &&
          will_second_subordinate(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )
          ? property_to_be_sold.second_current_monthly_payment
          : 0
      )
      .add(
        documentType === REFINANCE &&
          will_third_subordinate(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )
          ? property_to_be_sold.miscellaneous_housing_payment
          : 0
      );
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - DTI Top / Front  Expense Ratio
 */
export function dti_top_front_expense_ratio(
  documentType,
  loan,
  property,
  borrowers,
  investment_properties,
  property_to_be_sold,
  is_selling_property
) {
  if (qualifying_arm_interest_rate(loan).gt(0)) {
    return qualifying_arm_first_mortgage_piti_hoa_mi_included(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - DTI Bottom End Expense Ratio
 */
export function dti_bottom_end_expense_ratio(
  documentType,
  loan,
  property,
  borrowers,
  investment_properties,
  accountSummaries,
  residences,
  property_to_be_sold,
  is_selling_property
) {
  if (qualifying_arm_interest_rate(loan).gt(0)) {
    return qualifying_arm_first_mortgage_piti_hoa_mi_included(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        payment_for_DTI_calc_total(
          Object.values(accountSummaries),
          residences,
          investment_properties,
          loan
        )
      )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

/**
 * Calculation for Property Tax (Monthly)
 */
export function property_tax(documentType, loan, property) {
  if (documentType === REFINANCE) {
    const value = property.refinance_property_tax_amount;
    switch (property.refinance_property_tax_frequency) {
      case FREQUENCY.ANNUALLY:
        return new Decimal(value).div(12);
      case FREQUENCY.QUARTERLY:
        return new Decimal(value).div(3);
      default:
        return new Decimal(value);
    }
  }
  return new Decimal(loan.offer_price)
    .mul(new Decimal(property.property_tax_percent).div(100))
    .div(12);
}

/**
 * Calculation for Property Tax (Annual)
 */
export function annual_property_tax(documentType, loan, property) {
  if (documentType === REFINANCE) {
    return property_tax(documentType, loan, property).mul(12);
  }
  return new Decimal(loan.offer_price).mul(
    new Decimal(property.property_tax_percent).div(100)
  );
}

export function heloc_rate(loan) {
  return new Decimal(loan.second_mortgage_loan_index).plus(
    loan.second_mortgage_loan_margin
  );
}

/**
 * Calculation for Total Monthly Payment
 */
export function total_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return first_mortgage_payment_p_and_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      second_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      payment_shock_rate(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(property_tax(documentType, loan, property))
    .add(property_calc.home_owners_insurance_monthly(documentType, property))
    .add(
      mortgage_insurance_premium_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(property_calc.hoa_dues_1(property, loan))
    .add(property_calc.hoa_dues_2(property, loan))
    .add(property_calc.mello_roos(property, loan))
    .add(
      documentType === REFINANCE &&
        will_second_subordinate(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        ? property_to_be_sold.second_current_monthly_payment
        : 0
    )
    .add(
      documentType === REFINANCE &&
        will_third_subordinate(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        ? property_to_be_sold.miscellaneous_housing_payment
        : 0
    );
}

/**
 * Down Payment Calculator - Qualifying Amortizing P&I Payment
 */
export function total_qualifying_amortizing_payment_p_and_i_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return qualifying_amortizing_payment_p_and_i_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(property_tax(documentType, loan, property))
      .add(property_calc.home_owners_insurance_monthly(documentType, property))
      .add(
        mortgage_insurance_premium_monthly(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(property_calc.hoa_dues_1(property, loan))
      .add(property_calc.hoa_dues_2(property, loan))
      .add(property_calc.mello_roos(property, loan))
      .add(
        documentType === REFINANCE &&
          will_second_subordinate(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )
          ? property_to_be_sold.second_current_monthly_payment
          : 0
      )
      .add(
        documentType === REFINANCE &&
          will_third_subordinate(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )
          ? property_to_be_sold.miscellaneous_housing_payment
          : 0
      );
  }
  return new Decimal(0);
}

export function jumbo_reserves_required_total(loan) {
  return new Decimal(loan.jumbo_months_of_reserves)
    .plus(loan.jumbo_reserves_for_other_properties)
    .plus(loan.jumbo_arm_months_of_reserves);
}

export function ARM_index_rate(loan) {
  return new Decimal(loan.arm_index_percent).plus(loan.arm_margin);
}

export function arm_index(loan) {
  return loan.arm_index_type === ALT_INDEX
    ? new Decimal(0)
    : new Decimal(loan.arm_index_percent);
}

export function arm_alt_index(loan) {
  return loan.arm_index_type === ALT_INDEX
    ? new Decimal(loan.arm_index_percent)
    : new Decimal(0);
}

export function arm_first_payment_rate(rate, arm_caps) {
  return new Decimal(rate).plus(FIRST_ADJUSTMENT_CAP_PERCENTS[arm_caps]);
}

export function arm_second_payment_rate(rate, arm_caps) {
  const adjustmentCap = new Decimal(FIRST_ADJUSTMENT_CAP_PERCENTS[arm_caps]);
  return arm_first_payment_rate(rate, arm_caps).add(
    adjustmentCap.eq(ADJUSTMENT_LIFE_CAP[arm_caps]) ? 0 : adjustmentCap
  );
}

export function arm_max_payment_rate(rate, arm_caps) {
  return new Decimal(rate).add(ADJUSTMENT_LIFE_CAP[arm_caps]);
}

export function arm_first_payment_adjust(loan) {
  return new Decimal(ARM_FIRST_CHANGE_MONTHS[loan.term]).add(1);
}

export function arm_second_payment_adjust(loan) {
  return new Decimal(ARM_FIRST_CHANGE_MONTHS[loan.term]).add(12);
}

export function arm_third_payment_adjust(loan) {
  return new Decimal(ARM_FIRST_CHANGE_MONTHS[loan.term]).add(24);
}

export function arm_first_payment_amount(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const ir = arm_first_payment_rate(loan.rate, loan.arm_caps)
    .div(12)
    .div(100);
  const np = loan_term(loan);
  const pv = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
  return pmt(ir, np, pv);
}

export function arm_second_payment_amount(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const ir = arm_second_payment_rate(loan.rate, loan.arm_caps)
    .div(12)
    .div(100);
  const np = loan_term(loan);
  const pv = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
  return pmt(ir, np, pv);
}

export function arm_max_payment_amount(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const ir = arm_max_payment_rate(loan.rate, loan.arm_caps)
    .div(12)
    .div(100);
  const np = loan_term(loan);
  const pv = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
  return pmt(ir, np, pv);
}

export function ARM_qualifying_rate(loan) {
  const indexMarginRate = ARM_index_rate(loan);
  let noteRate;
  try {
    noteRate = new Decimal(loan.rate);
  } catch (e) {
    noteRate = new Decimal(0);
  }
  if ([ARM_3_1, ARM_5_1].includes(loan.term)) {
    const actualNoteRate = noteRate.plus(2);
    return indexMarginRate.lessThan(actualNoteRate)
      ? actualNoteRate
      : indexMarginRate;
  } else {
    return indexMarginRate.lessThan(noteRate) ? noteRate : indexMarginRate;
  }
}

export function va_total_fee(documentType, loan, property) {
  return loan_amount_first_mortgage_base(documentType, loan, property)
    .mul(loan.va_upfront_funding_fee || 0)
    .div(100);
}

export function va_total_add_to_loan(documentType, loan, property) {
  return va_total_fee(documentType, loan, property).minus(loan.va_add_to_loan);
}

/**
 * Lender loan percent.
 */
export function lender_credit_cost(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  includeBrokerFee
) {
  const { loan_options } = loan;
  const fields = [
    loan.base_fee,
    loan_options.broker_special,
    loan_options.fico_ltv_adjust,
    loan_options.high_balance_loan_amount,
    loan_options.interest_only,
    loan_options.loan_amount,
    loan_options.lock_period_adjust,
    loan_options.miscellaneous_fee,
    loan_options.no_impounds,
    loan_options.purchase_special,
    loan_options.state_of_zone_add
  ];

  if (includeBrokerFee) {
    fields.push(loan_options.broker_banker_fee);
  }
  if (MANUFACTURED_PROPERTY_TYPES.includes(loan.property_type)) {
    fields.push(loan_options.manufactured_modular_add_on_fee);
  }

  if (is_show_lpmi_adjustment_fees(documentType, loan, property)) {
    fields.push(loan_options.lpmi_adjustment_base_loan);
    fields.push(loan_options.term_25_years_and_less);
    fields.push(loan_options.cash_out);
    fields.push(loan_options.second_home);
    fields.push(loan_options.loan_amount_greater_than_424100);
  }
  if (
    loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).gte(AGENCY_PLUS_SUPER_CONFORMING_MINIMUM)
  ) {
    fields.push(loan_options.agency_plus_super_conforming);
  }
  if (SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    fields.push(loan_options.subordanite_add_on_fee);
  }

  // Refinance
  if (documentType === REFINANCE) {
    fields.push(loan_options.du_lp_refi_plus_add_on_fee);
    fields.push(loan_options.refinance_cash_out);
    fields.push(loan_options.refinance_cash_out_high_balance_loans);
  }

  if (ARM_TERMS.includes(loan.term)) {
    fields.push(loan_options.arm_add_on_fee);
  }
  if (property.classification === INVESTMENT_PROPERTY) {
    fields.push(loan_options.investment_property_add_on_fee);
  }
  if (FREDDIEMAC === loan.type) {
    fields.push(loan_options.freddiemac_add_on_fee);
  }
  if (CONDOMINIUM_TYPES.includes(loan.property_type)) {
    fields.push(loan_options.condominium_add_on_fee);
  }
  if (MULTI_UNIT_TYPES.includes(loan.property_type)) {
    fields.push(loan_options.multi_unit_add_on_fee);
  }
  return fields.reduce((accum, value) => {
    return accum.plus(value || 0);
  }, new Decimal(0));
}

export function lender_amount_total(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  includeBankerFee
) {
  return lender_credit_cost(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBankerFee
  )
    .div(100)
    .times(
      loan_amount_first_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * LE Price Engine Options - Borrower Closing Costs Minus LO Origination Fee
 */
export function borrower_closing_costs_minus_lo_origination_fee(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const underwritingFee = loan.origination_fees[UNDERWRITING_FEE] || 0;
  const administrationFee = loan.origination_fees[ADMINISTRATION_FEE] || 0;

  return total_other_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      total_services_you_cannot_shop_for(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(total_services_you_can_shop_for(loan))
    .add(underwritingFee)
    .add(administrationFee);
}

/**
 * LE Price Engine Options - Lender Credit To Closing Costs
 */
export function lender_credit_to_closing_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const isBorrowerPaidFee = loan.origination_fee_paid_by === BORROWER_PAID_FEE;
  const lenderTotalAmount = lender_amount_total(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    false
  );
  return isBorrowerPaidFee && lenderTotalAmount.lt(0)
    ? lenderTotalAmount
    : new Decimal(0);
}

/**
 * LE Price Engine Options - Balance Remaining of Credit - Fees to be Paid
 */
export function balance_remaining_of_credit(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return borrower_closing_costs_minus_lo_origination_fee(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    lender_credit_to_closing_costs(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

export function payment_shock_rate(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (
    documentType === REFINANCE &&
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  if (
    SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type) &&
    loan.second_mortgage_loan_rate_type === VARIABLE_RATE
  ) {
    return loan_amount_second_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).mul(SHOCK_RATE_PERCENT);
  }
  return new Decimal(0);
}

export function mortgage_insurance_government_fee(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (!is_fha(loan.type) && !is_usda(loan.type)) {
    return new Decimal(0);
  }
  const baseLoanAmount =
    documentType === PURCHASE
      ? loan_amount_first_mortgage_base(documentType, loan, property)
      : new Decimal(loan.refinance_loan_calculator.total_of_new_first_loan);
  return baseLoanAmount
    .sub(
      documentType === REFINANCE
        ? total_closing_costs_without_upfront_fees_and_lender_credits(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        ).add(
          total_other_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )
        )
        : 0
    )
    .times(
      is_fha(loan.type)
        ? loan.mortgage_insurance_fha_rate
        : USDA_MONTHLY_MORTGAGE_INSURANCE_RATE
    )
    .div(100)
    .dividedBy(12);
}

export function mortgage_insurance_pmi_monthly_fee(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (
    CONVENTIONAL_LOANS.includes(loan.type) &&
    ltv_first_td(documentType, loan, property).gt(80.01) &&
    loan.mortgage_insurance_is_monthly === YES
  ) {
    return loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .times(loan.mortgage_insurance_usda_rate_factor_pmi)
      .dividedBy(12)
      .div(100);
  }
  return new Decimal(0);
}

export function total_adjustments_other_credits(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;
  const adjustmentsTotal = new Decimal(0)
    .add(loan.other_adjustments.borrower_paid_fees)
    .add(isPurchase ? loan.other_adjustments.cash_deposit_on_sales_contract : 0)
    .add(isPurchase ? loan.other_adjustments.employer_assisted_housing : 0)
    .add(isPurchase ? loan.other_adjustments.lease_purchase_fund : 0)
    .add(loan.other_adjustments.lender_credit)
    .add(isPurchase ? loan.other_adjustments.relocation_funds : 0)
    .add(isPurchase ? loan.other_adjustments.seller_credit : 0)
    .add(isRefinance ? loan.other_adjustments.miscellaneous_credit_one : 0)
    .add(isRefinance ? loan.other_adjustments.miscellaneous_credit_two : 0)
    .add(isRefinance ? loan.other_adjustments.miscellaneous_credit_three : 0);

  return SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)
    ? adjustmentsTotal.plus(
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    : adjustmentsTotal;
}

export function total_lpmi_adjustment_fees(loan) {
  return new Decimal(loan.loan_options.lpmi_adjustment_base_loan)
    .plus(loan.loan_options.term_25_years_and_less)
    .plus(loan.loan_options.cash_out)
    .plus(loan.loan_options.r_t_refinance)
    .plus(loan.loan_options.second_home)
    .plus(loan.loan_options.non_owner)
    .plus(loan.loan_options.loan_amount_greater_than_424100);
}

export function mortgage_broker_fee(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return new Decimal(loan.loan_options.broker_banker_fee)
    .div(100)
    .times(
      loan_amount_first_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

export function percent_of_loan_amount(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const includeBrokerFee = loan.origination_fee_paid_by === LENDER_PAID_FEE;
  const lenderLoanPercent = lender_credit_cost(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBrokerFee
  );
  return lenderLoanPercent.gt(0) ? lenderLoanPercent : new Decimal(0);
}

export function loan_origination_fee(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return percent_of_loan_amount(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).eq(0)
    ? new Decimal(0)
    : percent_of_loan_amount(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .div(100)
      .times(
        loan_amount_first_mortgage(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      );
}

export function recording_fees_and_other_taxes(loan) {
  return new Decimal(0)
    .add(loan.trust_deed)
    .add(loan.grand_deed)
    .add(loan.in_out_trust);
}

export function total_origination_charges(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const isBorrowerPaid = loan.origination_fee_paid_by !== LENDER_PAID_FEE;
  return Object.values(loan.origination_fees)
    .reduce((total, fee) => total.plus(fee), new Decimal(0))
    .add(
      loan_origination_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      isBorrowerPaid
        ? mortgage_broker_fee(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
        : 0
    );
}

export function total_services_you_cannot_shop_for(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return Object.values(loan.services_you_cannot_shop_for)
    .reduce((total, fee) => total.plus(fee), new Decimal(0))
    .add(
      upfront_mortgage_insurance_fee_fha(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      upfront_mortgage_insurance_fee_usda(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(upfront_funding_fee(documentType, loan, property));
}

export function total_services_you_can_shop_for(loan) {
  return Object.values(loan.services_you_can_shop_for).reduce(
    (total, fee) => total.plus(fee),
    new Decimal(0)
  );
}

export function total_loan_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_origination_charges(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      total_services_you_cannot_shop_for(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(total_services_you_can_shop_for(loan));
}

export const transfer_taxes_calculator = {
  transfer_amount: function(documentType, loan, property) {
    return offer_price_or_market_value(documentType, loan, property).div(1000);
  },
  transfer_total: function(documentType, loan, transferTaxes, property) {
    return transfer_taxes_calculator
      .transfer_amount(documentType, loan, property)
      .mul(transferTaxes.base_value_k);
  },
  county_transfer_amount: function(
    documentType,
    loan,
    transferTaxes,
    property
  ) {
    return transfer_taxes_calculator
      .transfer_amount(documentType, loan, property)
      .mul(transferTaxes.county_base_tax_k);
  },
  city_transfer_amount: function(documentType, loan, transferTaxes, property) {
    return transfer_taxes_calculator
      .transfer_amount(documentType, loan, property)
      .mul(transferTaxes.city_base_tax_k);
  },
  total_of_transfer_taxes: function(
    documentType,
    loan,
    transferTaxes,
    property
  ) {
    return transfer_taxes_calculator
      .transfer_total(documentType, loan, transferTaxes, property)
      .add(
        transfer_taxes_calculator.county_transfer_amount(
          documentType,
          loan,
          transferTaxes,
          property
        )
      )
      .add(
        transfer_taxes_calculator.city_transfer_amount(
          documentType,
          loan,
          transferTaxes,
          property
        )
      );
  },
  borrower_cost: function(documentType, loan, transferTaxes, property) {
    return transfer_taxes_calculator
      .total_of_transfer_taxes(documentType, loan, transferTaxes, property)
      .mul(transferTaxes.percent_split)
      .div(100);
  }
};

export function transfer_tax_purchase(documentType, loan, property) {
  return transfer_taxes_calculator
    .county_transfer_amount(documentType, loan, loan.transfer_taxes, property)
    .add(
      transfer_taxes_calculator.city_transfer_amount(
        documentType,
        loan,
        loan.transfer_taxes,
        property
      )
    )
    .add(
      transfer_taxes_calculator.transfer_total(
        documentType,
        loan,
        loan.transfer_taxes,
        property
      )
    )
    .mul(loan.transfer_taxes.percent_split)
    .div(100);
}

export function rcd_fees(loan, property) {
  const isNonOwner = property.occupancy === NON_OWNER;
  const isInOutTrust = new Decimal(loan.in_out_trust).gt(0);

  return new Decimal(0)
    .add(isNonOwner ? RCD_FEE_TD : 0)
    .add(isNonOwner ? RCD_FEE_GD : 0)
    .add(isInOutTrust ? RCD_FEE_GD_TR : 0);
}

export function total_taxes_and_other_government_fees(
  documentType,
  loan,
  property
) {
  if (documentType === PURCHASE) {
    return recording_fees_and_other_taxes(loan)
      .add(transfer_tax_purchase(documentType, loan, property))
      .add(rcd_fees(loan, property));
  }
  // Refinance
  return recording_fees_and_other_taxes(loan).add(
    loan.ca_affordable_housing_recording_fee_co_sur_charge
  );
}

export function prepaids_homeowners_insurance_premium(
  documentType,
  loan,
  property
) {
  return property_calc
    .home_owners_insurance_monthly(documentType, property)
    .mul(loan.prepaids_homeowners_insurance_premium_months);
}

export function mortgage_insurance_premium_monthly(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return mortgage_insurance_government_fee(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    mortgage_insurance_pmi_monthly_fee(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

export function prepaids_mortgage_insurance_premium(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return mortgage_insurance_premium_monthly(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).mul(loan.prepaids_mortgage_insurance_premium_months);
}

export const prepaids_calculator = {
  prepaid_interest: function(
    documentType,
    loan,
    prepaids,
    property,
    property_to_be_sold,
    is_selling_property
  ) {
    if (
      !prepaids.days_of_interest ||
      new Decimal(prepaids.days_of_interest).eq(0)
    ) {
      return new Decimal(0);
    }
    return loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .mul(loan.rate)
      .div(100)
      .div(prepaids.days_of_interest);
  },
  total_interest: function(
    documentType,
    loan,
    prepaids,
    property,
    property_to_be_sold,
    is_selling_property
  ) {
    return prepaids_calculator
      .prepaid_interest(
        documentType,
        loan,
        prepaids,
        property,
        property_to_be_sold,
        is_selling_property
      )
      .mul(prepaids.per_days);
  }
};

export function prepaids_property_taxes(documentType, loan, property) {
  return property_tax(documentType, loan, property).mul(
    loan.prepaids_property_taxes_months
  );
}

export function total_prepaids(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return prepaids_homeowners_insurance_premium(documentType, loan, property)
    .add(
      is_fha(loan.type) || is_usda(loan.type)
        ? 0
        : prepaids_mortgage_insurance_premium(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
    )
    .add(
      prepaids_calculator.total_interest(
        documentType,
        loan,
        loan.prepaids,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(prepaids_property_taxes(documentType, loan, property))
    .add(loan.prepaids_real_estate_taxes_due);
}

export function initial_escrow_payment_at_closing_homeowners_insurance(
  documentType,
  loan,
  property
) {
  return property_calc
    .home_owners_insurance_monthly(documentType, property)
    .mul(loan.initial_escrow_payment_at_closing_homeowners_insurance_months);
}

export function initial_escrow_payment_at_closing_mortgage_insurance(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return mortgage_insurance_premium_monthly(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).mul(loan.initial_escrow_payment_at_closing_mortgage_insurance_months);
}

export function initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
  documentType,
  loan,
  property
) {
  return property_tax(documentType, loan, property).add(
    mello_roos(property, loan)
  );
}

export function initial_escrow_payment_at_closing_property_taxes(
  documentType,
  loan,
  property
) {
  return initial_escrow_payment_at_closing_property_taxes_mello_roos_monthly(
    documentType,
    loan,
    property
  ).mul(loan.initial_escrow_payment_at_closing_property_taxes_months);
}

export function total_initial_escrow_payment_at_closing(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return initial_escrow_payment_at_closing_homeowners_insurance(
    documentType,
    loan,
    property
  )
    .add(
      is_fha(loan.type) || is_usda(loan.type)
        ? 0
        : initial_escrow_payment_at_closing_mortgage_insurance(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
    )
    .add(
      initial_escrow_payment_at_closing_property_taxes(
        documentType,
        loan,
        property
      )
    );
}

/**
 * Reserves - Equity From Sale Calculator - Full Month of Interest for FHA Loan
 */
export function full_month_of_interest_for_fha_loan(
  is_selling_property,
  property_to_be_sold
) {
  return is_selling_property && property_to_be_sold.fha_loan === YES
    ? new Decimal(property_to_be_sold.existing_mortgage_insurance)
    : new Decimal(0);
}

/**
 * Closing Costs - Other - Section total
 */
export function total_other(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return Object.values(loan.other_services)
    .reduce((total, fee) => total.plus(fee), new Decimal(0))
    .add(
      documentType === REFINANCE
        ? payoff_request_fee(property_to_be_sold, is_selling_property)
        : 0
    );
}

export function total_other_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_taxes_and_other_government_fees(documentType, loan, property)
    .add(
      total_prepaids(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      total_initial_escrow_payment_at_closing(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      total_other(documentType, loan, property_to_be_sold, is_selling_property)
    );
}

export function d_plus_i(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_loan_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    total_other_costs(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

export function lender_credits(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const includeBrokerFee = loan.origination_fee_paid_by === LENDER_PAID_FEE;
  const lenderCredits = lender_amount_total(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBrokerFee
  );
  return lenderCredits.lt(0) ? lenderCredits : new Decimal(0);
}

export function total_closing_costs(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return d_plus_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    lender_credits(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Refinance - helper function for calculating Upfront Funding Fees / Upfront
 * Mortgage Insurance Fees and Mortgage Insurance (Government Fee)
 */
export function total_closing_costs_without_upfront_fees_and_lender_credits(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_loan_costs_without_upfront_fees(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    total_other_costs(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Refinance - helper function for Total Loan Costs (A + B + C) without any
 * upfront fees.
 */
export function total_loan_costs_without_upfront_fees(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return (
    total_origination_charges(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      // Services You Cannot Shop For without Upfront Fees.
      .add(
        Object.values(loan.services_you_cannot_shop_for).reduce(
          (total, fee) => total.plus(fee),
          new Decimal(0)
        )
      )
      .add(total_services_you_can_shop_for(loan))
  );
}

/**
 * Closing Costs - Calculating Cash to Close - Deposit
 */
export function deposit(documentType, loan, property) {
  return emd(documentType, loan, property).neg();
}

/**
 * Closing Costs - Calculating Cash to Close - Loan Credits
 */
export function loan_credits(documentType, loan, property) {
  const titleOwnersTitleInsuranceOptional =
    loan.other_services[TITLE_OWNERS_TITLE_INSURANCE_OPTIONAL];
  return new Decimal(
    titleOwnersTitleInsuranceOptional ? titleOwnersTitleInsuranceOptional : 0
  ).add(transfer_tax_purchase(documentType, loan, property));
}

/**
 * Closing Costs - Calculating Cash to Close - Seller Credits
 */
export function seller_credits(documentType, loan, property) {
  return seller_concessions(documentType, loan, property)
    .add(loan_credits(documentType, loan, property))
    .neg();
}

/**
 * Closing Costs - Calculating Cash to Close - Adjustments and Other Credits
 */
export function adjustments_and_other_credits(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_adjustments_other_credits(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).neg();
}

/**
 * Closing Costs - Calculating Cash to Close - Total Cost to Purchase
 */
export function total_cost_to_purchase(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_closing_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(actual_cash_down_payment(documentType, loan, property))
    .sub(
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Closing Costs - Calculating Cash to Close - Estimated Cash to Close
 */
export function estimated_cash_to_close(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  accountSummaries,
  residences
) {
  if (documentType === REFINANCE) {
    return loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        total_closing_costs(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        ).neg()
      )
      .add(
        estimated_total_payoffs_and_payments(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property,
          accountSummaries,
          residences
        )
      );
  }
  // Purchase
  return total_closing_costs(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(actual_cash_down_payment(documentType, loan, property))
    .add(deposit(documentType, loan, property))
    .add(loan.funds_for_borrower)
    .add(seller_credits(documentType, loan, property))
    .add(
      adjustments_and_other_credits(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Closing Costs - Calculating Cash to Close - Estimated Closing Costs Financed
 */
export function estimated_closing_costs_financed(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (is_fha(loan.type) && loan.is_ufmip_financed === YES) {
    return upfront_mortgage_insurance_fee_fha(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    );
  }
  if (is_usda(loan.type) && loan.is_ufmip_financed === YES) {
    return upfront_mortgage_insurance_fee_usda(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    );
  }
  if (is_va(loan.type)) {
    return new Decimal(loan.va_add_to_loan);
  }
  return new Decimal(0);
}

/**
 * Closing Costs - ARM Table - Maximum Interest Rate
 */
export function arm_max_interest_rate(loan) {
  return new Decimal(loan.rate).add(ADJUSTMENT_LIFE_CAP[loan.arm_caps]);
}

/**
 * Closing Costs - ARM Table - Beginning Month
 */
export function arm_beginning_month(loan) {
  return new Decimal(ARM_FIRST_CHANGE_MONTHS[loan.term]).add(1);
}

/**
 * Summary Sheet - Total Down Pymt Cash To Lender
 */
export function total_down_payment_cash_to_lender(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return actual_cash_down_payment(documentType, loan, property).add(
    loan_amount_second_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Summary Sheet - Interest Rate on NEW Second Loan
 */
export function interest_rate_on_new_second_loan(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (
    documentType === REFINANCE &&
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  if (SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    if (loan.second_mortgage_loan_rate_type === FIXED_RATE) {
      return new Decimal(loan.second_mortgage_fixed_rate);
    } else {
      // Variable Rate
      return heloc_rate(loan);
    }
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Monthly Payment Amount New 2nd TD
 */
export function monthly_payment_amount_new_second_td(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type)) {
    return second_mortgage_payment_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    );
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Total of All New Loan(s)
 */
export function total_of_all_new_loans(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return payment_shock_rate(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      documentType === REFINANCE
        ? monthly_payment_amount_subordinated_second_td(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        : 0
    )
    .add(
      documentType === REFINANCE
        ? monthly_payment_amount_subordinated_third_td(
          documentType,
          loan,
          property_to_be_sold,
          is_selling_property
        )
        : 0
    )
    .add(
      second_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      first_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      mortgage_insurance_pmi_monthly_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      mortgage_insurance_government_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Summary Sheet - Actual Principal and Interest - Monthly Payment
 */
export function actual_principal_and_interest_monthly_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (
    SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type) &&
    loan.second_mortgage_loan_rate_type === VARIABLE_RATE &&
    (documentType === PURCHASE ||
      !will_second_subordinate(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      ))
  ) {
    return first_mortgage_payment_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        second_mortgage_payment_p_and_i(
          documentType,
          loan,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .add(
        documentType === REFINANCE
          ? monthly_payment_amount_subordinated_third_td(
            documentType,
            loan,
            property_to_be_sold,
            is_selling_property
          )
          : 0
      );
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Qualifying Principal Interest Taxes Insurance (HOA and MI)
 */
export function qualifying_principal_interest_taxes_insurance_hoa_and_mi(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_monthly_payment(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
}

/**
 * Summary Sheet - Actual PITI HOA w/Out Shock
 */
export function actual_piti_hoa_without_shock(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (
    SECOND_LOAN_PAYMENT_TYPES.includes(loan.down_payment_type) &&
    loan.second_mortgage_loan_rate_type === VARIABLE_RATE &&
    (documentType === PURCHASE ||
      !will_second_subordinate(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      ))
  ) {
    return total_monthly_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).sub(
      payment_shock_rate(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Top / Front Home Expense Ratio
 */
export function top_front_home_expense_ratio(
  documentType,
  loan,
  property,
  borrowers,
  investment_properties,
  property_to_be_sold,
  is_selling_property
) {
  const monthlyIncome = compute_total_income(
    borrowers,
    investment_properties
  ).div(12);
  return monthlyIncome.eq(0)
    ? new Decimal(0)
    : total_monthly_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .div(monthlyIncome)
      .mul(100);
}

/**
 * Summary Sheet - In 5 Years Total Paid (PITI, MI and Closing Fee's)
 */
export function in_five_years_total_paid(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const interestOnlyTerms =
    loan.payment_type === INTEREST_ONLY
      ? new Decimal(loan.interest_only_period)
      : new Decimal(0);
  const mortgagePaymentIO = first_mortgage_payment_interest_only(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).mul(Decimal.min(new Decimal(60), interestOnlyTerms));

  let mortgagePaymentPI = first_mortgage_payment_p_and_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).mul(new Decimal(60).sub(interestOnlyTerms));
  if (interestOnlyTerms.gte(60)) {
    mortgagePaymentPI = new Decimal(0);
  }

  return mortgagePaymentPI
    .add(mortgagePaymentIO)
    .add(
      total_loan_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      mortgage_insurance_premium_monthly(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      ).mul(60)
    )
    .add(
      prepaids_calculator.total_interest(
        documentType,
        loan,
        loan.prepaids,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Summary Sheet - In 5 Years Principal you will have Paid Off
 */
export function in_five_years_principal_paid_off(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const interestOnlyTerms =
    loan.payment_type === INTEREST_ONLY
      ? new Decimal(loan.interest_only_period)
      : new Decimal(0);
  if (interestOnlyTerms.gte(60)) {
    return new Decimal(0);
  }

  return loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).sub(
    principal_balance_as_of_term(
      loan_amount_first_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      ),
      first_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      ),
      loan.rate,
      new Decimal(60).sub(interestOnlyTerms)
    )
  );
}

/**
 * Summary Sheet - Total Interest Percentage (TIP)
 */
export function total_interest_percentage(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  // Total interest = total payment + prepaid interest.
  const loanAmountFirstMortgage = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  return loanAmountFirstMortgage.eq(0)
    ? new Decimal(0)
    : first_mortgage_payment_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .mul(loan_term(loan))
      .add(
        prepaids_calculator.total_interest(
          documentType,
          loan,
          loan.prepaids,
          property,
          property_to_be_sold,
          is_selling_property
        )
      )
      .sub(loanAmountFirstMortgage)
      .div(loanAmountFirstMortgage)
      .mul(100);
}

/**
 * Down Payment Calculator - 1st Mortgage Payment (P & I) (Interest Only)
 */
export function first_mortgage_payment_interest_only(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .mul(loan.rate)
      .div(100)
      .div(12);
  }
  return new Decimal(0);
}

/**
 * Down Payment Calculator - Qualifying Amortizing P&I Payment
 */
export function qualifying_amortizing_payment_p_and_i_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    const loanTerm = loan_term(loan);
    if (loanTerm.eq(360) || loanTerm.eq(480)) {
      const ir = new Decimal(loan.rate).div(12).div(100);
      const np = loanTerm.sub(120);
      const pv = loan_amount_first_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      ).neg();
      return pmt(ir, np, pv);
    }
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Top / Front End Home Expense Ratio I/O Loan
 */
export function top_front_end_home_expense_ratio_io_loan(
  documentType,
  loan,
  property,
  borrowers,
  investment_properties,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return total_qualifying_amortizing_payment_p_and_i_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Bottom End Home Expense Ratio I/O Loan
 */
export function bottom_end_home_expense_ratio_io_loan(
  documentType,
  loan,
  property,
  borrowers,
  investment_properties,
  accountSummaries,
  residences,
  property_to_be_sold,
  is_selling_property
) {
  if (loan.payment_type === INTEREST_ONLY) {
    return total_qualifying_amortizing_payment_p_and_i_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
      .add(
        payment_for_DTI_calc_total(
          Object.values(accountSummaries),
          residences,
          investment_properties,
          loan
        )
      )
      .div(compute_total_income(borrowers, investment_properties).div(12))
      .mul(100);
  }
  return new Decimal(0);
}

export function total_services_you_cannot_shop_for_apr(loan) {
  return Object.entries(loan.services_you_cannot_shop_for).reduce(
    (total, [name, fee]) => {
      return SERVICES_YOU_CANNOT_SHOP_FOR_APR.includes(name)
        ? total.add(fee)
        : total;
    },
    new Decimal(0)
  );
}

export function total_services_you_can_shop_for_apr(loan) {
  return Object.entries(loan.services_you_can_shop_for).reduce(
    (total, [name, fee]) =>
      SERVICES_YOU_CAN_SHOP_FOR_APR.includes(name) ? total.add(fee) : total,
    new Decimal(0)
  );
}

export function total_prepaids_apr(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return prepaids_calculator
    .total_interest(
      documentType,
      loan,
      loan.prepaids,
      property,
      property_to_be_sold,
      is_selling_property
    )
    .add(
      prepaids_mortgage_insurance_premium(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

export function total_apr_fees(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return total_origination_charges(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(total_services_you_cannot_shop_for_apr(loan))
    .add(total_services_you_can_shop_for_apr(loan))
    .add(
      total_prepaids_apr(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      initial_escrow_payment_at_closing_mortgage_insurance(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Down Payment Calculator - APR
 */
export function apr(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const actualAmountFinanced = loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).sub(
    total_apr_fees(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
  return rate(
    loan_term(loan),
    first_mortgage_payment_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).neg(),
    actualAmountFinanced
  )
    .mul(100)
    .mul(12);
}

/**
 * Down Payment Calculator - Lender Credit Status
 */
export function lender_credit_status(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  const balanceRemainingOfCredit = balance_remaining_of_credit(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  );
  if (
    loan.origination_fee_paid_by === BORROWER_PAID_FEE &&
    balanceRemainingOfCredit.lt(0)
  ) {
    return balanceRemainingOfCredit;
  }
  return new Decimal(0);
}

/**
 * LE Price Engine - Rate Calculator
 */
export function total_rate_calculator(rate_calculator) {
  return Object.values(rate_calculator)
    .reduce((total, rate) => {
      return total.add(rate);
    }, new Decimal(0))
    .toDP(3);
}

/**
 * Refinance - Closing Costs - Payoff Request Fee
 */
export function payoff_request_fee(property_to_be_sold, is_selling_property) {
  if (is_selling_property !== YES) {
    return new Decimal(0);
  }
  const secondMonthly = new Decimal(
    property_to_be_sold.second_current_monthly_payment
  );
  const secondPayOff = new Decimal(
    property_to_be_sold.second_loan_total_pay_off_amount
  );
  const thirdMonthly = new Decimal(
    property_to_be_sold.miscellaneous_housing_payment
  );
  const thirdPayOff = new Decimal(
    property_to_be_sold.third_loan_total_pay_off_amount
  );
  return new Decimal(0)
    .add(
      new Decimal(property_to_be_sold.first_loan_total_pay_off_amount).gt(0)
        ? 0
        : first_current_monthly_payment(property_to_be_sold).add(LENDER_FEE)
    )
    .add(
      secondMonthly.gt(0) && secondPayOff.eq(0)
        ? secondMonthly.add(LENDER_FEE)
        : 0
    )
    .add(
      thirdMonthly.gt(0) && thirdPayOff.eq(0) ? thirdMonthly.add(LENDER_FEE) : 0
    );
}

/**
 * Refinance - Loan Configurator - Loan Calculator - Current Loan Balance 1st TD
 */
export function current_loan_balance_first_td(
  property_to_be_sold,
  is_selling_property
) {
  if (is_selling_property !== YES) {
    return new Decimal(0);
  }
  const firstLoanTotalPayOffAmount = new Decimal(
    property_to_be_sold.first_loan_total_pay_off_amount
  );
  return firstLoanTotalPayOffAmount.gt(0)
    ? firstLoanTotalPayOffAmount
    : new Decimal(property_to_be_sold.first_loan_balance);
}

/**
 * Refinance - Loan Configurator - Loan Calculator - Current Loan Balance 2nd TD
 */
export function current_loan_balance_second_td(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  if (
    !will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    ) &&
    is_selling_property === YES
  ) {
    return current_second_loan_amount(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    );
  }
  return new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Loan Calculator - 3rd Loan Balance
 */
export function third_loan_balance(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (
    will_third_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(0);
  }
  if (
    !will_third_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    ) &&
    is_selling_property === YES
  ) {
    return current_third_loan_amount(
      loan,
      property_to_be_sold,
      is_selling_property
    );
  }
  return new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Loan Calculator - VA Add UFF To Loan
 */
export function va_add_to_loan(loan) {
  return is_va(loan.type) ? new Decimal(loan.va_add_to_loan) : new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Loan Calculator - Add Funds To / From
 * Borrower
 */
export function add_funds_to_from_borrower(
  documentType,
  loan_calculator,
  loan,
  accountSummaries,
  residences,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return new Decimal(loan_calculator.total_of_new_first_loan)
    .add(
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(
      current_loan_balance_first_td(property_to_be_sold, is_selling_property)
    )
    .sub(
      current_loan_balance_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(
      third_loan_balance(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(
      balance_pay_offs_total(Object.values(accountSummaries), residences, loan)
    )
    .sub(loan_calculator.prepay_penalty)
    .sub(additional_cash_out_needed(documentType, loan, property))
    .sub(va_add_to_loan(loan))
    .sub(
      total_closing_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(loan_calculator.closing_cost_pad);
}

/**
 * Refinance - Closing Costs - Estimated Total Payoffs and Payments - Mortgage
 */
export function estimated_total_payoffs_and_payments_mortgage(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (is_selling_property !== YES) {
    return new Decimal(0);
  }
  if (
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(property_to_be_sold.first_loan_balance).neg();
  }
  if (
    !will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
  ) {
    return new Decimal(property_to_be_sold.first_loan_balance)
      .add(property_to_be_sold.second_loan_balance)
      .neg();
  }
  return current_loan_balance_first_td(property_to_be_sold, is_selling_property)
    .add(
      current_loan_balance_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .neg();
}

/**
 * Refinance - Closing Costs - Estimated Total Payoffs and Payments - Debt
 */
export function estimated_total_payoffs_and_payments_debt(
  accountSummaries,
  residences,
  loan
) {
  const balancePayOff = balance_pay_offs_total(
    Object.values(accountSummaries),
    residences,
    loan
  );
  return balancePayOff.gt(0) ? balancePayOff.neg() : new Decimal(0);
}

/**
 * Refinance - Closing Costs - Estimated Total Payoffs and Payments
 */
export function estimated_total_payoffs_and_payments(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property,
  accountSummaries,
  residences
) {
  return estimated_total_payoffs_and_payments_mortgage(
    documentType,
    loan,
    property_to_be_sold,
    is_selling_property
  ).add(
    estimated_total_payoffs_and_payments_debt(
      accountSummaries,
      residences,
      loan
    )
  );
}

/**
 * Down Payment / Refinance Loan Configurator
 * Purchase calculations are mostly based on offer price while refinance
 * calculations are based on market value (listed price).
 */
export function offer_price_or_market_value(documentType, loan, property) {
  return documentType === REFINANCE
    ? new Decimal(property.listed_price)
    : new Decimal(loan.offer_price);
}

/**
 * Refinance - Loan Configurator - Cash Out (Add to Loan Amount)
 */
export function cash_out_add_to_loan_amount(documentType, loan, property) {
  if (documentType !== REFINANCE) {
    return new Decimal(0);
  }
  return loan.rate_and_term_refi === NO
    ? new Decimal(loan.refinance_cash_out_add_to_loan_amount_percent)
      .div(100)
      .mul(property.listed_price)
      .add(loan.refinance_cash_out_add_to_loan_amount)
    : new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Loan Calculator - Additional Cash Out Needed
 */
export function additional_cash_out_needed(documentType, loan, property) {
  if (documentType !== REFINANCE) {
    return new Decimal(0);
  }
  if (cash_out_add_to_loan_amount(documentType, loan, property).gt(0)) {
    return cash_out_add_to_loan_amount(documentType, loan, property);
  }
  if (
    (loan.type === FHA_STREAMLINE || loan.type === VA_IRRRL) &&
    new Decimal(loan.max_cash_back_rate_term_refi_va_fha).gt(0)
  ) {
    return new Decimal(loan.max_cash_back_rate_term_refi_va_fha);
  }
  if (
    loan.rate_and_term_refi === YES &&
    new Decimal(loan.max_cash_out_at_coe).gt(0)
  ) {
    return new Decimal(loan.max_cash_out_at_coe);
  }
  return new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Total Cost to Close (Sec. J + Cash Dn.)
 */
export function total_cost_to_close(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  if (
    documentType === REFINANCE &&
    CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type)
  ) {
    return total_closing_costs(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).add(loan.cash_down);
  }
  return new Decimal(0);
}

/**
 * Refinance - Loan Configurator - Loan Configurator - Current 2nd Loan Amount
 */
export function current_second_loan_amount(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (documentType !== REFINANCE || is_selling_property !== YES) {
    return new Decimal(0);
  }
  const monthly = new Decimal(
    property_to_be_sold.second_current_monthly_payment
  );
  const payOff = new Decimal(
    property_to_be_sold.second_loan_total_pay_off_amount
  );
  if (monthly.gt(0) && payOff.gt(0)) {
    return payOff;
  }
  return new Decimal(property_to_be_sold.second_loan_balance);
}

/**
 * Refinance - Loan Configurator - Loan Configurator - Current 3rd Loan Amount
 */
export function current_third_loan_amount(
  loan,
  property_to_be_sold,
  is_selling_property
) {
  if (is_selling_property !== YES) {
    return new Decimal(0);
  }
  const monthly = new Decimal(
    property_to_be_sold.miscellaneous_housing_payment
  );
  const payOff = new Decimal(
    property_to_be_sold.third_loan_total_pay_off_amount
  );
  if (monthly.eq(0)) {
    return new Decimal(0);
  }
  if (payOff.gt(0)) {
    return payOff;
  }
  return new Decimal(property_to_be_sold.third_loan_balance);
}

/**
 * Refinance - Summary Sheet - Additional Funds To / From Borrower (COE)
 */
export function additional_funds_to_from_borrower(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property,
  accountSummaries,
  residences
) {
  return loan_amount_first_mortgage(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      loan_amount_second_mortgage(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(property_to_be_sold.first_loan_balance)
    .sub(
      current_loan_balance_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(
      third_loan_balance(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .sub(
      balance_pay_offs_total(Object.values(accountSummaries), residences, loan)
    )
    .sub(loan.refinance_loan_calculator.prepay_penalty)
    .sub(additional_cash_out_needed(documentType, loan, property))
    .sub(
      total_closing_costs(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Refinance - Summary Sheet - TOTAL OF EXISTING PAYMENTS
 */
export function total_of_existing_payments(
  property_to_be_sold,
  accountSummaries,
  residences,
  loan
) {
  return total_pi_mi_fees(property_to_be_sold).add(
    payment_savings_total(Object.values(accountSummaries), residences, loan)
  );
}

/**
 * Refinance - Summary Sheet - Savings with NEW Loan
 */
export function savings_with_new_loan(
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  property,
  is_selling_property
) {
  return total_of_existing_payments(
    property_to_be_sold,
    accountSummaries,
    residences,
    loan
  ).sub(
    new_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Refinance - Summary Sheet - Net Tangible Benefit > 105%
 */
export function net_tangible_benefit(
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  property,
  is_selling_property
) {
  if (
    first_mortgage_payment_p_and_i(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    ).eq(0)
  ) {
    return new Decimal(0);
  }
  return total_of_payments(property_to_be_sold)
    .add(
      payment_savings_total(Object.values(accountSummaries), residences, loan)
    )
    .mul(100)
    .div(
      first_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}

/**
 * Refinance - Summary Sheet - Subordinated 2nd TD
 */
export function subordinated_second_td(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return will_second_subordinate(
    documentType,
    loan,
    property_to_be_sold,
    is_selling_property
  )
    ? current_second_loan_amount(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
    : new Decimal(0);
}

/**
 * Refinance - Summary Sheet - Subordinated 3rd TD
 */
export function subordinated_third_td(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return will_third_subordinate(
    documentType,
    loan,
    property_to_be_sold,
    is_selling_property
  )
    ? current_third_loan_amount(loan, property_to_be_sold, is_selling_property)
    : new Decimal(0);
}

/**
 * Refinance - Summary Sheet - Monthly Payment Amount Subordinated 2nd TD
 */
export function monthly_payment_amount_subordinated_second_td(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return new Decimal(
    will_second_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
      ? property_to_be_sold.second_current_monthly_payment
      : 0
  );
}

/**
 * Refinance - Summary Sheet - Monthly Payment Amount Subordinated 3rd TD
 */
export function monthly_payment_amount_subordinated_third_td(
  documentType,
  loan,
  property_to_be_sold,
  is_selling_property
) {
  return new Decimal(
    will_third_subordinate(
      documentType,
      loan,
      property_to_be_sold,
      is_selling_property
    )
      ? property_to_be_sold.miscellaneous_housing_payment
      : 0
  );
}

/**
 * Refinance - Summary Sheet - New Payment
 */
export function new_payment(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return first_mortgage_payment_p_and_i(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      second_mortgage_payment_p_and_i(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      monthly_payment_amount_subordinated_second_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      monthly_payment_amount_subordinated_third_td(
        documentType,
        loan,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      mortgage_insurance_pmi_monthly_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    )
    .add(
      mortgage_insurance_government_fee(
        documentType,
        loan,
        property,
        property_to_be_sold,
        is_selling_property
      )
    );
}
