import React from "react";
import "./css/section.scss";

export default function Section({ children, name, className }) {
  return (
    <div className="section">
      <div className="section-name">{name}</div>
      <div className={className}>{children}</div>
    </div>
  );
}
