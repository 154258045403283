export const default_debt_info_account = {
  id: "", // Must be populated by generate_id
  borrower: "",
  liability_type: "",
  description: "",
  account_number: "",
  monthly_payment: "0.00",
  current_balance: "0.00",
  high_credit_limit: "0.00"
};

export const default_debt_info_residence = {
  id: "", // Must be populated by generate_id
  address: "",
  principal_balance: "0",
  first_mortgage: "0.00",
  second_mortgage: "0.00",
  taxes: "0.00",
  insurance: "0.00",
  hoa: "0.00",
  reserves: "2"
};