export const INCLUDE_IN_DTI = "0";
export const OMITTED = "1";
export const RESUBORDINATED = "2";
export const WILL_BE_PAID_OFF = "3";

export default Object.freeze({
  [INCLUDE_IN_DTI]: "Include in DTI",
  [OMITTED]: "Omitted",
  [RESUBORDINATED]: "Resubordinated",
  [WILL_BE_PAID_OFF]: "Will be paid off"
});
