import React from "react";

import CurrencyField from "../../fields/currency_field";
import { first_mortgage_payment_p_and_i } from "../../../calculations/loan";
import { useDocument } from "../loan_tool";
import NumberField from "../../fields/number_field";
import Decimal from "decimal.js";
import Section from "../../ui/section";

export default function AdjustablePaymentTable({ loan }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();

  return (
    <Section className="ap-table" name="Adjustable Payment">
      <div className="ap-table-title">Adjustable Payment (AP) Table</div>
      <div className="ap-table-content">
        <div className="ap-table-row">
          <div className="right-border">Interest Only Payments?</div>
          <div>Yes for your first {loan.interest_only_period} Payments</div>
        </div>
        <div className="ap-table-row">
          <div className="right-border">Optional Payments?</div>
          <div>No</div>
        </div>
        <div className="ap-table-row">
          <div className="right-border">Step Payments?</div>
          <div>No</div>
        </div>
        <div className="ap-table-row">
          <div className="right-border">Seasonal Payments?</div>
          <div>No</div>
        </div>
        <div className="ap-table-row">
          <div className="bold">Monthly Principal and Interest Payments.</div>
        </div>
        <div className="ap-table-row">
          <div>First Change/Amount</div>
          <div>
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />{" "}
            at{" "}
            <NumberField
              naked
              value={new Decimal(loan.interest_only_period).add(1)}
            />
            st payment
          </div>
        </div>
        <div className="ap-table-row">
          <div>Subsequent Changes</div>
          <div>No Subsequent Changes</div>
        </div>
        <div className="ap-table-row">
          <div>Maximum Payment</div>
          <div>
            <CurrencyField
              naked
              fixedDigits={0}
              value={first_mortgage_payment_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />{" "}
            Starting at{" "}
            <NumberField
              naked
              value={new Decimal(loan.interest_only_period).add(1)}
            />{" "}
            Payment
          </div>
        </div>
      </div>
    </Section>
  );
}
