import React, { useState, useEffect } from "react";
import TextField from "../components/fields/text_field";
import UserService from "../services/user_service";
import StandardTemplate from "../templates/standard_template/standard_template";
import { Link, navigate } from "@reach/router";

export default function PasswordReset(props) {
  const searchParams = new URLSearchParams(props.location.search);
  const username = searchParams.get("un");
  const ticket = searchParams.get("vt");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isRequestSubmitted, setRequestSubmitted] = useState(false);

  const onSubmit = async () => {
    setErrorMessage("");
    try {
      await UserService.postPasswordReset(
        username,
        ticket,
        newPassword,
        confirmPassword
      );
      setRequestSubmitted(true);
    } catch (e) {
      setErrorMessage(
        "Request failed, please check the new password and confirm password are matching and try again later"
      );
    }
  };

  async function onEnterSubmit(event) {
    if (event.key === "Enter") {
      await onSubmit();
    }
  }

  useEffect(() => {
    if (!username || !ticket) {
      navigate("/");
    }
  }, [username, ticket]);

  return (
    <>
      {username && ticket && (
        <StandardTemplate className="password-reset">
          <div className="password-reset-title">Reset Your Password</div>
          <div className="password-reset-content flex-direction-column align-items-center">
            <div className="error-message">{errorMessage}</div>
            {!isRequestSubmitted && (
              <div className="password-reset-action">
                <TextField
                  name="New Password"
                  fieldName="newPassword"
                  value={newPassword}
                  onChange={value => setNewPassword(value.newPassword)}
                  typeOverride="password"
                  onKeyPress={onEnterSubmit}
                />
                <TextField
                  name="Confirm Password"
                  fieldName="confirmPassword"
                  value={confirmPassword}
                  onChange={value => setConfirmPassword(value.confirmPassword)}
                  typeOverride="password"
                  onKeyPress={onEnterSubmit}
                />
                <div className="right-align">
                  <button className="button" onClick={onSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            )}
            {isRequestSubmitted && (
              <div className="center-align">
                Password has been reset. Proceed to{" "}
                <Link to="/" className="button">
                  Login
                </Link>
              </div>
            )}
          </div>
        </StandardTemplate>
      )}
    </>
  );
}
