import { FNM, LANIS_DOCUMENT_TYPES } from "../fnm_constants";

export function exportDocumentType({ documentType }) {
  return {
    purposeOfLoan: LANIS_DOCUMENT_TYPES.export[documentType]
  }
}

export function exportLoanOriginatorInformation({ accountProfile, companyProfile }) {
  const loanOriginatorsName = [
    accountProfile.first_name,
    accountProfile.middle_name,
    accountProfile.last_name,
  ].filter(Boolean).join(" ");
  return {
    thisApplicationWasTakenBy: FNM.APPLICATION_TAKEN_BY.INTERNET_OR_EMAIL,
    loanOriginatorsName,
    loanOriginatorsPhoneNumber: accountProfile.direct_contact_phone,
    loanOriginationCompanysName: companyProfile.company_name,
    loanOriginationCompanysStreetAddress: companyProfile.business_address,
    loanOriginationCompanysStreetAddress2: companyProfile.business_address_2,
    loanOriginationCompanysCity: companyProfile.city,
    loanOriginationCompanysStateCode: companyProfile.state,
    loanOriginationCompanysZipCode: companyProfile.zip
  }
}

export function exportLoanOriginationAdditionalData({ companyProfile }) {
  return [
    { identifier: "LoanOriginatorID" },
    { identifier: "LoanOriginationCompanyID", value: companyProfile.company_nmls_number },
    { identifier: "SupervisoryAppraiserLicenseNumber" }
  ]
}
