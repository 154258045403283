export const INVESTMENT_PROPERTY_ADD_ON_FEE_TYPES = {
  "1": "N/O/O: <=75%",
  "2": "N/O/O: 75.01-80%",
  "3": "N/O/O: > 80.01%"
};

export const AGENCY_PLUS_SUPER_CONFORMING_TYPES = {
  "1": "Super Conform",
  "2": "High Balance Lender Add",
  "3": "Fixed Cash-out - additional",
  "4": "ARM Cash-out - additional",
  "5": "LTV <= 75%",
  "6": "LTV > 75%"
};

export const SUBORDANITE_ADD_ON_FEE_TYPES = {
  "1": "CLTV 65.01 - 75.00",
  "2": "CLTV 75.01 - 95.00",
  "3": "CLTV 65.01 - 90.00",
  "4": "Any CLTV > %"
};

export const FREDDIEMAC_ADD_ON_FEE_TYPES = {
  "1": "Fixed <= 75,000 Pur/Refi",
  "2": "Fixed 75,001 to 99,999 Pur/Refi",
  "3": "Fixed 100,000 to 350,000 Pur/Refi",
  "4": "Fixed 350,001 to 417,000 Refi Only",
  "5": "Fixed 350,001 to 417,000 Purchase Only"
};

export const CONDOMINIUM_ADD_ON_FEE_TYPES = {
  "1": "Condo Add < 75%",
  "2": "Condo Add 75.01% to 97%"
};

export const MULTI_UNIT_ADD_ON_FEE_TYPES = {
  "1": "2-4 Unit <= 80%",
  "2": "2-4 Unit > 80% & <==85%",
  "3": "2-4 Unit > 85%"
};

export const DU_LP_REFI_PLUS_ADD_ON_FEE_TYPES = {
  "1": "LTV 100%",
  "2": "LTV 105%",
  "3": "LTV 125%"
};
