import React, { useState, useEffect } from "react";
import "./css/sign_up.scss";
import stripe_logo from "../images/secure-stripe-payment-logo.png";

import StandardTemplate from "../templates/standard_template/standard_template";
import {
  default_account_profile,
  default_company_profile
} from "../schema/profile";
import { basic_monthly } from "../constants/subscription_products";
import UserService from "../services/user_service";
import { navigate } from "@reach/router";
import Spinner from "../components/ui/spinner";
import { handleErrorMessages } from "../services/utils";
import BasicSignupInformation from "../components/sign_up/basic_signup_information";
import PaymentPlans from "../components/sign_up/payment_plans";
import { useUser } from "../hooks/user";

export default function SignUpPage() {
  const [user, { setAccessToken }] = useUser();
  if (!!user && user.isLoggedIn) {
    navigate("/");
    return null;
  }
  const [profile, setProfile] = useState({ ...default_account_profile });
  const [companyProfile, setCompanyProfile] = useState({
    ...default_company_profile
  });
  const [selectedProduct, setSelectedProduct] = useState(basic_monthly);
  const [password, setPassword] = useState("");
  const [paymentErrors, setPaymentErrors] = useState([]);
  const [paymentName, setPaymentName] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    // If something set payment errors, ensure the spinner is hidden so that
    // the user can see the errors.
    if (paymentErrors.length > 0) {
      setShowSpinner(false);
    }
  }, [paymentErrors]);

  function onProfileChange(updatedProfile) {
    setProfile({
      ...profile,
      ...updatedProfile
    });
  }

  function onCompanyProfileChange(updatedCompanyProfile) {
    setCompanyProfile({
      ...companyProfile,
      ...updatedCompanyProfile
    });
  }

  async function isPaymentSkipped() {
    return await UserService.isPaymentSkipped(
        promoCode
    ).catch(error => handleErrorMessages(error, setPaymentErrors));
  }

  async function onPaymentSubmit(paymentMethod) {
    setShowSpinner(true);
    setPaymentErrors([]);
    await UserService.postRegistration(
      { ...profile },
      password,
      { ...companyProfile },
      selectedProduct,
      paymentName,
      profile.email,
      profile.cell_phone,
      promoCode,
      paymentMethod
    )
      .then((response) => {
        setAccessToken(response.access_token);
        navigate("/sign-up/receipt");
      })
      .catch(error => handleErrorMessages(error, setPaymentErrors));
    setShowSpinner(false);
  }

  return (
    <StandardTemplate className="sign-up">
      {showSpinner && <Spinner />}
      <div className="main-title">Subscribe</div>
      <div className="basic-info-section">
        <PaymentPlans
          product={selectedProduct}
          setProduct={setSelectedProduct}
        />
        <BasicSignupInformation
          profile={profile}
          onProfileChange={onProfileChange}
          companyProfile={companyProfile}
          onCompanyProfileChange={onCompanyProfileChange}
          password={password}
          onPasswordChange={value => setPassword(value.password)}
          onBeforeCreatePaymentMethod={() => setShowSpinner(true)}
          paymentName={paymentName}
          setPaymentName={setPaymentName}
          promoCode={promoCode}
          setPromoCode={setPromoCode}
          onSubmit={onPaymentSubmit}
          isPaymentSkipped={isPaymentSkipped}
          paymentErrors={paymentErrors}
          setPaymentErrors={setPaymentErrors}
        />
      </div>
      <div className="stripe-logo">
        <picture>
          <source srcset={stripe_logo} type="image/png" />
          <img src={stripe_logo} type="image/png" alt="Stripe logo" />
        </picture>
      </div>
    </StandardTemplate>
  );
}
