import { ARM_10_1, ARM_3_1, ARM_5_1, ARM_7_1 } from "./loan_terms";

export const CAPS_2_6 = "2/6";
export const CAPS_5_2_5 = "5/2/5";
export const CAPS_2_2_5 = "2/2/5";
export const CAPS_2_2_6 = "2/2/6";
export const CAPS_1_5 = "1/5";

export const FIRST_ADJUSTMENT_CAP_PERCENTS = Object.freeze({
  [CAPS_2_6]: "2",
  [CAPS_5_2_5]: "5",
  [CAPS_2_2_5]: "2",
  [CAPS_2_2_6]: "2",
  [CAPS_1_5]: "1"
});

export const ARM_FIRST_CHANGE_MONTHS = Object.freeze({
  [ARM_10_1]: "120",
  [ARM_3_1]: "36",
  [ARM_5_1]: "60",
  [ARM_7_1]: "84"
});

export const ADJUSTMENT_CAP_PERCENTS = Object.freeze({
  [CAPS_2_6]: "2",
  [CAPS_5_2_5]: "2",
  [CAPS_2_2_5]: "2",
  [CAPS_2_2_6]: "2",
  [CAPS_1_5]: "1"
});

export const ADJUSTMENT_LIFE_CAP = Object.freeze({
  [CAPS_2_6]: "6",
  [CAPS_5_2_5]: "5",
  [CAPS_2_2_5]: "5",
  [CAPS_2_2_6]: "6",
  [CAPS_1_5]: "5"
});

export const ARM_CAPS = Object.freeze({
  [CAPS_2_6]: "2/6",
  [CAPS_5_2_5]: "5/2/5",
  [CAPS_2_2_5]: "2/2/5",
  [CAPS_2_2_6]: "2/2/6",
  [CAPS_1_5]: "1/5"
});

export const MARGIN_2_00 = "2";
export const MARGIN_2_25 = "2.25";
export const MARGIN_2_50 = "2.5";
export const MARGIN_2_75 = "2.75";

export const ARM_MARGINS = Object.freeze({
  [MARGIN_2_00]: "2.00%",
  [MARGIN_2_25]: "2.25%",
  [MARGIN_2_50]: "2.50%",
  [MARGIN_2_75]: "2.75%"
});

export const LIBOR_1_MO = "1";
export const LIBOR_1_YR = "2";
export const T_BILL_1_YR = "3";
export const T_BILL_10_YR = "4";
export const LIBOR_3_MO = "5";
export const T_BILL_3_YR = "6";
export const T_BILL_5_YR = "7";
export const CD_6_MO = "8";
export const LIBOR_6_MO = "9";
export const T_BILL_6_MO = "10";
export const CODI = "11";
export const COFI = "12";
export const DAILY_CD_RATE = "13";
export const FEDERAL_COST_OF_FUNDS = "14";
export const FHLMC_60_DAY_REQ_NET_YIELD = "15";
export const FNMA_60_DAY_REQ_NET_YIELD = "16";
export const MTA = "17";
export const NATL_AVE_CONTRACT_RATE_FHLBB = "18";
export const NATL_MO_MEDIAN_COST_OF_FUNDS = "19";
export const PRIME_RATE = "20";
export const ALT_INDEX = "21";
export const SOFR = "22";

export const ARM_INDEX_TYPES = Object.freeze({
  [LIBOR_1_MO]: "1 Mo LIBOR",
  [LIBOR_1_YR]: "1 Yr. LIBOR",
  [T_BILL_1_YR]: "1 Yr. T-Bill",
  [T_BILL_10_YR]: "10 Yr. T-Bill",
  [LIBOR_3_MO]: "3 Mo LIBOR",
  [T_BILL_3_YR]: "3 Yr. T-Bill",
  [T_BILL_5_YR]: "5 Yr. T-Bill",
  [CD_6_MO]: "6 Mo CD",
  [LIBOR_6_MO]: "6 Mo LIBOR",
  [T_BILL_6_MO]: "6 Mo T-Bill",
  [ALT_INDEX]: "Alt Index",
  [CODI]: "CODI",
  [COFI]: "COFI",
  [DAILY_CD_RATE]: "Daily CD Rate",
  [FEDERAL_COST_OF_FUNDS]: "Federal Cost of Funds",
  [FHLMC_60_DAY_REQ_NET_YIELD]: "FHLMC 60 Day Req Net Yield",
  [FNMA_60_DAY_REQ_NET_YIELD]: "FNMA 60 Day Req Net Yield",
  [MTA]: "MTA",
  [NATL_AVE_CONTRACT_RATE_FHLBB]: "Natl Ave Contract Rate (FHLBB)",
  [NATL_MO_MEDIAN_COST_OF_FUNDS]: "Natl Mo Median Cost of Funds",
  [PRIME_RATE]: "Prime Rate",
  [SOFR]: "SOFR"
});

export const ADJUSTMENT_PERIOD = "12";
