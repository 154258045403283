export const ALIMONY = "0";
export const AUTOMOBILE_ALLOWANCE = "1";
export const BOARDER_INCOME = "2";
export const CAPITAL_GAINS = "3";
export const CHILD_SUPPORT = "4";
export const DISABILITY = "5";
export const EMPLOYMENT_RELATED_ASSETS = "6";
export const FOREIGN_INCOME = "7";
export const FOSTER_CARE = "8";
export const HOUSING_OR_PARSONAGE = "9";
export const INTEREST_AND_DIVIDENDS = "10";
export const MILITARY_BASE_PAY = "11";
export const MILITARY_CLOTHES_ALLOWANCE = "12";
export const MILITARY_COMBAT_PAY = "13";
export const MILITARY_FLIGHT_PAY = "14";
export const MILITARY_HAZARD_PAY = "15";
export const MILITARY_OVERSEAS_PAY = "16";
export const MILITARY_PROP_PAY = "17";
export const MILITARY_QUARTERS_ALLOWANCE = "18";
export const MILITARY_RATIONS_ALLOWANCE = "19";
export const MILITARY_VARIABLE_HOUSING_ALLOWANCE = "20";
export const MORTGAGE_CREDIT_CERTIFICATE = "21";
export const MORTGAGE_DIFFERENTIAL_PAYMENTS = "22";
export const NOTES_RECEIVABLE = "23";
export const OTHER_INCOME = "24";
export const PUBLIC_ASSISTANCE = "25";
export const REAL_ESTATE_MORTGAGE_DIFFERENTIAL_INCOME = "26";
export const RETIREMENT = "27";
export const ROYALTY_PAYMENTS = "28";
export const SEASONAL_INCOME = "29";
export const SEPARATE_MAINTENANCE = "30";
export const SOCIAL_SECURITY = "31";
export const SUBJECT_PROPERTY_NET_CASH_FLOW = "32";
export const TEMPORARY_LEAVE = "33";
export const TIP_INCOME = "34";
export const TRAILING_CO_BORROWER_INCOME = "35";
export const TRUST_INCOME = "36";
export const UNEMPLOYMENT = "37";
export const VA_BENEFITS = "38";

export default Object.freeze({
  [ALIMONY]: "Alimony",
  [AUTOMOBILE_ALLOWANCE]: "Automobile Allowance",
  [BOARDER_INCOME]: "Boarder Income",
  [CAPITAL_GAINS]: "Capital Gains",
  [CHILD_SUPPORT]: "Child Support",
  [DISABILITY]: "Disability",
  [EMPLOYMENT_RELATED_ASSETS]: "Employment Related Assets",
  [FOREIGN_INCOME]: "Foreign Income",
  [FOSTER_CARE]: "Foster Care",
  [HOUSING_OR_PARSONAGE]: "Housing or Parsonage",
  [INTEREST_AND_DIVIDENDS]: "Interest and Dividends",
  [MILITARY_BASE_PAY]: "Military Base Pay",
  [MILITARY_CLOTHES_ALLOWANCE]: "Military Clothes Allowance",
  [MILITARY_COMBAT_PAY]: "Military Combat Pay",
  [MILITARY_FLIGHT_PAY]: "Military Flight Pay",
  [MILITARY_HAZARD_PAY]: "Military Hazard Pay",
  [MILITARY_OVERSEAS_PAY]: "Military Overseas Pay",
  [MILITARY_PROP_PAY]: "Military Prop Pay",
  [MILITARY_QUARTERS_ALLOWANCE]: "Military Quarters Allowance",
  [MILITARY_RATIONS_ALLOWANCE]: "Military Rations Allowance",
  [MILITARY_VARIABLE_HOUSING_ALLOWANCE]: "Military Variable Housing Allowance",
  [MORTGAGE_CREDIT_CERTIFICATE]: "Mortgage Credit Certificate (MCC)",
  [MORTGAGE_DIFFERENTIAL_PAYMENTS]: "Mortgage Differential Payments",
  [NOTES_RECEIVABLE]: "Notes Receivable",
  [OTHER_INCOME]: "Other Income",
  [PUBLIC_ASSISTANCE]: "Public Assistance",
  [REAL_ESTATE_MORTGAGE_DIFFERENTIAL_INCOME]:
    "Real Estate, Mortgage Differential Income",
  [RETIREMENT]: "Retirement (e.g. Pension, IRA)",
  [ROYALTY_PAYMENTS]: "Royalty Payments",
  [SEASONAL_INCOME]: "Seasonal Income",
  [SEPARATE_MAINTENANCE]: "Separate Maintenance",
  [SOCIAL_SECURITY]: "Social Security",
  [SUBJECT_PROPERTY_NET_CASH_FLOW]:
    "Subject Property Net Cash Flow (2-4 unity O/O properties)",
  [TEMPORARY_LEAVE]: "Temporary Leave",
  [TIP_INCOME]: "Tip Income",
  [TRAILING_CO_BORROWER_INCOME]: "Trailing Co-Borrower Income",
  [TRUST_INCOME]: "Trust Income",
  [UNEMPLOYMENT]: "Unemployment/Welfare Income",
  [VA_BENEFITS]: "VA Benefits (Non-education)"
});
