import React from "react";

import { BORROWER_PAID_FEE } from "../../../constants/origination_fee_paid_by";
import {
  CASH_DOWN_PAYMENT_TYPES,
  SECOND_LOAN_PAYMENT_TYPES
} from "../../../constants/payment_type";
import { ARM_TERMS } from "../../../constants/loan_terms";
import { INTEREST_ONLY } from "../../../constants/loan_payment_type";
import { CONVENTIONAL_LOANS } from "../../../constants/loan_types";
import {
  debt_to_income_ratio,
  revised_dti
} from "../../../calculations/debt_info";
import {
  actual_cash_down_payment,
  apr,
  bottom_end_home_expense_ratio_io_loan,
  combined_loan_to_value,
  current_second_loan_amount,
  current_third_loan_amount,
  dti_bottom_end_expense_ratio,
  estimated_cash_to_close_down_payment_with_closing_costs,
  final_total_loan_to_value,
  first_mortgage_payment_interest_only,
  first_mortgage_payment_p_and_i,
  lender_credit_cost,
  lender_credit_status,
  liquid_reserves_after_close_of_escrow,
  loan_amount_first_mortgage,
  loan_amount_second_mortgage,
  loan_term,
  loan_to_value,
  ltv_first_td,
  mortgage_insurance_government_fee,
  mortgage_insurance_pmi_monthly_fee,
  payment_shock_rate,
  property_tax,
  qualifying_amortizing_payment_p_and_i_payment,
  qualifying_arm_first_mortgage_p_and_i,
  qualifying_arm_first_mortgage_piti_hoa_mi_included,
  second_mortgage_payment_p_and_i,
  total_closing_costs,
  total_cost_to_close,
  total_cost_to_purchase,
  total_monthly_payment,
  total_qualifying_amortizing_payment_p_and_i_payment
} from "../../../calculations/loan";
import { calculate_reserve_total } from "../../../calculations/reserve";
import {
  home_owners_insurance_monthly,
  hoa_dues_1,
  hoa_dues_2,
  mello_roos
} from "../../../calculations/property";

import CurrencyField from "../../fields/currency_field";
import PercentField from "../../fields/percent_field";

import { useDocument } from "../../loan_tools/loan_tool";
import { PURCHASE, REFINANCE } from "../../../constants/document_types";
import Section from "../../ui/section";
import {
  class_list,
  is_fha,
  is_usda,
  will_second_subordinate,
  will_third_subordinate
} from "../../../constants/utils";
import { HOA_PROPERTY_TYPES } from "../../../constants/property_type";
import SaveLoan from "../save_loan";
import { VARIABLE_RATE } from "../../../constants/loan_rates";
import { YES } from "../../../constants/yes_no";
import { wrap_investment_properties } from "../../../calculations/investment_reo";

export default function CalculationResults({ loan, updateLoan }) {
  const [document, , { documentType }] = useDocument();
  const {
    applicant,
    property,
    investment_properties,
    property_to_be_sold,
    residences,
    accountSummaries,
    is_selling_property
  } = document;
  const { borrowers } = document.applicant;
  const wrapped_investment_properties = wrap_investment_properties(
      property, investment_properties);
  const isARMLoanTerm = ARM_TERMS.includes(loan.term);
  const isSecondLoanPaymentType = SECOND_LOAN_PAYMENT_TYPES.includes(
    loan.down_payment_type
  );
  const showPMIMonthlyFee =
    CONVENTIONAL_LOANS.includes(loan.type) &&
    ltv_first_td(documentType, loan, property).greaterThan("80.01");
  const isInterestOnly = loan.payment_type === INTEREST_ONLY;

  const isRefinance = documentType === REFINANCE;
  const isPurchase = documentType === PURCHASE;

  const lenderCreditCost = lender_credit_cost(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    true
  );

  return (
    <Section
      className="down-payment-calculation-result"
      name="Calculation Result"
    >
      <div className="flex">
        <div className={isInterestOnly ? "forth-width" : "third-width"}>
          <PercentField
            readOnly
            fieldName="debt_to_income_ratio"
            name="Debt to Income Ratio (DTI)"
            fixedDigits={2}
            title="LANIS Training Tip: Depending on the Type of loan selected, the DTI can be a critical factor in getting the loan approved. Conforming loans should be less than 50%, FHA some Lenders may cap at 50% while others cap at 55%. Jumbo's may cap at 41% or 43%. Check the program guidelines before running AUS. If you are using a New 2nd Loan, the hard cap on DTI ratios is 38%/43% and a score of 700. Check for additional guidelines."
            value={debt_to_income_ratio(
              documentType,
              borrowers,
              wrapped_investment_properties,
              accountSummaries,
              property,
              residences,
              loan,
              property_to_be_sold,
              is_selling_property
            )}
          />

          {applicant.pay_off_debt_to_qualify === YES && (
            <PercentField
              readOnly
              name="Revised DTI Ratio"
              fixedDigits={2}
              value={revised_dti(
                documentType,
                applicant.pay_off_debt_to_qualify,
                borrowers,
                wrapped_investment_properties,
                accountSummaries,
                property,
                residences,
                loan,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {isARMLoanTerm && (
            <PercentField
              readOnly
              name="ARM Qual DTI Percent"
              fixedDigits={2}
              value={dti_bottom_end_expense_ratio(
                documentType,
                loan,
                property,
                borrowers,
                wrapped_investment_properties,
                accountSummaries,
                residences,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {loan.payment_type === INTEREST_ONLY && (
            <PercentField
              readOnly
              name="I/O Qual DTI Percent"
              value={bottom_end_home_expense_ratio_io_loan(
                documentType,
                loan,
                property,
                borrowers,
                wrapped_investment_properties,
                accountSummaries,
                residences,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          <div className="configure-dti">
            <div>Configure DTI</div>
            <SaveLoan name="Debt Info" path="debt-info" className="button" />
          </div>

          <PercentField
            readOnly
            fieldName="annual_percentage_rate"
            name="An. Percentage Rate (APR)"
            value={apr(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          <PercentField
            readOnly
            fieldName="final_total_loan_to_value"
            name="Final Total Loan to Value (TLTV)"
            fixedDigits={2}
            value={final_total_loan_to_value(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          <PercentField
            readOnly
            fieldName="loan_to_value"
            name="Loan to Value (LTV)"
            fixedDigits={2}
            value={loan_to_value(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          <PercentField
            readOnly
            fieldName="combined_loan_to_value"
            name="Combined LTV (CLTV)"
            fixedDigits={2}
            value={combined_loan_to_value(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          <PercentField
            readOnly
            fieldName="origination_charges"
            name="Origination Charges"
            className={class_list(
              lenderCreditCost.gt(3) && "red-outlined",
              lenderCreditCost.gte(2.75) &&
                lenderCreditCost.lte(3) &&
                "yellow-highlighted"
            )}
            value={lenderCreditCost}
            title="LANIS Training Tip: Loan Origination Chares are typically Capped at 3% maximum on Agency loans.  If the property is a Non Owner Occupied / Investment Property over 3% maybe allowed or if there is a Bona Fied Discount applied.  Please check with your Lender to understand the guidelines and make sure your loan is compliant."
          />

          {loan.origination_fee_paid_by === BORROWER_PAID_FEE && (
            <CurrencyField
              readOnly
              name="Lender Credit Status"
              value={lender_credit_status(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
        </div>

        <div className={isInterestOnly ? "forth-width" : "third-width"}>
          <CurrencyField
            readOnly
            fieldName="loan_amount_first_mortgage"
            name="Loan Amount (1st Mortgage)"
            value={loan_amount_first_mortgage(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          {isSecondLoanPaymentType && (
            <CurrencyField
              readOnly
              name="Loan Amount (2nd Mortgage)"
              value={loan_amount_second_mortgage(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {isRefinance &&
            will_second_subordinate(
              documentType,
              loan,
              property_to_be_sold,
              is_selling_property
            ) && (
            <CurrencyField
              readOnly
              name="Current 2nd Loan Amount"
              value={current_second_loan_amount(
                documentType,
                loan,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {isRefinance &&
            will_third_subordinate(
              documentType,
              loan,
              property_to_be_sold,
              is_selling_property
            ) && (
            <CurrencyField
              readOnly
              name="Current 3rd Loan Amount"
              value={current_third_loan_amount(
                loan,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {isPurchase && (
            <CurrencyField
              readOnly
              fieldName="actual_cash_down_payment"
              name="Actual Cash Down Payment"
              value={actual_cash_down_payment(documentType, loan, property)}
            />
          )}

          {isRefinance &&
            CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type) && (
            <CurrencyField
              readOnly
              name="Total Cost To Close"
              value={total_cost_to_close(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          <CurrencyField
            readOnly
            fieldName="total_closing_costs"
            name="Total Closing Costs"
            value={total_closing_costs(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          {isPurchase && (
            <CurrencyField
              readOnly
              fieldName="estimate_cash_to_close"
              name="Estimated Cash To Close"
              value={estimated_cash_to_close_down_payment_with_closing_costs(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}
          {isRefinance &&
            CASH_DOWN_PAYMENT_TYPES.includes(loan.down_payment_type) && (
            <CurrencyField
              readOnly
              name="Estimated Cash To Close"
              value={loan.cash_down}
            />
          )}

          <CurrencyField
            readOnly
            fieldName="total_reserves_available"
            name="Total Reserves Available"
            value={calculate_reserve_total(
              documentType,
              borrowers,
              accountSummaries,
              residences,
              property_to_be_sold,
              loan,
              is_selling_property
            )}
          />

          {isPurchase && (
            <>
              <CurrencyField
                readOnly
                fieldName="total_cost_to_purchase_home"
                name="Total Cost to Purchase Home"
                value={total_cost_to_purchase(
                  documentType,
                  loan,
                  property,
                  property_to_be_sold,
                  is_selling_property
                )}
              />
              <CurrencyField
                readOnly
                fieldName="liquid_reserves_after_close_of_escrow"
                name="Liq Reserves After Close of Escrow"
                value={liquid_reserves_after_close_of_escrow(
                  documentType,
                  borrowers,
                  accountSummaries,
                  residences,
                  property_to_be_sold,
                  loan,
                  property,
                  is_selling_property
                )}
              />
            </>
          )}
        </div>

        <div className={isInterestOnly ? "forth-width" : "third-width"}>
          <CurrencyField
            readOnly
            name="1st Loan Payment (P &amp; I)"
            value={first_mortgage_payment_p_and_i(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          {qualifying_arm_first_mortgage_p_and_i(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          ).gt(0) && (
            <CurrencyField
              readOnly
              name="Qualifying ARM 1st Loan (P & I)"
              value={qualifying_arm_first_mortgage_p_and_i(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {isSecondLoanPaymentType &&
            (isPurchase ||
              !will_second_subordinate(
                documentType,
                loan,
                property_to_be_sold,
                is_selling_property
              )) && (
              <>
                <CurrencyField
                  readOnly
                  name="2nd Mortgage Payment"
                  value={second_mortgage_payment_p_and_i(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
                {loan.second_mortgage_loan_rate_type === VARIABLE_RATE && (
                  <CurrencyField
                    readOnly
                    name="Payment Shock Rate"
                    value={payment_shock_rate(
                      documentType,
                      loan,
                      property,
                      property_to_be_sold,
                      is_selling_property
                    )}
                    title="LANIS Training Tip: The payment shock is added into the DTI and the max is 38%/43%. You also need a minimum Credit Score of 700 from the Primary Wage Earner. CLTV loan amounts over $1.0 Mil need a minimum score of 730. The Max CLTV for Primary Residence is 89.99% regardless of the Loan Program. On 2nd Homes the LTV is determined by the Lender guidelines."
                  />
                )}
              </>
          )}

          {isRefinance &&
            will_second_subordinate(
              documentType,
              loan,
              property_to_be_sold,
              is_selling_property
            ) && (
            <CurrencyField
              readOnly
              name="Current 2nd Payment (Subordinate)"
              value={property_to_be_sold.second_current_monthly_payment}
            />
          )}

          {isRefinance &&
            will_third_subordinate(
              documentType,
              loan,
              property_to_be_sold,
              is_selling_property
            ) && (
            <CurrencyField
              readOnly
              name="Current 3rd Payment (Subordinate)"
              value={property_to_be_sold.miscellaneous_housing_payment}
            />
          )}

          <CurrencyField
            readOnly
            fieldName="property_tax"
            name="Property Tax"
            value={property_tax(documentType, loan, property)}
          />
          <CurrencyField
            readOnly
            fieldName="home_owners_insurance"
            name="Home Owners Insurance"
            value={home_owners_insurance_monthly(documentType, property)}
          />

          {(is_fha(loan.type) || is_usda(loan.type)) && (
            <CurrencyField
              readOnly
              name="Mortgage Insurance (Government Fee)"
              value={mortgage_insurance_government_fee(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {showPMIMonthlyFee && (
            <CurrencyField
              readOnly
              title="LANIS Training Tip: FannieMae and FreddieMac Mortgage Insurance pricing is dynamic and typically you have to go to a Mortgage Insurance site to get accurate pricing. There are many variables. Check the Resources Tab, Loan Officer Tools section on the web site and it will save time and the pricing will be more accurate. You can also click in the hot link section in the Mortgage Insurance section of the Dn Pay Calc to get a rate."
              name="Mortgage Insurance (PMI Monthly Fee)"
              value={mortgage_insurance_pmi_monthly_fee(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          {(HOA_PROPERTY_TYPES.includes(property.property_type) ||
            HOA_PROPERTY_TYPES.includes(loan.property_type)) && (
            <>
              <CurrencyField
                readOnly
                name="HOA Dues 1"
                value={hoa_dues_1(property, loan)}
              />
              <CurrencyField
                readOnly
                name="HOA Dues 2"
                value={hoa_dues_2(property, loan)}
              />
              <CurrencyField
                readOnly
                name="Mello Roos"
                value={mello_roos(property, loan)}
              />
            </>
          )}

          <CurrencyField
            readOnly
            fieldName="additional_monthly_fees"
            name="Additional Monthly Fees"
            value={loan.additional_monthly_fees}
            onChange={updateLoan}
          />
          <CurrencyField
            readOnly
            fieldName="total_monthly_payment"
            name="Total Monthly Payment"
            value={total_monthly_payment(
              documentType,
              loan,
              property,
              property_to_be_sold,
              is_selling_property
            )}
          />

          {isARMLoanTerm && (
            <CurrencyField
              readOnly
              name="Qualifying ARM 1st Loan (P & I)"
              value={qualifying_arm_first_mortgage_piti_hoa_mi_included(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
          )}

          <CurrencyField
            readOnly
            fieldName="client_preferred_payment"
            name="Client Preferred Payment"
            value={property.preferred_monthly_payment}
          />
        </div>

        {loan.payment_type === INTEREST_ONLY && (
          <div className="forth-width">
            <CurrencyField
              readOnly
              name="1st Loan Payment (Interest Only)"
              value={first_mortgage_payment_interest_only(
                documentType,
                loan,
                property,
                property_to_be_sold,
                is_selling_property
              )}
            />
            {(loan_term(loan).eq(360) || loan_term(loan).eq(480)) && (
              <>
                <CurrencyField
                  readOnly
                  name={
                    "Qualifying " +
                    loan_term(loan)
                      .div(12)
                      .sub(10)
                      .toString() +
                    " Yr Amortizing P&I Payment"
                  }
                  value={qualifying_amortizing_payment_p_and_i_payment(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
                <CurrencyField
                  readOnly
                  name={
                    "Qualifying " +
                    loan_term(loan)
                      .div(12)
                      .sub(10)
                      .toString() +
                    " Yr Amortizing P&I Payment"
                  }
                  value={total_qualifying_amortizing_payment_p_and_i_payment(
                    documentType,
                    loan,
                    property,
                    property_to_be_sold,
                    is_selling_property
                  )}
                />
              </>
            )}
          </div>
        )}
      </div>
    </Section>
  );
}
