import React, { useMemo, useEffect } from "react";
import moment from "moment-timezone";
import { useDocument } from "./loan_tool";
import DOCUMENT_TYPES from "../../constants/document_types";
import { toFnmText } from "../../fnm/fnm";
import { useUser } from "../../hooks/user";

export default function FnmExport() {
  const [document,, { documentType, name }] = useDocument();
  const [{ accountProfile, companyProfile }, { getProfile }] = useUser();

  useEffect(() => {
    getProfile();
  }, []);

  const fmlTextContent = useMemo(() => {
    // console.log('===== rendering FnmDocument', document);
    try {
      return toFnmText(document, documentType, accountProfile, companyProfile);
    } catch (e) {
      // Since this is constantly being created, just silently fail.
      console.error(e);
      return "";
    }
  }, [document]);

  const downloadFile = useMemo(() => {
    const file = new Blob([fmlTextContent], {
      type: `text/plain`
    });

    return URL.createObjectURL(file);
  }, [fmlTextContent]);

  return (
    <>
      {downloadFile && (
        <a
          className="button"
          href={downloadFile}
          download={`${DOCUMENT_TYPES[documentType]} - ${name} - ${moment().format("YYYY-MM-DD")}.FNM`}
        >
          FNM Export
        </a>
      )}
    </>
  );
}
