import React from "react";
import {
  MONTHLY_24,
  MONTHLY_26
} from "../../constants/income_calculator_types";
import {
  yearly_income_total,
  year_to_payment,
  payment_to_year,
  two_year_average
} from "../../calculations/income";

import CurrencyField from "../fields/currency_field";
import NumberField from "../fields/number_field";

export default function PaysPerYearIncomeCalculator({
  incomeSource,
  onChangeIncome,
  weeklyMode
}) {
  const {
    year_0,
    base_income_0,
    overtime_0,
    bonuses_0,
    commission_0,
    year_1,
    base_income_1,
    overtime_1,
    bonuses_1,
    commission_1,
    paymentsPerYear,
    calculator
  } = incomeSource;

  const year0Total = yearly_income_total(
    base_income_0,
    overtime_0,
    bonuses_0,
    commission_0
  );
  const year1Total = yearly_income_total(
    base_income_1,
    overtime_1,
    bonuses_1,
    commission_1
  );
  const twoYearAverageTotal = two_year_average(overtime_0, overtime_1)
    .add(two_year_average(bonuses_0, bonuses_1))
    .add(two_year_average(commission_0, commission_1));

  let label = "Number of Payments";
  if (weeklyMode) {
    label = "Number of weeks";
  }
  const readOnlyPayments = [MONTHLY_24, MONTHLY_26].includes(calculator);

  function onChange(updatedState) {
    const yearKeys = ["year_0", "year_1"];
    Object.keys(updatedState).forEach(fieldKey => {
      if (!yearKeys.includes(fieldKey)) {
        updatedState[fieldKey] = payment_to_year(
          updatedState[fieldKey],
          incomeSource.paymentsPerYear
        ).toString();
      }
    });

    onChangeIncome(updatedState);
  }

  function onChangePaymentCount(updatedState) {
    const prevPaymentsPerYear = incomeSource.paymentsPerYear;
    const updatedValues = {
      base_income_0: incomeSource.base_income_0,
      base_income_1: incomeSource.base_income_1,
      bonuses_0: incomeSource.bonuses_0,
      bonuses_1: incomeSource.bonuses_1,
      overtime_0: incomeSource.overtime_0,
      overtime_1: incomeSource.overtime_1,
      commission_0: incomeSource.commission_0,
      commission_1: incomeSource.commission_1
    };
    const { paymentsPerYear } = updatedState;
    Object.keys(updatedValues).forEach(fieldKey => {
      const payment = year_to_payment(
        updatedValues[fieldKey],
        prevPaymentsPerYear
      ).toString();
      updatedValues[fieldKey] = payment_to_year(
        payment,
        paymentsPerYear
      ).toString();
    });
    updatedValues.paymentsPerYear = paymentsPerYear;
    onChangeIncome(updatedValues);
  }

  function renderInputRow(
    fieldName0,
    value0,
    fieldName1,
    value1,
    label,
    tabIndex,
    noAverage
  ) {
    const { paymentsPerYear } = incomeSource;
    return (
      <tr>
        <td>{label}</td>
        <td>
          <CurrencyField
            fieldName={fieldName0}
            onChange={onChange}
            tabIndex={tabIndex}
            value={year_to_payment(value0, paymentsPerYear)}
          />
        </td>
        <td>
          <CurrencyField tabIndex="-1" readOnly value={value0} />
        </td>
        <td>
          <CurrencyField
            fieldName={fieldName1}
            tabIndex={tabIndex + 3}
            onChange={onChange}
            value={year_to_payment(value1, paymentsPerYear)}
          />
        </td>
        <td>
          <CurrencyField tabIndex="-1" readOnly value={value1} />
        </td>
        <td tabIndex="-1">
          {!noAverage && (
            <CurrencyField
              tabIndex="-1"
              readOnly
              value={two_year_average(value0, value1)}
            />
          )}
        </td>
      </tr>
    );
  }

  return (
    <div className="edit-borrower-income-calculator">
      <table className="annual_income_calculator">
        <thead>
          {!readOnlyPayments && (
            <tr>
              <th>{label}</th>
              <th>
                <NumberField
                  fieldName="paymentsPerYear"
                  readOnly={readOnlyPayments}
                  onChange={onChangePaymentCount}
                  value={paymentsPerYear}
                />
              </th>
              <th colSpan="4" />
            </tr>
          )}
          <tr>
            <th>
              <NumberField
                fieldName="year_0"
                onChange={onChange}
                value={year_0}
              />
            </th>
            <th>Per Payment</th>
            <th>Per Year</th>
            <th>Per Payment</th>
            <th>
              <NumberField
                fieldName="year_1"
                onChange={onChange}
                value={year_1}
              />
            </th>
            <th>2 Yr Avg.</th>
          </tr>
        </thead>

        <tbody>
          {renderInputRow(
            "base_income_0",
            base_income_0,
            "base_income_1",
            base_income_1,
            "Base",
            1,
            true
          )}
          {renderInputRow(
            "bonuses_0",
            bonuses_0,
            "bonuses_1",
            bonuses_1,
            "Bonuses",
            1
          )}
          {renderInputRow(
            "commission_0",
            commission_0,
            "commission_1",
            commission_1,
            "Commission",
            3
          )}
        </tbody>

        <tfoot>
          <tr>
            <td>Total</td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={year_to_payment(year0Total, paymentsPerYear)}
              />
            </td>
            <td>
              <CurrencyField readOnly tabIndex="-1" value={year0Total} />
            </td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={year_to_payment(year1Total, paymentsPerYear)}
              />
            </td>
            <td>
              <CurrencyField readOnly tabIndex="-1" value={year1Total} />
            </td>
            <td>
              <CurrencyField
                readOnly
                tabIndex="-1"
                value={twoYearAverageTotal}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}
