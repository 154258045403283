import React from "react";
import "./css/additional_information.css";
import LenderBrokerInformation from "./lender_broker_information";
import Comparisons from "./comparisons";
import OtherConsiderations from "./other_considerations";
import ConfirmReceipt from "./confirm_receipt";

export default function AdditionalInformation({ updateTrid, loan }) {
  return (
    <div className="additional-information">
      <div className="big-font bold">
        Additional Information About This Loan
      </div>
      <div className="with-border">
        <LenderBrokerInformation />
        <Comparisons loan={loan} />
        <OtherConsiderations updateTrid={updateTrid} trid={loan.trid} />
        <ConfirmReceipt />
      </div>
    </div>
  );
}
