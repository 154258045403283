import React, { useState } from "react";
import "./css/closing_costs_worksheet.scss";

import StandardTemplate from "../../templates/standard_template/standard_template";

import TaxesAndOtherGovernmentFees from "../../components/loan_tools/closing_cost_worksheet/taxes_and_other_government_fees";
import Prepaids from "../../components/loan_tools/closing_cost_worksheet/prepaids";
import TransferTaxesCalculator from "../../components/loan_tools/closing_cost_worksheet/transfer_taxes_calculator";
import PrepaidsCalculator from "../../components/loan_tools/closing_cost_worksheet/prepaids_calculator";
import InitialEscrowPaymentAtClosing from "../../components/loan_tools/closing_cost_worksheet/initial_escrow_payment_at_closing";
import Other from "../../components/loan_tools/closing_cost_worksheet/other";
import TotalOtherCosts from "../../components/loan_tools/closing_cost_worksheet/total_other_costs";
import TotalClosingCosts from "../../components/loan_tools/closing_cost_worksheet/total_closing_costs";
import OriginationCharges from "../../components/loan_tools/closing_cost_worksheet/origination_charges";
import CalculatingCashToClose from "../../components/loan_tools/closing_cost_worksheet/calculating_cash_to_close";
import AdjustableInterestRateTable from "../../components/loan_tools/closing_cost_worksheet/adjustable_interest_rate_table";
import { ARM_TERMS } from "../../constants/loan_terms";
import ServicesYouCannotShopFor from "../../components/loan_tools/closing_cost_worksheet/services_you_cannot_shop_for";
import ServicesYouCanShopFor from "../../components/loan_tools/closing_cost_worksheet/services_you_can_shop_for";
import TotalLoanCosts from "../../components/loan_tools/closing_cost_worksheet/total_loan_costs";
import { useDocument } from "../../components/loan_tools/loan_tool";
import { PURCHASE, REFINANCE } from "../../constants/document_types";
import { INTEREST_ONLY } from "../../constants/loan_payment_type";
import AdjustablePaymentTable from "../../components/loan_tools/closing_cost_worksheet/adjustable_payment_table";
import { CLOSING_COSTS_EST } from "../../constants/navigations";
import SERVICE_TYPES from "../../constants/service_types";
import LoanPickerNavigation from "../../components/loan_tools/loan_picker_navigation";
import NoLoans from "./no_loans";
import TextField from "../../components/fields/text_field";
import RefinanceCalculatingCashToClose from "../../components/loan_tools/closing_cost_worksheet/refinance_calculating_cash_to_close";

const allInputFields = [
  "origination_fees",
  "services_you_cannot_shop_for",
  "services_you_can_shop_for",
  "escrow_services_by",
  "title_services_by",
  "trust_deed",
  "grand_deed",
  "in_out_trust",
  "transfer_taxes",
  "prepaids_homeowners_insurance_premium_months",
  "prepaids_mortgage_insurance_premium_months",
  "prepaids_property_taxes_months",
  "prepaids_real_estate_taxes_due",
  "prepaids",
  "initial_escrow_payment_at_closing_homeowners_insurance_months",
  "initial_escrow_payment_at_closing_mortgage_insurance_months",
  "initial_escrow_payment_at_closing_property_taxes_months",
  "other_services",
  "funds_for_borrower",
  "ca_affordable_housing_recording_fee_co_sur_charge"
];

/**
 * Closing Costs Estimator
 */
export default function ClosingCostsPage() {
  const [document, setDocument, { documentType }] = useDocument();
  const [loanIndex, setLoanIndex] = useState(0);
  const [activePage, setActivePage] = useState("CLOSING_COST");
  const { loans, property } = document;
  const loan = loans[loanIndex];
  const readOnly = false;
  const isPurchase = documentType === PURCHASE;
  const isRefinance = documentType === REFINANCE;

  function populateFromLoanOne() {
    const { loans } = document;
    if (
      window.confirm(
        "This will wipe existing input data on this page, continue?"
      )
    ) {
      const tempLoan = allInputFields.reduce((loan, field) => {
        loan[field] = loans[0][field];
        return loan;
      }, {});
      updateLoan(tempLoan);
    }
  }

  function showClosingCostPage() {
    setActivePage("CLOSING_COST");
  }

  function updateOriginationCharges(loanFees) {
    updateLoanFees("origination_fees", loanFees);
  }

  function removeOriginationCharges(type) {
    const loanFees = { ...document.loans[0].origination_fees };
    delete loanFees[type];
    updateOriginationCharges(loanFees);
  }

  function updateServicesYouCannotShopFor(loanFees) {
    updateLoanFees("services_you_cannot_shop_for", loanFees);
  }

  function removeServicesYouCannotShopFor(type) {
    const loanFees = { ...document.loans[0].services_you_cannot_shop_for };
    delete loanFees[type];
    updateServicesYouCannotShopFor(loanFees);
  }

  function updateServicesYouCanShopFor(loanFees) {
    updateLoanFees("services_you_can_shop_for", loanFees);
  }

  function removeServicesYouCanShopFor(type) {
    const loanFees = { ...document.loans[0].services_you_can_shop_for };
    delete loanFees[type];
    updateServicesYouCanShopFor(loanFees);
  }

  function updateOtherServices(loanFees) {
    updateLoanFees("other_services", loanFees);
  }

  function removeOtherServices(type) {
    const loanFees = { ...document.loans[0].other_services };
    delete loanFees[type];
    updateOtherServices(loanFees);
  }

  function updateLoanFees(name, loanFees) {
    const { loans } = document;
    loans[loanIndex][name] = loanFees;
    setDocument({ ...document, loans });
  }

  function updateLoan(updatedLoan) {
    const loans = [...document.loans];
    loans[loanIndex] = {
      ...loans[loanIndex],
      ...updatedLoan
    };
    setDocument({ ...document, loans });
  }

  function showTransferTaxesCalculator() {
    setActivePage("TRANSFER_TAXES_CALCULATOR");
  }

  function showPrepaidsCalculator() {
    setActivePage("PREPAIDS_CALCULATOR");
  }

  return (
    <StandardTemplate
      active={CLOSING_COSTS_EST}
      isDisableNavSave={activePage !== "CLOSING_COST"}
    >
      <NoLoans />
      <div className="closing-costs-worksheet">
        {document.loans.length > 0 && (
          <LoanPickerNavigation
            currentLoanIndex={loanIndex}
            setLoanIndex={setLoanIndex}
            onLoanChange={showClosingCostPage}
          />
        )}

        {activePage === "CLOSING_COST" && loan && (
          <div className="closing-costs-main">
            <div className="closing-costs-main-top">
              <div className="flex-space-between align-items-center bottom-border medium-border">
                <div className="main-top-title">Closing Cost Estimator</div>
                {loanIndex > 0 && (
                  <button
                    className="button"
                    onClick={() => populateFromLoanOne(loan)}
                  >
                    Populate Data From Loan 1
                  </button>
                )}
              </div>
              <div className="flex">
                <div className="main-top-left">
                  <div className="main-top-title">Loan Costs</div>
                </div>
                <div className="main-top-right">
                  <div className="main-top-title">Other Costs</div>
                </div>
              </div>
            </div>
            <div className="closing-costs-main-section">
              <div className="closing-costs-main-left">
                <div className="closing-costs-main-left-inner">
                  <OriginationCharges
                    loan={loan}
                    property={property}
                    readOnly={readOnly}
                    removeOriginationCharges={removeOriginationCharges}
                    updateOriginationCharges={updateOriginationCharges}
                  />
                  <ServicesYouCannotShopFor
                    loan={loan}
                    readOnly={readOnly}
                    updateLoan={updateLoan}
                    removeServicesYouCannotShopFor={
                      removeServicesYouCannotShopFor
                    }
                    updateServicesYouCannotShopFor={
                      updateServicesYouCannotShopFor
                    }
                  />
                  <ServicesYouCanShopFor
                    loan={loan}
                    readOnly={readOnly}
                    removeServicesYouCanShopFor={removeServicesYouCanShopFor}
                    updateServicesYouCanShopFor={updateServicesYouCanShopFor}
                  />
                  <TotalLoanCosts loan={loan} property={property} />
                </div>
              </div>
              <div className="closing-costs-main-right">
                <TaxesAndOtherGovernmentFees
                  loan={loan}
                  property={property}
                  readOnly={readOnly}
                  updateLoan={updateLoan}
                  showTransferTaxesCalculator={showTransferTaxesCalculator}
                />
                <Prepaids
                  loan={loan}
                  property={property}
                  readOnly={readOnly}
                  updateLoan={updateLoan}
                  showPrepaidsCalculator={showPrepaidsCalculator}
                />
                <InitialEscrowPaymentAtClosing
                  loan={loan}
                  property={property}
                  readOnly={readOnly}
                  updateLoan={updateLoan}
                />
                <Other
                  loan={loan}
                  removeOtherServices={removeOtherServices}
                  updateOtherServices={updateOtherServices}
                  readOnly={readOnly}
                />
                <TotalOtherCosts loan={loan} />
                <TotalClosingCosts loan={loan} />
                {isPurchase && (
                  <CalculatingCashToClose loan={loan} updateLoan={updateLoan} />
                )}
                {isRefinance && <RefinanceCalculatingCashToClose loan={loan} />}
              </div>
            </div>
            <div className="services-by">
              <TextField
                id="escrow_services_by"
                fieldName="escrow_services_by"
                name="Escrow Services By:"
                title="LANIS Training Tip:  If you have different Closing Agent from the Title Company, you could have an additional Wire Fee, Sub Escrow Fees to add to Section E. It's important to add the Fees to be Accurate in your loan estimate.  Remember the Tollerance is 10% for Section C."
                value={loan.escrow_services_by}
                onChange={updateLoan}
                datalist={SERVICE_TYPES}
                readOnly={readOnly}
              />
              <TextField
                id="title_services_by"
                fieldName="title_services_by"
                name="Title Services By:"
                value={loan.title_services_by}
                onChange={updateLoan}
                datalist={SERVICE_TYPES}
                readOnly={readOnly}
              />
            </div>
            {ARM_TERMS.includes(loan.term) && (
              <AdjustableInterestRateTable loan={loan} />
            )}
            {loan.payment_type === INTEREST_ONLY && (
              <AdjustablePaymentTable loan={loan} />
            )}
          </div>
        )}
        {activePage === "TRANSFER_TAXES_CALCULATOR" && (
          <TransferTaxesCalculator
            readOnly={readOnly}
            updateLoan={updateLoan}
            returnBack={showClosingCostPage}
            loan={loan}
          />
        )}
        {activePage === "PREPAIDS_CALCULATOR" && (
          <PrepaidsCalculator
            readOnly={readOnly}
            updateLoan={updateLoan}
            returnBack={showClosingCostPage}
            loan={loan}
          />
        )}
      </div>
    </StandardTemplate>
  );
}
