import React from "react";
import Decimal from "decimal.js";

import ADDITIONAL_INCOME_TYPES from "../../constants/additional_income_types";

import {
  year_to_payment,
  sum_total_income_sources,
  sum_total_other_income,
  income_source_yearly_amount
} from "../../calculations/income";

import TextField from "../fields/text_field";
import CurrencyField from "../fields/currency_field";
import Section from "../ui/section";
import NumberField from "../fields/number_field";

export default function BorrowerIncomeInformation({
  borrower,
  borrowerIndex,
  showEditAdditionalIncome,
  showEditIncomeSource,
  onChangeBorrowerIncome
}) {
  function editIncomeSource(e) {
    const incomeSourceIndex =
      e.target.dataset.index !== undefined
        ? new Decimal(e.target.dataset.index).toNumber()
        : borrower.income.sources.length;
    showEditIncomeSource(borrowerIndex, incomeSourceIndex);
  }

  function editOtherIncome(e) {
    const editedEntryIndex =
      e.target.dataset.index !== undefined
        ? new Decimal(e.target.dataset.index).toNumber()
        : borrower.income.other_income.length;
    showEditAdditionalIncome(borrowerIndex, editedEntryIndex);
  }

  function onDelete(index, isOtherIncome) {
    const updates = isOtherIncome
      ? [...borrower.income.other_income]
      : [...borrower.income.sources];
    updates.splice(index, 1);
    if (isOtherIncome) {
      onChangeBorrowerIncome(
        {
          other_income: updates
        },
        borrowerIndex
      );
    } else {
      onChangeBorrowerIncome(
        {
          sources: updates
        },
        borrowerIndex
      );
    }
  }

  function renderIncomeSource(incomeSource, index) {
    const yearlyIncome = income_source_yearly_amount(incomeSource);
    const monthlyIncome = year_to_payment(yearlyIncome, 12);
    return (
      <tr key={index}>
        <td>
          <NumberField readOnly value={incomeSource.year_0} />
        </td>
        <td>
          <TextField readOnly value={incomeSource.description} />
        </td>
        <td>
          <CurrencyField readOnly value={monthlyIncome} />
        </td>
        <td>
          <CurrencyField readOnly value={yearlyIncome} />
        </td>
        <td>
          <button
            className="button"
            data-index={index}
            onClick={editIncomeSource}
          >
            Edit
          </button>
          <button className="button" onClick={() => onDelete(index, false)}>
            Delete
          </button>
        </td>
      </tr>
    );
  }

  function renderOtherIncome(entry, index) {
    return (
      <tr key={index}>
        <td>
          <TextField
            readOnly
            value={ADDITIONAL_INCOME_TYPES[entry.income_type]}
          />
        </td>
        <td>
          <TextField readOnly value={entry.description} />
        </td>
        <td>
          <CurrencyField
            readOnly
            value={year_to_payment(entry.yearly_amount, 12)}
          />
        </td>
        <td>
          <CurrencyField readOnly value={entry.yearly_amount} />
        </td>
        <td>
          <button
            className="button"
            data-index={index}
            onClick={editOtherIncome}
          >
            Edit
          </button>
          <button className="button" onClick={() => onDelete(index, true)}>
            Delete
          </button>
        </td>
      </tr>
    );
  }

  const { other_income, sources } = borrower.income;
  const sumTotalIncomeYearly = sum_total_income_sources(sources);
  const sumTotalIncomeMonthly = year_to_payment(sumTotalIncomeYearly, 12);

  const sumTotalOtherIncomeYearly = sum_total_other_income(other_income);
  const sumTotalOtherIncomeMonthly = year_to_payment(
    sumTotalOtherIncomeYearly,
    12
  );
  return (
    <div className="income-information">
      <Section
        name={`Borrower: ${borrower.first_name} ${borrower.middle_name} ${
          borrower.last_name
        }`}
      >
        {sources.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Year</th>
                <th>Description</th>
                <th>Monthly Income</th>
                <th>Yearly Income</th>
              </tr>
            </thead>
            <tbody>{sources.map(renderIncomeSource)}</tbody>
            <tfoot>
              <tr>
                <td />
                <td className="right-align bold">Totals</td>
                <td>
                  <CurrencyField readOnly value={sumTotalIncomeMonthly} />
                </td>
                <td>
                  <CurrencyField readOnly value={sumTotalIncomeYearly} />
                </td>
              </tr>
            </tfoot>
          </table>
        )}
        <button className="button" onClick={editIncomeSource}>
          Add Income Source
        </button>

        <div className="divider" />

        <div className="other-income-container">
          {other_income.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Income Type</th>
                  <th>Description</th>
                  <th>Monthly Amt</th>
                  <th>Yearly Amt</th>
                </tr>
              </thead>
              <tbody>{other_income.map(renderOtherIncome)}</tbody>
              <tfoot>
                {other_income.length !== 0 && (
                  <tr>
                    <td />
                    <td className="right-align bold">
                      Total Additional Income
                    </td>
                    <td>
                      <CurrencyField
                        readOnly
                        value={sumTotalOtherIncomeMonthly}
                      />
                    </td>
                    <td>
                      <CurrencyField
                        readOnly
                        value={sumTotalOtherIncomeYearly}
                      />
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          )}
          <button className="button" onClick={editOtherIncome}>
            Add Other Income Source
          </button>
        </div>
      </Section>
    </div>
  );
}
