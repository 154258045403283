import React from "react";

import ORIGINATION_FEE_PAID_BY, {
  LENDER_PAID_FEE
} from "../../../constants/origination_fee_paid_by";
import { YES_NO } from "../../../constants/yes_no";

import {
  balance_remaining_of_credit,
  borrower_closing_costs_minus_lo_origination_fee,
  lender_amount_total,
  lender_credit_cost,
  lender_credit_to_closing_costs,
  mortgage_broker_fee
} from "../../../calculations/loan";

import TextField from "../../fields/text_field";
import PercentField from "../../fields/percent_field";
import CurrencyField from "../../fields/currency_field";
import { useDocument } from "../../loan_tools/loan_tool";
import TrainingTip from "../../ui/training_tip";

export default function LoanProcessorDataTable() {
  const [{ loans }] = useDocument();

  return (
    <div className="loan-processor-data-table">
      <table>
        <thead>
          <tr>
            <th>Loan #</th>
            <th>Broker Fee Paid By</th>
            <th>List Broker fee on Loan</th>
            <th>Lender loan %</th>
            <th>Lender Total $</th>
            <th>Mortgage Broker Fee</th>
            <th>
              Borrower Closing Costs Minus LO Origination Fee{" "}
              <TrainingTip content="LANIS Training Note: Closing cost typically includes all sections except A. The only cost to include here is the Lenders Underwriting / Administration fees. The MLO fee must be paid by Equity or the Borrower(s) Accounts." />
            </th>
            <th>
              Lender Credit To Closing Costs{" "}
              <TrainingTip content="LANIS Training Note: If the loan is Borrower(s) paid and this is a Negative number, you have too much credit and the balance will typically be applied to a Principal reduction. Check with your Lender for the current Reduction Policies." />
            </th>
            <th>Balance Remaining of Credit - Fees to be Paid</th>
          </tr>
        </thead>
        <tbody>
          {loans.map((loan, index) => (
            <LoanRow loan={loan} index={index} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function LoanRow({ loan, index }) {
  const [
    { property, property_to_be_sold, is_selling_property },
    ,
    { documentType }
  ] = useDocument();
  const includeBankerFee = loan.origination_fee_paid_by === LENDER_PAID_FEE;

  const lenderPercentTotal = lender_credit_cost(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBankerFee
  );
  const lenderAmountTotal = lender_amount_total(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property,
    includeBankerFee
  );

  return (
    <tr key={index}>
      <td>Loan {index + 1}</td>
      <td>
        <TextField
          readOnly
          value={ORIGINATION_FEE_PAID_BY[loan.origination_fee_paid_by]}
        />
      </td>
      <td>
        <TextField readOnly value={YES_NO[loan.display_fee]} />
      </td>
      <td>
        <PercentField readOnly value={lenderPercentTotal} />
      </td>
      <td>
        <CurrencyField readOnly value={lenderAmountTotal} />
      </td>
      <td>
        <CurrencyField
          readOnly
          value={mortgage_broker_fee(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </td>
      <td>
        <CurrencyField
          readOnly
          value={borrower_closing_costs_minus_lo_origination_fee(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </td>
      <td>
        <CurrencyField
          readOnly
          value={lender_credit_to_closing_costs(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </td>
      <td>
        <CurrencyField
          readOnly
          value={balance_remaining_of_credit(
            documentType,
            loan,
            property,
            property_to_be_sold,
            is_selling_property
          )}
        />
      </td>
    </tr>
  );
}
