export const THIRD_PARTY_PROCESSING_FEE = "1";
export const ADMINISTRATION_FEE = "2";
export const AMORTIZATION_FEE = "3";
export const APPLICATION_FEE = "4";
export const APPRAISAL_DESK_REVIEW_FEE = "5";
export const APPRAISAL_FEE = "6";
export const ASSUMPTION_FEE = "7";
export const BROKER_FLAT_FEE = "8";
export const CLO_ACCESS_FEE = "9";
export const COMMITMENT_FEE = "10";
export const COURIER_MESSENGER_FEE = "11";
export const ESCROW_WAIVER_FEE = "12";
export const LENDER_DOC_PREP_FEE = "13";
export const LENDER_INSPECTION_FEE = "14";
export const LOAN_ORIGINATION_FEE = "15";
export const MERS_REGISTRATION_FEE = "16";
export const MODIFICATION_FEE = "17";
export const MORTGAGE_BROKER_FEE = "18";
export const NEW_LOAN_ADMINISTRATION_FEE = "19";
export const ORIGINATION_FEE = "20";
export const PROCESSING_FEE = "21";
export const RATE_LOCK_FEE = "22";
export const REDRAW_FEE = "23";
export const REINSERTION_FEE = "24";
export const SIGNING_AGENT_FEE = "25";
export const TAX_RELATED_SERVICE_FEE = "26";
export const UNDERWRITING_FEE = "27";
export const VERIFICATION_FEE = "28";
export const WIRE_TRANSFER_FEE = "29";

export default Object.freeze({
  [THIRD_PARTY_PROCESSING_FEE]: "3rd Party Processing fee",
  [ADMINISTRATION_FEE]: "Administration Fee",
  [AMORTIZATION_FEE]: "Amortization Fee",
  [APPLICATION_FEE]: "Application Fee",
  [APPRAISAL_DESK_REVIEW_FEE]: "Appraisal Desk Review Fee",
  [APPRAISAL_FEE]: "Appraisal Fee",
  [ASSUMPTION_FEE]: "Assumption Fee",
  [BROKER_FLAT_FEE]: "Broker Flat Fee",
  [CLO_ACCESS_FEE]: "CLO Access Fee",
  [COMMITMENT_FEE]: "Commitment Fee",
  [COURIER_MESSENGER_FEE]: "Courier/Messenger Fee",
  [ESCROW_WAIVER_FEE]: "Escrow Waiver Fee",
  [LENDER_DOC_PREP_FEE]: "Lender Doc Prep Fee",
  [LENDER_INSPECTION_FEE]: "Lender Inspection Fee",
  [LOAN_ORIGINATION_FEE]: "Loan Origination Fee",
  [MERS_REGISTRATION_FEE]: "MERS Registration Fee",
  [MODIFICATION_FEE]: "Modification Fee",
  [MORTGAGE_BROKER_FEE]: "Mortgage Broker Fee",
  [NEW_LOAN_ADMINISTRATION_FEE]: "New Loan Administration Fee",
  [ORIGINATION_FEE]: "Origination fee",
  [PROCESSING_FEE]: "Processing fee",
  [RATE_LOCK_FEE]: "Rate-lock fee",
  [REDRAW_FEE]: "Redraw Fee",
  [REINSERTION_FEE]: "Reinsertion Fee",
  [SIGNING_AGENT_FEE]: "Signing Agent Fee",
  [TAX_RELATED_SERVICE_FEE]: "Tax Related Service Fee",
  [UNDERWRITING_FEE]: "Underwriting fee",
  [VERIFICATION_FEE]: "Verification fee",
  [WIRE_TRANSFER_FEE]: "Wire Transfer Fee"
});
