/**
 * Passes the proper config object to the application
 */

let config = {};

try {
  config = require(`./${process.env.REACT_APP_ENV_TARGET}.js`).default;
} catch (e) {
  config = require("./local.js").default;
}

export default config;
