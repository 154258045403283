import { NO } from "../constants/yes_no";

export const default_property = {
  is_subject_property: NO,
  market_value: "0",
  principal_balance: "0",
  year_of_purchase: "",
  reported_on_1040: "",
  property_address: "",
  property_address_2: "",
  property_city: "",
  property_state: "",
  property_zip: "",
  reo_property_type: "",
  gross_rent_payment: "0",
  gross_rent_percent_used_in_calculations: "0",
  first_mortgage: "0",
  second_mortgage: "0",
  taxes: "0",
  insurance: "0",
  hoa1: "0",
  hoa2: "0",
  hoa1_frequency: "",
  hoa2_frequency: "",
  mello_roos: "",
  mello_roos_amount: "0",
  mello_roos_frequency: "",
  tax_returns: [
    {
      year: "",
      income: "0",
      depreciation: "0",
      insurance: "0",
      mortgage_interest: "0",
      taxes: "0",
      hoa: "0",
      repairs: "0",
      other: "0"
    },
    {
      year: "",
      income: "0",
      depreciation: "0",
      insurance: "0",
      mortgage_interest: "0",
      taxes: "0",
      hoa: "0",
      repairs: "0",
      other: "0"
    }
  ],
  months_included_in_income: "12"
};
