import config from "../config/config.js";
import {
  getHeaders
} from "./utils";

const BasicService = {
  async get(url) {
    const headers = getHeaders();
    const response = await fetch(config.serviceHost + url, {
      method: "GET",
      headers
    });
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response;
  }
};

export default BasicService;
