import React from "react";

import {
  arm_alt_index,
  arm_index,
  ARM_index_rate,
  ARM_qualifying_rate
} from "../../../calculations/loan";

import {
  FIRST_ADJUSTMENT_CAP_PERCENTS,
  ADJUSTMENT_CAP_PERCENTS,
  ARM_INDEX_TYPES,
  ADJUSTMENT_PERIOD,
  ADJUSTMENT_LIFE_CAP,
  ARM_FIRST_CHANGE_MONTHS,
  // ALT_INDEX
} from "../../../constants/loan_arm_properties";

import PercentField from "../../fields/percent_field";
import TextField from "../../fields/text_field";
import NumberField from "../../fields/number_field";

export default function ArmRateAdjustmentsTable({
  readOnly,
  onChange,
  loan,
  isEditLoan
}) {
  const {
    rate,
    arm_caps,
    arm_margin,
    // arm_alt_index_type,
    arm_rate_floor,
    arm_index_type,
    term
  } = loan;

  // const isAltIndex = arm_index_type === ALT_INDEX;

  return (
    <div className={isEditLoan && "third-width"}>
      <div className="le-price-engine-title">ARM Rate Adjustments</div>
      <PercentField
        readOnly
        name="1st Adjustment Cap"
        value={FIRST_ADJUSTMENT_CAP_PERCENTS[arm_caps]}
      />
      <TextField
        readOnly
        className="right-align"
        name="1st Change Months"
        value={ARM_FIRST_CHANGE_MONTHS[term]}
      />

      <PercentField
        readOnly
        name="Adjustment Cap"
        value={ADJUSTMENT_CAP_PERCENTS[arm_caps]}
      />

      <NumberField
        readOnly
        name="Adjustment Period"
        value={ADJUSTMENT_PERIOD}
      />

      <PercentField
        readOnly
        name="Adjustment Life Cap"
        value={ADJUSTMENT_LIFE_CAP[arm_caps]}
      />

      <PercentField readOnly name="ARM Margin" value={arm_margin} />

      <PercentField readOnly name="ARM Index" value={arm_index(loan)} />

      <PercentField
        readOnly
        name="ALT Index"
        value={arm_alt_index(loan)}
        title="LANIS Training Tip: The Alt. Index field is used for certain loans in New York and Rhode Island."
      />

      <TextField
        readOnly
        name="Index Type"
        value={ARM_INDEX_TYPES[arm_index_type]}
      />

      <PercentField
        readOnly={readOnly}
        onChange={onChange}
        fieldName="arm_rate_floor"
        name="Rate Floor"
        value={arm_rate_floor}
      />

      <PercentField
        readOnly
        name="Index + Margin = Rate"
        value={ARM_index_rate(loan)}
      />

      <PercentField
        readOnly
        name="ARM Note Rate"
        decimalPlaces={3}
        value={rate}
      />

      <PercentField
        readOnly
        name="ARM Qualifying rate"
        value={ARM_qualifying_rate(loan)}
      />
    </div>
  );
}
