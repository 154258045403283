import Decimal from "decimal.js";

import { LENDER_FEE } from "../constants/fixed_values";
import {
  PERCENT_CREDITED_FHA,
  PERCENT_CREDITED_AGENCIES
} from "../constants/reserves_percentage_credited";

import {
  balance_pay_offs_total,
  monthly_pitia_total,
  reserve_total_payment,
  residence_total_payment
} from "./debt_info";
import { total_payment } from "./investment_reo";
import {
  PRIMARY_RESIDENCE,
  SECOND_HOME
} from "../constants/property_classification";
import {
  TYPE_ASSET,
  TYPE_RETIREMENT
} from "../constants/reserves_account_types";
import {
  full_month_of_interest_for_fha_loan,
  jumbo_reserves_required_total,
  liquid_reserves_after_close_of_escrow,
  loan_amount_first_mortgage,
  total_monthly_payment
} from "./loan";
import * as LIABILITY_TYPE from "../constants/liability_types";
import { AGENCY_LOAN_TYPES, JUMBO } from "../constants/loan_types";
import { first_current_monthly_payment } from "./property";
import { is_fha } from "../constants/utils";
import { PURCHASE, REFINANCE } from "../constants/document_types";

export function liquid_funds(total_funds, percentage_credited) {
  return new Decimal(total_funds).times(percentage_credited).dividedBy(100);
}

export function lender_fees_estimate(property_to_be_sold) {
  let total = new Decimal(0);
  const firstMonthlyPayment = first_current_monthly_payment(
    property_to_be_sold
  );
  const secondMonthlyPayment = new Decimal(
    property_to_be_sold.second_current_monthly_payment
  );
  if (!firstMonthlyPayment.equals(0)) {
    total = total.plus(firstMonthlyPayment).plus(LENDER_FEE);
  }
  if (!secondMonthlyPayment.equals(0)) {
    total = total.plus(secondMonthlyPayment).plus(LENDER_FEE);
  }
  return total;
}

export function sum_total_loans_fees_payoff(
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  is_selling_property
) {
  const debt_payoff = loan
    ? balance_pay_offs_total(Object.values(accountSummaries), residences, loan)
    : new Decimal(0);
  return new Decimal(property_to_be_sold.first_loan_balance)
    .plus(property_to_be_sold.second_loan_balance)
    .plus(property_to_be_sold.lender_fees)
    .plus(lender_fees_estimate(property_to_be_sold))
    .plus(property_to_be_sold.hoa_transfer_fees)
    .plus(property_to_be_sold.pay_off_back_taxes)
    .plus(property_to_be_sold.seller_concessions)
    .plus(debt_payoff)
    .plus(property_to_be_sold.rebate_back_to_borrower_closing_costs)
    .plus(
      full_month_of_interest_for_fha_loan(
        is_selling_property,
        property_to_be_sold
      )
    );
}

export function estimate_seller_closing_costs(property_to_be_sold) {
  return new Decimal(property_to_be_sold.current_market_value)
    .mul(property_to_be_sold.percent_seller_closing_costs)
    .div(100);
}

export function estimate_real_estate_fees(property_to_be_sold) {
  return new Decimal(property_to_be_sold.current_market_value)
    .mul(property_to_be_sold.real_estate_commission)
    .div(100);
}

export function net_from_sale(
  property_to_be_sold,
  residences,
  is_selling_property
) {
  return new Decimal(property_to_be_sold.current_market_value)
    .sub(
      sum_total_loans_fees_payoff(
        property_to_be_sold,
        null,
        residences,
        null,
        is_selling_property
      )
    )
    .sub(estimate_seller_closing_costs(property_to_be_sold))
    .sub(estimate_real_estate_fees(property_to_be_sold));
}

export function equity_from_pending_sale(
  documentType,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  is_selling_property
) {
  if (documentType === REFINANCE) {
    return new Decimal(0);
  }
  return new Decimal(property_to_be_sold.current_market_value)
    .minus(
      sum_total_loans_fees_payoff(
        property_to_be_sold,
        accountSummaries,
        residences,
        loan,
        is_selling_property
      )
    )
    .minus(estimate_seller_closing_costs(property_to_be_sold))
    .minus(estimate_real_estate_fees(property_to_be_sold));
}

function conventional_reserve_total_for_borrower(total, borrower, reserveType) {
  return total.plus(
    borrower.reserves.reduce((accum, reserve) => {
      if (reserveType && reserve.type !== reserveType) {
        return accum;
      }
      return accum.plus(
        liquid_funds(reserve.funds, PERCENT_CREDITED_AGENCIES[reserve.sub_type])
      );
    }, new Decimal(0))
  );
}

function fha_reserve_total_for_borrower(total, borrower, reserveType) {
  return total.plus(
    borrower.reserves.reduce((accum, reserve) => {
      if (reserveType && reserve.type !== reserveType) {
        return accum;
      }
      return accum.plus(
        liquid_funds(reserve.funds, PERCENT_CREDITED_FHA[reserve.sub_type])
      );
    }, new Decimal(0))
  );
}

export function calculate_reserve_total(
  documentType,
  borrowers,
  accountSummaries,
  residences,
  property_to_be_sold,
  loan,
  is_selling_property,
  reserveType
) {
  if (is_fha(loan.type)) {
    return total_fha_reserves(
      documentType,
      borrowers,
      property_to_be_sold,
      accountSummaries,
      residences,
      loan,
      is_selling_property,
      reserveType
    );
  }
  return total_conventional_reserves(
    documentType,
    borrowers,
    property_to_be_sold,
    accountSummaries,
    residences,
    loan,
    is_selling_property,
    reserveType
  );
}

export function total_conventional_reserves(
  documentType,
  borrowers,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  is_selling_property,
  reserveType
) {
  return borrowers
    .reduce(
      (total, borrower) =>
        conventional_reserve_total_for_borrower(total, borrower, reserveType),
      new Decimal(0)
    )
    .plus(
      reserveType && reserveType !== TYPE_ASSET
        ? 0
        : equity_from_pending_sale(
          documentType,
          property_to_be_sold,
          accountSummaries,
          residences,
          loan,
          is_selling_property
        )
    );
}

export function total_fha_reserves(
  documentType,
  borrowers,
  property_to_be_sold,
  accountSummaries,
  residences,
  loan,
  is_selling_property,
  reserveType
) {
  return borrowers
    .reduce(
      (total, borrower) =>
        fha_reserve_total_for_borrower(total, borrower, reserveType),
      new Decimal(0)
    )
    .plus(
      reserveType && reserveType !== TYPE_ASSET
        ? 0
        : equity_from_pending_sale(
          documentType,
          property_to_be_sold,
          accountSummaries,
          residences,
          loan,
          is_selling_property
        )
    );
}

export function calculations_percentage(investment_properties, residences) {
  // There is always a subject property.
  const subjectPropertyCount = 1;
  const residenceCount = Object.values(residences).reduce(
    (count, residence) => {
      count += residence.classification !== PRIMARY_RESIDENCE ? 1 : 0;
      return count;
    },
    0
  );
  const propertyCount =
    subjectPropertyCount + investment_properties.length + residenceCount;
  if (propertyCount <= 4) {
    return new Decimal(2);
  } else if (propertyCount <= 6) {
    return new Decimal(4);
  }
  return new Decimal(6);
}

export function monthly_percent_reserves(
  investment_property,
  investment_properties,
  residences
) {
  return new Decimal(investment_property.principal_balance)
    .mul(calculations_percentage(investment_properties, residences))
    .div(100);
}

/**
 * Summary Sheet - Total of Liquid and Retirement Accounts
 */
export function total_of_liquid_and_retirement_accounts(
  documentType,
  borrowers,
  accountSummaries,
  residences,
  property_to_be_sold,
  loan,
  is_selling_property
) {
  return total_reserves_available(
    documentType,
    borrowers,
    accountSummaries,
    residences,
    property_to_be_sold,
    loan,
    is_selling_property
  ).add(
    calculate_reserve_total(
      documentType,
      borrowers,
      accountSummaries,
      residences,
      property_to_be_sold,
      loan,
      is_selling_property,
      TYPE_RETIREMENT
    )
  );
}

/**
 * Reserves - Total Reserves Available
 */
export function total_reserves_available(
  documentType,
  borrowers,
  accountSummaries,
  residences,
  property_to_be_sold,
  loan,
  is_selling_property
) {
  // We want to include all types so this ends up the same as total reserves.
  return calculate_reserve_total(
    documentType,
    borrowers,
    accountSummaries,
    residences,
    property_to_be_sold,
    loan,
    is_selling_property
  );
}

/**
 * Reserves - Subject Property - Outstanding UPB
 */
export function subject_property_outstanding_upb(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return property.classification !== PRIMARY_RESIDENCE && loan
    ? loan_amount_first_mortgage(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
    : new Decimal(0);
}

/**
 * Reserves - Subject Property - Monthly PITIA
 */
export function subject_property_monthly_pitia(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return property.classification !== PRIMARY_RESIDENCE && loan
    ? total_monthly_payment(
      documentType,
      loan,
      property,
      property_to_be_sold,
      is_selling_property
    )
    : new Decimal(0);
}

/**
 * Reserves - Subject Property - Reserve Months
 */
export function subject_property_reserve_months(property) {
  if (property.classification === PRIMARY_RESIDENCE) {
    return new Decimal(0);
  }
  if (property.classification === SECOND_HOME) {
    return new Decimal(2);
  }
  return new Decimal(6);
}

/**
 * Reserves - Subject Property - Monthly Reserves
 */
export function subject_property_monthly_reserves(
  documentType,
  loan,
  property,
  property_to_be_sold,
  is_selling_property
) {
  return subject_property_monthly_pitia(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).mul(subject_property_reserve_months(property));
}

/**
 * Reserves - Subject Property - Monthly % Reserves
 */
export function subject_property_monthly_percent_reserves(
  documentType,
  loan,
  property,
  investment_properties,
  residences,
  property_to_be_sold,
  is_selling_property
) {
  return subject_property_outstanding_upb(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  )
    .mul(calculations_percentage(investment_properties, residences))
    .div(100);
}

/**
 * Reserves - Subject Property - Total Reserves
 */
export function subject_property_total_reserves(
  documentType,
  loan,
  property,
  investment_properties,
  residences,
  property_to_be_sold,
  is_selling_property
) {
  return subject_property_monthly_reserves(
    documentType,
    loan,
    property,
    property_to_be_sold,
    is_selling_property
  ).add(
    subject_property_monthly_percent_reserves(
      documentType,
      loan,
      property,
      investment_properties,
      residences,
      property_to_be_sold,
      is_selling_property
    )
  );
}

/**
 * Reserves - Total Reserve Requirement (Agency Reserves)
 */
export function total_reserve_requirement(
  documentType,
  loan,
  property,
  investment_properties,
  residences,
  accountSummaries,
  property_to_be_sold,
  is_selling_property
) {
  return subject_property_total_reserves(
    documentType,
    loan,
    property,
    investment_properties,
    residences,
    property_to_be_sold,
    is_selling_property
  )
    .add(
      investment_properties.reduce((total, investment_property) => {
        total = total
          .add(
            monthly_percent_reserves(
              investment_property,
              investment_properties,
              residences
            )
          )
          .add(reserve_total_payment(6, total_payment(investment_property)));
        return total;
      }, new Decimal(0))
    )
    .add(
      Object.values(accountSummaries).reduce((total, accountSummary) => {
        // TODO: this is the same logic from the total_reserve_calculator, refactor it here and there.
        if (accountSummary.liability_type !== LIABILITY_TYPE.SECOND_HOME) {
          return total;
        }
        const residence = residences[accountSummary.residence];
        const residenceTotalPayment = residence_total_payment(
          residence.first_mortgage,
          residence.second_mortgage,
          residence.taxes,
          residence.insurance,
          residence.hoa
        );
        total = total
          .add(
            monthly_percent_reserves(
              residence,
              investment_properties,
              residences
            )
          )
          .add(reserve_total_payment(2, residenceTotalPayment));
        return total;
      }, new Decimal(0))
    );
}

/**
 * Reserves - Total Reserve Requirement (Jumbo Reserves)
 */
export function total_reserve_requirement_jumbo(
  loans,
  loan,
  investment_properties
) {
  return loan.type === JUMBO
    ? monthly_pitia_total(loans, investment_properties).mul(
      jumbo_reserves_required_total(loan)
    )
    : new Decimal(0);
}

/**
 * `true` if all loan types use the same {@link total_reserve_requirement}
 * formula.
 *
 * Previously, non-agency, non-jumbo loans had a zero total reserve requirement,
 * and jumbo loans had their own {@link total_reserve_requirement_jumbo}
 * formula.  That old behavior was reported as a bug.  Because that old behavior
 * looked intentional, we suspect it may return, and so we decided to disable
 * that old behavior but retain it in the code.  This constant being `true`
 * keeps that old behavior disabled, and changing this constant to `false` would
 * re-enable that old behavior.
 *
 * @type {boolean}
 */
const SAME_TRR_FOR_ALL_LOAN_TYPES = true;

/**
 * Summary Sheet - Total Reserve Requirement
 */
export function total_reserve_requirement_for_loan_type(
  documentType,
  loans,
  loan,
  property,
  investment_properties,
  residences,
  accountSummaries,
  property_to_be_sold,
  is_selling_property
) {
  if (SAME_TRR_FOR_ALL_LOAN_TYPES || AGENCY_LOAN_TYPES.includes(loan.type)) {
    return total_reserve_requirement(
      documentType,
      loan,
      property,
      investment_properties,
      residences,
      accountSummaries,
      property_to_be_sold,
      is_selling_property
    );
  }
  if (loan.type === JUMBO) {
    return total_reserve_requirement_jumbo(loans, loan, investment_properties);
  }
  return new Decimal(0);
}

/**
 * Summary Sheet - Reserves (Positive or Shortage)
 */
export function reserves_positive_or_shortage(
  documentType,
  loans,
  borrowers,
  accountSummaries,
  residences,
  property_to_be_sold,
  loan,
  property,
  is_selling_property,
  investment_properties
) {
  const reservesAmount =
    documentType === PURCHASE
      ? liquid_reserves_after_close_of_escrow(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        property,
        is_selling_property
      )
      : calculate_reserve_total(
        documentType,
        borrowers,
        accountSummaries,
        residences,
        property_to_be_sold,
        loan,
        is_selling_property
      );

  return reservesAmount.sub(
    total_reserve_requirement_for_loan_type(
      documentType,
      loans,
      loan,
      property,
      investment_properties,
      residences,
      accountSummaries,
      property_to_be_sold,
      is_selling_property
    )
  );
}
