import React from "react";
import Decimal from "decimal.js";

import TextField from "../../fields/text_field";
import DateField from "../../fields/date_field";

import { useDocument } from "../../loan_tools/loan_tool";
import PhoneField from "../../fields/phone_field";
import Section from "../../ui/section";

export default function ApplicantTable({
  onChangeApplicant,
  showEditBorrower,
  deleteBorrower
}) {
  const [document] = useDocument();
  const { first_contact_date, borrowers } = document.applicant;

  function addBorrower() {
    showEditBorrower(document.applicant.borrowers.length);
  }

  function editBorrower(e) {
    const index = new Decimal(e.target.dataset.index).toNumber();
    showEditBorrower(index);
  }

  return (
    <div className="applicant-info-table">
      <Section name="Applicant Information">
        <div className="third-width">
          <DateField
            fieldName="first_contact_date"
            name="First Contact Date"
            value={first_contact_date}
            onChange={onChangeApplicant}
          />
        </div>
      </Section>
      <Section name="Borrowers">
        {borrowers.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Middle Name</th>
                <th>Last Name</th>
                <th>Suffix</th>
                <th>Email</th>
                <th>Cell Phone</th>
              </tr>
            </thead>
            <tbody>
              {borrowers.map((borrower, borrowerIndex) => (
                <tr key={borrowerIndex}>
                  <td>
                    <TextField readOnly value={borrower.first_name} />
                  </td>
                  <td>
                    <TextField readOnly value={borrower.middle_name} />
                  </td>
                  <td>
                    <TextField readOnly value={borrower.last_name} />
                  </td>
                  <td>
                    <TextField readOnly value={borrower.suffix} />
                  </td>
                  <td>
                    <TextField readOnly value={borrower.email} />
                  </td>
                  <td>
                    <PhoneField readOnly value={borrower.cell_phone} />
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={editBorrower}
                      data-index={borrowerIndex}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="button"
                      onClick={e => {
                        const index = new Decimal(
                          e.target.dataset.index
                        ).toNumber();
                        deleteBorrower(index);
                      }}
                      data-index={borrowerIndex}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>
            To start the loan process you must enter one borrower name. You
            should also enter the phone number and email address.
          </div>
        )}
        <button
          className="button add-borrower-button"
          disabled={borrowers.length >= 10}
          onClick={addBorrower}
        >
          Add a borrower
        </button>
      </Section>
    </div>
  );
}
