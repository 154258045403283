export const TAXES_ONLY = "0";
export const TAXES_AND_INSURANCE = "1";
export const TAX_INSURANCE_MI = "2";
export const NONE = "3";
export const TBD = "TBD";

export default Object.freeze({
  [TAXES_ONLY]: "Taxes Only",
  [TAXES_AND_INSURANCE]: "Taxes & Insurance",
  [TAX_INSURANCE_MI]: "Taxes, Insurance & MI",
  [NONE]: "None",
  [TBD]: "TBD"
});
