// export const EXCELERATED = "1";
// export const MEGA_CAP = "2";
// export const PINNACLE_CAP = "3";
// export const STEARNS = "4";
// export const UWM = "5";
//
// export default Object.freeze({
//   [EXCELERATED]: "Excelerated",
//   [MEGA_CAP]: "Mega Cap",
//   [PINNACLE_CAP]: "Pinnacle Cap",
//   [STEARNS]: "Stearns",
//   [UWM]: "UWM"
// });

export default Object.freeze([
  "Excelerated",
  "Mega Cap",
  "Pinnacle Cap",
  "Stearns",
  "UWM"
]);
