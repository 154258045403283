import React from "react";
import "./css/trid.scss";
import LoanEstimate from "../../components/loan_tools/trid/loan_estimate";
import LoanTerms from "../../components/loan_tools/trid/loan_terms";
import ProjectedPayment from "../../components/loan_tools/trid/projected_payments";
import PageFooter from "../../components/loan_tools/trid/page_footer";
import ClosingCostDetails from "../../components/loan_tools/trid/closing_cost_details";
import AdditionalInformation from "../../components/loan_tools/trid/additional_information";

/**
 * TRID (Under Summary Sheet)
 */
export default function Trid({ loan, loanNumber, updateLoan }) {
  function updateTrid(updatedTrid) {
    loan.trid = {
      ...loan.trid,
      ...updatedTrid
    };
    updateLoan(loan);
  }

  // This is not a standalone page so it does not use the StandardTemplate.
  return (
    <div className="trid">
      <div className="trid-page">
        <div className="trid-content">
          <LoanEstimate loan={loan} loanNumber={loanNumber} />
          <LoanTerms loan={loan} />
          <ProjectedPayment loan={loan} />
        </div>
        <PageFooter currentPage={1} totalPage={3} loanId="" />
      </div>
      <div className="trid-page">
        <div className="trid-content">
          <ClosingCostDetails loan={loan} />
        </div>
        <PageFooter currentPage={2} totalPage={3} loanId="" />
      </div>
      <div className="trid-page">
        <div className="trid-content">
          <AdditionalInformation updateTrid={updateTrid} loan={loan} />
        </div>
        <PageFooter currentPage={3} totalPage={3} loanId="" />
      </div>
    </div>
  );
}
