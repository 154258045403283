import React from "react";

import AccountTableRow from "./account_table_row";
import CREDIT_SCORES from "../../../constants/credit_score";
import { NO } from "../../../constants/yes_no";

import {
  balance_pay_offs_total,
  balance_to_be_paid_down_total,
  current_balance_total,
  pay_off_debt_to_qualify_minus_debt_to_income,
  high_credit_limit_total,
  lower_balance_payment_left_total,
  monthly_payments_total,
  payment_for_DTI_calc_total,
  payment_savings_total,
  debt_to_income_ratio
} from "../../../calculations/debt_info";
import { total_net_yearly_income_loss_rental, wrap_investment_properties } from "../../../calculations/investment_reo";

import { useDocument } from "../../../components/loan_tools/loan_tool";
import PercentField from "../../../components/fields/percent_field";
import CurrencyField from "../../../components/fields/currency_field";
import TrainingTip from "../../../components/ui/training_tip";
import { INCLUDE_IN_DTI } from "../../../constants/account_dispositions";

export default function AccountSummaryTable({
  showEditAccount,
  loanIndex,
  groupedLoansTab
}) {
  const [document, setDocument, { documentType }] = useDocument();
  const { loans } = document;
  const hasLoans = loans.length > 0;

  function updateDisposition(accountSummary, disposition) {
    if (!hasLoans) {
      return;
    }
    const updatedLoans = loans.map((loan, index) => {
      if (groupedLoansTab || loanIndex === index) {
        const loanEntry = { ...loan };
        loanEntry.account_dispositions = {
          ...loan.account_dispositions,
          [accountSummary.id]: disposition
        };
        return loanEntry;
      } else {
        return loan;
      }
    });
    setDocument({ ...document, loans: updatedLoans });
  }

  function deleteAccountSummary(accountSummaryId) {
    const updatedLoans = [...loans];
    const updatedAccountSummaries = { ...document.accountSummaries };
    delete updatedAccountSummaries[accountSummaryId];
    updatedLoans.forEach(loan => {
      delete loan.account_dispositions[accountSummaryId];
    });
    setDocument({
      ...document,
      loans: updatedLoans,
      accountSummaries: updatedAccountSummaries
    });
  }

  function renderLoanIndexText() {
    if (!hasLoans) {
      return "Accounts Analysis";
    }
    if (!groupedLoansTab) {
      return `Loan ${loanIndex + 1} Analysis`;
    } else {
      return `Loans 1 - 5`;
    }
  }

  function loanDTIEntry(loan, index) {
    const {
      investment_properties,
      accountSummaries,
      residences,
      property,
      property_to_be_sold,
      is_selling_property
    } = document;
    const { borrowers } = document.applicant;
    const wrapped_investment_properties = wrap_investment_properties(
        property, investment_properties);
    return (
      <div key={index} className="loan-dti">
        <div>Loan {index + 1} DTI</div>
        <div>
          <PercentField
            naked
            fixedDigits={2}
            value={debt_to_income_ratio(
              documentType,
              borrowers,
              wrapped_investment_properties,
              accountSummaries,
              property,
              residences,
              loan,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
      </div>
    );
  }

  function backEndRatio(loan) {
    const {
      investment_properties,
      accountSummaries,
      property,
      residences,
      property_to_be_sold,
      is_selling_property
    } = document;
    const wrapped_investment_properties = wrap_investment_properties(
        property, investment_properties);
    const { borrowers } = document.applicant;
    return (
      <div key={loan.id} className="loan-dti">
        <div>Pay Off Debt to Qualify, DTI Back End Ratio</div>
        <div>
          <PercentField
            naked
            fixedDigits={2}
            value={pay_off_debt_to_qualify_minus_debt_to_income(
              documentType,
              borrowers,
              wrapped_investment_properties,
              accountSummaries,
              property,
              residences,
              loan,
              property_to_be_sold,
              is_selling_property
            )}
          />
        </div>
      </div>
    );
  }

  function renderTableBody() {
    const { residences, accountSummaries, loans } = document;
    const { borrowers } = document.applicant;
    const loan = hasLoans && (loanIndex === null ? loans[0] : loans[loanIndex]);
    const accountSummaryList = Object.values(accountSummaries)
        .slice(0)
        .sort((a, b) => a.description.localeCompare(b.description));

    const accountRows = [];
    for (let accountSummary of accountSummaryList) {
      const disposition =
        (loan && loan.account_dispositions[accountSummary.id]) ||
        INCLUDE_IN_DTI;
      const borrower = borrowers[parseInt(accountSummary.borrower, 10)];
      accountRows.push(
        <AccountTableRow
          key={accountSummary.id}
          accountSummary={accountSummary}
          residence={residences[accountSummary.residence]}
          borrower={borrower}
          updateDisposition={updateDisposition}
          showEditAccount={showEditAccount}
          deleteAccountSummary={deleteAccountSummary}
          disposition={disposition}
          hasLoans={hasLoans}
        />
      );
    }
    const negRentalRow = renderNegRental();
    const totalsRow = renderTotalsRow();
    return (
      <tbody>
        {accountRows}
        {negRentalRow}
        {totalsRow}
      </tbody>
    );
  }

  function renderTableHeader() {
    return (
      <thead>
        <tr>
          <th>Borrower</th>
          <th>Liability Type</th>
          <th>Company</th>
          <th>
            Account Number{" "}
            <TrainingTip content="LANIS Training Tip: Only use last 4 digits of the account number and cross reference it to the credit report." />
          </th>
          <th>Total Mo. Payment</th>
          <th>Payment Savings</th>
          <th>
            Current Balance{" "}
            <TrainingTip content="LANIS Training Tip: If there are less than 10 months remaining payments, you may not need to add it to the DTI Calculations. Check the Current Monthly statement because the Credit Report may be 30 - 45 days delayed in reporting. Get a copy for the file before submission to the Lender." />
          </th>
          <th>High Credit Limit</th>
          <th>Payment For DTI Calc*</th>
          <th>Balance Pay Off</th>
          <th>
            Percent of Credit Used{" "}
            <TrainingTip content="LANIS Training Tip: If the Percentage of Credit Used exceeds 50%, credit scores will be closer to 700 and can be even lower. If Percentage Used is above 80% for example, the Borrower(s) scores could be in the mid-600 range. This is critical to pricing out your loans with LLPA's." />
          </th>
          <th>
            Payments Left{" "}
            <TrainingTip content="LANIS Training Tip: For Auto, RV, Boat, Motorcycle and Installment loans, if you have 10 payments or less it does not need to be counted in the Debt To Income Ratios. Check with your Lender for their current guidelines." />
          </th>
          <th>Lower Bal PMT Left</th>
          <th>Disposition</th>
          <th>Balance to be Paid Dn</th>
          <th />
        </tr>
      </thead>
    );
  }

  function renderDebtToIncomeFooter() {
    return (
      <div className="loan-dti-section">
        {document.applicant.pay_off_debt_to_qualify === NO
          ? document.loans.map(loanDTIEntry)
          : backEndRatio(document.loans[loanIndex])}
      </div>
    );
  }

  function renderNegRental() {
    const wrapped_investment_properties = wrap_investment_properties(
        document.property, document.investment_properties);
    const totalNegativeRentalAmount = total_net_yearly_income_loss_rental(
        wrapped_investment_properties
    ).neg();
    if (totalNegativeRentalAmount.lessThanOrEqualTo(0)) {
      return <tr />;
    }

    return (
      <tr>
        <td />
        <td colSpan="3">
          <b>Total Negative rental from Investment REO</b>
        </td>
        {/*monthly payment*/}
        <td>
          <CurrencyField
            readOnly
            fixedDigits={0}
            value={totalNegativeRentalAmount}
          />
        </td>
        {/*payment savings*/}
        <td />
        {/*current balance*/}
        <td />
        {/*high credit limit*/}
        <td />
        {/*payment for dti calc*/}
        <td>
          <CurrencyField
            readOnly
            fixedDigits={0}
            value={totalNegativeRentalAmount}
          />
        </td>
        {/*balance pay off*/}
        <td />
        <td />
        <td />
        {/*lower bal pmt left*/}
        <td />
        <td />
        {/*lower bal pmt left*/}
        <td />
      </tr>
    );
  }

  function renderTotalsRow() {
    const { residences, investment_properties, loans, property } = document;
    const wrapped_investment_properties = wrap_investment_properties(
        property, investment_properties);
    const loan = loanIndex === null ? loans[0] : loans[loanIndex];
    const accountSummaries = Object.values(document.accountSummaries);

    const monthlyPaymentsTotal = monthly_payments_total(
      accountSummaries,
      residences,
      wrapped_investment_properties
    );
    const paymentSavingsTotal = payment_savings_total(
      accountSummaries,
      residences,
      loan
    );
    const currentBalancesTotal = current_balance_total(
      accountSummaries,
      residences
    );
    const highCreditLimitsTotal = high_credit_limit_total(accountSummaries);
    const dtiCalcPaymentsTotal = payment_for_DTI_calc_total(
      accountSummaries,
      residences,
      wrapped_investment_properties,
      loan
    );
    const balancePayOffsTotal = balance_pay_offs_total(
      accountSummaries,
      residences,
      loan
    );
    const lowerBalancePaymentsLeftTotal = lower_balance_payment_left_total(
      accountSummaries,
      residences
    );
    const balancesToBePaidDownTotal = balance_to_be_paid_down_total(
      accountSummaries,
      residences,
      loan
    );

    return (
      <>
        <tr>
          <td colSpan="16">
            <div className="divider" />
          </td>
        </tr>
        <tr>
          <td />
          <td />
          <td />
          <td>
            <b>Total</b>
          </td>
          {/*monthly payment*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={monthlyPaymentsTotal}
            />
          </td>
          {/*payment savings*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={paymentSavingsTotal}
            />
          </td>
          {/*current balance*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={currentBalancesTotal}
            />
          </td>
          {/*high credit limit*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={highCreditLimitsTotal}
            />
          </td>
          {/*payment for dti calc*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={dtiCalcPaymentsTotal}
            />
          </td>
          {/*balance pay off*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={balancePayOffsTotal}
            />
          </td>
          <td />
          <td />
          {/*lower bal pmt left*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={lowerBalancePaymentsLeftTotal}
            />
          </td>
          <td />
          {/*lower bal pmt left*/}
          <td>
            <CurrencyField
              readOnly
              fixedDigits={0}
              value={balancesToBePaidDownTotal}
            />
          </td>
        </tr>
      </>
    );
  }

  const creditScore =
    document.applicant.borrowers[0] &&
    document.applicant.borrowers[0].lowest_credit_mid_score;
  return (
    <div className="account-table">
      <div className="account-table-header">
        <div className="center-align bold">{renderLoanIndexText()}</div>
        <div className="credit-score">
          Credit Score: {CREDIT_SCORES[creditScore]}
        </div>
      </div>

      <table>
        {renderTableHeader()}
        {renderTableBody()}
      </table>

      {hasLoans && renderDebtToIncomeFooter()}
    </div>
  );
}
