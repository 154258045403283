export const default_account_profile = {
  user_id: 0,
  email: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  nmls_number: "",
  state_real_estate_license: "",
  cell_phone: "",
  direct_contact_phone: "",
  office_phone: "",
  loan_origination_software_used: "",

  // Role related
  system_admin_role: false,
  company_admin_role: false,
  loan_officer_role: false,
  loan_processor_role: false
};

export const default_company_profile = {
  subscription_active: false,
  normal_subscription: true,
  subscription_end_date: "",
  subaccount_limit: 1,
  seat_limit: 1,

  // content
  id: 0,
  company_name: "",
  company_nmls_number: "",
  company_state_license: "",
  admin_contact_name: "",
  email: "",
  contact_phone: "",
  extension: "",
  business_address: "",
  business_address_2: "",
  city: "",
  state: "",
  zip: "",
  company_los_used: "",
  company_web_site: ""
};
