import React, { useEffect, useState } from "react";

import { useUser } from "../../hooks/user";
import { default_company_profile } from "../../schema/profile";
import Section from "../ui/section";
import CompanyProfile from "../profile/company_profile";
import FileField from "../fields/file_field";
import Image from "../ui/image";

export default function CompanyInformation() {
  const [
    { accountProfile, companyProfile },
    { saveCompanyProfile }
  ] = useUser();
  const [profile, setProfile] = useState({
    ...default_company_profile
  });
  const [companyLogo, setCompanyLogo] = useState();
  const [imageHash, setImageHash] = useState(Date.now());

  async function onSaveProfile() {
    saveCompanyProfile({ ...profile }, companyLogo);
  }

  function onProfileChange(updatedProfile) {
    setProfile({
      ...profile,
      ...updatedProfile
    });
  }

  useEffect(() => {
    setProfile(companyProfile);
    if (companyLogo) {
      setImageHash(Date.now());
    }
  }, [companyProfile]);

  return (
    <Section name="Company Information" className="company-info">
      <div className="flex">
        <div className="half-width">
          <CompanyProfile
            companyProfile={profile}
            onCompanyProfileChange={onProfileChange}
            readOnly={!accountProfile.company_admin_role}
          />
        </div>
        <div className="half-width">
          <FileField
            fieldName="company_logo"
            readOnly={!accountProfile.company_admin_role}
            name={
              <div>
                Company Logo
                <br />
                (max size: 300x300, types: jpg, png)
              </div>
            }
            onChange={value => setCompanyLogo(value.company_logo)}
          />
          <div className="right-align">
            <Image url={`company/company-logo?${imageHash}`} />
          </div>
        </div>
      </div>
      <button
        className="button"
        disabled={!accountProfile.company_admin_role}
        onClick={onSaveProfile}
      >
        Save
      </button>
    </Section>
  );
}
