export const PERCENT_DOWN = "1";
export const PERCENT_DOWN_W_2ND_LOAN = "2";
export const CASH_DOWN = "3";
export const CASH_DOWN_W_2ND_LOAN = "4";
export const FIXED_LOAN_AMOUNT = "5";
export const FIXED_LOAN_AMOUNT_W_2ND_LOAN = "6";

export const PERCENT_PAYMENT_TYPES = [PERCENT_DOWN, PERCENT_DOWN_W_2ND_LOAN];

export const CASH_DOWN_PAYMENT_TYPES = [CASH_DOWN, CASH_DOWN_W_2ND_LOAN];

export const FIXED_LOAN_AMOUNT_PAYMENT_TYPES = [
  FIXED_LOAN_AMOUNT,
  FIXED_LOAN_AMOUNT_W_2ND_LOAN
];

export const SECOND_LOAN_PAYMENT_TYPES = [
  PERCENT_DOWN_W_2ND_LOAN,
  CASH_DOWN_W_2ND_LOAN,
  FIXED_LOAN_AMOUNT_W_2ND_LOAN
];

export default Object.freeze({
  [PERCENT_DOWN]: "Percent Down",
  [PERCENT_DOWN_W_2ND_LOAN]: "Percent Down w/ 2nd Loan",
  [CASH_DOWN]: "Cash Down",
  [CASH_DOWN_W_2ND_LOAN]: "Cash Down w/ 2nd Loan",
  [FIXED_LOAN_AMOUNT]: "Fixed Loan Amount",
  [FIXED_LOAN_AMOUNT_W_2ND_LOAN]: "Fixed Loan Amount w/ 2nd Loan"
});

export const REFINANCE_PAYMENT_TYPES = Object.freeze({
  [PERCENT_DOWN]: "Equity Remaining",
  [PERCENT_DOWN_W_2ND_LOAN]: "Equity Remaining w/ 2nd Loan",
  [CASH_DOWN]: "Cash Down",
  [CASH_DOWN_W_2ND_LOAN]: "Cash Down w/ 2nd Loan",
  [FIXED_LOAN_AMOUNT]: "Fixed Loan Amount",
  [FIXED_LOAN_AMOUNT_W_2ND_LOAN]: "Fixed Loan Amount w/ 2nd Loan"
});
